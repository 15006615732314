import {FormikErrors} from 'formik'
import {useEffect} from 'react'
import {toast} from 'react-toastify'

const FormErrorNotification = ({
  scrollToRef,
  errors,
  isSubmitting,
  isValid,
  isValidating
}: {
  errors: FormikErrors<any>
  isValid: boolean
  isValidating: boolean
  isSubmitting: boolean
  scrollToRef: () => void | undefined
}) => {
  const hasErrors = Object.keys(errors).length

  useEffect(() => {
    if (!isValid && !isValidating && isSubmitting && Boolean(errors)) {
      if (hasErrors) {
        toast.error('Please address the form issues')
        scrollToRef()
      }
    }
  }, [isSubmitting, isValid, isValidating, errors])

  return null
}

export default FormErrorNotification
