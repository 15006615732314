import React from 'react'
import styles from './SenderMessage.module.scss'
import {
  DownloadIconCircle,
  PdfIcon,
  Play_icon,
  Triangle
} from '../../../assets/svgs'
import {downloadPDFfromURL} from '../../utils'
import DOMPurify from 'dompurify'
import ReactPlayer from 'react-player'
import {Attachments, PreviewLinkProps} from 'common'
import ChatImage from 'components/ChatImage'
import UserProfilePicture from 'components/UserProfileImage'
import classNames from 'classnames'
import {AdminIcon} from 'assets/images'

interface SenderMessageProps {
  attachment: Array<Attachments>
  preview?: PreviewLinkProps
  sellerContactId: string
  isAdmin: boolean
  message: string
}

const SenderMessage = ({
  sellerContactId,
  attachment,
  message,
  isAdmin,
  preview
}: SenderMessageProps) => {
  if (attachment?.length > 0) {
    return (
      <React.Fragment>
        {attachment.map((att, index) => {
          const {type} = att
          const renderComponentBasedOnType = () =>
            type === 'image' ? (
              <ChatImage alt="sender-attachment-img" url={att?.downloadURL} />
            ) : type === 'video' ? (
              <div className={styles.video_wrapper}>
                <ReactPlayer
                  className={styles.videos}
                  controls
                  playing
                  url={att?.downloadURL}
                  playIcon={<Play_icon />}
                />
              </div>
            ) : type === 'application/pdf' ? (
              <div className={styles.PDF_container}>
                <PdfIcon />{' '}
                {att?.name.length > 9
                  ? `${att?.name.slice(0, 5).trim()}...`
                  : att?.name}
                <button
                  onClick={() =>
                    downloadPDFfromURL(att?.downloadURL, att?.name)
                  }
                >
                  <DownloadIconCircle />
                </button>
              </div>
            ) : null

          return (
            <div
              key={`sender-message-with-attachement-${index}`}
              className={classNames(
                styles.container,
                isAdmin ? styles.isAdmin : ''
              )}
            >
              <div className={styles.right_container}>
                <div className={styles.messagerimg}>
                  {isAdmin ? (
                    <img src={AdminIcon} alt="admin-img" />
                  ) : (
                    <UserProfilePicture
                      userId={sellerContactId}
                      alt="img-messager"
                    />
                  )}
                </div>
                <div
                  style={
                    type === 'video'
                      ? {maxWidth: '23.5rem'}
                      : {maxWidth: '22.5rem'}
                  }
                  className={styles.content}
                >
                  {isAdmin && (
                    <b className={styles.adminMessage}>Message from Admin:</b>
                  )}
                  <div className={styles.attacment_wrapper}>
                    {renderComponentBasedOnType()}
                  </div>
                  {message && (
                    <p
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(message)
                      }}
                      className={styles.attachment_message}
                    ></p>
                  )}
                  <Triangle className={styles.message_ions} />
                </div>
              </div>
            </div>
          )
        })}
      </React.Fragment>
    )
  }
  return (
    <div
      className={classNames(styles.container, isAdmin ? styles.isAdmin : '')}
    >
      <div className={styles.right_container}>
        <div className={styles.messagerimg}>
          {isAdmin ? (
            <img src={AdminIcon} alt="admin-img" />
          ) : (
            <UserProfilePicture userId={sellerContactId} alt="img-messager" />
          )}
        </div>
        <div className={styles.content}>
          {preview?.title?.length ? (
            <div className={styles.preview_container}>
              <div className={styles.preview_container_img_container}>
                {preview?.image && <img src={preview?.image} alt="map-img" />}
              </div>
              <div className={styles.preview_container_content_container}>
                <div>{preview?.title}</div>
                <div className={styles.preview_content}>
                  {preview?.description}
                </div>
              </div>
            </div>
          ) : null}
          {isAdmin && (
            <b className={styles.adminMessage}>Message from Admin:</b>
          )}
          <p
            className={styles.content_message}
            dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(message)}}
          ></p>
          <Triangle className={styles.message_ions} />
        </div>
      </div>
    </div>
  )
}

export default SenderMessage
