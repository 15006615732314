import React from 'react'
import styles from './ContentModal.module.scss'
import {Cancel, CrossIcon, ObjectSVG} from 'assets/svgs'
import cn from 'classnames'
import Button from 'components/Button'

interface ContentModalProps {
  handleClose: () => void
  heading: string
  content: string
  removeIcon?: Boolean
  buttonText: string
  buttonOnClick: () => void
  buttonDisabled: boolean
}

const ContentModal = ({
  handleClose,
  heading,
  content,
  buttonText,
  buttonOnClick,
  removeIcon,
  buttonDisabled
}: ContentModalProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.heading}>
          <Cancel className={styles.cancel} onClick={handleClose} />
          <div className={styles.infoContainer}>
            {removeIcon ? <CrossIcon /> : <ObjectSVG />}
          </div>
        </div>
        <h1>{heading}</h1>
        <p>{content}</p>
        {removeIcon ? (
          <Button
            customClass={cn(styles.buttons, styles.singleButton)}
            customBtnClass="btn-cancel"
            disabled={buttonDisabled}
            onClick={buttonOnClick}
            text={buttonText}
          />
        ) : (
          <div className={styles.buttons}>
            <button className="btn-fill-grey" onClick={handleClose}>
              Cancel
            </button>
            <Button
              customBtnClass="btn-fill-orange"
              disabled={buttonDisabled}
              onClick={buttonOnClick}
              text={buttonText}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default ContentModal
