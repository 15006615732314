import {Cancel} from 'assets/svgs'
import styles from './ImageModal.module.scss'

interface ImageModalProps {
  handleClose: () => void
  url: string
  alt: string
}

const ImageModal = ({handleClose, url, alt}: ImageModalProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Cancel onClick={handleClose} />
      </div>
      <div className={styles.img_container}>
        <img width={300} height={250} src={url} alt={alt} />
      </div>
    </div>
  )
}

export default ImageModal
