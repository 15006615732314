import {ContactDocument} from 'common'
import Pagination from 'components/Pagination'
import ReqNotFoundScreen from 'components/ReqNotFoundScreen'
import {TABLE_PAGINATION_PAGE_SIZE} from 'components/constants'
import {Timestamp} from 'firebase/firestore'
import {debounce, isDate} from 'lodash'
import React, {useEffect, useRef, useState} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {CheckedIcon, UncheckedIconTwo} from '../../assets/images'
import AlgoliaClient, {
  CONTACTS_ADMIN_PANEL_ALGOLIA_INDEX
} from '../AlgoliaSearch'
import Button from '../Button'
import {useAppContext, useFirebase} from '../Firebase/hooks'
import InputField from '../InputField'
import Layout from '../Layout'
import {Loader} from '../Loader'
import NavBar from '../Navbar'
import withAuthorization from '../Session/withAuthorization'
import Table from '../TableComponent'
import {
  convertToDate,
  getFormattedDate,
  getUserFullName,
  searchWithAlgolia,
  useSortableData
} from '../utils'
import styles from './ContactPage.module.scss'

const ContactPage = ({customClass}: {customClass: string}) => {
  const {state} = useLocation()
  const pageFromLocation = state?.page
  const [currentPage, setCurrentPage] = useState<number>(pageFromLocation || 1)
  const [contactData, setContactsData] = useState<Array<ContactDocument>>([])
  const [accountList, setAccountList] = useState<Array<string>>([])
  const [loader, setLoader] = useState(true)
  const {getUserAccounts} = useFirebase()
  const navigate = useNavigate()
  const [searchValue, setSearchValue] = useState('')
  const searchIndex = AlgoliaClient.initIndex(
    CONTACTS_ADMIN_PANEL_ALGOLIA_INDEX
  )
  const [pageCount, setPageCount] = useState<number>(0)
  const {
    appState: {isMSAAdmin, isSuperAdmin, isSuperManager}
  } = useAppContext()

  const getMSAAccountsList = async () => {
    const userAccounts = await getUserAccounts()
    const accountIDLength = userAccounts.length
    if (accountIDLength) {
      !accountList.length && setAccountList([...userAccounts])
    }
  }

  useEffect(() => {
    if (isMSAAdmin) {
      getMSAAccountsList()
    }
  }, [])

  useEffect(() => {
    AlgoliaClient.clearCache()
    if (isMSAAdmin && accountList.length) {
      searchWithAlgolia<ContactDocument>({
        searchQuery: '*',
        searchIndex,
        setLoaderState: setLoader,
        setData: setContactsData,
        setPageCount,
        accountList,
        currentPage
      }).then(() => setLoader(false))
      return
    }
    if (isSuperAdmin || isSuperManager) {
      searchWithAlgolia<ContactDocument>({
        searchQuery: '*',
        searchIndex,
        setLoaderState: setLoader,
        setData: setContactsData,
        setPageCount,
        accountList,
        currentPage
      }).then(() => setLoader(false))
    }
  }, [accountList?.length])

  const tableTitles = [
    {title: 'Contact Name', sortBy: () => sortBy('contactName')},
    {title: 'Account Name', sortBy: () => sortBy('companyName')},
    {title: 'Is Primary Contact'},
    {title: 'Mobile'},
    {title: 'Created At', sortBy: () => sortBy('date')}
  ]

  const getFormattedData = (
    contactData: ContactDocument & {objectID?: string}
  ) => {
    const {contactInfo, firstName, lastName, objectID} = contactData || {}
    const mobileNumber = contactInfo?.mobileNumber || '-'
    const userfirstName = firstName || ''
    const userlastName = lastName || ''
    const contactName = getUserFullName(userfirstName, userlastName)
    const companyName = contactData?.accountName || ''
    const isPrimaryContact = contactData?.isPrimaryContact || false
    const createdAtDate =
      contactData?.createdAt && convertToDate(contactData?.createdAt)
    const createdAt = isDate(createdAtDate)
      ? getFormattedDate(createdAtDate)
      : '-'
    const date = isDate(createdAtDate)
      ? Timestamp.fromDate(createdAtDate).seconds
      : '-'
    const documentId = contactData?.contactId || ''
    return {
      contactName,
      isPrimaryContact,
      mobileNumber,
      createdAt,
      documentId,
      companyName,
      objectID,
      date
    }
  }

  const getRowData = (
    rowData: Array<{
      contactName: string
      isPrimaryContact: boolean
      mobileNumber: string
      createdAt: string
      documentId: string
      companyName: string
      objectID?: string
    }>
  ) => {
    return (rowData || []).map((singleRowData, index) => {
      const {
        contactName,
        isPrimaryContact,
        mobileNumber,
        createdAt,
        documentId,
        companyName,
        objectID
      } = singleRowData || {}
      return (
        <tr
          key={`contact-row-data-${index}`}
          onClick={() => {
            navigate('/contacts/single-contact-details', {
              state: {id: documentId, objectID, isEdit: true, currentPage},
              preventScrollReset: true
            })
          }}
        >
          <td>{contactName || '-'}</td>
          <td>{companyName}</td>
          <td>
            <img
              src={isPrimaryContact ? CheckedIcon : UncheckedIconTwo}
              alt="primary"
            />
          </td>
          <td>{mobileNumber}</td>
          <td>{createdAt}</td>
        </tr>
      )
    })
  }

  const debouncedSearch: any = useRef(debounce(searchWithAlgolia, 800))

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (isMSAAdmin && !accountList?.length) return
    const value = event?.target?.value
    setSearchValue(value)
    if (value) {
      debouncedSearch?.current({
        searchQuery: value,
        searchIndex: searchIndex,
        setData: setContactsData,
        setLoaderState: setLoader,
        setPageCount,
        accountList: accountList
      })
    } else {
      debouncedSearch?.current({
        searchQuery: '*',
        searchIndex,
        setLoaderState: setLoader,
        setData: setContactsData,
        setPageCount,
        accountList,
        currentPage
      })
    }
  }

  const noData = !contactData.length && !loader

  const scrollRef: React.RefObject<HTMLDivElement> = React.createRef()

  const scrollToRef = () =>
    scrollRef?.current?.scrollIntoView({behavior: 'smooth'})

  const formattedContactData = contactData.map((data) => getFormattedData(data))
  const {items, sortBy} = useSortableData(formattedContactData)

  const showPagination = pageCount > TABLE_PAGINATION_PAGE_SIZE

  const onPageChange = (page: number) => {
    setLoader(true)
    setCurrentPage(page)
    debouncedSearch?.current({
      searchQuery: searchValue,
      searchIndex,
      setLoaderState: setLoader,
      setData: setContactsData,
      setPageCount,
      accountList,
      currentPage: page
    })
    setLoader(false)
    scrollToRef()
  }

  return (
    <div className={styles.withLayout}>
      <Layout />
      <div className={[styles.container, customClass].join(' ')}>
        <NavBar title="Contacts" />
        <div className={styles.button_filters}>
          <InputField
            name="contactSearchField"
            type="text"
            value={searchValue}
            onChange={handleSearch}
            isSearch={true}
            customClass={styles.inputField}
            placeholder="Search for Filters"
          />
          <div className={styles.button_creaters}>
            <Button
              onClick={() => navigate('/contacts/single-contact-details')}
              text={'Create Contact'}
              customClass={styles.creater_button}
            />
          </div>
        </div>
        {loader ? (
          <Loader />
        ) : noData ? (
          <ReqNotFoundScreen />
        ) : (
          <React.Fragment>
            <Table titles={tableTitles} rowData={getRowData(items)} />
            {showPagination && (
              <div className="pagination__wrapper">
                <Pagination
                  currentPage={currentPage}
                  totalCount={pageCount}
                  pageSize={TABLE_PAGINATION_PAGE_SIZE}
                  onPageChange={onPageChange}
                />
              </div>
            )}
          </React.Fragment>
        )}
      </div>
    </div>
  )
}

export default withAuthorization(ContactPage)
