import styles from './ConfirmModal.module.scss'
import {ObjectSVG, Cancel} from '../../assets/svgs'
import Button from 'components/Button'
import CurrencyInputField from 'components/InputField/CurrencyInputField'

interface ModalProps {
  handleClose: () => void
  text: string
  content: string
  confirmBtnDisabled: boolean
  handleConfirm: () => Promise<void>
  hasInput?: boolean
  inputState?: {
    state: string | number
    label: string
    setState: React.Dispatch<React.SetStateAction<string>>
  }
}

const ConfirmModal = ({
  handleClose,
  text,
  content,
  confirmBtnDisabled,
  handleConfirm,
  hasInput = false,
  inputState
}: ModalProps) => {
  return (
    <div
      style={(hasInput && {height: 'auto'}) || {}}
      className={styles.container}
    >
      <div className={styles.content}>
        <div className={styles.heading}>
          <Cancel className={styles.cancel} onClick={handleClose} />
          <div className={styles.infoContainer}>
            <ObjectSVG className={styles.help} />
          </div>
        </div>
        <h1>{text}</h1>
        <p>{content}</p>
        {hasInput && (
          <div className={styles.inputWrapper}>
            <CurrencyInputField
              name="paidAmount"
              value={inputState?.state}
              placeholder={inputState?.label}
              onChange={(value) => inputState?.setState(value || '')}
            />
          </div>
        )}
        <div className={styles.buttons}>
          <button onClick={handleClose}>Cancel</button>
          <Button
            disabled={confirmBtnDisabled}
            onClick={handleConfirm}
            text="Confirm"
          />
        </div>
      </div>
    </div>
  )
}

export default ConfirmModal
