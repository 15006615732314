import {User} from 'firebase/auth'
import {useContext, useEffect, useState} from 'react'
import {FirebaseContext} from './context'
import {AppContext} from 'components/Context'

const useFirebase = () => {
  const firebase = useContext(FirebaseContext)
  return firebase
}

const useAppContext = () => {
  const appContext = useContext(AppContext)
  return appContext
}

const useSession = () => {
  const [user, setUser] = useState<User | null>(null)
  const [loading, setLoading] = useState(true)
  const {sessionObserver} = useFirebase()

  useEffect(() => {
    const unsubscribe = sessionObserver((authState) => {
      setUser(() => {
        setLoading(false)
        return authState
      })
    })

    return () => {
      unsubscribe()
    }
    // eslint-disable-next-line
  }, [])

  return {
    user,
    loading
  }
}

export {useFirebase, useSession, useAppContext}
