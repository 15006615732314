import React, {useEffect} from 'react'
import styles from './ModalComponent.module.scss'
import cn from 'classnames'

interface ModalComponentProps {
  show: boolean
  handleClose: () => void
  children: React.ReactNode
  disableOverlayClick?: boolean
}

const ModalComponent = ({
  show,
  handleClose,
  children,
  disableOverlayClick
}: ModalComponentProps) => {
  useEffect(() => {
    document.addEventListener('keydown', handleEscapeClick, false)
    return () => {
      document.removeEventListener('keydown', handleEscapeClick, false)
    }
  }, [])

  const handleEscapeClick = (event: any) => {
    if (event.keyCode === 27) handleClose()
  }

  const handleOverlayClick = () => {
    if (disableOverlayClick) return
    handleClose()
  }

  return (
    <div
      className={cn(
        styles['modal-container'],
        styles.center,
        show && styles.show
      )}
    >
      <div className={styles['modal-overlay']} onClick={handleOverlayClick} />
      <div className={styles['modal-wrapper']}>{children}</div>
    </div>
  )
}

export default ModalComponent
