import classNames from 'classnames'
import {SalesTaxDocument} from 'common'
import AlgoliaClient, {
  SALES_TAX_ADMIN_PANEL_ALGOLIA_INDEX
} from 'components/AlgoliaSearch'
import Button from 'components/Button'
import InputField from 'components/InputField'
import ModalComponent from 'components/ModalComponent'
import Pagination from 'components/Pagination'
import {TABLE_PAGINATION_PAGE_SIZE} from 'components/constants'
import {
  getFormattedDate,
  searchWithAlgolia,
  useSortableData
} from 'components/utils'
import {Timestamp} from 'firebase/firestore'
import {debounce} from 'lodash'
import React, {useEffect, useRef, useState} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {CheckedIcon, UncheckedIconTwo} from '../../assets/images'
import Layout from '../Layout'
import {Loader} from '../Loader'
import NavBar from '../Navbar'
import withAuthorization from '../Session/withAuthorization'
import Table from '../TableComponent'
import GenerateSalesTaxModal from './GenerateSalesTaxModal'
import styles from './SalesTax.module.scss'

interface SalesTaxProps {
  customClass: string
}
const SalesTax = ({customClass}: SalesTaxProps) => {
  const {state} = useLocation()
  const navigate = useNavigate()
  const pageFromLocation = state?.page
  const [currentPage, setCurrentPage] = useState<number>(pageFromLocation || 1)
  const [pageCount, setPageCount] = useState<number>(0)
  const [loader, setLoader] = useState(true)
  const [show, setShow] = useState<boolean>(false)
  const [saleTaxesData, setSaleTaxesData] = useState<Array<SalesTaxDocument>>(
    []
  )

  const toggleShow = () => setShow(!show)

  useEffect(() => {
    AlgoliaClient.clearCache()
    searchWithAlgolia<SalesTaxDocument>({
      searchQuery: '*',
      searchIndex,
      setLoaderState: setLoader,
      setData: setSaleTaxesData,
      setPageCount,
      currentPage
    }).then(() => setLoader(false))
  }, [])

  const getRowData = (rowData: Array<SalesTaxDocument>) => {
    return (rowData || []).map((singleRowData, index: number) => {
      const {salesTaxId, generalInfo, salesTaxInfo} = singleRowData

      return (
        <tr
          key={`role-management-row-data-${index}`}
          onClick={() => {
            navigate('/sales-tax/single-sales-tax-detail', {
              state: {
                data: singleRowData,
                currentPage
              },
              preventScrollReset: true
            })
          }}
        >
          <td>{salesTaxId || '-'}</td>
          <td>{generalInfo?.productTitle || '-'}</td>
          <td>{generalInfo?.invoiceId || '-'}</td>
          <td>
            <img
              src={salesTaxInfo?.hasNexus ? CheckedIcon : UncheckedIconTwo}
              alt="active"
            />
          </td>
          <td>{salesTaxInfo?.amountToCollect || '-'}</td>
          <td>{salesTaxInfo?.rate || '-'}</td>
          <td>{salesTaxInfo?.taxableAmount || '-'}</td>
          <td>
            {getFormattedDate(generalInfo?.createdAt || Timestamp.now()) || '-'}
          </td>
        </tr>
      )
    })
  }

  const scrollRef: React.RefObject<HTMLDivElement> = React.createRef()

  const scrollToRef = () =>
    scrollRef?.current?.scrollIntoView({behavior: 'smooth'})

  const formattedItems =
    saleTaxesData.map((data) => ({
      ...data,
      date: data.generalInfo?.createdAt || 0
    })) || saleTaxesData
  const {items, sortBy} = useSortableData(formattedItems)

  const showPagination = pageCount > TABLE_PAGINATION_PAGE_SIZE

  const onPageChange = (page: number) => {
    setLoader(true)
    setCurrentPage(page)
    debouncedSearch?.current({
      searchQuery: searchValue,
      searchIndex,
      setLoaderState: setLoader,
      setData: setSaleTaxesData,
      setPageCount,
      currentPage: page
    })
    setLoader(false)
    scrollToRef()
  }

  const tableTitles = [
    {title: 'Sale Tax ID', sortBy: () => sortBy('saleTaxId')},
    {title: 'Equipment Title', sortBy: () => sortBy('productTitle')},
    {title: 'Invoice ID', sortBy: () => sortBy('invoiceId')},
    {title: 'Has Nexus'},
    {title: 'Amount to Collect', sortBy: () => sortBy('amountToCollect')},
    {title: 'Rate', sortBy: () => sortBy('rate')},
    {title: 'Taxable Amount', sortBy: () => sortBy('taxableAmount')},
    {title: 'Date', sortBy: () => sortBy('date')}
  ]

  const [searchValue, setSearchValue] = useState('')
  const searchIndex = AlgoliaClient.initIndex(
    SALES_TAX_ADMIN_PANEL_ALGOLIA_INDEX
  )
  const debouncedSearch = useRef(
    debounce(searchWithAlgolia<SalesTaxDocument>, 800)
  )

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event?.target?.value
    setSearchValue(value)
    if (value) {
      debouncedSearch?.current({
        searchQuery: value,
        searchIndex,
        setLoaderState: setLoader,
        setData: setSaleTaxesData,
        setPageCount
      })
    } else {
      debouncedSearch?.current({
        searchQuery: '*',
        searchIndex,
        setLoaderState: setLoader,
        setData: setSaleTaxesData,
        setPageCount,
        currentPage
      })
    }
  }
  return (
    <div className={styles.withLayout}>
      <Layout />
      <div className={classNames(styles.container, customClass)}>
        <NavBar title="Sales Tax" />
        <div className={styles.button_filters}>
          <InputField
            name="searchField"
            type="text"
            value={searchValue}
            onChange={handleSearch}
            customClass={styles.inputField}
            placeholder="Search for Filters"
            isSearch={true}
          />
          <Button
            customClass={styles.create_account_btn}
            text="Generate Sales Tax"
            onClick={toggleShow}
          />
        </div>
        {loader ? (
          <Loader />
        ) : (
          <React.Fragment>
            <Table
              customClass={styles.threedetails}
              titles={tableTitles}
              rowData={getRowData(items)}
            />
            {showPagination && (
              <div className="pagination__wrapper">
                <Pagination
                  currentPage={currentPage}
                  totalCount={pageCount}
                  pageSize={TABLE_PAGINATION_PAGE_SIZE}
                  onPageChange={onPageChange}
                />
              </div>
            )}
            {show && (
              <ModalComponent handleClose={toggleShow} show={show}>
                <GenerateSalesTaxModal handleClose={toggleShow} />
              </ModalComponent>
            )}
          </React.Fragment>
        )}
      </div>
    </div>
  )
}

export default withAuthorization(SalesTax)
