import {ProductDocument} from 'common'
import {equipmentOptions, productStatus} from 'common/enums'
import Pagination from 'components/Pagination'
import ReqNotFoundScreen from 'components/ReqNotFoundScreen'
import {TABLE_PAGINATION_PAGE_SIZE} from 'components/constants'
import {debounce, isDate, isNumber} from 'lodash'
import React, {useEffect, useRef, useState} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {CheckedIcon, UncheckedIconTwo} from '../../assets/images'
import AlgoliaClient, {
  PRODUCTS_ADMIN_PANEL_ALGOLIA_INDEX
} from '../AlgoliaSearch'
import Button from '../Button'
import {useAppContext, useFirebase} from '../Firebase/hooks'
import InputField from '../InputField'
import Layout from '../Layout'
import {Loader} from '../Loader'
import NavBar from '../Navbar'
import withAuthorization from '../Session/withAuthorization'
import Table from '../TableComponent'
import {
  convertToDate,
  getFormattedDate,
  searchWithAlgolia,
  useSortableData
} from '../utils'
import styles from './ProductPage.module.scss'

interface IFormattedProductRowData extends ProductDocument {
  productID: string
  productName: string
  isActive: boolean
  accountName: string
  commissionPercentage: number
  productDate: string
  documentId: string
}

const ProductPage = ({customClass}: {customClass: string}) => {
  const {state} = useLocation()
  const pageFromLocation = state?.page || 1
  const {
    appState: {isMSAAdmin, isSuperAdmin, isSuperManager}
  } = useAppContext()
  const [currentPage, setCurrentPage] = useState<number>(pageFromLocation ?? 1)
  const [productData, setProductsData] = useState<Array<ProductDocument>>([])
  const [loader, setLoader] = useState(true)
  const {getUserAccounts} = useFirebase()
  const navigate = useNavigate()
  const [searchValue, setSearchValue] = useState('')
  const searchIndex = AlgoliaClient.initIndex(
    PRODUCTS_ADMIN_PANEL_ALGOLIA_INDEX
  )
  const [accountList, setAccountList] = useState<Array<string>>([])
  const [pageCount, setPageCount] = useState<number>(0)

  const getMSAAccountsList = async () => {
    const userAccounts = await getUserAccounts()
    const accountIDLength = userAccounts.length
    if (accountIDLength) {
      !accountList.length && setAccountList([...userAccounts])
    }
  }

  useEffect(() => {
    if (isMSAAdmin) {
      getMSAAccountsList()
    }
  }, [])

  useEffect(() => {
    AlgoliaClient.clearCache()
    if (isMSAAdmin && accountList.length) {
      searchWithAlgolia<ProductDocument>({
        searchQuery: '*',
        searchIndex,
        setLoaderState: setLoader,
        setData: setProductsData,
        setPageCount,
        accountList,
        currentPage
      }).then(() => setLoader(false))
      return
    }
    if (isSuperAdmin || isSuperManager) {
      searchWithAlgolia<ProductDocument>({
        searchQuery: '*',
        searchIndex,
        setLoaderState: setLoader,
        setData: setProductsData,
        setPageCount,
        accountList,
        currentPage
      }).then(() => setLoader(false))
    }
  }, [accountList?.length])

  const getFormattedData = (
    productData: ProductDocument & {objectID?: string}
  ) => {
    const objectID = productData?.objectID || ''
    const productID = productData?.productId || ''
    const productName = productData?.title || ''
    const isActive = productData?.status === productStatus.Approved || false
    const accountName = productData?.accountName || ''
    const commissionPercentage = productData?.commissionPercentage || ''
    const createdAtDate = productData?.createdAt
      ? convertToDate(productData?.createdAt)
      : ''
    const productDate = isDate(createdAtDate)
      ? getFormattedDate(createdAtDate)
      : '-'
    const documentId = productData?.productId || ''
    return {
      ...productData,
      productID,
      productName,
      objectID,
      isActive,
      accountName,
      commissionPercentage,
      createdAt: productData?.createdAt || 0,
      productDate,
      documentId
    }
  }
  const redirectToEditProductScreen = (
    product: ProductDocument & {objectID?: string},
    productDocId: string
  ) => {
    if (product?.productDetails) {
      const {
        productDetails,
        equipmentHours,
        equipmentMileage,
        equipmentVinNumber,
        serialNumber
      } = product

      const firstChoiceUnknown =
        product.productDetails.firstChoice === equipmentOptions.Unknown
      productDetails.firstChoice = isNumber(equipmentHours)
        ? equipmentOptions.EquipmentHours
        : isNumber(equipmentMileage)
        ? equipmentOptions.EquipmentMileage
        : firstChoiceUnknown
        ? equipmentOptions.Unknown
        : 'Equipment Hours or Mileage'

      const secondChoiceUnknown =
        product.productDetails.secondChoice === equipmentOptions.Unknown
      productDetails.secondChoice = equipmentVinNumber
        ? equipmentOptions.EquipmentVINNumber
        : serialNumber
        ? equipmentOptions.SerialNumber
        : secondChoiceUnknown
        ? equipmentOptions.Unknown
        : 'Serial Number or VIN'
    }

    navigate('/equipment/single-equipment-detail', {
      state: {
        id: productDocId,
        isEdit: true,
        objectID: product?.objectID,
        productData: {...product},
        currentPage
      },
      preventScrollReset: true
    })
  }

  const getRowData = (
    rowData: Array<IFormattedProductRowData & {objectID?: string}>
  ) => {
    return (rowData || []).map((singleRowData, index) => {
      const {
        productID,
        productName,
        isActive,
        productDate,
        documentId,
        ...restProductValues
      } = singleRowData
      const {accountName, commissionPercentage} = restProductValues
      return (
        <tr
          key={`product-row-data-${index}`}
          onClick={() => {
            redirectToEditProductScreen(restProductValues, documentId)
          }}
        >
          <td>{productID || '-'}</td>
          <td>{productName || '-'}</td>
          <td>{accountName || '-'}</td>
          <td>{commissionPercentage || '-'}</td>
          <td>
            <img src={isActive ? CheckedIcon : UncheckedIconTwo} alt="active" />
          </td>
          <td>
            <img
              src={
                restProductValues?.listingAgreementSigned
                  ? CheckedIcon
                  : UncheckedIconTwo
              }
              alt="active"
            />
          </td>
          <td>{productDate || '-'}</td>
        </tr>
      )
    })
  }

  const debouncedSearch = useRef(
    debounce(searchWithAlgolia<ProductDocument>, 800)
  )

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (isMSAAdmin && !accountList?.length) return
    const value = event?.target?.value
    setSearchValue(value)
    if (value) {
      setCurrentPage(1)
      debouncedSearch?.current({
        searchQuery: value,
        searchIndex,
        setLoaderState: setLoader,
        setData: setProductsData,
        setPageCount,
        accountList
      })
    } else {
      debouncedSearch?.current({
        searchQuery: '*',
        searchIndex,
        setLoaderState: setLoader,
        setData: setProductsData,
        setPageCount,
        accountList,
        currentPage
      })
    }
  }

  const noData = !productData.length && !loader
  const formattedProductData = productData.map((data) => getFormattedData(data))
  const scrollRef: React.RefObject<HTMLDivElement> = React.createRef()
  const showPagination = pageCount > TABLE_PAGINATION_PAGE_SIZE
  const {items, sortBy} = useSortableData(formattedProductData)

  const scrollToRef = () =>
    scrollRef?.current?.scrollIntoView({behavior: 'smooth'})

  const onPageChange = (page: number) => {
    setLoader(true)
    setCurrentPage(page)
    debouncedSearch?.current({
      searchQuery: searchValue,
      searchIndex,
      setLoaderState: setLoader,
      setData: setProductsData,
      setPageCount,
      accountList,
      currentPage: page
    })
    setLoader(false)
    scrollToRef()
  }

  const tableTitles = [
    {title: 'Equipment ID'},
    {title: 'Equipment Name', sortBy: () => sortBy('productName')},
    {title: 'Account Name', sortBy: () => sortBy('accountName')},
    {
      title: 'Commision Percentage',
      sortBy: () => sortBy('commissionPercentage')
    },
    {title: 'Equipment Active'},
    {title: 'Agreement Signed'},
    {title: 'Created At', sortBy: () => sortBy('createdAt')}
  ]

  return (
    <div className={styles.withLayout}>
      <Layout />
      <div className={[styles.container, customClass].join(' ')}>
        <NavBar title="Equipment" />
        <div className={styles.button_filters}>
          <InputField
            name="productSearchField"
            type="text"
            value={searchValue}
            onChange={handleSearch}
            customClass={styles.inputField}
            placeholder="Search for Filters"
            isSearch={true}
          />
          <Button
            customClass={styles.create_product_btn}
            text="Create Equipment"
            onClick={() => navigate('/equipment/single-equipment-detail')}
          />
        </div>
        {loader ? (
          <Loader />
        ) : noData ? (
          <ReqNotFoundScreen />
        ) : (
          <React.Fragment>
            <div>
              <Table titles={tableTitles} rowData={getRowData(items)} />
            </div>
            {showPagination && (
              <div className="pagination__wrapper">
                <Pagination
                  currentPage={currentPage}
                  totalCount={pageCount}
                  pageSize={TABLE_PAGINATION_PAGE_SIZE}
                  onPageChange={onPageChange}
                />
              </div>
            )}
          </React.Fragment>
        )}
      </div>
    </div>
  )
}

export default withAuthorization(ProductPage)
