import React from 'react'
import {adminRole} from 'common/enums'
import {IFieldSetSingleField, IFormattedAdmin} from 'components/types'
import {FieldSet} from '../DetailFields'
import styles from '../DetailPage.module.scss'

const AdminDetails = (data: IFormattedAdmin) => {
  const isMSA = data?.role === adminRole.MSA || false
  const accountsListField: IFieldSetSingleField = isMSA
    ? {
        label: 'Accounts List',
        dropDown: {
          isDropdDown: true,
          route: '/accounts/single-account-details',
          options: data?.accountsList || []
        }
      }
    : {label: ''}

  const firstSet: Array<IFieldSetSingleField> = [
    {
      label: 'First Name',
      value: data?.firstName
    },
    {
      label: 'Last Name',
      value: data?.lastName
    },
    {
      label: 'Mobile Number',
      value: data?.mobileNumber
    },
    accountsListField
  ]

  const secondSet: Array<IFieldSetSingleField> = [
    {
      label: 'Email Address',
      value: data?.emailAddress || '-'
    },
    {
      label: 'Admin Role',
      value:
        data?.role === 'superAdmin'
          ? 'Super Admin'
          : data?.role === 'superManager'
          ? 'Super Manager'
          : data?.role
    },
    {
      label: 'Is Active',
      value: data?.isActive ? 'Yes' : 'No'
    },
    {
      label: 'Is Verified',
      value: data?.isVerified ? 'Yes' : 'No'
    }
  ]

  return (
    <React.Fragment>
      <div className={styles.inputsfields}>
        <FieldSet title="Admin Details" fields={firstSet} />
      </div>
      <div style={{marginTop: '1.5rem'}} className={styles.inputsfields}>
        <FieldSet gap fields={secondSet} />
      </div>
    </React.Fragment>
  )
}

export default AdminDetails
