import {InvoiceDocument} from 'common'
import {
  AdminNotifications,
  BuyerNotifications,
  SellerNotifications,
  sendNotification
} from 'common/apis/notifications'
import {Collection} from 'common/enums'
import Button from 'components/Button'
import ConfirmModal from 'components/ConfirmModal'
import ModalComponent from 'components/ModalComponent'
import {markInvoiceAsClosed, reOpenInvoice} from 'components/apis/invoice'
import {API_HOST_URL} from 'components/constants'
import {DetailType} from 'components/enums'
import React, {useEffect, useState} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {toast} from 'react-toastify'
import {ArrowDown} from '../../assets/svgs'
import DetailPage from '../DetailPage'
import {useAppContext, useFirebase} from '../Firebase/hooks'
import Layout from '../Layout'
import {Loader} from '../Loader'
import NavBar from '../Navbar'
import withAuthorization from '../Session/withAuthorization'
import styles from './InvoiceDetailPage.module.scss'

interface InvoiceDetailPageProps {
  customClass: string
}

enum invoiceModalType {
  OPEN = 'open',
  CLOSE = 'close'
}

const InvoiceDetailPage = ({customClass}: InvoiceDetailPageProps) => {
  const navigate = useNavigate()
  const {state} = useLocation()
  const {getDocumentData} = useFirebase()
  const invoiceId = state?.id ? state?.id : ''
  const isEdit = state?.isEdit ? state?.isEdit : false
  const previousPage = state?.currentPage
  const [loader, setLoader] = useState(false)
  const [disableMarkAsClosed, setDisableMarkAsClosed] = useState(false)
  const [{show: showInvoiceToggleModal, modalType}, setShowInvoiceToggleModal] =
    useState<{show: boolean; modalType: invoiceModalType | undefined}>({
      show: false,
      modalType: undefined
    })
  const {
    appState: {isSuperAdmin}
  } = useAppContext()
  const [applicationState, setApplicationState] = useState<{
    documentData: InvoiceDocument | null
  }>({
    documentData: null
  })
  const invoiceMarkedAsClosed =
    applicationState.documentData?.invoiceMarkedAsClosed

  const toggleInvoiceOpenCloseModal = (modalType?: invoiceModalType) => {
    setShowInvoiceToggleModal((prev) =>
      modalType
        ? {
            modalType,
            show: !showInvoiceToggleModal
          }
        : {
            modalType: prev.modalType,
            show: !showInvoiceToggleModal
          }
    )
  }

  const cancelCallback = (error: Error) => {
    if (error) {
      toast.error(`Something went wrong while fetching invoice data.`)
    }
  }

  useEffect(() => {
    if (!invoiceId) return
    const {unsSubData} = getDocumentData(
      setApplicationState,
      cancelCallback,
      invoiceId,
      Collection.Invoices
    )
    return () => unsSubData?.()
  }, [invoiceId])

  useEffect(() => {
    if (isEdit) {
      setTimeout(() => {
        setLoader(false)
      }, 2000)
    }
  }, [])

  const handleMarkInvoiceAsClosed = async () => {
    if (!applicationState.documentData) return
    if (!applicationState.documentData.sellerWireConfirmation?.length) {
      toast.warn(
        'Cannot mark invoice as closed without any seller wire confirmations.'
      )
      return
    }
    setDisableMarkAsClosed(true)
    const {
      data: {data, code}
    } = await markInvoiceAsClosed({invoiceId: invoiceId})
    if (code !== 200) {
      const errorMsg = data.msg
      toast.error(
        errorMsg || 'Something went wrong while marking invoice as closed'
      )
      setDisableMarkAsClosed(false)
      return
    }
    sendNotification({
      baseURL: API_HOST_URL,
      code: SellerNotifications.SellerPaid,
      productId: applicationState.documentData.productId,
      contactId: applicationState.documentData.sellerContactId
    })
    sendNotification({
      baseURL: API_HOST_URL,
      code: AdminNotifications.SellerPaid,
      productId: applicationState.documentData.productId
    })
    sendNotification({
      baseURL: API_HOST_URL,
      code: AdminNotifications.InvoicePaid,
      productId: applicationState.documentData.productId
    })
    sendNotification({
      baseURL: API_HOST_URL,
      code: BuyerNotifications.AdminAcknowledgedWire,
      contactId: applicationState.documentData.buyerContactId,
      productId: applicationState.documentData.productId
    })
    toggleInvoiceOpenCloseModal()
    setDisableMarkAsClosed(false)
    toast.success('Invoice marked as closed successfully.')
  }

  const handleReOpenInvoice = async () => {
    setDisableMarkAsClosed(true)
    const {
      data: {data, code}
    } = await reOpenInvoice({invoiceId: invoiceId})
    if (code !== 200) {
      const errorMsg = data.msg
      toast.error(errorMsg || 'Something went wrong while re-opening invoice')
      setDisableMarkAsClosed(false)
      return
    }
    setDisableMarkAsClosed(false)
    toggleInvoiceOpenCloseModal()
    toast.success('Invoice re-opened successfully.')
  }

  return (
    <div>
      <div className={styles.withLayout}>
        <Layout />
        <div className={[styles.container, customClass].join(' ')}>
          <NavBar title={'Invoice Details'} />
          {loader ? (
            <Loader />
          ) : (
            <React.Fragment>
              <div className={styles.buttons}>
                <span
                  onClick={() =>
                    navigate('/invoices', {
                      state: {page: previousPage}
                    })
                  }
                >
                  <ArrowDown />
                  Back
                </span>

                {invoiceMarkedAsClosed === false && isSuperAdmin ? (
                  <Button
                    customClass={styles.markAsClosedBtn}
                    text="Mark Invoice as Closed"
                    onClick={() =>
                      toggleInvoiceOpenCloseModal(invoiceModalType.OPEN)
                    }
                  />
                ) : invoiceMarkedAsClosed === true && isSuperAdmin ? (
                  <Button
                    customClass={styles.markAsClosedBtn}
                    text="Re-Open Invoice"
                    onClick={() =>
                      toggleInvoiceOpenCloseModal(invoiceModalType.CLOSE)
                    }
                  />
                ) : null}
              </div>

              <div className={styles.edit_section}>
                <DetailPage
                  data={applicationState.documentData}
                  detailType={DetailType.Invoice}
                />
              </div>
              {showInvoiceToggleModal && isSuperAdmin && (
                <ModalComponent
                  show={showInvoiceToggleModal}
                  handleClose={toggleInvoiceOpenCloseModal}
                >
                  <ConfirmModal
                    content={
                      modalType === invoiceModalType.CLOSE
                        ? 'Are you sure you want to re-open this invoice'
                        : 'Are you sure you want to mark this invoice as closed'
                    }
                    text={
                      modalType === invoiceModalType.CLOSE
                        ? 'Re-open Invoice'
                        : 'Mark Invoice as Closed'
                    }
                    handleClose={toggleInvoiceOpenCloseModal}
                    handleConfirm={
                      modalType === invoiceModalType.CLOSE
                        ? handleReOpenInvoice
                        : handleMarkInvoiceAsClosed
                    }
                    confirmBtnDisabled={disableMarkAsClosed}
                  />
                </ModalComponent>
              )}
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  )
}

export default withAuthorization(InvoiceDetailPage)
