import {Bid} from 'common'
import React, {useMemo, useState} from 'react'
import styles from './ProductBidsModal.module.scss'
import {
  getRelativeTime,
  getTimeStampFromObject,
  useWindowSize,
  withThousandSeperatorString
} from 'components/utils'
import {Cancel} from 'assets/svgs'
import UserProfilePicture from 'components/UserProfileImage'
import {UserPlaceholder} from 'assets/images'
import Pagination from 'components/Pagination'

interface ProductBidsModalProps {
  handleClose: () => void
  bids: Array<Bid>
}

const ProductBidsModal = ({handleClose, bids}: ProductBidsModalProps) => {
  const {height, width} = useWindowSize()
  const isDesktop = width && width > 481 ? true : false
  const PAGINATION_PAGE_SIZE =
    (height && (height < 700 ? 2 : height < 950 ? 3 : 4)) || 3
  const [currentPage, setCurrentPage] = useState<number>(1)
  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PAGINATION_PAGE_SIZE
    const lastPageIndex = firstPageIndex + PAGINATION_PAGE_SIZE
    return bids.slice(firstPageIndex, lastPageIndex)
  }, [PAGINATION_PAGE_SIZE, currentPage, bids])

  const showPagination = bids.length > PAGINATION_PAGE_SIZE

  return (
    <React.Fragment>
      <div className={styles.heading}>
        <Cancel onClick={handleClose} />
      </div>
      <div className={styles.container}>
        <div className={styles.bid_container}>
          {isDesktop ? (
            <React.Fragment>
              <div className={styles.card_header}>
                <div>Buyer Name</div>
                <div>Bid</div>
                <div>Time</div>
              </div>
              <div className={styles.card_container}>
                {currentTableData.map((item, index) => {
                  const bidAmount = `${withThousandSeperatorString(
                    `${item?.bidAmount}`
                  )}`
                  const bidTime = getRelativeTime(
                    getTimeStampFromObject(item?.createdAt).toDate()
                  )
                  return (
                    <div className={styles.card} key={`bid-user-${index}`}>
                      <div>
                        <UserProfilePicture
                          width={40}
                          height={40}
                          alt="user-avatar"
                          userId={item?.buyerId}
                          className={styles.card_img}
                        />
                        <span>{item?.buyerName?.[0]}****</span>
                      </div>
                      <div>{bidAmount}</div>
                      <div>{bidTime}</div>
                    </div>
                  )
                })}
              </div>
            </React.Fragment>
          ) : (
            <React.Fragment>
              {currentTableData.map((item, index) => {
                const bidAmount = `${withThousandSeperatorString(
                  `${item?.bidAmount}`
                )}`
                const bidTime = getRelativeTime(
                  getTimeStampFromObject(item?.createdAt).toDate()
                )
                return (
                  <div
                    className={styles.mobile_card}
                    key={`bid-user-${index}--mobile`}
                  >
                    <div>
                      <div className={styles.mobile_card_header}>
                        Buyer Name
                      </div>
                      <div className={styles.mobile_card_buyer}>
                        <img
                          width={40}
                          height={40}
                          className={styles.card_img}
                          alt="user-avatar"
                          src={item?.buyerPhotoUrl || UserPlaceholder}
                        />
                        <span>{item?.buyerName[0]}****</span>
                      </div>
                    </div>
                    <div>
                      <span className={styles.mobile_card_header}>Bid:</span>{' '}
                      {bidAmount}
                    </div>
                    <div>
                      <span className={styles.mobile_card_header}>Time:</span>{' '}
                      {bidTime}
                    </div>
                  </div>
                )
              })}
            </React.Fragment>
          )}
        </div>
        {showPagination && (
          <div className={styles['pagination__wrapper']}>
            <Pagination
              currentPage={currentPage}
              totalCount={bids.length}
              pageSize={PAGINATION_PAGE_SIZE}
              arrowsForNextAndPrev={false}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        )}
      </div>
    </React.Fragment>
  )
}

export default ProductBidsModal
