import {Subcategory} from 'common'
import Button from 'components/Button'
import {useAppContext, useFirebase} from 'components/Firebase/hooks'
import {Loader} from 'components/Loader'
import Pagination from 'components/Pagination'
import Table from 'components/TableComponent'
import {TABLE_PAGINATION_PAGE_SIZE} from 'components/constants'
import {useSortableData} from 'components/utils'
import React, {useEffect, useMemo, useState} from 'react'
import {toast} from 'react-toastify'
import styles from './SubCategoriesPage.module.scss'
import RowData from './RowData'
import SubcategoryModal from '../SubcategoryModal'

const SubCategoriesPage = ({categoryId}: {categoryId: string}) => {
  const [subcategories, setSubcategories] = useState<Array<Subcategory>>([])
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [loader, setLoader] = useState(true)
  const {getCategoriesList} = useFirebase()
  const [showAddModal, setShowAddModal] = useState(false)
  const handleShowAddModal = () => setShowAddModal(true)
  const handleCloseAddModal = () => setShowAddModal(false)
  const {
    appState: {isSuperManager}
  } = useAppContext()

  const cancelCallback = (error: Error) => {
    if (error) {
      toast.error(`Something went wrong while fetching categories.`)
    }
  }

  useEffect(() => {
    const unSubscribe = getCategoriesList<Subcategory>({
      setCategories: setSubcategories,
      cancelCallback,
      categoryId
    })
    setTimeout(() => {
      setLoader(false)
    }, 1000)
    return () => {
      unSubscribe?.()
    }
  }, [])

  const getRowData = (rowData: Array<Subcategory>) => {
    return (rowData || []).map((singleRowData, index: number) => {
      return (
        <RowData
          rowData={singleRowData}
          categoryId={categoryId}
          subcategories={subcategories}
          isSuperManager={isSuperManager}
          setSubcategories={setSubcategories}
          key={`subcategory-row-data-${index}`}
        />
      )
    })
  }

  const scrollRef: React.RefObject<HTMLDivElement> = React.createRef()

  const scrollToRef = () =>
    scrollRef?.current?.scrollIntoView({behavior: 'smooth'})

  const {items, sortBy} = useSortableData(subcategories)

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * TABLE_PAGINATION_PAGE_SIZE
    const lastPageIndex = firstPageIndex + TABLE_PAGINATION_PAGE_SIZE
    return items.slice(firstPageIndex, lastPageIndex)
  }, [currentPage, items])

  const showPagination = subcategories?.length > TABLE_PAGINATION_PAGE_SIZE

  const onPageChange = (page: number) => {
    setCurrentPage(page)
    scrollToRef()
  }

  const tableTitles = [
    {title: 'Subcategory Id', sortBy: () => sortBy('nodeId')},
    {title: 'Subcategory', sortBy: () => sortBy('name')},
    {title: 'Image'}
  ]

  return (
    <React.Fragment>
      <div className={styles.button_filters}>
        {!isSuperManager && (
          <Button
            text="Add Subcategory"
            onClick={handleShowAddModal}
            customClass={styles.create_account_btn}
          />
        )}
      </div>
      {loader ? (
        <Loader fullHeight={false} />
      ) : (
        <React.Fragment>
          <Table
            titles={tableTitles}
            customClass={styles.table_container}
            rowData={getRowData(currentTableData)}
          />
          {showPagination && (
            <div className="pagination__wrapper">
              <Pagination
                currentPage={currentPage}
                onPageChange={onPageChange}
                totalCount={subcategories?.length}
                pageSize={TABLE_PAGINATION_PAGE_SIZE}
              />
            </div>
          )}
        </React.Fragment>
      )}
      {showAddModal && (
        <SubcategoryModal
          setSubcategories={setSubcategories}
          handleClose={handleCloseAddModal}
          subcategories={subcategories}
          categoryId={categoryId}
          show={showAddModal}
          isEdit={false}
        />
      )}
    </React.Fragment>
  )
}

export default SubCategoriesPage
