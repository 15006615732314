import React, {useState} from 'react'
import styles from './CreateTaxJarCustomerModal.module.scss'
import {toast} from 'react-toastify'
import DropDownSelect from 'components/DropDown'
import {Loader} from 'components/Loader'
import {Cancel} from 'assets/svgs'
import Button from 'components/Button'
import {useFirebase} from 'components/Firebase/hooks'
import {AccountDocument} from 'common'
import {Collection} from 'common/enums'
import {createTaxJarCustomer} from 'components/apis/taxjar'

interface CreateTaxJarCustomerModalProps {
  handleClose: () => void
  runRefetch: () => void
}

const CreateTaxJarCustomerModal = ({
  handleClose,
  runRefetch
}: CreateTaxJarCustomerModalProps) => {
  const {getCollectionBasedOnQuery} = useFirebase()
  const [loader, setLoader] = useState<boolean>(false)
  const [disabled, setDisabled] = useState(false)
  const [selectedAccount, setSelectedAccount] = useState<{
    id: string
    name: string
  }>()
  const [accountData, setAccountData] = useState<Array<AccountDocument>>([])
  const optionsList = accountData.map((acc) => ({
    id: acc.accountId,
    name: acc.accountName
  }))

  const getAccountData = async () => {
    loader === false && setLoader(true)
    const {data = [], error} = await getCollectionBasedOnQuery<AccountDocument>(
      Collection.Accounts,
      'isOnTaxJar',
      false
    )
    if (error) {
      toast.warn('Something went wrong while fetching accounts.')
      setLoader(false)
      return
    }
    setAccountData([...data])
    setLoader(false)
  }

  React.useEffect(() => {
    getAccountData()
  }, [])

  const handleSubmit = async () => {
    if (!selectedAccount) {
      toast.warn('Please select an account.')
      return
    }
    setDisabled(true)
    const {
      data: {data, code, msg}
    } = await createTaxJarCustomer({accountId: selectedAccount?.id})
    if (code !== 201) {
      toast.warn(
        data.msg || 'Failed to create TaxJar Customer against this account.'
      )
      setDisabled(false)
    }
    if (code === 201) {
      toast.success(msg)
      handleClose()
      runRefetch()
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.cancelContainer}>
        <Cancel className={styles.cancel} onClick={handleClose} />
      </div>
      <div className={styles.content}>
        {loader ? (
          <Loader fullHeight={false} />
        ) : (
          <div className={styles.modalContent}>
            <div className={styles.headingContainer}>Select an Account</div>
            <div className={styles.dropdownContainer}>
              <DropDownSelect
                options={optionsList}
                customClass={styles.dropdowninput}
                placeholder="Select Account"
                value={optionsList.findIndex(
                  (option) => option.id === selectedAccount?.id
                )}
                cancelClickFunction={() => {
                  setSelectedAccount(undefined)
                }}
                onChange={(index) => {
                  const currentOption = optionsList[index]
                  setSelectedAccount(currentOption)
                }}
                name="accountSelect"
              />
            </div>
            <div className={styles.buttonsComponent}>
              <Button
                customClass={styles.buttonClass}
                text="Submit"
                disabled={disabled}
                onClick={handleSubmit}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default CreateTaxJarCustomerModal
