import {Cancel} from 'assets/svgs'
import classNames from 'classnames'
import {FILE_OBJECT} from 'common'
import {getOGPreviewData} from 'components/utils'
import React, {useState} from 'react'
import {toast} from 'react-toastify'
import ModalComponent from '..'
import buttonStyles from '../../ContentModal/ContentModal.module.scss'
import styles from './YouTubeEmbedModal.module.scss'
import Button from 'components/Button'

interface YouTubeEmbedModalProps {
  handleClose: () => void
  show: boolean
  addFileToArr: React.Dispatch<FILE_OBJECT>
}

const YouTubeEmbedModal: React.FC<YouTubeEmbedModalProps> = ({
  handleClose,
  show,
  addFileToArr
}) => {
  const [videoLink, setVideoLink] = useState('')
  const [videoId, setVideoId] = useState<string | null>(null)
  const [title, setTitle] = useState<string>('')
  const [disabled, setDisabled] = useState(false)

  const onConfirm = () => {
    if (!videoId) {
      toast.warn('Please enter a video link to embed video')
      return
    }
    const videoEmbed = `https://www.youtube.com/embed/${videoId}`
    addFileToArr({
      URL: videoEmbed,
      name: title,
      type: 'video',
      uploadType: 'embed'
    })
  }

  const handleSubmit = () => {
    if (!videoLink) {
      toast.warn('Please enter a video link to embed video')
      return
    }
    setDisabled(true)
    const youtubeRegex =
      /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/(watch\?v=)?([a-zA-Z0-9_-]+)/
    const match = videoLink.match(youtubeRegex)
    if (match) {
      const extractedVideoId = match[5]
      setVideoId(extractedVideoId)
      getOGPreviewData(videoLink).then((res) => {
        const title = res?.ogTitle
        if (!title) {
          toast.warn('Please enter a valid Youtube video link')
          setDisabled(false)
          setVideoId(null)
          return
        }
        setTitle(title)
        setDisabled(false)
      })
    } else {
      toast.warn('Please enter a valid Youtube video link')
      setDisabled(false)
      setVideoId(null)
    }
  }

  return (
    <ModalComponent handleClose={handleClose} show={show}>
      <div className={styles.modalWrapper}>
        <Cancel className={styles.cancel} onClick={handleClose} />
        <div className={styles.modalContent}>
          <h1>Embed YouTube Video</h1>
          <p>Enter a YouTube video link below:</p>
          <input
            type="text"
            placeholder="https://www.youtube.com/watch?v=VIDEO_ID"
            value={videoLink}
            onChange={(e) => setVideoLink(e.target.value)}
          />
          <button
            className="btn-fill-orange"
            type="button"
            onClick={handleSubmit}
          >
            Embed
          </button>
          {videoId && (
            <div className={styles.embedContainer}>
              <iframe
                src={`https://www.youtube.com/embed/${videoId}`}
                title="Embedded YouTube Video"
                allowFullScreen
              />
            </div>
          )}
        </div>
        <div className={classNames(buttonStyles.buttons, styles.buttons)}>
          <button className="btn-cancel" onClick={handleClose}>
            Cancel
          </button>
          <Button
            customBtnClass={classNames('btn-fill-orange', styles.modal_button)}
            disabled={disabled}
            onClick={onConfirm}
            text="Confirm"
            type={'button'}
          />
          {/* </Butt> */}
        </div>
      </div>
    </ModalComponent>
  )
}

export default YouTubeEmbedModal
