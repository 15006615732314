import React, {useState} from 'react'
import ContentModal from 'components/ContentModal'
import ModalComponent from 'components/ModalComponent'
import {useFirebase} from 'components/Firebase/hooks'
import {toast} from 'react-toastify'

const DeleteChatModal = ({
  show,
  chatId,
  dealId,
  handleReset,
  handleClose
}: {
  show: boolean
  chatId: string
  dealId: string
  handleClose: () => void
  handleReset: () => void
}) => {
  const [disabled, setDisabled] = useState(false)
  const {deleteUserChat} = useFirebase()

  const handleDeleteChat = async () => {
    setDisabled(true)
    const {error} = await deleteUserChat(chatId, dealId)
    if (error) {
      toast.error(`Something went wrong! ${error}`)
      setDisabled(false)
      return
    }
    handleClose()
    handleReset()
    setDisabled(false)
  }
  return (
    <ModalComponent handleClose={handleClose} show={show}>
      <ContentModal
        buttonDisabled={disabled}
        buttonOnClick={handleDeleteChat}
        handleClose={handleClose}
        buttonText="Yes, delete"
        heading="Are you sure?"
        content="Are you sure you want to delete this chat? Once deleted, the chat data will be irrecoverable."
      />
    </ModalComponent>
  )
}

export default DeleteChatModal
