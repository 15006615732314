import {createContext} from 'react'
import firebase from './firebase'

const FirebaseContext = createContext(firebase)

FirebaseContext.displayName = 'FirebaseContext'

const FirebaseProvider = FirebaseContext.Provider

export {FirebaseProvider, FirebaseContext}
