/* eslint-disable react/display-name */
import {useNavigate} from 'react-router-dom'
import {useEffect, useState} from 'react'
import {useSession, useFirebase, useAppContext} from '../Firebase/hooks'
import {Loader} from '../Loader'
import {routes} from 'components/Layout/constants'

const withAuthorization = (Component: any) => {
  const WithAuthorization = (props: any) => {
    const navigate = useNavigate()
    const pathName = window.location.pathname
    const {user, loading} = useSession()
    const {auth, checkCustomClaims} = useFirebase()
    const {appState, newAppState, removeFcmAndSignOut} = useAppContext()
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_isLoading, setLoading] = useState(true)
    const isAuthorized = appState.isAuthorized && !loading

    const handleRedirect = async () => {
      const {doesCustomClaimsExist, isSuperAdmin, isMSAAdmin, isSuperManager} =
        await checkCustomClaims()
      const userLoggedIn = user && !loading
      const userLoggedOut = !user && !loading
      if (userLoggedIn) {
        if (!doesCustomClaimsExist) {
          newAppState({
            isAuthorized: false
          })
          removeFcmAndSignOut()
          navigate('/sign-in')
        } else {
          newAppState({
            isAuthorized: true,
            isSuperManager,
            isSuperAdmin,
            isMSAAdmin
          })
        }
        const routesWithCheck = routes.map((route) => {
          const isAllowed =
            (route.isSuperAdmin && isSuperAdmin) ||
            (route.isSuperManager && isSuperManager) ||
            (route.isMSAAdmin && isMSAAdmin)
          return {isAllowed, path: route.path}
        })
        const isAllowed = routesWithCheck.find(
          (route) => route.path === pathName
        )?.isAllowed
        if (isAllowed === false) {
          navigate('/dashboard')
        }
      } else if (userLoggedOut) {
        newAppState({
          isAuthorized: false
        })
        removeFcmAndSignOut()
        navigate('/sign-in')
      }

      setTimeout(() => {
        setLoading(false)
      }, 2000)
    }

    useEffect(() => {
      handleRedirect()
      // eslint-disable-next-line
    }, [user, loading, auth])

    return isAuthorized ? <Component {...props} /> : <Loader />
  }
  return WithAuthorization
}

export default withAuthorization
