import classNames from 'classnames'
import {
  CheckedIcon,
  CommentIcon,
  UncheckedIcon
} from '../../../../assets/images'
import styles from '../ProductDetails.module.scss'
import TextArea from '../../../TextArea'
import RichTextEditor from 'components/RichTextEditor'
import {isNumber} from 'lodash'
import React from 'react'

interface FieldComponentProps {
  withCommentIcon?: boolean
  value?: any
  label?: string | JSX.Element
  setReqChangePopupVariables?: (prop: string) => void
  clickableTextOnClick?: () => void
  checkedField?: boolean
  clickableText?: string
  checkedCondition?: boolean
  requestedChangeKey?: string
  isTextArea?: boolean
  isRichText?: boolean
  isInputField?: boolean
  isLink?: boolean
}

const FieldComponent = ({
  withCommentIcon,
  value,
  label,
  setReqChangePopupVariables,
  checkedField,
  checkedCondition,
  isRichText,
  requestedChangeKey,
  isTextArea,
  clickableText,
  clickableTextOnClick,
  isLink
}: FieldComponentProps) => {
  return (
    <div className={styles.singleDetailBox}>
      {!isRichText && <div className={styles.labelDetail}>{label}</div>}
      <div
        style={isRichText ? {width: '100%'} : {}}
        className={classNames(styles.labelValue)}
      >
        {isTextArea ? (
          <TextArea
            value={value || '-'}
            disabled={true}
            customTextAreaClass={styles.descriptionBox}
            customDivClass={styles.descriptionContainer}
            noCounter={true}
          />
        ) : checkedField ? (
          <img
            src={checkedCondition ? CheckedIcon : UncheckedIcon}
            alt="default"
          />
        ) : isRichText ? (
          <RichTextEditor name="description" value={value} readOnly={true} />
        ) : isLink ? (
          (
            <a target="_blank" rel="noreferrer" href={value}>
              {value}
            </a>
          ) || '-'
        ) : value || isNumber(value) || clickableText ? (
          <React.Fragment>
            {value}{' '}
            {clickableText ? (
              <span
                className={styles.clickableText}
                onClick={clickableTextOnClick}
              >
                {clickableText}
              </span>
            ) : null}
          </React.Fragment>
        ) : (
          '-'
        )}
      </div>
      {withCommentIcon && (
        <div
          className={styles.commentIconContainer}
          onClick={() => {
            setReqChangePopupVariables &&
              requestedChangeKey &&
              setReqChangePopupVariables(requestedChangeKey)
          }}
        >
          <img src={CommentIcon} alt="comment" />
        </div>
      )}
    </div>
  )
}

export default FieldComponent
