import {Cancel_upload, PdfIcon, Sent} from 'assets/svgs'
import cn from 'classnames'
import {FILE_OBJECT, Message} from 'common'
import {useFirebase} from 'components/Firebase/hooks'
import {ChatsDocument} from 'components/types'
import {Timestamp} from 'firebase/firestore'
import React, {FormEvent, useState} from 'react'
import {toast} from 'react-toastify'
import AttachmentInput from '../AttachmentInput'
import {EmojiInput} from '../EmojiInput'
import styles from './MessageBar.module.scss'

export interface stateObjProps {
  isLoading: boolean
  filesArr: any
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
  setFilesArr: React.Dispatch<any>
}

const MessageBar = ({data}: {data: ChatsDocument}) => {
  const {sendChatMessage, deleteFile} = useFirebase()
  const [inputValue, setInputValue] = useState('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [removeDisabled, setRemoveDisabled] = useState(false)
  const [filesArr, setFilesArr] = useState<any>([])
  const {chatId, dealId} = data

  const stateObj: stateObjProps = {
    isLoading,
    filesArr,
    setIsLoading,
    setFilesArr
  }

  const handleRemove = async (file: FILE_OBJECT, index: number) => {
    if (removeDisabled) return
    setRemoveDisabled(true)
    await deleteFile({
      fileName: file?.name
    })
    removeFile(index)
    setRemoveDisabled(false)
  }

  const onMessageSend = async (e: FormEvent) => {
    if (!chatId || !dealId) return
    e.preventDefault()
    if (inputValue.length > 0 || filesArr.length > 0) {
      setInputValue('')
      setFilesArr([])
      const messsageObj: Message = {
        message: inputValue.trim(),
        timestamp: Timestamp.now(),
        hasSeen: false,
        serverSeen: false,
        attachment: [...filesArr],
        senderId: ''
      }
      const {error} = await sendChatMessage({
        message: messsageObj,
        chatId,
        dealId
      })
      if (error) {
        toast.error(`Something went wrong!`)
      }
    }
  }

  const onEmojiSelection = (input: string) => {
    setInputValue(input)
  }

  const removeFile = (index: number) => {
    const fileArray = [...filesArr]
    fileArray.splice(index, 1)
    setFilesArr([...fileArray])
    toast.success('File Removed')
  }

  const UploadedFiles = () => (
    <div
      className={filesArr.length > 0 || isLoading ? styles.content_upload : ''}
    >
      {filesArr.map((file: any, index: any) => {
        const renderComponentBasedOnType = () =>
          file?.type === 'application/pdf' ? (
            <div className={styles.PDF_container}>
              <PdfIcon />
              <span
                className={cn(
                  styles.attachementName,
                  'with-ellipsis--single-line'
                )}
              >
                {file?.name}
              </span>
            </div>
          ) : (
            <img src={file?.URL} alt="uploaded-file" width={100} height={80} />
          )

        return (
          <div className={styles.img_card} key={`upload-image-${index}`}>
            <div className={styles.img_container}>
              {renderComponentBasedOnType()}
              <div
                className={styles.cancel}
                onClick={() => handleRemove(file, index)}
              >
                <Cancel_upload className={styles.cancel_icon} />
              </div>
            </div>
          </div>
        )
      })}
      {isLoading && (
        <div
          className="skeleton-box"
          style={{
            width: '100px',
            height: '80px',
            borderRadius: '8px'
          }}
        />
      )}
    </div>
  )
  return (
    <div className={cn(styles.container)}>
      <div className={styles.content}>
        <form onSubmit={onMessageSend}>
          <input
            type="text"
            placeholder="Write your message..."
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
          />
        </form>
        <div className={styles.icons}>
          <AttachmentInput stateObj={stateObj} />
          <EmojiInput value={inputValue} onSelection={onEmojiSelection} />
          <button onClick={onMessageSend} className={styles.sent_container}>
            <Sent />
          </button>
        </div>
        <UploadedFiles />
      </div>
    </div>
  )
}

export default MessageBar
