import {Truck} from 'assets/images'
import {DealDocument, PreviewLinkProps, ProductDocument} from 'common'
import {Collection, alertTypes, messageTypes} from 'common/enums'
import {Loader} from 'components/Loader'
import UserProfilePicture from 'components/UserProfileImage'
import {ChatsDocument} from 'components/types'
import {isArray} from 'lodash'
import React, {useEffect, useState} from 'react'
import {toast} from 'react-toastify'
import {MenuDots, Trash} from '../../../assets/svgs'
import {useAppContext, useFirebase} from '../../Firebase/hooks'
import {
  getFormattedDate,
  getLinkPreviewData,
  getRelativeTime,
  isValidUrl,
  plainTextLinkToHyperlink,
  useClickOutside
} from '../../utils'
import Alert from '../Alert'
import AutoScroll from '../AutoScroll'
import DateContainer from '../DateContainer'
import DeleteChatModal from '../DeleteChatModal'
import InboxOfferReceiver from '../InboxOfferReceiver'
import MessageBar from '../MessageBar'
import styles from '../MessageSection.module.scss'
import OfferProductBanner from '../OfferProductBanner'
import ReceiverMessage from '../ReceiverMessage'
import RequestForInspectionReceiver from '../RequestForInspectionReceiver'
import SenderMessage from '../SenderMessage'

const Chat = ({
  data,
  deals,
  handleReset
}: {
  data: ChatsDocument
  deals: Array<DealDocument>
  handleReset: () => void
}) => {
  const [loading, setLoading] = useState(false)
  const [loader, setLoader] = useState(false)
  const [chatData, setChatData] = useState<Array<any>>([])
  const [showDeleteChatModal, setShowDeleteChatModal] = useState<boolean>(false)
  const [currentProduct, setCurrentProduct] = useState<{
    documentData: ProductDocument | undefined
  }>({
    documentData: undefined
  })
  const [chatOptions, setChatOptions] = useState(false)
  const {
    appState: {isSuperManager}
  } = useAppContext()
  const {getUserChat, getDocumentData} = useFirebase()
  const {
    chatId,
    dealId,
    sellerContactName,
    buyerContactName,
    sellerContactId,
    thumbnail,
    buyerContactId,
    lastMessageTimestamp
  } = data

  useEffect(() => {
    setLoader(true)

    const timeoutId = setTimeout(() => {
      setLoader(false)
    }, 2000)

    return () => {
      clearTimeout(timeoutId)
    }
  }, [data.dealId])

  const toggelChatOptions = () => setChatOptions(!chatOptions)

  const chatOptionsRef = React.useRef<HTMLDivElement>(null)
  const dismissChatOptions = React.useCallback(() => {
    setChatOptions(false)
  }, [setChatOptions])

  useClickOutside([chatOptionsRef], dismissChatOptions)

  const uid = sellerContactId
  const currentDeal = deals?.find((deal) => deal.dealId === dealId)
  const offers = currentDeal?.offers || []
  const inactiveParticipants = data?.activeParticipants
    ? Object.keys(data.activeParticipants)
        .filter((participantId) => !data?.activeParticipants![participantId])
        .map((participantId) =>
          participantId === sellerContactId
            ? sellerContactName
            : participantId === buyerContactId
            ? buyerContactName
            : ''
        )
    : []

  const inspectionRequests =
    deals?.find((deal) => deal.dealId === dealId)?.requestForInspection || []

  const handleDeleteChat = () => {
    toggelChatOptions()
    setShowDeleteChatModal(true)
  }

  const cancelCallback = (error: Error) => {
    if (error) {
      toast.error(`Something went wrong!`)
    }
  }

  const updateChatState = async (data: any) => {
    let preview: PreviewLinkProps | null
    if (data.message.includes('https://')) {
      const url =
        data.message.split(' ').find((str: any) => str.includes('https://')) ||
        ''
      if (isValidUrl(url)) {
        preview = await getLinkPreviewData(url)
      }
    }
    data.message = plainTextLinkToHyperlink(data.message)

    setChatData((prevState) =>
      [...prevState, {...data, preview}].sort(
        (a, b) => a?.timestamp?.seconds - b?.timestamp?.seconds
      )
    )
    setLoading(false)
  }

  useEffect(() => {
    const unsubscribe = getUserChat(chatId, updateChatState, cancelCallback)
    return () => {
      unsubscribe()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatId])

  useEffect(() => {
    const {unsSubData} = getDocumentData(
      setCurrentProduct,
      cancelCallback,
      data.productId,
      Collection.Products
    )
    return () => {
      unsSubData?.()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.dealId])

  useEffect(() => {
    setChatData([])
  }, [chatId])

  const autoScrollStyles = {
    height: '460px',
    paddingBottom: '1rem',
    overflow: 'auto'
  }

  const productImage =
    (isArray(thumbnail?.productImage)
      ? thumbnail?.productImage[0]
      : thumbnail?.productImage) || Truck

  if (loading) return <Loader />

  const formattedChatData = chatData.map((data) => ({
    ...data,
    type: messageTypes.TEXT,
    date: data.timestamp
  }))
  const formattedOffers = offers?.map((offer, index) => ({
    ...offer,
    offerIndex: index,
    type: messageTypes.OFFER
  }))
  const formattedInspectionRequests = inspectionRequests?.map((request) => ({
    ...request,
    type: messageTypes.INSPECTION,
    date: request.lastUpdated
  }))

  const newChatData = [
    ...formattedChatData,
    ...formattedOffers,
    ...formattedInspectionRequests
  ]
    .map((data) => {
      return data.type === messageTypes.TEXT &&
        !data?.message?.length &&
        !data?.attachment?.length
        ? null
        : {
            ...data,
            dateLabel: getFormattedDate(data.date)
          }
    })
    .filter(Boolean)
    .sort((a, b) => {
      const dateA = a?.date?.seconds || a?.date?._seconds
      const dateB = b?.date?.seconds || b?.date?._seconds
      return dateA - dateB
    })

  return (
    <React.Fragment>
      <div className={styles.message_user}>
        <div className={styles.accountant}>
          <div className={styles.accoount_avater}>
            <img
              alt="img-messages-product"
              src={productImage}
              className={styles.avatar}
            />
            <UserProfilePicture
              userId={buyerContactId}
              className={styles.user_img}
              alt="img-messages-user"
            />
          </div>
          <div className={styles.username}>
            <div>
              <div className={styles.contactsContainer}>
                <div style={{minWidth: 'max-content'}}>
                  {sellerContactName.length > 15
                    ? `${sellerContactName.slice(0, 15)}...`
                    : sellerContactName}
                </div>
                <span className={styles.contactType}>Seller</span>
              </div>
              <div style={{fontWeight: 'lighter', fontSize: '16px'}}>with</div>
              <div
                style={{margin: '7px 0'}}
                className={styles.contactsContainer}
              >
                <div style={{minWidth: 'max-content'}}>
                  {buyerContactName.length > 15
                    ? `${buyerContactName.slice(0, 15)}...`
                    : buyerContactName}
                </div>
                <span className={styles.contactType}>Buyer</span>
              </div>
            </div>
            <p>Last seen {getRelativeTime(lastMessageTimestamp.toDate())}</p>
          </div>
        </div>
        <div ref={chatOptionsRef}>
          <div onClick={toggelChatOptions}>
            <MenuDots className={styles.menu_dots} />
          </div>
          {chatOptions && (
            <div className={styles.chat_options_menu}>
              <button onClick={handleDeleteChat}>
                <Trash /> Delete chat
              </button>
            </div>
          )}
        </div>
      </div>
      {loader ? (
        <Loader />
      ) : (
        <React.Fragment>
          <div className={styles.messages}>
            <div className={styles['content-show']}>
              <div className={styles.Message_inner}>
                <OfferProductBanner data={data} />
                <AutoScroll
                  className={styles.message__container}
                  style={autoScrollStyles}
                >
                  {newChatData.length > 0 &&
                    newChatData.map(
                      (chat: any, index: number, chatArray: Array<any>) => {
                        const {
                          attachment,
                          message,
                          senderId,
                          type,
                          dateLabel,
                          isAdmin
                        } = chat

                        const offer = type === messageTypes.OFFER ? chat : null
                        const requestForInspection =
                          type === messageTypes.INSPECTION ? chat : null
                        const showDateLabel =
                          dateLabel &&
                          (index === 0 ||
                            (index > 0 &&
                              chatArray[index].dateLabel !==
                                chatArray[index - 1].dateLabel))

                        const isToday =
                          dateLabel === getFormattedDate(new Date())

                        if (
                          (type === messageTypes.TEXT &&
                            !message &&
                            !attachment) ||
                          (type === messageTypes.OFFER && !offer)
                        )
                          return null

                        const renderSenderMessage = () => (
                          <ReceiverMessage
                            buyerContactId={buyerContactId}
                            message={message}
                            attachment={attachment}
                            preview={chat?.preview}
                          />
                        )

                        const renderReceiverMessage = () => (
                          <SenderMessage
                            sellerContactId={sellerContactId}
                            preview={chat?.preview}
                            attachment={attachment}
                            isAdmin={isAdmin}
                            message={message}
                          />
                        )

                        const RenderMessage = () => {
                          if (type === messageTypes.TEXT) {
                            return uid === senderId || isAdmin
                              ? renderReceiverMessage()
                              : renderSenderMessage()
                          }

                          if (type === messageTypes.OFFER) {
                            return offer ? (
                              <React.Fragment>
                                <InboxOfferReceiver
                                  offer={offer}
                                  data={data}
                                  productImage={productImage}
                                  currentProduct={currentProduct.documentData}
                                  buyerContactId={buyerContactId}
                                />
                                <Alert
                                  type={offer?.offerStatus}
                                  alertType={alertTypes.OfferReceiver}
                                  purchaseMethod={
                                    currentProduct.documentData?.purchaseMethod
                                  }
                                />
                              </React.Fragment>
                            ) : (
                              <div style={{display: 'none'}} />
                            )
                          }

                          if (type === messageTypes.INSPECTION) {
                            return requestForInspection ? (
                              <React.Fragment>
                                <RequestForInspectionReceiver
                                  buyerContactId={buyerContactId}
                                  item={requestForInspection}
                                />
                                <Alert
                                  purchaseMethod={
                                    currentProduct.documentData?.purchaseMethod
                                  }
                                  type={requestForInspection?.inspectionStatus}
                                  alertType={alertTypes.InspectionReceiver}
                                />
                              </React.Fragment>
                            ) : (
                              <div style={{display: 'none'}} />
                            )
                          }

                          return <div style={{display: 'none'}} />
                        }

                        return (
                          <React.Fragment
                            key={`chat-message-${senderId}-${index}}`}
                          >
                            {showDateLabel && (
                              <DateContainer
                                date={isToday ? 'Today' : dateLabel}
                              />
                            )}
                            <RenderMessage />
                          </React.Fragment>
                        )
                      }
                    )}
                  {Boolean(inactiveParticipants.length) && (
                    <Alert
                      inactiveParticipants={inactiveParticipants}
                      alertType={alertTypes.ParticipantLeft}
                      purchaseMethod={
                        currentProduct.documentData?.purchaseMethod
                      }
                    />
                  )}
                </AutoScroll>
              </div>
            </div>
          </div>
          {!isSuperManager && <MessageBar data={data} />}
        </React.Fragment>
      )}
      {showDeleteChatModal && (
        <DeleteChatModal
          chatId={chatId}
          dealId={dealId}
          handleReset={handleReset}
          handleClose={() => setShowDeleteChatModal(false)}
          show={showDeleteChatModal}
        />
      )}
    </React.Fragment>
  )
}

export default Chat
