import {ReqNotFound} from 'assets/svgs'
import styles from './ReqNotFoundScreen.module.scss'
import React from 'react'

const ReqNotFoundScreen = () => {
  return (
    <div className={styles.container}>
      <ReqNotFound />
    </div>
  )
}

export default ReqNotFoundScreen
