import {FinanceDocument} from 'common'
import {applicationStatus} from 'common/enums'
import AlgoliaClient, {
  FINANCE_ADMIN_PANEL_ALGOLIA_INDEX
} from 'components/AlgoliaSearch'
import InputField from 'components/InputField'
import Pagination from 'components/Pagination'
import ReqNotFoundScreen from 'components/ReqNotFoundScreen'
import {TABLE_PAGINATION_PAGE_SIZE} from 'components/constants'
import {Timestamp} from 'firebase/firestore'
import {debounce, isDate, startCase} from 'lodash'
import React, {useEffect, useState} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import Layout from '../Layout'
import {Loader} from '../Loader'
import NavBar from '../Navbar'
import withAuthorization from '../Session/withAuthorization'
import Table from '../TableComponent'
import {
  convertToDate,
  getFormattedDate,
  searchWithAlgolia,
  useSortableData
} from '../utils'
import styles from './FinanceApplicationsPage.module.scss'

const FinanceApplications = ({customClass}: {customClass: string}) => {
  const {state} = useLocation()
  const pageFromLocation = state?.page
  const [currentPage, setCurrentPage] = useState<number>(pageFromLocation || 1)
  const [pageCount, setPageCount] = useState<number>(0)
  const [financeData, setFinancesData] = useState<Array<FinanceDocument>>([])
  const [loader, setLoader] = useState(true)
  const [searchValue, setSearchValue] = useState('')
  const searchIndex = AlgoliaClient.initIndex(FINANCE_ADMIN_PANEL_ALGOLIA_INDEX)
  const debouncedSearch = React.useRef(
    debounce(searchWithAlgolia<FinanceDocument>, 800)
  )
  const navigate = useNavigate()

  useEffect(() => {
    AlgoliaClient.clearCache()
    searchWithAlgolia<FinanceDocument>({
      searchQuery: '*',
      searchIndex,
      setLoaderState: setLoader,
      setData: setFinancesData,
      setPageCount,
      currentPage
    }).then(() => setLoader(false))
  }, [])

  const tableTitles = [
    {title: 'Contact Name', sortBy: () => sortBy('contactName')},
    {title: 'Account Name', sortBy: () => sortBy('accountName')},
    {title: 'Contact Number'},
    {title: 'Product ID'},
    {title: 'Application Status', sortBy: () => sortBy('status')},
    {title: 'Last Modified', sortBy: () => sortBy('date')}
  ]

  const getFormattedData = (financeData: FinanceDocument) => {
    const {
      contactName,
      accountName,
      contactNumber,
      status,
      lastUpdatedAt,
      productId,
      financeId
    } = financeData
    const lastUpdatedDate = lastUpdatedAt && convertToDate(lastUpdatedAt)
    const lastUpdated = isDate(lastUpdatedDate)
      ? getFormattedDate(lastUpdatedDate)
      : '-'
    const date = isDate(lastUpdatedDate)
      ? Timestamp.fromDate(lastUpdatedDate).seconds
      : '-'
    const documentId = financeId || ''
    return {
      contactName,
      accountName,
      contactNumber,
      status: startCase(status),
      lastUpdated,
      productId,
      date,
      sortDate:
        (lastUpdatedDate && Timestamp.fromDate(lastUpdatedDate).seconds) || 0,
      documentId
    }
  }

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event?.target?.value
    setSearchValue(value)
    if (value) {
      debouncedSearch?.current({
        searchQuery: value,
        searchIndex,
        setLoaderState: setLoader,
        setData: setFinancesData,
        setPageCount
      })
    } else {
      debouncedSearch?.current({
        searchQuery: '*',
        searchIndex,
        setLoaderState: setLoader,
        setData: setFinancesData,
        setPageCount,
        currentPage
      })
    }
  }

  const getRowData = (
    rowData: Array<{
      contactName: string
      accountName: string
      contactNumber: string
      productId: string
      status: applicationStatus
      lastUpdated: string
      date: string | number
      documentId: string
    }>
  ) => {
    return (rowData || []).map((singleRowData, index) => {
      const {
        contactName,
        accountName,
        contactNumber,
        status,
        productId,
        lastUpdated,
        documentId
      } = singleRowData
      return (
        <tr
          key={`finance-row-data-${index}`}
          onClick={() => {
            navigate('/finance-applications/finance-application-details', {
              state: {id: documentId, currentPage},
              preventScrollReset: true
            })
          }}
        >
          <td>{contactName || '-'}</td>
          <td>{accountName || '-'}</td>
          <td>{contactNumber || '-'}</td>
          <td>{productId || '-'}</td>
          <td>{status || '-'}</td>
          <td>{lastUpdated || '-'}</td>
        </tr>
      )
    })
  }

  const noData = !financeData.length && !loader

  const scrollRef: React.RefObject<HTMLDivElement> = React.createRef()

  const scrollToRef = () =>
    scrollRef?.current?.scrollIntoView({behavior: 'smooth'})

  const formattedFinanceData = financeData.map((data) => getFormattedData(data))
  const {items, sortBy} = useSortableData(formattedFinanceData)

  const showPagination = pageCount > TABLE_PAGINATION_PAGE_SIZE

  const onPageChange = (page: number) => {
    setLoader(true)
    setCurrentPage(page)
    debouncedSearch?.current({
      searchQuery: searchValue,
      searchIndex,
      setLoaderState: setLoader,
      setData: setFinancesData,
      setPageCount,
      currentPage: page
    })
    setLoader(false)
    scrollToRef()
  }

  return (
    <div className={styles.withLayout}>
      <Layout />
      <div className={[styles.container, customClass].join(' ')}>
        <NavBar title="Finance Applications" />
        <div className={styles.button_filters}>
          <InputField
            name="financeSearchField"
            type="text"
            value={searchValue}
            onChange={handleSearch}
            customClass={styles.inputField}
            placeholder="Search for Filters"
            isSearch={true}
          />
        </div>
        {loader ? (
          <Loader />
        ) : noData ? (
          <ReqNotFoundScreen />
        ) : (
          <React.Fragment>
            <Table titles={tableTitles} rowData={getRowData(items)} />
            {showPagination && (
              <div className="pagination__wrapper">
                <Pagination
                  currentPage={currentPage}
                  totalCount={pageCount}
                  pageSize={TABLE_PAGINATION_PAGE_SIZE}
                  onPageChange={onPageChange}
                />
              </div>
            )}
          </React.Fragment>
        )}
      </div>
    </div>
  )
}

export default withAuthorization(FinanceApplications)
