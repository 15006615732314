import {TaxJarCustomer} from 'common'
import {ExemptionType} from 'common/enums'
import * as Yup from 'yup'

export const INITIAL_TAXJAR_CUSTOMER_STATE: TaxJarCustomer = {
  city: '',
  country: '',
  exemption_type: ExemptionType.Other,
  name: '',
  state: '',
  street: '',
  zip: '',
  exempt_regions: [{country: '', state: ''}]
}

export const TaxJarCustomerSchema = Yup.object({
  name: Yup.string()
    .required()
    .matches(/^[A-Za-z]+(?:[ ]?[A-Za-z]+)*$/, 'Invalid Name!')
    .min(3, 'Name must be at least 3 characters')
    .required('Required'),
  exemption_type: Yup.string().required('Required')
})
