import styles from './FeedbackCard.module.scss'
import {Cancel, Star} from '../../assets/svgs'
import {FeedbackDocument} from 'common'
import UserProfilePicture from 'components/UserProfileImage'
interface FeedbackCardProps {
  feedbackItem?: FeedbackDocument | null
  handleClose: () => void
}

const FeedbackCardModal = ({handleClose, feedbackItem}: FeedbackCardProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.heading}>
          <Cancel className={styles.cancel} onClick={handleClose} />
        </div>
        <h1>Feedback</h1>
        <div className={styles.feedbackCard}>
          <div className={styles.imageContainer}>
            <div className={styles.productImage}>
              {feedbackItem?.productThumbnail && (
                <img alt="buyer-img" src={feedbackItem?.productThumbnail} />
              )}
              <div className={styles.userTypeTag}>
                {feedbackItem?.tag || '-'}
              </div>
            </div>
          </div>
          <div className={styles.feedbackDescription}>
            <div className={styles.userInfo}>
              <div className={styles.userImageName}>
                <div className={styles.userImage}>
                  <UserProfilePicture
                    alt="feedback-user-image"
                    userId={feedbackItem?.submittedByUserId || ''}
                  />
                </div>
                <div className={styles.userName}>
                  {feedbackItem?.userName || '-'}
                </div>
              </div>
              <div className={styles.ratingsContainer}>
                <div>
                  <Star />
                </div>
                <div className={styles.ratingValue}>
                  {feedbackItem?.rating || 0}
                </div>
              </div>
            </div>
            <div className={styles.userFeedback}>
              {feedbackItem?.message || '-'}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FeedbackCardModal
