import {USER_NOT_AUTHORIZED_MESSAGE} from 'components/constants'
import {Formik} from 'formik'
import React, {useEffect, useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import {toast} from 'react-toastify'
import {LoginSvg, Logo} from '../../assets/svgs'
import Button from '../Button'
import {useAppContext, useFirebase, useSession} from '../Firebase/hooks'
import InputField from '../InputField'
import {Loader} from '../Loader'
import styles from './Login.module.scss'
import {SignInSchema} from './schema'

const INITIAL_STATE = {
  email: '',
  password: ''
}

const Login = () => {
  const [passwordShown] = useState(false)
  const {newAppState, setFcmToken} = useAppContext()
  const [loader, setLoader] = useState(false)
  const {signIn, checkCustomClaims} = useFirebase()
  const navigate = useNavigate()
  const {user, loading} = useSession()
  const [isSubmitting, setIsSubmitting] = useState(false)

  useEffect(() => {
    if (user && !loading && !isSubmitting) {
      navigate('/dashboard')
    }
  }, [user, loading, isSubmitting])

  const preventInput = (event: React.KeyboardEvent) =>
    ' '.includes(event.key) && event.preventDefault()
  return (
    <React.Fragment>
      {loading ? (
        <Loader />
      ) : (
        <div className={styles.conatiner}>
          <div className={styles.logo_side}>
            <h2>
              <b>Admin</b> Panel
            </h2>
            <LoginSvg className={styles.svg_img} />
          </div>
          <div className={styles.formSide}>
            {loader ? (
              <Loader />
            ) : (
              <React.Fragment>
                <Logo className={styles.logo_img} />
                <Formik
                  initialValues={{...INITIAL_STATE}}
                  onSubmit={async ({email, password}) => {
                    setIsSubmitting(true)
                    setLoader(true)
                    if (isSubmitting) return
                    const {error} = await signIn(
                      email.replace(/\s/g, ''),
                      password
                    )
                    if (error) {
                      toast.error(error.message)
                      setIsSubmitting(false)
                      setLoader(false)
                      return
                    } else {
                      const {doesCustomClaimsExist, isSuperAdmin} =
                        await checkCustomClaims()
                      if (doesCustomClaimsExist) {
                        newAppState({
                          isAuthorized: true,
                          isSuperAdmin
                        })
                        setFcmToken()
                        navigate('/dashboard')
                      } else {
                        toast.error(USER_NOT_AUTHORIZED_MESSAGE)
                        setIsSubmitting(false)
                        setLoader(false)
                        return
                      }
                    }
                  }}
                  validationSchema={SignInSchema}
                >
                  {({
                    values: {email, password},
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit
                  }) => (
                    <form onSubmit={handleSubmit}>
                      <div className={styles.input_fields_down}>
                        <InputField
                          name="email"
                          type="email"
                          smaller={true}
                          errors={errors}
                          touched={touched}
                          value={email.replace(/\s/g, '')}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder="Email"
                          customClass={styles.inputfield}
                          onKeyDown={preventInput}
                        />
                        <div>
                          <div className={styles.input_password}>
                            <InputField
                              isPassword
                              name="password"
                              type={passwordShown ? 'text' : 'password'}
                              smaller={true}
                              errors={errors}
                              touched={touched}
                              value={password}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              placeholder="Password*"
                            />
                          </div>
                        </div>
                      </div>
                      <Button text={'Login'} customClass={styles.login_btn} />
                    </form>
                  )}
                </Formik>
                <Link to="/forgot-password" className={styles.link}>
                  Forgot your password?
                </Link>
              </React.Fragment>
            )}
          </div>
        </div>
      )}
    </React.Fragment>
  )
}

export default Login
