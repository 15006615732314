import React from 'react'
import styles from './DropDown.module.scss'
import {useState} from 'react'
import cn from 'classnames'
import {get} from 'lodash'
import {useEffect} from 'react'
import {FormikTouched} from 'formik'
import {useDetectClickOutside} from 'react-detect-click-outside'
import {Cancel} from 'assets/svgs'
import {useNavigate} from 'react-router-dom'
import {ICountry, IState} from 'country-state-city'

interface DropDownSelectProps {
  customClass?: string
  options: Array<any>
  firstIcon?: React.ReactNode
  placeholder?: string
  mode?: 'country' | 'state' | undefined
  createNew?: {
    currentPath: string
    pathTo: string
    title: string
    id?: string
  }
  disabled?: boolean
  value?: any
  onChange?: (e: any) => void
  errors?: any
  touched?: FormikTouched<{}>
  name: string
  onBlur?: (e: any) => void
  displayValue?: boolean
  unSelectable?: boolean
  cancelClickFunction?: () => void
  customOnClick?: () => void
  customValue?: string | undefined
  customRoute?: string
}

const DropDownSelect = ({
  customClass,
  mode,
  options,
  firstIcon,
  placeholder,
  createNew,
  disabled,
  value,
  onChange,
  errors,
  touched,
  name,
  onBlur,
  unSelectable,
  displayValue = false,
  cancelClickFunction,
  customOnClick,
  customValue,
  customRoute
}: DropDownSelectProps) => {
  const [isOptionsOpen, setIsOptionsOpen] = useState(false)
  const navigate = useNavigate()
  const [selectedOption, setSelectedOption] = useState(value)
  const hasError = get(touched, name) || ''
  const dropdownError = hasError && get(errors, name)
  const valueToShow = isOptionsOpen
    ? placeholder
    : options[selectedOption || 0]?.name ||
      options[selectedOption || 0]?.label ||
      options[selectedOption || 0] ||
      customValue ||
      (displayValue && value) ||
      placeholder
  const isPlaceHolder = valueToShow === placeholder
  const [searchTerm, setSearchTerm] = useState('')

  useEffect(() => {
    setSelectedOption(value)
  }, [value])

  const closeDropdown = () => {
    setSearchTerm('')
    setIsOptionsOpen(false)
  }

  const toggleOptions = (e: React.MouseEvent<HTMLButtonElement>) => {
    setIsOptionsOpen(!isOptionsOpen)
  }

  const ref = useDetectClickOutside({onTriggered: closeDropdown})

  const setSelectedThenCloseDropdown = (
    index: React.SetStateAction<number>
  ) => {
    onChange && onChange(index)
    setSelectedOption(index)
    setSearchTerm('')
    setIsOptionsOpen(false)
  }

  const noOptions = () => (
    <li role="option" aria-selected={false} tabIndex={0}>
      No Options
    </li>
  )

  const handleKeyDown =
    (index: any) => (e: {key: any; preventDefault: () => void}) => {
      switch (e.key) {
        case ' ':
        case 'SpaceBar':
        case 'Enter':
          e.preventDefault()
          setSelectedThenCloseDropdown(index)
          break
        default:
          break
      }
    }

  const handleListKeyDown = (e: any) => {
    switch (e.key) {
      case 'Escape':
        e.preventDefault()
        setIsOptionsOpen(false)
        break
      case 'ArrowUp':
        e.preventDefault()
        setSelectedOption(
          selectedOption - 1 >= 0 ? selectedOption - 1 : options.length - 1
        )
        break
      case 'ArrowDown':
        e.preventDefault()
        setSelectedOption(
          selectedOption === options.length - 1 ? 0 : selectedOption + 1
        )
        break
      default:
        break
    }
  }

  const renderOptions = () => {
    switch (mode) {
      case 'country':
        const countryOptions = options
          .filter((option) => {
            if (searchTerm) {
              return option?.name
                ?.toLowerCase()
                ?.includes(searchTerm.toLowerCase())
            } else {
              return true
            }
          })
          .map((country: ICountry, index) => (
            <li
              key={`drop-down-${name}-row-data-${index}`}
              id={country.isoCode}
              role="option"
              aria-selected={selectedOption === index}
              tabIndex={0}
              onKeyDown={() => handleKeyDown(index)}
              onClick={() => {
                const idx =
                  options.findIndex((opt) => opt?.name === country?.name) ||
                  index
                !unSelectable && setSelectedThenCloseDropdown(idx)
              }}
            >
              {country.name}
            </li>
          ))
        return (countryOptions.length && countryOptions) || noOptions()
      case 'state':
        const stateOptions = options
          .filter((option) => {
            if (searchTerm) {
              return option?.name
                ?.toLowerCase()
                ?.includes(searchTerm.toLowerCase())
            } else {
              return true
            }
          })
          .map((state: IState, index) => (
            <li
              key={`drop-down-${name}-row-data-${index}`}
              id={state.isoCode}
              role="option"
              aria-selected={selectedOption === index}
              tabIndex={0}
              onKeyDown={() => handleKeyDown(index)}
              onClick={() => {
                const idx =
                  options.findIndex((opt) => opt?.name === state?.name) || index
                !unSelectable && setSelectedThenCloseDropdown(idx)
              }}
            >
              {state.name}
            </li>
          ))
        return (stateOptions.length && stateOptions) || noOptions()

      default:
        const otherOptions = options
          .filter((option) => {
            if (searchTerm) {
              return (option?.name || option)
                ?.toLowerCase()
                ?.includes(searchTerm.toLowerCase())
            } else {
              return true
            }
          })
          .map((option, index) => (
            <li
              key={`drop-down-${name}-row-data-${index}`}
              id={option?.id || option}
              role="option"
              aria-selected={selectedOption === index}
              tabIndex={0}
              onKeyDown={() => handleKeyDown(index)}
              onClick={() => {
                if (!unSelectable) {
                  const idx =
                    options.findIndex(
                      (opt) => (opt?.name || opt) === (option?.name || option)
                    ) || index
                  setSelectedThenCloseDropdown(idx)
                }
                if (customRoute) {
                  navigate(customRoute, {
                    state: {isEdit: true, id: option}
                  })
                }
              }}
            >
              {option?.name || option}
            </li>
          ))
        return (otherOptions.length && otherOptions) || noOptions()
    }
  }

  return (
    <div
      key={name}
      id={name}
      className={[styles.wrapper, customClass].join(' ')}
    >
      <div ref={ref} className={styles.container}>
        <input
          className={styles.searchInput}
          type="text"
          placeholder={valueToShow}
          readOnly={disabled}
          value={searchTerm}
          onClick={() => setIsOptionsOpen(!isOptionsOpen)}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <button
          name={name}
          type="button"
          aria-haspopup="listbox"
          aria-expanded={isOptionsOpen}
          onClick={(e) => {
            customOnClick ? customOnClick() : toggleOptions(e)
          }}
          className={
            isOptionsOpen
              ? cn(
                  styles.tabs,
                  styles['expended'],
                  isPlaceHolder && styles.placeholderColor
                )
              : isPlaceHolder
              ? styles.placeholderColor
              : ''
          }
          onKeyDown={handleKeyDown}
          onBlur={onBlur}
        >
          {firstIcon}
        </button>
        <div
          onClick={(e) => {
            e.stopPropagation()
            e.preventDefault()
            cancelClickFunction && cancelClickFunction()
            setSearchTerm('')
            setIsOptionsOpen(false)
          }}
          className={
            firstIcon
              ? styles.cancelContainer
              : cn(styles.cancelContainer, styles.customRight)
          }
        >
          <Cancel />
        </div>
        {isOptionsOpen && (
          <ul
            className={isOptionsOpen ? cn(styles.option, styles['show']) : ''}
            role="listbox"
            aria-activedescendant={options[selectedOption]?.name}
            tabIndex={-1}
            onKeyDown={handleListKeyDown}
          >
            {renderOptions()}
            {createNew && (
              <li
                onClick={() =>
                  navigate(createNew?.pathTo, {
                    state: {path: createNew?.currentPath, id: createNew?.id}
                  })
                }
                className={styles.temp}
              >
                + New {createNew?.title}
              </li>
            )}
          </ul>
        )}
      </div>
      <div className={styles.error}>{dropdownError}</div>
    </div>
  )
}

export default DropDownSelect
