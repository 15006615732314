import React, {useState, useMemo} from 'react'
import {getFormattedDate, convertToDate, useSortableData} from '../../utils'
import styles from '../DetailPage.module.scss'
import Table from 'components/TableComponent'
import {TABLE_PAGINATION_PAGE_SIZE} from 'components/constants'
import Pagination from 'components/Pagination'
import {AgreementDocument, RecipientDocumentSecondFormat} from 'common'
import {useLocation} from 'react-router-dom'
import {isDate} from 'lodash'
import {FieldSet} from '../DetailFields'
import {IFieldSetSingleField} from 'components/types'

const ListingAgreementDetailsPage = (data: AgreementDocument) => {
  const [currentPage, setCurrentPage] = useState<number>(1)
  const tableTitles = [{title: 'Name'}, {title: 'Email'}, {title: 'Status'}]
  const scrollRef: React.RefObject<HTMLDivElement> = React.createRef()
  const {pathname} = useLocation()
  const isLAPage = pathname === '/listing-agreement/agreement-details' || false
  const isMSAPage = pathname === '/msa-agreement/agreement-details' || false
  const isACHPage = pathname === '/ach-agreement/agreement-details' || false
  const scrollToRef = () =>
    scrollRef?.current?.scrollIntoView({behavior: 'smooth'})

  const getFormattedData = (recipientData: RecipientDocumentSecondFormat) => {
    const {name, recipient_name, email, recipient_email, action_status} =
      recipientData
    const recipientName = name || recipient_name || ''
    const recipientEmail = email || recipient_email || ''
    const actionStatus = action_status || ''
    return {
      recipientName,
      recipientEmail,
      actionStatus
    }
  }

  const getRowData = (
    rowData: Array<{
      recipientName: string
      recipientEmail: string
      actionStatus: string
    }>
  ) => {
    return (rowData || [])?.map((singleRowData, index) => {
      const {recipientName, recipientEmail, actionStatus} = singleRowData
      return (
        <tr key={`recipient-document-${index}`}>
          <td>{recipientName || '-'}</td>
          <td>{recipientEmail || '-'}</td>
          <td>{actionStatus || '-'}</td>
        </tr>
      )
    })
  }

  const formattedRecipientData = data?.recipients?.map((data) =>
    getFormattedData(data)
  )

  const {items} = useSortableData(formattedRecipientData)

  const showPagination = data?.recipients?.length > TABLE_PAGINATION_PAGE_SIZE

  const onPageChange = (page: number) => {
    setCurrentPage(page)
    scrollToRef()
  }

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * TABLE_PAGINATION_PAGE_SIZE
    const lastPageIndex = firstPageIndex + TABLE_PAGINATION_PAGE_SIZE
    return items.slice(firstPageIndex, lastPageIndex)
  }, [currentPage, items])

  const agreementFieldsFirst: Array<IFieldSetSingleField> = [
    {
      label: 'Document ID',
      value: data?.documentId || '-'
    },
    {
      label: isLAPage
        ? 'Equipment ID'
        : isACHPage
        ? 'Contact ID'
        : isMSAPage
        ? 'Account ID'
        : '-',
      value: isLAPage
        ? data?.productId
        : isACHPage
        ? data?.contactId
        : isMSAPage
        ? data?.accountId
        : '-'
    },
    {
      label: 'Last Updated',
      value: isDate(convertToDate(data?.lastUpdated))
        ? getFormattedDate(convertToDate(data?.lastUpdated))
        : '-'
    }
  ]

  const agreementFieldsSecond: Array<IFieldSetSingleField> = [
    {
      label: 'Status',
      value: data?.status
    },
    {
      label: 'Description',
      value: data?.description,
      isTextbox: true
    }
  ]

  return (
    <React.Fragment>
      <div className={styles.inputFieldsContainer}>
        <div className={styles.inputsfields}>
          <FieldSet title="Agreement Details" fields={agreementFieldsFirst} />
        </div>
        <div className={styles.inputsfields} style={{marginTop: '1.5rem'}}>
          <FieldSet gap fields={agreementFieldsSecond} />
        </div>
      </div>
      <div>
        <h1 className={styles.secondheading}>Recipients Details</h1>
        <Table
          titles={tableTitles}
          rowData={getRowData(currentTableData)}
          customClass={styles.containerTable}
        />
        {showPagination && (
          <div className="pagination__wrapper">
            <Pagination
              currentPage={currentPage}
              totalCount={data?.recipients?.length}
              pageSize={TABLE_PAGINATION_PAGE_SIZE}
              onPageChange={onPageChange}
            />
          </div>
        )}
      </div>
    </React.Fragment>
  )
}

export default ListingAgreementDetailsPage
