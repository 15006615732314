import {CheckedIcon, UncheckedIconTwo} from 'assets/images'
import cn from 'classnames'
import {AccountDocument} from 'common'
import {AccountTypes, Collection} from 'common/enums'
import Button from 'components/Button'
import {useFirebase} from 'components/Firebase/hooks'
import ModalComponent from 'components/ModalComponent'
import Pagination from 'components/Pagination'
import CreateTaxJarCustomerModal from 'components/TaxJarCustomers/CreateTaxJarCustomerModal'
import {listTaxJarCustomers} from 'components/apis/taxjar'
import React, {useEffect, useMemo, useState} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {toast} from 'react-toastify'
import Layout from '../Layout'
import {Loader} from '../Loader'
import NavBar from '../Navbar'
import withAuthorization from '../Session/withAuthorization'
import Table from '../TableComponent'
import styles from './TaxJarCustomers.module.scss'

interface ICustomerData {
  customerId: string
  hasFetchedFiresoreFields: boolean
  accountName?: string
  accountType?: AccountTypes
  country?: string
  hasSignedMSA?: string | true
  sector?: string
}

const TaxJarCustomers = ({customClass}: {customClass: string}) => {
  const {state} = useLocation()
  const navigate = useNavigate()
  const pageFromLocation = state?.page
  const [currentPage, setCurrentPage] = useState<number>(pageFromLocation || 1)
  const [customersData, setCustomersData] = useState<Array<ICustomerData>>([])
  const {getCollectionBasedOnIds} = useFirebase()
  const [loader, setLoader] = useState(true)
  const [show, setShow] = useState<boolean>(false)
  const [refetch, setRefetch] = useState(0)

  const runRefetch = () => setRefetch((prev) => prev + 1)
  const toggleShow = () => setShow(!show)

  const getCustomersData = async () => {
    const {
      data: {data, code}
    } = await listTaxJarCustomers()
    const customers = data?.map((id: string) => ({
      customerId: id,
      hasFetchedFiresoreFields: false
    }))
    !customers?.length ? setLoader(false) : setCustomersData(customers)
    if (code !== 200) {
      toast.warn(data.msg || 'Failed to get TaxJar Customers')
    }
    return
  }

  useEffect(() => {
    getCustomersData()
  }, [refetch])

  const getRowData = (rowData: Array<ICustomerData>) => {
    return (rowData || []).map((customer, index) => {
      return (
        <tr
          key={`taxjar-customers-row-data-${index}`}
          onClick={() => {
            navigate('/taxjar-customers/single-taxjar-customer-detail', {
              state: {
                id: customer.customerId,
                isEdit: true,
                currentPage
              },
              preventScrollReset: true
            })
          }}
        >
          <td>{customer.customerId || '-'}</td>
          <td>{customer?.accountName || '-'}</td>
          <td>{customer?.accountType || '-'}</td>
          <td>{customer?.country || '-'}</td>
          <td>
            <img
              src={customer.hasSignedMSA ? CheckedIcon : UncheckedIconTwo}
              alt="hasSignedMSA"
            />
          </td>
          <td>{customer?.sector || '-'}</td>
        </tr>
      )
    })
  }

  const scrollRef: React.RefObject<HTMLDivElement> = React.createRef()

  const scrollToRef = () =>
    scrollRef?.current?.scrollIntoView({behavior: 'smooth'})

  const fetchFirestoreFeilds = async (customersArr: Array<ICustomerData>) => {
    if (!customersArr.length) return
    const allItemsHaveFirestoreFields = customersArr.every(
      ({hasFetchedFiresoreFields}) => hasFetchedFiresoreFields === true
    )
    if (allItemsHaveFirestoreFields) {
      setLoader(false)
      return
    }
    const itemsWithoutFirestoreFields =
      customersArr
        .filter(
          ({hasFetchedFiresoreFields}) => hasFetchedFiresoreFields === false
        )
        .map((customer) => customer.customerId) || []
    if (itemsWithoutFirestoreFields.length) {
      const res = await getCollectionBasedOnIds({
        collectionName: Collection.Accounts,
        idsArray: itemsWithoutFirestoreFields,
        queryVariable: 'accountId'
      })

      const accountData: Array<AccountDocument> = res?.collectionData || []
      if (accountData.length) {
        const newCustomersArr = customersData.map((customer) => {
          const firestoreDocData = accountData.find(
            (data) => data.accountId === customer.customerId
          )
          if (firestoreDocData) {
            return {
              customerId: customer.customerId,
              hasFetchedFiresoreFields: true,
              accountName: firestoreDocData?.accountName || '-',
              accountType: firestoreDocData?.accountType || '-',
              country: firestoreDocData?.shippingAddress.shippingCountry || '-',
              hasSignedMSA: firestoreDocData?.hasSignedMSA || '',
              sector: firestoreDocData?.sector || '-'
            }
          }
          return customer
        })

        setCustomersData([...newCustomersArr])
      }
    }
    setLoader(false)
  }

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * 10
    const lastPageIndex = firstPageIndex + 10
    const currentLitmit = customersData.slice(firstPageIndex, lastPageIndex)
    setLoader(true)
    fetchFirestoreFeilds(currentLitmit)
    return currentLitmit
  }, [currentPage, customersData])

  const showPagination = customersData?.length > 10

  const onPageChange = (page: number) => {
    setCurrentPage(page)
    scrollToRef()
  }

  const tableTitles = [
    {title: 'Customer ID'},
    {title: 'Account Name'},
    {title: 'Account Type'},
    {title: 'Country'},
    {title: 'Has Signed MSA'},
    {title: 'Sector'}
  ]

  return (
    <div className={styles.withLayout}>
      <Layout />
      <div className={cn(styles.container, customClass)}>
        <NavBar title="TaxJar Customers" />
        <div className={styles.button_filters}>
          <Button
            customClass={styles.create_account_btn}
            text="Create TaxJar Customer"
            onClick={toggleShow}
          />
        </div>
        {loader ? (
          <Loader />
        ) : (
          <React.Fragment>
            <Table
              customClass={styles.threedetails}
              titles={tableTitles}
              rowData={getRowData(currentTableData)}
            />
            {show && (
              <ModalComponent handleClose={toggleShow} show={show}>
                <CreateTaxJarCustomerModal
                  runRefetch={runRefetch}
                  handleClose={toggleShow}
                />
              </ModalComponent>
            )}
            {showPagination && (
              <div className="pagination__wrapper">
                <Pagination
                  currentPage={currentPage}
                  totalCount={customersData?.length}
                  pageSize={10}
                  onPageChange={onPageChange}
                />
              </div>
            )}
          </React.Fragment>
        )}
      </div>
    </div>
  )
}

export default withAuthorization(TaxJarCustomers)
