import {TaxJarCustomer} from 'common'
import {ExemptionType} from 'common/enums'
import {getCountryNameByCode, getStateNameByCode} from 'components/utils'
import _ from 'lodash'
import React from 'react'
import {FieldSet} from '../DetailFields'
import styles from '../DetailPage.module.scss'

const TaxJarCustomerDetails = (data: TaxJarCustomer) => {
  const state =
    data.country && data.state
      ? getStateNameByCode(data.state, data.country)
      : data.state || ''
  const country = (data.country && getCountryNameByCode(data.country)) || ''
  const customerInformation = [
    {label: 'Customer Name', value: data.name},
    {
      label: 'Exemption Type',
      value: _.startCase(
        _.replace(data.exemption_type || ExemptionType.NonExempt, /_/g, ' ')
      )
    },
    {label: 'Country', value: country},
    {
      label: 'State',
      value: state
    },
    {label: 'City', value: data.city},
    {label: 'Street', value: data.street},
    {label: 'Zip', value: data.zip}
  ]

  const exemptRegions = data?.exempt_regions?.length
    ? data?.exempt_regions
        ?.map(({country, state}) => [
          {
            label: 'Country',
            value: country ? getCountryNameByCode(country) : ''
          },
          {
            label: 'State',
            value: country && state ? getStateNameByCode(state, country) : ''
          }
        ])
        .flat()
    : [{label: 'None', value: ''}]

  return (
    <React.Fragment>
      <div className={styles.inputsfields}>
        <FieldSet title="Customer Details" fields={customerInformation} />
      </div>
      <div className={styles.inputsfields}>
        <FieldSet title="Exempt Regions" fields={exemptRegions} />
      </div>
    </React.Fragment>
  )
}

export default TaxJarCustomerDetails
