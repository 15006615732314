import {ChatsDocument} from 'components/types'
import styles from './OfferProductBanner.module.scss'
import {withThousandSeperatorString} from 'components/utils'

const OfferProductBanner = ({data}: {data: ChatsDocument}) => {
  const {listingPrice, productName} = data

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <p>
          <b>{withThousandSeperatorString(`${listingPrice}`)}</b>
          <span className="with-ellipsis">{productName}</span>
        </p>
      </div>
    </div>
  )
}

export default OfferProductBanner
