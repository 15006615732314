import {AssetReportDocument} from 'common'
import AlgoliaClient, {
  ASSET_REPORTS_ADMIN_PANEL_ALGOLIA_INDEX
} from 'components/AlgoliaSearch'
import {useAppContext, useFirebase} from 'components/Firebase/hooks'
import InputField from 'components/InputField'
import Pagination from 'components/Pagination'
import {TABLE_PAGINATION_PAGE_SIZE} from 'components/constants'
import {searchWithAlgolia, useSortableData} from 'components/utils'
import {debounce} from 'lodash'
import React, {useEffect, useRef, useState} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import Layout from '../Layout'
import {Loader} from '../Loader'
import NavBar from '../Navbar'
import withAuthorization from '../Session/withAuthorization'
import Table from '../TableComponent'
import styles from './EquipmentReportTable.module.scss'

const EquipmentReportTable = () => {
  const {state} = useLocation()
  const navigate = useNavigate()
  const pageFromLocation = state?.page
  const [accountList, setAccountList] = useState<Array<string>>([])
  const [currentPage, setCurrentPage] = useState<number>(pageFromLocation || 1)
  const [pageCount, setPageCount] = useState<number>(0)
  const [loader, setLoader] = useState(true)
  const {getUserAccounts} = useFirebase()
  const [assetReportsData, setAssetReportsData] = useState<
    Array<AssetReportDocument>
  >([])
  const {
    appState: {isMSAAdmin, isSuperAdmin, isSuperManager}
  } = useAppContext()

  const getMSAAccountsList = async () => {
    const userAccounts = await getUserAccounts()
    const accountIDLength = userAccounts.length
    if (accountIDLength) {
      !accountList.length && setAccountList([...userAccounts])
    }
  }

  useEffect(() => {
    if (isMSAAdmin) {
      getMSAAccountsList()
    }
  }, [])

  useEffect(() => {
    AlgoliaClient.clearCache()
    if (isMSAAdmin && accountList.length) {
      searchWithAlgolia<AssetReportDocument>({
        searchQuery: '*',
        searchIndex,
        setLoaderState: setLoader,
        setData: setAssetReportsData,
        setPageCount,
        accountList,
        currentPage
      }).then(() => setLoader(false))
    }
    if (isSuperAdmin || isSuperManager) {
      searchWithAlgolia<AssetReportDocument>({
        searchQuery: '*',
        searchIndex,
        setLoaderState: setLoader,
        setData: setAssetReportsData,
        setPageCount,
        accountList,
        currentPage
      }).then(() => setLoader(false))
    }
  }, [accountList?.length])

  const getRowData = (rowData: Array<AssetReportDocument>) => {
    return (rowData || []).map((singleRowData, index: number) => {
      const {accountName, contactName, contactId} = singleRowData

      return (
        <tr
          key={`equipment-report-row-data-${index}`}
          onClick={() => {
            navigate('/reports/single-report-detail', {
              state: {
                data: singleRowData,
                currentPage
              },
              preventScrollReset: true
            })
          }}
        >
          <td>{accountName || '-'}</td>
          <td>{contactName || '-'}</td>
          <td>{contactId || '-'}</td>
        </tr>
      )
    })
  }

  const scrollRef: React.RefObject<HTMLDivElement> = React.createRef()

  const scrollToRef = () =>
    scrollRef?.current?.scrollIntoView({behavior: 'smooth'})

  const {items, sortBy} = useSortableData(assetReportsData)

  const showPagination = pageCount > TABLE_PAGINATION_PAGE_SIZE

  const onPageChange = (page: number) => {
    setLoader(true)
    setCurrentPage(page)
    debouncedSearch?.current({
      searchQuery: searchValue,
      searchIndex,
      setLoaderState: setLoader,
      setData: setAssetReportsData,
      setPageCount,
      currentPage: page
    })
    setLoader(false)
    scrollToRef()
  }

  const tableTitles = [
    {title: 'Account Name', sortBy: () => sortBy('accountName')},
    {title: 'Contact Name', sortBy: () => sortBy('contactName')},
    {title: 'Contact ID', sortBy: () => sortBy('contactId')}
  ]

  const [searchValue, setSearchValue] = useState('')
  const searchIndex = AlgoliaClient.initIndex(
    ASSET_REPORTS_ADMIN_PANEL_ALGOLIA_INDEX
  )
  const debouncedSearch = useRef(
    debounce(searchWithAlgolia<AssetReportDocument>, 800)
  )

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (isMSAAdmin && !accountList?.length) return
    const value = event?.target?.value
    setSearchValue(value)
    if (value) {
      debouncedSearch?.current({
        searchQuery: value,
        searchIndex,
        setLoaderState: setLoader,
        setData: setAssetReportsData,
        setPageCount,
        accountList
      })
    } else {
      debouncedSearch?.current({
        searchQuery: '*',
        searchIndex,
        setLoaderState: setLoader,
        setData: setAssetReportsData,
        setPageCount,
        currentPage,
        accountList
      })
    }
  }
  return (
    <div className={styles.withLayout}>
      <Layout />
      <div className={styles.container}>
        <NavBar title="Equipment Reports" />
        <div className={styles.button_filters}>
          <InputField
            name="searchField"
            type="text"
            value={searchValue}
            onChange={handleSearch}
            customClass={styles.inputField}
            placeholder="Search for Filters"
            isSearch={true}
          />
        </div>
        {loader ? (
          <Loader />
        ) : (
          <React.Fragment>
            <Table
              customClass={styles.threedetails}
              titles={tableTitles}
              rowData={getRowData(items)}
            />
            {showPagination && (
              <div className="pagination__wrapper">
                <Pagination
                  currentPage={currentPage}
                  totalCount={pageCount}
                  pageSize={TABLE_PAGINATION_PAGE_SIZE}
                  onPageChange={onPageChange}
                />
              </div>
            )}
          </React.Fragment>
        )}
      </div>
    </div>
  )
}

export default withAuthorization(EquipmentReportTable)
