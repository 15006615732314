import {GoogleDriveLogo} from 'assets/images'
import {Drag_img, YoutubeIcon} from 'assets/svgs'
import classNames from 'classnames'
import {FILE_OBJECT} from 'common'
import DrivePicker from 'components/DrivePicker'
import {useFirebase} from 'components/Firebase/hooks'
import YouTubeEmbedModal from 'components/ModalComponent/YouTubeEmbedModal'
import {getParsedFileNameForUploadingFile, isCorrectURI} from 'components/utils'
import {cloneDeep, get} from 'lodash'
import React, {useEffect, useRef, useState} from 'react'
import {toast} from 'react-toastify'
import FilesDnD from './FilesDnD'
import styles from './UploadFileSection.module.scss'

interface UploadFileSectionProps {
  touched?: {} | undefined
  errors?: {} | undefined
  deleteCallback?: () => void
  isUploading: boolean
  setIsUploading: (e: boolean) => any
  handleBlur?: (e: any) => void | undefined
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<any>
  values: any
  requestedChanges?: {
    [key: string]: string
  }
  productID?: string
  isCategoryUpload?: boolean
  filesCountState?: {
    filesCount: {attachments: number; media: number}
    initialLoaded: boolean
  }
}

const UploadFileSection = ({
  touched,
  errors,
  handleBlur,
  setFieldValue,
  deleteCallback,
  values,
  requestedChanges,
  isCategoryUpload,
  productID,
  setIsUploading,
  filesCountState
}: UploadFileSectionProps) => {
  const isTouched = get(
    touched,
    isCategoryUpload ? 'imageUrl' : 'productDetails.files'
  )
  const files: Array<FILE_OBJECT> = isCategoryUpload
    ? values?.imageUrl?.filter(Boolean)
    : values?.productDetails?.files
  const requestedChangeImages = get(requestedChanges, 'images')
  const requestedChangeVideos = get(requestedChanges, 'videos')
  const error = get(
    errors,
    isCategoryUpload ? 'imageUrl' : 'productDetails.files'
  )
  const setFiles = (files: Array<FILE_OBJECT>) =>
    setFieldValue('productDetails.files', cloneDeep(files))
  const {filesCount, initialLoaded} = filesCountState || {}
  const [filesArr, setFilesArr] = useState<Array<FILE_OBJECT>>(files || [])
  const [uploadingFilesCount, setUploadingFilesCount] = useState(0)
  const [dragOver, setDragOver] = useState(false)
  const [showEmbedModal, setShowEmbedModal] = useState(false)
  const fileInputRef = useRef<HTMLInputElement>(null)

  const toggleEmbedModal = () => setShowEmbedModal(!showEmbedModal)

  const handleDragEnter = () => {
    setDragOver(true)
  }

  const handleDragLeave = () => {
    setDragOver(false)
  }
  const incrementCount = () => setUploadingFilesCount((prev) => prev + 1)
  const decrementCount = () =>
    setUploadingFilesCount((prev) => (prev <= 1 ? 0 : prev - 1))

  useEffect(() => {
    if (isCategoryUpload) {
      files?.length && setFilesArr(cloneDeep(filesArr))
      return
    }
    files?.length && setFilesArr(cloneDeep(files))
  }, [files?.length])

  const [removeLoading, setRemoveLoading] = useState<boolean>(false)
  const {uploadFile, deleteFile} = useFirebase()

  const handleDelete = (title: string, showToast: boolean = true) => {
    const fileArray = filesArr?.filter(
      (file: FILE_OBJECT) => file?.name !== title
    )
    setFilesArr(() => {
      if (isCategoryUpload) {
        setFieldValue('imageUrl', [])
        return []
      } else {
        const filesCopy = cloneDeep(fileArray)
        setFieldValue('productDetails.files', filesCopy)
        return filesCopy
      }
    })
    deleteCallback?.()
    showToast && toast.success('File Deleted Successfully')
  }

  const renderPlaceholder = () => {
    const isProductForm = !initialLoaded && !isCategoryUpload
    const modifiedFiles = isProductForm
      ? (filesCount && filesCount.media - filesArr.length) || 0
      : 0
    const uploadingOrModifiedFilesCount = isProductForm
      ? modifiedFiles > 0
        ? modifiedFiles
        : 0
      : uploadingFilesCount
    const showPlaceholder = Boolean(uploadingOrModifiedFilesCount)
    return showPlaceholder
      ? Array.from({length: uploadingOrModifiedFilesCount}).map((_, index) => (
          <div
            key={`skeleton-box-${index}`}
            className="skeleton-box"
            style={{
              width: '140px',
              marginBottom: '30px',
              height: '140px',
              borderRadius: '25px'
            }}
          />
        ))
      : null
  }

  const handleRemove = async (file: FILE_OBJECT) => {
    if (removeLoading) return
    setRemoveLoading(true)

    if (isCategoryUpload) {
      const isPlaceHolderImage = file?.name.includes('ImagePlaceholder')
      if (isPlaceHolderImage) {
        setFilesArr([])
        setRemoveLoading(false)
        return
      }
      setFieldValue('imageUrl', [])
      setRemoveLoading(false)
      setFilesArr([])
      return
    }

    if (file?.uploadType === 'google-drive' || file?.uploadType === 'embed') {
      handleDelete(file?.name)
      setRemoveLoading(false)
      return
    }

    const fileName = file?.name
    const fileType = file?.type.includes('video') ? 'videos' : 'images'

    await deleteFile({
      fileName,
      productID,
      fileType,
      isCategory: isCategoryUpload
    })

    handleDelete(isCategoryUpload ? 'category-image' : file?.name)
    setRemoveLoading(false)
  }

  const handleUpload = async (
    currentFile: File,
    type: string,
    bytesToMegaBytes: number
  ) => {
    incrementCount()
    setIsUploading(true)
    const fileType = type === 'video' ? 'videos' : 'images'
    const fileName = getParsedFileNameForUploadingFile(currentFile.name)
    await uploadFile({
      fileType,
      fileName,
      file: currentFile,
      productId: productID,
      isCategory: Boolean(isCategoryUpload),
      onFileUpload: (downloadURL?: string) => {
        const file = {
          type,
          mbSize: bytesToMegaBytes,
          name: fileName,
          URL: URL.createObjectURL(currentFile),
          originalName: currentFile.name,
          downloadURL
        }
        setFilesArr((state: Array<FILE_OBJECT>) => {
          if (isCategoryUpload) {
            setFieldValue('imageUrl', [file])
            return [file]
          } else {
            const stateCopy = cloneDeep(state)
            setFieldValue('productDetails.files', [...stateCopy, file])
            return [...stateCopy, file]
          }
        })
        toast.success('File Uploaded Successfully')
        decrementCount()
        setIsUploading(false)
        return
      },
      onError: () => {
        decrementCount()
        setIsUploading(false)
        toast.error('Failed to upload image, please select a valid file type.')
        return
      }
    })
  }

  const getFilesBasedOnType = (
    fileArray: Array<FILE_OBJECT>,
    fileType: string
  ) => fileArray?.filter((file: FILE_OBJECT) => file.type === fileType)

  const getFileInformation = (file: File) => {
    const {type, size, name} = file
    const isGif = type.includes('gif')
    const isImage = type.includes('image')
    const isVideo = type.includes('video')
    const isVideoFormatValid = type === 'video/mp4'
    const sizeInMB = size / 1024 ** 2
    const fileArray = [...filesArr]
    const countOfImages = getFilesBasedOnType(fileArray, 'image')?.length
    const totalSizeOfVideos = getFilesBasedOnType(fileArray, 'video').reduce(
      (curr, prev) => curr + (prev?.mbSize || 0),
      0
    )
    return {
      name: getParsedFileNameForUploadingFile(name),
      sizeInMB,
      countOfImages,
      totalSizeOfVideos,
      isGif,
      isImage,
      isVideo,
      isVideoFormatValid
    }
  }

  const uploadFileValidations = async (file: File) => {
    const {name, sizeInMB, countOfImages, isGif, isImage, isVideo} =
      getFileInformation(file)

    if (isCategoryUpload && isVideo) {
      toast.warn('Please only upload images.')
      return
    }
    const fileExtention = name.split('.').pop()
    const isMP4 = (fileExtention?.search(/mp4/gi) === 0 && true) || false

    if (isVideo && !isMP4) {
      toast.warn('Only MP4 file format allowed for videos')
      return
    }
    if (!isCorrectURI(name)) {
      toast.warn(
        'Please rename your file appropriately. The file name contains invalid characters.'
      )
      return
    }
    if ((isImage || isVideo) && !isGif) {
      if (isImage) {
        if (sizeInMB > 10) {
          toast.warning('Images should not be over 200 KB')
          return
        }
        if (countOfImages === 20) {
          toast.warning('Maximum 20 Images Allowed')
          return
        } else {
          setIsUploading(true)
          await handleUpload(file, 'image', sizeInMB)
        }
      }
      if (isVideo) {
        if (sizeInMB > 251) {
          toast.warning('Videos should not be over 250MB')
          return
        } else {
          setIsUploading(true)
          await handleUpload(file, 'video', sizeInMB)
        }
      }
    } else {
      toast.warning('GIFs and other formats are not allowed')
      setIsUploading(false)
      return
    }
  }

  return (
    <React.Fragment>
      <div className={styles.container}>
        <h1>{isCategoryUpload ? `IMAGE` : `IMAGE / VIDEO`}</h1>
        <div>
          <div
            className={classNames(styles['drag_area'], {
              [styles['blur']]: dragOver
            })}
            onDragEnter={handleDragEnter}
            onDragOver={(e) => e.preventDefault()}
            onDragLeave={handleDragLeave}
          >
            <div>
              <div className={styles.icon}>
                <div>
                  <Drag_img />
                </div>
              </div>
              <h4>
                Drag & drop an image, or{' '}
                <button
                  type="button"
                  onClick={() => fileInputRef.current?.click()}
                >
                  Browse
                </button>
              </h4>
              <p>
                1200x1200 or higher recommended. Max 10MB{' '}
                {!isCategoryUpload && 'each (250MB for videos)'}
              </p>
            </div>
            <input
              onBlur={handleBlur}
              type="file"
              accept={isCategoryUpload ? 'image/*' : 'image/*,video/mp4'}
              ref={fileInputRef}
              onDropCapture={(e) => {
                e.stopPropagation()
                setDragOver(false)
              }}
              onChange={(e) => {
                if (e?.target?.files) {
                  for (var i = 0; i < e.target.files.length; i++) {
                    var imageFile = e.target.files[i]
                    if (isCategoryUpload) {
                      const file = {
                        type: 'image',
                        name: imageFile.name,
                        mbSize: imageFile.size / 1024 ** 2,
                        downloadURL: URL.createObjectURL(imageFile),
                        URL: URL.createObjectURL(imageFile)
                      }
                      setFilesArr([file])
                      setFieldValue('imageUrl', [imageFile])
                    } else {
                      uploadFileValidations(imageFile)
                    }
                  }
                }
                if (fileInputRef.current) fileInputRef.current.value = ''
              }}
              onClick={(event) => {
                const target = event.target as HTMLInputElement
                target.value = ''
              }}
              multiple={!isCategoryUpload}
            />
            {!isCategoryUpload && (
              <React.Fragment>
                <p style={{padding: 'unset', fontSize: '1rem'}}>OR</p>
                <div className={styles.drive_picker_container}>
                  <img
                    alt="google-drive-logo"
                    src={GoogleDriveLogo}
                    height={68}
                    width={68}
                  />
                  <div className={styles.dirve_button_contianer}>
                    <DrivePicker
                      addFileToArr={(obj) =>
                        setFilesArr((filesArr: Array<FILE_OBJECT>) => {
                          setFiles([...filesArr, obj])
                          return [...filesArr, obj]
                        })
                      }
                    />
                  </div>
                </div>
                <p style={{padding: 'unset', fontSize: '1rem'}}>OR</p>
                <div className={styles.youtube_embed_container}>
                  <YoutubeIcon />
                  <button
                    type="button"
                    onClick={toggleEmbedModal}
                    className={styles.btn_red}
                  >
                    Embed from YouTube
                  </button>
                </div>
              </React.Fragment>
            )}
          </div>

          <div className={styles.content_upload}>
            <FilesDnD
              filesArr={filesArr}
              handleRemove={handleRemove}
              setFilesArr={setFilesArr}
              setFiles={setFiles}
              renderPlaceholder={renderPlaceholder}
              isCategoryUpload={isCategoryUpload}
            />
          </div>
        </div>
        {requestedChangeImages && requestedChangeVideos ? (
          <React.Fragment>
            <span className={styles.error}>
              {requestedChangeImages
                ? `${requestedChangeImages}${
                    isTouched && error ? ', ' + error : ''
                  } `
                : isTouched && error}
            </span>
            <span className={styles.error}>
              {requestedChangeVideos
                ? `${requestedChangeVideos}${
                    isTouched && error ? ', ' + error : ''
                  } `
                : isTouched && error}
            </span>
          </React.Fragment>
        ) : (
          <span className={styles.error}>
            {requestedChangeImages || requestedChangeVideos
              ? `${requestedChangeImages || requestedChangeVideos}${
                  isTouched && error ? ', ' + error : ''
                } `
              : isTouched && error}
          </span>
        )}
      </div>
      {showEmbedModal && (
        <YouTubeEmbedModal
          show={showEmbedModal}
          handleClose={toggleEmbedModal}
          addFileToArr={(obj) => {
            setFilesArr((filesArr: Array<FILE_OBJECT>) => {
              setFiles([...filesArr, obj])
              return [...filesArr, obj]
            })
            setShowEmbedModal(false)
          }}
        />
      )}
    </React.Fragment>
  )
}

export default UploadFileSection
