import {UserPlaceholder} from 'assets/images'
import {useAppContext, useFirebase, useSession} from 'components/Firebase/hooks'
import LinkWrapper from 'components/LinkWrapper'
import {
  addQueryParam,
  convertToDate,
  extractPathFromUrl,
  getRelativeTime,
  sameHostURL
} from 'components/utils'
import {Link} from 'react-router-dom'
import styles from '../Navbar.module.scss'
import {useEffect} from 'react'

const NotificationsDropMenu = () => {
  const {user} = useSession()
  const {appState} = useAppContext()
  const {markNotificationAsRead, updateLastViewedNotification} = useFirebase()

  const displayName = user?.displayName
  const photoURL = user?.photoURL
  const {notifications, adminDetails} = appState || {}
  const {notificationTimestamps} = adminDetails || {}
  const {lastViewed, markAsRead} = notificationTimestamps || {}
  const latestNotificationTime = notifications?.[0]?.createdAt?.valueOf() || 0
  const hasUnreadNotifications = lastViewed
    ? latestNotificationTime > lastViewed?.valueOf()
    : Boolean(notifications?.length)

  useEffect(() => {
    if (hasUnreadNotifications) updateLastViewedNotification()
  }, [])

  return (
    <div className={styles.dropdown_menu_notification}>
      <div className={styles.message_heading}>
        <h3>Notifications</h3>
        <Link to="/notifications">
          <h4>SEE MORE</h4>
        </Link>
      </div>

      <div className={styles.scroll_message}>
        {notifications?.map((notification, index) => {
          const {CTA, createdAt, title, read, notificationId} =
            notification || {}
          const time = getRelativeTime(convertToDate(createdAt))
          const isRead =
            read || (markAsRead?.valueOf() || 0) >= createdAt.valueOf() || false

          const getCta = () => {
            const hostname =
              (typeof window !== 'undefined' && window.location.hostname) || ''
            const ctaOfSameOrigin = sameHostURL(CTA, hostname)
            if (ctaOfSameOrigin) {
              const ctaPath = extractPathFromUrl(
                isRead
                  ? CTA
                  : addQueryParam({
                      url: CTA,
                      param: 'notificationId',
                      value: notificationId
                    })
              )
              return {isExternal: false, cta: ctaPath}
            }
            return {
              isExternal: true,
              cta: CTA
            }
          }

          const link = getCta()
          return (
            <LinkWrapper
              key={`notification-drop-menu-item-${createdAt?.seconds}-${index}`}
              to={link.cta}
              external={link.isExternal}
            >
              <div
                style={isRead ? {} : {background: 'aliceblue'}}
                className={styles.messages_notification}
              >
                <img src={photoURL || UserPlaceholder} alt="user-img" />
                <div className={styles.message_info}>
                  <div>
                    <h3 className="with-ellipsis">
                      <b>{displayName}</b> {title}
                    </h3>
                    <p>{time}</p>
                    {isRead ? null : <span className={styles.dot} />}
                  </div>
                </div>
              </div>
            </LinkWrapper>
          )
        })}
      </div>
    </div>
  )
}
export default NotificationsDropMenu
