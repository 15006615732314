import {AccountDocument} from 'common'
import Pagination from 'components/Pagination'
import ReqNotFoundScreen from 'components/ReqNotFoundScreen'
import {TABLE_PAGINATION_PAGE_SIZE} from 'components/constants'
import {debounce} from 'lodash'
import React, {useEffect, useRef, useState} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import AlgoliaClient, {
  ACCOUNTS_ADMIN_PANEL_ALGOLIA_INDEX
} from '../AlgoliaSearch'
import Button from '../Button'
import {useAppContext, useFirebase} from '../Firebase/hooks'
import InputField from '../InputField'
import Layout from '../Layout'
import {Loader} from '../Loader'
import NavBar from '../Navbar'
import withAuthorization from '../Session/withAuthorization'
import Table from '../TableComponent'
import {searchWithAlgolia, useSortableData} from '../utils'
import styles from './Account.module.scss'

interface IFormattedAccountData {
  streetAddress: string
  phoneNumber: string
  documentId: string
  objectID: string
  name: string
}

const Accounts = ({customClass}: {customClass: string}) => {
  const {state} = useLocation()
  const pageFromLocation = state?.page
  const [accountData, setAccountData] = useState<Array<AccountDocument>>([])
  const [currentPage, setCurrentPage] = useState<number>(pageFromLocation || 1)
  const [accountList, setAccountList] = useState<Array<string>>([])
  const [loader, setLoader] = useState(true)
  const {
    appState: {isMSAAdmin, isSuperAdmin, isSuperManager}
  } = useAppContext()
  const {getUserAccounts} = useFirebase()
  const navigate = useNavigate()
  const [searchValue, setSearchValue] = useState('')
  const searchIndex = AlgoliaClient.initIndex(
    ACCOUNTS_ADMIN_PANEL_ALGOLIA_INDEX
  )
  const [pageCount, setPageCount] = useState<number>(0)

  const getMSAAccountsList = async () => {
    const userAccounts = await getUserAccounts()
    const accountIDLength = userAccounts.length
    if (accountIDLength) {
      !accountList.length && setAccountList([...userAccounts])
    }
  }

  useEffect(() => {
    if (isMSAAdmin) {
      getMSAAccountsList()
    }
  }, [])

  useEffect(() => {
    AlgoliaClient.clearCache()
    if (isMSAAdmin && accountList.length) {
      searchWithAlgolia<AccountDocument>({
        searchQuery: '*',
        searchIndex,
        setLoaderState: setLoader,
        setData: setAccountData,
        setPageCount,
        accountList,
        currentPage
      }).then(() => setLoader(false))
      return
    }
    if (isSuperAdmin || isSuperManager) {
      searchWithAlgolia<AccountDocument>({
        searchQuery: '*',
        searchIndex,
        setLoaderState: setLoader,
        setData: setAccountData,
        setPageCount,
        accountList,
        currentPage
      }).then(() => setLoader(false))
    }
  }, [accountList?.length])

  const getFormattedData = (
    accountData: AccountDocument & {objectID?: string}
  ) => {
    const {
      shippingAddress,
      billingAddress,
      accountInfo,
      accountName,
      accountId,
      objectID
    } = accountData || {}
    const phoneNumber = accountInfo?.phoneNumber || ''
    const streetAddress =
      shippingAddress?.shippingStreetAddress ||
      billingAddress?.billingStreetAddress ||
      ''
    const name = accountName || ''
    const documentId = accountId || ''
    return {phoneNumber, streetAddress, name, documentId, objectID}
  }

  const getRowData = (rowData: Array<IFormattedAccountData>) => {
    return (rowData || []).map((singleRowData, index) => {
      const {phoneNumber, streetAddress, name, documentId, objectID} =
        singleRowData || {}

      return (
        <tr
          key={`account-row-data-${index}`}
          onClick={() => {
            navigate('/accounts/single-account-details', {
              state: {id: documentId, objectID, isEdit: true, currentPage},
              preventScrollReset: true
            })
          }}
        >
          <td>{name || '-'}</td>
          <td>{phoneNumber || '-'}</td>
          <td>{streetAddress || '-'}</td>
        </tr>
      )
    })
  }

  const debouncedSearch = useRef(
    debounce(searchWithAlgolia<AccountDocument>, 800)
  )

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (isMSAAdmin && !accountList?.length) return
    const value = event?.target?.value
    setSearchValue(value)
    if (value) {
      debouncedSearch?.current({
        searchQuery: value,
        searchIndex,
        setLoaderState: setLoader,
        setData: setAccountData,
        setPageCount,
        accountList
      })
    } else {
      debouncedSearch?.current({
        searchQuery: '*',
        searchIndex,
        setLoaderState: setLoader,
        setData: setAccountData,
        setPageCount,
        accountList,
        currentPage
      })
    }
  }

  const noData = !accountData.length && !loader

  const scrollRef: React.RefObject<HTMLDivElement> = React.createRef()

  const scrollToRef = () =>
    scrollRef?.current?.scrollIntoView({behavior: 'smooth'})

  const formattedAccountData = accountData.map(
    (data: AccountDocument & {objectID?: string}) => getFormattedData(data)
  )
  const {items, sortBy} = useSortableData(formattedAccountData)

  const showPagination = pageCount > TABLE_PAGINATION_PAGE_SIZE

  const onPageChange = (page: number) => {
    setLoader(true)
    setCurrentPage(page)
    debouncedSearch?.current({
      searchQuery: searchValue,
      searchIndex,
      setLoaderState: setLoader,
      setData: setAccountData,
      setPageCount,
      accountList,
      currentPage: page
    })
    setLoader(false)
    scrollToRef()
  }

  const tableTitles = [
    {title: 'Account Name', sortBy: () => sortBy('name')},
    {title: 'Phone'},
    {title: 'Mailing Address', sortBy: () => sortBy('streetAddress')}
  ]

  return (
    <div className={styles.withLayout}>
      <Layout />
      <div className={[styles.container, customClass].join(' ')}>
        <NavBar title="Accounts" />
        <div className={styles.button_filters}>
          <InputField
            name="accountSearchField"
            isSearch={true}
            type="text"
            value={searchValue}
            onChange={handleSearch}
            customClass={styles.inputField}
            placeholder="Search for Filters"
          />
          <Button
            customClass={styles.create_account_btn}
            text={'Create Account'}
            onClick={() => navigate('/accounts/single-account-details')}
          />
        </div>
        {loader ? (
          <Loader />
        ) : noData ? (
          <ReqNotFoundScreen />
        ) : (
          <React.Fragment>
            <Table
              customClass={styles.threedetails}
              titles={tableTitles}
              rowData={getRowData(items)}
            />
            {showPagination && (
              <div className="pagination__wrapper">
                <Pagination
                  currentPage={currentPage}
                  totalCount={pageCount}
                  pageSize={TABLE_PAGINATION_PAGE_SIZE}
                  onPageChange={onPageChange}
                />
              </div>
            )}
          </React.Fragment>
        )}
      </div>
    </div>
  )
}

export default withAuthorization(Accounts)
