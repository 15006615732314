import {useState, useEffect} from 'react'
import {getStorage, ref} from 'firebase/storage'

interface IUserProfilePicture
  extends React.ImgHTMLAttributes<HTMLImageElement> {
  userId: string
}

const UserProfilePicture = ({userId, ...props}: IUserProfilePicture) => {
  const [imageUrl, setImageUrl] = useState('')

  useEffect(() => {
    fetchImageUrl(userId)
  }, [userId])

  const fetchImageUrl = (userId: string) => {
    const now = Date.now()
    const userImageStorage =
      JSON.parse(`${localStorage.getItem('userImageStorage')}`) || {}

    const hasNotExceededAnHour =
      now - userImageStorage?.[userId]?.timestamp < 60 * 60 * 1000
    if (userImageStorage[userId] && hasNotExceededAnHour) {
      setImageUrl(userImageStorage[userId].imageUrl)
      return
    }

    const storage = getStorage()
    const storageRef = ref(
      storage,
      `users/${userId}/photoURL/user-profile-picture`
    )
    // browser cahced image fix
    const image = `https://storage.googleapis.com/${storageRef.bucket}/${storageRef.fullPath}?ts=${now}`
    setImageUrl(image)

    userImageStorage[userId] = {
      timestamp: now,
      imageUrl: image
    }

    localStorage.setItem('userImageStorage', JSON.stringify(userImageStorage))
  }

  return (
    <div>
      {imageUrl && <img {...props} src={imageUrl} alt="user-profile-img" />}
    </div>
  )
}

export default UserProfilePicture
