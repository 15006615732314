import classNames from 'classnames'
import {AgreementDocument} from 'common'
import {listingStatus} from 'common/enums'
import AlgoliaClient, {
  ACH_ADMIN_PANEL_ALGOLIA_INDEX,
  LA_ADMIN_PANEL_ALGOLIA_INDEX,
  MSA_ADMIN_PANEL_ALGOLIA_INDEX
} from 'components/AlgoliaSearch'
import InputField from 'components/InputField'
import {Loader} from 'components/Loader'
import Pagination from 'components/Pagination'
import ReqNotFoundScreen from 'components/ReqNotFoundScreen'
import Table from 'components/TableComponent'
import {TABLE_PAGINATION_PAGE_SIZE} from 'components/constants'
import {
  getFormattedDate,
  searchWithAlgolia,
  useSortableData
} from 'components/utils'
import {debounce} from 'lodash'
import React, {useEffect, useState} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import Layout from '../Layout'
import NavBar from '../Navbar'
import withAuthorization from '../Session/withAuthorization'
import styles from './MSAPage.module.scss'

interface SyncedProps {
  customClass: string
}

interface formattedListingAgreementData {
  documentId: string | undefined
  productId?: string
  accountId?: string
  contactId?: string
  status: listingStatus
  recipientName: string
  recipientEmail: string
  lastUpdatedDate: string
  lastUpdatedAt: number
}

const AgreementPage = ({customClass}: SyncedProps) => {
  const {state, pathname} = useLocation()
  const pageFromLocation = state?.page
  const isLAPage = pathname === '/listing-agreement' || false
  const isMSAPage = pathname === '/msa-agreement' || false
  const isACHPage = pathname === '/ach-agreement' || false
  const pageTitle = isLAPage
    ? 'Listing Agreements'
    : isMSAPage
    ? 'MSA Agreements'
    : isACHPage
    ? 'ACH Agreements'
    : ''
  const agreementIndexName = isLAPage
    ? LA_ADMIN_PANEL_ALGOLIA_INDEX
    : isMSAPage
    ? MSA_ADMIN_PANEL_ALGOLIA_INDEX
    : isACHPage
    ? ACH_ADMIN_PANEL_ALGOLIA_INDEX
    : '-'
  const [currentPage, setCurrentPage] = useState<number>(pageFromLocation || 1)
  const [loader, setLoader] = useState<boolean>(true)
  const [listingAgreementData, setListingAgreementData] = useState<
    Array<AgreementDocument>
  >([])

  const scrollRef: React.RefObject<HTMLDivElement> = React.createRef()
  const navigate = useNavigate()
  const [pageCount, setPageCount] = useState<number>(0)
  const [searchValue, setSearchValue] = useState('')
  const searchIndex = AlgoliaClient.initIndex(agreementIndexName)
  const debouncedSearch = React.useRef(
    debounce(searchWithAlgolia<AgreementDocument>, 800)
  )

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event?.target?.value
    setSearchValue(value)
    if (value) {
      debouncedSearch?.current({
        searchQuery: value,
        searchIndex,
        setLoaderState: setLoader,
        setData: setListingAgreementData,
        setPageCount
      })
    } else {
      debouncedSearch?.current({
        searchQuery: '*',
        searchIndex,
        setLoaderState: setLoader,
        setData: setListingAgreementData,
        setPageCount,
        currentPage
      })
    }
  }

  useEffect(() => {
    AlgoliaClient.clearCache()
    searchWithAlgolia<AgreementDocument>({
      searchQuery: '*',
      searchIndex,
      setLoaderState: setLoader,
      setData: setListingAgreementData,
      setPageCount,
      currentPage
    }).then(() => setLoader(false))
  }, [agreementIndexName])

  const onPageChange = (page: number) => {
    setLoader(true)
    setCurrentPage(page)
    debouncedSearch?.current({
      searchQuery: searchValue,
      searchIndex,
      setLoaderState: setLoader,
      setData: setListingAgreementData,
      setPageCount,
      currentPage: page
    })
    setLoader(false)
    scrollToRef()
  }

  const getFormattedData = (listingAgreementData: AgreementDocument) => {
    const {productId, contactId, accountId, status, recipients, lastUpdated} =
      listingAgreementData
    const recipientOfAgreement = recipients?.[recipients.length - 2]
    const recipientName =
      recipientOfAgreement?.recipient_name || recipientOfAgreement?.name || ''
    const recipientEmail =
      recipientOfAgreement?.recipient_email || recipientOfAgreement?.email || ''
    const lastUpdatedDate = getFormattedDate(lastUpdated)
    return {
      ...(isLAPage
        ? {
            productId
          }
        : isMSAPage
        ? {
            accountId
          }
        : isACHPage
        ? {contactId}
        : {}),
      status,
      recipientName,
      recipientEmail,
      lastUpdatedDate,
      lastUpdatedAt: lastUpdated || 0,
      documentId: (listingAgreementData as any)?.objectID // Algolia objectID for the doc
    }
  }

  const scrollToRef = () =>
    scrollRef?.current?.scrollIntoView({behavior: 'smooth'})

  const getRowData = (rowData: Array<formattedListingAgreementData>) => {
    return (rowData || []).map((singleRowData, index) => {
      const {
        productId,
        accountId,
        contactId,
        status,
        recipientName,
        recipientEmail,
        lastUpdatedDate,
        documentId
      } = singleRowData

      return (
        <tr
          key={`agreement-row-data-${index}`}
          onClick={() => {
            navigate(
              isLAPage
                ? '/listing-agreement/agreement-details'
                : isMSAPage
                ? '/msa-agreement/agreement-details'
                : isACHPage
                ? '/ach-agreement/agreement-details'
                : '',
              {
                state: {
                  id: documentId,
                  isEdit: true,
                  currentPage
                },
                preventScrollReset: true
              }
            )
          }}
        >
          <td>
            {isLAPage
              ? productId
              : isMSAPage
              ? accountId
              : isACHPage
              ? contactId
              : '-' || '-'}
          </td>
          <td>{status || '-'}</td>
          <td>{recipientName || '-'}</td>
          <td style={{textTransform: 'none'}}>{recipientEmail || '-'}</td>
          <td>{lastUpdatedDate || '-'}</td>
        </tr>
      )
    })
  }

  const formattedListingAgreementData = listingAgreementData.map((data) =>
    getFormattedData(data)
  )
  const {items, sortBy} = useSortableData(formattedListingAgreementData)

  const showPagination = pageCount > TABLE_PAGINATION_PAGE_SIZE

  const tableTitles = [
    {
      title: isLAPage
        ? 'Equipment ID'
        : isMSAPage
        ? 'Account ID'
        : isACHPage
        ? 'Contact ID'
        : '-'
    },
    {title: 'Status', sortBy: () => sortBy('status')},
    {title: 'Recipient Name', sortBy: () => sortBy('recipientName')},
    {title: 'Recipient Email', sortBy: () => sortBy('recipientEmail')},
    {title: 'Last Updated', sortBy: () => sortBy('lastUpdatedAt')}
  ]

  const noData = !listingAgreementData.length && !loader

  return (
    <div className={styles.withLayout}>
      <Layout />
      <div className={classNames(styles.container, customClass)}>
        <NavBar title={pageTitle} />
        <div className={styles.button_filters}>
          <InputField
            name="agreementSearchField"
            type="text"
            value={searchValue}
            onChange={handleSearch}
            customClass={styles.inputField}
            placeholder="Search for Filters"
            isSearch={true}
          />
        </div>{' '}
        {loader ? (
          <Loader />
        ) : noData ? (
          <ReqNotFoundScreen />
        ) : (
          <React.Fragment>
            <Table titles={tableTitles} rowData={getRowData(items)} />
            {showPagination && (
              <div className="pagination__wrapper">
                <Pagination
                  currentPage={currentPage}
                  totalCount={pageCount}
                  pageSize={TABLE_PAGINATION_PAGE_SIZE}
                  onPageChange={onPageChange}
                />
              </div>
            )}
          </React.Fragment>
        )}
      </div>
    </div>
  )
}

export default withAuthorization(AgreementPage)
