import {TaxJarCustomer} from 'common'
import {fetcher, methods} from 'common/apis'
import {
  CREATE_TAXJAR_CUSTOMER_ENDPOINT,
  DELETE_TAXJAR_CUSTOMER_ENDPOINT,
  GENERATE_SALES_TAX_ENDPOINT,
  LIST_TAXJAR_CUSTOMERS_ENDPOINT,
  UPDATE_TAXJAR_CUSTOMER_ENDPOINT,
  VALIDATE_CUSTOMER_ADDRESS,
  VIEW_TAXJAR_CUSTOMER_ENDPOINT
} from 'common/constants'
import {API_HOST_URL} from 'components/constants'

const CREATE_CUSTOMER = `${API_HOST_URL}${CREATE_TAXJAR_CUSTOMER_ENDPOINT}`
const UPDATE_CUSTOMER = `${API_HOST_URL}${UPDATE_TAXJAR_CUSTOMER_ENDPOINT}`
const VIEW_CUSTOMER = `${API_HOST_URL}${VIEW_TAXJAR_CUSTOMER_ENDPOINT}`
const DELETE_CUSTOMER = `${API_HOST_URL}${DELETE_TAXJAR_CUSTOMER_ENDPOINT}`
const LIST_CUSTOMERS = `${API_HOST_URL}${LIST_TAXJAR_CUSTOMERS_ENDPOINT}`
const GENERATE_SALES_TAX = `${API_HOST_URL}${GENERATE_SALES_TAX_ENDPOINT}`
const VALIDATE_ADDRESS = `${API_HOST_URL}${VALIDATE_CUSTOMER_ADDRESS}`

export const createTaxJarCustomer = async ({
  accountId
}: {
  accountId: string
}) => {
  const {data, error} = await fetcher(
    `${CREATE_CUSTOMER}/${accountId}`,
    methods.PATCH
  )
  return {data, error}
}
export const viewTaxJarCustomer = async ({accountId}: {accountId: string}) => {
  const {data, error} = await fetcher(
    `${VIEW_CUSTOMER}/${accountId}`,
    methods.GET
  )
  return {data, error}
}

export const listTaxJarCustomers = async () => {
  const {data, error} = await fetcher(`${LIST_CUSTOMERS}`, methods.GET)
  return {data, error}
}

export const deleteTaxJarCustomer = async ({
  accountId
}: {
  accountId: string
}) => {
  const {data, error} = await fetcher(
    `${DELETE_CUSTOMER}/${accountId}`,
    methods.DELETE
  )
  return {data, error}
}

export const updateTaxJarCustomer = async ({
  accountId,
  customerObj
}: {
  accountId: string
  customerObj: TaxJarCustomer
}) => {
  const {data, error} = await fetcher(
    `${UPDATE_CUSTOMER}/${accountId}`,
    methods.PATCH,
    {...customerObj}
  )
  return {data, error}
}

export const generateSalesTax = async ({invoiceId}: {invoiceId: string}) => {
  const {data, error} = await fetcher(
    `${GENERATE_SALES_TAX}/${invoiceId}`,
    methods.POST
  )
  return {data, error}
}

export const validateAddress = async (address: {
  city?: string
  country?: string
  state?: string
  zip?: string
}) => {
  try {
    // const {data} = await fetcher(
    //   `${API_HOST_URL}${VALIDATE_CUSTOMER_ADDRESS}`,
    //   methods.POST,
    //   {...address}
    // )
    // return data.status ? {data} : {error: data.msg}
    return {data: true}
  } catch (error: any) {
    return {error}
  }
}
