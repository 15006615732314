import {FeedbackDocument} from 'common'
import AlgoliaClient, {
  FEEDBACKS_ADMIN_PANEL_ALGOLIA_INDEX
} from 'components/AlgoliaSearch'
import FeedbackCardModal from 'components/FeedbackCardModal'
import InputField from 'components/InputField'
import ModalComponent from 'components/ModalComponent'
import Pagination from 'components/Pagination'
import ReqNotFoundScreen from 'components/ReqNotFoundScreen'
import {TABLE_PAGINATION_PAGE_SIZE} from 'components/constants'
import {Timestamp} from 'firebase/firestore'
import {debounce} from 'lodash'
import React, {useEffect, useLayoutEffect, useState} from 'react'
import {useLocation} from 'react-router-dom'
import Layout from '../Layout'
import {Loader} from '../Loader'
import NavBar from '../Navbar'
import withAuthorization from '../Session/withAuthorization'
import Table from '../TableComponent'
import {getFormattedDate, searchWithAlgolia, useSortableData} from '../utils'
import styles from './FeedbackPage.module.scss'

const Feedback = ({customClass}: {customClass: string}) => {
  const {state} = useLocation()
  const pageFromLocation = state?.page
  const [currentPage, setCurrentPage] = useState<number>(pageFromLocation || 1)
  const [pageCount, setPageCount] = useState<number>(0)
  const [feedbackData, setFeedbackData] = useState<Array<FeedbackDocument>>([])
  const [currentFeedback, setCurrentFeedback] =
    useState<FeedbackDocument | null>(null)
  const [loader, setLoader] = useState(true)
  const [show, setShow] = useState<boolean>(false)

  const toggleShow = () => setShow(!show)

  useEffect(() => {
    AlgoliaClient.clearCache()
    searchWithAlgolia<FeedbackDocument>({
      searchQuery: '*',
      searchIndex,
      setLoaderState: setLoader,
      setData: setFeedbackData,
      setPageCount,
      currentPage
    }).then(() => setLoader(false))
  }, [])

  const tableTitles = [
    {title: 'Tag', sortBy: () => sortBy('tag')},
    {title: 'Contact Name', sortBy: () => sortBy('userName')},
    {title: 'Equipment Name', sortBy: () => sortBy('productName')},
    {title: 'Message', sortBy: () => sortBy('message')},
    {title: 'Created At', sortBy: () => sortBy('date')}
  ]

  const [searchValue, setSearchValue] = useState('')
  const searchIndex = AlgoliaClient.initIndex(
    FEEDBACKS_ADMIN_PANEL_ALGOLIA_INDEX
  )
  const debouncedSearch = React.useRef(
    debounce(searchWithAlgolia<FeedbackDocument>, 800)
  )

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event?.target?.value
    setSearchValue(value)
    if (value) {
      debouncedSearch?.current({
        searchQuery: value,
        searchIndex,
        setLoaderState: setLoader,
        setData: setFeedbackData,
        setPageCount
      })
    } else {
      debouncedSearch?.current({
        searchQuery: '*',
        searchIndex,
        setLoaderState: setLoader,
        setData: setFeedbackData,
        setPageCount,
        currentPage
      })
    }
  }

  const getFormattedData = (feedbackData: FeedbackDocument) => {
    const {createdAt} = feedbackData
    const createdAtDate =
      typeof createdAt === 'number'
        ? Timestamp.fromMillis(createdAt)
        : createdAt
    return {
      ...feedbackData,
      createdAtDateTime: getFormattedDate(createdAtDate),
      date: createdAt || 0
    }
  }

  interface IFormattedFeedbackData extends FeedbackDocument {
    createdAtDateTime: string
    date: string | number
  }

  const getRowData = (rowData: Array<IFormattedFeedbackData>) => {
    return (rowData || []).map((singleRowData, index) => {
      const {tag, userName, productName, message, createdAtDateTime} =
        singleRowData
      const formattedMessage =
        message.length > 16 ? `${message.slice(0, 16).trim()}...` : message
      return (
        <tr
          key={`feedback-row-data-${index}`}
          onClick={() => {
            setCurrentFeedback(singleRowData)
          }}
        >
          <td>{tag || '-'}</td>
          <td>{userName || '-'}</td>
          <td>{productName || '-'}</td>
          <td>{formattedMessage || '-'}</td>
          <td>{createdAtDateTime || '-'}</td>
        </tr>
      )
    })
  }

  const noData = !feedbackData.length && !loader

  const scrollRef: React.RefObject<HTMLDivElement> = React.createRef()

  const scrollToRef = () =>
    scrollRef?.current?.scrollIntoView({behavior: 'smooth'})

  const formattedFeedbackData = feedbackData.map((data) =>
    getFormattedData(data)
  )
  const {items, sortBy} = useSortableData(formattedFeedbackData)

  const showPagination = pageCount > TABLE_PAGINATION_PAGE_SIZE

  const onPageChange = (page: number) => {
    setLoader(true)
    setCurrentPage(page)
    debouncedSearch?.current({
      searchQuery: searchValue,
      searchIndex,
      setLoaderState: setLoader,
      setData: setFeedbackData,
      setPageCount,
      currentPage: page
    })
    setLoader(false)
    scrollToRef()
  }

  useLayoutEffect(() => {
    if (currentFeedback) {
      toggleShow()
    }
  }, [currentFeedback])

  return (
    <div className={styles.withLayout}>
      <Layout />
      <div className={[styles.container, customClass].join(' ')}>
        <ModalComponent handleClose={toggleShow} show={show}>
          <FeedbackCardModal
            handleClose={toggleShow}
            feedbackItem={currentFeedback}
            key={`${currentFeedback?.productId || ''}+${
              currentFeedback?.buyerContactId || ''
            }`}
          />
        </ModalComponent>
        <NavBar title="Feedbacks" />
        <div className={styles.button_filters}>
          <InputField
            name="feedbackSearchField"
            type="text"
            value={searchValue}
            onChange={handleSearch}
            customClass={styles.inputField}
            placeholder="Search for Filters"
            isSearch={true}
          />
        </div>
        {loader ? (
          <Loader />
        ) : noData ? (
          <ReqNotFoundScreen />
        ) : (
          <React.Fragment>
            <Table titles={tableTitles} rowData={getRowData(items)} />
            {showPagination && (
              <div className="pagination__wrapper">
                <Pagination
                  currentPage={currentPage}
                  totalCount={pageCount}
                  pageSize={TABLE_PAGINATION_PAGE_SIZE}
                  onPageChange={onPageChange}
                />
              </div>
            )}
          </React.Fragment>
        )}
      </div>
    </div>
  )
}

export default withAuthorization(Feedback)
