import {Cancel} from 'assets/svgs'
import {FILE_OBJECT, Subcategory} from 'common'
import Button from 'components/Button'
import {useFirebase} from 'components/Firebase/hooks'
import InputField from 'components/InputField'
import {Loader} from 'components/Loader'
import ModalComponent from 'components/ModalComponent'
import UploadFileSection from 'components/ProductDetailPage/UploadFileSection'
import {generateRandomId} from 'components/utils'
import {Formik, FormikProps} from 'formik'
import {cloneDeep, first, get} from 'lodash'
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState
} from 'react'
import {toast} from 'react-toastify'
import styles from './SubcategoryModal.module.scss'
import {CreateSubcategorySchema} from './schema'

interface ISubcategoryFormState {
  imageUrl?: Array<FILE_OBJECT | undefined>
  name: string
}

const SubcategoryModal = ({
  setSubcategories,
  subcategoryId,
  subcategories,
  handleClose,
  categoryId,
  isEdit,
  data,
  show
}: {
  show: boolean
  isEdit: boolean
  data?: Subcategory
  categoryId: string
  subcategoryId?: string
  handleClose: () => void
  subcategories?: Subcategory[]
  setSubcategories?: Dispatch<SetStateAction<Subcategory[]>>
}) => {
  const [imageFile, setImageFile] = useState<FILE_OBJECT>()
  const [loader, setLoader] = useState(isEdit ? true : false)
  const [isUploading, setIsUploading] = useState<boolean>(false)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [isRemoving, setIsRemoving] = useState<boolean>(false)
  const formRef: React.Ref<FormikProps<ISubcategoryFormState>> = useRef(null)
  const {
    createOrEditSubcateogry,
    deleteSubcateogry,
    uploadFile,
    getFileInfoFromStorage
  } = useFirebase()
  const appState = {
    documentData: isEdit
      ? {
          name: data?.name || '',
          nodeId: data?.nodeId || '',
          imageUrl: data?.imageUrl || ''
        }
      : {
          name: '',
          nodeId: '',
          imageUrl: ''
        }
  }

  useEffect(() => {
    if (!appState.documentData?.imageUrl?.length) return
    getFileInfoFromStorage(appState.documentData?.imageUrl).then((file) => {
      if (file) {
        const downloadURL = `${file?.downloadURL}?time=${Date.now()}`
        setImageFile({
          ...file,
          downloadURL
        })
        setLoader(false)
      }
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appState.documentData?.imageUrl])

  const handleDeletion = async () => {
    if (!subcategoryId) return
    setIsRemoving(true)
    await deleteSubcateogry({categoryId, subcategoryId})
    const updatedArr = subcategories?.filter(
      (subCat) => subCat.nodeId !== subcategoryId
    )
    setSubcategories?.(updatedArr || [])
    toast.success('Subcategory deleted successfully')
    setIsRemoving(false)
    handleClose()
    return
  }

  const placeHolderImage =
    'https://firebasestorage.googleapis.com/v0/b/auctionsite.appspot.com/o/assets%2FImagePlaceholder.png?alt=media&token=d33e0dbc-6ae2-403b-b8af-d689a6304235'

  return (
    <ModalComponent handleClose={handleClose} show={show}>
      <div>
        <div className={styles.container}>
          <div className={styles.content}>
            <div className={styles.heading}>
              <Cancel className={styles.cancel} onClick={handleClose} />
            </div>
            <h1 className={styles.heading_content}>
              {isEdit ? 'Edit' : 'Add'} Subcategory
            </h1>
            {loader ? (
              <Loader fullHeight={false} />
            ) : (
              <Formik
                innerRef={formRef}
                initialValues={{
                  name: appState.documentData?.name || '',
                  imageUrl: (imageFile && [imageFile]) || []
                }}
                validationSchema={CreateSubcategorySchema}
                onSubmit={async (values: ISubcategoryFormState) => {
                  const subcategoryNodeId = subcategoryId || generateRandomId()
                  const image = first(values.imageUrl)
                  const hasImage = image?.type?.includes('image') || false
                  if (isUploading) {
                    toast.warn('Please wait till the files finish uploading')
                    return
                  }
                  if (!hasImage) {
                    toast.warn('Please Upload an Image')
                    return
                  }
                  const imageUnchanged = image?.downloadURL || false
                  setIsSubmitting(true)
                  if (imageUnchanged) {
                    const {error, message} = await createOrEditSubcateogry({
                      categoryId,
                      subcategoryId: subcategoryNodeId,
                      isEdit,
                      name: `${values.name}`.trim()
                    })
                    setIsSubmitting(false)
                    if (error) {
                      toast.warning(`${error}`)
                      return
                    } else {
                      if (subcategories) {
                        const index = subcategories?.findIndex(
                          (subCat) => subCat.nodeId === subcategoryNodeId
                        )
                        const subcategoriesCopy = [...cloneDeep(subcategories)]
                        subcategories[index] = {
                          ...subcategories[index],
                          name: values?.name
                        }
                        setSubcategories?.(subcategoriesCopy)
                      }
                      toast.success(message)
                      handleClose()
                    }
                    return
                  } else {
                    await uploadFile({
                      file: image as any as File,
                      filePath: `categories/${categoryId}/subcategories/${subcategoryNodeId}/image/category-image`,
                      onFileUpload: async (downloadURL?: string) => {
                        const {error, message} = await createOrEditSubcateogry({
                          categoryId,
                          subcategoryId: subcategoryNodeId,
                          name: `${values.name}`.trim(),
                          imageUrl:
                            `${downloadURL}?time=${Date.now()}` ||
                            placeHolderImage,
                          isEdit
                        })
                        if (isEdit && subcategories) {
                          const index = subcategories?.findIndex(
                            (subCat) => subCat.nodeId === subcategoryNodeId
                          )
                          const subcategoriesCopy = [
                            ...cloneDeep(subcategories)
                          ]
                          subcategories[index] = {
                            ...subcategories[index],
                            name: values?.name,
                            imageUrl: `${downloadURL}?time=${Date.now()}`
                          }
                          setSubcategories?.(subcategoriesCopy)
                        }
                        if (error) {
                          setIsSubmitting(false)
                          toast.warning(`${error}`)
                          return
                        } else {
                          setIsSubmitting(false)
                          toast.success(message)
                          handleClose()
                        }
                        return
                      }
                    })
                  }
                }}
              >
                {(props) => {
                  const {
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue
                  } = props
                  return (
                    <form
                      className={styles.from_submiting}
                      onSubmit={handleSubmit}
                    >
                      <div className={styles.formContainer}>
                        <div className={styles.inputs}>
                          <div className={styles.inputContainer}>
                            <div className={styles.inputField}>
                              <InputField
                                type="text"
                                name="name"
                                errors={errors}
                                touched={touched}
                                placeholder="Name"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={get(values, 'name')}
                              />
                            </div>
                          </div>
                          <div className={styles.upload_contianer}>
                            <UploadFileSection
                              values={values}
                              errors={errors}
                              touched={touched}
                              productID={categoryId}
                              handleBlur={handleBlur}
                              isCategoryUpload={true}
                              isUploading={isUploading}
                              setFieldValue={setFieldValue}
                              setIsUploading={setIsUploading}
                              deleteCallback={() =>
                                (appState.documentData.imageUrl = '')
                              }
                            />
                          </div>
                        </div>
                      </div>
                      {isEdit ? (
                        <div className={styles.buttons}>
                          <Button
                            customBtnClass="btn-cancel"
                            disabled={isRemoving}
                            onClick={handleDeletion}
                            type="button"
                            text="Remove"
                          />
                          <Button
                            customBtnClass="btn-fill-orange"
                            disabled={isSubmitting}
                            text={'Submit'}
                          />
                        </div>
                      ) : (
                        <div className={styles.buttons}>
                          <button
                            type="button"
                            className="btn-fill-grey"
                            onClick={handleClose}
                          >
                            Cancel
                          </button>
                          <Button
                            customBtnClass="btn-fill-orange"
                            disabled={isSubmitting}
                            text={'Submit'}
                          />
                        </div>
                      )}
                    </form>
                  )
                }}
              </Formik>
            )}
          </div>
        </div>
      </div>
    </ModalComponent>
  )
}

export default SubcategoryModal
