import axios from 'axios'
import {
  AccountDocument,
  AdminDocument,
  BankAccountInfoDocument,
  Bid,
  Category,
  ContactDocument,
  DealDocument,
  ExemptRegions,
  FILE_OBJECT,
  FrontEndNotification,
  InvoiceComment,
  InvoiceDocument,
  Map,
  Message,
  PersonalDetail,
  ProductDocument,
  Sector
} from 'common'
import {methods} from 'common/apis'
import {InvoiceItemType} from 'common/apis/enums'
import {
  AdminNotifications,
  AllUsersNotifications,
  SellerNotifications,
  sendNotification
} from 'common/apis/notifications'
import {
  APIDealDocument,
  AddContactAPIDocument,
  EditAccountAPIDocument,
  EditContactAPIDocument
} from 'common/apis/types'
import {
  ACH_TRANSFER_ENDPOINT,
  ADMIN_DENY_ACCESS_ENDPOINT,
  ADMIN_GRANT_ACCESS_ENDPOINT,
  CREATE_ACCOUNT_ENDPOINT,
  CREATE_ADMIN_ENDPOINT,
  CREATE_CONTACT_ENDPOINT,
  CREATE_DEAL_ENDPOINT,
  CREATE_PRODUCT_ENDPOINT,
  DELETE_ACCOUNT_ENDPOINT,
  DELETE_ADMIN_ENDPOINT,
  DELETE_CONTACT_ENDPOINT,
  DELETE_PRODUCT_ENDPOINT,
  EDIT_ACCOUNT_ENDPOINT,
  EDIT_ADMIN_ENDPOINT,
  EDIT_CONTACT_ENDPOINT,
  EDIT_PRODUCT_ENDPOINT,
  FINANCE_CONTACT_ACTION_ENDPOINT,
  FIREBASE_TO_ZOHO_ACCOUNT_SYNC_ENDPOINT,
  ZOHO_SIGN_GENERAL_RECALL_AGREEMENT,
  FIREBASE_TO_ZOHO_PRODUCT_SYNC_ENDPOINT,
  ZOHO_TO_FIREBASE_WEBSITE_ACCOUNT_SYNC_ENDPOINT,
  INVOICE_ADD_COMMENT_ENDPOINT,
  INVOICE_LINE_ITEMS_ENDPOINT,
  INVOICE_MARK_BUYER_WIRE_AS_PAID_ENDPOINT,
  INVOICE_REMOVE_ITEM_ENDPOINT,
  UPDATE_EXEMPTION_TYPE_AND_REGIONS_ENDPOINT,
  UPDATE_TAX_EXEMPTION_CERTIFICATE_ENDPOINT,
  ZOHO_SIGN_GENERAL_MSA_AGREEMENT,
  ZOHO_SIGN_LISTING_AGREEMENT,
  ZOHO_TO_FIREBASE_ACCOUNT_SYNC_ENDPOINT,
  ZOHO_TO_FIREBASE_PRODUCT_SYNC_ENDPOINT
} from 'common/constants'
import {
  Collection,
  Environment,
  ExemptionType,
  SubCollection,
  adminRole,
  applicationStatus,
  dealStage,
  equipmentOptions,
  productStatus
} from 'common/enums'
import {IAppContext} from 'components/Context'
import {API_HOST_URL} from 'components/constants'
import {UserInformation} from 'components/types'
import {getUniqueObjects, trimStringFields} from 'components/utils'
import {initializeApp} from 'firebase/app'
import {
  EmailAuthProvider,
  NextOrObserver,
  User,
  connectAuthEmulator,
  getAuth,
  reauthenticateWithCredential,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
  updatePassword
} from 'firebase/auth'
import {
  connectDatabaseEmulator,
  ref as dbRef,
  get,
  getDatabase,
  onChildAdded,
  push,
  remove,
  set,
  update
} from 'firebase/database'
import {
  DocumentData,
  DocumentSnapshot,
  QueryDocumentSnapshot,
  Timestamp,
  Unsubscribe,
  collection,
  connectFirestoreEmulator,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  limit,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  startAfter,
  updateDoc,
  where
} from 'firebase/firestore'
import {getMessaging, getToken} from 'firebase/messaging'
import {
  StorageError,
  connectStorageEmulator,
  deleteObject,
  getMetadata,
  getStorage,
  ref as storageRef,
  uploadBytesResumable
} from 'firebase/storage'
import localforage from 'localforage'
import _, {first, isEmpty, isNull, isNumber, last} from 'lodash'

export const ERRORS_CODE_MESSAGES_MAP: Map = {
  'auth/invalid-login-credentials': 'Incorrect email or password.',
  'auth/wrong-password': 'Incorrect email or password.',
  'auth/user-not-found': "Email doesn't exist.",
  'auth/weak-password': 'Password should be at least 6 characters.',
  'auth/invalid-email': 'Invalid email.',
  'auth/too-many-requests':
    'You have sent too many requests. Please try again later.'
}

const headers = {
  'DAX-Header-67-e89b-12d3-a456-426641000-R22': 'TOKEN HERE'
}

const environment =
  (process.env.REACT_APP_PUBLIC_APPLICATION_ENVIRONMENT as Environment) ||
  Environment.DEVELOPMENT

const firebaseConfig = {
  [Environment.DEVELOPMENT]: {
    appId: process.env.REACT_APP_PUBLIC_DEVELOPMENT_APP_ID,
    apiKey: process.env.REACT_APP_PUBLIC_DEVELOPMENT_API_KEY,
    authDomain: process.env.REACT_APP_PUBLIC_DEVELOPMENT_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_PUBLIC_DEVELOPMENT_DATABASE_URL,
    projectId: process.env.REACT_APP_PUBLIC_DEVELOPMENT_PROJECT_ID,
    storageBucket: process.env.REACT_APP_PUBLIC_DEVELOPMENT_STORAGE_BUCKET,
    messagingSenderId:
      process.env.REACT_APP_PUBLIC_DEVELOPMENT_MESSAGING_SENDER_ID
  },
  [Environment.STAGING]: {
    appId: process.env.REACT_APP_PUBLIC_STAGING_APP_ID,
    apiKey: process.env.REACT_APP_PUBLIC_STAGING_API_KEY,
    authDomain: process.env.REACT_APP_PUBLIC_STAGING_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_PUBLIC_STAGING_DATABASE_URL,
    projectId: process.env.REACT_APP_PUBLIC_STAGING_PROJECT_ID,
    storageBucket: process.env.REACT_APP_PUBLIC_STAGING_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_PUBLIC_STAGING_MESSAGING_SENDER_ID
  },
  [Environment.PRODUCTION]: {
    appId: process.env.REACT_APP_PUBLIC_PRODUCTION_APP_ID,
    apiKey: process.env.REACT_APP_PUBLIC_PRODUCTION_API_KEY,
    authDomain: process.env.REACT_APP_PUBLIC_PRODUCTION_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_PUBLIC_PRODUCTION_DATABASE_URL,
    projectId: process.env.REACT_APP_PUBLIC_PRODUCTION_PROJECT_ID,
    storageBucket: process.env.REACT_APP_PUBLIC_PRODUCTION_STORAGE_BUCKET,
    messagingSenderId:
      process.env.REACT_APP_PUBLIC_PRODUCTION_MESSAGING_SENDER_ID
  }
}

class Firebase {
  private static instance: Firebase

  public app: ReturnType<typeof initializeApp>
  public auth: ReturnType<typeof getAuth>
  public firestore: ReturnType<typeof getFirestore>
  public database: ReturnType<typeof getDatabase>
  public storage: ReturnType<typeof getStorage>

  private constructor() {
    const useEmulators = process.env.REACT_APP_USE_EMULATOR === 'true'

    this.app = initializeApp(firebaseConfig[environment])
    this.auth = getAuth()
    this.firestore = getFirestore()
    this.database = getDatabase()
    this.storage = getStorage()

    if (useEmulators) {
      console.log('💻 Firebase is running in dev mode...')

      connectAuthEmulator(this.auth, 'http://localhost:9099')
      connectFirestoreEmulator(this.firestore, 'localhost', 8080)
      connectDatabaseEmulator(this.database, 'localhost', 9000)
      connectStorageEmulator(this.storage, 'localhost', 9199)
    } else {
      console.log('⚠ Firebase is running in production mode!')
    }
  }

  public static getInstance(): Firebase {
    if (!Firebase.instance) {
      Firebase.instance = new Firebase()
    }
    return Firebase.instance
  }

  sessionObserver = (callback: NextOrObserver<User | null>) =>
    this.auth.onAuthStateChanged(callback)

  handleAPIRequest = async (
    endpoint: string,
    method: string = 'GET',
    data: any = null
  ) => {
    try {
      const options = {
        method,
        url: `${API_HOST_URL}${endpoint}`,
        headers,
        ...(data && {data})
      }

      const rawResponse = await axios(options)
      const message = rawResponse.data.msg

      return {
        response: rawResponse,
        message
      }
    } catch (err: any) {
      const {response} = err
      const {data, status} = response

      return {
        error: data?.msg || data?.data || err.message,
        errorCode: status
      }
    }
  }

  getSectors = async () => {
    const sectorsRef = dbRef(this.database, 'sectors')
    const snapshot = await get(sectorsRef)
    const data: Array<Sector> = []
    snapshot.forEach((childSnapshot) => {
      const childData = childSnapshot.val()
      data.push({
        nodeId: childData?.nodeId,
        name: childData?.name
      })
    })
    return _.sortBy(data, ['name'])
  }

  recallAgreement = (
    cancelReason: string,
    documentId: string,
    agreementType:
      | Collection.ZohoSignGeneralMsaAgreement
      | Collection.ZohoSignListingAgreement
  ) => {
    const endpoint = ZOHO_SIGN_GENERAL_RECALL_AGREEMENT
    return this.handleAPIRequest(endpoint, methods.POST, {
      documentId,
      agreementType,
      cancelReason
    })
  }

  handleSyncFirebaseToZohoAccountAPI = async () => {
    const endpoint = FIREBASE_TO_ZOHO_ACCOUNT_SYNC_ENDPOINT
    return this.handleAPIRequest(endpoint)
  }

  handleSyncZohoToFirebaseProductAPI = async () => {
    const endpoint = ZOHO_TO_FIREBASE_PRODUCT_SYNC_ENDPOINT
    return this.handleAPIRequest(endpoint)
  }

  handleSyncZohoToFirebaseAccountAPI = async () => {
    const endpoint = ZOHO_TO_FIREBASE_ACCOUNT_SYNC_ENDPOINT
    return this.handleAPIRequest(endpoint)
  }

  handleSyncFirebaseToZohoProductAPI = async () => {
    const endpoint = FIREBASE_TO_ZOHO_PRODUCT_SYNC_ENDPOINT
    return this.handleAPIRequest(endpoint)
  }

  handleSyncZohoToFirebaseWebsiteAccountAPI = async () => {
    const endpoint = ZOHO_TO_FIREBASE_WEBSITE_ACCOUNT_SYNC_ENDPOINT
    return this.handleAPIRequest(endpoint)
  }

  getCategories = async () => {
    try {
      const categoriesRef = dbRef(this.database, 'categories')
      const snapshot = await get(categoriesRef)
      const data: Array<Category> = []
      snapshot.forEach((childSnapshot) => {
        const childData = childSnapshot.val()
        data.push({
          nodeId: childData?.nodeId,
          slug: childData?.slug,
          name: childData?.name,
          imageUrl: childData?.imageUrl,
          subcategories: childData?.subcategories,
          isPopularCategoryMap: childData?.isPopularCategoryMap
        })
      })
      return {data: data?.sort((a, b) => a.name.localeCompare(b.name))}
    } catch (error: unknown) {
      return {error}
    }
  }

  getSingleCategory = async (nodeId: string) => {
    try {
      const categoriesRef = dbRef(this.database, `categories/${nodeId}/`)
      const snapshot = await get(categoriesRef)
      return {data: snapshot.val() as Category}
    } catch (error: unknown) {
      return {error}
    }
  }

  getSingleSector = async (nodeId: string) => {
    try {
      const sectorsRef = dbRef(this.database, `sectors/${nodeId}/`)
      const snapshot = await get(sectorsRef)
      return {data: snapshot.val() as Sector}
    } catch (error: unknown) {
      return {error}
    }
  }

  getFileInfoFromStorage = async (
    filePath: string
  ): Promise<FILE_OBJECT | null> => {
    try {
      const ref = storageRef(this.storage, filePath)
      const metadata = await getMetadata(ref)
      const fileSizeInBytes = metadata.size
      const fileType = metadata.contentType || ''
      const fileName = metadata.name
      const sizeInMB = fileSizeInBytes / 1024 ** 2
      const originalFileName = metadata.customMetadata?.fileName
      const downloadUrl = `https://storage.googleapis.com/${ref.bucket}/${ref.fullPath}`

      return {
        type: fileType,
        mbSize: sizeInMB,
        name: fileName,
        URL: downloadUrl,
        downloadURL: downloadUrl,
        originalName: originalFileName || fileName
      }
    } catch (error) {
      console.error('Error retrieving file info:', error)
      return null
    }
  }

  uploadFile = async ({
    file,
    onFileUpload,
    fileName,
    fileType,
    isCategory,
    onError,
    onProgressUpdate,
    filePath,
    productId
  }: {
    file: File
    onError?: (error?: StorageError) => void
    onFileUpload: (downloadURL?: string) => void
    onProgressUpdate?: (progress: number) => void
    productId?: string
    fileType?: string
    fileName?: string
    filePath?: string
    isCategory?: boolean
  }) => {
    const nameOfFile = fileName || file.name || ''
    const storageReference = filePath
      ? storageRef(this.storage, filePath)
      : fileType
      ? isCategory
        ? storageRef(
            this.storage,
            `categories/${productId}/image/category-image`
          )
        : storageRef(
            this.storage,
            `products/${productId}/${fileType}/${nameOfFile}`
          )
      : storageRef(this.storage, nameOfFile)

    try {
      const metadata = {
        customMetadata: {
          [isCategory ? 'categoryId' : productId ? 'productId' : '']: productId
            ? productId
            : '',
          fileName: file.name
        }
      }
      const uploadTask = uploadBytesResumable(
        storageReference,
        file,
        _.omit(metadata, '')
      )
      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          onProgressUpdate && onProgressUpdate(progress)
        },
        (err) => onError?.(err),
        async () => {
          const downloadUrl = `https://storage.googleapis.com/${uploadTask.snapshot.ref.bucket}/${uploadTask.snapshot.ref.fullPath}`
          onFileUpload && onFileUpload(downloadUrl)
        }
      )
      return {success: true}
    } catch (error: any) {
      const code = error.code
      return {
        error: {
          success: false,
          code,
          message: ERRORS_CODE_MESSAGES_MAP[code]
        }
      }
    }
  }

  deleteFile = async ({
    fileName,
    fileType,
    isCategory,
    productID,
    filePath
  }: {
    fileName?: string
    productID?: string
    fileType?: string
    isCategory?: boolean
    filePath?: string
  }) => {
    const deleteRef = filePath
      ? storageRef(this.storage, filePath)
      : productID && fileType
      ? isCategory
        ? storageRef(
            this.storage,
            `categories/${productID}/image/category-image`
          )
        : storageRef(
            this.storage,
            `products/${productID}/${fileType}/${fileName}`
          )
      : storageRef(this.storage, fileName)
    try {
      await deleteObject(deleteRef)
      if (isCategory) {
        const ref = dbRef(this.database, `categories/${productID}`)
        const placeHolderImage =
          'https://firebasestorage.googleapis.com/v0/b/auctionsite.appspot.com/o/assets%2FImagePlaceholder.png?alt=media&token=d33e0dbc-6ae2-403b-b8af-d689a6304235'
        await update(ref, {
          imageUrl: placeHolderImage
        })
      }
      return {}
    } catch (error) {
      return {
        error
      }
    }
  }

  createOrEditSector = async ({name, nodeId}: Sector, isEdit: boolean) => {
    try {
      if (!nodeId || !name)
        throw new Error('Something went wrong while creating sector')

      const sectorRef = dbRef(this.database, `sectors/${nodeId}`)
      await set(sectorRef, {
        name,
        nodeId
      })
      return {message: `Sector ${isEdit ? 'Edited' : 'Added'} Successfully`}
    } catch (error: unknown) {
      return {
        error
      }
    }
  }

  createOrEditCateogry = async (
    {name, nodeId, imageUrl, isPopularCategoryMap, slug}: Category,
    isEdit: boolean
  ) => {
    try {
      if (!nodeId || !name)
        throw new Error('Something went wrong while creating category')

      const hasImage = imageUrl ? {imageUrl} : {}

      if (isEdit === false) {
        isPopularCategoryMap.isPopularCategoryTimestamp = Timestamp.now()
      }

      const categoryRef = dbRef(this.database, `categories/${nodeId}`)

      if (isEdit) {
        await update(categoryRef, {
          name,
          slug,
          nodeId,
          ...{...hasImage},
          isPopularCategoryMap
        })
      } else {
        await set(categoryRef, {
          name,
          slug,
          nodeId,
          imageUrl,
          isPopularCategoryMap
        })
      }
      return {message: `Category ${isEdit ? 'Edited' : 'Added'} Successfully`}
    } catch (error: unknown) {
      return {
        error
      }
    }
  }

  createOrEditSubcateogry = async ({
    subcategoryId,
    categoryId,
    imageUrl,
    isEdit,
    name
  }: {
    subcategoryId: string
    categoryId: string
    imageUrl?: string
    isEdit: boolean
    name: string
  }) => {
    try {
      const hasImage = imageUrl ? {imageUrl} : {}
      const categoryRef = dbRef(
        this.database,
        `categories/${categoryId}/subcategories/${subcategoryId}`
      )
      if (isEdit) {
        await update(categoryRef, {
          name,
          ...{...hasImage},
          nodeId: subcategoryId
        })
      } else {
        await set(categoryRef, {
          name,
          imageUrl,
          nodeId: subcategoryId
        })
      }
      return {
        message: `Subcategory ${isEdit ? 'Edited' : 'Added'} Successfully`
      }
    } catch (error: unknown) {
      return {
        error
      }
    }
  }

  deleteCateogry = async (nodeId: string) => {
    try {
      const collectionRef = collection(this.firestore, Collection.Products)
      const q = query(
        collectionRef,
        where('equipmentId', '==', nodeId),
        limit(1)
      )
      const product = await getDocs(q)
      if (!product.empty) {
        throw new Error(
          'This category cannot be deleted because there is equipment associated with it. You may only modify this category.'
        )
      }
      const categoryRef = dbRef(this.database, `categories/${nodeId}`)
      await remove(categoryRef)
      const deleteRef = storageRef(
        this.storage,
        `categories/${nodeId}/image/category-image`
      )
      await deleteObject(deleteRef)
      return {}
    } catch (error: unknown) {
      const err = error as any
      return {error: err?.message ? err?.message : err}
    }
  }

  deleteSector = async (nodeId: string) => {
    try {
      const collectionRef = collection(this.firestore, Collection.Accounts)
      const q = query(collectionRef, where('sector', '==', nodeId), limit(1))
      const account = await getDocs(q)
      if (!account.empty) {
        throw new Error(
          'This sector cannot be deleted because there are accounts associated with it. You may only modify this sector.'
        )
      }
      const sectorRef = dbRef(this.database, `sectors/${nodeId}`)
      await remove(sectorRef)
      return {}
    } catch (error: unknown) {
      const err = error as any
      return {error: err?.message ? err?.message : err}
    }
  }

  deleteSubcateogry = async ({
    categoryId,
    subcategoryId
  }: {
    categoryId: string
    subcategoryId: string
  }) => {
    try {
      const categoryRef = dbRef(
        this.database,
        `categories/${categoryId}/subcategories/${subcategoryId}`
      )
      await remove(categoryRef)
      const deleteRef = storageRef(
        this.storage,
        `categories/${categoryId}/subcategories/${subcategoryId}/image/category-image`
      )
      await deleteObject(deleteRef)
      return {}
    } catch (error: unknown) {
      return {error}
    }
  }

  deleteProduct = async (productID: string) => {
    try {
      const rawResponse = await axios.delete(
        `${API_HOST_URL}${DELETE_PRODUCT_ENDPOINT}/${productID}`,
        {
          headers
        }
      )
      const message = rawResponse.data.msg
      return {
        response: rawResponse,
        message
      }
    } catch (error: any) {
      const {response} = error
      const {data, status} = response
      return {
        error: data?.msg || data?.data || error,
        errorCode: status
      }
    }
  }

  setProductDocument = async <T>({
    productData,
    isEdit,
    productId
  }: {
    productData: T
    isEdit?: boolean
    productId?: string
  }) => {
    try {
      const rawResponse = isEdit
        ? await axios.patch(
            `${API_HOST_URL}${EDIT_PRODUCT_ENDPOINT}/${productId}`,
            {
              ...trimStringFields(productData),
              hasAdminEdited: true
            },
            {
              headers
            }
          )
        : await axios.post(
            `${API_HOST_URL}${CREATE_PRODUCT_ENDPOINT}`,
            {
              ...trimStringFields(productData)
            },
            {
              headers
            }
          )
      const message = rawResponse.data.msg
      const equipment = (rawResponse?.data?.data || {}) as ProductDocument
      return {
        message,
        equipment,
        code: rawResponse?.data?.code
      }
    } catch (err: any) {
      const {response} = err
      const {data, status} = response
      return {
        error: data?.msg || data?.data || err,
        errorCode: status
      }
    }
  }

  deleteAccount = async (accountID: string) => {
    try {
      const rawResponse = await axios.delete(
        `${API_HOST_URL}${DELETE_ACCOUNT_ENDPOINT}/${accountID}`,
        {
          headers
        }
      )
      const message = rawResponse.data.msg
      return {
        response: rawResponse,
        message
      }
    } catch (error: any) {
      const {response} = error
      const {data, status} = response
      return {
        error: data?.msg || data?.data || error,
        errorCode: status
      }
    }
  }

  deleteAdmin = async (adminID: string) => {
    try {
      const rawResponse = await axios.delete(
        `${API_HOST_URL}${DELETE_ADMIN_ENDPOINT}/${adminID}`,
        {
          headers
        }
      )
      const message = rawResponse.data.msg
      return {
        response: rawResponse,
        message
      }
    } catch (error: any) {
      const {response} = error
      const {data, status} = response
      return {
        error: data?.msg || data?.data || error,
        errorCode: status
      }
    }
  }

  updateProductDetails = async ({
    productDetails,
    productId
  }: {
    productId: string
    productDetails: {
      images?: Array<string>
      videos?: Array<string>
      driveUrls?: Array<string>
    }
  }) => {
    try {
      const docRef = doc(this.firestore, Collection.Products, productId)
      await setDoc(
        docRef,
        {
          productDetails: {
            ...productDetails
          }
        },
        {merge: true}
      )
      return {}
    } catch (error: any) {
      return {error}
    }
  }

  sendNoticeOfRelease = async (invoice: InvoiceDocument) => {
    const invoiceId = invoice?.invoiceId
    if (!invoiceId) return {success: false}
    try {
      const docRef = doc(this.firestore, Collection.Invoices, invoiceId)
      await sendNotification({
        baseURL: API_HOST_URL,
        code: SellerNotifications.NoticeOfRelease,
        contactId: invoice?.sellerContactId,
        productId: invoice.productId
      })
      await setDoc(docRef, {noticeOfRelease: Timestamp.now()}, {merge: true})
      return {success: true}
    } catch (error) {
      return {error, success: false}
    }
  }

  signAgreement = async ({
    agreementData,
    signMSA,
    signACH
  }: {
    agreementData: {
      consigneeName: string
      consigneeEmail: string
      productID?: string
      accountID?: string
      contactID?: string
      adminID?: string
    }
    signMSA?: boolean
    signACH?: boolean
  }) => {
    const endpoint = signMSA
      ? ZOHO_SIGN_GENERAL_MSA_AGREEMENT
      : signACH
      ? ACH_TRANSFER_ENDPOINT
      : ZOHO_SIGN_LISTING_AGREEMENT
    const {
      consigneeName,
      consigneeEmail,
      accountID,
      productID,
      contactID,
      adminID
    } = agreementData
    const APIData = signMSA
      ? {
          consignee_name: consigneeName || '',
          consignee_email: consigneeEmail || '',
          accountId: accountID || ''
        }
      : signACH
      ? {
          contactId: contactID || '',
          adminId: adminID || ''
        }
      : {
          consignee_name: consigneeName || '',
          consignee_email: consigneeEmail || '',
          productID: productID || ''
        }
    try {
      const rawResponse = await axios.post(
        `${API_HOST_URL}${endpoint}`,
        {
          ...APIData
        },
        {
          headers
        }
      )
      const message = rawResponse.data.msg
      const statusCode = rawResponse.data.code
      const errorCondition =
        statusCode === 409 ||
        statusCode === 500 ||
        statusCode === 404 ||
        rawResponse.data?.status === false
      if (signMSA && !errorCondition) {
        sendNotification({
          baseURL: API_HOST_URL,
          code: AdminNotifications.MSAAgreementSentToSeller,
          accountId: accountID
        })
      }
      if (!signMSA && !signACH && !errorCondition) {
        sendNotification({
          baseURL: API_HOST_URL,
          code: AdminNotifications.LAAgreementSentToSeller,
          productId: productID
        })
      }
      return {
        response: rawResponse,
        message,
        statusCode
      }
    } catch (err: any) {
      const {response} = err
      const {data, status} = response
      return {
        error: data?.msg || data?.data || err,
        errorCode: status
      }
    }
  }

  createAccount = async (accountData: AccountDocument) => {
    try {
      const rawResponse = await axios.post(
        `${API_HOST_URL}${CREATE_ACCOUNT_ENDPOINT}`,
        {
          ...accountData
        },
        {
          headers
        }
      )
      const message = rawResponse.data.msg
      return {
        response: rawResponse,
        message
      }
    } catch (err: any) {
      const {response} = err
      const {data, status} = response
      return {
        error: data?.msg || data?.data || err,
        errorCode: status
      }
    }
  }

  createAdmin = async (adminData: UserInformation) => {
    try {
      const rawResponse = await axios.post(
        `${API_HOST_URL}${CREATE_ADMIN_ENDPOINT}`,
        {
          ...adminData
        },
        {
          headers
        }
      )
      const message = rawResponse.data.msg
      return {
        response: rawResponse,
        message
      }
    } catch (err: any) {
      const {response} = err
      const {data, status} = response
      return {
        error: data?.msg || data?.data || err,
        errorCode: status
      }
    }
  }

  grantDenyAdminAccess = async (adminID: string, isGrant: boolean) => {
    try {
      const rawResponse = await axios.patch(
        `${API_HOST_URL}${
          isGrant ? ADMIN_GRANT_ACCESS_ENDPOINT : ADMIN_DENY_ACCESS_ENDPOINT
        }/${adminID}`,
        {},
        {
          headers
        }
      )
      const message = rawResponse.data.msg
      return {
        response: rawResponse,
        message
      }
    } catch (error: any) {
      const {response} = error
      const {data, status} = response
      return {
        error: data?.msg || data?.data || error,
        errorCode: status
      }
    }
  }

  resetPassword = async (email: string) => {
    try {
      await sendPasswordResetEmail(this.auth, email)
      return {
        success: true
      }
    } catch (error: any) {
      const code = error.code
      return {
        error: {
          code,
          message: ERRORS_CODE_MESSAGES_MAP[code]
        }
      }
    }
  }

  createContact = async (contactData: AddContactAPIDocument) => {
    try {
      const rawResponse = await axios.post(
        `${API_HOST_URL}${CREATE_CONTACT_ENDPOINT}`,
        {
          ...contactData
        },
        {
          headers
        }
      )
      const contactId = rawResponse.data.data.contactId
      if (contactId) {
        sendNotification({
          baseURL: API_HOST_URL,
          code: AdminNotifications.NewContactAddedFromAccount,
          contactId
        })
      }

      const message = rawResponse.data.msg
      return {
        response: rawResponse,
        message
      }
    } catch (err: any) {
      const {response} = err
      const {data, status} = response
      return {
        error: data?.msg || data?.data || err,
        errorCode: status
      }
    }
  }

  deleteContact = async (contactID: string) => {
    try {
      const rawResponse = await axios.delete(
        `${API_HOST_URL}${DELETE_CONTACT_ENDPOINT}/${contactID}`,
        {
          headers
        }
      )
      const message = rawResponse.data.msg
      return {
        response: rawResponse,
        message
      }
    } catch (error: any) {
      const {response} = error
      const {data, status} = response
      return {
        error: data?.msg || data?.data || error,
        errorCode: status
      }
    }
  }

  waiveBidderDeposit = async (userId: string, status: boolean) => {
    try {
      const documentRef = doc(
        collection(
          this.firestore,
          Collection.Contacts,
          userId,
          SubCollection.PersonalDetails
        ),
        SubCollection.PersonalDetails
      )
      await setDoc(
        documentRef,
        {stripe: {depositWaived: status, isPaymentHold: status}},
        {merge: true}
      )
      return {status: true}
    } catch (error) {
      return {
        status: false
      }
    }
  }

  getUserPersonalDetail = ({
    userId,
    cancelCallback,
    updateState
  }: {
    userId: string
    updateState: (e: any) => void
    cancelCallback: (e: Error) => void
  }) => {
    try {
      const documentRef = doc(
        collection(
          this.firestore,
          Collection.Contacts,
          userId,
          SubCollection.PersonalDetails
        ),
        SubCollection.PersonalDetails
      )
      const unSubscribe = onSnapshot(
        documentRef,
        (doc) => {
          const personalDetail: PersonalDetail | undefined =
            doc.data() as PersonalDetail
          updateState(personalDetail)
        },
        cancelCallback
      )
      return {unSubscribe}
    } catch (error) {
      return {error}
    }
  }

  checkCustomClaims = async () => {
    try {
      const idTokenResult: any = this.auth.currentUser
        ? await this.auth.currentUser.getIdTokenResult(true)
        : {}
      const userClaimsObject = idTokenResult.claims || {}
      const userAccountsList = userClaimsObject?.accounts || []
      const isSuperAdmin: boolean =
        userClaimsObject.hasOwnProperty('superAdmin') || false
      const isSuperManager: boolean =
        userClaimsObject.hasOwnProperty('superManager') || false
      const isMSAAdmin: boolean = userAccountsList.length || false
      const doesCustomClaimsExist: boolean =
        isSuperAdmin || isSuperManager || isMSAAdmin || false
      return {
        doesCustomClaimsExist,
        isSuperAdmin,
        isMSAAdmin,
        isSuperManager
      }
    } catch (error) {
      return {error}
    }
  }

  getUserAccounts = async () => {
    const idTokenResult: any = this.auth.currentUser
      ? await this.auth.currentUser.getIdTokenResult(true)
      : {}
    const userClaimsObject = idTokenResult?.claims || {}
    const userAccountsList = userClaimsObject?.accounts || []
    return userAccountsList as Array<string>
  }

  editContact = async (
    contactData: EditContactAPIDocument,
    contactID: string
  ) => {
    try {
      const rawResponse = await axios.patch(
        `${API_HOST_URL}${EDIT_CONTACT_ENDPOINT}/${contactID}`,
        {
          ...contactData
        },
        {
          headers
        }
      )
      const message = rawResponse.data.msg
      return {
        response: rawResponse,
        message
      }
    } catch (err: any) {
      const {response} = err
      const {data, status} = response
      return {
        error: data?.msg || data?.data || err,
        errorCode: status
      }
    }
  }

  editAdmin = async (adminData: UserInformation, adminID: string) => {
    try {
      const rawResponse = await axios.patch(
        `${API_HOST_URL}${EDIT_ADMIN_ENDPOINT}/${adminID}`,
        {
          ...adminData
        },
        {
          headers
        }
      )
      const message = rawResponse.data.msg
      return {
        response: rawResponse,
        message
      }
    } catch (error: any) {
      const {response} = error
      const {data, status} = response
      return {
        error: data?.msg || data?.data || error,
        errorCode: status
      }
    }
  }

  editAccount = async (
    accountData: EditAccountAPIDocument,
    accountId: string
  ) => {
    try {
      const rawResponse = await axios.patch(
        `${API_HOST_URL}${EDIT_ACCOUNT_ENDPOINT}/${accountId}`,
        {
          ...accountData
        },
        {
          headers
        }
      )
      const message = rawResponse.data.msg
      return {
        response: rawResponse,
        message
      }
    } catch (err: any) {
      const {response} = err
      const {data, status} = response
      return {
        error: data?.msg || data?.data || err,
        errorCode: status
      }
    }
  }

  signIn = async (email: string, password: string) => {
    try {
      const {
        user: {uid}
      } = await signInWithEmailAndPassword(this.auth, email, password)
      return {
        success: true,
        uid
      }
    } catch (error: any) {
      const code = error.code
      return {
        error: {
          code,
          message: ERRORS_CODE_MESSAGES_MAP[code]
        }
      }
    }
  }

  getCollectionBasedOnQuery = async <T>(
    collectionName: string,
    queryVariable: string,
    queryValue: string | boolean
  ) => {
    const uid = this.auth?.currentUser?.uid
    if (!uid) return {}
    try {
      const queryCollection = collection(this.firestore, collectionName)
      const querySnapshot = query(
        queryCollection,
        where(queryVariable, '==', queryValue),
        orderBy('lastUpdated', 'desc')
      )
      const snapshot = await getDocs(querySnapshot)
      return {
        data: snapshot.docs.map((doc: DocumentData) => ({
          documentId: doc.id,
          ...doc.data()
        })) as Array<T>
      }
    } catch (error) {
      console.error(error)
      return {error}
    }
  }

  getCollectionSnapshotBasedOnQuery = <T>({
    updateState,
    cancelCallback,
    collectionName,
    queryVariable,
    queryValue
  }: {
    updateState: React.Dispatch<
      React.SetStateAction<
        | {
            documentData: T[]
          }
        | undefined
      >
    >
    cancelCallback: (error: Error) => void
    collectionName: string
    queryVariable: string
    queryValue: string | boolean
  }) => {
    try {
      const uid = this.auth?.currentUser?.uid
      if (!uid)
        throw new Error('user not logged in.', {
          cause: {
            message: 'user not logged in.',
            name: 'USER_NOT_FOUND'
          }
        })

      const queryCollection = collection(this.firestore, collectionName)
      const querySnapshot = query(
        queryCollection,
        where(queryVariable, '==', queryValue),
        orderBy('lastUpdated', 'desc')
      )

      const unsub = onSnapshot(
        querySnapshot,
        (snapshot) => {
          const data = snapshot.docs.map((doc: DocumentData) => ({
            documentId: doc.id,
            ...(doc.data() as T)
          }))
          updateState({documentData: data})
        },
        cancelCallback
      )

      return {unsub}
    } catch (error) {
      return {error}
    }
  }

  getCollectionBasedOnIds = async ({
    collectionName,
    queryVariable,
    idsArray,
    arrayCondition = 'in'
  }: {
    collectionName: string
    queryVariable: string
    arrayCondition?: 'in' | 'not-in'
    idsArray: Array<string>
  }) => {
    const uid = this.auth?.currentUser?.uid
    if (!uid) return
    try {
      const queryCollection = collection(this.firestore, collectionName)
      const querySnapshot = query(
        queryCollection,
        where(queryVariable, arrayCondition, idsArray)
      )
      const response = await getDocs(querySnapshot)
      const collectionData: any = []
      response.forEach((doc) => {
        collectionData.push({documentId: doc.id, ...doc.data()})
      })
      return {collectionData}
    } catch (error) {
      return {error}
    }
  }

  getCollectionData = async (collectionName: string) => {
    const uid = this.auth?.currentUser?.uid
    if (!uid) return {}
    try {
      const queryCollection = collection(this.firestore, collectionName)
      const collectionQuery = query(
        queryCollection,
        orderBy('lastUpdated', 'desc')
      )
      const querySnapshot = await getDocs(collectionQuery)
      return {
        data: querySnapshot.docs.map((doc: DocumentData) => ({
          documentId: doc.id,
          ...doc.data()
        }))
      }
    } catch (error) {
      console.error(error)
      return {error}
    }
  }

  checkDocuemnt = async ({
    documentId,
    collectionName,
    queryVariable,
    queryValue
  }: {
    documentId: string
    collectionName: string
    queryVariable: string
    queryValue: string | number | boolean
  }) => {
    try {
      const documentRef = doc(
        collection(this.firestore, collectionName),
        documentId
      )
      const documentSnapshot = await getDoc(documentRef)

      if (
        documentSnapshot.exists() &&
        documentSnapshot.data()?.[queryVariable] === queryValue
      ) {
        return {exists: true}
      }
      return {exists: false}
    } catch (error) {
      return {error}
    }
  }

  getCategoriesList = <T>({
    cancelCallback,
    setCategories,
    categoryId
  }: {
    categoryId?: string
    cancelCallback: (e: Error) => void
    setCategories: React.Dispatch<React.SetStateAction<T[]>>
  }) => {
    const categoriesRef = categoryId
      ? dbRef(this.database, `categories/${categoryId}/subcategories`)
      : dbRef(this.database, 'categories')
    return onChildAdded(
      categoriesRef,
      (snapshot) => {
        if (snapshot.exists()) {
          setCategories((prev) =>
            getUniqueObjects([...prev, snapshot.val()], (node) => node.nodeId)
          )
        }
      },
      cancelCallback
    )
  }

  getSectorsList = <T>({
    cancelCallback,
    setSectors
  }: {
    cancelCallback: (e: Error) => void
    setSectors: React.Dispatch<React.SetStateAction<T[]>>
  }) => {
    const sectorsRef = dbRef(this.database, 'sectors')
    return onChildAdded(
      sectorsRef,
      (snapshot) => {
        if (snapshot.exists()) {
          setSectors((prev) =>
            getUniqueObjects([...prev, snapshot.val()], (node) => node.nodeId)
          )
        }
      },
      cancelCallback
    )
  }

  updateExemptionTypeAndRegions = async ({
    userId,
    exemptRegions,
    exemptionType
  }: {
    userId: string
    exemptionType?: ExemptionType
    exemptRegions?: Array<ExemptRegions>
  }) => {
    try {
      await axios.post(
        `${API_HOST_URL}${UPDATE_EXEMPTION_TYPE_AND_REGIONS_ENDPOINT}/${userId}`,
        {exemptRegions, exemptionType},
        {
          headers
        }
      )
      return {
        success: true,
        message: 'Tax exemption type and regions updated successfully.'
      }
    } catch (error) {
      return {
        success: false,
        message:
          'Something went wrong! Failed to update exemption type and regions.'
      }
    }
  }

  updateExemptionCertificate = async ({
    userId,
    status,
    certificateId
  }: {
    userId: string
    certificateId: string
    status: applicationStatus
  }) => {
    try {
      await axios.post(
        `${API_HOST_URL}${UPDATE_TAX_EXEMPTION_CERTIFICATE_ENDPOINT}/${userId}`,
        {status, certificateId},
        {
          headers
        }
      )
      return {
        success: true,
        message: 'Tax exemption certificate updated successfully.'
      }
    } catch (error) {
      return {
        success: false,
        message: 'Something went wrong! Failed to update exemption certificate.'
      }
    }
  }

  getDocumentData = (
    updateState: React.Dispatch<
      React.SetStateAction<{
        documentData: any
      }>
    >,
    cancelCallback: (error: Error) => void,
    documentId: string,
    collectionName: string
  ) => {
    try {
      const uid = this.auth?.currentUser?.uid
      if (!uid)
        throw new Error('user not logged in.', {
          cause: {
            message: 'user not logged in.',
            name: 'USER_NOT_FOUND '
          }
        })

      const userDocRef = doc(
        this.firestore,
        collectionName || Collection.Contacts,
        documentId || uid
      )
      const unsSubData = onSnapshot(
        userDocRef,
        (doc: DocumentSnapshot) => {
          const docData = doc.data() || {}
          updateState({
            documentData: {...docData, documentId: doc.id}
          })
        },
        cancelCallback
      )
      return {unsSubData}
    } catch (error) {
      return {error}
    }
  }

  getProductDocumentData = ({
    cancelCallback,
    documentId,
    updateState,
    callback
  }: {
    updateState: React.Dispatch<
      React.SetStateAction<{
        documentData: ProductDocument | null
      }>
    >
    callback?: (props: ProductDocument) => void
    cancelCallback: (error: Error) => void
    documentId: string
  }) => {
    const uid = this.auth?.currentUser?.uid
    if (!uid) return {}
    const userDocRef = doc(
      this.firestore,
      Collection.Products,
      documentId || uid
    )
    const unsSubData = onSnapshot(
      userDocRef,
      (doc: DocumentSnapshot) => {
        const product = doc.data() as ProductDocument
        if (product?.productDetails) {
          const firstChoiceUnknown =
            product.productDetails.firstChoice === equipmentOptions.Unknown
          product.productDetails.firstChoice = isNumber(product.equipmentHours)
            ? equipmentOptions.EquipmentHours
            : isNumber(product.equipmentMileage)
            ? equipmentOptions.EquipmentMileage
            : firstChoiceUnknown
            ? equipmentOptions.Unknown
            : 'Equipment Hours or Mileage'

          const secondChoiceUnknown =
            product.productDetails.secondChoice === equipmentOptions.Unknown
          product.productDetails.secondChoice = product.equipmentVinNumber
            ? equipmentOptions.EquipmentVINNumber
            : product.serialNumber
            ? equipmentOptions.SerialNumber
            : secondChoiceUnknown
            ? equipmentOptions.Unknown
            : 'Serial Number or VIN'
        }

        _.assign(product, {yearUnknown: isNull(product?.year)})

        updateState({
          documentData: {...product}
        })

        callback?.(product)
      },
      cancelCallback
    )
    return {unsSubData}
  }

  getSingleDocumentData = async (docId: string, colelctionName: string) => {
    try {
      const uid = this.auth?.currentUser?.uid
      if (!uid)
        throw new Error('user not logged in.', {
          cause: {
            message: 'user not logged in.',
            name: 'USER_NOT_FOUND '
          }
        })

      const docRef = doc(this.firestore, colelctionName, docId)
      const data = (await getDoc(docRef))?.data()
      return {data}
    } catch (error) {
      return {error}
    }
  }

  addLineItem = async ({
    amount,
    attachment,
    description,
    invoiceId,
    isCredit,
    quantity
  }: {
    invoiceId: string
    attachment: string
    amount: number
    description: string
    quantity: number
    isCredit: boolean
  }) => {
    try {
      const ADD_LINE_ITEM = `${API_HOST_URL}${INVOICE_LINE_ITEMS_ENDPOINT}`
      const {data} = await axios.post(
        `${ADD_LINE_ITEM}/${invoiceId}`,
        {
          amount,
          attachment,
          description,
          isCredit,
          quantity
        },
        {headers}
      )
      return {data, status: true}
    } catch (error: any) {
      const {response} = error
      const {
        data: {data, status}
      } = response
      const message = data.msg
      return {
        message,
        status,
        error
      }
    }
  }

  removeInvoiceItem = async ({
    itemId,
    itemType,
    invoiceId
  }: {
    itemId: string
    invoiceId: string
    itemType: InvoiceItemType
  }) => {
    try {
      const rawResponse = await axios.post(
        `${API_HOST_URL}${INVOICE_REMOVE_ITEM_ENDPOINT}/${invoiceId}`,
        {
          itemId,
          itemType
        },
        {
          headers
        }
      )
      const message = rawResponse.data.msg
      return {
        response: rawResponse,
        message,
        success: true
      }
    } catch (error: any) {
      const {response} = error
      const {data, status} = response
      return {
        error: data?.msg || data?.data || error,
        errorCode: status
      }
    }
  }

  getPrimaryContact = async (
    accountId: string
  ): Promise<ContactDocument | undefined> => {
    try {
      const contactsCollectionRef = collection(
        this.firestore,
        Collection.Contacts
      )
      const q = query(
        contactsCollectionRef,
        where('accountId', '==', accountId),
        where('isPrimaryContact', '==', true)
      )
      const querySnapshot = await getDocs(q)
      return first(
        querySnapshot.docs.map((doc) => doc.data() as ContactDocument)
      )
    } catch (error) {
      return
    }
  }

  markBuyerWireAsPaid = async ({
    wireConfirmationId,
    invoiceId,
    amount
  }: {
    wireConfirmationId: string
    invoiceId: string
    amount: number
  }) => {
    try {
      const rawResponse = await axios.post(
        `${API_HOST_URL}${INVOICE_MARK_BUYER_WIRE_AS_PAID_ENDPOINT}/${invoiceId}`,
        {
          wireConfirmationId,
          amount
        },
        {
          headers
        }
      )
      const message = rawResponse.data.msg
      return {
        response: rawResponse,
        message,
        success: true
      }
    } catch (error: any) {
      const {response} = error
      const {data, status} = response
      return {
        error: data?.msg || data?.data || error,
        errorCode: status
      }
    }
  }

  addInvoiceComment = async ({
    invoiceId,
    invoiceComment
  }: {
    invoiceComment: InvoiceComment
    invoiceId: string
  }) => {
    try {
      await axios.post(
        `${API_HOST_URL}${INVOICE_ADD_COMMENT_ENDPOINT}/${invoiceId}`,
        {
          invoiceComment
        },
        {
          headers
        }
      )
      return {
        success: true,
        message: 'Invoice comment added successfully.'
      }
    } catch (error) {
      return {
        success: false,
        message: 'Something went wrong! Failed to add invoice comment.'
      }
    }
  }

  updateFinanceStatus = async (
    userId: string,
    financeBody: {
      financeId: string
      financingStatus: applicationStatus
    }
  ) => {
    try {
      const rawResponse = await axios.patch(
        `${API_HOST_URL}${FINANCE_CONTACT_ACTION_ENDPOINT}/${userId}`,
        {
          ...financeBody
        },
        {
          headers
        }
      )
      const message = rawResponse.data.msg
      return {
        response: rawResponse,
        message,
        success: true
      }
    } catch (err: any) {
      return {
        error: err?.response?.data?.msg || err,
        errorCode: err?.response?.status
      }
    }
  }

  updateRequestedChanges = async (requestedChanges: Map, productId: string) => {
    try {
      const uid = this.auth?.currentUser?.uid
      if (!uid)
        throw new Error('user not logged in.', {
          cause: {
            message: 'user not logged in.',
            name: 'USER_NOT_FOUND '
          }
        })
      const productDocRef = doc(this.firestore, Collection.Products, productId)
      await updateDoc(productDocRef, {
        requestedChanges: isEmpty(requestedChanges) ? null : requestedChanges,
        lastUpdated: Timestamp.now(),
        status: isEmpty(requestedChanges)
          ? productStatus.Pending
          : productStatus.inProgress
      })
      return {
        success: true
      }
    } catch (error) {
      return {error}
    }
  }

  signOut = () => signOut(this.auth)

  removeFcmToken = async ({role}: {role: string}) => {
    const uid = this.auth?.currentUser?.uid || ''
    if (!role || !uid) return
    const adminRef = dbRef(this.database, `admins/${role}/${uid}/`)
    await localforage.removeItem('fcm_token')
    localStorage.removeItem('hideNotificationBanner')
    await update(adminRef, {
      fcmToken: ''
    })
  }

  changePassword = async (currentPassword: string, newPassword: string) => {
    const currentUser = this.auth?.currentUser
    try {
      if (!currentUser)
        throw new Error('User not logged in.', {
          cause: {
            message: 'User not logged in.',
            name: 'USER_NOT_FOUND '
          }
        })

      const authCredentials = EmailAuthProvider.credential(
        currentUser.email || '',
        currentPassword
      )

      const authenticated = await reauthenticateWithCredential(
        currentUser,
        authCredentials
      )
      if (authenticated) {
        await updatePassword(currentUser, newPassword)
        sendNotification({
          baseURL: API_HOST_URL,
          code: AllUsersNotifications.PasswordUpdated,
          contactId: currentUser?.uid
        })
        return {message: 'Password has been updated.'}
      } else {
        throw new Error(`Incorrect current password`, {
          cause: {
            message: 'Incorrect current password.',
            name: 'INCORRECT_CURRENT_PASS '
          }
        })
      }
    } catch (error: any) {
      return {error: error.message}
    }
  }

  updateDeal = async (stage: dealStage, dealId: string) => {
    try {
      const uid = this.auth?.currentUser?.uid
      if (!uid)
        throw new Error('user not logged in.', {
          cause: {
            message: 'user not logged in.',
            name: 'USER_NOT_FOUND '
          }
        })
      const dealDocRef = doc(this.firestore, Collection.Deals, dealId)
      await updateDoc(dealDocRef, {
        stage,
        lastUpdated: Timestamp.now()
      })
      return {
        success: true
      }
    } catch (error) {
      return {error}
    }
  }

  createDeal = async (dealObject: APIDealDocument) => {
    try {
      const rawResponse = await axios.post(
        `${API_HOST_URL}${CREATE_DEAL_ENDPOINT}`,
        {
          ...dealObject
        },
        {
          headers
        }
      )
      const message = rawResponse.data.msg
      return {
        response: rawResponse.data,
        message,
        dealId: rawResponse.data.data.dealId
      }
    } catch (err: any) {
      return {
        error: err?.response?.data?.msg || err,
        errorCode: err?.response?.status
      }
    }
  }

  getUserDeals = (
    updateState: (e: any) => void,
    cancelCallback: (e: Error) => void,
    isSuperAdminOrManager: boolean,
    accountIdList: Array<string>
  ) => {
    try {
      const uid = this.auth?.currentUser?.uid
      if (!uid)
        throw new Error('user not logged in.', {
          cause: {
            message: 'user not logged in.',
            name: 'USER_NOT_FOUND '
          }
        })
      const collectionRef = collection(this.firestore, Collection.Deals)
      const docQuery = isSuperAdminOrManager
        ? collectionRef
        : query(collectionRef, where('sellerAccountId', 'in', accountIdList))
      const unSubscribe = onSnapshot(
        docQuery,
        (doc) => {
          const docData: DocumentData = []
          doc.forEach((snap) => {
            docData.push(snap.data())
          })
          updateState(docData)
        },
        cancelCallback
      )

      return {unSubscribe}
    } catch (error) {
      return {error}
    }
  }

  getUserChat = (
    chatId: string,
    updateChatState: (data: any) => void,
    cancelCallback: (e: Error) => void
  ) => {
    const chatRef = dbRef(this.database, `chats/${chatId}/messages`)
    return onChildAdded(
      chatRef,
      (snapshot) => {
        if (snapshot.exists()) {
          if (snapshot.val().hasOwnProperty('offer')) {
            updateChatState({
              ...snapshot.val(),
              offer: {
                ...snapshot.val().offer,
                messageId: snapshot.key
              }
            })
          } else {
            updateChatState(snapshot.val())
          }
        }
      },
      cancelCallback
    )
  }

  getUserBankAccountInfo = async (userId?: string) => {
    try {
      if (!userId) return
      const docRef = doc(this.firestore, Collection.Contacts, userId)
      const colRef = collection(docRef, 'bankDetails')
      const detailDoc = doc(colRef, 'bankDetails')
      const userBankData = (
        await getDoc(detailDoc)
      )?.data() as BankAccountInfoDocument
      return {
        userBankData
      }
    } catch (error) {
      return {error}
    }
  }

  updateAdminBankAccountInfo = async (info: BankAccountInfoDocument) => {
    try {
      const adminBankAccInfoRef = doc(
        this.firestore,
        Collection.AdminBankAccountDetails,
        Collection.AdminBankAccountDetails
      )
      await setDoc(
        adminBankAccInfoRef,
        {
          ...info
        },
        {merge: true}
      )
      return {}
    } catch (error) {
      return {error}
    }
  }

  updateLastViewedNotification = async () => {
    const uid = this.auth.currentUser?.uid
    if (!uid) return {}
    try {
      const documentRef = doc(this.firestore, Collection.Admins, uid)
      await setDoc(
        documentRef,
        {
          notificationTimestamps: {lastViewed: Timestamp.now()}
        },
        {merge: true}
      )
      return {success: true}
    } catch (error) {
      return {error, success: false}
    }
  }

  markAllNotiicationsAsRead = async () => {
    const uid = this.auth.currentUser?.uid
    if (!uid) return {}
    try {
      const documentRef = doc(this.firestore, Collection.Admins, uid)
      await setDoc(
        documentRef,
        {
          notificationTimestamps: {markAsRead: Timestamp.now()}
        },
        {merge: true}
      )
      return {success: true}
    } catch (error) {
      return {error, success: false}
    }
  }

  markNotificationAsRead = async (notificationId?: string) => {
    if (!notificationId) return {}
    try {
      const docRef = doc(
        this.firestore,
        Collection.AdminNotifications,
        notificationId
      )
      await setDoc(docRef, {read: true}, {merge: true})
      return {success: true}
    } catch (error) {
      console.error(error)
      return {error}
    }
  }

  getAdminDocument = (
    updateState: React.Dispatch<React.SetStateAction<IAppContext['appState']>>
  ) => {
    const uid = this.auth.currentUser?.uid
    if (!uid) return {}
    const docRef = doc(this.firestore, Collection.Admins, uid)
    const unsubscribe: Unsubscribe = onSnapshot(
      docRef,
      (doc) => {
        const adminDoc = doc.data() as AdminDocument
        updateState((prev) => ({
          ...prev,
          adminDetails: adminDoc
        }))
      },
      (error) =>
        console.error(
          'Something went wrong! Failed to get admin details',
          error
        )
    )
    return {unsubscribe}
  }

  getNotifications = (
    updateState?: React.Dispatch<React.SetStateAction<IAppContext['appState']>>
  ) => {
    const uid = this.auth?.currentUser?.uid
    if (!uid) return

    const collectionRef = collection(
      this.firestore,
      Collection.AdminNotifications
    )
    const queryRef = query(
      collectionRef,
      where('adminId', '==', uid),
      orderBy('createdAt', 'desc'),
      limit(10)
    )

    const unsubscribe = onSnapshot(
      queryRef,
      (snapshot) => {
        const notifications = snapshot.docs.map(
          (doc) =>
            ({
              ...doc?.data(),
              notificationId: doc?.id
            } as DocumentData)
        )
        updateState?.((prev: IAppContext['appState']) => {
          const newNotificationsArr = [
            ...(prev?.notifications || []),
            ...notifications
          ] as Array<FrontEndNotification>

          const removeDuplicates = getUniqueObjects(
            newNotificationsArr,
            (e) => e?.createdAt.seconds && e?.createdAt.nanoseconds
          ).sort((a, b) => b.createdAt.seconds - a.createdAt.seconds)

          return {
            ...prev,
            notifications: [...removeDuplicates],
            lastVisibleNotification: last(snapshot.docs),
            hasMoreNotifications: notifications.length === 10
          }
        })
      },
      (error) => {
        console.error('Notification Error:', error)
      }
    )

    return unsubscribe
  }

  getNextNotifications = async ({
    lastVisibleNotification
  }: {
    lastVisibleNotification?: QueryDocumentSnapshot<DocumentData>
  }) => {
    const uid = this.auth?.currentUser?.uid
    if (!uid) return
    try {
      const adminNotificationsCol = collection(
        this.firestore,
        Collection.AdminNotifications
      )
      if (lastVisibleNotification) {
        const queryRef = query(
          adminNotificationsCol,
          limit(10),
          orderBy('createdAt', 'desc'),
          startAfter(lastVisibleNotification),
          where('adminId', '==', uid)
        )
        const docs = await getDocs(queryRef)
        const lastVisibleNotificationDoc = docs.docs[docs.docs.length - 1]
        const notifications = docs.docs.map((notification) => ({
          ...notification.data(),
          notificationId: notification.id
        }))

        return {
          notifications,
          lastVisibleNotification: lastVisibleNotificationDoc
        }
      }

      const queryRef = query(
        adminNotificationsCol,
        limit(10),
        orderBy('createdAt', 'desc'),
        where('adminId', '==', uid)
      )

      const docs = await getDocs(queryRef)
      const lastVisibleNotificationDoc = docs.docs[docs.docs.length - 1]
      const notifications = docs.docs.map((notification) => ({
        ...notification.data(),
        notificationId: notification.id
      }))

      return {
        notifications,
        lastVisibleNotification: lastVisibleNotificationDoc
      }
    } catch (error) {
      return {error}
    }
  }

  requestNotifactionsPermission = async ({role}: {role: adminRole}) => {
    const uid = this.auth?.currentUser?.uid
    if (!role || !uid) return
    if (typeof window !== 'undefined' && typeof Notification !== 'undefined') {
      const status = await Notification.requestPermission()
      if (status && status === 'granted') {
        const tokenInLocalForage = await localforage.getItem('fcm_token')
        if (tokenInLocalForage !== null) {
          return tokenInLocalForage
        }
        const messaging = getMessaging(this.app)
        const fcmToken = await getToken(messaging, {
          vapidKey: process.env.REACT_APP_PUBLIC_VAPID_KEY
        })
        if (fcmToken) {
          localforage.setItem('fcm_token', fcmToken)
          await this.updateFCM({token: fcmToken, role})
        } else {
          console.log('Do not have permission for Notification!')
        }
      }
    }
  }

  getFCM = async ({role}: {role: adminRole}) => {
    const uid = this.auth?.currentUser?.uid
    try {
      const adminRef = dbRef(this.database, `admins/${role}/${uid}/fcmToken`)
      const fcmToken = (await get(adminRef)).val() || null
      return {fcmToken}
    } catch (error) {
      return {error}
    }
  }

  updateFCM = async ({role, token}: {token: string; role: adminRole}) => {
    const uid = this.auth?.currentUser?.uid
    try {
      const adminRef = dbRef(this.database, `admins/${role}/${uid}/`)
      await update(adminRef, {
        fcmToken: token
      })
      return {}
    } catch (error) {
      return {error}
    }
  }

  sendChatMessage = async ({
    chatId,
    dealId,
    message
  }: {
    message: Message
    chatId: string
    dealId: string
  }) => {
    const senderId = this.auth?.currentUser?.uid || ''
    const dealDocRef = doc(this.firestore, Collection.Deals, dealId)
    try {
      await push(dbRef(this.database, `chats/${chatId}/messages`), {
        ...message,
        hasSeen: false,
        timestamp: Timestamp.now(),
        serverSeen: false,
        isAdmin: true,
        senderId
      })

      const attachmentType =
        message.attachment?.length && message.attachment?.length > 1
          ? message?.attachment[message.attachment?.length - 1]?.type
          : message?.attachment![0]?.type

      const isAttachment =
        attachmentType === 'image'
          ? '🖼️ Image'
          : attachmentType === 'video'
          ? '🎥 Video'
          : attachmentType === 'application/pdf'
          ? '📁File'
          : message?.message

      type LastMessageType = DealDocument['lastMessage']

      const lastMessage: LastMessageType = {
        text: message?.message.includes('https://goo.gl')
          ? '📍 Location'
          : isAttachment
          ? isAttachment
          : message?.message,
        lastMessageTimestamp: Timestamp.now(),
        senderId
      }

      await updateDoc(dealDocRef, {
        lastMessage: {...lastMessage},
        isBuyerUnRead: true,
        isSellerUnRead: true
      })
      return {}
    } catch (error) {
      return {error}
    }
  }

  getProductBids = (
    productId: string,
    cancelCallback: (e: Error) => void,
    updateState: React.Dispatch<React.SetStateAction<Bid[] | undefined>>
  ) => {
    const docRef = doc(this.firestore, Collection.Products, productId)
    const colRef = collection(docRef, SubCollection.Bids)
    const unSubscribe = onSnapshot(
      colRef,
      (doc) => {
        const docData: Array<Bid> = []
        doc.forEach((snap) => {
          docData.push(snap.data() as Bid)
        })
        updateState(docData)
      },
      cancelCallback
    )
    return unSubscribe
  }

  deleteUserChat = async (chatId: string, dealId: string) => {
    try {
      const chatRef = dbRef(this.database, `chats/${chatId}`)
      const chatReference = doc(this.firestore, Collection.Deals, dealId)
      await remove(chatRef)
      await deleteDoc(chatReference)
      return {}
    } catch (error) {
      return {error}
    }
  }
}

const firebase = Firebase.getInstance()

export default firebase
