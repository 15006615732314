import React from 'react'
import styles from './Button.module.scss'
import cn from 'classnames'
import classNames from 'classnames'
import ButtonLoader from './ButtonLoader'

interface ButtonProps {
  customClass?: string
  customBtnClass?: string
  text: string
  withLoader?: boolean
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined
  type?: 'button' | 'submit' | 'reset' | undefined
  disabled?: boolean
  style?: React.CSSProperties
  ref?: React.LegacyRef<HTMLButtonElement> | undefined
}

const Button = ({
  customClass,
  customBtnClass,
  text,
  withLoader = true,
  onClick,
  disabled = false,
  type = undefined,
  ref,
  style
}: ButtonProps) => {
  const [isDisabled, setIsDisabled] = React.useState(false)
  const isButtonDisabled = disabled || isDisabled || false
  const paddingRightOnDisable =
    (isButtonDisabled && withLoader && {paddingRight: '2rem'}) || {}
  return (
    <div
      className={cn(
        styles.container,
        customClass,
        (text === 'Cancel' && styles['btn-cancel']) || '',
        styles.btnStyles
      )}
    >
      <button
        ref={ref}
        style={{...style, ...paddingRightOnDisable}}
        className={classNames('btn-fill-orange', customBtnClass)}
        type={type}
        onClick={(e) => {
          setIsDisabled(true)
          onClick?.(e)
          setIsDisabled(false)
        }}
        disabled={isButtonDisabled}
      >
        {isButtonDisabled && withLoader && <ButtonLoader />}
        {text}
      </button>
    </div>
  )
}

export default Button
