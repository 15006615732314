import React from 'react'
import {useState, useEffect} from 'react'
import {ArrowDown} from '../../assets/svgs'
import styles from './CreateNewInvoice.module.scss'
import {useLocation, useNavigate} from 'react-router-dom'
import withAuthorization from '../Session/withAuthorization'
import NavBar from '../Navbar'
import Layout from '../Layout'
import {Loader} from '../Loader'
import GenerateInvoicePage from './GenerateInvoicePage'

interface CreateNewInvoiceProps {
  customClass: string
}

const CreateNewInvoice = ({customClass}: CreateNewInvoiceProps) => {
  const navigate = useNavigate()
  const {state} = useLocation()
  const isEdit = state?.isEdit ? state?.isEdit : false
  const [loader, setLoader] = useState(false)
  const previousPage = state?.currentPage

  useEffect(() => {
    if (isEdit) {
      setTimeout(() => {
        setLoader(false)
      }, 2000)
    }
  }, [])

  return (
    <div>
      <div className={styles.withLayout}>
        <Layout />
        <div className={[styles.container, customClass].join(' ')}>
          <NavBar title={'Generate Invoice'} />
          {loader ? (
            <Loader />
          ) : (
            <React.Fragment>
              <div className={styles.buttons}>
                <span
                  onClick={() =>
                    navigate('/invoices', {
                      state: {page: previousPage}
                    })
                  }
                >
                  <ArrowDown />
                  Back
                </span>
              </div>
              <div className={styles.deals_conatiner}>
                <GenerateInvoicePage />
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  )
}

export default withAuthorization(CreateNewInvoice)
