import classNames from 'classnames'
import {AccountDocument, ContactDocument, DealDocument} from 'common'
import {APIDealDocument} from 'common/apis/types'
import {Collection, dealStage} from 'common/enums'
import ModalComponent from 'components/ModalComponent'
import SelectProductModal from 'components/SelectProductModal'
import {DetailType} from 'components/enums'
import {Formik, FormikProps} from 'formik'
import {get, isEqual} from 'lodash'
import React, {useEffect, useRef, useState} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {toast} from 'react-toastify'
import {ArrowDown, Building, Eye, LargeSearch} from '../../assets/svgs'
import Button from '../Button'
import DetailPage from '../DetailPage'
import DropDownSelect from '../DropDown'
import {useFirebase} from '../Firebase/hooks'
import Layout from '../Layout'
import {Loader} from '../Loader'
import NavBar from '../Navbar'
import withAuthorization from '../Session/withAuthorization'
import {getUserFullName, sortByName, trimStringFields} from '../utils'
import styles from './CreateNewDeal.module.scss'
import {CreateDealSchema, EditDealSchema, INITIAL_DEAL_STATE} from './schema'
import {PRODUCTION_URL} from 'components/constants'

interface CreateNewDealProps {
  customClass: string
}

interface AccountDropdownOptions {
  id: string
  name: string
}
interface ContactDropdownOptions {
  id: string
  name: string
  isPrimaryContact: boolean
  photoURL: string
}

const DealDetailsPage = ({customClass}: CreateNewDealProps) => {
  const [showProductSelectModal, setShowProductSelectModal] = useState(false)
  const stageDropdownOptions = Object.values(dealStage)
  const [formData, setFormData] = useState({})
  const [accountOptionsList, setAccountOptionsList] = useState<
    Array<AccountDropdownOptions>
  >([])
  const [contactOptionsList, setContactOptionsList] = useState<
    Array<ContactDropdownOptions>
  >([])
  const navigate = useNavigate()
  const {state} = useLocation()
  const {
    getDocumentData,
    getCollectionData,
    updateDeal,
    createDeal,
    getCollectionBasedOnQuery
  } = useFirebase()
  const formRef: React.Ref<FormikProps<DealDocument>> | undefined = useRef(null)
  const dealID = state?.id ? state?.id : ''
  const isEdit = state?.isEdit ? state?.isEdit : false
  const previousPage = state?.currentPage
  const [loader, setLoader] = useState(true)
  const [disabled, setDisabled] = useState(isEdit ? true : false)
  const [appState, setAppState] = useState<{documentData: DealDocument}>({
    documentData: INITIAL_DEAL_STATE
  })
  const productId = appState?.documentData?.productId || ''
  const chatId = appState?.documentData?.chatId || ''
  const isDealDetails = isEdit && disabled
  const formSchema = isEdit ? EditDealSchema : CreateDealSchema

  const getAccounts = async () => {
    const {data: accounts = [], error} = await getCollectionData(
      Collection.Accounts
    )
    if (error) {
      toast.error('Something went wrong while fetching accounts.')
      return
    }
    const dropdownOptions = accounts
      .map((account: AccountDocument) => {
        const accountName = account?.accountName || ''
        return {
          id: account?.accountId,
          name: accountName
        }
      })
      ?.sort(sortByName)
    setAccountOptionsList([...dropdownOptions])
    setLoader(false)
  }

  const getContacts = async (accountId: string) => {
    const {data = []} = await getCollectionBasedOnQuery<ContactDocument>(
      Collection.Contacts,
      'accountId',
      accountId
    )
    return data
  }

  const cancelCallback = (error: Error) => {
    if (error) {
      toast.error(`Something went wrong while fetching deal document.`)
    }
  }
  const toggleShowSelectProductModal = () =>
    setShowProductSelectModal(!showProductSelectModal)

  const setFilteredContacts = async (
    index: number,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined
    ) => Promise<any>,
    setFieldError: (field: string, message: string | undefined) => void,
    setFieldTouched: (
      field: string,
      isTouched?: boolean | undefined,
      shouldValidate?: boolean | undefined
    ) => void
  ) => {
    const account = accountOptionsList[index]
    const accountID = account.id
    const accountName = account.name
    setFieldValue('buyerAccountId', accountID)
    setFieldValue('buyerAccountName', accountName)
    setFieldError('buyerAccountId', '')
    setFieldTouched('buyerAccountId', false)
    const contactsData: Array<ContactDocument> = await getContacts(accountID)
    const filteredContacts = contactsData.filter(
      (contact) => contact?.accountId === accountID
    )
    if (!filteredContacts.length) {
      toast.error('Selected Account has no Contacts')
      setFieldValue('buyerContactId', '')
      setFieldValue('buyerContactName', '')
      setFieldTouched('buyerContactId', true)
      setContactOptionsList([])
      return
    } else {
      const dropdownOptions =
        filteredContacts?.map((contact) => {
          const contactName = getUserFullName(
            contact.firstName,
            contact.lastName
          )
          const photoURL = contact?.contactInfo?.photoURL || ''
          return {
            id: contact?.contactId,
            name: contactName,
            isPrimaryContact: contact?.isPrimaryContact || false,
            photoURL
          }
        }) || []
      const primaryContact = dropdownOptions?.find(
        (contact) => contact.isPrimaryContact
      )
      if (primaryContact) {
        setFieldValue('buyerContactId', primaryContact.id)
        setFieldValue('buyerContactName', primaryContact.name)
        setFieldValue('thumbnail.buyerProfilePicture', [
          primaryContact.photoURL
        ])
        setFieldError('buyerContactId', '')
        setFieldTouched('buyerContactId', false)
      } else {
        setFieldValue('buyerContactId', '')
        setFieldValue('buyerContactName', '')
      }
      dropdownOptions?.sort(sortByName)
      setContactOptionsList([...dropdownOptions])
    }
  }

  useEffect(() => {
    if (!isEdit) {
      getAccounts()
    } else {
      setTimeout(() => {
        setLoader(false)
      }, 2000)
    }
  }, [])

  useEffect(() => {
    if (isEdit) {
      const {documentData} = appState
      if (documentData && !disabled) {
        setFormData({...documentData})
      }
    }
  }, [appState, formRef, disabled])

  useEffect(() => {
    if (disabled && isEdit) {
      const {unsSubData} = getDocumentData(
        setAppState,
        cancelCallback,
        dealID,
        Collection.Deals
      )

      return () => {
        unsSubData?.()
      }
    }
  }, [disabled])

  return (
    <div>
      <div className={styles.withLayout}>
        <Layout />
        <div className={[styles.container, customClass].join(' ')}>
          <NavBar
            title={
              isDealDetails
                ? 'View Deal'
                : isEdit
                ? 'Edit Deal'
                : 'Create New Deal'
            }
          />
          {loader ? (
            <Loader />
          ) : (
            <React.Fragment>
              {showProductSelectModal && (
                <ModalComponent
                  show={showProductSelectModal}
                  handleClose={toggleShowSelectProductModal}
                >
                  <SelectProductModal
                    handleClose={toggleShowSelectProductModal}
                    formRef={formRef}
                  />
                </ModalComponent>
              )}
              <div className={styles.buttons}>
                <span
                  onClick={() => {
                    if (isEdit) {
                      if (!disabled) {
                        setDisabled(true)
                      } else {
                        navigate('/deals', {
                          state: {page: previousPage}
                        })
                      }
                    } else {
                      navigate('/deals', {
                        state: {page: previousPage}
                      })
                    }
                  }}
                >
                  <ArrowDown />
                  Back
                </span>
                <div className={styles.twobtn}>
                  {isEdit && (
                    <React.Fragment>
                      <a
                        href={`${PRODUCTION_URL}/equipment-detail/${productId}`}
                        target="_blank"
                        rel="noreferrer"
                        className={classNames(
                          styles.button,
                          styles.changes_btn,
                          'btn-fill-orange'
                        )}
                      >
                        <Eye /> View Equipment
                      </a>
                      <button
                        onClick={() => {
                          navigate('/chats', {
                            state: {id: chatId, openChat: true}
                          })
                        }}
                        className={classNames(
                          styles.button,
                          styles.changes_btn,
                          'btn-fill-orange'
                        )}
                      >
                        <Eye /> View Chat
                      </button>
                    </React.Fragment>
                  )}
                  {disabled && (
                    <Button
                      text="Edit"
                      customClass={styles.changes_btn}
                      onClick={() => setDisabled(false)}
                    />
                  )}
                  {(!isEdit || !disabled) && (
                    <Button
                      text={'Save'}
                      customClass={styles.changes_btn}
                      onClick={() => formRef.current?.handleSubmit()}
                      disabled={loader}
                    />
                  )}
                </div>
              </div>
              <div className={styles.edit_section}>
                {isDealDetails ? (
                  <React.Fragment>
                    <DetailPage
                      data={appState.documentData}
                      detailType={DetailType.Deals}
                    />
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <Formik
                      innerRef={formRef}
                      initialValues={appState.documentData}
                      onSubmit={async (values) => {
                        if (isEdit) {
                          const isObjectSame = isEqual(values, formData)
                          if (isEdit && isObjectSame) {
                            toast.warning('Please make some changes')
                            return
                          }
                          const {stage, dealId} = values
                          setLoader(true)
                          const {error} = await updateDeal(stage, dealId)
                          if (error) {
                            toast.error('Something went wrong!')
                            setLoader(false)
                            return
                          }
                          toast.success('Successfully updated!')
                          setLoader(false)
                          setDisabled(true)
                          return
                        }
                        const {
                          lastMessage,
                          dealId,
                          lastUpdated,
                          createdAt,
                          chatId,
                          ...rest
                        } = values
                        const dealObject: APIDealDocument = {
                          ...rest,
                          isBuyerUnRead: true,
                          isSellerUnRead: true,
                          requestForInspection:
                            values.requestForInspection || [],
                          location: {
                            equipmentCity: values.location?.equipmentCity || '',
                            equipmentCountry:
                              values.location?.equipmentCountry || '',
                            equipmentState:
                              values.location?.equipmentState || '',
                            equipmentStreet:
                              values.location?.equipmentStreet || '',
                            equipmentZip: values.location?.equipmentZip || '',
                            ...values.location
                          },
                          activeParticipants: {
                            [values.buyerContactId]: true,
                            [values.sellerContactId]: true
                          }
                        }
                        const {buyerContactId, sellerContactId, productId} =
                          dealObject
                        const sameUser = buyerContactId === sellerContactId
                        if (sameUser) {
                          toast.error(
                            'Selected buyer contact is the same as the seller of the equipment, please change the buyer contact to proceed'
                          )
                          return
                        }
                        setLoader(true)
                        const {error} = await createDeal(
                          trimStringFields(dealObject)
                        )
                        if (error) {
                          toast.error('Something went wrong!')
                          setLoader(false)
                          return
                        }
                        toast.success('Deal created successfully!')
                        navigate('/deals', {
                          state: {page: previousPage}
                        })
                        return
                      }}
                      validationSchema={formSchema}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        handleBlur,
                        handleSubmit,
                        setFieldValue,
                        setFieldError,
                        setFieldTouched
                      }) => (
                        <form
                          className={styles.from_submiting}
                          onSubmit={handleSubmit}
                        >
                          <div className={styles.formContainer}>
                            {!isEdit && (
                              <React.Fragment>
                                <div className={styles.headingContainer}>
                                  <div className={styles.formHeading}>
                                    Equipment Details
                                  </div>
                                  <div className={styles.inputs}>
                                    <div className={styles.inputContainer}>
                                      <div className={styles.inputField}>
                                        <div className={styles.dropdown}>
                                          <div>
                                            <DropDownSelect
                                              firstIcon={<LargeSearch />}
                                              options={[]}
                                              customClass={classNames(
                                                styles.dropdowninput,
                                                styles.dropdownblock,
                                                styles.dropdownsearch
                                              )}
                                              customOnClick={() => {
                                                toggleShowSelectProductModal()
                                              }}
                                              customValue={
                                                get(values, 'dealName') || ''
                                              }
                                              placeholder={'Select Equipment'}
                                              cancelClickFunction={() => {
                                                setFieldValue('dealName', '')
                                              }}
                                              value={0}
                                              errors={errors}
                                              touched={touched}
                                              disabled={disabled}
                                              onBlur={handleBlur}
                                              name="dealName"
                                            />
                                            <div
                                              className={classNames(
                                                styles.disblock,
                                                styles.disnone
                                              )}
                                            >
                                              ____
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className={styles.inputField}>
                                        <div className={styles.dropdown}>
                                          <div></div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className={styles.headingContainer}>
                                  <div className={styles.formHeading}>
                                    Buyer Details
                                  </div>
                                  <div className={styles.inputs}>
                                    <div className={styles.inputContainer}>
                                      <div className={styles.inputField}>
                                        <div className={styles.dropdown}>
                                          <div>
                                            <DropDownSelect
                                              firstIcon={<Building />}
                                              options={accountOptionsList}
                                              customClass={classNames(
                                                styles.dropdowninput,
                                                styles.dropdownblock
                                              )}
                                              placeholder="Select Account"
                                              value={accountOptionsList.findIndex(
                                                (option) =>
                                                  option.id ===
                                                  get(values, 'buyerAccountId')
                                              )}
                                              onChange={(index) =>
                                                setFilteredContacts(
                                                  index,
                                                  setFieldValue,
                                                  setFieldError,
                                                  setFieldTouched
                                                )
                                              }
                                              cancelClickFunction={() => {
                                                setFieldValue(
                                                  'buyerAccountId',
                                                  ''
                                                )
                                                setFieldValue(
                                                  'buyerAccountName',
                                                  ''
                                                )
                                                setFieldValue(
                                                  'buyerContactId',
                                                  ''
                                                )
                                                setFieldValue(
                                                  'buyerContactName',
                                                  ''
                                                )
                                                setContactOptionsList([])
                                              }}
                                              errors={errors}
                                              touched={touched}
                                              disabled={disabled}
                                              onBlur={handleBlur}
                                              name="buyerAccountId"
                                            />
                                            <div
                                              className={classNames(
                                                styles.disblock,
                                                styles.disnone
                                              )}
                                            >
                                              ____
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className={styles.inputField}>
                                        <div className={styles.dropdown}>
                                          <div>
                                            <DropDownSelect
                                              firstIcon={<Building />}
                                              options={contactOptionsList}
                                              customClass={classNames(
                                                styles.dropdowninput,
                                                styles.dropdownblock
                                              )}
                                              placeholder="Select Contact"
                                              value={contactOptionsList.findIndex(
                                                (option) =>
                                                  option.id ===
                                                  get(values, 'buyerContactId')
                                              )}
                                              cancelClickFunction={() => {
                                                setFieldValue(
                                                  'buyerContactId',
                                                  ''
                                                )
                                                setFieldValue(
                                                  'buyerContactName',
                                                  ''
                                                )
                                              }}
                                              onChange={(index) => {
                                                const contact =
                                                  contactOptionsList[index]
                                                const contactID = contact.id
                                                const contactName = contact.name
                                                const contactPhoto =
                                                  contact.photoURL
                                                setFieldValue(
                                                  'buyerContactId',
                                                  contactID
                                                )
                                                setFieldValue(
                                                  'buyerContactName',
                                                  contactName
                                                )
                                                setFieldValue(
                                                  'thumbnail.buyerProfilePicture',
                                                  [contactPhoto]
                                                )
                                              }}
                                              errors={errors}
                                              touched={touched}
                                              disabled={disabled}
                                              onBlur={handleBlur}
                                              name="buyerContactId"
                                            />
                                            <div
                                              className={classNames(
                                                styles.disblock,
                                                styles.disnone
                                              )}
                                            >
                                              ____
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </React.Fragment>
                            )}

                            <div className={styles.headingContainer}>
                              <div className={styles.formHeading}>Stage</div>
                              <div className={styles.inputs}>
                                <div className={styles.inputContainer}>
                                  <div className={styles.inputField}>
                                    <div className={styles.dropdown}>
                                      <div>
                                        <DropDownSelect
                                          firstIcon={<Building />}
                                          options={stageDropdownOptions}
                                          customClass={classNames(
                                            styles.dropdowninput,
                                            styles.dropdownblock
                                          )}
                                          placeholder="Select Stage"
                                          value={stageDropdownOptions.findIndex(
                                            (option) =>
                                              option === get(values, 'stage')
                                          )}
                                          onChange={(index) => {
                                            const stage =
                                              stageDropdownOptions[index]
                                            setFieldValue('stage', stage)
                                          }}
                                          cancelClickFunction={() => {
                                            setFieldValue('stage', '')
                                          }}
                                          errors={errors}
                                          touched={touched}
                                          disabled={disabled}
                                          onBlur={handleBlur}
                                          name="stage"
                                        />
                                        <div
                                          className={classNames(
                                            styles.disblock,
                                            styles.disnone
                                          )}
                                        >
                                          ____
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className={styles.inputField}>
                                    <div className={styles.dropdown}>
                                      <div></div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      )}
                    </Formik>
                  </React.Fragment>
                )}
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  )
}

export default withAuthorization(DealDetailsPage)
