import ModalComponent from 'components/ModalComponent'
import styles from './ChatImage.module.scss'
import React from 'react'
import ImageModal from 'components/ImageModal'
import {FullScreen} from 'assets/svgs'

const ChatImage = ({url, alt}: {url: string; alt: string}) => {
  const [showModal, setShowModal] = React.useState(false)
  const toggleShowModal = () => setShowModal(!showModal)

  return (
    <React.Fragment>
      <div className={styles.img_contianer}>
        <span
          className={styles.fullscreen_button}
          onClick={toggleShowModal}
          aria-label="Open Fullscreen"
        >
          <FullScreen strokeWidth={2} />
        </span>
        <img width={300} height={250} alt={alt} src={url} />
      </div>
      {showModal && (
        <ModalComponent show={showModal} handleClose={toggleShowModal}>
          <ImageModal handleClose={toggleShowModal} url={url} alt={alt} />
        </ModalComponent>
      )}
    </React.Fragment>
  )
}

export default ChatImage
