import algoliasearch from 'algoliasearch'
import {Environment} from 'common/enums'

const environment =
  (process.env.REACT_APP_PUBLIC_APPLICATION_ENVIRONMENT as Environment) ||
  Environment.DEVELOPMENT

const config = {
  [Environment.DEVELOPMENT]: {
    applicationID:
      process.env.REACT_APP_PUBLIC_DEVELOPMENT_ALGOLIA_APPLICATION_ID || '',
    APIKey: process.env.REACT_APP_PUBLIC_DEVELOPMENT_ALGOLIA_API_KEY || ''
  },
  [Environment.STAGING]: {
    applicationID:
      process.env.REACT_APP_PUBLIC_STAGING_ALGOLIA_APPLICATION_ID || '',
    APIKey: process.env.REACT_APP_PUBLIC_STAGING_ALGOLIA_API_KEY || ''
  },
  [Environment.PRODUCTION]: {
    applicationID:
      process.env.REACT_APP_PUBLIC_PRODUCTION_ALGOLIA_APPLICATION_ID || '',
    APIKey: process.env.REACT_APP_PUBLIC_PRODUCTION_ALGOLIA_API_KEY || ''
  }
}[environment]

const AlgoliaClient = algoliasearch(config.applicationID, config.APIKey)
const environmentPrefix = ''
// environment === Environment.DEVELOPMENT
//   ? 'dev_'
//   : environment === Environment.STAGING
//   ? 'stage_'
//   : ''
export const CONTACTS_ADMIN_PANEL_ALGOLIA_INDEX = environmentPrefix + 'contacts'
export const ACCOUNTS_ADMIN_PANEL_ALGOLIA_INDEX = environmentPrefix + 'accounts'
export const PRODUCTS_ALGOLIA_INDEX = environmentPrefix + 'products'
export const PRODUCTS_ADMIN_PANEL_ALGOLIA_INDEX =
  environmentPrefix + 'products_admin_panel_index'
export const DEALS_ADMIN_PANEL_ALGOLIA_INDEX =
  environmentPrefix + 'deals_admin_panel_index'
export const INVOICE_ADMIN_PANEL_ALGOLIA_INDEX =
  environmentPrefix + 'invoices_admin_panel_index'
export const SALES_TAX_ADMIN_PANEL_ALGOLIA_INDEX =
  environmentPrefix + 'sales_tax_admin_panel_index'
export const ADMINS_ADMIN_PANEL_ALGOLIA_INDEX =
  environmentPrefix + 'admins_admin_panel_index'
export const LA_ADMIN_PANEL_ALGOLIA_INDEX =
  environmentPrefix + 'la_admin_panel_index'
export const MSA_ADMIN_PANEL_ALGOLIA_INDEX =
  environmentPrefix + 'msa_admin_panel_index'
export const ACH_ADMIN_PANEL_ALGOLIA_INDEX =
  environmentPrefix + 'ach_admin_panel_index'
export const FINANCE_ADMIN_PANEL_ALGOLIA_INDEX =
  environmentPrefix + 'finance_admin_panel_index'
export const FEEDBACKS_ADMIN_PANEL_ALGOLIA_INDEX =
  environmentPrefix + 'feedbacks_admin_panel_index'
export const SALE_TAX_EXEMPTION_ADMIN_PANEL_ALGOLIA_INDEX =
  environmentPrefix + 'sale_tax_exemption_admin_panel_index'
export const ASSET_REPORTS_ADMIN_PANEL_ALGOLIA_INDEX =
  environmentPrefix + 'assetReports_admin_panel_index'

export default AlgoliaClient
