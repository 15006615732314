import {useState} from 'react'
import styles from './ChangePassword.module.scss'
import Layout from 'components/Layout'
import NavBar from 'components/Navbar'
import InputField from 'components/InputField'
import Button from 'components/Button'
import {toast} from 'react-toastify'
import {Formik} from 'formik'
import {ChangePasswordSchema} from './schema'
import {useFirebase} from 'components/Firebase/hooks'
import {useNavigate} from 'react-router-dom'
import withAuthorization from 'components/Session/withAuthorization'

const ChangePassword = () => {
  const [disabled, setDisabled] = useState(false)
  const {changePassword} = useFirebase()
  const navigate = useNavigate()
  const INITIAL_STATE = {
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: ''
  }

  return (
    <div className={styles.withLayout}>
      <Layout />
      <div className={styles.layoutContainer}>
        <NavBar title={'Change Password'} />
        <div className={styles.container}>
          <Formik
            initialValues={{...INITIAL_STATE}}
            onSubmit={async ({currentPassword, newPassword}) => {
              setDisabled(true)
              if (disabled) return
              const {error, message} = await changePassword(
                currentPassword,
                newPassword
              )
              if (error) {
                const passwordError = error.includes('auth/wrong-password')
                  ? 'You have entered a wrong password'
                  : 'Something went wrong!'
                toast.error(passwordError)
                setDisabled(false)
                return
              }
              if (message) {
                toast.success(message)
                navigate('/')
                return
              }
            }}
            validationSchema={ChangePasswordSchema}
          >
            {({
              values: {currentPassword, newPassword, confirmNewPassword},
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting
            }) => (
              <form className={styles.form} onSubmit={handleSubmit}>
                <InputField
                  isPassword
                  customClass={styles.inputFieldStyle}
                  label="Current Password"
                  value={currentPassword}
                  onChange={handleChange}
                  errors={errors}
                  touched={touched}
                  onBlur={handleBlur}
                  type="password"
                  name={'currentPassword'}
                />
                <InputField
                  isPassword
                  customClass={styles.inputFieldStyle}
                  label="New Password"
                  type="password"
                  value={newPassword}
                  errors={errors}
                  touched={touched}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name={'newPassword'}
                />
                <InputField
                  isPassword
                  customClass={styles.inputFieldStyle}
                  label="Confirm New Password"
                  type="password"
                  value={confirmNewPassword}
                  onChange={handleChange}
                  errors={errors}
                  touched={touched}
                  onBlur={handleBlur}
                  name={'confirmNewPassword'}
                />
                <Button
                  type="submit"
                  text="Update"
                  disabled={disabled}
                  customClass={styles.button}
                />
              </form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  )
}

export default withAuthorization(ChangePassword)
