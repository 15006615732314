import * as Yup from 'yup'
import phone from 'phone'
import {IFormattedContact} from 'components/types'

export const INIITAL_CONTACT_STATE: IFormattedContact = {
  firstName: '',
  lastName: '',
  accountId: '',
  accountName: '',
  title: '',
  mobileNumber: '',
  emailAddress: '',
  photoURL: '',
  isPrimaryContact: false,
  newsLetter: false,
  textMessage: false
}

export const CreateContactSchema = Yup.object({
  firstName: Yup.string()
    .matches(/^[A-Za-z]+(?:[ ]?[A-Za-z]+)*$/, 'Invalid First Name!')
    .min(3, 'First Name must be at least 3 characters')
    .required('Required'),
  lastName: Yup.string()
    .matches(/^[A-Za-z]+(?:[ ]?[A-Za-z]+)*$/, 'Invalid Last Name!')
    .min(3, 'Last Name must be at least 3 characters')
    .required('Required'),
  accountId: Yup.string().required('Required'),
  title: Yup.string().required('Required'),
  mobileNumber: Yup.string()
    .test('invalid_number', 'Invalid Number', (value) => {
      return value ? phone(value, {validateMobilePrefix: true}).isValid : false
    })
    .required('Required'),
  emailAddress: Yup.string()
    .matches(/^[^$%`~|\/]+$/, 'Invalid Email')
    .email('Invalid Email')
    .required('Required'),
  isPrimaryContact: Yup.bool(),
  newsLetter: Yup.bool(),
  textMessage: Yup.bool()
})
