import React, {useState, useEffect} from 'react'
import styles from './ForgotPassword.module.scss'
import {LoginSvg, Logo} from '../../assets/svgs'
import InputField from '../InputField'
import Button from '../Button'
import {Formik} from 'formik'
import {ForgotPasswordSchema} from './schema'
import {useFirebase, useSession} from '../Firebase/hooks'
import {toast} from 'react-toastify'
import {useNavigate} from 'react-router-dom'
import {Loader} from '../Loader'

const INITIAL_STATE = {
  email: ''
}

const ForgetPassword = () => {
  const {user, loading} = useSession()
  const [loader, setLoader] = useState(true)
  const isLoggedIn = user && !loading
  const {resetPassword} = useFirebase()
  const navigate = useNavigate()

  useEffect(() => {
    setLoader(true)
    if (isLoggedIn) {
      navigate(-1)
    } else setLoader(false)
  }, [user, loading])

  return (
    <React.Fragment>
      {loading ? (
        <Loader />
      ) : (
        !isLoggedIn && (
          <div className={styles.conatiner}>
            <div className={styles.logo_side}>
              <h2>
                <b>Admin</b> Panel
              </h2>
              <LoginSvg className={styles.svg_img} />
            </div>
            <div className={styles.formSide}>
              {loader ? (
                <Loader />
              ) : (
                <React.Fragment>
                  <Logo
                    className={styles.logo_img}
                    onClick={() => navigate('/sign-in')}
                  />
                  <Formik
                    initialValues={{...INITIAL_STATE}}
                    onSubmit={async ({email}) => {
                      setLoader(true)
                      if (loader) return
                      const {error} = await resetPassword(email)
                      if (error) {
                        toast.error(error.message)
                        setLoader(false)
                        return
                      }
                      toast.success('Password reset email sent.')
                      navigate('/sign-in')
                    }}
                    validationSchema={ForgotPasswordSchema}
                  >
                    {({
                      values: {email},
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div className={styles.heading_container}>
                          Forgot Password
                        </div>
                        <div className={styles.input_fields_down}>
                          <InputField
                            name="email"
                            dataCypress="password-recovery-field"
                            type="email"
                            smaller={true}
                            errors={errors}
                            touched={touched}
                            value={email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            placeholder="Email"
                          />
                        </div>
                        <Button
                          disabled={isSubmitting || loader}
                          customClass={styles.button_container}
                          text="Submit"
                        />
                      </form>
                    )}
                  </Formik>
                </React.Fragment>
              )}
            </div>
          </div>
        )
      )}
    </React.Fragment>
  )
}

export default ForgetPassword
