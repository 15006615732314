import React from 'react'
import classNames from 'classnames'
import {get} from 'lodash'
import styles from './InputRadio.module.scss'
import {Map} from 'common'

interface InputRadioProps {
  radioOptions: Array<Map>
  name: string
  label: string
  selected: string
  errors?: {} | undefined
  touched?: {} | undefined
  onChange: (e: any) => void
  onBlur?: (e: any) => void | undefined
  disabled?: boolean
  onKeyDown?: (e: any) => void
  onInput?: (e: any) => void
  readOnly?: boolean
}

const InputRadio = ({
  radioOptions,
  name,
  label,
  selected,
  errors = {},
  touched = {},
  onChange,
  onBlur,
  disabled,
  onKeyDown,
  onInput,
  readOnly
}: InputRadioProps) => {
  const hasError = get(touched, name) || ''
  const errorCondition = hasError && get(errors, name)
  return (
    <div className={classNames(styles.inputs)}>
      {label && <label>{label}</label>}
      <div className={styles.radioOptions} radioGroup="radio-options">
        {radioOptions.map(({key, value}, index) => (
          <label htmlFor={key} key={`input-option-${key}-${index}`}>
            <input
              className={classNames(errorCondition ? styles.hasError : '')}
              name={key}
              checked={selected === value}
              onChange={(e) => {
                onChange && onChange(e)
              }}
              type={'radio'}
              value={value}
              onBlur={(e) => {
                onBlur && onBlur(e)
              }}
              disabled={disabled}
              onKeyDown={onKeyDown}
              onInput={onInput}
              readOnly={readOnly}
            />
            {key}
          </label>
        ))}
      </div>
      <div className={styles.error}>{hasError && get(errors, name)}</div>
    </div>
  )
}

export default InputRadio
