import React from 'react'
import styles from '../DetailPage.module.scss'
import {BankAccountInfoDocument} from 'common'
import {useLocation} from 'react-router-dom'
import {IFieldSetSingleField} from 'components/types'
import {FieldSet} from '../DetailFields'

const BankAccountDetails = (data: BankAccountInfoDocument) => {
  const {pathname} = useLocation()
  const isAdminBackAccountPage = pathname === '/bank-account-info'
  const hasDomesticWireInfo =
    data?.domesticWireInfo?.accountName ||
    data?.domesticWireInfo?.accountNumber ||
    data?.domesticWireInfo?.bank ||
    data?.domesticWireInfo?.routingNumber
  const hasInernationalWireInfo =
    data?.internationalWireInfo?.accountName ||
    data?.internationalWireInfo?.accountNumber ||
    data?.internationalWireInfo?.bank ||
    data?.internationalWireInfo?.routingNumber ||
    data?.internationalWireInfo?.swiftCode

  const domesticWireFields: Array<IFieldSetSingleField> = [
    {label: 'Account Name', value: data?.domesticWireInfo?.accountName},
    {label: 'Account Number', value: data?.domesticWireInfo?.accountNumber},
    {label: 'Bank', value: data?.domesticWireInfo?.bank},
    {label: 'Routing Number', value: data?.domesticWireInfo?.routingNumber}
  ]

  const internationalWireFields: Array<IFieldSetSingleField> = [
    {label: 'Account Name', value: data?.internationalWireInfo?.accountName},
    {
      label: 'Account Number',
      value: data?.internationalWireInfo?.accountNumber
    },
    {label: 'Bank', value: data?.internationalWireInfo?.bank},
    {
      label: 'Routing Number',
      value: data?.internationalWireInfo?.routingNumber
    },
    {label: 'Swift Code', value: data?.internationalWireInfo?.swiftCode}
  ]

  const noPaymentDetailsText = isAdminBackAccountPage
    ? 'No bank account details found!'
    : 'Please contact the seller for their bank account information to proceed with payments'

  return (
    <React.Fragment>
      <div className={styles.inputsfields}>
        <FieldSet
          isTwoCol
          title="Domestic Wire Instructions"
          fields={domesticWireFields}
        />
        {!hasDomesticWireInfo && (
          <div className={styles.noPaymentDetails}>{noPaymentDetailsText}</div>
        )}
      </div>

      <div className={styles.inputsfields}>
        <FieldSet
          isTwoCol
          title="International Wire Instructions"
          fields={internationalWireFields}
        />
        {!hasInernationalWireInfo && (
          <div className={styles.noPaymentDetails}>{noPaymentDetailsText}</div>
        )}
      </div>
    </React.Fragment>
  )
}

export default BankAccountDetails
