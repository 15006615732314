import {ThemeProvider} from '@mui/material'
import {createTheme} from '@mui/material/styles'
import {LocalizationProvider} from '@mui/x-date-pickers'
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment'
import AgreementDetailPage from 'components/AgreementDetailPage'
import AgreementPage from 'components/AgreementPage'
import BankAccountInformation from 'components/BankAccountInformation'
import Categories from 'components/Categories'
import ChangePassword from 'components/ChangePassword'
import {AppStateProvider} from 'components/Context'
import CreateNewCategory from 'components/CreateNewCategory'
import CreateNewInvoice from 'components/CreateNewInvoice'
import DealDetailsPage from 'components/DealDetailsPage'
import DealPage from 'components/DealPage'
import EquipmentReportDetailPage from 'components/EquipmentReportDetailPage'
import EquipmentReportTable from 'components/EquipmentReportTable'
import Feedback from 'components/Feedback'
import FinanceApplications from 'components/FinanceApplications'
import FinanceDetailPage from 'components/FinanceDetailPage'
import {useFirebase, useSession} from 'components/Firebase/hooks'
import ForgetPassword from 'components/ForgotPassword'
import InvoiceDetailPage from 'components/InvoiceDetailPage'
import Invoices from 'components/Invoices'
import MessageSection from 'components/MessageSection'
import Notifications from 'components/Notifications'
import SalesTax from 'components/SalesTax'
import SalesTaxDetailPage from 'components/SalesTaxDetailPage'
import SyncedPage from 'components/SyncedPage'
import TaxExemptionApplications from 'components/TaxExemptionApplications'
import TaxExemptionDetailPage from 'components/TaxExemptionDetailPage'
import TaxJarCustomerPage from 'components/TaxJarCustomerPage'
import TaxJarCustomers from 'components/TaxJarCustomers'
import WebsiteBanner from 'components/WebsiteBanner'
import {NOTIFICATION_NOT_GRANTED_MESSAGE} from 'components/constants'
import {displayNotificationBanner} from 'components/utils'
import {useEffect, useState} from 'react'
import {Navigate, Route, Routes, useLocation} from 'react-router-dom'
import {ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Accounts from './components/Account'
import ContactPage from './components/ContactPage'
import CreateNewAccount from './components/CreateNewAccount'
import CreateNewContact from './components/CreateNewContact'
import Dashboard from './components/Dashboard'
import Login from './components/Login'
import ProductDetailPage from './components/ProductDetailPage'
import ProductPage from './components/ProductPage'
import RoleManagement from './components/RoleManagement'
import RoleManagementAccess from './components/RoleManagementAccess'
import './globals.scss'
import Sectors from 'components/Sectors'
import CreateNewSector from 'components/CreateNewSector'

function App() {
  const {search} = useLocation()
  const {markNotificationAsRead} = useFirebase()
  const notificationId = new URLSearchParams(search).get('notificationId') || ''

  useEffect(() => {
    if (notificationId) markNotificationAsRead(notificationId)
  }, [notificationId.length])

  const notificationBannerKey = localStorage.getItem('hideNotificationBanner')
  useEffect(() => {
    setShowNotificationBanner(displayNotificationBanner())
  }, [notificationBannerKey])
  const [showNotificationBanner, setShowNotificationBanner] =
    useState<boolean>()

  const {user} = useSession()
  const isUserLoggedIn = user?.uid
  const notificationBannerCancel = () => {
    localStorage.setItem('hideNotificationBanner', 'true')
    setShowNotificationBanner(false)
  }
  const theme = createTheme({
    palette: {
      primary: {
        main: '#f27236',
        contrastText: '#ffffff'
      }
    }
  })

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <AppStateProvider>
          {isUserLoggedIn && showNotificationBanner && (
            <WebsiteBanner
              showCancelButton
              isNotificationBanner
              cancelButtonClick={notificationBannerCancel}
              bannerText={NOTIFICATION_NOT_GRANTED_MESSAGE}
            />
          )}
          <div>
            <Routes>
              <Route path="/sign-in" element={<Login />} />
              <Route path="/forgot-password" element={<ForgetPassword />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/notifications" element={<Notifications />} />
              <Route path="/accounts" element={<Accounts />} />
              <Route path="/deals" element={<DealPage />} />
              <Route path="/deals/deal-details" element={<DealDetailsPage />} />
              <Route path="/feedback" element={<Feedback />} />
              <Route
                path="/finance-applications"
                element={<FinanceApplications />}
              />
              <Route
                path="/finance-applications/finance-application-details"
                element={<FinanceDetailPage />}
              />
              <Route
                path="/tax-exemption-applications"
                element={<TaxExemptionApplications />}
              />
              <Route
                path="/tax-exemption-applications/tax-exemption-application-details"
                element={<TaxExemptionDetailPage />}
              />
              <Route
                path="/accounts/single-account-details"
                element={<CreateNewAccount />}
              />
              <Route path="/ach-agreement" element={<AgreementPage />} />
              <Route path="/msa-agreement" element={<AgreementPage />} />
              <Route path="/listing-agreement" element={<AgreementPage />} />
              <Route
                path="/ach-agreement/agreement-details"
                element={<AgreementDetailPage />}
              />
              <Route
                path="/msa-agreement/agreement-details"
                element={<AgreementDetailPage />}
              />
              <Route
                path="/listing-agreement/agreement-details"
                element={<AgreementDetailPage />}
              />
              <Route path="/change-password" element={<ChangePassword />} />
              <Route path="/contacts" element={<ContactPage />} />
              <Route path="/role-management" element={<RoleManagement />} />
              <Route path="/chats" element={<MessageSection />} />
              <Route
                path="/role-management/role-management-details"
                element={<RoleManagementAccess />}
              />
              <Route path="/sync" element={<SyncedPage />} />
              <Route
                path="/contacts/single-contact-details"
                element={<CreateNewContact />}
              />
              <Route path="/reports" element={<EquipmentReportTable />} />
              <Route
                path="/reports/single-report-detail"
                element={<EquipmentReportDetailPage />}
              />
              <Route path="/equipment" element={<ProductPage />} />
              <Route path="/categories" element={<Categories />} />
              <Route
                path="/categories/single-category-details"
                element={<CreateNewCategory />}
              />
              <Route path="/sectors" element={<Sectors />} />
              <Route
                path="/sectors/single-sector-details"
                element={<CreateNewSector />}
              />
              <Route
                path="/equipment/single-equipment-detail"
                element={<ProductDetailPage />}
              />
              <Route
                path="/bank-account-info"
                element={<BankAccountInformation />}
              />
              <Route path="/invoices" element={<Invoices />} />
              <Route
                path="/invoices/create-invoice"
                element={<CreateNewInvoice />}
              />
              <Route
                path="/invoices/single-invoice-detail"
                element={<InvoiceDetailPage />}
              />
              <Route path="/sales-tax" element={<SalesTax />} />
              <Route path="/taxjar-customers" element={<TaxJarCustomers />} />
              <Route
                path="/taxjar-customers/single-taxjar-customer-detail"
                element={<TaxJarCustomerPage />}
              />
              <Route
                path="/sales-tax/single-sales-tax-detail"
                element={<SalesTaxDetailPage />}
              />
              <Route path="*" element={<Navigate to="/sign-in" replace />} />
            </Routes>
            <ToastContainer closeButton={true} />
          </div>
        </AppStateProvider>
      </LocalizationProvider>
    </ThemeProvider>
  )
}

export default App
