import React, {useState, useEffect} from 'react'
import {ArrowDown} from '../../assets/svgs'
import styles from './FinanceDetailPage.module.scss'
import {useLocation, useNavigate} from 'react-router-dom'
import withAuthorization from '../Session/withAuthorization'
import NavBar from '../Navbar'
import Layout from '../Layout'
import {useFirebase} from '../Firebase/hooks'
import {toast} from 'react-toastify'
import {Loader} from '../Loader'
import DetailPage from '../DetailPage'
import {Collection, applicationStatus} from 'common/enums'
import Button from 'components/Button'
import ModalComponent from '../ModalComponent'
import ConfirmationModal from 'components/ConfirmationModal'
import {DetailType} from 'components/enums'
import cn from 'classnames'

interface FinanceDetailPageProps {
  customClass: string
}

const FinanceDetailPage = ({customClass}: FinanceDetailPageProps) => {
  const navigate = useNavigate()
  const {state} = useLocation()
  const [show, setShow] = useState<boolean>(false)
  const {getDocumentData, updateFinanceStatus} = useFirebase()
  const financeId = state?.id || ''
  const [loader, setLoader] = useState(true)
  const previousPage = state?.currentPage
  const [appState, setAppState] = useState<any>({
    documentData: {}
  })
  const contactID = appState?.documentData?.contactId || ''
  const [isApprove, setIsApprove] = useState<boolean>(false)
  const isApplied = appState?.documentData?.status !== applicationStatus.APPLIED

  const toggleShow = () => setShow(!show)

  const cancelCallback = (error: Error) => {
    if (error) {
      toast.error(`Something went wrong while fetching the finance document.`)
    }
  }

  const handleUpdateFinanceStatus = async (isAccepted?: boolean) => {
    toggleShow()
    setLoader(true)
    const {error} = await updateFinanceStatus(contactID, {
      financeId,
      financingStatus: isAccepted
        ? applicationStatus.ACCEPTED
        : applicationStatus.REJECTED
    })
    setLoader(false)
    if (error) {
      toast.error('Something Went Wrong!')
      return
    } else {
      toast.success('Status Changed Successfully!')
      return
    }
  }

  useEffect(() => {
    const {unsSubData} = getDocumentData(
      setAppState,
      cancelCallback,
      financeId,
      Collection.Finance
    )
    setTimeout(() => {
      setLoader(false)
    }, 2000)
    return () => {
      unsSubData?.()
    }
  }, [])

  const handleStatusButton = (isApproveBool: boolean) => {
    setIsApprove(isApproveBool)
    setShow(true)
  }
  return (
    <div>
      <div className={styles.withLayout}>
        <Layout />
        <div className={cn(styles.container, customClass)}>
          <NavBar title="Finance Application Details" />
          {loader ? (
            <Loader />
          ) : (
            <React.Fragment>
              <div className={styles.buttons}>
                <span
                  onClick={() =>
                    navigate('/finance-applications', {
                      state: {page: previousPage}
                    })
                  }
                >
                  <ArrowDown />
                  Back
                </span>
                {!isApplied && (
                  <div className={styles.twobtn}>
                    <Button
                      text="Approve Application"
                      customClass={styles.changes_btn}
                      onClick={() => handleStatusButton(true)}
                    />
                    <Button
                      text="Reject Application"
                      customClass={styles.changes_btn}
                      onClick={() => handleStatusButton(false)}
                    />
                  </div>
                )}
              </div>

              <div className={styles.edit_section}>
                <DetailPage
                  data={appState.documentData}
                  detailType={DetailType.Finance}
                />
              </div>
            </React.Fragment>
          )}

          {show && (
            <ModalComponent handleClose={toggleShow} show={show}>
              <ConfirmationModal
                handleClose={toggleShow}
                text={`Change Status to ${isApprove ? 'Accepted' : 'Rejected'}`}
                content={
                  'You cannot undo these changes. Do you want to save changes to this document?'
                }
                isApprove={isApprove}
                handleConfirmButton={handleUpdateFinanceStatus}
              />
            </ModalComponent>
          )}
        </div>
      </div>
    </div>
  )
}

export default withAuthorization(FinanceDetailPage)
