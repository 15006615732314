import classNames from 'classnames'
import {AdminDocument, Map} from 'common'
import AlgoliaClient, {
  ADMINS_ADMIN_PANEL_ALGOLIA_INDEX
} from 'components/AlgoliaSearch'
import InputField from 'components/InputField'
import Pagination from 'components/Pagination'
import {TABLE_PAGINATION_PAGE_SIZE} from 'components/constants'
import {
  convertToDate,
  getFormattedDate,
  getUserFullName,
  searchWithAlgolia,
  useSortableData
} from 'components/utils'
import {Timestamp} from 'firebase/firestore'
import {debounce, isDate} from 'lodash'
import React, {useEffect, useRef, useState} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {CheckedIcon, UncheckedIconTwo} from '../../assets/images'
import Button from '../Button'
import Layout from '../Layout'
import {Loader} from '../Loader'
import NavBar from '../Navbar'
import withAuthorization from '../Session/withAuthorization'
import Table from '../TableComponent'
import styles from './RoleManagement.module.scss'

const adminRoleConst: Map = {
  superManager: 'Super Manager',
  superAdmin: 'Super Admin'
}

interface RoleManagementProps {
  customClass: string
}

interface formattedAdminData {
  adminName: string
  emailAddress: string
  mobileNumber: string
  role: string
  isActive: boolean
  documentId: string
  isMSA: boolean
  createdAt: string
  date: string | number
}

const RoleManagement = ({customClass}: RoleManagementProps) => {
  const {state} = useLocation()
  const pageFromLocation = state?.page
  const [pageCount, setPageCount] = useState<number>(0)
  const [currentPage, setCurrentPage] = useState<number>(pageFromLocation || 1)
  const [loader, setLoader] = useState(true)
  const [adminData, setAdminData] = useState<Array<AdminDocument>>([])
  const navigate = useNavigate()
  const searchIndex = AlgoliaClient.initIndex(ADMINS_ADMIN_PANEL_ALGOLIA_INDEX)
  const debouncedSearch = useRef(
    debounce(searchWithAlgolia<AdminDocument>, 800)
  )
  const [searchValue, setSearchValue] = useState<string>('')

  useEffect(() => {
    AlgoliaClient.clearCache()
    searchWithAlgolia<AdminDocument>({
      searchQuery: '*',
      searchIndex,
      setLoaderState: setLoader,
      setData: setAdminData,
      setPageCount,
      currentPage
    }).then(() => setLoader(false))
  }, [])

  const getFormattedData = (adminData: AdminDocument) => {
    const {contactInfo} = adminData
    const firstName = adminData?.firstName || ''
    const lastName = adminData?.lastName || ''
    const role = (adminData?.role && adminRoleConst[adminData?.role]) || 'MSA'
    const adminName = getUserFullName(firstName, lastName)
    const mobileNumber = contactInfo?.mobileNumber || ''
    const emailAddress = contactInfo?.emailAddress || ''
    const isActive = adminData?.isActive || false
    const documentId = adminData?.userId || ''
    const isMSA = role === 'MSA' || false
    const createdAtDate =
      adminData?.createdAt && convertToDate(adminData?.createdAt)
    const createdAt = isDate(createdAtDate)
      ? getFormattedDate(createdAtDate)
      : '-'
    const date = isDate(createdAtDate)
      ? Timestamp.fromDate(createdAtDate).seconds
      : '-'
    return {
      adminName,
      emailAddress,
      mobileNumber,
      role,
      isActive,
      documentId,
      isMSA,
      createdAt,
      date
    }
  }

  const getRowData = (rowData: Array<formattedAdminData>) => {
    return (rowData || []).map(
      (singleRowData: formattedAdminData, index: number) => {
        const {
          adminName,
          role,
          emailAddress,
          mobileNumber,
          isActive,
          documentId,
          isMSA,
          createdAt
        } = singleRowData
        return (
          <tr
            key={`role-management-row-data-${index}`}
            onClick={() => {
              navigate('/role-management/role-management-details', {
                state: {
                  id: documentId,
                  isEdit: true,
                  isMSA,
                  isActive,
                  currentPage
                },
                preventScrollReset: true
              })
            }}
          >
            <td>{adminName || '-'}</td>
            <td className={styles.no_text_transform}>{emailAddress || '-'}</td>
            <td>{mobileNumber || '-'}</td>
            <td>{role || '-'}</td>
            <td>
              <img
                src={isActive ? CheckedIcon : UncheckedIconTwo}
                alt="active"
              />
            </td>
            <td>{createdAt || '-'}</td>
          </tr>
        )
      }
    )
  }

  const scrollRef: React.RefObject<HTMLDivElement> = React.createRef()

  const scrollToRef = () =>
    scrollRef?.current?.scrollIntoView({behavior: 'smooth'})

  const formattedAdminData = adminData.map((data) => getFormattedData(data))

  const {items, sortBy} = useSortableData(formattedAdminData)

  const showPagination = pageCount > TABLE_PAGINATION_PAGE_SIZE

  const onPageChange = (page: number) => {
    setLoader(true)
    setCurrentPage(page)
    debouncedSearch?.current({
      searchQuery: searchValue,
      searchIndex,
      setLoaderState: setLoader,
      setData: setAdminData,
      setPageCount,
      currentPage: page
    })
    setLoader(false)
    scrollToRef()
  }

  const tableTitles = [
    {title: 'Contact Name', sortBy: () => sortBy('adminName')},
    {title: 'Email Address', sortBy: () => sortBy('emailAddress')},
    {title: 'Mobile Number'},
    {title: 'Role', sortBy: () => sortBy('role')},
    {title: 'Active'},
    {title: 'Created At', sortBy: () => sortBy('date')}
  ]

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event?.target?.value
    setSearchValue(value)
    if (value) {
      debouncedSearch?.current({
        searchQuery: value,
        searchIndex,
        setLoaderState: setLoader,
        setData: setAdminData,
        setPageCount
      })
    } else {
      debouncedSearch?.current({
        searchQuery: '*',
        searchIndex,
        setLoaderState: setLoader,
        setData: setAdminData,
        setPageCount,
        currentPage
      })
    }
  }

  return (
    <div className={styles.withLayout}>
      <Layout />
      <div className={classNames(styles.container, customClass)}>
        <NavBar title="Role Management" />
        <div className={styles.button_filters}>
          <InputField
            name="adminSearchField"
            type="text"
            value={searchValue}
            onChange={handleSearch}
            isSearch={true}
            customClass={styles.inputField}
            placeholder="Search"
          />
          <Button
            customClass={styles.create_account_btn}
            text="Add Roles"
            onClick={() => navigate('/role-management/role-management-details')}
          />
        </div>
        {loader ? (
          <Loader />
        ) : (
          <React.Fragment>
            <Table
              customClass={styles.threedetails}
              titles={tableTitles}
              rowData={getRowData(items)}
            />
            {showPagination && (
              <div className="pagination__wrapper">
                <Pagination
                  currentPage={currentPage}
                  totalCount={pageCount}
                  pageSize={TABLE_PAGINATION_PAGE_SIZE}
                  onPageChange={onPageChange}
                />
              </div>
            )}
          </React.Fragment>
        )}
      </div>
    </div>
  )
}

export default withAuthorization(RoleManagement)
