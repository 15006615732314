import React from 'react'
import styles from '../DetailPage.module.scss'
import {FinanceDocument} from 'common'
import {convertToDate, getFormattedDate} from 'components/utils'
import {isDate, startCase} from 'lodash'
import {FieldSet} from '../DetailFields'

const FinanceDetails = (data: FinanceDocument) => {
  const applicationFields = [
    {
      label: 'Account Name',
      value: data?.accountName || '-'
    },
    {
      label: 'Contact Name',
      value: data?.contactName || '-'
    },
    {
      label: 'Contact Number',
      value: data?.contactNumber || '-'
    },
    {
      label: 'Product Id',
      value: data?.productId || '-'
    }
  ]

  const applicationDetailsFields = [
    {
      label: 'Created At',
      value: isDate(convertToDate(data?.createdAt))
        ? getFormattedDate(convertToDate(data?.createdAt))
        : '-'
    },
    {
      label: 'Last Updated',
      value: isDate(convertToDate(data?.lastUpdatedAt))
        ? getFormattedDate(convertToDate(data?.lastUpdatedAt))
        : '-'
    },
    {
      label: 'Status',
      value: startCase(data?.status) || '-'
    }
  ]

  return (
    <React.Fragment>
      <div className={styles.inputsfields}>
        <FieldSet title="Application Details" fields={applicationFields} />
      </div>

      <div style={{marginTop: '1.5rem'}} className={styles.inputsfields}>
        <FieldSet fields={applicationDetailsFields} />
      </div>
    </React.Fragment>
  )
}

export default FinanceDetails
