import * as Yup from 'yup'
import {URL_REGEX} from 'components/constants'
import phone from 'phone'
import {IFormattedAccount} from 'components/types'

export const INIITAL_ACCOUNT_STATE: IFormattedAccount = {
  accountName: '',
  accountType: '',
  billingCity: '',
  billingCountry: '',
  billingState: '',
  billingStreetAddress: '',
  billingStreetAddress2: '',
  billingZipCode: '',
  descriptionInfo: '',
  email: '',
  parentAccountId: '',
  phoneNumber: '',
  sector: '',
  shippingCity: '',
  shippingCountry: '',
  shippingState: '',
  shippingStreetAddress: '',
  shippingStreetAddress2: '',
  shippingZipCode: '',
  websiteURL: '',
  sameAddress: false
}

export const CreateAccountSchema = Yup.object({
  accountName: Yup.string()
    .required('Required')
    .min(3, 'Account name must be greater than 3 characters.'),
  websiteURL: Yup.string().matches(URL_REGEX, 'Invalid URL'),
  email: Yup.string()
    .matches(/^[^$%`~|\/]+$/, 'Invalid Email')
    .email('Invalid Email'),
  phoneNumber: Yup.string()
    .test('invalid_number', 'Invalid Number', (value) => {
      return value ? phone(value, {validateMobilePrefix: true}).isValid : false
    })
    .optional(),
  shippingStreetAddress: Yup.string().when('sameAddress', {
    is: (sameAddress: boolean | undefined) =>
      sameAddress === false || sameAddress === undefined,
    then: Yup.string().required('Required')
  }),
  shippingStreetAddress2: Yup.string(),
  shippingCountry: Yup.string().when('sameAddress', {
    is: (sameAddress: boolean | undefined) =>
      sameAddress === false || sameAddress === undefined,
    then: Yup.string().required('Required')
  }),
  shippingState: Yup.string().when('sameAddress', {
    is: (sameAddress: boolean | undefined) =>
      sameAddress === false || sameAddress === undefined,
    then: Yup.string().required('Required')
  }),
  shippingCity: Yup.string().when('sameAddress', {
    is: (sameAddress: boolean | undefined) =>
      sameAddress === false || sameAddress === undefined,
    then: Yup.string().required('Required')
  }),
  shippingZipCode: Yup.string().when('sameAddress', {
    is: (sameAddress: boolean | undefined) =>
      sameAddress === false || sameAddress === undefined,
    then: Yup.string().required('Required')
  }),
  sameAddress: Yup.boolean(),
  billingStreetAddress: Yup.string().required('Required'),
  billingStreetAddress2: Yup.string(),
  billingCountry: Yup.string().required('Required'),
  billingState: Yup.string().required('Required'),
  billingCity: Yup.string().required('Required'),
  billingZipCode: Yup.string().required('Required'),
  parentAccountId: Yup.string(),
  sector: Yup.string().required('Required'),
  accountType: Yup.string().required('Required'),
  descriptionInfo: Yup.string()
})
