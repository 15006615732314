import classNames from 'classnames'
import {get} from 'lodash'
import React from 'react'
import CurrencyInput from 'react-currency-input-field'
import styles from './InputField.module.scss'

const CurrencyInputField = ({
  name,
  id,
  label,
  placeholder = '$',
  errors = {},
  touched = {},
  onBlur,
  disabled,
  readOnly,
  value,
  onInput,
  setFieldValue,
  onChange,
  customClass,
  noError
}: {
  id?: string
  name: string
  placeholder?: string
  value?: string | number
  defaultValue?: string
  errors?: any
  touched?: any
  label?: string
  disabled?: boolean
  readOnly?: boolean
  onChange?: (changeValue: string | undefined) => void
  setFieldValue?: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<any>
  onBlur?: (e: any) => void
  onInput?: (e: any) => void
  customClass?: string
  noError?: boolean
}) => {
  const inputRef = React.useRef<HTMLInputElement>(null)
  const hasError = get(touched, name) || ''
  const inputFieldError = hasError && get(errors, name)

  function formatNumberWithTwoDecimals(input: string) {
    let number = parseFloat(input)
    if (input.endsWith('.')) {
      return number.toFixed(1)
    }
    return Number(number.toFixed(2))
  }

  const handleChange = (changeValue: string | undefined) => {
    const maxValue = ((changeValue && parseFloat(changeValue)) || 0) > 9999999
    if (maxValue) return
    const numberValue = !changeValue
      ? undefined
      : formatNumberWithTwoDecimals(changeValue)
    setFieldValue?.(name || id || '', numberValue)
  }

  const handleKeyDown = (event: React.KeyboardEvent) => {
    const hasSingleDecimalsValue =
      `${value}`.includes('.') && `${value}`.split('.')[1].length === 1
    const hasDecimal = `${value}`.includes('.')
    const defaultPreventArray = ['b', 'm', 'k', '-']
    const removeKeys = ['Backspace', 'Delete']
    const arrowKeys = ['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight']
    const shouldPreventDefault =
      (hasDecimal && event.key === '.') ||
      defaultPreventArray.includes(event.key)
    if (hasSingleDecimalsValue && event.key === 'Backspace') {
      event.preventDefault()
      onChange
        ? onChange(`${value}`.split('.')?.[0])
        : setFieldValue?.(name || id || '', Number(`${value}`.split('.')?.[0]))
    }
    if (shouldPreventDefault) {
      event.preventDefault()
    }
    const inputEvent = event?.target as any
    const cursorPosition = inputEvent?.selectionStart || 0
    const targetValue = inputEvent?.value
    const decimalPosition = targetValue?.indexOf('.')
    if (
      cursorPosition > decimalPosition &&
      !removeKeys.includes(event.key) &&
      !arrowKeys.includes(event.key)
    ) {
      inputRef.current?.setSelectionRange(cursorPosition, cursorPosition + 1)
    }
  }

  return (
    <div className={classNames(styles.container, customClass && customClass)}>
      {label && <label htmlFor={id}>{label}</label>}
      <span style={{position: 'relative'}}>
        <CurrencyInput
          ref={inputRef}
          id={id}
          name={name}
          step={10}
          prefix={'$'}
          className={classNames(
            styles.priceInputField,
            inputFieldError && styles.hasError
          )}
          placeholder={placeholder}
          onValueChange={onChange ? onChange : handleChange}
          disabled={disabled}
          value={value}
          allowNegativeValue={false}
          allowDecimals={true}
          onBlur={onBlur}
          maxLength={18}
          onInput={onInput}
          readOnly={readOnly}
          onKeyDown={handleKeyDown}
        />
        <span className={styles.suffix}>USD</span>
      </span>
      {!noError && <div className={styles.error}>{inputFieldError}</div>}
    </div>
  )
}

export default CurrencyInputField
