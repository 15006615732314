import classNames from 'classnames'
import {DealDocument, ProductDocument} from 'common'
import {productStatus} from 'common/enums'
import AlgoliaClient, {
  PRODUCTS_ADMIN_PANEL_ALGOLIA_INDEX
} from 'components/AlgoliaSearch'
import Button from 'components/Button'
import InputField from 'components/InputField'
import {Loader} from 'components/Loader'
import ReqNotFoundScreen from 'components/ReqNotFoundScreen'
import {FormikProps} from 'formik'
import {debounce} from 'lodash'
import React, {useRef, useState} from 'react'
import {toast} from 'react-toastify'
import {Cancel} from '../../assets/svgs'
import {
  convertToDate,
  getEllipsisString,
  getFormattedDate,
  searchWithAlgolia
} from '../utils'
import styles from './SelectProductModal.module.scss'
import {PRODUCTION_URL} from 'components/constants'
interface ConfirmToPayInvoiceModalProps {
  handleClose: () => void
  formRef: React.RefObject<FormikProps<DealDocument>>
}

const SelectProductModal = ({
  handleClose,
  formRef
}: ConfirmToPayInvoiceModalProps) => {
  const searchIndex = AlgoliaClient.initIndex(
    PRODUCTS_ADMIN_PANEL_ALGOLIA_INDEX
  )
  const [selectedProduct, setSelectedProduct] =
    useState<ProductDocument | null>(null)
  const [loader, setLoader] = useState<boolean>(false)
  const [productData, setProductsData] = useState<Array<ProductDocument>>([])
  const [searchValue, setSearchValue] = useState('')
  const noData = !productData.length && !loader

  const debouncedSearch = useRef(
    debounce(searchWithAlgolia<ProductDocument>, 800)
  )

  const handleSearch = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event?.target?.value
    setSearchValue(value)
    if (value) {
      setLoader(true)
      await debouncedSearch?.current({
        searchQuery: value,
        searchIndex,
        setLoaderState: setLoader,
        setData: setProductsData,
        accountList: [],
        setPageCount: () => {},
        numberOfHits: 5,
        facetFilters: [`status:${productStatus.Approved}`]
      })
    }
  }

  const setProductValues = () => {
    const {current} = formRef
    const productImage = selectedProduct?.productDetails?.images?.length
      ? [selectedProduct?.productDetails?.images[0]]
      : []
    const equipmentAddressObject =
      selectedProduct?.isTheEquipmentAtADifferentAddress
    current?.setFieldValue('dealName', selectedProduct?.title || '')
    current?.setFieldValue('productId', selectedProduct?.productId || '')
    current?.setFieldValue(
      'location.equipmentCity',
      equipmentAddressObject?.equipmentCity || ''
    )
    current?.setFieldValue(
      'location.equipmentCountry',
      equipmentAddressObject?.equipmentCountry || ''
    )
    current?.setFieldValue(
      'location.equipmentState',
      equipmentAddressObject?.equipmentState || ''
    )
    current?.setFieldValue(
      'location.equipmentStreet',
      equipmentAddressObject?.equipmentStreet || ''
    )
    current?.setFieldValue(
      'location.equipmentZip',
      equipmentAddressObject?.equipmentZip || ''
    )
    current?.setFieldValue('thumbnail.productImage', productImage)
    current?.setFieldValue('thumbnail.sellerProfilePicture', [
      selectedProduct?.sellerPhotoUrl
    ])
    current?.setFieldValue('sellerContactId', selectedProduct?.contactId || '')
    current?.setFieldValue(
      'sellerContactName',
      selectedProduct?.contactName || ''
    )
    current?.setFieldValue('sellerAccountId', selectedProduct?.accountId || '')
    current?.setFieldValue(
      'sellerAccountName',
      selectedProduct?.accountName || ''
    )
    current?.setFieldValue(
      'listingPrice',
      selectedProduct?.finalSalePriceMap?.finalSalePrice || ''
    )
    current?.setFieldError('dealName', '')
    current?.setFieldTouched('dealName', false)
    handleClose()
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.heading}>
          <Cancel onClick={handleClose} />
          <h1>Search For Equipment</h1>
        </div>
        <InputField
          name="productModalSearchField"
          isSearch={true}
          type="text"
          value={searchValue}
          onChange={handleSearch}
          customClass={styles.inputField}
          placeholder="Search"
        />
        {loader ? (
          <Loader fullHeight={false} />
        ) : noData ? (
          <div className={styles.noItemsScreen}>
            <ReqNotFoundScreen />
          </div>
        ) : (
          <div className={styles.container_products}>
            {productData.map((product) => {
              const productId = product.productId
              const productTitle = product?.title || ''
              const productTitleFormatted = getEllipsisString(productTitle, 24)
              const accountNameFormatted = getEllipsisString(
                product.accountName,
                24
              )
              const contactNameFormatted = getEllipsisString(
                product.contactName,
                24
              )
              const isSelectedProduct = selectedProduct?.productId === productId
              return (
                <div
                  key={`Product-${productId}`}
                  className={classNames(
                    styles.container_img,
                    isSelectedProduct && styles.selected_product
                  )}
                  onClick={() => {
                    setSelectedProduct(product)
                  }}
                >
                  <img
                    width={50}
                    height={50}
                    alt="product-img"
                    src={product?.productDetails?.images?.[0]}
                  />
                  <div className={styles.product_detail_container}>
                    <div className={styles.heading}>
                      <div className={styles.product_heading}>
                        Equipment Title
                      </div>
                      <div className={styles.product_heading}>
                        Seller Account Name
                      </div>
                      <div className={styles.product_heading}>
                        Seller Contact Name
                      </div>
                      <div
                        className={classNames(
                          styles.product_heading,
                          styles.specificwidth
                        )}
                      >
                        Created At
                      </div>
                    </div>
                    <div className={styles.info_content}>
                      <h4>
                        <b>{productTitleFormatted || ''}</b>
                      </h4>
                      <h4>
                        <b>{accountNameFormatted}</b>
                      </h4>
                      <h4>
                        <b>{contactNameFormatted}</b>
                      </h4>
                      <h4 className={styles.specificwidth}>
                        {product.createdAt
                          ? getFormattedDate(convertToDate(product.createdAt))
                          : ''}
                      </h4>
                    </div>
                  </div>
                  <div className={styles.viewProduct}>
                    <a
                      href={`${PRODUCTION_URL}/equipment-detail/${productId}`}
                      className={styles.button}
                      target="_blank"
                      rel="noreferrer"
                    >
                      View Equipment
                    </a>
                  </div>
                </div>
              )
            })}
          </div>
        )}
        <div className={styles.buttons}>
          <button type="button" onClick={handleClose}>
            Cancel
          </button>
          <Button
            text="Confirm"
            onClick={() => {
              if (selectedProduct) {
                setProductValues()
              } else {
                toast.error('Please select a product')
              }
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default SelectProductModal
