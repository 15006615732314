import React, {useState, useEffect} from 'react'
import styles from './ConfirmationModal.module.scss'
import {ObjectSVG, Cancel, CrossIcon} from '../../assets/svgs'
import {DealDocument, AgreementDocument, ProductDocument} from 'common'
import {useFirebase} from 'components/Firebase/hooks'
import {Collection} from 'common/enums'
import Button from 'components/Button'
import cn from 'classnames'
interface ModalProps {
  handleClose: () => void
  text: string
  content: string
  productID?: string
  currentProduct?: ProductDocument
  isApprove?: boolean
  handleConfirmButton?: (isAccepted?: boolean) => Promise<void>
  isDelete?: boolean
  removeIcon?: boolean
  handleSubmit?: () => void
  handleDeleteButton?: () => void
  setHasDocuments?: React.Dispatch<React.SetStateAction<boolean>>
  documentId?: string
  isContact?: boolean
  submitModal?: boolean
  isAccount?: boolean
  hasCheckedDocuments?: boolean
  setHasCheckedDocuments?: React.Dispatch<React.SetStateAction<boolean>>
  isAdmin?: boolean
  shouldCheckForDocuments?: boolean
}

const ConfirmationModal = ({
  handleClose,
  handleSubmit,
  text,
  content,
  removeIcon,
  isApprove,
  handleConfirmButton,
  isDelete,
  submitModal,
  handleDeleteButton,
  setHasDocuments,
  documentId,
  isContact,
  isAccount,
  hasCheckedDocuments,
  setHasCheckedDocuments,
  isAdmin,
  shouldCheckForDocuments = true
}: ModalProps) => {
  const [disabled, setDisabled] = useState(
    isAdmin || hasCheckedDocuments ? false : isDelete ? true : false
  )
  const isProduct = !isContact && !isAccount
  const collectionName = isContact
    ? Collection.Products
    : isAccount
    ? Collection.Contacts
    : ''
  const queryValue = isContact
    ? 'contactId'
    : isAccount
    ? 'accountId'
    : 'productId'
  const {getCollectionBasedOnQuery} = useFirebase()

  const checkForExistingDocuments = async () => {
    setDisabled(true)
    if (isProduct) {
      const {data: dealsCollectionData = []} =
        await getCollectionBasedOnQuery<DealDocument>(
          Collection.Deals,
          queryValue,
          documentId || ''
        )
      const {data: laAgreementData = []} =
        await getCollectionBasedOnQuery<AgreementDocument>(
          Collection.ZohoSignListingAgreement,
          queryValue,
          documentId || ''
        )
      setHasDocuments &&
        setHasDocuments(
          laAgreementData.length || dealsCollectionData.length ? true : false
        )
      setDisabled(false)
      setHasCheckedDocuments && setHasCheckedDocuments(true)
    } else {
      const {data: collectionData = []} = await getCollectionBasedOnQuery(
        collectionName,
        queryValue,
        documentId || ''
      )
      setHasDocuments && setHasDocuments(collectionData.length ? true : false)
      setDisabled(false)
      setHasCheckedDocuments && setHasCheckedDocuments(true)
    }
  }

  useEffect(() => {
    if (
      isDelete &&
      !hasCheckedDocuments &&
      !isAdmin &&
      shouldCheckForDocuments
    ) {
      checkForExistingDocuments()
    }
  }, [isDelete, hasCheckedDocuments, isAdmin])

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.heading}>
          <Cancel className={styles.cancel} onClick={handleClose} />
          <div className={styles.infoContainer}>
            {removeIcon ? <CrossIcon /> : <ObjectSVG />}
          </div>
        </div>
        <h1>{text}</h1>
        <p>{content}</p>
        {removeIcon ? (
          <div className={cn(styles.buttons, styles.singleButton)}>
            <button
              className="btn-cancel"
              disabled={disabled}
              onClick={() => {
                if (submitModal) {
                  handleSubmit?.()
                } else {
                  if (isDelete) {
                    handleDeleteButton?.()
                  } else {
                    handleConfirmButton?.(isApprove || false)
                  }
                }
              }}
            >
              Confirm
            </button>
          </div>
        ) : (
          <div className={styles.buttons}>
            <button className="btn-cancel" onClick={handleClose}>
              Cancel
            </button>
            <Button
              disabled={disabled}
              onClick={() => {
                if (submitModal) {
                  handleSubmit && handleSubmit()
                } else {
                  if (isDelete) {
                    handleDeleteButton && handleDeleteButton()
                  } else {
                    handleConfirmButton &&
                      handleConfirmButton(isApprove || false)
                  }
                }
              }}
              text="Confirm"
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default ConfirmationModal
