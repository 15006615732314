import classNames from 'classnames'
import {TaxExemptionCertificate} from 'common'
import {applicationStatus} from 'common/enums'
import ContentModal from 'components/ContentModal'
import ModalComponent from 'components/ModalComponent'
import ViewPDFModal from 'components/ViewPDFModal'
import {getFormattedDate} from 'components/utils'
import React, {useState} from 'react'
import styles from '../DetailPage.module.scss'
import {useFirebase} from 'components/Firebase/hooks'
import {toast} from 'react-toastify'

interface IRowData extends TaxExemptionCertificate {
  contactId: string
}

const RowData = ({
  certificateId,
  createdAt,
  document,
  contactId,
  status
}: IRowData) => {
  const [showAcceptModal, setShowAcceptModal] = useState<boolean>(false)
  const [showRejectModal, setShowRejectModal] = useState<boolean>(false)
  const [showPDFModal, setShowPDFModal] = useState<boolean>(false)
  const [disabled, setDisabled] = useState<boolean>(false)
  const {updateExemptionCertificate} = useFirebase()
  const documentTitle = document?.originalTitle || ''

  const toggleRejectModal = () => setShowRejectModal(!showRejectModal)
  const toggleAcceptModal = () => setShowAcceptModal(!showAcceptModal)
  const toggleViewPDFModal = () => setShowPDFModal(!showPDFModal)

  const handleApplicationUpdate = async (
    status: applicationStatus,
    callBack: () => void
  ) => {
    setDisabled(true)
    const {message, success} = await updateExemptionCertificate({
      certificateId,
      status,
      userId: contactId
    })
    success ? toast.success(message) : toast.error(message)
    setDisabled(false)
    callBack()
  }

  return (
    <React.Fragment>
      <tr>
        <td title={documentTitle}>
          {documentTitle.length > 16
            ? `${documentTitle.slice(0, 16)}...`
            : documentTitle}
        </td>
        <td className="capitalize">
          {status === applicationStatus.APPLIED ? (
            <div className="flex-center">
              <button
                onClick={toggleAcceptModal}
                className={classNames(styles.btn, 'btn-fill-orange')}
              >
                Approve
              </button>
              <button
                onClick={toggleRejectModal}
                className={classNames(styles.btn, styles.btn_red)}
              >
                Reject
              </button>
            </div>
          ) : (
            status
          )}
        </td>
        <td>{getFormattedDate(createdAt) || '-'}</td>
        <td>
          <button
            onClick={toggleViewPDFModal}
            className={classNames(styles.btn, 'btn-fill-orange')}
          >
            View
          </button>
        </td>
      </tr>
      {showPDFModal && (
        <ModalComponent show={showPDFModal} handleClose={toggleViewPDFModal}>
          <ViewPDFModal handleClose={toggleViewPDFModal} url={document.url} />
        </ModalComponent>
      )}
      {showRejectModal && (
        <ModalComponent handleClose={toggleRejectModal} show={showRejectModal}>
          <ContentModal
            removeIcon={true}
            buttonText="Reject"
            buttonOnClick={() =>
              handleApplicationUpdate(
                applicationStatus.REJECTED,
                toggleRejectModal
              )
            }
            buttonDisabled={disabled}
            handleClose={toggleRejectModal}
            heading="Reject Application for Tax Exemption"
            content={
              'You cannot undo these changes. Are you sure you want to reject this application?'
            }
          />
        </ModalComponent>
      )}
      {showAcceptModal && (
        <ModalComponent handleClose={toggleAcceptModal} show={showAcceptModal}>
          <ContentModal
            buttonText="Confirm"
            buttonOnClick={() =>
              handleApplicationUpdate(
                applicationStatus.ACCEPTED,
                toggleAcceptModal
              )
            }
            buttonDisabled={disabled}
            handleClose={toggleAcceptModal}
            heading="Approve Application for Tax Exemption"
            content={
              'You cannot undo these changes. Are you sure you want to approve this application?'
            }
          />
        </ModalComponent>
      )}
    </React.Fragment>
  )
}

export default RowData
