import styles from './Loader.module.scss'

export const Loader = ({fullHeight = true}: {fullHeight?: boolean}) => (
  <div className={styles.fullScreenLoader}>
    <span className={styles.loader}></span>
    <div className={styles.heading}>Loading please wait</div>
  </div>
)

export const LoaderWithoutText = () => (
  <div className={styles.loader}>
    <span className={styles.loader}></span>
  </div>
)
