import {FILE_OBJECT} from 'common'
import React, {useCallback, useRef, useState} from 'react'
import {toast} from 'react-toastify'
import {stateObjProps} from '../MessageBar'
import styles from './AttachmentInput.module.scss'
import {useFirebase} from 'components/Firebase/hooks'
import {
  getParsedFileNameForUploadingFile,
  useClickOutside
} from 'components/utils'
import {DocumentIcon, ImageIconFilled, PinLight} from 'assets/svgs'

interface AttachmentInputProps {
  stateObj: stateObjProps
  isDisabled?: boolean
}

const AttachmentInput = ({stateObj, isDisabled}: AttachmentInputProps) => {
  const {filesArr, setFilesArr, setIsLoading} = stateObj
  const [showAttachmentOptions, setShowAttachmentOptions] = useState(false)
  const attachmentWindowRef = useRef<HTMLDivElement>(null)
  const imageVideoRef = useRef<HTMLInputElement | null>(null)
  const attachmentRef = useRef<HTMLInputElement | null>(null)

  const {uploadFile} = useFirebase()

  const toggleAttachmentOptions = () =>
    setShowAttachmentOptions(!showAttachmentOptions)

  const dismissAttachmentWindow = useCallback(() => {
    setShowAttachmentOptions(false)
  }, [setShowAttachmentOptions])

  useClickOutside([attachmentWindowRef], dismissAttachmentWindow)

  const getFilesBasedOnType = (
    fileArray: Array<FILE_OBJECT>,
    fileType: string
  ) => fileArray?.filter((file: FILE_OBJECT) => file.type.includes(fileType))

  const getFileInformation = (file: File) => {
    const {type, size, name} = file
    const isGif = type.includes('gif')
    const isImage = type.includes('image')
    const isVideo = type.includes('video')
    const isVideoFormatValid = type === 'video/mp4'
    const isPDF = type.includes('application/pdf')
    const sizeInMB = size / 1024 ** 2
    const fileArray = [...filesArr]
    const countOfImages = getFilesBasedOnType(fileArray, 'image')?.length
    const totalSizeOfVideos = getFilesBasedOnType(fileArray, 'video').reduce(
      (curr, prev) => curr + (prev?.mbSize || 0),
      0
    )
    const totalSizeOfPDFs = getFilesBasedOnType(
      fileArray,
      'application/pdf'
    ).reduce((curr, prev) => curr + (prev?.mbSize || 0), 0)
    return {
      name,
      sizeInMB,
      countOfImages,
      totalSizeOfVideos,
      totalSizeOfPDFs,
      isGif,
      isImage,
      isVideo,
      isPDF,
      isVideoFormatValid
    }
  }

  const tempFilesArray: any = []
  const handleUpload = (
    currentFile: File,
    type: string,
    fileName: string,
    bytesToMegaBytes: number
  ) => {
    uploadFile({
      file: currentFile,
      onFileUpload: (downloadURL?: string) => {
        const file = {
          type,
          mbSize: bytesToMegaBytes,
          name: fileName,
          originalName: currentFile.name,
          URL: URL.createObjectURL(currentFile),
          downloadURL
        }
        tempFilesArray.push(currentFile)
        if (tempFilesArray.length > 20) {
          toast.warning('Maximum 20 Images Allowed')
          setIsLoading(false)
          return
        } else {
          setFilesArr((state: any) => [...state, file])
          toast.success('File Uploaded Successfully')
          setIsLoading(false)
          return
        }
      }
    })
  }

  const uploadFileValidations = (file: File) => {
    const {
      name,
      sizeInMB,
      countOfImages,
      totalSizeOfVideos,
      totalSizeOfPDFs,
      isGif,
      isImage,
      isVideo,
      isPDF
    } = getFileInformation(file)
    const fileExtention = name.split('.').pop()
    const isMP4 = (fileExtention?.search(/mp4/gi) === 0 && true) || false

    if (isVideo && !isMP4) {
      toast.warn('Only MP4 file format allowed for videos')
      return
    }
    const fileName = getParsedFileNameForUploadingFile(name)
    if ((isImage || isVideo || isPDF) && !isGif) {
      if (isImage) {
        if (sizeInMB > 10) {
          toast.warning('Images should not be over 10 MB')
          return
        }
        if (countOfImages > 20) {
          toast.warning('Maximum 20 Images Allowed')
          return
        } else {
          setIsLoading(true)
          handleUpload(file, 'image', fileName, sizeInMB)
        }
      }
      if (isVideo) {
        if (sizeInMB > 20) {
          toast.warning('Videos should not be over 20 MB')
          return
        }
        if (totalSizeOfVideos > 20) {
          toast.warning('Maximum 20MB of videos allowed')
          return
        } else {
          setIsLoading(true)
          handleUpload(file, 'video', name, sizeInMB)
        }
      }
      if (isPDF) {
        if (sizeInMB > 5) {
          toast.warning('Each Document should be less than 5 MB')
          return
        }
        if (totalSizeOfPDFs > 5) {
          toast.warning('Maximum 5MB of PDFs allowed')
          return
        } else {
          setIsLoading(true)
          handleUpload(file, 'application/pdf', name, sizeInMB)
        }
      }
    } else {
      toast.warning('GIFs and other formats are not allowed')
      return
    }
  }

  const handleFileOnChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    type: string
  ) => {
    if (e?.target?.files) {
      for (var i = 0; i < e.target.files.length; i++) {
        var imageFile = e.target.files[i]
        if (type === 'pdf') {
          if (imageFile.type === 'application/pdf') {
            uploadFileValidations(imageFile)
          } else {
            toast.warning('Document should only be in PDF format')
            return
          }
        } else if (type === 'image/video') {
          if (
            imageFile.type.includes('image') ||
            imageFile.type.includes('video')
          ) {
            uploadFileValidations(imageFile)
          } else {
            toast.warning('GIFs and other formats are not allowed')
            return
          }
        }
      }
    }
  }

  const handleFileOnClick:
    | React.MouseEventHandler<HTMLInputElement>
    | undefined = (e) => {
    const target = e.target as HTMLInputElement
    target.value = ''
  }

  const AttachmentWindow = () => (
    <div className={styles.attachment_window}>
      <div>
        <span>
          <ImageIconFilled />
          <input
            onChange={(e) => handleFileOnChange(e, 'image/video')}
            onClick={handleFileOnClick}
            type={'file'}
            ref={imageVideoRef}
            multiple
          />
          <span
            className={styles.spanContainer}
            onClick={() =>
              imageVideoRef.current && imageVideoRef.current.click()
            }
          >
            Image/Video
          </span>
        </span>
      </div>
      <span>
        <DocumentIcon />
        <input
          onChange={(e) => handleFileOnChange(e, 'pdf')}
          onClick={handleFileOnClick}
          type={'file'}
          multiple
          ref={attachmentRef}
        />
        <span
          className={styles.spanContainer}
          onClick={() => attachmentRef.current && attachmentRef.current.click()}
        >
          Document
        </span>
      </span>
    </div>
  )

  return (
    <div ref={attachmentWindowRef} className={styles.attachment__container}>
      {showAttachmentOptions && <AttachmentWindow />}
      <button disabled={isDisabled} onClick={toggleAttachmentOptions}>
        <PinLight />
      </button>
    </div>
  )
}

export default AttachmentInput
