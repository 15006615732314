import {TaxExemptionCertificate, TaxExemptionDocument} from 'common'
import {ExemptionType} from 'common/enums'
import Pagination from 'components/Pagination'
import Table from 'components/TableComponent'
import {TABLE_PAGINATION_PAGE_SIZE} from 'components/constants'
import {
  convertToDate,
  getCountryNameByCode,
  getStateNameByCode,
  getFormattedDate
} from 'components/utils'
import {isDate, replace, startCase} from 'lodash'
import React, {useMemo, useState} from 'react'
import {FieldSet} from '../DetailFields'
import styles from '../DetailPage.module.scss'
import RowData from './RowData'
import Button from 'components/Button'
import ExemptionRegionsAndTypeModal from './ExemptionRegionsAndTypeModal'
import classNames from 'classnames'

const TaxExemptionDetails = (data: TaxExemptionDocument) => {
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [showUpdateModal, setShowUpdateModal] = useState(false)
  const scrollRef: React.RefObject<HTMLDivElement> = React.createRef()
  const certificates = data?.certificates || []
  const lastUpdatedSeconds = data?.lastUpdated?.seconds || 0

  const scrollToRef = () =>
    scrollRef?.current?.scrollIntoView({behavior: 'smooth'})

  const toggleUpdateModal = () => setShowUpdateModal(!showUpdateModal)

  const onPageChange = (page: number) => {
    setCurrentPage(page)
    scrollToRef()
  }

  const getRowData = (rowData: Array<TaxExemptionCertificate>) =>
    rowData.map((rowData, index) => (
      <RowData
        key={`tax-exemption-certificate-${index}`}
        contactId={data.contactId}
        {...rowData}
      />
    ))

  const userExemptionType = startCase(
    replace(data?.exemptionType || ExemptionType.NonExempt, /_/g, ' ')
  )

  const applicationFields = [
    {
      label: 'Account Name',
      value: data?.accountName || '-'
    },
    {
      label: 'Account ID',
      value: data?.accountId || '-'
    },
    {
      label: 'Exemption Type',
      value: userExemptionType
    }
  ]

  const applicationDetailsFields = [
    {
      label: 'Contact Name',
      value: data?.contactName || '-'
    },
    {
      label: 'Contact ID',
      value: data?.contactId || '-'
    },
    {
      label: 'Last Updated At',
      value: isDate(convertToDate(data?.lastUpdated))
        ? getFormattedDate(convertToDate(data?.lastUpdated))
        : '-'
    }
  ]

  const applicationsColumnTitles = [
    {title: 'Title'},
    {title: 'Status'},
    {title: 'Date'},
    {title: 'Document'}
  ]

  const showPagination = certificates?.length > TABLE_PAGINATION_PAGE_SIZE

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * TABLE_PAGINATION_PAGE_SIZE
    const lastPageIndex = firstPageIndex + TABLE_PAGINATION_PAGE_SIZE
    return certificates.slice(firstPageIndex, lastPageIndex)
  }, [currentPage, lastUpdatedSeconds])

  const exemptRegions = data?.exemptRegions?.length
    ? data?.exemptRegions
        ?.map(({country, state}) => [
          {
            label: 'Country',
            value: country ? getCountryNameByCode(country) : ''
          },
          {
            label: 'State',
            value: country && state ? getStateNameByCode(state, country) : ''
          }
        ])
        .flat()
    : [{label: 'None', value: ''}]

  return (
    <React.Fragment>
      <div className="flex">
        <div className={styles.inputsfields}>
          <FieldSet title="Application Details" fields={applicationFields} />
        </div>
        <div style={{marginTop: '1.5rem'}} className={styles.inputsfields}>
          <FieldSet fields={applicationDetailsFields} />
        </div>
      </div>

      <div className={classNames(styles.exemptRegions, styles.inputsfields)}>
        <FieldSet isTwoCol title="Exempt Regions" fields={exemptRegions} />
      </div>

      <div style={{marginTop: '3rem'}}>
        <div className={styles.wireConfirmation_wraper}>
          <h1 className={styles.secondheading}>
            Tax Exemption Certificate Applications
          </h1>

          <Button
            customClass={styles.exemptionTypeAndRegions_btn}
            text="Update Exemption Type And Regions"
            onClick={toggleUpdateModal}
          />
        </div>
        <Table
          titles={applicationsColumnTitles}
          rowData={getRowData(currentTableData)}
          customClass={styles.containerTable}
        />
        {showPagination && (
          <div className="pagination__wrapper">
            <Pagination
              currentPage={currentPage}
              onPageChange={onPageChange}
              totalCount={certificates.length}
              pageSize={TABLE_PAGINATION_PAGE_SIZE}
            />
          </div>
        )}
      </div>
      {showUpdateModal && (
        <ExemptionRegionsAndTypeModal
          show={showUpdateModal}
          contactId={data.contactId}
          handleClose={toggleUpdateModal}
          exemptRegions={data.exemptRegions}
          exemptionType={data.exemptionType}
        />
      )}
    </React.Fragment>
  )
}

export default TaxExemptionDetails
