import React, {useState, useEffect} from 'react'
import {ArrowDown} from '../../assets/svgs'
import styles from '../InvoiceDetailPage/InvoiceDetailPage.module.scss'
import {useLocation, useNavigate} from 'react-router-dom'
import withAuthorization from '../Session/withAuthorization'
import NavBar from '../Navbar'
import Layout from '../Layout'
import {Loader} from '../Loader'
import DetailPage from '../DetailPage'
import {DetailType} from 'components/enums'

interface SalesTaxDetailPageProps {
  customClass: string
}

const SalesTaxDetailPage = ({customClass}: SalesTaxDetailPageProps) => {
  const [loader, setLoader] = useState(true)
  const navigate = useNavigate()
  const {state} = useLocation()
  const data = state?.data ? state?.data : null
  const previousPage = state?.currentPage

  useEffect(() => {
    setTimeout(() => {
      setLoader(false)
    }, 2000)
  }, [])

  return (
    <div>
      <div className={styles.withLayout}>
        <Layout />
        <div className={[styles.container, customClass].join(' ')}>
          <NavBar
            title={`Sales Tax Item - ${
              data?.generalInfo?.productTitle || 'Untitled'
            }`}
          />
          {loader ? (
            <Loader />
          ) : (
            <React.Fragment>
              <div className={styles.buttons}>
                <span
                  onClick={() =>
                    navigate('/sales-tax', {
                      state: {page: previousPage}
                    })
                  }
                >
                  <ArrowDown />
                  Back
                </span>
              </div>

              <div className={styles.edit_section}>
                <DetailPage data={data} detailType={DetailType.SalesTax} />
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  )
}

export default withAuthorization(SalesTaxDetailPage)
