import classNames from 'classnames'
import {Category} from 'common'
import {INITIAL_BUY_NOW_PRODUCT_STATE} from 'common/constants'
import {equipmentOptions} from 'common/enums'
import {useFirebase} from 'components/Firebase/hooks'
import RichTextEditor from 'components/RichTextEditor'
import {parseHtmlToPlainText, removeHtmlTags} from 'components/utils'
import {get, isNull} from 'lodash'
import React from 'react'
import {toast} from 'react-toastify'
import styles from '../ProductDetailPage.module.scss'
import {
  DROPDOWNONE_OPTIONS,
  DROPDOWNTWO_OPTIONS,
  NEWORUSEDEQUIPMENT_OPTIONS
} from '../constants'
import InputField from 'components/InputField'
import DatePickerComponent from 'components/DatePicker'
import DropDownSelect from 'components/DropDown'
import {Building} from 'assets/svgs'

interface ProductDetailSectionProps {
  values: typeof INITIAL_BUY_NOW_PRODUCT_STATE | any
  handleChange?: (e: any) => void | undefined
  handleBlur?: (e: any) => void | undefined
  touched?: {} | undefined
  errors?: {} | undefined
  disabled?: boolean
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<any>
}

const ProductDetailSection = ({
  values,
  handleChange,
  errors,
  touched,
  handleBlur,
  disabled,
  setFieldValue
}: ProductDetailSectionProps) => {
  const descriptionLength = removeHtmlTags(values?.description || '').trim()
    .length
  const descriptionEditorValue = parseHtmlToPlainText(values?.description || '')
  const minimumDate = new Date('1 Jan 1900')
  const maximumDate = new Date()
  const yearPickerValue = isNull(values.year)
    ? null
    : values.year || maximumDate.getFullYear()

  const yearUnknow = get(values, 'yearUnknown')
  const firstChoiceValue = get(values, 'productDetails.firstChoice')
  const secondChoiceValue = get(values, 'productDetails.secondChoice')

  const preventSpaceInput = (event: any) =>
    (event.code === 'Space' ||
      event.keyCode === 32 ||
      event.which === 32 ||
      event.key === ' ') &&
    event.preventDefault()

  const preventInput = (event: React.KeyboardEvent) =>
    ['e', 'E', '+', '-', '.'].includes(event.key) && event.preventDefault()

  const {getCategories} = useFirebase()
  const [categories, setCategories] = React.useState<Array<Category>>([])
  const selectedCategory = categories?.find(
    (option) => option.nodeId === values.equipmentId
  )
  const subcategoriesOfCategory = selectedCategory?.subcategories || []
  const subcategories = [
    {nodeId: 'None', name: 'None'},
    ...Object.values(subcategoriesOfCategory)
  ].map(({nodeId, name}) => ({nodeId, name}))

  React.useEffect(() => {
    const getCategoriesList = async () => {
      const {data, error} = await getCategories()
      if (error) {
        toast.warn(`${error}`)
      }
      setCategories([...(data || [])])
    }
    getCategoriesList()
  }, [])

  const categoryValue = categories.find(
    (option) => option.nodeId === values.equipmentId
  )?.name

  const subcategoryValue = subcategories.find(
    (option) => option.nodeId === values.subcategoryId
  )?.name

  return (
    <React.Fragment>
      <div className={styles.formHeading}>Equipment Details</div>

      <div className={styles.inputs}>
        <div className={styles.inputContainer}>
          <div className={styles.inputField}>
            <InputField
              name="title"
              placeholder="Listing Title"
              value={get(values, 'title')}
              onChange={handleChange}
              errors={errors}
              touched={touched}
              onBlur={handleBlur}
              type="text"
              disabled={disabled}
            />
          </div>
        </div>
        <div className={styles.inputContainer}>
          <div className={styles.inputField}>
            <div className={styles.dropdown}>
              <div>
                <DropDownSelect
                  firstIcon={<Building />}
                  options={categories}
                  displayValue={true}
                  customClass={classNames(
                    styles.dropdowninput,
                    styles.dropdownblock
                  )}
                  placeholder="Equipment Type"
                  value={categoryValue}
                  cancelClickFunction={() => {
                    setFieldValue('equipmentId', '')
                    setFieldValue('subcategoryId', 'None')
                  }}
                  onChange={(index) => {
                    const value = categories[index].nodeId
                    setFieldValue('equipmentId', value)
                    setFieldValue('subcategoryId', 'None')
                  }}
                  errors={errors}
                  touched={touched}
                  disabled={disabled}
                  onBlur={handleBlur}
                  name="equipmentId"
                />
                <div className={classNames(styles.disblock, styles.disnone)}>
                  ____
                </div>
              </div>
            </div>
          </div>
          <div className={styles.inputField}>
            <div className={styles.dropdown}>
              <div>
                <DropDownSelect
                  firstIcon={<Building />}
                  options={subcategories}
                  displayValue={true}
                  customClass={classNames(
                    styles.dropdowninput,
                    styles.dropdownblock
                  )}
                  placeholder="Equipment Sub Type"
                  value={subcategoryValue}
                  cancelClickFunction={() => {
                    setFieldValue('subcategoryId', 'None')
                  }}
                  onChange={(index) => {
                    const value = subcategories[index].nodeId
                    setFieldValue('subcategoryId', value)
                  }}
                  errors={errors}
                  touched={touched}
                  disabled={disabled}
                  onBlur={handleBlur}
                  name="subcategoryId"
                />
                <div className={classNames(styles.disblock, styles.disnone)}>
                  ____
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.inputContainer}>
          <div className={styles.inputField}>
            <InputField
              name="productManufacturer"
              placeholder="Equipment Manufacturer"
              value={get(values, 'productManufacturer')}
              onChange={handleChange}
              errors={errors}
              touched={touched}
              onBlur={handleBlur}
              type="text"
              disabled={disabled}
            />
          </div>
          <div className={styles.inputField}>
            <InputField
              name="model"
              placeholder="Model"
              value={get(values, 'model')}
              onChange={handleChange}
              errors={errors}
              touched={touched}
              onBlur={handleBlur}
              type="text"
              disabled={disabled}
            />
          </div>
        </div>

        <div className={styles.inputContainer}>
          <div className={styles.inputField}>
            <div className={styles.year_input}>
              <label
                style={yearUnknow ? {display: 'block'} : {display: 'none'}}
              >
                Unknown
              </label>
              <DatePickerComponent
                disabled={yearUnknow}
                selectedValue={yearPickerValue}
                setFieldValue={setFieldValue}
                minDate={minimumDate}
                maxDate={maximumDate}
                name="year"
                isYear
              />
              <div>
                <label>Unknown</label>
                <input
                  onChange={(e) =>
                    setFieldValue('yearUnknown', e.target.checked)
                  }
                  checked={yearUnknow}
                  onBlur={handleBlur}
                  type="checkbox"
                  name="yearUnknown"
                />
              </div>
            </div>
          </div>
          <div className={styles.inputField}>
            <div className={styles.dropdown}>
              <div>
                <DropDownSelect
                  firstIcon={<Building />}
                  options={NEWORUSEDEQUIPMENT_OPTIONS}
                  customClass={classNames(
                    styles.dropdowninput,
                    styles.dropdownblock
                  )}
                  placeholder="New Or Used Equipment"
                  value={NEWORUSEDEQUIPMENT_OPTIONS.findIndex(
                    (option) => option === get(values, 'newOrUsedEquipment')
                  )}
                  cancelClickFunction={() => {
                    setFieldValue('newOrUsedEquipment', '')
                  }}
                  onChange={(index) => {
                    const value = NEWORUSEDEQUIPMENT_OPTIONS[index]
                    setFieldValue('newOrUsedEquipment', value)
                  }}
                  errors={errors}
                  touched={touched}
                  disabled={disabled}
                  onBlur={handleBlur}
                  name="newOrUsedEquipment"
                />
                <div className={classNames(styles.disblock, styles.disnone)}>
                  ____
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.inputContainer}>
          <div className={styles.inputField}>
            <div className={styles.dropdown}>
              <div>
                <DropDownSelect
                  firstIcon={<Building />}
                  options={DROPDOWNONE_OPTIONS}
                  customClass={classNames(
                    styles.dropdowninput,
                    styles.dropdownblock
                  )}
                  placeholder={firstChoiceValue}
                  value={DROPDOWNONE_OPTIONS.findIndex(
                    (option) => option === firstChoiceValue
                  )}
                  cancelClickFunction={() => {
                    setFieldValue(
                      'productDetails.firstChoice',
                      'Equipment Hours or Mileage'
                    )
                  }}
                  onChange={(index) => {
                    const value = DROPDOWNONE_OPTIONS[index]
                    setFieldValue('productDetails.firstChoice', value)
                    if (firstChoiceValue !== value) {
                      if (
                        value === equipmentOptions.EquipmentMileage ||
                        value === equipmentOptions.EquipmentHours
                      ) {
                        setFieldValue(
                          value === equipmentOptions.EquipmentMileage
                            ? 'equipmentMileage'
                            : 'equipmentHours',
                          ''
                        )
                      }
                    }
                  }}
                  errors={errors}
                  touched={touched}
                  disabled={disabled}
                  onBlur={handleBlur}
                  name="productDetails.firstChoice"
                />
                <div className={classNames(styles.disblock, styles.disnone)}>
                  ____
                </div>
              </div>
            </div>
          </div>
          <div className={styles.inputField}>
            <div className={styles.dropdown}>
              <div>
                <DropDownSelect
                  firstIcon={<Building />}
                  options={DROPDOWNTWO_OPTIONS}
                  customClass={classNames(
                    styles.dropdowninput,
                    styles.dropdownblock
                  )}
                  placeholder={secondChoiceValue}
                  value={DROPDOWNTWO_OPTIONS.findIndex(
                    (option) => option === secondChoiceValue
                  )}
                  cancelClickFunction={() => {
                    setFieldValue(
                      'productDetails.secondChoice',
                      'Serial Number or VIN'
                    )
                  }}
                  onChange={(index) => {
                    const value = DROPDOWNTWO_OPTIONS[index]
                    if (secondChoiceValue !== value) {
                      setFieldValue('productDetails.secondChoice', value)
                      if (value === equipmentOptions.SerialNumber) {
                        setFieldValue('serialNumber', '')
                        return
                      }
                      if (value === equipmentOptions.EquipmentVINNumber) {
                        setFieldValue('equipmentVinNumber', '')
                        return
                      }
                    }
                  }}
                  errors={errors}
                  touched={touched}
                  disabled={disabled}
                  onBlur={handleBlur}
                  name="productDetails.secondChoice"
                />
                <div className={classNames(styles.disblock, styles.disnone)}>
                  ____
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.inputContainer}>
          <div className={styles.inputField}>
            {firstChoiceValue === equipmentOptions.EquipmentMileage ? (
              <InputField
                name="equipmentMileage"
                placeholder="Equipment Mileage"
                value={get(values, 'equipmentMileage')}
                onChange={handleChange}
                errors={errors}
                touched={touched}
                onBlur={handleBlur}
                type="number"
                disabled={disabled}
                onKeyDown={preventInput}
                min={0}
                max={Number.MAX_SAFE_INTEGER}
              />
            ) : firstChoiceValue === equipmentOptions.EquipmentHours ? (
              <InputField
                name="equipmentHours"
                placeholder="Equipment Hours"
                value={get(values, 'equipmentHours')}
                onChange={handleChange}
                errors={errors}
                touched={touched}
                onBlur={handleBlur}
                type="number"
                disabled={disabled}
                onKeyDown={preventInput}
                min={0}
                max={Number.MAX_SAFE_INTEGER}
              />
            ) : null}
          </div>
          <div className={styles.inputField}>
            {secondChoiceValue === equipmentOptions.SerialNumber ? (
              <InputField
                name="serialNumber"
                placeholder="Serial Number"
                value={get(values, 'serialNumber')?.replace(/\s/g, '')}
                onChange={handleChange}
                errors={errors}
                touched={touched}
                onBlur={handleBlur}
                type="text"
                onKeyDown={preventSpaceInput}
                disabled={disabled}
              />
            ) : secondChoiceValue === equipmentOptions.EquipmentVINNumber ? (
              <InputField
                name="equipmentVinNumber"
                placeholder="Equipment VIN Number"
                value={get(values, 'equipmentVinNumber')?.replace(/\s/g, '')}
                onChange={handleChange}
                errors={errors}
                touched={touched}
                onBlur={handleBlur}
                type="text"
                onKeyDown={preventSpaceInput}
                disabled={disabled}
              />
            ) : null}
          </div>
        </div>
        <div className={styles.inputContainer}>
          <div style={{width: '100%'}} className={styles.inputField}>
            <RichTextEditor
              name="description"
              touched={touched}
              errors={errors}
              value={descriptionEditorValue}
              onContentChange={(value) => setFieldValue('description', value)}
            />
            <p className={styles.textCount}>
              {descriptionLength}
              /2500 remaining characters
            </p>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ProductDetailSection
