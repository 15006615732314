import {NoPreview, Truck} from 'assets/images'
import {Cancel_upload as CancelUpload} from 'assets/svgs'
import classNames from 'classnames'
import {FILE_OBJECT} from 'common'
import {bytesToSize, useWindowSize} from 'components/utils'
import {isNumber} from 'lodash'
import React from 'react'
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable
} from 'react-beautiful-dnd'
import styles from './UploadFileSection.module.scss'

interface Props {
  filesArr: Array<FILE_OBJECT>
  renderPlaceholder: () => JSX.Element | Array<JSX.Element> | null
  setFilesArr: React.Dispatch<React.SetStateAction<Array<FILE_OBJECT>>>
  handleRemove: (file: FILE_OBJECT) => Promise<void>
  setFiles: (files: Array<FILE_OBJECT>) => void
  isCategoryUpload: boolean | undefined
}

const FilesDnD: React.FC<Props> = ({
  filesArr,
  setFiles,
  setFilesArr,
  handleRemove,
  renderPlaceholder,
  isCategoryUpload
}) => {
  const {width = 1024} = useWindowSize()

  const getFilesArrBatch = (arr: Array<FILE_OBJECT>) => {
    const batchSize = width <= 520 ? 3 : width <= 720 ? 4 : 6
    const firstImageIndex = arr.findIndex((item) =>
      item?.type.includes('image')
    )

    const batches = []
    for (let i = 0; i < arr.length; i += batchSize) {
      const batch = arr.slice(i, i + batchSize).filter(Boolean)
      batch.forEach((item) => {
        item.isCover = false
      })
      if (firstImageIndex !== -1 && batch.includes(arr[firstImageIndex])) {
        batch[firstImageIndex].isCover = true
      }
      batches.push(batch)
    }
    return batches
  }

  const filesArrBatches = getFilesArrBatch(filesArr)

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return
    }
    const sourceBatchIndex = parseInt(result.source.droppableId.split('-')[1])
    const destBatchIndex = parseInt(
      result.destination.droppableId.split('-')[1]
    )
    if (sourceBatchIndex === destBatchIndex) {
      const batch = filesArrBatches[sourceBatchIndex]
      const [removed] = batch.splice(result.source.index, 1)
      batch.splice(result.destination.index, 0, removed)
    } else {
      const sourceBatch = filesArrBatches[sourceBatchIndex]
      const destBatch = filesArrBatches[destBatchIndex]
      const [removed] = sourceBatch.splice(result.source.index, 1)
      destBatch.splice(result.destination.index, 0, removed)
    }
    const reorderedImages = filesArrBatches.flat()
    setFilesArr(reorderedImages)
    setFiles(reorderedImages)
  }

  const renderDraggable = (file: FILE_OBJECT, index: number) => {
    const fileId = file?.URL?.split('/')[5]
    const mbSize = file?.mbSize
    const fileName = isCategoryUpload
      ? 'category-image'
      : file?.uploadType !== 'embed'
      ? file?.originalName || file?.name
      : file?.name

    const fileSize =
      file?.uploadType === 'google-drive'
        ? mbSize
          ? bytesToSize(mbSize)
          : ''
        : isNumber(mbSize)
        ? `${Number(file?.mbSize)?.toFixed(2)}MB`
        : ''

    return (
      <React.Fragment>
        <div
          className={classNames(
            styles.img_container,
            (file?.isCover && styles.img_container_first_child) || ''
          )}
        >
          {file?.isCover && (
            <div className={styles.cover_container}>Cover Image</div>
          )}
          {file?.uploadType === 'embed' ? (
            <iframe
              title="Embed Video"
              key={`vide-player-${index}`}
              src={file?.URL}
              width="170"
              height="170"
            ></iframe>
          ) : file?.uploadType === 'google-drive' ? (
            <React.Fragment>
              {!file?.hasThumbnail ? (
                <img
                  src={NoPreview}
                  width={140}
                  height={140}
                  alt="category-img"
                />
              ) : (
                <iframe
                  key={`vide-player-${index}`}
                  title="product-video"
                  src={`https://drive.google.com/file/d/${fileId}/preview`}
                  width="170"
                  height="170"
                ></iframe>
              )}
            </React.Fragment>
          ) : file.type.includes('image') ? (
            <img
              src={file?.downloadURL || Truck}
              alt="product-img"
              width={140}
              height={140}
            />
          ) : (
            <video src={file?.downloadURL} controls={true} />
          )}
          <div
            className={styles.cancel}
            onClick={async () => await handleRemove(file)}
          >
            <CancelUpload className={styles.cancel_icon} />
          </div>
        </div>
        <h5>{fileName}</h5>
        <p>{fileSize}</p>
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <DragDropContext onDragEnd={handleDragEnd}>
        {filesArrBatches.map((batch, batchIndex) => (
          <Droppable
            key={`droppable-${batchIndex}`}
            droppableId={`droppable-${batchIndex}`}
            direction="horizontal"
          >
            {(provided) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                style={{
                  marginBottom: '20px',
                  display: 'flex',
                  overflowX: 'visible'
                }}
              >
                {batch.map((file: FILE_OBJECT, index: number) => (
                  <Draggable
                    key={`draggable-${file.name}-${index}`}
                    draggableId={`draggable-${file.name}-${index}`}
                    index={index}
                  >
                    {(innerProvided) => (
                      <div
                        className={styles.card}
                        ref={innerProvided.innerRef}
                        {...innerProvided.draggableProps}
                        {...innerProvided.dragHandleProps}
                      >
                        {renderDraggable(file, index)}
                      </div>
                    )}
                  </Draggable>
                ))}
              </div>
            )}
          </Droppable>
        ))}
      </DragDropContext>
      {renderPlaceholder()}
    </React.Fragment>
  )
}

export default FilesDnD
