import React, {useState, useEffect, useRef} from 'react'
import {ArrowDown, Building} from '../../assets/svgs'
import styles from './RoleManagementAccess.module.scss'
import Button from '../Button'
import {useLocation, useNavigate} from 'react-router-dom'
import {Formik, FormikProps} from 'formik'
import {get, isEqual} from 'lodash'
import InputField from '../InputField'
import withAuthorization from '../Session/withAuthorization'
import NavBar from '../Navbar'
import Layout from '../Layout'
import classNames from 'classnames'
import {useFirebase, useSession} from '../Firebase/hooks'
import {toast} from 'react-toastify'
import {ROLEMANAGEMENT_OBJECT, adminRoleConst} from 'common/constants'
import DropDownSelect from '../DropDown'
import PhoneInputSelect from '../PhoneInput'
import {Loader} from '../Loader'
import DetailPage from '../DetailPage'
import {getFormattedAdmin, trimStringFields} from '../utils'
import {CreateAdminSchema} from './schema'
import {Collection, adminRole} from 'common/enums'
import ConfirmationModal from 'components/ConfirmationModal'
import ModalComponent from 'components/ModalComponent'
import {generateRandomPhotoURL} from 'common/utils'
import {DetailType} from 'components/enums'
import {UserInformation} from 'components/types'

interface RoleManagementAccessProps {
  customClass: string
}

const RoleManagementAccess = ({customClass}: RoleManagementAccessProps) => {
  const navigate = useNavigate()
  const [show, setShow] = useState<boolean>(false)
  const toggleShow = () => setShow(!show)
  const {user} = useSession()
  const userId = user?.uid || ''
  const {state} = useLocation()
  const roleOptions = [adminRoleConst.superAdmin, adminRoleConst.superManager]
  const {
    getDocumentData,
    deleteAdmin,
    editAdmin,
    createAdmin,
    grantDenyAdminAccess
  } = useFirebase()
  const [formData, setFormData] = useState({})
  const formRef: React.Ref<FormikProps<UserInformation>> | undefined =
    useRef(null)
  const [active, setActive] = useState(true)
  const adminID = state?.id ? state?.id : ''
  const isEdit = state?.isEdit ? state?.isEdit : false
  const [loader, setLoader] = useState(isEdit ? true : false)
  const previousPage = state?.currentPage
  const isMSA = state?.isMSA ? state?.isMSA : false
  const isActive: boolean = state?.isActive || false
  const [appState, setAppState] = useState<any>({
    documentData: isEdit ? {} : {...ROLEMANAGEMENT_OBJECT}
  })
  const sameUser = adminID === userId
  const [disabled, setDisabled] = useState(isEdit ? true : false)

  const cancelCallback = (error: Error) => {
    if (error) {
      console.error(error)
      toast.error(`Something went wrong!`)
    }
  }

  useEffect(() => {
    isEdit &&
      setTimeout(() => {
        setLoader(false)
      }, 2000)
  }, [])

  useEffect(() => {
    if (!disabled || !isEdit) return
    const {unsSubData} = getDocumentData(
      setAppState,
      cancelCallback,
      adminID,
      Collection.Admins
    )

    return () => {
      unsSubData?.()
    }
  }, [disabled])

  useEffect(() => {
    const {documentData} = appState
    if (documentData && !disabled) {
      const adminObject = getFormattedAdmin(documentData)
      const {isVerified, isActive, accountsList, ...restValues} = adminObject
      setFormData({...restValues})
      formRef?.current?.setValues({...restValues})
    }
  }, [appState, formRef, disabled])

  const isAdminDetails = isEdit && disabled

  const handleDeletion = async () => {
    toggleShow()
    setLoader(true)
    const {message, error} = await deleteAdmin(adminID)
    setLoader(false)
    if (error) {
      toast.warning(error)
      return
    } else {
      toast.success(message)
      navigate('/role-management', {
        state: {page: previousPage}
      })
      return
    }
  }

  const handleAccess = async () => {
    const isDenyingAccess = isActive || false
    if (sameUser) {
      if (isDenyingAccess) {
        toast.error('You cannot restrict your access!')
        return
      } else {
        toast.error('You cannot grant yourself access!')
        return
      }
    } else {
      setLoader(true)
      const {message, error} = isDenyingAccess
        ? await grantDenyAdminAccess(adminID, false)
        : await grantDenyAdminAccess(adminID, true)
      setLoader(false)
      if (error) {
        toast.warning(error)
        return
      } else {
        toast.success(message)
        navigate('/role-management', {
          state: {page: previousPage}
        })
        return
      }
    }
  }

  return (
    <div>
      <div className={styles.withLayout}>
        <Layout />
        <div className={[styles.container, customClass].join(' ')}>
          <NavBar title="Role Management" />
          {loader ? (
            <Loader />
          ) : (
            <React.Fragment>
              {show && (
                <ModalComponent handleClose={toggleShow} show={show}>
                  <ConfirmationModal
                    removeIcon
                    handleClose={toggleShow}
                    text={`Delete Admin`}
                    content={
                      'You cannot undo these changes. Are you sure you want to delete this admin?'
                    }
                    handleDeleteButton={handleDeletion}
                    isDelete={true}
                    documentId={adminID}
                  />
                </ModalComponent>
              )}
              <div className={styles.buttons}>
                <span
                  onClick={() =>
                    navigate('/role-management', {
                      state: {page: previousPage}
                    })
                  }
                >
                  <ArrowDown />
                  Back
                </span>
                <div className={styles.twobtn}>
                  {isEdit && (
                    <Button
                      text={isActive ? 'Deny Access' : 'Grant Access'}
                      customClass={styles.changes_btn}
                      onClick={handleAccess}
                    />
                  )}
                  {!isMSA && disabled && (
                    <Button
                      text="Edit"
                      customClass={styles.changes_btn}
                      onClick={() => setDisabled(false)}
                    />
                  )}
                  {(!isEdit || !disabled) && (
                    <Button
                      text={'Save'}
                      customClass={styles.changes_btn}
                      onClick={() => formRef.current?.handleSubmit()}
                      disabled={loader}
                    />
                  )}
                  {isEdit && (
                    <Button
                      text={'Delete'}
                      customClass={styles.changes_btn}
                      onClick={() => {
                        if (sameUser) {
                          toast.error('You cannot delete yourself.')
                          return
                        } else {
                          toggleShow()
                        }
                      }}
                    />
                  )}
                </div>
              </div>

              <div className={styles.edit_section}>
                {!isAdminDetails && (
                  <h1 className={styles.secondheading}>Admin Details</h1>
                )}
                {isAdminDetails ? (
                  <DetailPage
                    data={getFormattedAdmin(appState.documentData)}
                    detailType={DetailType.Admin}
                  />
                ) : (
                  <Formik
                    innerRef={formRef}
                    initialValues={appState.documentData}
                    onSubmit={async (values: UserInformation) => {
                      const {
                        role,
                        emailAddress,
                        firstName,
                        lastName,
                        mobileNumber
                      } = values
                      const roleValue =
                        role === adminRoleConst.superAdmin
                          ? adminRole.superAdmin
                          : role === adminRoleConst.superManager
                          ? adminRole.superManager
                          : role
                      const photoURL = values.photoURL
                        ? values.photoURL
                        : generateRandomPhotoURL(
                            `${values.firstName}+${values.lastName}`
                          )
                      const formValues = trimStringFields({
                        emailAddress,
                        firstName,
                        lastName,
                        mobileNumber,
                        role: roleValue,
                        photoURL
                      })
                      const isObjectSame = isEqual(formValues, formData)
                      if (isEdit && isObjectSame) {
                        toast.warning('Please make some changes')
                        return
                      }
                      setLoader(true)
                      const {message, error} = isEdit
                        ? await editAdmin(formValues, adminID)
                        : await createAdmin(formValues)
                      setLoader(false)
                      if (error) {
                        toast.warning(error)
                      } else {
                        toast.success(message)
                        navigate('/role-management', {
                          state: {page: previousPage}
                        })
                      }
                    }}
                    validationSchema={CreateAdminSchema}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      setFieldValue
                    }) => (
                      <form
                        className={styles.from_submiting}
                        onSubmit={handleSubmit}
                      >
                        <div className={styles.formContainer}>
                          <div className={styles.inputs}>
                            <div className={styles.inputContainer}>
                              <div
                                className={styles.inputField}
                                title="Type names like John, Larry, Kane"
                              >
                                <InputField
                                  name="firstName"
                                  placeholder="First Name"
                                  value={get(values, 'firstName')}
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  onBlur={handleBlur}
                                  type="text"
                                  disabled={disabled}
                                />
                              </div>
                              <div
                                className={styles.inputField}
                                title="Type names like Doe, Dane, Jane"
                              >
                                <InputField
                                  placeholder="Last Name"
                                  name="lastName"
                                  value={get(values, 'lastName')}
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  onBlur={handleBlur}
                                  type="text"
                                  disabled={disabled}
                                />
                              </div>
                            </div>
                            <div className={styles.inputContainer}>
                              <div className={styles.inputField}>
                                <PhoneInputSelect
                                  placeholder="Mobile Number"
                                  name="mobileNumber"
                                  value={get(values, 'mobileNumber')}
                                  setFieldValue={setFieldValue}
                                  errors={errors}
                                  touched={touched}
                                  onBlur={handleBlur}
                                  disabled={disabled}
                                  type="text"
                                  id="mobileNumber"
                                />
                              </div>
                              <div className={styles.inputField}>
                                <InputField
                                  placeholder="Email Address"
                                  name="emailAddress"
                                  value={get(values, 'emailAddress')}
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  onBlur={handleBlur}
                                  type="text"
                                  disabled={disabled}
                                />
                              </div>
                            </div>
                            <div className={styles.inputContainer}>
                              <div className={styles.inputField}>
                                <div className={styles.dropdown}>
                                  <div>
                                    <DropDownSelect
                                      firstIcon={<Building />}
                                      options={roleOptions}
                                      customClass={classNames(
                                        styles.dropdowninput,
                                        active ? styles.dropdownblock : ''
                                      )}
                                      placeholder="Role"
                                      value={roleOptions.findIndex(
                                        (option) =>
                                          option ===
                                          (get(values, 'role') ===
                                          adminRole.superManager
                                            ? adminRoleConst.superManager
                                            : get(values, 'role') ===
                                              adminRole.superAdmin
                                            ? adminRoleConst.superAdmin
                                            : get(values, 'role'))
                                      )}
                                      cancelClickFunction={() => {
                                        setFieldValue('role', '')
                                      }}
                                      onChange={(index) => {
                                        const currentOption = roleOptions[index]
                                        setFieldValue('role', currentOption)
                                      }}
                                      errors={errors}
                                      touched={touched}
                                      disabled={disabled}
                                      onBlur={handleBlur}
                                      name="role"
                                    />
                                    <div
                                      onClick={() => setActive(!active)}
                                      className={classNames(
                                        styles.disblock,
                                        `${active ? styles.disnone : ''}`
                                      )}
                                    >
                                      ____
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className={styles.inputField}></div>
                            </div>
                          </div>
                        </div>
                      </form>
                    )}
                  </Formik>
                )}
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  )
}

export default withAuthorization(RoleManagementAccess)
