import React, {useEffect, useMemo, useState} from 'react'
import Button from '../Button'
import Table from '../TableComponent'
import styles from '../Categories/Categories.module.scss'
import {useAppContext, useFirebase} from '../Firebase/hooks'
import withAuthorization from '../Session/withAuthorization'
import Layout from '../Layout'
import NavBar from '../Navbar'
import {useNavigate, useLocation} from 'react-router-dom'
import {Loader} from '../Loader'
import {Sector} from 'common'
import {TABLE_PAGINATION_PAGE_SIZE} from 'components/constants'
import Pagination from 'components/Pagination'
import {toast} from 'react-toastify'
import {useSortableData} from 'components/utils'

const Sectors = () => {
  const {state} = useLocation()
  const [sectors, setSectors] = useState<Array<Sector>>([])
  const pageFromLocation = state?.page
  const [currentPage, setCurrentPage] = useState<number>(pageFromLocation || 1)
  const [loader, setLoader] = useState(true)
  const {getSectorsList} = useFirebase()
  const navigate = useNavigate()
  const {
    appState: {isSuperManager}
  } = useAppContext()

  const cancelCallback = (error: Error) => {
    if (error) {
      toast.error(`Something went wrong while fetching sectors.`)
    }
  }

  useEffect(() => {
    const unSubscribe = getSectorsList<Sector>({
      setSectors,
      cancelCallback
    })
    setTimeout(() => {
      setLoader(false)
    }, 2000)
    return () => {
      unSubscribe?.()
    }
  }, [])

  const getRowData = (rowData: Array<Sector>) => {
    return (rowData || []).map((singleRowData, index: number) => {
      const {name, nodeId} = singleRowData
      return (
        <tr
          key={`sector-row-data-${index}`}
          style={isSuperManager ? {cursor: 'unset'} : undefined}
          onClick={() => {
            if (isSuperManager) return
            navigate('/sectors/single-sector-details', {
              state: {
                id: nodeId,
                data: singleRowData,
                isEdit: true,
                currentPage
              },
              preventScrollReset: true
            })
          }}
        >
          <td>{nodeId}</td>
          <td>{name}</td>
        </tr>
      )
    })
  }

  const scrollRef: React.RefObject<HTMLDivElement> = React.createRef()

  const scrollToRef = () =>
    scrollRef?.current?.scrollIntoView({behavior: 'smooth'})

  const {items, sortBy} = useSortableData(sectors)

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * TABLE_PAGINATION_PAGE_SIZE
    const lastPageIndex = firstPageIndex + TABLE_PAGINATION_PAGE_SIZE
    return items.slice(firstPageIndex, lastPageIndex)
  }, [currentPage, items])

  const showPagination = sectors?.length > TABLE_PAGINATION_PAGE_SIZE

  const onPageChange = (page: number) => {
    setCurrentPage(page)
    scrollToRef()
  }

  const tableTitles = [
    {title: 'Sector Id', sortBy: () => sortBy('nodeId')},
    {title: 'Sector', sortBy: () => sortBy('name')}
  ]

  return (
    <div className={styles.withLayout}>
      <Layout />
      <div className={styles.container}>
        <NavBar title="Sectors" />
        <div className={styles.button_filters}>
          {!isSuperManager && (
            <Button
              text="Add Sector"
              customClass={styles.create_account_btn}
              onClick={() => navigate('/sectors/single-sector-details')}
            />
          )}
        </div>
        {loader ? (
          <Loader />
        ) : (
          <React.Fragment>
            <Table
              titles={tableTitles}
              customClass={styles.threedetails}
              rowData={getRowData(currentTableData)}
            />
            {showPagination && (
              <div className="pagination__wrapper">
                <Pagination
                  currentPage={currentPage}
                  onPageChange={onPageChange}
                  totalCount={sectors?.length}
                  pageSize={TABLE_PAGINATION_PAGE_SIZE}
                />
              </div>
            )}
          </React.Fragment>
        )}
      </div>
    </div>
  )
}

export default withAuthorization(Sectors)
