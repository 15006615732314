import * as Yup from 'yup'

const domesticWireInfoSchema = Yup.object().shape({
  accountName: Yup.string()
    .required('Account Name is required')
    .min(3, 'Account Name must be at least 3 characters')
    .max(200, 'Account Name must not exceed 200 characters'),
  accountNumber: Yup.string()
    .required('Account Number is required')
    .max(17, 'Domestic Account Number must not exceed 17 characters')
    .matches(/^[0-9]+$/, 'Account Number must contain only numeric characters'),
  bank: Yup.string()
    .required('Bank is required')
    .max(200, 'Bank name must not exceed 200 characters'),
  routingNumber: Yup.string()
    .required('Routing Number is required')
    .matches(
      /^[0-3]\d{8,11}$/,
      'Routing Number must start with 0, 1, 2, or 3 and must be 9-12 characters long'
    )
})

const internationalWireInfoSchema = Yup.object().shape({
  accountName: Yup.string()
    .required('Account Name is required')
    .min(3, 'Account Name must be at least 3 characters')
    .max(200, 'Account Name must not exceed 200 characters'),
  accountNumber: Yup.string()
    .required('Account Number is required')
    .max(34, 'International Account Number must not exceed 34 characters')
    .matches(/^[0-9]+$/, 'Account Number must contain only numeric characters'),
  bank: Yup.string()
    .required('Bank is required')
    .max(200, 'Bank name must not exceed 200 characters'),
  routingNumber: Yup.string().notRequired(),
  swiftCode: Yup.string()
    .required('Swift Code is required')
    .min(8, 'Swift Code must be at least 8 characters')
    .max(11, 'Swift Code must not exceed 11 characters')
})

export const validationSchema = Yup.object().shape({
  domesticWireInfo: domesticWireInfoSchema,
  internationalWireInfo: internationalWireInfoSchema
})
