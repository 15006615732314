import React from 'react'
import styles from '../DetailPage.module.scss'
import {DealDocument} from 'common'
import {getFormattedDate, withThousandSeperatorString} from 'components/utils'
import {isDate} from 'lodash'
import {FieldSet} from '../DetailFields'

const DealsDetails = (data: DealDocument) => {
  const dealCreatedAt = data?.createdAt
    ? new Date(data?.createdAt.toDate())
    : ''
  const dealLastUpdated = data?.lastUpdated
    ? new Date(data?.lastUpdated.toDate())
    : ''
  const formattedDealCreatedAtDate = isDate(dealCreatedAt)
    ? getFormattedDate(dealCreatedAt)
    : ''
  const formattedDealLastUpdatedDate = isDate(dealLastUpdated)
    ? getFormattedDate(dealLastUpdated)
    : ''

  const overviewFields = [
    {
      label: 'Deal Name',
      value: data?.dealName || '-'
    },
    {
      label: 'Deal ID',
      value: data?.dealId || '-'
    },
    {
      label: 'Chat ID',
      value: data?.chatId || '-'
    },
    {
      label: 'Stage',
      value: data?.stage
        ? data?.stage.charAt(0).toUpperCase() + data?.stage.slice(1)
        : '-'
    },
    {
      label: 'Listing Price',
      value: withThousandSeperatorString(`${data?.listingPrice}`) || '-'
    }
  ]

  const buyerDetailsFields = [
    {
      label: 'Account ID',
      value: data?.buyerAccountId || '-'
    },
    {
      label: 'Account Name',
      value: data?.buyerAccountName || '-'
    },
    {
      label: 'Contact ID',
      value: data?.buyerContactId || '-'
    },
    {
      label: 'Contact Name',
      value: data?.buyerContactName || '-'
    }
  ]

  const sellerDetailsFields = [
    {
      label: 'Account ID',
      value: data?.sellerAccountId || '-'
    },
    {
      label: 'Account Name',
      value: data?.sellerAccountName || '-'
    },
    {
      label: 'Contact ID',
      value: data?.sellerContactId || '-'
    },
    {
      label: 'Contact Name',
      value: data?.sellerContactName || '-'
    }
  ]

  const locationFields = [
    {
      label: 'Equipment City',
      value: data?.location?.equipmentCity || '-'
    },
    {
      label: 'Equipment Country',
      value: data?.location?.equipmentCountry || '-'
    },
    {
      label: 'Equipment State',
      value: data?.location?.equipmentState || '-'
    },
    {
      label: 'Equipment Street',
      value: data?.location?.equipmentStreet || '-'
    },
    {
      label: 'Equipment Zip',
      value: data?.location?.equipmentZip || '-'
    }
  ]

  const miscFields = [
    {
      label: 'Created At',
      value: formattedDealCreatedAtDate || '-'
    },
    {
      label: 'Last Updated',
      value: formattedDealLastUpdatedDate || '-'
    },
    {
      label: 'Is Synced',
      value: data?.isSynced ? 'Yes' : 'No'
    },
    {
      label: 'Is Archived',
      value: data?.isArchived ? 'Yes' : 'No'
    },
    {
      label: 'Equipment ID',
      value: data?.productId || '-'
    }
  ]

  return (
    <React.Fragment>
      <div className={styles.inputsfields}>
        <FieldSet title="Overview" fields={overviewFields} />
        <FieldSet title="Buyer Details" fields={buyerDetailsFields} gap />
        <FieldSet title="Seller Details" fields={sellerDetailsFields} gap />
        <FieldSet title="Location" fields={locationFields} gap />
      </div>
      <div style={{marginTop: '1.5rem'}} className={styles.inputsfields}>
        <FieldSet fields={miscFields} gap />
      </div>
    </React.Fragment>
  )
}

export default DealsDetails
