import React, {useState} from 'react'
import styles from './GenerateSalesTaxModal.module.scss'
import {toast} from 'react-toastify'
import DropDownSelect from 'components/DropDown'
import {Loader} from 'components/Loader'
import {Cancel} from 'assets/svgs'
import Button from 'components/Button'
import {useFirebase} from 'components/Firebase/hooks'
import {InvoiceDocument} from 'common'
import {Collection} from 'common/enums'
import {generateSalesTax} from 'components/apis/taxjar'
import {isString} from 'lodash'

interface GenerateSalesTaxModalProps {
  handleClose: () => void
}

const GenerateSalesTaxModal = ({handleClose}: GenerateSalesTaxModalProps) => {
  const {getCollectionBasedOnQuery} = useFirebase()
  const [loader, setLoader] = useState<boolean>(false)
  const [disabled, setDisabled] = useState(false)
  const [selectedInvoice, setSelectedInvoice] = useState<{
    id: string
    name: string
  }>()
  const [invoicesData, setInvoicesData] = useState<Array<InvoiceDocument>>([])
  const optionsList = invoicesData.map((acc) => ({
    id: acc.invoiceId || '',
    name: acc.productTitle || ''
  }))

  const getInvoiceData = async () => {
    loader === false && setLoader(true)
    const {data, error} = await getCollectionBasedOnQuery<InvoiceDocument>(
      Collection.Invoices,
      'salesTaxGenerated',
      false
    )
    if (error) {
      toast.warn('Something went wrong while fetching invoices.')
      setLoader(false)
      return
    }
    const invoicesArray = data?.length ? data : []
    setInvoicesData([...invoicesArray])
    setLoader(false)
  }

  React.useEffect(() => {
    getInvoiceData()
  }, [])

  const handleSubmit = async () => {
    if (!selectedInvoice) {
      toast.warn('Please select an invoice.')
      return
    }
    setDisabled(true)
    const {
      data: {msg, code}
    } = await generateSalesTax({
      invoiceId: selectedInvoice?.id
    })

    if (code !== 201) {
      toast.warn(msg || 'Failed to generate sales tax against this invoice.')
      setDisabled(false)
    }
    if (code === 201) {
      toast.success(msg)
      const updatedData = invoicesData.filter(
        (invoice) => invoice.invoiceId !== selectedInvoice.id
      )
      setInvoicesData(updatedData)
      handleClose()
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.cancelContainer}>
        <Cancel className={styles.cancel} onClick={handleClose} />
      </div>
      <div className={styles.content}>
        {loader ? (
          <Loader fullHeight={false} />
        ) : (
          <div className={styles.modalContent}>
            <div className={styles.headingContainer}>Select an Invoice</div>
            <div className={styles.dropdownContainer}>
              <DropDownSelect
                options={optionsList}
                customClass={styles.dropdowninput}
                placeholder="Select Invoice"
                value={optionsList.findIndex(
                  (option) => option.id === selectedInvoice?.id
                )}
                cancelClickFunction={() => {
                  setSelectedInvoice(undefined)
                }}
                onChange={(index) => {
                  const currentOption = optionsList[index]
                  setSelectedInvoice(currentOption)
                }}
                name="accountSelect"
              />
            </div>
            <div className={styles.buttonsComponent}>
              <Button
                customClass={styles.buttonClass}
                text="Submit"
                disabled={disabled}
                onClick={handleSubmit}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default GenerateSalesTaxModal
