import {ArrowDown} from 'assets/svgs'
import {AssetReportDocument} from 'common'
import DetailPage from 'components/DetailPage'
import Layout from 'components/Layout'
import {Loader} from 'components/Loader'
import Navbar from 'components/Navbar'
import withAuthorization from 'components/Session/withAuthorization'
import {DetailType} from 'components/enums'
import React, {useEffect, useState} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import styles from '../InvoiceDetailPage/InvoiceDetailPage.module.scss'

const EquipmentReportDetailPage = () => {
  const [loader, setLoader] = useState(true)
  const navigate = useNavigate()
  const {state} = useLocation()
  const data: AssetReportDocument = state?.data ? state?.data : null
  const previousPage = state?.currentPage

  useEffect(() => {
    setTimeout(() => {
      setLoader(false)
    }, 2000)
  }, [])

  return (
    <div>
      <div className={styles.withLayout}>
        <Layout />
        <div className={styles.container}>
          <Navbar title={`Equipment Reports - ${data?.contactName || ''}`} />
          {loader ? (
            <Loader />
          ) : (
            <React.Fragment>
              <div className={styles.buttons}>
                <span
                  onClick={() =>
                    navigate('/reports', {
                      state: {page: previousPage}
                    })
                  }
                >
                  <ArrowDown />
                  Back
                </span>
              </div>
              <DetailPage
                data={data}
                detailType={DetailType.EquipmentReports}
              />
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  )
}

export default withAuthorization(EquipmentReportDetailPage)
