import React, {useState} from 'react'
import {EditorState, convertToRaw} from 'draft-js'
import {Editor} from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import styles from './RichTextEditor.module.scss'
import draftToHtml from 'draftjs-to-html'
import {get} from 'lodash'

interface RichTextEditorProps {
  onContentChange?: (content: any) => void
  maxCharacters?: number
  value?: EditorState
  readOnly?: boolean
  touched?: {} | undefined
  errors?: {} | undefined
  name: string
}

const RichTextEditor: React.FC<RichTextEditorProps> = ({
  onContentChange,
  maxCharacters = 2500,
  value,
  touched,
  errors,
  name,
  readOnly
}) => {
  const [editorState, setEditorState] = useState(value)
  const length = readOnly
    ? value
    : !value?.getCurrentContent().getPlainText().length
  const hasError = get(touched, name) || ''
  const textAreaError = hasError && get(errors, name)

  React.useEffect(() => {
    if (readOnly) setEditorState(value)
  }, [length])

  const handleEditorChange = (newEditorState: EditorState) => {
    const currentContent = newEditorState.getCurrentContent()
    const currentText = currentContent.getPlainText()
    if (currentText.length >= maxCharacters) return
    setEditorState(newEditorState)
    const content = convertToRaw(newEditorState.getCurrentContent())
    onContentChange!(draftToHtml(content))
  }

  const editorProps = readOnly
    ? {
        toolbar: {},
        wrapperClassName: styles['wrapper-class--read-only'],
        editorClassName: styles['editor-class--read-only'],
        toolbarClassName: styles['toolbar-class--read-only']
      }
    : {
        wrapperClassName: styles['wrapper-class'],
        editorClassName: styles['editor-class'],
        toolbarClassName: styles['toolbar-class'],
        onEditorStateChange: handleEditorChange,
        toolbar: {
          options: ['inline', 'blockType', 'fontSize', 'list', 'emoji'],
          inline: {
            options: ['bold', 'italic', 'underline', 'strikethrough']
          }
        }
      }

  return (
    <React.Fragment>
      <Editor editorState={editorState} readOnly={readOnly} {...editorProps} />
      <div className={styles.descriptionErrorContainer}>
        <span className={styles.error}>{textAreaError}</span>
      </div>
    </React.Fragment>
  )
}

export default RichTextEditor
