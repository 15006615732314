import * as Yup from 'yup'

export const SignInSchema = Yup.object().shape({
  email: Yup.string()
    .email('invalid email')
    .matches(/^[^$%`~|\/]+$/, 'Invalid email format')
    .required('required*'),
  password: Yup.string()
    .required('required*')
    .matches(
      /^[A-Za-z0-9!@#*()+{}.&-_^%+=$]*$/,
      'Password cannot contain spaces'
    )
})
