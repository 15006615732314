import {useState} from 'react'
import {toast} from 'react-toastify'
import {Cancel} from '../../../assets/svgs'
import Button from '../../Button'
import TextArea from '../../TextArea'
import styles from './RequestChangesModal.module.scss'

interface RequestedChangesModalProps {
  handleClose: () => void
  updateRequestedChanges: ({
    comment,
    remove
  }: {
    comment?: string
    remove?: boolean
  }) => void
  fieldComment: string
}

const RequestedChangesModal = ({
  handleClose,
  fieldComment,
  updateRequestedChanges
}: RequestedChangesModalProps) => {
  const [comment, setComment] = useState(fieldComment || '')
  const [disabled, setDisabled] = useState(false)

  const handleRemove = () => {
    updateRequestedChanges({remove: true})
    toast.success('Requested Change Removed')
    handleClose()
  }

  const handleSubmit = async () => {
    if (comment === '') toast.warning('Please Write a Comment')
    else {
      setDisabled(true)
      updateRequestedChanges({comment})
      toast.success('Requested Change Added')
      setDisabled(false)
      handleClose()
      return
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.cancelContainer}>
        <Cancel className={styles.cancel} onClick={handleClose} />
      </div>
      <div className={styles.content}>
        <div className={styles.modalContent}>
          <div className={styles.headingContainer}>Add Comment</div>
          <div className={styles.textAreaContainer}>
            <TextArea
              value={comment}
              customTextAreaClass={styles.descriptionBox}
              customDivClass={styles.descriptionContainer}
              handleChange={(e) => {
                const value = e.target.value
                setComment(value)
              }}
              placeholder="Type your comment here..."
              name="commentBox"
              maxLength={100}
            />
          </div>
          <div className={styles.buttonsContainer}>
            {fieldComment ? (
              <Button
                customClass={styles.buttonClass}
                customBtnClass="btn-cancel"
                onClick={handleRemove}
                text="Remove"
              />
            ) : null}
            <Button
              customClass={styles.buttonClass}
              customBtnClass="btn-fill-orange"
              text="Submit"
              disabled={disabled}
              onClick={handleSubmit}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default RequestedChangesModal
