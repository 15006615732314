import React, {useState, useEffect} from 'react'
import styles from './FileModal.module.scss'
import {Cancel} from '../../../../assets/svgs'

interface FileModalProps {
  handleClose: () => void
  selectedFile: string
  isVideo: boolean
}

const FileModal = ({handleClose, selectedFile, isVideo}: FileModalProps) => {
  const handleCloseEvent = () => {
    handleClose()
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.heading}>
          <Cancel className={styles.cancel} onClick={handleCloseEvent} />
        </div>
        {isVideo ? (
          <div className={styles.videoContainer}>
            <video src={selectedFile} controls={true} />
          </div>
        ) : (
          <div className={styles.imgContainer}>
            <img src={selectedFile} alt="image" />
          </div>
        )}
      </div>
    </div>
  )
}

export default FileModal
