import React from 'react'
import styles from './InputField.module.scss'
import {get} from 'lodash'
import {CloseIcon, LargeSearch, OpenIcon} from 'assets/svgs'
import classNames from 'classnames'
import {removeEmojis} from 'components/utils'

interface InputFieldProps {
  inputMode?:
    | 'search'
    | 'text'
    | 'email'
    | 'tel'
    | 'url'
    | 'none'
    | 'numeric'
    | 'decimal'
    | undefined
  onChange?: React.ChangeEventHandler<HTMLInputElement>
  onBlur?: React.FocusEventHandler<HTMLInputElement>
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>
  onInput?: React.FormEventHandler<HTMLInputElement>
  iconClick?: () => void
  customClass?: string
  id?: string
  dataCypress?: string
  name?: string
  placeholder?: string
  type?: string
  value?: string | number
  defaultValue?: string
  errors?: any
  readOnly?: boolean
  touched?: any
  label?: string
  smaller?: boolean
  disabled?: boolean
  isVerified?: boolean
  requestedChanges?: {
    [key: string]: string
  }
  min?: number
  max?: number
  inputFieldIcon?: string
  isArrow?: boolean
  isSearch?: boolean
  isPassword?: boolean
  acceptEmoji?: boolean
  noError?: boolean
}

const InputField = ({
  customClass,
  placeholder,
  label,
  name = '',
  id,
  type = 'text',
  value,
  defaultValue,
  errors = {},
  touched = {},
  onChange,
  onBlur,
  disabled,
  onKeyDown,
  min,
  max,
  onInput,
  readOnly,
  inputFieldIcon,
  isArrow,
  isPassword = false,
  iconClick,
  isSearch = false,
  noError,
  acceptEmoji = false
}: InputFieldProps) => {
  const hasError = get(touched, name) || ''
  const inputFieldError = hasError && get(errors, name)
  const [passwordShown, setPasswordShown] = React.useState(false)
  const inputRef = React.useRef<HTMLInputElement>(null)
  const [fieldType, setFieldType] = React.useState(
    (isPassword && 'password') || type
  )

  if (type === 'number') {
    inputRef.current?.addEventListener('wheel', (event) => {
      event.preventDefault()
    })

    inputRef.current?.addEventListener('keydown', (event) => {
      if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
        event.preventDefault()
      }
    })
  }

  const preventNumberKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (type === 'number' || type === 'numeric') {
      if (e.key === 'e' || e.key === '-') {
        e.preventDefault()
      }
    }
  }

  const handleOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) =>
    onKeyDown ? onKeyDown(e) : preventNumberKeyDown(e)

  return (
    <div
      className={classNames(
        styles.container,
        isSearch && styles.search,
        customClass
      )}
      style={(isPassword && {width: 'max-content'}) || {}}
    >
      {isSearch && <LargeSearch />}
      <label htmlFor={name}>{label}</label>
      {isPassword ? (
        <div className={styles.revealContainer}>
          <input
            type={fieldType}
            id={id}
            name={name}
            value={value}
            min={min}
            max={max}
            onInput={onInput}
            readOnly={readOnly}
            placeholder={placeholder}
            defaultValue={defaultValue}
            className={inputFieldError && styles.hasError}
            disabled={Boolean(inputFieldIcon) || disabled}
            onChange={(e) => {
              if (
                !acceptEmoji &&
                (fieldType === 'text' || fieldType === 'email')
              ) {
                e.target.value = removeEmojis(e.target.value)
              }
              onChange && onChange(e)
            }}
            onBlur={(e) => {
              onBlur && onBlur(e)
            }}
          />

          <button
            tabIndex={-1}
            type="button"
            style={
              inputFieldError
                ? {
                    border: ' 1px solid #f8a517',
                    backgroundColor: 'rgba(248, 165, 23, 0.0980387)'
                  }
                : {border: '1px solid #e5e5e5', backgroundColor: '#f4f4f4'}
            }
            className={classNames(
              styles.eye_icon_container,
              inputFieldError && styles.err
            )}
            onClick={(e) => {
              e.preventDefault()
              setPasswordShown(!passwordShown)
              passwordShown ? setFieldType('password') : setFieldType('text')
            }}
          >
            {passwordShown ? (
              <OpenIcon className={styles.eye_icon} />
            ) : (
              <CloseIcon className={styles.eye_icon} />
            )}
          </button>
        </div>
      ) : (
        <input
          ref={inputRef}
          type={fieldType}
          max={max}
          id={id}
          name={name}
          value={value}
          onInput={onInput}
          readOnly={readOnly}
          placeholder={placeholder}
          defaultValue={defaultValue}
          onKeyDown={handleOnKeyDown}
          disabled={Boolean(inputFieldIcon) || disabled}
          min={min || (type === 'number' && 0) || undefined}
          className={classNames(
            inputFieldError && styles.hasError,
            type === 'number' && styles.number_field
          )}
          onChange={(e) => {
            if (
              !acceptEmoji &&
              (fieldType === 'text' || fieldType === 'email')
            ) {
              e.target.value = removeEmojis(e.target.value)
            }
            onChange && onChange(e)
          }}
          onBlur={(e) => {
            onBlur && onBlur(e)
          }}
        />
      )}
      {inputFieldIcon && (
        <div
          tabIndex={1}
          className={classNames(styles.inputIcon, isArrow && styles.arrowStyle)}
          onClick={iconClick}
        >
          <img alt="input-img" src={inputFieldIcon} />
        </div>
      )}
      {!noError && <div className={styles.error}>{inputFieldError}</div>}
    </div>
  )
}

export default InputField
