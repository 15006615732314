import {DealDocument} from 'common'
import {Collection, dealStage, productStatus} from 'common/enums'
import AlgoliaClient, {
  DEALS_ADMIN_PANEL_ALGOLIA_INDEX
} from 'components/AlgoliaSearch'
import Button from 'components/Button'
import ConfirmModal from 'components/ConfirmModal'
import {useFirebase} from 'components/Firebase/hooks'
import InputField from 'components/InputField'
import {Loader} from 'components/Loader'
import ModalComponent from 'components/ModalComponent'
import Pagination from 'components/Pagination'
import Table from 'components/TableComponent'
import {generateInvoice} from 'components/apis/invoice'
import {TABLE_PAGINATION_PAGE_SIZE} from 'components/constants'
import {debounce} from 'lodash'
import React, {useEffect, useRef, useState} from 'react'
import {toast} from 'react-toastify'
import styles from '../../DetailPage/DetailPage.module.scss'
import {searchWithAlgolia, useSortableData} from '../../utils'

const GenerateInvoicePage = () => {
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [confirmDisabled, setConfirmDisabled] = useState<boolean>(false)
  const [pageCount, setPageCount] = useState<number>(0)
  const [selectedRow, setSelectedRow] = useState<DealDocument>()
  const {checkDocuemnt} = useFirebase()
  const [loader, setLoader] = useState(false)
  const [deals, setDeals] = useState<Array<DealDocument>>([])
  const [showConfirmInvoiceModal, setShowConfirmInvoiceModal] =
    useState<boolean>(false)

  const scrollRef: React.RefObject<HTMLDivElement> = React.createRef()
  const scrollToRef = () =>
    scrollRef?.current?.scrollIntoView({behavior: 'smooth'})

  const searchIndex = AlgoliaClient.initIndex(DEALS_ADMIN_PANEL_ALGOLIA_INDEX)
  const debouncedSearch = useRef(debounce(searchWithAlgolia<DealDocument>, 800))
  const [searchValue, setSearchValue] = useState<string>('')

  useEffect(() => {
    AlgoliaClient.clearCache()
    searchWithAlgolia<DealDocument>({
      searchQuery: '*',
      searchIndex,
      setLoaderState: setLoader,
      facetFilters: [`stage:-${dealStage.OfferAccepted}`],
      setData: setDeals,
      setPageCount,
      currentPage
    }).then(() => setLoader(false))
  }, [])

  const {items, sortBy} = useSortableData(deals)
  const showPagination = pageCount > TABLE_PAGINATION_PAGE_SIZE

  const onPageChange = (page: number) => {
    setLoader(true)
    setCurrentPage(page)
    debouncedSearch?.current({
      searchQuery: searchValue,
      searchIndex,
      setLoaderState: setLoader,
      setData: setDeals,
      facetFilters: [`stage:-${dealStage.OfferAccepted}`],
      setPageCount,
      currentPage: page
    })
    setLoader(false)
    scrollToRef()
  }

  const toggleConfirmModal = () =>
    setShowConfirmInvoiceModal(!showConfirmInvoiceModal)

  const handleConfirm = async () => {
    if (!selectedRow?.dealId) {
      toast.warn('No deal selected')
      return
    }
    setConfirmDisabled(true)
    const {error, exists} = await checkDocuemnt({
      collectionName: Collection.Products,
      documentId: selectedRow.productId,
      queryValue: productStatus.Sold,
      queryVariable: 'status'
    })
    if (error) {
      toast.warn('Something went wrong!')
      setConfirmDisabled(false)
      return
    }
    if (exists) {
      toast.warn('The equipment against this deal has been sold.')
      setConfirmDisabled(false)
      return
    }
    if (exists === false) {
      setConfirmDisabled(true)
      const {data: invoiceData} = await generateInvoice({
        dealId: selectedRow.dealId
      })
      if (invoiceData?.code !== 200) {
        setConfirmDisabled(false)
        toast.warn(invoiceData?.data)
        return
      }
      setConfirmDisabled(false)
      setShowConfirmInvoiceModal(false)
      toast.success('Invoice generated successfully')
    }
  }

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event?.target?.value
    setSearchValue(value)
    if (value) {
      debouncedSearch?.current({
        searchQuery: value,
        searchIndex,
        setLoaderState: setLoader,
        facetFilters: [`stage:-${dealStage.OfferAccepted}`],
        setData: setDeals,
        setPageCount
      })
    } else {
      debouncedSearch?.current({
        searchQuery: '*',
        searchIndex,
        setLoaderState: setLoader,
        facetFilters: [`stage:-${dealStage.OfferAccepted}`],
        setData: setDeals,
        setPageCount,
        currentPage
      })
    }
  }

  const tableTitles = [
    {title: 'Deal Name', sortBy: () => sortBy('dealName')},
    {title: 'Buyer Account Name', sortBy: () => sortBy('buyerAccountName')},
    {title: 'Buyer Contact Name', sortBy: () => sortBy('buyerContactName')},
    {title: 'Seller Account Name', sortBy: () => sortBy('sellerAccountName')},
    {title: 'Seller Contact Name', sortBy: () => sortBy('sellerContactName')},
    {title: 'Generate Invoice'}
  ]

  const getRowData = (rowData: Array<DealDocument>) => {
    return rowData.map((rowData, index) => {
      const {
        buyerAccountName,
        buyerContactName,
        sellerAccountName,
        sellerContactName,
        dealName
      } = rowData
      return (
        <tr key={`deal-${index}`}>
          <td>{dealName || '-'}</td>
          <td>{buyerAccountName || '-'}</td>
          <td>{buyerContactName || '-'}</td>
          <td>{sellerAccountName || '-'}</td>
          <td>{sellerContactName || '-'}</td>
          <td>
            <Button
              customClass={styles.genrate_invoice_btn}
              text="Generate"
              onClick={() => {
                setSelectedRow(rowData)
                toggleConfirmModal()
              }}
            />
          </td>
        </tr>
      )
    })
  }

  if (loader) return <Loader />

  return (
    <section className={styles.invoiceSection}>
      <div className={styles.button_filters}>
        <InputField
          name="generateInvoiceSearchField"
          type="text"
          value={searchValue}
          onChange={handleSearch}
          isSearch={true}
          customClass={styles.inputField}
          placeholder="Search"
        />
      </div>
      <Table
        titles={tableTitles}
        rowData={getRowData(items)}
        customClass={styles.containerTable}
      />
      {showPagination && (
        <div className="pagination__wrapper">
          <Pagination
            currentPage={currentPage}
            totalCount={pageCount}
            pageSize={TABLE_PAGINATION_PAGE_SIZE}
            onPageChange={onPageChange}
          />
        </div>
      )}
      {showConfirmInvoiceModal && (
        <ModalComponent
          show={showConfirmInvoiceModal}
          handleClose={() => setShowConfirmInvoiceModal(false)}
        >
          <ConfirmModal
            content="Are you sure you want to generate an invoice for this deal"
            text="Confirm Invoice Generation"
            handleClose={() => setShowConfirmInvoiceModal(false)}
            handleConfirm={handleConfirm}
            confirmBtnDisabled={confirmDisabled}
          />
        </ModalComponent>
      )}
    </section>
  )
}

export default GenerateInvoicePage
