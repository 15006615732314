import classNames from 'classnames'
import {
  AccountDocument,
  AdminDocument,
  AgreementDocument,
  AttachmentFileType,
  BillingAddress,
  ContactDocument,
  FILE_OBJECT,
  IProductFormState,
  ProductDocument
} from 'common'
import {
  AdminNotifications,
  SellerNotifications,
  sendNotification
} from 'common/apis/notifications'
import {APIProductInitalDocument} from 'common/apis/types'
import {
  INITIAL_AUCTION_PRODUCT_STATE,
  INITIAL_BUY_NOW_PRODUCT_STATE
} from 'common/constants'
import {
  Collection,
  adminRole,
  listingStatus,
  listingType,
  productStatus
} from 'common/enums'
import AlgoliaClient, {PRODUCTS_ALGOLIA_INDEX} from 'components/AlgoliaSearch'
import ButtonDropDown from 'components/ButtonDropDown'
import ConfirmationModal from 'components/ConfirmationModal'
import MessageInformation from 'components/MessageInformation'
import ModalComponent from 'components/ModalComponent'
import RecallModal from 'components/RecallModal'
import SelectConsigneeModal from 'components/SelectConsigneeModal'
import {validateAddress} from 'components/apis/taxjar'
import {getUserAuth} from 'components/apis/user'
import {API_HOST_URL} from 'components/constants'
import {DetailType, signMethod} from 'components/enums'
import {Formik, FormikHelpers, FormikProps} from 'formik'
import _, {get, isEqual, isNumber, last} from 'lodash'
import React, {createRef, useEffect, useRef, useState} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {toast} from 'react-toastify'
import {CheckedIcon, UncheckedIcon} from '../../assets/images'
import {ArrowDown, Building} from '../../assets/svgs'
import Button from '../Button'
import DetailPage from '../DetailPage'
import DropDownSelect from '../DropDown'
import {useAppContext, useFirebase} from '../Firebase/hooks'
import InputField from '../InputField'
import CurrencyInputField from '../InputField/CurrencyInputField'
import Layout from '../Layout'
import {Loader} from '../Loader'
import NavBar from '../Navbar'
import withAuthorization from '../Session/withAuthorization'
import {
  getDaysFromToday,
  getLinkPreviewData,
  getUserFullName,
  sortByName,
  toMomentDateString
} from '../utils'
import AuctionDetail from './AuctionDetail'
import DragPdf from './DragPdf'
import FaqSection from './FAQSection'
import FormErrorNotification from './FormErrorNotification'
import styles from './ProductDetailPage.module.scss'
import ProductDetailSection from './ProductDetailSection'
import UploadFileSection from './UploadFileSection'
import {INITIAL_PRODUCT_FORM_STATE, LISTINGTYPE_OPTIONS} from './constants'
import {
  AuctionProductFormSchema,
  ProductFormSchema,
  ProductInitialDocumentSchema
} from './schema'
import {getModifiedEqipmentFormFields} from './utils'

const ProductDetailPage = ({customClass}: {customClass: string}) => {
  const [agreementDocuments, setAgreementDocuments] = useState<{
    documentData: Array<AgreementDocument>
  }>()
  const [hasCheckedDocuments, setHasCheckedDocuments] = useState<boolean>(false)
  const [submitting, setSubmitting] = useState<boolean>(false)
  const [isFileUploading, setIsFileUploading] = useState<boolean>(false)
  const [isPDFUploading, setIsPDFUploading] = useState<boolean>(false)
  const isUploading = isFileUploading || isPDFUploading
  const [rejctionSubmitting, setRejctionSubmitting] = useState<boolean>(false)
  const [deleteSubmitted, setDeleteSubmitted] = useState<boolean>(false)
  const [show, setShow] = useState<boolean>(false)
  const [showRejectionModal, setShowRejectionModal] = useState<boolean>(false)
  const [preventInitialSubmission, setPreventInitialSubmission] =
    useState<boolean>(true)
  const [documents, setDocuments] = useState<boolean>(false)
  const [accountOptionsList, setAccountOptionsList] = useState<
    Array<{[key: string]: string}>
  >([])
  const [showConsigneeModal, setShowConsigneeModal] = useState<{
    method: signMethod | undefined
    show: boolean
  }>({
    method: undefined,
    show: false
  })
  const [showRecallModal, setShowRecallModal] = useState(false)
  const [contactOptionsList, setContactOptionsList] = useState<
    Array<{[key: string]: string}>
  >([])
  const navigate = useNavigate()
  const {state, search} = useLocation()
  const searchProductId = new URLSearchParams(search).get('productId')
  const formRef: React.Ref<FormikProps<IProductFormState>> | undefined =
    useRef(null)
  const [accounts, setAccounts] = useState<Array<AccountDocument>>([])
  const objectID = state?.objectID
  const productId =
    searchProductId ||
    state?.productData?.productId ||
    state?.id ||
    state?.product?.productId
  const isEdit = state?.isEdit
    ? state?.isEdit
    : false || Boolean(searchProductId)
  const [accountAddress, setAccountAddress] = useState<BillingAddress>()
  const isUpdate = state?.isUpdate || false
  const previousPage = state?.currentPage || 1
  const [disabled, setDisabled] = useState(isUpdate ? false : isEdit)
  const [loader, setLoader] = useState(isEdit ? true : false)
  const [filesCount, setFilesCount] = useState<{
    attachments: number
    media: number
  }>({attachments: 0, media: 0})
  const [assetsLoaded, setAssetsLoaded] = useState<boolean>(false)
  const [product, setProduct] = useState<{
    documentData: ProductDocument | null
  }>({
    documentData: null
  })

  useEffect(() => {
    if (!productId) return

    const unsubscribe = getCollectionSnapshotBasedOnQuery<AgreementDocument>({
      updateState: setAgreementDocuments,
      cancelCallback: (_error) => {
        toast.warn('Failed to get listing agreement status')
      },
      collectionName: Collection.ZohoSignListingAgreement,
      queryVariable: 'productId',
      queryValue: productId
    }).unsub

    return () => {
      if (unsubscribe) unsubscribe()
    }
  }, [productId])

  const displayDeleteModal = () => {
    if (documents) {
      toast.warn('This equipment has deals/listing agreements made against it.')
    }
    setShow(true)
  }
  const closeDeleteModal = () => setShow(false)
  const closeRejectionModal = () => setShowRejectionModal(false)
  const displayRejectionModal = () => setShowRejectionModal(true)

  const {
    getCollectionData,
    getProductDocumentData,
    setProductDocument,
    getCollectionBasedOnQuery,
    updateProductDetails,
    deleteProduct,
    getFileInfoFromStorage,
    getCollectionSnapshotBasedOnQuery
  } = useFirebase()

  const checkProductIsEmpty = (productData: ProductDocument) => {
    const hasAddressObject = Boolean(
      productData?.isTheEquipmentAtADifferentAddress
    )
    const hasTitle = Boolean(productData?.title)
    const hasManufactorer = Boolean(productData?.productManufacturer)
    const hasYear = Boolean(productData?.year)
    const hasSerialNumber = Boolean(productData?.serialNumber)
    const hasVinNumber = Boolean(productData?.equipmentVinNumber)
    const hasMileage = isNumber(productData?.equipmentMileage)
    const hasHours = isNumber(productData?.equipmentHours)
    const isEmptyCase =
      !hasHours && !hasVinNumber && !hasSerialNumber && !hasMileage
    const hasDescription = Boolean(productData?.description)
    const isEmptyProduct =
      !hasAddressObject &&
      !hasTitle &&
      !hasManufactorer &&
      !hasYear &&
      isEmptyCase &&
      !hasDescription
    if (isEmptyProduct) {
      return true
    }
    return false
  }

  const isEmptyProduct = isEdit
    ? checkProductIsEmpty(product.documentData!)
    : false

  const hasSignedLAAgreement: boolean =
    product.documentData?.listingAgreementSigned || false

  const disableLAButton: boolean =
    hasSignedLAAgreement || isEmptyProduct || false

  const [adminsList, setAdminsList] = useState<any>([])
  const {appState: applicationState} = useAppContext()
  const isSuperAdmin = applicationState?.isSuperAdmin || false
  const isSuperManager = applicationState?.isSuperManager || false
  const hasAdminPrivelages =
    applicationState.isSuperAdmin || applicationState.isSuperManager
  const showLAButton = (isEdit && isSuperAdmin) || false
  const isAuction = product.documentData?.listingType === listingType.Auction
  const isBuyNow = product.documentData?.listingType === listingType.BuyNow
  const isPending =
    product.documentData?.status === productStatus.Pending || false
  const isApproved =
    product.documentData?.status === productStatus.Approved || false
  const isCanceled =
    product.documentData?.status === productStatus.Canceled || false
  const inProgress =
    product.documentData?.status === productStatus.inProgress || false

  const getDropdownOptions = () => {
    if (isApproved) {
      return [
        productStatus.Approved,
        productStatus.Rejected,
        productStatus.Canceled
      ]
    }
    if (isPending) {
      return [
        productStatus.Approved,
        productStatus.Rejected,
        productStatus.Pending
      ]
    }
    if (isCanceled) {
      return [productStatus.Canceled, productStatus.Pending]
    }
    if (inProgress) {
      return [productStatus.inProgress, productStatus.Pending]
    }
    return [
      productStatus.Approved,
      productStatus.Canceled,
      productStatus.Pending,
      productStatus.Rejected,
      productStatus.Sold,
      productStatus.inProgress
    ]
  }

  const statusDropdownOptions = getDropdownOptions()
  const {documentData: productData} = product
  const agreementDocument = _.first(agreementDocuments?.documentData)
  const isSignedByOneParty =
    agreementDocument?.status === listingStatus.CONSIGNEE_SIGNED ||
    agreementDocument?.status === listingStatus.RECIPIENT_SIGNED
  const displayRecallOption = agreementDocument ? !isSignedByOneParty : false

  const toggleShowManualSignModal = () =>
    setShowConsigneeModal({
      method: signMethod.Manual,
      show: true
    })

  const toggleShowZohoSignModal = () =>
    setShowConsigneeModal({
      method: signMethod.Zoho,
      show: true
    })

  const handleCloseConsigneeModal = () =>
    setShowConsigneeModal({method: undefined, show: false})

  const getConsigneeData = async () => {
    !loader && setLoader(true)
    const response = await getCollectionBasedOnQuery<AdminDocument>(
      Collection.Admins,
      'role',
      adminRole.superAdmin
    )
    const consigneeData = response?.data ? response.data : []
    const adminData = consigneeData.map((admin) => {
      return {
        id: admin.userId,
        name: getUserFullName(admin?.firstName || '', admin?.lastName || ''),
        email: admin.contactInfo.emailAddress
      }
    })
    setAdminsList(adminData)
    setLoader(false)
  }

  const durationInDays = getDaysFromToday(
    product.documentData?.contractInfo?.contractDuration
  )

  const isProductDetails = isEdit && disabled
  const formInitialState = isAuction
    ? product.documentData?.productId
      ? {
          ...INITIAL_AUCTION_PRODUCT_STATE,
          ...product.documentData,
          contractInfo: {
            ...product.documentData.contractInfo,
            contractDuration: durationInDays,
            durationInDays: product.documentData.contractInfo?.contractDuration,
            autoRenew: product.documentData.contractInfo?.autoRenew || false
          }
        }
      : {...INITIAL_AUCTION_PRODUCT_STATE, status: productStatus.inProgress}
    : isBuyNow
    ? product.documentData?.productId
      ? {
          ...INITIAL_BUY_NOW_PRODUCT_STATE,
          ...product.documentData,
          contractInfo: {
            ...product.documentData.contractInfo,
            contractDuration: durationInDays,
            durationInDays: product.documentData.contractInfo?.contractDuration,
            autoRenew: product.documentData.contractInfo?.autoRenew || false
          }
        }
      : {...INITIAL_BUY_NOW_PRODUCT_STATE, status: productStatus.inProgress}
    : INITIAL_PRODUCT_FORM_STATE

  const formSchema = isAuction
    ? AuctionProductFormSchema
    : isBuyNow
    ? ProductFormSchema
    : ProductInitialDocumentSchema

  const setFormState = async () => {
    const formValues: IProductFormState = {
      ...(isAuction
        ? INITIAL_AUCTION_PRODUCT_STATE
        : INITIAL_BUY_NOW_PRODUCT_STATE),
      ...{
        ...productData,
        contractInfo: {
          ...productData?.contractInfo,
          contractDuration: durationInDays,
          durationInDays: durationInDays,
          autoRenew: productData?.contractInfo?.autoRenew || false
        }
      }
    }
    await formRef.current?.setValues(formValues)
  }

  const setAsAccountAddress = async (
    equipmentAddress: ProductDocument['isTheEquipmentAtADifferentAddress']
  ) => {
    const {
      equipmentCity,
      equipmentCountry,
      equipmentState,
      equipmentStreet,
      equipmentZip,
      isEquipmentAtADifferentAddress
    } = equipmentAddress || {}
    if (formRef.current) {
      const {current} = formRef
      const {setFieldValue} = current
      await setFieldValue(
        'isTheEquipmentAtADifferentAddress.equipmentCountry',
        equipmentCountry
      )
      await setFieldValue(
        'isTheEquipmentAtADifferentAddress.equipmentState',
        equipmentState
      )
      await setFieldValue(
        'isTheEquipmentAtADifferentAddress.equipmentCity',
        equipmentCity
      )
      await setFieldValue(
        'isTheEquipmentAtADifferentAddress.equipmentZip',
        equipmentZip
      )
      await setFieldValue(
        'isTheEquipmentAtADifferentAddress.equipmentStreet',
        equipmentStreet
      )
      await setFieldValue(
        'isTheEquipmentAtADifferentAddress.isEquipmentAtADifferentAddress',
        isEquipmentAtADifferentAddress
      )
    }
  }

  const setAccountAddressFields = async () => {
    if (!product.documentData?.accountId) return
    const isDifferentAddress =
      product.documentData?.isTheEquipmentAtADifferentAddress
        ?.isEquipmentAtADifferentAddress

    if (isDifferentAddress) {
      const equipmentAddress =
        product.documentData?.isTheEquipmentAtADifferentAddress

      setAsAccountAddress({
        equipmentCity: equipmentAddress?.equipmentCity || '',
        equipmentCountry: equipmentAddress?.equipmentCountry || '',
        equipmentState: equipmentAddress?.equipmentState || '',
        equipmentStreet: equipmentAddress?.equipmentStreet || '',
        equipmentZip: equipmentAddress?.equipmentZip || '',
        isEquipmentAtADifferentAddress:
          equipmentAddress?.isEquipmentAtADifferentAddress || true
      })
      return
    }
    setAsAccountAddress({
      equipmentCity: accountAddress?.billingCity || '',
      equipmentCountry: accountAddress?.billingCountry || '',
      equipmentState: accountAddress?.billingState || '',
      equipmentStreet: accountAddress?.billingStreetAddress || '',
      equipmentZip: accountAddress?.billingZipCode || '',
      isEquipmentAtADifferentAddress: false
    })
  }

  const getAccounts = async () => {
    const {data = [], error} = await getCollectionData(Collection.Accounts)
    if (error) {
      toast.error('Something went wrong while fetching accounts.')
      return
    }
    setAccounts(data)
  }

  const modifyFiles = async (filesUrls: Array<string>) => {
    const fileURLArray: Array<FILE_OBJECT> = []
    for (const url of filesUrls) {
      const obj = await getFileInfoFromStorage(url)
      if (obj) fileURLArray.push(obj)
    }
    return fileURLArray
  }

  const getContacts = async (accountId: string) => {
    const {data = []} = await getCollectionBasedOnQuery<ContactDocument>(
      Collection.Contacts,
      'accountId',
      accountId
    )
    return data
  }

  const setFilteredContacts = async (
    index: number,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined
    ) => Promise<any>,
    setFieldError: (field: string, message: string | undefined) => void,
    setFieldTouched: (
      field: string,
      isTouched?: boolean | undefined,
      shouldValidate?: boolean | undefined
    ) => void
  ) => {
    const account = accountOptionsList[index]
    const accountID = account.id
    const accountName = account.name
    await setFieldValue('accountId', accountID)
    await setFieldValue('accountName', accountName)
    setFieldError('accountId', '')
    setFieldTouched('accountId', false)
    const contactsData: Array<ContactDocument> = await getContacts(accountID)
    const filteredContacts = contactsData.filter(
      (contact) => contact?.accountId === accountID
    )
    if (!filteredContacts.length) {
      toast.error('Selected Account has no Contacts')
      await setFieldValue('contactId', '')
      await setFieldValue('contactName', '')
      await setFieldValue('sellerPhotoUrl', '')
      setFieldTouched('contactId', true)
      setContactOptionsList([])
      return
    } else {
      const dropdownOptions = filteredContacts.map((contact: any) => {
        const contactName =
          (contact?.firstName ? contact?.firstName + ' ' : '') +
          (contact?.lastName ? contact?.lastName + ' ' : '')
        const photoURL = contact?.contactInfo?.photoURL || ''
        return {
          id: contact?.documentId || contact?.contactId,
          name: contactName,
          isPrimaryContact: contact?.isPrimaryContact || false,
          photoURL
        }
      })
      const primaryContact = dropdownOptions.find(
        (contact) => contact.isPrimaryContact
      )
      if (primaryContact) {
        await setFieldValue('contactId', primaryContact.id)
        await setFieldValue('contactName', primaryContact.name)
        await setFieldValue('sellerPhotoUrl', primaryContact.photoURL)
        setFieldError('contactId', '')
        setFieldTouched('contactId', false)
      } else {
        await setFieldValue('contactId', '')
        await setFieldValue('contactName', '')
        await setFieldValue('sellerPhotoUrl', '')
      }
      dropdownOptions.sort(sortByName)
      setContactOptionsList([...dropdownOptions])
    }
  }

  const setFileFieldValues = async (
    fileArray: Array<string>,
    pdfArray: Array<AttachmentFileType>,
    driveUrlsArray?: Array<string>,
    embedUrlsArray?: Array<string>
  ) => {
    const files = (await modifyFiles(fileArray)) || []
    if (embedUrlsArray?.length) {
      const embedUrls = await modifyEmbedUrls(embedUrlsArray)
      await formRef.current?.setFieldValue(
        'productDetails.embedUrls',
        embedUrls
      )
      files.push(...embedUrls)
    }
    if (driveUrlsArray?.length) {
      const driveUrls = await modifyDriveUrls(driveUrlsArray)
      await formRef.current?.setFieldValue(
        'productDetails.driveUrls',
        driveUrls
      )
      files.push(...driveUrls)
    }
    await formRef.current?.setFieldValue('productDetails.files', files)
    await formRef.current?.setFieldValue(
      'productDetails.pdfAttachments',
      pdfArray
    )
  }

  const modifyEmbedUrls = async (filesUrls: Array<string>) => {
    const embedUrlsArray: Array<FILE_OBJECT> = []
    for (const url of filesUrls) {
      const videoId = last(url.split('/'))
      const obj = await getLinkPreviewData(
        `https://www.youtube.com/watch?v=${videoId}`
      )
      const fileObj: FILE_OBJECT = {
        name: obj?.title,
        originalName: obj?.title,
        URL: obj.video || url,
        type: 'video',
        uploadType: 'embed'
      }
      embedUrlsArray.push(fileObj)
    }
    return embedUrlsArray
  }

  const modifyDriveUrls = async (filesUrls: Array<string>) => {
    const driveUrlsArray: Array<FILE_OBJECT> = []
    for (const url of filesUrls) {
      const obj = await getLinkPreviewData(url)
      const fileObj: FILE_OBJECT = {
        type: 'video',
        name: obj?.title,
        URL: obj?.image || obj?.url,
        originalName: obj?.title,
        downloadURL: obj?.url,
        hasThumbnail: true,
        uploadType: 'google-drive'
      }
      driveUrlsArray.push(fileObj)
    }
    return driveUrlsArray
  }

  const handleDeletion = async () => {
    if (deleteSubmitted) return
    setDeleteSubmitted(true)
    setLoader(true)
    const searchIndex = AlgoliaClient.initIndex(PRODUCTS_ALGOLIA_INDEX)
    await searchIndex.deleteObject(objectID)
    const {error} = await deleteProduct(productId)
    setLoader(false)
    if (error) {
      toast.error('Something Went Wrong.')
      setDeleteSubmitted(false)
      setShow(false)
      return
    }
    setShow(false)
    setDeleteSubmitted(false)
    toast.success('Equipment Successfully Deleted!')
    navigate('/equipment')
    return
  }

  const handleDraftSubmit = async (values: IProductFormState) => {
    const {
      contactId,
      contactName,
      accountId,
      accountName,
      sellerPhotoUrl = '',
      listingType: productListingType
    } = values || {}

    if (!contactId) {
      toast.error('Please select a contact')
      return
    }

    setSubmitting(true)
    setLoader(true)

    const initialState =
      productListingType === listingType.Auction
        ? INITIAL_AUCTION_PRODUCT_STATE
        : INITIAL_BUY_NOW_PRODUCT_STATE

    const initialProductDocument: APIProductInitalDocument = {
      sellerPhotoUrl,
      accountId,
      accountName,
      contactId,
      contactName,
      listingType: productListingType,
      status: productStatus.inProgress,
      isCommissionSetManually: false
    }

    const {error, message, equipment} = await setProductDocument({
      productData: {
        ...initialProductDocument,
        contractInfo: {
          autoRenew: true,
          renewalWindow: initialState.contractInfo?.renewalWindow || 30,
          durationInDays: initialState.contractInfo?.durationInDays,
          contractDuration: toMomentDateString(
            initialState.contractInfo?.contractDuration
          )
        }
      }
    })

    if (error) {
      toast.warning(error)
      setSubmitting(false)
      setLoader(false)
      return
    }

    toast.success(message)
    navigate(`/equipment/single-equipment-detail`, {
      state: {
        id: equipment?.productId,
        isEdit: true,
        isUpdate: true
      }
    })
    setLoader(false)
    setSubmitting(false)
    return
  }

  const handleEquipmentSubmit = async (values: ProductDocument) => {
    const {
      createdAt,
      lastUpdated,
      productId,
      isFeaturedListing,
      ...restProductValues
    } = values
    const {productDetails: restProductValuesProductDetails} = restProductValues
    const {driveUrls, images, videos} = restProductValuesProductDetails || {}

    const productDetails = {
      ...(videos
        ? {
            videos
          }
        : {}),
      ...(images
        ? {
            images
          }
        : {}),
      ...(driveUrls
        ? {
            driveUrls
          }
        : {})
    }

    const {
      error,
      message,
      code: errorCode
    } = await setProductDocument({
      productData: {
        ...restProductValues,
        isFeaturedListing:
          values.status === productStatus.Canceled ? false : isFeaturedListing
      },
      isEdit: true,
      productId
    })

    if (error) {
      toast.warning(error)
      setSubmitting(false)
      return
    }

    // For updating order according to drag & drop
    if (productDetails.images?.length) {
      const {error: updateError} = await updateProductDetails({
        productId,
        productDetails
      })

      if (updateError) {
        toast.warn(
          'Something went wrong! we were not able to update the arrangement of the images/videos, please try again.'
        )
        setSubmitting(false)
        return
      }
    }

    if (values.status !== productData?.status) {
      const productStatusForSellerNotification =
        (values.status === productStatus.Approved &&
          SellerNotifications.ProductApproved) ||
        (values.status === productStatus.Rejected &&
          SellerNotifications.ProductRejected)

      const productStatusForAdminNotification =
        (values.status === productStatus.Approved &&
          AdminNotifications.ProductApplicationApproved) ||
        (values.status === productStatus.Rejected &&
          AdminNotifications.ProductApplicationRejected)

      const scheduledAuctionStartNotification =
        values.status === productStatus.Approved &&
        values.listingType === listingType.Auction

      if (productStatusForSellerNotification)
        sendNotification({
          baseURL: API_HOST_URL,
          contactId: productData?.contactId,
          code: productStatusForSellerNotification,
          productId
        })

      if (productStatusForAdminNotification)
        sendNotification({
          baseURL: API_HOST_URL,
          code: productStatusForAdminNotification,
          contactId: productData?.contactId,
          productId
        })

      if (scheduledAuctionStartNotification)
        sendNotification({
          baseURL: API_HOST_URL,
          code: SellerNotifications.ScheduledAuctionStartDate,
          contactId: productData?.contactId,
          productId
        })
    }

    if (errorCode === 206) {
      toast.warn(
        `Commission percentage cannot be manually updated as MSA has not been signed for the account.
           Other data has been successfully updated`
      )
    } else {
      toast.success(message)
    }

    setDisabled(true)
    setAssetsLoaded(false)
    setSubmitting(false)
    navigate(`/equipment/single-equipment-detail`, {
      state: {
        id: productId,
        isEdit: true,
        isUpdate: true
      }
    })
    return
  }

  const handleFormConditions = async (values: IProductFormState) => {
    const isRejectted = productData?.status === productStatus.Rejected
    const isSold = productData?.status === productStatus.Sold
    const isBeingApproved = values.status === productStatus.Approved
    const isBeingRejected =
      product.documentData?.status !== productStatus.Rejected &&
      values?.status === productStatus.Rejected

    if (isRejectted) {
      toast.warn('You cannot update a rejected product')
      return true
    }

    if (isSold) {
      toast.warn('You cannot update a sold product')
      return true
    }

    if ((isSuperManager && isApproved) || (isSuperManager && isBeingApproved)) {
      toast.warn('Insufficient privelages to perform this action.')
      return true
    }

    if (isBeingRejected) {
      if (preventInitialSubmission) {
        displayRejectionModal()
        return true
      }
      setPreventInitialSubmission(true)
    }

    if (isUploading) {
      toast.warn('Please wait till the files finish uploading')
      return true
    }

    if (!assetsLoaded) {
      toast.warn('Please wait till all the files have completed loading')
      return true
    }

    const isDifferentStatus = values.status !== productData?.status
    const hasPendingLA =
      isDifferentStatus &&
      !hasSignedLAAgreement &&
      values.status === productStatus.Approved &&
      isPending

    if (isDifferentStatus) {
      const {data} = await getUserAuth(productData?.contactId || '')
      const emailNotVerified = !data?.data?.emailVerified

      if (data?.code !== 200) {
        toast.warn(data.msg)
        return true
      }

      if (emailNotVerified) {
        toast.error('The contact has not verified their email address')
        return true
      }
    }

    if (hasPendingLA) {
      toast.error('Cannot approve equipment as listing agreement is not signed')
      setSubmitting(false)
      return true
    }

    return false
  }

  const handleSubmitForm = async (
    values: IProductFormState,
    formik: FormikHelpers<IProductFormState>
  ) => {
    if (submitting) return
    setSubmitting(true)

    if (!isEdit) {
      await handleDraftSubmit(values)
      setSubmitting(false)
      return
    }

    if (!productData) {
      toast.warn('Failed to get equipment document')
      setSubmitting(false)
      return
    }

    setSubmitting(true)

    const isDifferentAddress =
      values.isTheEquipmentAtADifferentAddress?.isEquipmentAtADifferentAddress
    const differentAddressChanged = !isEqual(
      productData.isTheEquipmentAtADifferentAddress,
      values.isTheEquipmentAtADifferentAddress
    )

    if (isDifferentAddress && differentAddressChanged) {
      const {equipmentCity, equipmentCountry, equipmentState, equipmentZip} =
        values?.isTheEquipmentAtADifferentAddress || {}

      const {error} = await validateAddress({
        city: equipmentCity,
        state: equipmentState,
        zip: equipmentZip,
        country: equipmentCountry
      })

      const isUnsupportedBillingCountry = error?.includes(
        'Unsupported Country',
        /g/
      )

      if (isUnsupportedBillingCountry) {
        toast.info(
          'Our platform validates only US addresses, please make sure the entered physical address is correct'
        )
      }

      if (error && !isUnsupportedBillingCountry) {
        formik.setFieldError(
          'isTheEquipmentAtADifferentAddress.equipmentCountry',
          'Invalid Address'
        )
        formik.setFieldError(
          'isTheEquipmentAtADifferentAddress.equipmentState',
          'Invalid Address'
        )
        formik.setFieldError(
          'isTheEquipmentAtADifferentAddress.equipmentCity',
          'Invalid Address'
        )
        formik.setFieldError(
          'isTheEquipmentAtADifferentAddress.equipmentZip',
          'Invalid Address'
        )
        toast.warn('Please enter a valid equipment address.')
        setSubmitting(false)
        return
      }
    }

    const shouldReturn = await handleFormConditions(values)
    if (shouldReturn) {
      setSubmitting(false)
      return
    }

    const formValues = getModifiedEqipmentFormFields({
      values,
      isAuction,
      productDocument: productData,
      companyAddress: accountAddress
    })

    await handleEquipmentSubmit(formValues)
  }

  useEffect(() => {
    const cancelCallback = (error: Error) => {
      if (error) {
        toast.error(`Something went wrong while fetching equipment data!`)
      }
    }
    const {unsSubData} = getProductDocumentData({
      cancelCallback,
      documentId: productId,
      updateState: setProduct,
      callback: setTotalFilesCallback
    })
    return () => {
      unsSubData?.()
      setAssetsLoaded(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productId])

  const setTotalFilesCallback = (product: ProductDocument) => {
    if (product?.productDetails) {
      const {
        firstChoice,
        imageThumbnails,
        secondChoice,
        attachments,
        videoThumbnails,
        ...rest
      } = product.productDetails
      const files = Object.values(rest).filter(Array.isArray)
      const filesLength = files.reduce(
        (accumulator, currentArray) => accumulator + currentArray.length,
        0
      )
      setFilesCount({attachments: attachments?.length || 0, media: filesLength})
    }
  }

  useEffect(() => {
    const getAccountAddress = async () => {
      if (!product.documentData?.accountId) return
      const {data} = await getCollectionBasedOnQuery<AccountDocument>(
        Collection.Accounts,
        'accountId',
        product.documentData?.accountId
      )
      const accountAddress: BillingAddress | undefined =
        data?.[0]?.billingAddress

      setAccountAddress(accountAddress)
    }
    getAccountAddress()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product.documentData?.accountId])

  const setFormFields = async () => {
    if (!isEdit) {
      await getAccounts()
    }

    if (isEdit) {
      if (isSuperAdmin) {
        await getConsigneeData()
      }

      if (!disabled) {
        await setFormState()
        await setAccountAddressFields()

        const productDetails = product.documentData?.productDetails
        const images = productDetails?.images || []
        const videos = productDetails?.videos || []
        const productDataStatus = product.documentData?.status
        const isActive = productDataStatus === productStatus.Approved
        const attachments = productDetails?.attachments || []
        const driveUrls = productDetails?.driveUrls || []
        const embedUrls = productDetails?.embedUrls || []
        const fileArray = [...images, ...videos]

        if (isActive)
          await formRef.current?.setFieldValue('status', productStatus.Approved)

        await setFileFieldValues(fileArray, attachments, driveUrls, embedUrls)
        setAssetsLoaded(true)
      }
      return
    }
  }

  useEffect(() => {
    setLoader(true)
    setFormFields().then(() => setLoader(false))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    disabled,
    isEdit,
    product.documentData?.status,
    product.documentData?.productId
  ])

  useEffect(() => {
    const dropdownOptions = accounts
      .map((account: AccountDocument) => {
        const accountName = account?.accountName || ''
        return {
          id: account?.accountId,
          name: accountName
        }
      })
      .sort(sortByName)
    setAccountOptionsList([...dropdownOptions])
  }, [accounts])

  const scrollRef: React.RefObject<HTMLDivElement> = createRef()
  const scrollToRef = () =>
    scrollRef?.current?.scrollIntoView({behavior: 'smooth'})

  const listingAgreementOptions = displayRecallOption
    ? [
        {
          onClick: toggleShowZohoSignModal,
          option: 'Send Agreement Through Zoho Sign'
        },
        {
          onClick: toggleShowManualSignModal,
          option: 'Sign Agreement Manually'
        },
        {
          onClick: () => setShowRecallModal(true),
          option: 'Recall Listing Agreement'
        }
      ]
    : [
        {
          onClick: toggleShowZohoSignModal,
          option: 'Send Agreement Through Zoho Sign'
        },
        {
          onClick: toggleShowManualSignModal,
          option: 'Sign Agreement Manually'
        }
      ]
  return (
    <div>
      <div className={styles.withLayout}>
        <Layout />
        <div className={[styles.container, customClass].join(' ')}>
          <NavBar
            title={
              isProductDetails
                ? 'View Equipment'
                : isEdit && !isUpdate
                ? 'Edit Equipment'
                : 'Create New Equipment'
            }
          />
          {loader ? (
            <Loader />
          ) : (
            <React.Fragment>
              {show && (
                <ModalComponent handleClose={closeDeleteModal} show={show}>
                  <ConfirmationModal
                    removeIcon
                    handleClose={closeDeleteModal}
                    text={`Delete Equipment`}
                    content={
                      'You cannot undo these changes. Are you sure you want to delete this equipment?'
                    }
                    handleDeleteButton={handleDeletion}
                    isDelete={true}
                    documentId={productId}
                    setHasDocuments={setDocuments}
                    hasCheckedDocuments={hasCheckedDocuments}
                    setHasCheckedDocuments={setHasCheckedDocuments}
                  />
                </ModalComponent>
              )}
              {showRejectionModal && (
                <ModalComponent
                  handleClose={closeRejectionModal}
                  show={showRejectionModal}
                >
                  <ConfirmationModal
                    removeIcon
                    handleClose={closeRejectionModal}
                    text={`Reject Equipment`}
                    submitModal
                    handleSubmit={() => {
                      if (rejctionSubmitting) return
                      setRejctionSubmitting(true)
                      setPreventInitialSubmission(false)
                      formRef.current?.handleSubmit()
                      closeRejectionModal()
                    }}
                    content={
                      'You will not be able to change the status of this equipment later. Are you sure you want to reject this equipment?'
                    }
                  />
                </ModalComponent>
              )}
              {showRecallModal && (
                <RecallModal
                  agreement={agreementDocument}
                  agreementType={Collection.ZohoSignListingAgreement}
                  handleClose={() => setShowRecallModal(false)}
                  show={showRecallModal}
                />
              )}
              {showConsigneeModal && (
                <ModalComponent
                  show={showConsigneeModal.show}
                  handleClose={handleCloseConsigneeModal}
                >
                  <SelectConsigneeModal
                    handleClose={handleCloseConsigneeModal}
                    adminsList={adminsList}
                    productID={productId}
                    sign={showConsigneeModal.method}
                    isMSA={false}
                  />
                </ModalComponent>
              )}
              <div className={styles.buttons}>
                <span
                  onClick={() => {
                    navigate('/equipment', {
                      state: {page: previousPage}
                    })
                    setFilesCount({attachments: 0, media: 0})
                    setAssetsLoaded(false)
                  }}
                >
                  <ArrowDown />
                  Back
                </span>
                <div className={styles.twobtn}>
                  {showLAButton && (
                    <ButtonDropDown
                      buttonText="Send Listing Agreement"
                      customButtonStlyes={(styles.changes_btn, styles.LAbtn)}
                      customOptionsContainerStyles={styles.dropdownOptions}
                      selectOptions={listingAgreementOptions}
                      disabled={disableLAButton}
                    />
                  )}
                  {isEdit && !isUpdate ? (
                    <Button
                      text="Delete"
                      customClass={styles.changes_btn}
                      onClick={displayDeleteModal}
                    />
                  ) : null}
                  {disabled && (
                    <Button
                      text="Edit"
                      customClass={styles.changes_btn}
                      onClick={() => setDisabled(false)}
                      disabled={submitting}
                    />
                  )}
                  {(!isEdit || !disabled) && (
                    <Button
                      text={isEdit ? 'Save' : 'Next'}
                      customClass={styles.changes_btn}
                      type={isEdit ? 'submit' : 'button'}
                      onClick={() => formRef.current?.handleSubmit()}
                      disabled={submitting}
                    />
                  )}
                </div>
              </div>
              <div>
                {isProductDetails ? (
                  <div className={styles.detailPageWrapper}>
                    <DetailPage
                      data={{
                        ...productData,
                        agreementStatus: agreementDocument?.status
                      }}
                      detailType={DetailType.Product}
                    />
                  </div>
                ) : (
                  <div ref={scrollRef} className={styles.edit_section}>
                    <Formik
                      innerRef={formRef}
                      initialValues={formInitialState as IProductFormState}
                      validationSchema={formSchema}
                      onSubmit={handleSubmitForm}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        isValid,
                        isValidating,
                        isSubmitting,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        setFieldValue,
                        setFieldError,
                        setFieldTouched
                      }) => {
                        return (
                          <React.Fragment>
                            {isEdit && (
                              <MessageInformation
                                touched={touched}
                                errors={errors}
                              />
                            )}
                            <form
                              className={styles.from_submiting}
                              onSubmit={handleSubmit}
                            >
                              <div className={styles.formContainer}>
                                {!isEdit ? (
                                  <div className={styles.inputs}>
                                    <div className={styles.inputContainer}>
                                      <div className={styles.inputField}>
                                        <div className={styles.dropdown}>
                                          <div>
                                            <DropDownSelect
                                              firstIcon={<Building />}
                                              options={LISTINGTYPE_OPTIONS}
                                              customClass={classNames(
                                                styles.dropdowninput,
                                                styles.dropdownblock
                                              )}
                                              value={LISTINGTYPE_OPTIONS.findIndex(
                                                (option) =>
                                                  option ===
                                                  get(values, 'listingType')
                                              )}
                                              placeholder="Listing Type"
                                              cancelClickFunction={() => {
                                                setFieldValue('listingType', '')
                                              }}
                                              onChange={(index) => {
                                                const value =
                                                  LISTINGTYPE_OPTIONS[index]
                                                setFieldValue(
                                                  'listingType',
                                                  value
                                                )
                                              }}
                                              errors={errors}
                                              touched={touched}
                                              disabled={disabled}
                                              onBlur={handleBlur}
                                              name="listingType"
                                            />
                                            <div
                                              className={classNames(
                                                styles.disblock,
                                                styles.disnone
                                              )}
                                            >
                                              ____
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className={styles.inputField}></div>
                                    </div>

                                    <div className={styles.inputContainer}>
                                      <div className={styles.inputField}>
                                        <div className={styles.dropdown}>
                                          <div>
                                            <DropDownSelect
                                              firstIcon={<Building />}
                                              options={accountOptionsList}
                                              customClass={classNames(
                                                styles.dropdowninput,
                                                styles.dropdownblock
                                              )}
                                              placeholder="Account"
                                              value={accountOptionsList.findIndex(
                                                (option) =>
                                                  option.id ===
                                                  get(values, 'accountId')
                                              )}
                                              onChange={(index) =>
                                                setFilteredContacts(
                                                  index,
                                                  setFieldValue,
                                                  setFieldError,
                                                  setFieldTouched
                                                )
                                              }
                                              cancelClickFunction={() => {
                                                setFieldValue('accountId', '')
                                                setFieldValue('accountName', '')
                                                setFieldValue('contactId', '')
                                                setFieldValue('contactName', '')
                                                setContactOptionsList([])
                                              }}
                                              errors={errors}
                                              touched={touched}
                                              disabled={disabled}
                                              onBlur={handleBlur}
                                              name="accountId"
                                            />
                                            <div
                                              className={classNames(
                                                styles.disblock,
                                                styles.disnone
                                              )}
                                            >
                                              ____
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className={styles.inputField}>
                                        <div className={styles.dropdown}>
                                          <div>
                                            <DropDownSelect
                                              firstIcon={<Building />}
                                              options={contactOptionsList}
                                              customClass={classNames(
                                                styles.dropdowninput,
                                                styles.dropdownblock
                                              )}
                                              placeholder="Contact"
                                              value={contactOptionsList.findIndex(
                                                (option) =>
                                                  option.id ===
                                                  get(values, 'contactId')
                                              )}
                                              cancelClickFunction={() => {
                                                setFieldValue('contactId', '')
                                                setFieldValue('contactName', '')
                                              }}
                                              onChange={(index) => {
                                                const contact =
                                                  contactOptionsList[index]
                                                const contactID = contact.id
                                                const contactName = contact.name
                                                setFieldValue(
                                                  'contactId',
                                                  contactID
                                                )
                                                setFieldValue(
                                                  'contactName',
                                                  contactName
                                                )
                                                setFieldValue(
                                                  'sellerPhotoUrl',
                                                  contact.photoURL
                                                )
                                              }}
                                              errors={errors}
                                              touched={touched}
                                              disabled={disabled}
                                              onBlur={handleBlur}
                                              name="contactId"
                                            />
                                            <div
                                              className={classNames(
                                                styles.disblock,
                                                styles.disnone
                                              )}
                                            >
                                              ____
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  <React.Fragment>
                                    <div className={styles.formHeading}>
                                      Equipment Status
                                    </div>
                                    <div className={styles.inputs}>
                                      <div className={styles.inputContainer}>
                                        <div className={styles.inputField}>
                                          <div className={styles.dropdown}>
                                            <div>
                                              <DropDownSelect
                                                firstIcon={<Building />}
                                                options={statusDropdownOptions}
                                                customClass={classNames(
                                                  styles.dropdowninput,
                                                  styles.dropdownblock
                                                )}
                                                placeholder="Status"
                                                value={statusDropdownOptions.findIndex(
                                                  (option: string) =>
                                                    option ===
                                                    get(values, 'status')
                                                )}
                                                cancelClickFunction={() => {
                                                  setFieldValue(
                                                    'status',
                                                    productStatus.Pending
                                                  )
                                                }}
                                                onChange={(index) => {
                                                  const value =
                                                    statusDropdownOptions[index]
                                                  setFieldValue('status', value)
                                                }}
                                                errors={errors}
                                                touched={touched}
                                                disabled={disabled}
                                                onBlur={handleBlur}
                                                name="status"
                                              />
                                              <div
                                                className={classNames(
                                                  styles.disblock,
                                                  styles.disnone
                                                )}
                                              >
                                                ____
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          className={styles.inputField}
                                        ></div>
                                      </div>
                                    </div>
                                    <div className={styles.withCheckbox}>
                                      <div className={styles.containerCheck}>
                                        <div className={styles.formHeading}>
                                          Auto Renewal
                                        </div>
                                        <div className={styles.inputCheckbox}>
                                          <div className={styles.inputField}>
                                            <InputField
                                              placeholder="Yes"
                                              inputFieldIcon={
                                                get(
                                                  values,
                                                  'contractInfo.autoRenew'
                                                )
                                                  ? CheckedIcon
                                                  : UncheckedIcon
                                              }
                                              iconClick={() => {
                                                setFieldValue(
                                                  'contractInfo.autoRenew',
                                                  true
                                                )
                                              }}
                                            />
                                          </div>
                                          <div className={styles.inputField}>
                                            <InputField
                                              placeholder="No"
                                              inputFieldIcon={
                                                !get(
                                                  values,
                                                  'contractInfo.autoRenew'
                                                )
                                                  ? CheckedIcon
                                                  : UncheckedIcon
                                              }
                                              iconClick={() => {
                                                setFieldValue(
                                                  'contractInfo.autoRenew',
                                                  false
                                                )
                                              }}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className={styles.containerCheck}>
                                        <div className={styles.formHeading}>
                                          Is Featured Listing
                                        </div>
                                        <div className={styles.inputCheckbox}>
                                          <div className={styles.inputField}>
                                            <InputField
                                              placeholder="Yes"
                                              inputFieldIcon={
                                                get(values, 'isFeaturedListing')
                                                  ? CheckedIcon
                                                  : UncheckedIcon
                                              }
                                              iconClick={() => {
                                                setFieldValue(
                                                  'isFeaturedListing',
                                                  true
                                                )
                                              }}
                                            />
                                          </div>
                                          <div className={styles.inputField}>
                                            <InputField
                                              placeholder="No"
                                              inputFieldIcon={
                                                !get(
                                                  values,
                                                  'isFeaturedListing'
                                                )
                                                  ? CheckedIcon
                                                  : UncheckedIcon
                                              }
                                              iconClick={() => {
                                                setFieldValue(
                                                  'isFeaturedListing',
                                                  false
                                                )
                                              }}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    {!isAuction && (
                                      <div className={styles.inputs}>
                                        <div className={styles.inputContainer}>
                                          <div className={styles.inputField}>
                                            <div className={styles.formHeading}>
                                              Contract Duration
                                            </div>
                                            <InputField
                                              name="contractInfo.contractDuration"
                                              value={get(
                                                values,
                                                'contractInfo.contractDuration'
                                              )}
                                              placeholder="Contract Duration (Days)"
                                              disabled={!hasAdminPrivelages}
                                              onBlur={handleBlur}
                                              onChange={handleChange}
                                              touched={touched}
                                              errors={errors}
                                              type="number"
                                              onKeyDown={(event) =>
                                                [
                                                  'e',
                                                  'E',
                                                  '+',
                                                  '-',
                                                  '.'
                                                ].includes(event.key) &&
                                                event.preventDefault()
                                              }
                                              min={1}
                                              max={999}
                                            />
                                          </div>
                                          <div className={styles.inputField}>
                                            <div className={styles.formHeading}>
                                              Notification Duration
                                            </div>
                                            <InputField
                                              name="contractInfo.renewalWindow"
                                              value={get(
                                                values,
                                                'contractInfo.renewalWindow'
                                              )}
                                              disabled={!hasAdminPrivelages}
                                              onBlur={handleBlur}
                                              onChange={handleChange}
                                              touched={touched}
                                              errors={errors}
                                              placeholder="Notification Duration (Days)"
                                              type="number"
                                              onKeyDown={(event) =>
                                                [
                                                  'e',
                                                  'E',
                                                  '+',
                                                  '-',
                                                  '.'
                                                ].includes(event.key) &&
                                                event.preventDefault()
                                              }
                                              min={1}
                                              max={999}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                    <div className={styles.inputs}>
                                      <div className={styles.inputContainer}>
                                        <DragPdf
                                          values={values}
                                          onlyPdfs={true}
                                          errors={errors}
                                          touched={touched}
                                          productId={productId}
                                          handleBlur={handleBlur}
                                          isUploading={isPDFUploading}
                                          setFieldValue={setFieldValue}
                                          setIsUploading={setIsPDFUploading}
                                          filesCountState={{
                                            filesCount,
                                            initialLoaded: assetsLoaded
                                          }}
                                        />
                                      </div>
                                    </div>

                                    <div className={styles.inputs}>
                                      <div className={styles.inputContainer}>
                                        <UploadFileSection
                                          isUploading={isFileUploading}
                                          setIsUploading={setIsFileUploading}
                                          touched={touched}
                                          errors={errors}
                                          handleBlur={handleBlur}
                                          setFieldValue={setFieldValue}
                                          values={values}
                                          productID={productId}
                                          filesCountState={{
                                            filesCount,
                                            initialLoaded: assetsLoaded
                                          }}
                                        />
                                      </div>
                                    </div>

                                    <ProductDetailSection
                                      values={values}
                                      handleChange={handleChange}
                                      errors={errors}
                                      touched={touched}
                                      handleBlur={handleBlur}
                                      disabled={disabled}
                                      setFieldValue={setFieldValue}
                                    />

                                    <FaqSection
                                      values={values}
                                      handleChange={handleChange}
                                      errors={errors}
                                      touched={touched}
                                      handleBlur={handleBlur}
                                      disabled={disabled}
                                      setFieldValue={setFieldValue}
                                    />
                                  </React.Fragment>
                                )}

                                {get(values, 'listingType') ===
                                  listingType.BuyNow && isEdit ? (
                                  <div className={styles.inputs}>
                                    {isSuperAdmin && (
                                      <div className={styles.inputContainer}>
                                        <div className={styles.withCheckbox}>
                                          <div
                                            className={styles.containerCheck}
                                          >
                                            <div className={styles.formHeading}>
                                              Manual Set Commission
                                            </div>
                                            <div
                                              className={styles.inputCheckbox}
                                            >
                                              <div
                                                className={styles.inputField}
                                              >
                                                <InputField
                                                  placeholder="Yes"
                                                  inputFieldIcon={
                                                    get(
                                                      values,
                                                      'isCommissionSetManually'
                                                    )
                                                      ? CheckedIcon
                                                      : UncheckedIcon
                                                  }
                                                  iconClick={() => {
                                                    setFieldValue(
                                                      'isCommissionSetManually',
                                                      true
                                                    )
                                                  }}
                                                />
                                              </div>
                                              <div
                                                className={styles.inputField}
                                              >
                                                <InputField
                                                  placeholder="No"
                                                  inputFieldIcon={
                                                    !get(
                                                      values,
                                                      'isCommissionSetManually'
                                                    )
                                                      ? CheckedIcon
                                                      : UncheckedIcon
                                                  }
                                                  iconClick={() => {
                                                    setFieldValue(
                                                      'isCommissionSetManually',
                                                      false
                                                    )
                                                    setFieldValue(
                                                      'commissionPercentage',
                                                      product.documentData
                                                        ?.commissionPercentage
                                                    )
                                                    setFieldTouched(
                                                      'commissionPercentage',
                                                      false
                                                    )
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className={styles.withCheckbox}>
                                          <div
                                            className={styles.containerCheck}
                                          >
                                            <div className={styles.formHeading}>
                                              Manual Set Buyer's Premium
                                            </div>
                                            <div
                                              className={styles.inputCheckbox}
                                            >
                                              <div
                                                className={styles.inputField}
                                              >
                                                <InputField
                                                  placeholder="Yes"
                                                  inputFieldIcon={
                                                    get(
                                                      values,
                                                      'isBuyersPremiumSetManually'
                                                    )
                                                      ? CheckedIcon
                                                      : UncheckedIcon
                                                  }
                                                  iconClick={() => {
                                                    setFieldValue(
                                                      'isBuyersPremiumSetManually',
                                                      true
                                                    )
                                                  }}
                                                />
                                              </div>
                                              <div
                                                className={styles.inputField}
                                              >
                                                <InputField
                                                  placeholder="No"
                                                  inputFieldIcon={
                                                    !get(
                                                      values,
                                                      'isBuyersPremiumSetManually'
                                                    )
                                                      ? CheckedIcon
                                                      : UncheckedIcon
                                                  }
                                                  iconClick={() => {
                                                    setFieldValue(
                                                      'isBuyersPremiumSetManually',
                                                      false
                                                    )
                                                    setFieldValue(
                                                      'buyersPremium',
                                                      product.documentData
                                                        ?.buyersPremium
                                                    )
                                                    setFieldTouched(
                                                      'buyersPremium',
                                                      false
                                                    )
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    )}

                                    <div className={styles.inputContainer}>
                                      <div className={styles.inputField}>
                                        <div className={styles.formHeading}>
                                          Set Price
                                        </div>
                                        <CurrencyInputField
                                          name="buyNowPriceMap.buyNowPrice"
                                          value={get(
                                            values,
                                            'buyNowPriceMap.buyNowPrice'
                                          )}
                                          onBlur={handleBlur}
                                          setFieldValue={setFieldValue}
                                          touched={touched}
                                          errors={errors}
                                        />
                                      </div>
                                      <div className={styles.inputField}>
                                        <div className={styles.formHeading}>
                                          Comission Percentage
                                        </div>
                                        <InputField
                                          name="commissionPercentage"
                                          value={get(
                                            values,
                                            'commissionPercentage'
                                          )}
                                          disabled={
                                            !get(
                                              values,
                                              'isCommissionSetManually'
                                            ) || !isSuperAdmin
                                          }
                                          onBlur={handleBlur}
                                          onChange={handleChange}
                                          touched={touched}
                                          errors={errors}
                                          placeholder="Commission Percentage"
                                          type="number"
                                          onKeyDown={(event) =>
                                            ['e', 'E', '+', '-'].includes(
                                              event.key
                                            ) && event.preventDefault()
                                          }
                                          min={0}
                                          max={9999999}
                                        />
                                      </div>
                                      <div className={styles.inputField}>
                                        <div className={styles.formHeading}>
                                          Buyer's Premium
                                        </div>
                                        <InputField
                                          name="buyersPremium"
                                          value={get(values, 'buyersPremium')}
                                          disabled={
                                            !get(
                                              values,
                                              'isBuyersPremiumSetManually'
                                            ) || !isSuperAdmin
                                          }
                                          onBlur={handleBlur}
                                          onChange={handleChange}
                                          touched={touched}
                                          errors={errors}
                                          placeholder="Buyer's Premium"
                                          type="number"
                                          onKeyDown={(event) =>
                                            ['e', 'E', '+', '-'].includes(
                                              event.key
                                            ) && event.preventDefault()
                                          }
                                          min={0}
                                          max={9999999}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  isEdit && (
                                    <React.Fragment>
                                      <AuctionDetail
                                        values={values}
                                        handleChange={handleChange}
                                        errors={errors}
                                        touched={touched}
                                        handleBlur={handleBlur}
                                        disabled={disabled}
                                        setFieldValue={setFieldValue}
                                      />
                                      <div className={styles.inputs}>
                                        <div className={styles.inputContainer}>
                                          <div className={styles.inputField}>
                                            <div className={styles.formHeading}>
                                              Contract Duration
                                            </div>
                                            <InputField
                                              name="contractInfo.contractDuration"
                                              value={get(
                                                values,
                                                'contractInfo.contractDuration'
                                              )}
                                              disabled={!hasAdminPrivelages}
                                              onBlur={handleBlur}
                                              onChange={handleChange}
                                              touched={touched}
                                              errors={errors}
                                              placeholder="Contract Duration (Days)"
                                              type="number"
                                              onKeyDown={(event) =>
                                                [
                                                  'e',
                                                  'E',
                                                  '+',
                                                  '-',
                                                  '.'
                                                ].includes(event.key) &&
                                                event.preventDefault()
                                              }
                                              min={1}
                                              max={999}
                                            />
                                          </div>
                                          <div className={styles.inputField}>
                                            <div className={styles.formHeading}>
                                              Notification Duration
                                            </div>
                                            <InputField
                                              name="contractInfo.renewalWindow"
                                              value={get(
                                                values,
                                                'contractInfo.renewalWindow'
                                              )}
                                              disabled={!hasAdminPrivelages}
                                              onBlur={handleBlur}
                                              onChange={handleChange}
                                              touched={touched}
                                              errors={errors}
                                              placeholder="Notification Duration (Days)"
                                              type="number"
                                              onKeyDown={(event) =>
                                                [
                                                  'e',
                                                  'E',
                                                  '+',
                                                  '-',
                                                  '.'
                                                ].includes(event.key) &&
                                                event.preventDefault()
                                              }
                                              min={1}
                                              max={999}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className={styles.inputs}>
                                        <div className={styles.inputContainer}>
                                          <div className={styles.inputField}>
                                            <div className={styles.formHeading}>
                                              Comission Percentage
                                            </div>
                                            <InputField
                                              name="commissionPercentage"
                                              value={get(
                                                values,
                                                'commissionPercentage'
                                              )}
                                              disabled={
                                                !get(
                                                  values,
                                                  'isCommissionSetManually'
                                                ) || !isSuperAdmin
                                              }
                                              onBlur={handleBlur}
                                              onChange={handleChange}
                                              touched={touched}
                                              errors={errors}
                                              placeholder="Commission Percentage"
                                              type="number"
                                              onKeyDown={(event) =>
                                                ['e', 'E', '+', '-'].includes(
                                                  event.key
                                                ) && event.preventDefault()
                                              }
                                              min={0}
                                              max={9999999}
                                            />
                                          </div>
                                          {isSuperAdmin && (
                                            <div className={styles.inputField}>
                                              <div
                                                className={styles.withCheckbox}
                                              >
                                                <div
                                                  className={
                                                    styles.containerCheck
                                                  }
                                                >
                                                  <div
                                                    className={
                                                      styles.formHeading
                                                    }
                                                  >
                                                    Manual Set Commission
                                                  </div>
                                                  <div
                                                    className={
                                                      styles.inputCheckbox
                                                    }
                                                  >
                                                    <div
                                                      className={
                                                        styles.inputField
                                                      }
                                                    >
                                                      <InputField
                                                        placeholder="Yes"
                                                        inputFieldIcon={
                                                          get(
                                                            values,
                                                            'isCommissionSetManually'
                                                          )
                                                            ? CheckedIcon
                                                            : UncheckedIcon
                                                        }
                                                        iconClick={() => {
                                                          setFieldValue(
                                                            'isCommissionSetManually',
                                                            true
                                                          )
                                                        }}
                                                      />
                                                    </div>
                                                    <div
                                                      className={
                                                        styles.inputField
                                                      }
                                                    >
                                                      <InputField
                                                        placeholder="No"
                                                        inputFieldIcon={
                                                          !get(
                                                            values,
                                                            'isCommissionSetManually'
                                                          )
                                                            ? CheckedIcon
                                                            : UncheckedIcon
                                                        }
                                                        iconClick={() => {
                                                          setFieldValue(
                                                            'isCommissionSetManually',
                                                            false
                                                          )
                                                          setFieldValue(
                                                            'commissionPercentage',
                                                            product.documentData
                                                              ?.commissionPercentage
                                                          )
                                                          setFieldTouched(
                                                            'commissionPercentage',
                                                            false
                                                          )
                                                        }}
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                      <div className={styles.inputs}>
                                        <div className={styles.inputContainer}>
                                          <div className={styles.inputField}>
                                            <div className={styles.formHeading}>
                                              Buyer's Premium
                                            </div>
                                            <InputField
                                              name="buyersPremium"
                                              value={get(
                                                values,
                                                'buyersPremium'
                                              )}
                                              disabled={
                                                !get(
                                                  values,
                                                  'isBuyersPremiumSetManually'
                                                ) || !isSuperAdmin
                                              }
                                              onBlur={handleBlur}
                                              onChange={handleChange}
                                              touched={touched}
                                              errors={errors}
                                              placeholder="Buyer's Premium"
                                              type="number"
                                              onKeyDown={(event) =>
                                                ['e', 'E', '+', '-'].includes(
                                                  event.key
                                                ) && event.preventDefault()
                                              }
                                              min={0}
                                              max={9999999}
                                            />
                                          </div>
                                          {isSuperAdmin && (
                                            <div className={styles.inputField}>
                                              <div
                                                className={styles.withCheckbox}
                                              >
                                                <div
                                                  className={
                                                    styles.containerCheck
                                                  }
                                                >
                                                  <div
                                                    className={
                                                      styles.formHeading
                                                    }
                                                  >
                                                    Manual Set Buyer's Premium
                                                  </div>
                                                  <div
                                                    className={
                                                      styles.inputCheckbox
                                                    }
                                                  >
                                                    <div
                                                      className={
                                                        styles.inputField
                                                      }
                                                    >
                                                      <InputField
                                                        placeholder="Yes"
                                                        inputFieldIcon={
                                                          get(
                                                            values,
                                                            'isBuyersPremiumSetManually'
                                                          )
                                                            ? CheckedIcon
                                                            : UncheckedIcon
                                                        }
                                                        iconClick={() => {
                                                          setFieldValue(
                                                            'isBuyersPremiumSetManually',
                                                            true
                                                          )
                                                        }}
                                                      />
                                                    </div>
                                                    <div
                                                      className={
                                                        styles.inputField
                                                      }
                                                    >
                                                      <InputField
                                                        placeholder="No"
                                                        inputFieldIcon={
                                                          !get(
                                                            values,
                                                            'isBuyersPremiumSetManually'
                                                          )
                                                            ? CheckedIcon
                                                            : UncheckedIcon
                                                        }
                                                        iconClick={() => {
                                                          setFieldValue(
                                                            'isBuyersPremiumSetManually',
                                                            false
                                                          )
                                                          setFieldValue(
                                                            'buyersPremium',
                                                            product.documentData
                                                              ?.buyersPremium
                                                          )
                                                          setFieldTouched(
                                                            'buyersPremium',
                                                            false
                                                          )
                                                        }}
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </React.Fragment>
                                  )
                                )}
                              </div>
                            </form>
                            <FormErrorNotification
                              errors={errors}
                              scrollToRef={scrollToRef}
                              isValid={isValid}
                              isValidating={isValidating}
                              isSubmitting={isSubmitting}
                            />
                          </React.Fragment>
                        )
                      }}
                    </Formik>
                  </div>
                )}
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  )
}

export default withAuthorization(ProductDetailPage)
