import {AssetReportDocument} from 'common'
import React from 'react'
import styles from '../DetailPage.module.scss'
import ListItem from './ListItem'
import classNames from 'classnames'

const EquipmentReportDetails = (data: AssetReportDocument) => {
  const {assetReports} = data

  return (
    <React.Fragment>
      <div className={classNames(styles.inputsfields, styles.reportFields)}>
        {assetReports?.map(({title, url}, index) => (
          <ListItem key={`report-${title}-${index}`} title={title} url={url} />
        ))}
      </div>
    </React.Fragment>
  )
}

export default EquipmentReportDetails
