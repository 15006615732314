import {get} from 'lodash'
import React from 'react'
import styles from './TextArea.module.scss'
import {removeEmojis} from 'components/utils'

interface TextAreaProps {
  label?: string
  value: string
  handleBlur?: React.FocusEventHandler<HTMLTextAreaElement> | undefined
  handleChange?: React.ChangeEventHandler<HTMLTextAreaElement> | undefined
  maxLength?: number
  touched?: {} | undefined
  errors?: {} | undefined
  name?: string
  placeholder?: string
  disabled?: boolean
  customDivClass?: string
  customTextAreaClass?: string
  noCounter?: boolean
  acceptEmoji?: boolean
  cols?: number
  rows?: number
}

const TextArea = ({
  label,
  value,
  handleBlur,
  handleChange,
  maxLength,
  touched,
  errors,
  name = '',
  placeholder,
  disabled,
  customDivClass,
  acceptEmoji = true,
  customTextAreaClass,
  noCounter,
  cols = 30,
  rows = 10
}: TextAreaProps) => {
  const hasError = get(touched, name) || ''
  const textAreaError = hasError && get(errors, name)
  return (
    <div className={customDivClass ? customDivClass : styles.text_area_label}>
      <label htmlFor={name}>{label}</label>
      <textarea
        className={customTextAreaClass}
        name={name}
        id={name}
        value={maxLength ? value?.slice(0, maxLength) : value}
        onBlur={handleBlur}
        onChange={(e) => {
          if (!acceptEmoji) {
            e.target.value = removeEmojis(e.target.value)
          }
          handleChange?.(e)
        }}
        placeholder={placeholder}
        cols={cols}
        rows={rows}
        maxLength={maxLength}
        disabled={disabled}
      />
      <div className={styles.descriptionErrorContainer}>
        <div>
          {!noCounter && (
            <p>
              {value?.length > (maxLength || 500)
                ? maxLength?.toString()
                : value?.length || 0}
              /{maxLength} remaining characters
            </p>
          )}
        </div>
        <span className={styles.error}>{textAreaError}</span>
      </div>
    </div>
  )
}

export default TextArea
