import styles from '../InboxOfferReceiver/InboxOfferReceiver.module.scss'
import {Triangle} from '../../../assets/svgs'
import {getFormattedDate} from '../../utils'
import {RequestForInspection} from 'common'
import {inspectionStatus} from 'common/enums'
import UserProfilePicture from 'components/UserProfileImage'

interface RequestForInspectionReceiverProps {
  item: RequestForInspection
  buyerContactId: string
}

const RequestForInspectionReceiver = ({
  item,
  buyerContactId
}: RequestForInspectionReceiverProps) => {
  const offerDate = getFormattedDate(item?.inspectionDate)

  const renderOptions = () => {
    switch (item?.inspectionStatus) {
      case inspectionStatus.DONE:
        return (
          <div className={styles['option-selected']}>
            <span>Request for Inspection Accepted</span>
          </div>
        )
      case inspectionStatus.REJECTED:
        return (
          <div className={styles['option-selected']}>
            <span>Request for Inspection Rejected</span>
          </div>
        )
      case inspectionStatus.PENDING:
        return (
          <div className={styles['option-selected']}>
            <span>Buyer has Requested for Inspection Location</span>
          </div>
        )

      default:
        return null
    }
  }
  return (
    <div className={styles.container}>
      <div className={styles.user_img}>
        <UserProfilePicture alt="img-user-offer" userId={buyerContactId} />
      </div>
      <div className={styles.content}>
        <div
          style={{alignItems: 'flex-start'}}
          className={styles.img_container}
        >
          <img alt="img-product-offer" src={item?.thumbnail} />
          <div style={{marginTop: '1rem'}} className={styles.info_headings}>
            <p>{item?.productTitle}</p>
            <h3>
              Date: <b> {offerDate}</b>
            </h3>
          </div>
        </div>
        <h5>Message:</h5>
        <p>{item?.message} </p>
        {renderOptions()}
        <Triangle className={styles.msg_icon} />
      </div>
    </div>
  )
}

export default RequestForInspectionReceiver
