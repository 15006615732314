import * as Yup from 'yup'
import phone from 'phone'

export const CreateAdminSchema = Yup.object({
  firstName: Yup.string()
    .matches(/^[A-Za-z]+(?:[ ]?[A-Za-z]+)*$/, 'Invalid First Name!')
    .min(3, 'First Name must be at least 3 characters')
    .required('Required'),
  lastName: Yup.string()
    .matches(/^[A-Za-z]+(?:[ ]?[A-Za-z]+)*$/, 'Invalid Last Name!')
    .min(3, 'Last Name must be at least 3 characters')
    .required('Required'),
  mobileNumber: Yup.string()
    .test(
      'invalid_number',
      'Invalid Number',
      (value: string | undefined | null) => {
        if (value !== undefined || value !== null) {
          return phone(value!, {validateMobilePrefix: true}).isValid
        }
        return true
      }
    )
    .required('Required'),
  emailAddress: Yup.string()
    .matches(/^[^$%`~|\/]+$/, 'Invalid Email')
    .email('Invalid Email')
    .required('Required'),
  role: Yup.string().required('Required')
})
