import {Cancel} from 'assets/svgs'
import classNames from 'classnames'
import {ExemptRegions} from 'common'
import {ExemptionType} from 'common/enums'
import Button from 'components/Button'
import DropDownSelect from 'components/DropDown'
import {useFirebase} from 'components/Firebase/hooks'
import ModalComponent from 'components/ModalComponent'
import {
  filteredCountries,
  getCountryIsoCode,
  getCountryNameByCode,
  getStateNameByCode,
  getStatesOfCountryWithTwoLetterIso,
  memoizeFunc
} from 'components/utils'
import {ICountry, IState} from 'country-state-city'
import {useFormik} from 'formik'
import _, {replace, startCase} from 'lodash'
import {useState} from 'react'
import {toast} from 'react-toastify'
import styles from './ExemptionRegionsAndTypeModal.module.scss'

const ExemptionRegionsAndTypeModal = ({
  show,
  contactId,
  handleClose,
  exemptRegions,
  exemptionType
}: {
  exemptionType?: ExemptionType
  exemptRegions?: Array<ExemptRegions>
  handleClose: () => void
  contactId: string
  show: boolean
}) => {
  const [disabled, setDisabled] = useState(false)
  const {updateExemptionTypeAndRegions} = useFirebase()

  const handleSubmit = async (values: {
    exemptionType?: ExemptionType
    regions?: Array<ExemptRegions>
    country?: string
    state?: string
  }) => {
    if (disabled) return
    setDisabled(true)
    const {message, success} = await updateExemptionTypeAndRegions({
      userId: contactId,
      exemptionType: values.exemptionType,
      exemptRegions: values.regions
    })
    success ? toast.success(message) : toast.error(message)
    setDisabled(false)
    handleClose()
    return
  }

  const {
    values,
    handleSubmit: onSubmit,
    handleBlur,
    touched,
    setFieldValue
  } = useFormik({
    initialValues: {
      regions: exemptRegions || [],
      exemptionType: exemptionType || ExemptionType.NonExempt,
      country: '',
      state: ''
    },
    onSubmit: handleSubmit
  })

  const countryValue = filteredCountries?.findIndex(
    (country: ICountry) => country.isoCode === values.country
  )
  const memoizedStatesOfCountry = memoizeFunc(
    getStatesOfCountryWithTwoLetterIso
  )
  const state = memoizedStatesOfCountry(getCountryIsoCode(values.country)) || []
  const exemptionTypeOptions = Object.values(ExemptionType).map((etype) => ({
    name: startCase(replace(etype || ExemptionType.NonExempt, /_/g, ' ')),
    value: etype
  }))
  const exemptionTypeValue = exemptionTypeOptions.findIndex(
    (type) => type.value === values.exemptionType
  )

  const handleAdd = () => {
    const {country, state} = values
    if (!country) {
      toast.warn('Please select a country')
      return
    }
    if (!state) {
      toast.warn('Please select a state')
      return
    }
    const alreadyExists = values.regions.some(
      (region) => region.country === country && region.state === state
    )
    if (alreadyExists) {
      toast.warn('Selected region is already in list')
      return
    }
    setFieldValue('regions', [
      ...values?.regions,
      {country: values.country, state: values.state}
    ])
  }

  const handleRemove = (country?: string, state?: string) => {
    const updatedRegions = _.filter(
      values?.regions,
      _.negate((region) => region.country === country && region.state === state)
    )
    setFieldValue('regions', updatedRegions)
  }

  return (
    <ModalComponent handleClose={handleClose} show={show}>
      <div className={styles.container}>
        <form onSubmit={onSubmit} className={styles.content}>
          <div className={styles.heading}>
            <Cancel className={styles.cancel} onClick={handleClose} />
            <div className={styles.infoContainer}></div>
          </div>
          <h1>Exemption Type</h1>
          <div className={styles.dropdowns}>
            <DropDownSelect
              name="exemptionType"
              placeholder="Exemption Type"
              value={exemptionTypeValue}
              onBlur={handleBlur}
              touched={touched}
              customClass={styles.exemptionTypeDropdown}
              options={exemptionTypeOptions}
              onChange={(index) =>
                setFieldValue(
                  'exemptionType',
                  exemptionTypeOptions[index].value
                )
              }
            />
          </div>
          <h1>Exempt Regions</h1>
          <div className={styles.dropdowns}>
            <DropDownSelect
              name="country"
              mode="country"
              options={filteredCountries}
              placeholder="Country"
              onBlur={handleBlur}
              touched={touched}
              value={countryValue}
              cancelClickFunction={() => {
                setFieldValue('country', '')
                setFieldValue('state', '')
              }}
              onChange={(index) => {
                setFieldValue('country', filteredCountries[index].isoCode)
                setFieldValue('state', '')
              }}
              disabled={disabled}
            />
            <DropDownSelect
              name="state"
              mode="state"
              options={state}
              disabled={disabled}
              onBlur={handleBlur}
              touched={touched}
              placeholder="State"
              value={state?.findIndex(
                (state: IState) => state.isoCode === values.state
              )}
              cancelClickFunction={() => {
                setFieldValue('state', '')
              }}
              onChange={(index) => {
                setFieldValue('state', state[index].isoCode)
              }}
            />
            <Button
              text="Add"
              type="button"
              onClick={handleAdd}
              customClass={styles.btnAdd}
            />
          </div>
          {values?.regions.map(({country, state}, index) => (
            <div
              key={`region-${country}-${state}-${index}`}
              className={styles.regions}
            >
              <div>
                <b>Country: </b>
                {country ? getCountryNameByCode(country) : country}
              </div>
              <div>
                <b>State: </b>
                {country && state ? getStateNameByCode(state, country) : ''}
              </div>
              <button
                type="button"
                onClick={() => handleRemove(country, state)}
                className={classNames('btn-fill-red', styles.removeBtn)}
              >
                Remove
              </button>
            </div>
          ))}
          <div className={styles.buttons}>
            <button
              type="button"
              className="btn-fill-grey"
              onClick={handleClose}
            >
              Cancel
            </button>
            <Button
              type="submit"
              text="Confirm"
              disabled={disabled}
              customBtnClass="btn-fill-orange"
            />
          </div>
        </form>
      </div>
    </ModalComponent>
  )
}

export default ExemptionRegionsAndTypeModal
