import {useFirebase} from 'components/Firebase/hooks'
import {IFormattedAccount} from 'components/types'
import React, {useEffect, useState} from 'react'
import {FieldSet} from '../DetailFields'
import styles from '../DetailPage.module.scss'

const AccountDetails = (data: IFormattedAccount) => {
  const [sector, setSector] = useState('-')
  const {getSingleSector} = useFirebase()

  useEffect(() => {
    if (!data?.sector) return
    getSingleSector(data.sector).then(({data}) =>
      data?.name ? setSector(data.name) : null
    )
  }, [data?.sector])

  const accountFields = [
    {label: 'Account ID', value: data?.accountId},
    {label: 'Account Name', value: data?.accountName},
    {label: 'Description', value: data?.descriptionInfo},
    {label: 'Parent Account', value: data?.parentAccountId},
    {label: 'Sector', value: sector},
    {label: 'Account Type', value: data?.accountType}
  ]

  const accountInfoFields = [
    {label: 'Website URL', value: data?.websiteURL},
    {label: 'Company Phone Number', value: data?.phoneNumber},
    {label: 'Email', value: data?.email}
  ]

  const mailingAddressFields = [
    {label: 'Street Address', value: data?.shippingStreetAddress},
    {label: 'Street Address 2', value: data?.shippingStreetAddress2},
    {label: 'Country', value: data?.shippingCountry},
    {label: 'State', value: data?.shippingState},
    {label: 'City', value: data?.shippingCity},
    {label: 'Zip Code', value: data?.shippingZipCode}
  ]

  const physicalAddressFields = [
    {label: 'Street Address', value: data?.billingStreetAddress},
    {label: 'Street Address 2', value: data?.billingStreetAddress2},
    {label: 'Country', value: data?.billingCountry},
    {label: 'State', value: data?.billingState},
    {label: 'City', value: data?.billingCity},
    {label: 'Zip Code', value: data?.billingZipCode}
  ]

  return (
    <React.Fragment>
      <div className={styles.inputsfields}>
        <FieldSet title="Account Details" fields={accountFields} />
        <FieldSet gap title="Account Information" fields={accountInfoFields} />
        <FieldSet gap title="Mailing Address" fields={mailingAddressFields} />
      </div>
      <div className={styles.inputsfields}>
        <FieldSet title="Physical Address" fields={physicalAddressFields} />
      </div>
    </React.Fragment>
  )
}

export default AccountDetails
