import {Cancel, Cancel_red, PinPdf} from 'assets/svgs'
import classNames from 'classnames'
import {FILE_OBJECT, InvoiceDocument} from 'common'
import Button from 'components/Button'
import {useFirebase} from 'components/Firebase/hooks'
import CurrencyInputField from 'components/InputField/CurrencyInputField'
import TextArea from 'components/TextArea'
import {addBuyerWire} from 'components/apis/invoice'
import {
  getFileInformation,
  getFormattedDate,
  getParsedFileNameForUploadingFile,
  isCorrectURI,
  removeThousandSeperatorString,
  withBuyersPremium,
  withThousandSeperatorString
} from 'components/utils'
import React, {useRef, useState} from 'react'
import {toast} from 'react-toastify'
import dragPdfStyles from './DragStyles.module.scss'
import styles from './UploadBuyerWireModal.module.scss'
import _ from 'lodash'

interface UploadBuyerWireModalProps {
  handleClose: () => void
  invoice: InvoiceDocument
}

const UploadBuyerWireModal = ({
  handleClose,
  invoice
}: UploadBuyerWireModalProps) => {
  enum radioOptions {
    OTHER = 'other',
    WIRETRANSFER = 'wiretransfer'
  }
  const {salePrice, productThumbnail, productTitle, createdAt, isFinancing} =
    invoice
  const [fileArr, setFileArr] = useState<Array<FILE_OBJECT>>([])
  const [descriptionValue, setDescriptionValue] = useState('')
  const [amountPaid, setAmountPaid] = useState<string | undefined>()
  const {uploadFile, deleteFile} = useFirebase()
  const [progress, setProgress] = useState<number>(100)
  const [disabled, setDisabled] = useState(false)
  const [removeDisabled, setRemoveDisabled] = useState(false)
  const [dragOver, setDragOver] = useState(false)
  const formRef = useRef<HTMLFormElement>(null)
  const fileInputRef = useRef<HTMLInputElement>(null)
  const [selectedOption, setSelectedOption] = useState<radioOptions>(
    radioOptions.WIRETRANSFER
  )

  const handleDelete = (index: number) => {
    const fileArray = [...fileArr]
    fileArray.splice(index, 1)
    setFileArr(() => [...fileArray])
    toast.success('File Deleted Successfully')
  }

  const handleRemove = async (file: FILE_OBJECT, index: number) => {
    if (removeDisabled) return
    setRemoveDisabled(true)
    await deleteFile({
      filePath: `invoice/${invoice.invoiceId}/buyerWireConfirmations/${file?.name}`
    })
    handleDelete(index)
    setRemoveDisabled(false)
  }

  const handleDragEnter = () => {
    setDragOver(true)
  }

  const handleDragLeave = () => {
    setDragOver(false)
  }

  const handleUpload = async (
    currentFile: File,
    type: string,
    fileName: string,
    bytesToMegaBytes: number
  ) => {
    const {success} = await uploadFile({
      file: currentFile,
      onFileUpload: (downloadURL?: string) => {
        const file = {
          type,
          name: fileName,
          mbSize: bytesToMegaBytes,
          downloadURL: downloadURL || '',
          originalName: currentFile.name,
          URL: URL.createObjectURL(currentFile)
        }
        setFileArr([file])
        toast.success('File Uploaded Successfully')
        return
      },
      onProgressUpdate: setProgress,
      filePath: `invoice/${invoice.invoiceId}/buyerWireConfirmations/${fileName}`
    })
    if (!success) {
      toast.warn('Something went wrong while uploading the receipt')
    }
  }

  const uploadFileValidations = (file: File) => {
    const {name, sizeInMB, isImage, isPDF, type} = getFileInformation(
      fileArr,
      file
    )
    if (!isCorrectURI(name)) {
      toast.warn(
        'Please rename your file appropriately. The file name contains invalid characters.'
      )
      return
    }
    const fileName = getParsedFileNameForUploadingFile(name)
    if (isImage || isPDF) {
      if (sizeInMB > 10) {
        toast.warning('Uploaded file should not exceed 10MB')
        return
      }
      handleUpload(file, type, fileName, sizeInMB)
      return
    }
    toast.warn('Only Image & PDF format are supported')
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.stopPropagation()
    e.preventDefault()
    if (selectedOption === radioOptions.OTHER && descriptionValue.length < 5) {
      toast.warn('The description must be at least 5 characters')
      return
    }
    if (
      descriptionValue?.length ||
      selectedOption === radioOptions.WIRETRANSFER
    ) {
      if (!amountPaid) {
        toast.warn('Please enter wire amount paid')
        return
      }
      setDisabled(true)
      const wireConfirmationObj = {
        invoiceId: invoice.invoiceId || '',
        buyerWireAttachment: fileArr?.[0]?.downloadURL || '',
        buyerWireAmountPaid: Number(amountPaid),
        buyerWireDescription:
          selectedOption === radioOptions.OTHER
            ? descriptionValue
            : 'Wire Transfer'
      }
      const {data} = await addBuyerWire(wireConfirmationObj)
      if (data?.code !== 200) {
        toast.warn(data.msg)
        setDisabled(false)
        return
      }
      toast.success('Payment confirmation uploaded successfully')
      handleClose()
    } else {
      toast.warn(
        `Please provide wire description if you haven't checked 'I've paid my due amount via: "Wire Transfer".'`
      )
      return
    }
  }

  const offerAmount = withThousandSeperatorString(salePrice)
  const totalAmount = withThousandSeperatorString(
    withBuyersPremium(salePrice, invoice?.buyersPremium)
  )

  const totalAmountWithoutDeposit = withThousandSeperatorString(
    isFinancing
      ? withBuyersPremium(salePrice, invoice?.buyersPremium)
      : _.subtract(
          Number(withBuyersPremium(salePrice, invoice?.buyersPremium)),
          2500
        )
  )

  const buyersPremiumValue = withThousandSeperatorString(
    `${
      removeThousandSeperatorString(totalAmount) -
      removeThousandSeperatorString(`${salePrice}`)
    }`
  )

  return (
    <div className={styles.container}>
      <form ref={formRef} onSubmit={handleSubmit} className={styles.content}>
        <div className={styles.heading}>
          <Cancel onClick={handleClose} />
          <h1>Buyer Payment Confirmation</h1>
        </div>
        <div className={styles.container_img}>
          {productThumbnail && <img alt="product-img" src={productThumbnail} />}
          <div className={styles.info_content}>
            <h5 className="with-ellipsis--single-line">{productTitle}</h5>
            <h4>
              <b>Date:</b> {getFormattedDate(createdAt) || ''}{' '}
            </h4>
            <div className={styles.info_content_inner_contianer}>
              <div>
                <div className={styles.info_content_offer}>Offer Amount</div>
                <div className={styles.info_content_offer_price}>
                  {offerAmount}
                </div>
              </div>
              <div>
                <div className={styles.info_content_offer}>
                  Buyer&apos;s Premium
                </div>
                <div className={styles.info_content_offer_price}>
                  {buyersPremiumValue}
                </div>
              </div>
              <div className={styles.totalAmount}>
                <div
                  className={classNames(
                    styles.info_content_offer,
                    styles.priceText
                  )}
                >
                  Total Amount
                </div>
                <div
                  className={classNames(
                    styles.info_content_offer_price,
                    styles.priceText
                  )}
                >
                  {totalAmountWithoutDeposit}
                </div>
              </div>
            </div>
          </div>
        </div>
        <h3>Upload Payment Confirmation</h3>
        <div
          className={classNames(styles['upload_section'], {
            [styles['blur']]: dragOver
          })}
          onDragEnter={handleDragEnter}
          onDragOver={(e) => e.preventDefault()}
          onDragLeave={handleDragLeave}
        >
          <p>
            Drag & drop an image or PDF or{' '}
            <button type="button" onClick={() => fileInputRef.current?.click()}>
              Browse
            </button>
          </p>
          <input
            name="files"
            type="file"
            ref={fileInputRef}
            onDropCapture={(e) => {
              e.stopPropagation()
              setDragOver(false)
            }}
            onChange={(e) => {
              if (e?.target?.files) {
                for (var i = 0; i < e.target.files.length; i++) {
                  var imageFile = e.target.files[i]
                  uploadFileValidations(imageFile)
                }
              }
            }}
            onClick={(event) => {
              const target = event.target as HTMLInputElement
              target.value = ''
            }}
          />
        </div>
        {fileArr.length > 0 && <div style={{marginBottom: '10px'}} />}
        {fileArr.map((file, index) => {
          const fileName = file?.originalName
          return progress < 100 ? (
            <div className={dragPdfStyles.file_container_green} key={index}>
              <h4>
                <PinPdf className={dragPdfStyles.pin_icon} /> {fileName}
              </h4>
              <button>Uploading...{progress.toFixed(2)}%</button>
            </div>
          ) : (
            <div className={dragPdfStyles.file_container_red} key={index}>
              <h4>
                <PinPdf />
                {fileName}
              </h4>
              <button
                type="button"
                disabled={removeDisabled}
                onClick={() => handleRemove(file, index)}
              >
                Remove <Cancel_red />
              </button>
            </div>
          )
        })}
        <div className={styles.amountPaidInputWrapper}>
          <CurrencyInputField
            label="Amount Paid"
            name="amountPaid"
            id="amountPaid"
            onChange={(value) => setAmountPaid(value)}
            value={amountPaid}
          />
        </div>
        <div className={styles.checkbox_container}>
          <p>{`I've paid my due amount via:`}</p>
          <div className={styles.radioGroup}>
            <div className={styles.radioOption}>
              <input
                disabled={disabled}
                onChange={() => setSelectedOption(radioOptions.WIRETRANSFER)}
                name={radioOptions.WIRETRANSFER}
                value={radioOptions.WIRETRANSFER}
                checked={selectedOption === radioOptions.WIRETRANSFER}
                type="radio"
              />
              <label htmlFor={radioOptions.WIRETRANSFER}>Wire transfer</label>
            </div>
            <div className={styles.radioOption}>
              <input
                disabled={disabled}
                onChange={() => setSelectedOption(radioOptions.OTHER)}
                name={radioOptions.OTHER}
                value={radioOptions.OTHER}
                checked={selectedOption === radioOptions.OTHER}
                type="radio"
              />
              <label htmlFor={radioOptions.OTHER}>Other</label>
            </div>
          </div>
        </div>
        <div className={styles.description_wrapper}>
          <TextArea
            disabled={selectedOption !== radioOptions.OTHER}
            cols={4}
            rows={4}
            maxLength={150}
            placeholder={'Wire Description'}
            name={'description'}
            value={descriptionValue}
            handleChange={(e) => setDescriptionValue(e.target.value)}
          />
        </div>
        <div className={styles.buttons}>
          <button type="button" className={'btn-cancel'} onClick={handleClose}>
            Cancel
          </button>
          <Button disabled={disabled} type="submit" text={`I've paid`} />
        </div>
      </form>
    </div>
  )
}

export default UploadBuyerWireModal
