import {fetcher, methods} from 'common/apis'
import {FETCH_CONTACT_AUTH_ENDPOINT} from 'common/constants'
import {API_HOST_URL} from 'components/constants'

const GET_USER_AUTH = `${API_HOST_URL}${FETCH_CONTACT_AUTH_ENDPOINT}`

export const getUserAuth = async (userId: string) => {
  const {data, error} = await fetcher(`${GET_USER_AUTH}/${userId}`, methods.GET)
  return {data, error}
}
