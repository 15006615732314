import {CheckedIcon, UncheckedIconTwo} from 'assets/images'
import classNames from 'classnames'
import {
  BuyerWireConfirmation,
  InvoiceDocument,
  InvoiceLineItem,
  SellerWireConfirmation
} from 'common'
import {InvoiceItemType} from 'common/apis/enums'
import {AdminNotifications, sendNotification} from 'common/apis/notifications'
import Button from 'components/Button'
import ConfirmModal from 'components/ConfirmModal'
import ContentModal from 'components/ContentModal'
import UploadSellerWireModal from 'components/DetailPage/InvoiceDetails/UploadSellerWireModal'
import {useAppContext, useFirebase} from 'components/Firebase/hooks'
import ModalComponent from 'components/ModalComponent'
import Pagination from 'components/Pagination'
import Table from 'components/TableComponent'
import ViewPDFModal from 'components/ViewPDFModal'
import {API_HOST_URL, TABLE_PAGINATION_PAGE_SIZE} from 'components/constants'
import {InvoiceTable} from 'components/enums'
import {IFieldSetSingleField} from 'components/types'
import {Timestamp} from 'firebase/firestore'
import _ from 'lodash'
import React, {useMemo, useState} from 'react'
import {toast} from 'react-toastify'
import {
  getFormattedDate,
  useSortableData,
  withThousandSeperatorString
} from '../../utils'
import {FieldSet} from '../DetailFields'
import styles from '../DetailPage.module.scss'
import AddCommentsModal from './AddCommentsModal'
import AddLineItemModal from './AddLineItemModal'
import NoticeOfReleaseModal from './NoticeOfReleaseModal'
import UploadBuyerWireModal from './UploadBuyerWireModal'
import moment from 'moment'

const InvoiceDetails = (data: InvoiceDocument) => {
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [showPDFModal, setShowPDFModal] = useState<boolean>(false)
  const [showAddLineItem, setShowAddLineItem] = useState<boolean>(false)
  const [showUploadSellerWireModal, setShowUploadSellerWireModal] =
    useState<boolean>(false)
  const [showUploadBuyerWireModal, setShowUploadBuyerWireModal] =
    useState<boolean>(false)
  const [showNoticeModal, setShowNoticeModal] = useState<boolean>(false)
  const [invoiceAttachmentURL, setInvoiceAttachmentURL] = useState<string>('')
  const [amountPaidByBuyer, setAmountPaidBySeller] = useState<string>('')
  const [disableMarkAsPaid, setDisableMarkAsPaid] = useState<boolean>(false)
  const [disableDelete, setDisableDelete] = useState<boolean>(false)
  const [showAddCommentsModal, setShowAddCommentsModal] =
    useState<boolean>(false)
  const [showMarkAsPaidModal, setShowMarkAsPaidModal] = useState<boolean>(false)
  const [showDeleteTableItemModal, setShowDeleteTableItemModal] =
    useState<boolean>(false)
  const [selectedBuyerWireRowData, setSelectedBuyerWireRowData] =
    useState<BuyerWireConfirmation>()
  const [selectedTableItem, setSelectedTableItem] = useState<{
    selectedTable: InvoiceTable
    selectedItem:
      | BuyerWireConfirmation
      | SellerWireConfirmation
      | InvoiceLineItem
  }>()

  const {markBuyerWireAsPaid, removeInvoiceItem} = useFirebase()
  const {appState} = useAppContext()
  const isSuperAdmin = appState?.isSuperAdmin

  const handleRemoveWire = async () => {
    if (!selectedTableItem?.selectedTable || !selectedTableItem?.selectedItem) {
      return
    }

    const invoiceId = data.invoiceId || ''
    const isBuyerWire =
      selectedTableItem.selectedTable === InvoiceTable.BuyerWire
    const isSellerWire =
      selectedTableItem.selectedTable === InvoiceTable.SellerWire

    const itemType = isBuyerWire
      ? InvoiceItemType.BuyerWire
      : isSellerWire
      ? InvoiceItemType.SellerWire
      : InvoiceItemType.LineItem

    type WireConfirmation = BuyerWireConfirmation | SellerWireConfirmation

    const itemId =
      (selectedTableItem.selectedItem as WireConfirmation).wireConfirmationId ||
      (selectedTableItem.selectedItem as InvoiceLineItem).lineItemId ||
      ''

    setDisableDelete(true)

    const {error, message} = await removeInvoiceItem({
      itemId,
      itemType,
      invoiceId
    })

    if (error) {
      toast.warn(message)
      setDisableDelete(false)
      return
    }

    toast.success(message)
    setDisableDelete(false)
    setShowDeleteTableItemModal(false)
  }

  const handleMarkAsPaid = async () => {
    if (!selectedBuyerWireRowData) return
    if (selectedBuyerWireRowData?.markedAsRecieved) {
      toast.warn('Already marked as paid')
      return
    }

    if (!amountPaidByBuyer) {
      toast.warn('Please enter the amount paid by buyer')
      return
    }

    setDisableMarkAsPaid(true)

    const {error} = await markBuyerWireAsPaid({
      invoiceId: data.invoiceId!,
      amount: Number(amountPaidByBuyer),
      wireConfirmationId: selectedBuyerWireRowData.wireConfirmationId!
    })

    sendNotification({
      baseURL: API_HOST_URL,
      code: AdminNotifications.WireTransferAcknowledged,
      productId: data.productId
    })

    if (error) {
      toast.warn('Something went wrong while marking buyer as paid')
      setDisableMarkAsPaid(false)
    }

    toast.success('Wire marked as paid successfully')
    setAmountPaidBySeller('')
    setShowMarkAsPaidModal(false)
    setDisableMarkAsPaid(false)
  }

  const toggleAddCommentsModalModal = () =>
    setShowAddCommentsModal(!showAddCommentsModal)
  const toggleAddLineItemModal = () => setShowAddLineItem(!showAddLineItem)
  const toggleNoticeModal = () => setShowNoticeModal(!showNoticeModal)
  const toggleViewPDFModal = () => setShowPDFModal(!showPDFModal)
  const toggleUploadSellerWireModal = () =>
    setShowUploadSellerWireModal(!showUploadSellerWireModal)
  const toggleUploadBuyerWireModal = () =>
    setShowUploadBuyerWireModal(!showUploadBuyerWireModal)

  const allowNoticeDispatch = () => {
    if (!data?.noticeOfRelease) return true
    const timestamp1 = Timestamp.now()
    const timestamp2: Timestamp = data.noticeOfRelease

    const date1 = timestamp1.toDate()
    const date2 = timestamp2.toDate()

    const difference = date1.getTime() - date2.getTime()

    const twoHours = 2 * 60 * 60 * 1000
    const hasTwoHoursPassed = difference >= twoHours
    return hasTwoHoursPassed
  }

  const displayNoticeModal = () => {
    const allowDispatch = allowNoticeDispatch()
    if (!allowDispatch) {
      toast.warn('Please wait 2 hours before re-sending "Notice of Release".')
      return
    }
    setShowNoticeModal(true)
  }

  const lineItemsTableTitles = [
    {title: 'Amount'},
    {title: 'Description'},
    {title: 'Date'},
    {title: 'Credit'},
    {title: 'Quantity'},
    {title: 'Attachment'},
    {title: 'Remove Line Item'}
  ]
  const sellerWireTableTitles = [
    {title: 'Amount Paid'},
    {title: 'Description'},
    {title: 'Date'},
    {title: 'Credit'},
    {title: 'Marked as Received'},
    {title: 'Attachment'},
    {title: 'Remove Wire'}
  ]
  const buyerWireTableTitles = [
    {title: 'Amount Paid'},
    {title: 'Description'},
    {title: 'Date'},
    {title: 'Credit'},
    {title: 'Marked as Received'},
    {title: 'Attachment'},
    {title: 'Mark wire as Paid'},
    {title: 'Remove Wire'}
  ]
  const scrollRef: React.RefObject<HTMLDivElement> = React.createRef()
  const invoiceMarkedAsClosed = data?.invoiceMarkedAsClosed
  const buyerInvoice = data?.buyerInvoiceAttachment?.length
    ? _.last(data?.buyerInvoiceAttachment)
    : false
  const sellerInvoice = data?.sellerInvoiceAttachment?.length
    ? _.last(data?.sellerInvoiceAttachment)
    : false

  const scrollToRef = () =>
    scrollRef?.current?.scrollIntoView({behavior: 'smooth'})

  const getRowData = (rowData: Array<any>, wire: InvoiceTable) => {
    switch (wire) {
      case InvoiceTable.LineItem:
        return rowData.map((rowData: InvoiceLineItem, index) => {
          const {
            amount,
            attachment,
            createdAt,
            description,
            isCredit,
            quantity
          } = rowData

          return (
            <tr key={`buyer-wire-${index}`}>
              <td>{withThousandSeperatorString(`${amount}`) || '-'}</td>
              <td className={description.length > 20 ? 'with-ellipsis' : ''}>
                {description || '-'}
              </td>
              <td>{getFormattedDate(createdAt) || '-'}</td>
              <td>
                <img
                  src={isCredit ? CheckedIcon : UncheckedIconTwo}
                  alt="active"
                />
              </td>
              <td>{quantity || 1}</td>
              <td>
                {(attachment && (
                  <button
                    onClick={() => {
                      setInvoiceAttachmentURL(attachment)
                      toggleViewPDFModal()
                    }}
                    className={classNames(styles.btn, 'btn-fill-orange')}
                  >
                    View
                  </button>
                )) ||
                  '-'}
              </td>
              <td>
                <button
                  onClick={() => {
                    if (!isSuperAdmin) {
                      toast.warn(
                        'Insufficient privelages to perform this action.'
                      )
                      return
                    }
                    setSelectedTableItem({
                      selectedTable: InvoiceTable.LineItem,
                      selectedItem: rowData
                    })
                    setShowDeleteTableItemModal(true)
                  }}
                  className={classNames(styles.btn, styles.btn_red)}
                >
                  Delete
                </button>
              </td>
            </tr>
          )
        })
      case InvoiceTable.BuyerWire:
        return rowData.map((rowData: BuyerWireConfirmation, index) => {
          const {
            buyerWireAmountPaid,
            buyerWireDate,
            buyerWireDescription,
            isCredit,
            markedAsRecieved,
            buyerWireAttachment
          } = rowData

          return (
            <tr key={`buyer-wire-${index}`}>
              <td>
                {withThousandSeperatorString(`${buyerWireAmountPaid}`) || '-'}
              </td>
              {buyerWireDescription.length > 20 ? (
                <td style={{padding: '0rem 1rem'}} className="with-ellipsis">
                  {buyerWireDescription || '-'}
                </td>
              ) : (
                <td>{buyerWireDescription || '-'}</td>
              )}
              <td>{getFormattedDate(buyerWireDate) || '-'}</td>
              <td>
                <img
                  src={isCredit ? CheckedIcon : UncheckedIconTwo}
                  alt="active"
                />
              </td>
              <td>
                <img
                  src={markedAsRecieved ? CheckedIcon : UncheckedIconTwo}
                  alt="active"
                />
              </td>
              <td>
                {(buyerWireAttachment && (
                  <button
                    onClick={() => {
                      setInvoiceAttachmentURL(buyerWireAttachment)
                      toggleViewPDFModal()
                    }}
                    className={classNames(styles.btn, 'btn-fill-orange')}
                  >
                    View
                  </button>
                )) ||
                  '-'}
              </td>
              <td>
                <button
                  disabled={markedAsRecieved}
                  onClick={() => {
                    if (!isSuperAdmin) {
                      toast.warn(
                        'Insufficient privelages to perform this action.'
                      )
                      return
                    }
                    setSelectedBuyerWireRowData(rowData)
                    setShowMarkAsPaidModal(true)
                  }}
                  className={styles.btn}
                >
                  {markedAsRecieved ? 'Paid' : 'Mark as Paid'}
                </button>
              </td>
              <td>
                <button
                  onClick={() => {
                    if (!isSuperAdmin) {
                      toast.warn(
                        'Insufficient privelages to perform this action.'
                      )
                      return
                    }
                    setSelectedTableItem({
                      selectedTable: InvoiceTable.BuyerWire,
                      selectedItem: rowData
                    })
                    setShowDeleteTableItemModal(true)
                  }}
                  className={classNames(styles.btn, styles.btn_red)}
                >
                  Delete
                </button>
              </td>
            </tr>
          )
        })
      case InvoiceTable.SellerWire:
        return rowData.map((rowData: SellerWireConfirmation, index) => {
          const {
            sellerWireAmountPaid,
            sellerWireAttachment,
            sellerWireDate,
            isCredit,
            markedAsRecieved,
            sellerWireDescription
          } = rowData

          return (
            <tr key={`seller-wire-${index}`}>
              <td>
                {withThousandSeperatorString(`${sellerWireAmountPaid}`) || '-'}
              </td>
              {sellerWireDescription.length > 20 ? (
                <td style={{padding: '0rem 1rem'}} className="with-ellipsis">
                  {sellerWireDescription || '-'}
                </td>
              ) : (
                <td>{sellerWireDescription || '-'}</td>
              )}
              <td>{getFormattedDate(sellerWireDate) || '-'}</td>
              <td>
                <img
                  src={isCredit ? CheckedIcon : UncheckedIconTwo}
                  alt="active"
                />
              </td>
              <td>
                <img
                  src={markedAsRecieved ? CheckedIcon : UncheckedIconTwo}
                  alt="active"
                />
              </td>
              <td>
                {(sellerWireAttachment && (
                  <button
                    onClick={() => {
                      setInvoiceAttachmentURL(sellerWireAttachment)
                      toggleViewPDFModal()
                    }}
                    className={classNames(styles.btn, 'btn-fill-orange')}
                  >
                    View
                  </button>
                )) ||
                  '-'}
              </td>
              <td>
                <button
                  onClick={() => {
                    if (!isSuperAdmin) {
                      toast.warn(
                        'Insufficient privelages to perform this action.'
                      )
                      return
                    }
                    setSelectedTableItem({
                      selectedTable: InvoiceTable.SellerWire,
                      selectedItem: rowData
                    })
                    setShowDeleteTableItemModal(true)
                  }}
                  className={classNames(styles.btn, styles.btn_red)}
                >
                  Delete
                </button>
              </td>
            </tr>
          )
        })

      default:
        return null
    }
  }

  const sellerWireConfirmations = data?.sellerWireConfirmation || []
  const buyerWireConfirmations = data?.buyerWireConfirmation || []
  const invoiceLineItems = data?.invoiceLineItems || []
  const {items: sellerWireItems} = useSortableData(sellerWireConfirmations)
  const {items: buyerWireItems} = useSortableData(buyerWireConfirmations)
  const {items: lineItems} = useSortableData(invoiceLineItems)

  const showSellerWirePagination =
    sellerWireConfirmations?.length > TABLE_PAGINATION_PAGE_SIZE
  const showBuyerWirePagination =
    buyerWireConfirmations?.length > TABLE_PAGINATION_PAGE_SIZE

  const onPageChange = (page: number) => {
    setCurrentPage(page)
    scrollToRef()
  }

  const currentSellerWireTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * TABLE_PAGINATION_PAGE_SIZE
    const lastPageIndex = firstPageIndex + TABLE_PAGINATION_PAGE_SIZE
    return sellerWireItems.slice(firstPageIndex, lastPageIndex)
  }, [currentPage, sellerWireItems])

  const currentLineItemsTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * TABLE_PAGINATION_PAGE_SIZE
    const lastPageIndex = firstPageIndex + TABLE_PAGINATION_PAGE_SIZE
    return lineItems.slice(firstPageIndex, lastPageIndex)
  }, [currentPage, lineItems])

  const currentBuyerWireTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * TABLE_PAGINATION_PAGE_SIZE
    const lastPageIndex = firstPageIndex + TABLE_PAGINATION_PAGE_SIZE
    return buyerWireItems.slice(firstPageIndex, lastPageIndex)
  }, [currentPage, buyerWireItems])

  const pageItemsLeft = () => {
    const items: Array<IFieldSetSingleField> = [
      {
        label: 'Invoice ID',
        value: data?.invoiceId
      },
      {label: 'Invoice Number', value: data?.invoiceNumber},
      {label: 'Equipment ID', value: data?.productId},
      {label: 'Equipment Title', value: data?.productTitle},
      {
        label: 'Sale Price',
        value:
          (data?.salePrice &&
            `${withThousandSeperatorString(`${data?.salePrice}`)}`) ||
          ''
      },
      {label: 'Sale Commission', value: data?.saleCommission},
      {label: `Buyer's Premium`, value: data?.buyersPremium},
      {
        label: 'Created At',
        value: getFormattedDate(data?.createdAt) || '-'
      },
      {
        label: 'Last Updated',
        value: getFormattedDate(data?.lastUpdated) || ''
      },
      {
        label: 'Marked As Closed',
        value: data?.invoiceMarkedAsClosed,
        isBoolean: true
      }
    ]
    return <FieldSet fields={items} />
  }

  const noticeOfRelease = data?.noticeOfRelease
    ? `Sent on ${moment(data.noticeOfRelease.toDate())
        .format('DD MMM YYYY [at] hh:mm A')
        .toString()}`
    : 'Not Sent'

  const pageItemsRight = () => {
    const items = [
      {label: 'Buyer Account ID', value: data?.buyerAccountId},
      {label: 'Buyer Account Name', value: data?.buyerAccountName},
      {label: 'Buyer Contact ID', value: data?.buyerContactId},
      {label: 'Buyer Contact Name', value: data?.buyerContactName},
      {label: 'Seller Account ID', value: data?.sellerAccountId},
      {label: 'Seller Account Name', value: data?.sellerAccountName},
      {label: 'Seller Contact ID', value: data?.sellerContactId},
      {label: 'Seller Contact Name', value: data?.sellerContactName},
      {
        label: 'Notice of Release',
        value: noticeOfRelease
      }
    ]
    return <FieldSet fields={items} />
  }

  const deleteTableItemModal = {
    heading:
      selectedTableItem?.selectedTable === InvoiceTable.LineItem
        ? 'Remove Line Item'
        : 'Remove Wire Transfer',
    content:
      selectedTableItem?.selectedTable === InvoiceTable.LineItem
        ? 'Are you sure you want to delete this line item from invoice line items history.'
        : 'Are you sure you want to delete this transfer from wire transfer history.'
  }

  return (
    <section className={styles.invoiceSection}>
      <div className={styles.inputFieldsContainer}>
        <div style={{marginTop: '-2rem'}} className={styles.inputsfields}>
          {pageItemsLeft()}
        </div>
        <div style={{marginTop: '-2rem'}} className={styles.inputsfields}>
          {pageItemsRight()}
        </div>
      </div>
      <div>
        <div>
          <div className={styles.wireConfirmation_wraper}>
            <h1 className={styles.secondheading}>Buyer Wire Confirmation</h1>
            <div style={{display: 'flex'}}>
              {!invoiceMarkedAsClosed && isSuperAdmin && (
                <Button
                  text="Add Invoice Comment"
                  customBtnClass={'btn-fill-orange'}
                  customClass={styles.viewInvoice_btn}
                  onClick={toggleAddCommentsModalModal}
                />
              )}
              {!invoiceMarkedAsClosed && isSuperAdmin && (
                <Button
                  customClass={styles.wireConfirmation_btn}
                  text="Upload Wire Confirmation"
                  onClick={toggleUploadBuyerWireModal}
                />
              )}
              {buyerInvoice && (
                <Button
                  text="View Buyer Invoice"
                  customBtnClass={'btn-fill-blue'}
                  customClass={styles.viewInvoice_btn}
                  onClick={() => {
                    setInvoiceAttachmentURL(buyerInvoice)
                    toggleViewPDFModal()
                  }}
                />
              )}
            </div>
          </div>
          <Table
            titles={buyerWireTableTitles}
            rowData={getRowData(
              currentBuyerWireTableData,
              InvoiceTable.BuyerWire
            )}
            customClass={styles.containerTable}
          />
          {showBuyerWirePagination && (
            <div className="pagination__wrapper">
              <Pagination
                currentPage={currentPage}
                totalCount={buyerWireItems?.length}
                pageSize={TABLE_PAGINATION_PAGE_SIZE}
                onPageChange={onPageChange}
              />
            </div>
          )}
        </div>
        <div style={{marginTop: '3rem'}}>
          <div className={styles.wireConfirmation_wraper}>
            <h1 className={styles.secondheading}>Seller Wire Confirmation</h1>
            <div style={{display: 'flex'}}>
              {sellerInvoice && (
                <Button
                  text="View Seller Invoice"
                  customBtnClass={'btn-fill-blue'}
                  customClass={styles.viewInvoice_btn}
                  onClick={() => {
                    setInvoiceAttachmentURL(sellerInvoice)
                    toggleViewPDFModal()
                  }}
                />
              )}
              {!invoiceMarkedAsClosed && isSuperAdmin && (
                <Button
                  customBtnClass={'btn-fill-blue'}
                  customClass={styles.wireConfirmation_btn}
                  text="Send Notice of Release"
                  onClick={displayNoticeModal}
                />
              )}
              {!invoiceMarkedAsClosed && isSuperAdmin && (
                <Button
                  customClass={styles.wireConfirmation_btn}
                  text="Upload Wire Confirmation"
                  onClick={toggleUploadSellerWireModal}
                />
              )}
            </div>
          </div>
          <Table
            titles={sellerWireTableTitles}
            rowData={getRowData(
              currentSellerWireTableData,
              InvoiceTable.SellerWire
            )}
            customClass={styles.containerTable}
          />
          {showSellerWirePagination && (
            <div className="pagination__wrapper">
              <Pagination
                currentPage={currentPage}
                totalCount={sellerWireItems?.length}
                pageSize={TABLE_PAGINATION_PAGE_SIZE}
                onPageChange={onPageChange}
              />
            </div>
          )}
        </div>
        <div style={{marginTop: '3rem'}}>
          <div className={styles.wireConfirmation_wraper}>
            <h1 className={styles.secondheading}>Line Items</h1>
            {!invoiceMarkedAsClosed && isSuperAdmin && (
              <Button
                text="Add Line Item"
                customBtnClass={'btn-fill-orange'}
                customClass={styles.viewInvoice_btn}
                onClick={() => {
                  toggleAddLineItemModal()
                }}
              />
            )}
          </div>
          <Table
            titles={lineItemsTableTitles}
            rowData={getRowData(
              currentLineItemsTableData,
              InvoiceTable.LineItem
            )}
            customClass={styles.containerTable}
          />
          {showSellerWirePagination && (
            <div className="pagination__wrapper">
              <Pagination
                currentPage={currentPage}
                totalCount={sellerWireItems?.length}
                pageSize={TABLE_PAGINATION_PAGE_SIZE}
                onPageChange={onPageChange}
              />
            </div>
          )}
        </div>
      </div>
      {showPDFModal && (
        <ModalComponent show={showPDFModal} handleClose={toggleViewPDFModal}>
          <ViewPDFModal
            handleClose={toggleViewPDFModal}
            url={invoiceAttachmentURL}
          />
        </ModalComponent>
      )}
      {showAddLineItem && (
        <ModalComponent
          show={showAddLineItem}
          handleClose={toggleAddLineItemModal}
        >
          <AddLineItemModal
            handleClose={toggleAddLineItemModal}
            invoice={data}
          />
        </ModalComponent>
      )}
      {showUploadSellerWireModal && (
        <ModalComponent
          handleClose={toggleUploadSellerWireModal}
          show={showUploadSellerWireModal}
        >
          <UploadSellerWireModal
            invoice={data}
            handleClose={toggleUploadSellerWireModal}
          />
        </ModalComponent>
      )}
      {showUploadBuyerWireModal && (
        <ModalComponent
          handleClose={toggleUploadBuyerWireModal}
          show={showUploadBuyerWireModal}
        >
          <UploadBuyerWireModal
            invoice={data}
            handleClose={toggleUploadBuyerWireModal}
          />
        </ModalComponent>
      )}
      {showMarkAsPaidModal && (
        <ModalComponent
          show={showMarkAsPaidModal}
          handleClose={() => setShowMarkAsPaidModal(false)}
        >
          <ConfirmModal
            content="Are you sure you want to mark this invoice as paid"
            text="Mark Invoice as Paid"
            handleClose={() => setShowMarkAsPaidModal(false)}
            handleConfirm={handleMarkAsPaid}
            confirmBtnDisabled={disableMarkAsPaid}
            hasInput
            inputState={{
              setState: setAmountPaidBySeller,
              state: amountPaidByBuyer,
              label: 'Amount Paid'
            }}
          />
        </ModalComponent>
      )}
      {showDeleteTableItemModal && (
        <ModalComponent
          show={showDeleteTableItemModal}
          handleClose={() => setShowDeleteTableItemModal(false)}
        >
          <ContentModal
            removeIcon={true}
            buttonText="Remove"
            buttonDisabled={disableDelete}
            buttonOnClick={handleRemoveWire}
            heading={deleteTableItemModal.heading}
            content={deleteTableItemModal.content}
            handleClose={() => setShowDeleteTableItemModal(false)}
          />
        </ModalComponent>
      )}
      {showNoticeModal && (
        <NoticeOfReleaseModal
          show={showNoticeModal}
          invoiceDocument={data}
          handleClose={toggleNoticeModal}
        />
      )}
      {showAddCommentsModal && (
        <AddCommentsModal
          handleClose={toggleAddCommentsModalModal}
          show={showAddCommentsModal}
          invoice={data}
        />
      )}
    </section>
  )
}

export default InvoiceDetails
