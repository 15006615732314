import classNames from 'classnames'
import styles from '../DetailPage.module.scss'
import {IFieldSetSingleField} from 'components/types'
import React from 'react'
import FieldComponent from '../ProductDetails/FieldComponent'
import DropDownSelect from 'components/DropDown'
import TextArea from 'components/TextArea'

export const SingleField = (field: IFieldSetSingleField) => (
  <div
    className={classNames(styles.sec_input, styles.normalAlignItems)}
    key={field.label}
  >
    <p className={styles.customParagraphTag}>{field.label}</p>
    {field.isTextbox ? (
      <TextArea
        value={`${field.value || '-'}`}
        disabled={true}
        customTextAreaClass={styles.descriptionBox}
        customDivClass={styles.descriptionContainer}
        noCounter={true}
      />
    ) : (
      <div className={styles.labelTag}>
        {field.isBoolean ? (
          <FieldComponent
            checkedField={true}
            checkedCondition={Boolean(field?.value)}
          />
        ) : (
          field?.value
        )}
      </div>
    )}
  </div>
)

const TwoColFields = ({fields}: {fields: Array<IFieldSetSingleField>}) => (
  <React.Fragment>
    {fields?.map((field, index, arr) => {
      const isEven = index % 2 === 0
      const isWithinLength = index + 1 < arr.length
      return isEven ? (
        <div className={styles.twoColItems} key={index}>
          <SingleField key={`${field.label}-${index}`} {...field} />
          {isWithinLength ? (
            <SingleField
              key={`${arr[index + 1].label}-${index + 1}`}
              {...arr[index + 1]}
            />
          ) : (
            <SingleField key={`empty-single-field-${index}`} label={''} />
          )}
        </div>
      ) : null
    })}
  </React.Fragment>
)

const CustomFieldDropDown = (field: IFieldSetSingleField) => (
  <DropDownSelect
    customRoute={field?.dropDown?.route}
    customClass={classNames(styles.more_option_btn, styles.accountsListBox)}
    options={field?.dropDown?.options || []}
    placeholder={field.label}
    unSelectable={true}
    disabled={true}
    name="drop_list"
  />
)

const FieldWithLine = ({value, label}: IFieldSetSingleField) => (
  <React.Fragment>
    <div className={styles.fieldLine} />
    <div
      style={{marginTop: 'unset'}}
      className={classNames(
        styles.sec_input,
        styles.newstyles,
        styles.normalAlignItems
      )}
    >
      <p className={styles.customParagraphTag}>{label}</p>
      <div className={styles.labelTag}>{value}</div>
    </div>
  </React.Fragment>
)

export const FieldSet = ({
  isTwoCol,
  fields,
  title,
  gap
}: {
  gap?: boolean
  title?: string
  isTwoCol?: boolean
  fields: Array<IFieldSetSingleField>
}) => {
  return (
    <React.Fragment>
      {title ? (
        <h1
          className={classNames(
            styles.secondheading,
            gap ? styles.gapClass : ''
          )}
        >
          {title}
        </h1>
      ) : null}

      {isTwoCol ? (
        <TwoColFields fields={fields} />
      ) : (
        fields?.map((field, index) => {
          return field.dropDown ? (
            <CustomFieldDropDown key={`${field.label}-${index}`} {...field} />
          ) : field.line ? (
            <FieldWithLine key={`${field.label}-${index}`} {...field} />
          ) : (
            <SingleField key={`${field.label}-${index}`} {...field} />
          )
        })
      )}
    </React.Fragment>
  )
}
