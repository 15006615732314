import {listingType, equipmentOptions} from 'common/enums'

export const INITIAL_PRODUCT_FORM_STATE = {
  accountId: '',
  contactId: '',
  listingType: ''
}

export const LISTINGTYPE_OPTIONS = Object.values(listingType)
export const NEWORUSEDEQUIPMENT_OPTIONS = ['New', 'Used']
export const DROPDOWNONE_OPTIONS = [
  equipmentOptions.EquipmentHours,
  equipmentOptions.EquipmentMileage,
  equipmentOptions.Unknown
]
export const DROPDOWNTWO_OPTIONS = [
  equipmentOptions.SerialNumber,
  equipmentOptions.EquipmentVINNumber,
  equipmentOptions.Unknown
]
