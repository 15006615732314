import {default as classNames, default as cn} from 'classnames'
import {ContactDocument, TaxJarCustomer} from 'common'
import ConfirmationModal from 'components/ConfirmationModal'
import ExemptionRegionsAndTypeModal from 'components/DetailPage/TaxExemptionDetails/ExemptionRegionsAndTypeModal'
import {useFirebase} from 'components/Firebase/hooks'
import ModalComponent from 'components/ModalComponent'
import {
  deleteTaxJarCustomer,
  updateTaxJarCustomer,
  viewTaxJarCustomer
} from 'components/apis/taxjar'
import {DetailType} from 'components/enums'
import {ICity, ICountry, IState} from 'country-state-city'
import {Formik, FormikProps} from 'formik'
import {get, isEqual} from 'lodash'
import React, {useEffect, useRef, useState} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {toast} from 'react-toastify'
import {ArrowDown, Building} from '../../assets/svgs'
import Button from '../Button'
import DetailPage from '../DetailPage'
import DropDownSelect from '../DropDown'
import InputField from '../InputField'
import Layout from '../Layout'
import {Loader} from '../Loader'
import NavBar from '../Navbar'
import withAuthorization from '../Session/withAuthorization'
import {
  filteredCountries,
  getCitiesOfState,
  memoizedStatesOfCountry
} from '../utils'
import styles from './TaxJarCustomerPage.module.scss'
import {INITIAL_TAXJAR_CUSTOMER_STATE, TaxJarCustomerSchema} from './schema'
import {ExemptionType} from 'common/enums'

interface ITaxJarCustomerFromState extends TaxJarCustomer {
  customer_id: string
}

const TaxJarCustomerPage = ({customClass}: {customClass: string}) => {
  const navigate = useNavigate()
  const {state} = useLocation()
  const {getPrimaryContact} = useFirebase()
  const [primaryContact, setPrimaryContact] = useState<
    ContactDocument | undefined
  >()
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
  const [showUpdateModal, setShowUpdateModal] = useState(false)
  const toggleShowDeleteModal = () => setShowDeleteModal(!showDeleteModal)
  const toggleUpdateModal = () => {
    if (!primaryContact?.contactId) {
      toast.warn('Please first set a primary contact for this account.')
      return
    }
    setShowUpdateModal(!showUpdateModal)
  }
  const formRef: React.Ref<FormikProps<TaxJarCustomer>> | undefined =
    useRef(null)
  const [formData, setFormData] = useState({})
  const accountId = state?.id ? state?.id : ''
  const isEdit = state?.isEdit ? state?.isEdit : false
  const previousPage = state?.currentPage
  const [loader, setLoader] = useState(isEdit ? true : false)
  const [disabled, setDisabled] = useState(isEdit ? true : false)
  const [appState, setAppState] = useState({
    documentData: {...INITIAL_TAXJAR_CUSTOMER_STATE}
  })
  const optionsList = Object.values(ExemptionType).map((exemption: string) => ({
    id: exemption,
    name: exemption
  }))

  useEffect(() => {
    isEdit &&
      setTimeout(() => {
        setLoader(false)
      }, 2000)
  }, [])

  useEffect(() => {
    const getCustomerInfo = async () => {
      const {
        data: {data, code}
      } = await viewTaxJarCustomer({accountId})
      if (code === 200) {
        setAppState({documentData: data})
      } else {
        toast.warn(data.msg || 'Failed to get TaxJar customer data.')
      }
    }
    getPrimaryContact(accountId).then((res) => setPrimaryContact(res))
    getCustomerInfo()
  }, [accountId])

  const getFormattedCustomer = ({exempt_regions, ...rest}: TaxJarCustomer) => ({
    ...rest
  })

  useEffect(() => {
    const {documentData} = appState
    if (documentData && !disabled) {
      const customerObject = getFormattedCustomer(documentData)
      setFormData({...customerObject})
      formRef?.current?.setValues({...customerObject})
    }
  }, [appState, formRef, disabled])

  const isDetail = isEdit && disabled

  const handleDeletion = async () => {
    toggleShowDeleteModal()
    setLoader(true)
    const {
      data: {data, code, msg}
    } = await deleteTaxJarCustomer({accountId})
    if (code === 200) {
      toast.success(msg)
      navigate('/taxjar-customers', {
        state: {page: previousPage}
      })
    } else {
      toast.warning(data.msg || 'Failed to delete TaxJar Customer')
      setLoader(false)
    }
    return
  }

  return (
    <div>
      <div className={styles.withLayout}>
        <Layout />
        <div className={cn(styles.container, customClass)}>
          <NavBar
            title={
              isDetail
                ? 'View TaxJar Customer'
                : isEdit
                ? 'Edit TaxJar Customer'
                : 'Create New TaxJar Customer'
            }
          />
          {loader ? (
            <Loader />
          ) : (
            <React.Fragment>
              {showUpdateModal && (
                <ExemptionRegionsAndTypeModal
                  show={showUpdateModal}
                  contactId={primaryContact?.contactId!}
                  handleClose={toggleUpdateModal}
                  exemptRegions={appState.documentData.exempt_regions}
                  exemptionType={appState.documentData.exemption_type}
                />
              )}
              {showDeleteModal && (
                <ModalComponent
                  handleClose={toggleShowDeleteModal}
                  show={showDeleteModal}
                >
                  <ConfirmationModal
                    removeIcon
                    submitModal={true}
                    handleClose={toggleShowDeleteModal}
                    text={`Delete Customer`}
                    handleSubmit={handleDeletion}
                    shouldCheckForDocuments={false}
                    content={
                      'You cannot undo these changes. Are you sure you want to delete this customer?'
                    }
                  />
                </ModalComponent>
              )}
              <div className={styles.buttons}>
                <span
                  onClick={() =>
                    navigate('/taxjar-customers', {
                      state: {page: previousPage}
                    })
                  }
                >
                  <ArrowDown />
                  Back
                </span>
                <div className={styles.twobtn}>
                  {isEdit && (
                    <Button
                      text="Delete"
                      customClass={styles.changes_btn}
                      onClick={toggleShowDeleteModal}
                    />
                  )}
                  {isEdit && (
                    <Button
                      text="Update Exemption Type And Regions"
                      customClass={styles.exemptionTypeAndRegions_btn}
                      onClick={toggleUpdateModal}
                    />
                  )}
                  {(!isEdit || !disabled) && (
                    <Button
                      text={'Save'}
                      customClass={styles.changes_btn}
                      disabled={disabled}
                      onClick={() => formRef.current?.handleSubmit()}
                    />
                  )}
                </div>
              </div>
              <div className={styles.edit_section}>
                {isDetail ? (
                  <DetailPage
                    data={{...appState.documentData}}
                    detailType={DetailType.TaxJarCustomer}
                  />
                ) : (
                  <React.Fragment>
                    <h1 className={styles.secondheading}>
                      Customer Information
                    </h1>
                    <Formik
                      innerRef={formRef}
                      initialValues={appState.documentData}
                      onSubmit={async (values: TaxJarCustomer) => {
                        const isObjectSame = isEqual(values, formData)
                        if (isEdit && isObjectSame) {
                          toast.warning('Please make some changes')
                          return
                        }
                        setLoader(true)
                        setDisabled(true)
                        const {customer_id, ...rest} = {
                          ...appState.documentData,
                          ...values
                        } as ITaxJarCustomerFromState

                        const {
                          data: {data, code, msg}
                        } = await updateTaxJarCustomer({
                          accountId,
                          customerObj: {...rest}
                        })
                        if (code === 200) {
                          toast.success(
                            msg || 'Customer Data Updated Successfully'
                          )
                          navigate('/taxjar-customers', {
                            state: {page: previousPage}
                          })
                        } else {
                          toast.warn(
                            data.msg || 'Failed to update customer information'
                          )
                          setLoader(false)
                          setDisabled(false)
                        }
                        return
                      }}
                      validationSchema={TaxJarCustomerSchema}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        setFieldValue,
                        setFieldError,
                        setFieldTouched
                      }) => {
                        const states =
                          memoizedStatesOfCountry(values.country) || []
                        const cities = getCitiesOfState(
                          values.country,
                          values.state
                        )
                        return (
                          <form
                            className={styles.from_submiting}
                            onSubmit={handleSubmit}
                          >
                            <div className={styles.formContainer}>
                              <div className={styles.inputs}>
                                <div className={styles.inputContainer}>
                                  <div className={styles.inputField}>
                                    <InputField
                                      name="name"
                                      placeholder="Name"
                                      value={get(values, 'name')}
                                      onChange={handleChange}
                                      errors={errors}
                                      touched={touched}
                                      onBlur={handleBlur}
                                      type="text"
                                      disabled={disabled}
                                    />
                                  </div>
                                  <div className={styles.inputField}>
                                    <div className={styles.dropdown}>
                                      <div>
                                        <DropDownSelect
                                          firstIcon={<Building />}
                                          options={optionsList}
                                          customClass={cn(
                                            styles.dropdowninput,
                                            styles.dropdownblock
                                          )}
                                          placeholder="Exemption Type"
                                          value={optionsList.findIndex(
                                            (option) =>
                                              option.id ===
                                              get(values, 'exemption_type')
                                          )}
                                          cancelClickFunction={() => {
                                            setFieldValue('exemption_type', '')
                                          }}
                                          onChange={(index) => {
                                            const currentOption =
                                              optionsList[index]
                                            setFieldValue(
                                              'exemption_type',
                                              currentOption.id
                                            )
                                            setFieldError('exemption_type', '')
                                            setFieldTouched(
                                              'exemption_type',
                                              false
                                            )
                                          }}
                                          errors={errors}
                                          touched={touched}
                                          disabled={disabled}
                                          onBlur={handleBlur}
                                          name="exemption_type"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className={styles.inputContainer}>
                                  <div className={styles.inputField}>
                                    <div className={styles.dropdown}>
                                      <div>
                                        <DropDownSelect
                                          mode="country"
                                          firstIcon={<Building />}
                                          options={filteredCountries}
                                          customClass={classNames(
                                            styles.dropdowninput,
                                            styles.dropdownblock
                                          )}
                                          placeholder="Country"
                                          value={filteredCountries?.findIndex(
                                            (country: ICountry) =>
                                              country.isoCode === values.country
                                          )}
                                          cancelClickFunction={() => {
                                            setFieldValue('country', '')
                                            setFieldValue('state', '')
                                            setFieldValue('city', '')
                                          }}
                                          onChange={(index) => {
                                            setFieldValue(
                                              'country',
                                              filteredCountries[index].isoCode
                                            )
                                            setFieldValue('state', '')
                                            setFieldValue('city', '')
                                          }}
                                          errors={errors}
                                          touched={touched}
                                          disabled={disabled}
                                          onBlur={handleBlur}
                                          name="country"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className={styles.inputField}>
                                    <div className={styles.dropdown}>
                                      <div>
                                        <DropDownSelect
                                          mode="state"
                                          firstIcon={<Building />}
                                          options={states}
                                          customClass={classNames(
                                            styles.dropdowninput,
                                            styles.dropdownblock
                                          )}
                                          placeholder="State"
                                          value={states.findIndex(
                                            (country: IState) =>
                                              country.isoCode === values.state
                                          )}
                                          cancelClickFunction={() => {
                                            setFieldValue('state', '')
                                          }}
                                          onChange={(index) => {
                                            setFieldValue(
                                              'state',
                                              states[index].isoCode
                                            )
                                            setFieldValue('city', '')
                                          }}
                                          errors={errors}
                                          touched={touched}
                                          disabled={disabled}
                                          onBlur={handleBlur}
                                          name="state"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className={styles.inputContainer}>
                                  <div className={styles.inputField}>
                                    <div className={styles.dropdown}>
                                      <div>
                                        <DropDownSelect
                                          firstIcon={<Building />}
                                          options={cities}
                                          customClass={classNames(
                                            styles.dropdowninput,
                                            styles.dropdownblock
                                          )}
                                          placeholder="City"
                                          value={cities.findIndex(
                                            (city: ICity) =>
                                              city.name === values.city
                                          )}
                                          cancelClickFunction={() => {
                                            setFieldValue('city', '')
                                          }}
                                          onChange={(index) => {
                                            setFieldValue(
                                              'city',
                                              cities[index].name
                                            )
                                          }}
                                          errors={errors}
                                          touched={touched}
                                          disabled={disabled}
                                          onBlur={handleBlur}
                                          name="city"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className={styles.inputField}>
                                    <InputField
                                      placeholder="Zip Code"
                                      name="zip"
                                      value={get(values, 'zip')}
                                      onChange={handleChange}
                                      errors={errors}
                                      touched={touched}
                                      onBlur={handleBlur}
                                      type="text"
                                      disabled={disabled}
                                    />
                                  </div>
                                </div>
                                <div className={styles.inputContainer}>
                                  <div
                                    style={{width: '100%'}}
                                    className={styles.inputField}
                                  >
                                    <InputField
                                      placeholder="Street Address"
                                      name="street"
                                      value={get(values, 'street')}
                                      onChange={handleChange}
                                      errors={errors}
                                      touched={touched}
                                      onBlur={handleBlur}
                                      type="text"
                                      disabled={disabled}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </form>
                        )
                      }}
                    </Formik>
                  </React.Fragment>
                )}
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  )
}

export default withAuthorization(TaxJarCustomerPage)
