import React, {useState} from 'react'
import styles from './ViewPDFModal.module.scss'
import {Cancel, Download_icon} from '../../assets/svgs'
import {Loader} from '../Loader'
import PDFViewer from './pdfViewer'
import {downloadPDFfromURL} from '../utils'
import classNames from 'classnames'

interface ViewPDFModalModalProps {
  url: string
  title?: string
  fileName?: string
  handleClose: () => void
  hideDownlaodBtn?: boolean
  forRFCFile?: boolean
}

const ViewPDFModal = ({
  handleClose,
  url,
  title,
  fileName,
  hideDownlaodBtn = false,
  forRFCFile
}: ViewPDFModalModalProps) => {
  const [showLoader, setShowLoader] = useState(true)
  const [isURLForImage, setIsURLForImage] = useState(/\.(png|jpe?g)/i.test(url))

  const downloadRFCFile = () => {
    const fileName = 'RFC_Submission_Guidelines.pdf'
    const filePath = `/${fileName}`
    const link = document.createElement('a')
    link.href = process.env.PUBLIC_URL + filePath
    link.setAttribute('download', fileName)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  React.useEffect(() => {
    fetch(url).then((response) =>
      response
        .blob()
        .then((blob) =>
          blob.type === 'application/pdf'
            ? setIsURLForImage(false)
            : setIsURLForImage(true)
        )
    )
  }, [url])

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.heading}>
          <Cancel className={styles.cancel} onClick={handleClose} />
        </div>
        <h1>{title}</h1>
        {showLoader && <Loader fullHeight={false} />}
        <div
          style={showLoader ? {display: 'none'} : {display: 'flex'}}
          className={styles.viewer}
        >
          {isURLForImage ? (
            <img
              alt="img-modal-content"
              onLoad={() => setShowLoader(false)}
              className={styles.img}
              src={url}
            />
          ) : (
            <PDFViewer setShowLoader={setShowLoader} file={url} />
          )}
        </div>
        <div
          className={classNames(
            styles.action_container,
            showLoader ? styles.disnone : ''
          )}
        >
          {!hideDownlaodBtn && (
            <div>
              <button
                onClick={
                  forRFCFile
                    ? downloadRFCFile
                    : () => downloadPDFfromURL(url, fileName)
                }
              >
                <Download_icon />
                Download
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default ViewPDFModal
