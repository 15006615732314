import {Cancel, Cancel_red, PinPdf} from 'assets/svgs'
import classNames from 'classnames'
import {BankAccountInfoDocument, FILE_OBJECT, InvoiceDocument} from 'common'
import {
  AdminNotifications,
  SellerNotifications,
  sendNotification
} from 'common/apis/notifications'
import {BANK_ACCOUNT_INFO_INITIAL_STATE} from 'common/constants'
import Button from 'components/Button'
import DetailPage from 'components/DetailPage'
import {useFirebase} from 'components/Firebase/hooks'
import CurrencyInputField from 'components/InputField/CurrencyInputField'
import {Loader} from 'components/Loader'
import TextArea from 'components/TextArea'
import {addSellerWire} from 'components/apis/invoice'
import {API_HOST_URL} from 'components/constants'
import {DetailType} from 'components/enums'
import {
  getFileInformation,
  getFormattedDate,
  getParsedFileNameForUploadingFile,
  isCorrectURI,
  withThousandSeperatorString,
  withoutBuyersPremium
} from 'components/utils'
import React, {useEffect, useRef, useState} from 'react'
import {toast} from 'react-toastify'
import dragPdfStyles from './DragStyles.module.scss'
import styles from './UploadSellerWireModal.module.scss'

interface UploadSellerWireModalProps {
  handleClose: () => void
  invoice: InvoiceDocument
}

const UploadSellerWireModal = ({
  handleClose,
  invoice
}: UploadSellerWireModalProps) => {
  enum radioOptions {
    OTHER = 'other',
    WIRETRANSFER = 'wiretransfer'
  }
  const {salePrice, productThumbnail, productTitle, createdAt} = invoice
  const [loader, setLoader] = useState(true)
  const [fileArr, setFileArr] = useState<Array<FILE_OBJECT>>([])
  const [descriptionValue, setDescriptionValue] = useState('')
  const [amountPaid, setAmountPaid] = useState<string | undefined>()
  const [formStep, setFormStep] = useState(0)
  const [bankAccountDetails, setBankAccountDetails] =
    useState<BankAccountInfoDocument>()
  const {uploadFile, deleteFile, getUserBankAccountInfo} = useFirebase()
  const [progress, setProgress] = useState<number>(100)
  const [disabled, setDisabled] = useState(false)
  const [removeDisabled, setRemoveDisabled] = useState(false)
  const [dragOver, setDragOver] = useState(false)
  const formRef = useRef<HTMLFormElement>(null)
  const fileInputRef = useRef<HTMLInputElement>(null)
  const [selectedOption, setSelectedOption] = useState<radioOptions>(
    radioOptions.WIRETRANSFER
  )

  const handleDelete = (index: number) => {
    const fileArray = [...fileArr]
    fileArray.splice(index, 1)
    setFileArr(() => [...fileArray])
    toast.success('File Deleted Successfully')
  }

  const handleRemove = async (file: FILE_OBJECT, index: number) => {
    if (removeDisabled) return
    setRemoveDisabled(true)
    await deleteFile({
      filePath: `invoice/${invoice.invoiceId}/sellerWireConfirmations/${file?.name}`
    })
    handleDelete(index)
    setRemoveDisabled(false)
  }

  const handleDragEnter = () => {
    setDragOver(true)
  }

  const handleDragLeave = () => {
    setDragOver(false)
  }

  useEffect(() => {
    if (!invoice.sellerContactId) return
    getUserBankAccountInfo(invoice.sellerContactId).then((res) => {
      if (res?.userBankData) {
        setBankAccountDetails(res?.userBankData)
      }
      if (res?.error) {
        toast.error(`Failed to get seller's bank account information.`)
      }
      setLoader(false)
    })
  }, [invoice.sellerContactId])

  const handleUpload = async (
    currentFile: File,
    type: string,
    fileName: string,
    bytesToMegaBytes: number
  ) => {
    const {success} = await uploadFile({
      file: currentFile,
      onFileUpload: (downloadURL?: string) => {
        const file = {
          type,
          name: fileName,
          mbSize: bytesToMegaBytes,
          downloadURL: downloadURL || '',
          originalName: currentFile.name,
          URL: URL.createObjectURL(currentFile)
        }
        setFileArr([file])
        toast.success('File Uploaded Successfully')
        return
      },
      onProgressUpdate: setProgress,
      filePath: `invoice/${invoice.invoiceId}/sellerWireConfirmations/${fileName}`
    })
    if (!success) {
      toast.warn('Something went wrong while uploading the receipt')
    }
  }

  const uploadFileValidations = (file: File) => {
    const {name, sizeInMB, isImage, isPDF, type} = getFileInformation(
      fileArr,
      file
    )
    if (!isCorrectURI(name)) {
      toast.warn(
        'Please rename your file appropriately. The file name contains invalid characters.'
      )
      return
    }
    const fileName = getParsedFileNameForUploadingFile(name)
    if (isImage || isPDF) {
      if (sizeInMB > 10) {
        toast.warning('Uploaded file should not exceed 10MB')
        return
      }
      handleUpload(file, type, fileName, sizeInMB)
      return
    }
    toast.warn('Only Image & PDF format are supported')
  }

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.stopPropagation()
    e.preventDefault()
    if (formStep === 0) return
    if (selectedOption === radioOptions.OTHER && descriptionValue.length < 5) {
      toast.warn('The description must be at least 5 characters')
      return
    }
    if (
      descriptionValue?.length ||
      selectedOption === radioOptions.WIRETRANSFER
    ) {
      if (!fileArr.length) {
        toast.warn(`Please upload wire confirmation`)
        return
      }
      if (!amountPaid) {
        toast.warn('Please enter wire amount paid')
        return
      }
      setDisabled(true)
      const wireConfirmationObj = {
        invoiceId: invoice.invoiceId || '',
        sellerWireAttachment: fileArr[0].downloadURL || '',
        sellerWireAmountPaid: Number(amountPaid),
        sellerWireDescription:
          selectedOption === radioOptions.OTHER
            ? descriptionValue
            : 'Wire Transfer'
      }
      const {data} = await addSellerWire(wireConfirmationObj)
      if (data?.code !== 200) {
        toast.warn(data.msg)
        setDisabled(false)
        return
      }
      sendNotification({
        baseURL: API_HOST_URL,
        code: SellerNotifications.WireTransferInitiated,
        contactId: invoice.sellerContactId,
        productId: invoice.productId
      })
      sendNotification({
        baseURL: API_HOST_URL,
        code: AdminNotifications.WireTransferPayoutInitiatedToSeller,
        contactId: invoice.sellerContactId
      })
      toast.success('Payment confirmation uploaded successfully')
      handleClose()
    } else {
      toast.warn(
        `Please provide wire description if you haven't checked 'I've paid my due amount via: "Wire Transfer".'`
      )
      return
    }
  }

  const offerAmount = withThousandSeperatorString(salePrice)
  const netAmount = withThousandSeperatorString(
    withoutBuyersPremium(salePrice, invoice.saleCommission)
  )
  const sellerFee = withThousandSeperatorString(
    Math.abs(
      salePrice -
        Number(withoutBuyersPremium(salePrice, invoice.saleCommission))
    ).toFixed(2)
  )

  return (
    <div className={styles.container}>
      <form ref={formRef} onSubmit={handleSubmit} className={styles.content}>
        <div className={styles.heading}>
          <Cancel onClick={handleClose} />
          <h1>
            {formStep === 0
              ? `Seller's Bank Account Info`
              : `Seller Payment Confirmation`}
          </h1>
        </div>
        {formStep === 0 ? (
          <React.Fragment>
            {loader ? (
              <div className={styles.loaderWrapper}>
                <Loader fullHeight={true} />
              </div>
            ) : (
              <div className={styles.detailsWrapper}>
                <DetailPage
                  data={
                    bankAccountDetails || {...BANK_ACCOUNT_INFO_INITIAL_STATE}
                  }
                  detailType={DetailType.BankAccount}
                />
              </div>
            )}
          </React.Fragment>
        ) : (
          <React.Fragment>
            <div className={styles.container_img}>
              {productThumbnail && (
                <img alt="product-img" src={productThumbnail} />
              )}
              <div className={styles.info_content}>
                <h5 className="with-ellipsis--single-line">{productTitle}</h5>
                <h4>
                  <b>Date:</b> {getFormattedDate(createdAt) || ''}{' '}
                </h4>
                <div className={styles.info_content_inner_contianer}>
                  <div>
                    <div className={styles.info_content_offer}>
                      Offer Amount
                    </div>
                    <div className={styles.info_content_offer_price}>
                      {offerAmount}
                    </div>
                  </div>
                  <div>
                    <div className={styles.info_content_offer}>Seller Fee</div>
                    <div className={styles.info_content_offer_price}>
                      {sellerFee}
                    </div>
                  </div>
                  <div className={styles.totalAmount}>
                    <div
                      className={classNames(
                        styles.info_content_offer,
                        styles.priceText
                      )}
                    >
                      Net to Seller
                    </div>
                    <div
                      className={classNames(
                        styles.info_content_offer_price,
                        styles.priceText
                      )}
                    >
                      {netAmount}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <h3>Upload Payment Confirmation</h3>
            <div
              className={classNames(styles['upload_section'], {
                [styles['blur']]: dragOver
              })}
              onDragEnter={handleDragEnter}
              onDragOver={(e) => e.preventDefault()}
              onDragLeave={handleDragLeave}
            >
              <p>
                Drag & drop an image or PDF or{' '}
                <button
                  type="button"
                  onClick={() => fileInputRef.current?.click()}
                >
                  Browse
                </button>
              </p>
              <input
                name="files"
                type="file"
                ref={fileInputRef}
                onDropCapture={(e) => {
                  e.stopPropagation()
                  setDragOver(false)
                }}
                onChange={(e) => {
                  if (e?.target?.files) {
                    for (var i = 0; i < e.target.files.length; i++) {
                      var imageFile = e.target.files[i]
                      uploadFileValidations(imageFile)
                    }
                  }
                }}
                onClick={(event) => {
                  const target = event.target as HTMLInputElement
                  target.value = ''
                }}
              />
            </div>
            {fileArr.length > 0 && <div style={{marginBottom: '10px'}} />}
            {fileArr.map((file, index) => {
              const fileName = file?.originalName
              return progress < 100 ? (
                <div className={dragPdfStyles.file_container_green} key={index}>
                  <h4>
                    <PinPdf className={dragPdfStyles.pin_icon} /> {fileName}
                  </h4>
                  <button>Uploading...{progress.toFixed(2)}%</button>
                </div>
              ) : (
                <div className={dragPdfStyles.file_container_red} key={index}>
                  <h4>
                    <PinPdf />
                    {fileName}
                  </h4>
                  <button
                    type="button"
                    disabled={removeDisabled}
                    onClick={() => handleRemove(file, index)}
                  >
                    Remove <Cancel_red />
                  </button>
                </div>
              )
            })}
            <div className={styles.amountPaidInputWrapper}>
              <CurrencyInputField
                label="Amount Paid"
                name="amountPaid"
                id="amountPaid"
                onChange={(value) => setAmountPaid(value)}
                value={amountPaid}
              />
            </div>
            <div className={styles.checkbox_container}>
              <p>{`I've paid my due amount via:`}</p>
              <div className={styles.radioGroup}>
                <div className={styles.radioOption}>
                  <input
                    disabled={disabled}
                    onChange={() =>
                      setSelectedOption(radioOptions.WIRETRANSFER)
                    }
                    name={radioOptions.WIRETRANSFER}
                    value={radioOptions.WIRETRANSFER}
                    checked={selectedOption === radioOptions.WIRETRANSFER}
                    type="radio"
                  />
                  <label htmlFor={radioOptions.WIRETRANSFER}>
                    Wire transfer
                  </label>
                </div>
                <div className={styles.radioOption}>
                  <input
                    disabled={disabled}
                    onChange={() => setSelectedOption(radioOptions.OTHER)}
                    name={radioOptions.OTHER}
                    value={radioOptions.OTHER}
                    checked={selectedOption === radioOptions.OTHER}
                    type="radio"
                  />
                  <label htmlFor={radioOptions.OTHER}>Other</label>
                </div>
              </div>
            </div>
            <div className={styles.description_wrapper}>
              <TextArea
                disabled={selectedOption !== radioOptions.OTHER}
                cols={4}
                rows={4}
                maxLength={150}
                placeholder={'Wire Description'}
                name={'description'}
                value={descriptionValue}
                handleChange={(e) => setDescriptionValue(e.target.value)}
              />
            </div>
          </React.Fragment>
        )}
        <div className={styles.buttons}>
          <button
            type="button"
            className={formStep === 0 ? 'btn-cancel' : ''}
            onClick={formStep === 0 ? handleClose : () => setFormStep(0)}
          >
            {formStep === 0 ? `Cancel` : `Back`}
          </button>
          {formStep === 0 && (
            <button onClick={() => setFormStep(1)}>Next</button>
          )}
          {formStep === 1 && (
            <Button disabled={disabled} type="submit" text={`I've paid`} />
          )}
        </div>
      </form>
    </div>
  )
}

export default UploadSellerWireModal
