import {DealDocument} from 'common'
import {dealStage} from 'common/enums'
import * as Yup from 'yup'

export const INITIAL_DEAL_STATE: DealDocument = {
  dealId: '',
  productId: '',
  chatId: '',
  buyerContactId: '',
  sellerContactId: '',
  isSynced: false,
  lastUpdated: {},
  createdAt: {},
  lastMessage: {
    text: '',
    lastMessageTimestamp: {},
    senderId: ''
  },
  stage: dealStage.InterestedInBuying,
  offers: [],
  requestForInspection: [],
  listingPrice: 0,
  isArchived: false,
  dealName: '',
  buyerAccountId: '',
  buyerAccountName: '',
  buyerContactName: '',
  sellerAccountId: '',
  sellerAccountName: '',
  sellerContactName: '',
  thumbnail: {
    productImage: [],
    buyerProfilePicture: [],
    sellerProfilePicture: []
  },
  location: {
    equipmentCity: '',
    equipmentCountry: '',
    equipmentState: '',
    equipmentStreet: '',
    equipmentZip: ''
  }
}

export const CreateDealSchema = Yup.object({
  dealName: Yup.string().required('Required'),
  buyerContactId: Yup.string().required('Required'),
  buyerAccountId: Yup.string().required('Required'),
  stage: Yup.string().required('Required')
})

export const EditDealSchema = Yup.object({
  stage: Yup.string().required('Required')
})
