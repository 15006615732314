import React, {useCallback, useRef, useState} from 'react'
import styles from './ButtonDropDown.module.scss'
import classNames from 'classnames'
import {ChevronLeft} from 'assets/svgs'
import {useClickOutside} from 'components/utils'

const ButtonDropDown = ({
  buttonText,
  selectOptions,
  customButtonStlyes,
  customOptionsContainerStyles,
  disabled
}: {
  disabled?: boolean
  customButtonStlyes?: string
  customOptionsContainerStyles?: string
  buttonText: string
  selectOptions: Array<{option: string; onClick: () => void}>
}) => {
  const [showOptions, setShowOptions] = useState(false)
  const optionsMenuWindowRef = useRef<HTMLDivElement>(null)
  const toggleOptions = () => setShowOptions(!showOptions)
  const dismissOptionsMenuWindowRef = useCallback(() => {
    setShowOptions(false)
  }, [setShowOptions])
  useClickOutside([optionsMenuWindowRef], dismissOptionsMenuWindowRef)
  return (
    <div ref={optionsMenuWindowRef} className={styles.container}>
      <button
        disabled={disabled}
        className={classNames(styles.button, customButtonStlyes)}
        onClick={toggleOptions}
      >
        {buttonText} <ChevronLeft />
      </button>
      {showOptions && (
        <div
          className={classNames(
            styles.options_container,
            customOptionsContainerStyles
          )}
        >
          {selectOptions.map(({onClick, option}, index) => (
            <React.Fragment>
              <button className={styles.options_button} onClick={onClick}>
                {option}
              </button>
              {index !== selectOptions.length - 1 &&
                selectOptions.length !== 1 && (
                  <hr className={styles.options_seperator} />
                )}
            </React.Fragment>
          ))}
        </div>
      )}
    </div>
  )
}

export default ButtonDropDown
