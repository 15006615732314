import {Cancel, Cancel_red, PinPdf} from 'assets/svgs'
import classNames from 'classnames'
import {FILE_OBJECT, InvoiceDocument} from 'common'
import {
  AdminNotifications,
  BuyerNotifications,
  sendNotification
} from 'common/apis/notifications'
import Button from 'components/Button'
import {useFirebase} from 'components/Firebase/hooks'
import InputField from 'components/InputField'
import CurrencyInputField from 'components/InputField/CurrencyInputField'
import TextArea from 'components/TextArea'
import {API_HOST_URL} from 'components/constants'
import {
  getFileInformation,
  getParsedFileNameForUploadingFile,
  isCorrectURI
} from 'components/utils'
import {useFormik} from 'formik'
import {useRef, useState} from 'react'
import {toast} from 'react-toastify'
import styles from './AddLineItemModal.module.scss'
import dragPdfStyles from './DragStyles.module.scss'

enum radioOptions {
  POSITIVE = 'positive',
  NEGATIVE = 'negative'
}

interface IFormValues {
  amount: string
  quantity: string
  description: string
  option: radioOptions
}

const AddLineItemModal = ({
  handleClose,
  invoice
}: {
  handleClose: () => void
  invoice: InvoiceDocument
}) => {
  const [fileArr, setFileArr] = useState<Array<FILE_OBJECT>>([])
  const [disabled, setDisabled] = useState(false)
  const [removeDisabled, setRemoveDisabled] = useState(false)
  const [progress, setProgress] = useState<number>(100)
  const [dragOver, setDragOver] = useState(false)
  const {addLineItem, uploadFile, deleteFile} = useFirebase()
  const fileInputRef = useRef<HTMLInputElement>(null)

  const handleSubmit = async ({
    amount,
    description,
    option,
    quantity
  }: IFormValues) => {
    setDisabled(true)
    const isCredit = option !== radioOptions.POSITIVE
    const {status, error, message} = await addLineItem({
      attachment: fileArr?.[0]?.downloadURL || '',
      invoiceId: invoice.invoiceId || '',
      quantity: Number(quantity),
      description: description,
      amount: Number(amount),
      isCredit
    })

    if (!status) {
      toast.warn(message ? message : error)
      setDisabled(false)
      return
    }

    sendNotification({
      baseURL: API_HOST_URL,
      code: BuyerNotifications.LineItemAddedToInvoice,
      productId: invoice.productId,
      contactId: invoice.buyerContactId
    })

    sendNotification({
      baseURL: API_HOST_URL,
      code: AdminNotifications.LineItemAddedToInvoice,
      productId: invoice.productId
    })

    toast.success('Line item added successfully!')
    handleClose()
  }

  const validate = (values: IFormValues) => {
    const errors: Partial<IFormValues> = {}
    if (isNaN(Number(values.amount))) {
      errors.amount = 'Please enter a valid amount'
    }
    if (!values.quantity || isNaN(Number(values.quantity))) {
      errors.quantity = 'Please enter a valid quantity'
    }
    if (!values.description || values.description.length < 5) {
      errors.description = 'Description must be at least 5 characters'
    }
    return errors
  }

  const {
    values: {amount, description, option, quantity},
    handleSubmit: onSubmit,
    handleBlur,
    handleChange,
    errors,
    touched,
    setFieldValue
  } = useFormik({
    initialValues: {
      amount: '',
      quantity: '',
      description: '',
      option: radioOptions.POSITIVE
    },
    validate,
    onSubmit: handleSubmit
  })

  const handleDragEnter = () => {
    setDragOver(true)
  }

  const handleDragLeave = () => {
    setDragOver(false)
  }

  const handleDelete = (index: number) => {
    const fileArray = [...fileArr]
    fileArray.splice(index, 1)
    setFileArr(() => [...fileArray])
    toast.success('File Deleted Succesfully')
  }

  const handleRemove = async (file: FILE_OBJECT, index: number) => {
    if (removeDisabled) return
    setRemoveDisabled(true)
    await deleteFile({
      filePath: `invoice/${invoice.invoiceId}/lineItems/${file?.name}`
    })
    handleDelete(index)
    setRemoveDisabled(false)
  }

  const uploadFileValidations = (file: File) => {
    const {name, sizeInMB, isImage, isPDF, type} = getFileInformation(
      fileArr,
      file
    )
    if (!isCorrectURI(name)) {
      toast.warn(
        'Please rename your file appropriately. The file name contains invalid characters.'
      )
      return
    }
    const fileName = getParsedFileNameForUploadingFile(name)
    if (isImage || isPDF) {
      if (sizeInMB > 10) {
        toast.warning('Uploaded file should not exceed 10MB')
        return
      }
      handleUpload(file, type, fileName, sizeInMB)
      return
    }
    toast.warn('Only Image & PDF format are supported')
  }

  const handleUpload = (
    currentFile: File,
    type: string,
    fileName: string,
    bytesToMegaBytes: number
  ) => {
    uploadFile({
      file: currentFile,
      filePath: `invoice/${invoice.invoiceId}/lineItems/${fileName}`,
      onFileUpload: (downloadURL?: string) => {
        const file = {
          type,
          name: fileName,
          mbSize: bytesToMegaBytes,
          downloadURL: downloadURL || '',
          originalName: currentFile.name,
          URL: URL.createObjectURL(currentFile)
        }
        setFileArr([file])
        toast.success('File Uploaded Successfully')
      },
      onProgressUpdate: setProgress
    })
  }

  return (
    <div className={styles.container}>
      <form onSubmit={onSubmit} className={styles.content}>
        <div className={styles.heading}>
          <Cancel onClick={handleClose} />
          <h1>Add Line Item</h1>
        </div>
        <h3>Upload Attachment</h3>
        <div
          className={classNames(styles['upload_section'], {
            [styles['blur']]: dragOver
          })}
          onDragEnter={handleDragEnter}
          onDragOver={(e) => e.preventDefault()}
          onDragLeave={handleDragLeave}
        >
          <p>
            Drag & drop an image or PDF or{' '}
            <button type="button" onClick={() => fileInputRef.current?.click()}>
              Browse
            </button>
          </p>
          <input
            name="files"
            type="file"
            ref={fileInputRef}
            onDropCapture={(e) => {
              e.stopPropagation()
              setDragOver(false)
            }}
            onChange={(e) => {
              if (e?.target?.files) {
                for (var i = 0; i < e.target.files.length; i++) {
                  var imageFile = e.target.files[i]
                  uploadFileValidations(imageFile)
                }
              }
            }}
            onClick={(event) => {
              const target = event.target as HTMLInputElement
              target.value = ''
            }}
          />
        </div>
        {fileArr.length > 0 && <div style={{marginBottom: '10px'}} />}
        {fileArr.map((file: FILE_OBJECT, index: number) => {
          const fileName = file?.originalName
          return progress < 100 ? (
            <div className={dragPdfStyles.file_container_green} key={index}>
              <div className="with-ellipsis--single-line">
                <PinPdf className={styles.pin_icon} /> {file?.name}
              </div>
              <button>Uploading...{progress.toFixed(2)}%</button>
            </div>
          ) : (
            <div className={dragPdfStyles.file_container_red} key={index}>
              <div className={dragPdfStyles.files_container_item}>
                <PinPdf />
                <div className="with-ellipsis--single-line">{fileName}</div>
              </div>
              <button
                type="button"
                disabled={removeDisabled}
                onClick={() => handleRemove(file, index)}
              >
                Remove <Cancel_red />
              </button>
            </div>
          )
        })}
        <div className={styles.amountPaidInputWrapper}>
          <CurrencyInputField
            label="Amount"
            name="amount"
            id="amount"
            onBlur={handleBlur}
            setFieldValue={setFieldValue}
            errors={errors}
            touched={touched}
            value={amount}
          />
        </div>
        <div className={styles.amountPaidInputWrapper}>
          <InputField
            label="Quantity"
            name="quantity"
            id="quantity"
            type="number"
            onChange={handleChange}
            onBlur={handleBlur}
            errors={errors}
            touched={touched}
            value={quantity}
          />
        </div>
        <div style={{margin: '10px 0'}} className={styles.checkbox_container}>
          <p>{`Positive or Negative line item:`}</p>
          <div className={styles.radioGroup}>
            <div className={styles.radioOption}>
              <input
                disabled={disabled}
                name={radioOptions.POSITIVE}
                value={radioOptions.POSITIVE}
                onChange={() => setFieldValue('option', radioOptions.POSITIVE)}
                checked={option === radioOptions.POSITIVE}
                onBlur={handleBlur}
                type="radio"
              />
              <label htmlFor={radioOptions.POSITIVE}>Positive</label>
            </div>
            <div className={styles.radioOption}>
              <input
                disabled={disabled}
                onBlur={handleBlur}
                onChange={() => setFieldValue('option', radioOptions.NEGATIVE)}
                name={radioOptions.NEGATIVE}
                value={radioOptions.NEGATIVE}
                checked={option === radioOptions.NEGATIVE}
                type="radio"
              />
              <label htmlFor={radioOptions.NEGATIVE}>Negative</label>
            </div>
          </div>
        </div>
        <div className={styles.description_wrapper}>
          <TextArea
            cols={4}
            rows={4}
            maxLength={150}
            name={'description'}
            acceptEmoji={false}
            errors={errors}
            value={description}
            touched={touched}
            handleBlur={handleBlur}
            handleChange={handleChange}
            placeholder={'Line item description'}
          />
        </div>
        <div style={{marginTop: '2rem'}} className={styles.buttons}>
          <button type="button" className={'btn-cancel'} onClick={handleClose}>
            Cancel
          </button>
          <Button disabled={disabled} type="submit" text={`Submit`} />
        </div>
      </form>
    </div>
  )
}

export default AddLineItemModal
