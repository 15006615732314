import React from 'react'
import cn from 'classnames'
import {usePagination, DOTS} from '../utils'
import styles from './Pagination.module.scss'

interface PaginationProps {
  onPageChange: (param: number) => void
  totalCount: number
  siblingCount?: number
  currentPage: number
  pageSize: number
  className?: string
  arrowsForNextAndPrev?: boolean
}

const Pagination = ({
  onPageChange,
  totalCount,
  siblingCount = 1,
  currentPage,
  pageSize,
  className,
  arrowsForNextAndPrev = true
}: PaginationProps) => {
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize
  })

  if (paginationRange === undefined) {
    return null
  }

  if (currentPage === 0 || paginationRange.length < 2) {
    return null
  }

  const onNext = (): void => {
    onPageChange(currentPage + 1)
  }

  const onPrevious = (): void => {
    onPageChange(currentPage - 1)
  }

  let lastPage = paginationRange[paginationRange.length - 1]
  return (
    <ul
      className={cn(
        styles['pagination-container'],
        className && styles[className]
      )}
    >
      <li
        className={cn(
          styles['pagination-item'],
          currentPage === 1 && styles.disabled
        )}
        onClick={onPrevious}
      >
        {arrowsForNextAndPrev ? (
          <div className={cn(styles.arrow, styles.left)} />
        ) : (
          <div className={styles.prevLabel}>Previous</div>
        )}
      </li>
      {paginationRange.map((pageNumber) => {
        if (pageNumber === DOTS) {
          return (
            <li
              key={`pagination-pageNumber-${pageNumber}`}
              className={cn(styles['pagination-item'], styles.dots)}
            >
              &#8230;
            </li>
          )
        }
        return (
          <li
            key={`pagination-pageNumber-${pageNumber}`}
            className={cn(
              styles['pagination-item'],
              pageNumber === currentPage && styles.selected,
              !arrowsForNextAndPrev && styles['pagination-item--grey']
            )}
            onClick={() => onPageChange(Number(pageNumber))}
          >
            {pageNumber}
          </li>
        )
      })}
      <li
        className={cn(
          styles['pagination-item'],
          currentPage === lastPage && styles.disabled
        )}
        onClick={onNext}
      >
        {arrowsForNextAndPrev ? (
          <div className={cn(styles.arrow, styles.right)} />
        ) : (
          <div className={styles.nextLabel}>Next</div>
        )}
      </li>
    </ul>
  )
}

export default Pagination
