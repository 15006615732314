import {Sector} from 'common'
import {IFieldSetSingleField} from 'components/types'
import {FieldSet} from '../DetailFields'
import styles from '../DetailPage.module.scss'

const SectorDetails = (data: Sector) => {
  const fields: Array<IFieldSetSingleField> = [
    {
      label: 'Name',
      value: data?.name || '-'
    }
  ]

  return (
    <div className={styles.category_detail_container}>
      <div className={styles.category_detail_inner_container}>
        <div className={styles.inputsfields}>
          <FieldSet title="Sector Details" fields={fields} />
        </div>
      </div>
    </div>
  )
}

export default SectorDetails
