import {ArrowDown} from 'assets/svgs'
import classNames from 'classnames'
import {BankAccountInfoDocument} from 'common'
import {AdminNotifications, sendNotification} from 'common/apis/notifications'
import {BANK_ACCOUNT_INFO_INITIAL_STATE} from 'common/constants'
import {Collection} from 'common/enums'
import {API_HOST_URL} from 'components/constants'
import {DetailType} from 'components/enums'
import {Formik, FormikProps} from 'formik'
import {get} from 'lodash'
import React, {useEffect, useRef, useState} from 'react'
import {toast} from 'react-toastify'
import Button from '../Button'
import DetailPage from '../DetailPage'
import {useAppContext, useFirebase} from '../Firebase/hooks'
import InputField from '../InputField'
import Layout from '../Layout'
import {Loader} from '../Loader'
import NavBar from '../Navbar'
import withAuthorization from '../Session/withAuthorization'
import styles from './BankAccountInformation.module.scss'
import {validationSchema} from './schema'

interface BankAccountInformationProps {
  customClass: string
}

const BankAccountInformation = ({customClass}: BankAccountInformationProps) => {
  const {getDocumentData, updateAdminBankAccountInfo} = useFirebase()
  const {
    appState: {isSuperManager}
  } = useAppContext()
  const [isEdit, setIsEdit] = useState(false)
  const [loader, setLoader] = useState(true)
  const [disabled, setDisabled] = useState(true)
  const [appState, setAppState] = useState<{
    documentData: BankAccountInfoDocument
  }>({
    documentData: BANK_ACCOUNT_INFO_INITIAL_STATE
  })
  const hasDomesticWireInfo =
    appState.documentData?.domesticWireInfo?.accountName ||
    appState.documentData?.domesticWireInfo?.accountNumber ||
    appState.documentData?.domesticWireInfo?.bank ||
    appState.documentData?.domesticWireInfo?.routingNumber
  const hasInernationalWireInfo =
    appState.documentData?.internationalWireInfo?.accountName ||
    appState.documentData?.internationalWireInfo?.accountNumber ||
    appState.documentData?.internationalWireInfo?.bank ||
    appState.documentData?.internationalWireInfo?.routingNumber ||
    appState.documentData?.internationalWireInfo?.swiftCode
  const hasWireInfo = hasInernationalWireInfo || hasDomesticWireInfo
  const formRef: React.Ref<FormikProps<BankAccountInfoDocument>> | undefined =
    useRef(null)

  const cancelCallback = (error: Error) => {
    if (error) {
      toast.error(`Something went wrong while fetching bank account details.`)
    }
  }

  useEffect(() => {
    setTimeout(() => {
      setLoader(false)
    }, 2000)
  }, [isEdit])

  useEffect(() => {
    const {unsSubData} = getDocumentData(
      setAppState,
      cancelCallback,
      Collection.AdminBankAccountDetails,
      Collection.AdminBankAccountDetails
    )
    return () => unsSubData?.()
  }, [])

  useEffect(() => {
    const {documentData} = appState
    if (documentData && !disabled) {
      formRef?.current?.setValues({...documentData})
    }
  }, [appState, formRef, disabled])

  return (
    <div>
      <div className={styles.withLayout}>
        <Layout />
        <div className={classNames(styles.container, customClass)}>
          <NavBar title="Bank Account Information" />
          {loader ? (
            <Loader />
          ) : (
            <React.Fragment>
              <div className={styles.buttons}>
                <div className={styles.twobtn}>
                  {!isEdit && !isSuperManager && (
                    <Button
                      text={hasWireInfo ? 'Edit' : 'Add'}
                      customClass={styles.changes_btn}
                      onClick={() => {
                        setDisabled(false)
                        setIsEdit(true)
                      }}
                    />
                  )}
                  {isEdit && (
                    <span onClick={() => setIsEdit(false)}>
                      <ArrowDown />
                      Back
                    </span>
                  )}
                  {isEdit && (
                    <Button
                      text={'Save'}
                      type="submit"
                      disabled={disabled}
                      customClass={styles.changes_btn}
                      onClick={() => formRef.current?.handleSubmit()}
                    />
                  )}
                </div>
              </div>

              <div className={styles.edit_section}>
                {!isEdit ? (
                  <DetailPage
                    data={
                      appState.documentData || {
                        ...BANK_ACCOUNT_INFO_INITIAL_STATE
                      }
                    }
                    detailType={DetailType.BankAccount}
                  />
                ) : (
                  <Formik
                    innerRef={formRef}
                    validationSchema={validationSchema}
                    initialValues={appState.documentData}
                    onSubmit={async ({
                      domesticWireInfo,
                      internationalWireInfo
                    }: BankAccountInfoDocument) => {
                      if (isSuperManager) {
                        toast.warn(
                          'Insufficient privelages to perform this action.'
                        )
                        return
                      }
                      if (disabled) return
                      setDisabled(true)
                      setLoader(true)
                      const {error} = await updateAdminBankAccountInfo({
                        domesticWireInfo,
                        internationalWireInfo
                      })
                      if (error) {
                        toast.warn(
                          'Something went wrong while updating the bank account information'
                        )
                        setDisabled(false)
                      } else {
                        toast.success(
                          'Bank account details updated successfully'
                        )
                        sendNotification({
                          baseURL: API_HOST_URL,
                          code: AdminNotifications.BankAccountInfoUpdated
                        })
                        setLoader(false)
                        setIsEdit(false)
                      }
                    }}
                  >
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit
                    }) => (
                      <form
                        className={styles.from_submiting}
                        onSubmit={handleSubmit}
                      >
                        <div className={styles.formContainer}>
                          <h1 className={styles.secondheading}>
                            Domestic Wire Info
                          </h1>
                          <div className={styles.inputs}>
                            <div className={styles.inputContainer}>
                              <div className={styles.inputField}>
                                <InputField
                                  name="domesticWireInfo.accountName"
                                  placeholder="Account Name"
                                  value={get(
                                    values,
                                    'domesticWireInfo.accountName'
                                  )}
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  onBlur={handleBlur}
                                  type="text"
                                  disabled={disabled}
                                />
                              </div>
                              <div className={styles.inputField}>
                                <InputField
                                  name="domesticWireInfo.accountNumber"
                                  placeholder="Account Number"
                                  value={get(
                                    values,
                                    'domesticWireInfo.accountNumber'
                                  )}
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  onBlur={handleBlur}
                                  type="text"
                                  disabled={disabled}
                                />
                              </div>
                            </div>
                            <div className={styles.inputContainer}>
                              <div className={styles.inputField}>
                                <InputField
                                  name="domesticWireInfo.bank"
                                  placeholder="Bank"
                                  value={get(values, 'domesticWireInfo.bank')}
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  onBlur={handleBlur}
                                  type="text"
                                  disabled={disabled}
                                />
                              </div>
                              <div className={styles.inputField}>
                                <InputField
                                  name="domesticWireInfo.routingNumber"
                                  placeholder="Routing Number"
                                  value={get(
                                    values,
                                    'domesticWireInfo.routingNumber'
                                  )}
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  onBlur={handleBlur}
                                  type="text"
                                  disabled={disabled}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className={styles.formContainer}>
                          <h1 className={styles.secondheading}>
                            International Wire Info
                          </h1>
                          <div className={styles.inputs}>
                            <div className={styles.inputContainer}>
                              <div className={styles.inputField}>
                                <InputField
                                  name="internationalWireInfo.accountName"
                                  placeholder="Account Name"
                                  value={get(
                                    values,
                                    'internationalWireInfo.accountName'
                                  )}
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  onBlur={handleBlur}
                                  type="text"
                                  disabled={disabled}
                                />
                              </div>
                              <div className={styles.inputField}>
                                <InputField
                                  name="internationalWireInfo.accountNumber"
                                  placeholder="Account Number"
                                  value={get(
                                    values,
                                    'internationalWireInfo.accountNumber'
                                  )}
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  onBlur={handleBlur}
                                  type="text"
                                  disabled={disabled}
                                />
                              </div>
                            </div>
                            <div className={styles.inputContainer}>
                              <div className={styles.inputField}>
                                <InputField
                                  name="internationalWireInfo.bank"
                                  placeholder="Bank"
                                  value={get(
                                    values,
                                    'internationalWireInfo.bank'
                                  )}
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  onBlur={handleBlur}
                                  type="text"
                                  disabled={disabled}
                                />
                              </div>
                              <div className={styles.inputField}>
                                <InputField
                                  name="internationalWireInfo.routingNumber"
                                  placeholder="Routing Number"
                                  value={get(
                                    values,
                                    'internationalWireInfo.routingNumber'
                                  )}
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  onBlur={handleBlur}
                                  type="text"
                                  disabled={disabled}
                                />
                              </div>
                            </div>
                            <div className={styles.inputContainer}>
                              <div className={styles.inputField}>
                                <InputField
                                  name="internationalWireInfo.swiftCode"
                                  placeholder="Swift Code"
                                  value={get(
                                    values,
                                    'internationalWireInfo.swiftCode'
                                  )}
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  onBlur={handleBlur}
                                  type="text"
                                  disabled={disabled}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    )}
                  </Formik>
                )}
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  )
}

export default withAuthorization(BankAccountInformation)
