import {TaxExemptionDocument} from 'common'
import {applicationStatus} from 'common/enums'
import AlgoliaClient, {
  SALE_TAX_EXEMPTION_ADMIN_PANEL_ALGOLIA_INDEX
} from 'components/AlgoliaSearch'
import InputField from 'components/InputField'
import Pagination from 'components/Pagination'
import ReqNotFoundScreen from 'components/ReqNotFoundScreen'
import {TABLE_PAGINATION_PAGE_SIZE} from 'components/constants'
import {Timestamp} from 'firebase/firestore'
import {debounce, isDate, last, startCase} from 'lodash'
import React, {useEffect, useState} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import Layout from '../Layout'
import {Loader} from '../Loader'
import NavBar from '../Navbar'
import withAuthorization from '../Session/withAuthorization'
import Table from '../TableComponent'
import {
  convertToDate,
  getFormattedDate,
  searchWithAlgolia,
  useSortableData
} from '../utils'
import styles from './TaxExemptionApplications.module.scss'

const TaxExemptionApplications = ({customClass}: {customClass: string}) => {
  const navigate = useNavigate()
  const {state} = useLocation()
  const pageFromLocation = state?.page
  const [loader, setLoader] = useState(true)
  const [searchValue, setSearchValue] = useState('')
  const [pageCount, setPageCount] = useState<number>(0)
  const [currentPage, setCurrentPage] = useState<number>(pageFromLocation || 1)
  const [taxExemptionData, setTaxExemptionData] = useState<
    Array<TaxExemptionDocument>
  >([])

  const searchIndex = AlgoliaClient.initIndex(
    SALE_TAX_EXEMPTION_ADMIN_PANEL_ALGOLIA_INDEX
  )

  const debouncedSearch = React.useRef(
    debounce(searchWithAlgolia<TaxExemptionDocument>, 800)
  )

  useEffect(() => {
    AlgoliaClient.clearCache()
    searchWithAlgolia<TaxExemptionDocument>({
      searchQuery: '*',
      searchIndex,
      setLoaderState: setLoader,
      setData: setTaxExemptionData,
      setPageCount,
      currentPage
    }).then(() => setLoader(false))
  }, [])

  const tableTitles = [
    {title: 'Account Name', sortBy: () => sortBy('accountName')},
    {title: 'Contact Name', sortBy: () => sortBy('contactName')},
    {title: 'Account ID'},
    {title: 'Contact ID'},
    {title: 'Application Status', sortBy: () => sortBy('status')},
    {title: 'Last Modified', sortBy: () => sortBy('date')}
  ]

  const getFormattedData = (taxExemptionData: TaxExemptionDocument) => {
    const {
      contactName,
      accountName,
      contactId,
      accountId,
      certificates,
      lastUpdated: lastUpdatedAt
    } = taxExemptionData
    const lastUpdatedDate = lastUpdatedAt && convertToDate(lastUpdatedAt)
    const status = startCase(last(certificates)?.status)
    const lastUpdated = isDate(lastUpdatedDate)
      ? getFormattedDate(lastUpdatedDate)
      : '-'
    const date = isDate(lastUpdatedDate)
      ? Timestamp.fromDate(lastUpdatedDate).seconds
      : '-'
    return {
      contactId,
      accountId,
      contactName,
      accountName,
      status,
      lastUpdated,
      date,
      sortDate:
        (lastUpdatedDate && Timestamp.fromDate(lastUpdatedDate).seconds) || 0
    }
  }

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event?.target?.value
    setSearchValue(value)
    if (value) {
      debouncedSearch?.current({
        searchQuery: value,
        searchIndex,
        setLoaderState: setLoader,
        setData: setTaxExemptionData,
        setPageCount
      })
    } else {
      debouncedSearch?.current({
        searchQuery: '*',
        searchIndex,
        setLoaderState: setLoader,
        setData: setTaxExemptionData,
        setPageCount,
        currentPage
      })
    }
  }

  const getRowData = (
    rowData: Array<{
      contactId: string
      contactName: string
      accountId: string
      accountName: string
      status: applicationStatus
      lastUpdated: string
      date: string | number
    }>
  ) => {
    return (rowData || []).map((singleRowData, index) => {
      const {
        contactName,
        accountName,
        status,
        lastUpdated,
        contactId,
        accountId
      } = singleRowData
      return (
        <tr
          key={`tax-exemption-row-data-${index}`}
          onClick={() => {
            navigate(
              '/tax-exemption-applications/tax-exemption-application-details',
              {
                state: {id: contactId, currentPage},
                preventScrollReset: true
              }
            )
          }}
        >
          <td>{accountName || '-'}</td>
          <td>{contactName || '-'}</td>
          <td>{contactId || '-'}</td>
          <td>{accountId || '-'}</td>
          <td>{status || '-'}</td>
          <td>{lastUpdated || '-'}</td>
        </tr>
      )
    })
  }

  const noData = !taxExemptionData.length && !loader

  const scrollRef: React.RefObject<HTMLDivElement> = React.createRef()

  const scrollToRef = () =>
    scrollRef?.current?.scrollIntoView({behavior: 'smooth'})

  const formattedTaxExemptionData = taxExemptionData.map((data) =>
    getFormattedData(data)
  )
  const {items, sortBy} = useSortableData(formattedTaxExemptionData)

  const showPagination = pageCount > TABLE_PAGINATION_PAGE_SIZE

  const onPageChange = (page: number) => {
    setLoader(true)
    setCurrentPage(page)
    debouncedSearch?.current({
      searchQuery: searchValue,
      searchIndex,
      setLoaderState: setLoader,
      setData: setTaxExemptionData,
      setPageCount,
      currentPage: page
    })
    setLoader(false)
    scrollToRef()
  }

  return (
    <div className={styles.withLayout}>
      <Layout />
      <div className={[styles.container, customClass].join(' ')}>
        <NavBar title="Tax Exemption Applications" />
        <div className={styles.button_filters}>
          <InputField
            name="taxExemptionSearchField"
            type="text"
            value={searchValue}
            onChange={handleSearch}
            customClass={styles.inputField}
            placeholder="Search for Filters"
            isSearch={true}
          />
        </div>
        {loader ? (
          <Loader />
        ) : noData ? (
          <ReqNotFoundScreen />
        ) : (
          <React.Fragment>
            <Table titles={tableTitles} rowData={getRowData(items)} />
            {showPagination && (
              <div className="pagination__wrapper">
                <Pagination
                  currentPage={currentPage}
                  totalCount={pageCount}
                  pageSize={TABLE_PAGINATION_PAGE_SIZE}
                  onPageChange={onPageChange}
                />
              </div>
            )}
          </React.Fragment>
        )}
      </div>
    </div>
  )
}

export default withAuthorization(TaxExemptionApplications)
