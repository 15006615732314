import classNames from 'classnames'
import {INITIAL_BUY_NOW_PRODUCT_STATE} from 'common/constants'
import {listingType} from 'common/enums'
import PhoneInputSelect from 'components/PhoneInput'
import {get} from 'lodash'
import React, {useState} from 'react'
import {CheckedIcon, UncheckedIcon} from '../../../assets/images'
import InputField from '../../InputField'
import styles from '../ProductDetailPage.module.scss'
interface FaqSectionProps {
  touched: {} | undefined
  errors?: {} | undefined
  disabled?: boolean
  handleBlur?: (e: any) => void | undefined
  handleChange?: (e: any) => void | undefined
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<any>
  values: typeof INITIAL_BUY_NOW_PRODUCT_STATE | any
  requestedChanges?: {
    [key: string]: string
  }
}

const FaqSection = ({
  values,
  handleChange,
  errors,
  touched,
  handleBlur,
  disabled,
  setFieldValue
}: FaqSectionProps) => {
  const [active, setActive] = useState(true)
  const isAuction = values.listingType === listingType.Auction

  return (
    <React.Fragment>
      <div className={classNames(styles.formHeading, styles.questionHeading)}>
        Questions
      </div>

      <div className={styles.questionsBox}>
        <div className={styles.withCheckbox}>
          {!isAuction && (
            <div className={styles.containerCheck}>
              <div className={styles.questionsContainer}>
                <div className={styles.questionTag}>
                  Allow Buyers to make you offers?
                </div>
                <div className={styles.inputCheckbox}>
                  <div className={styles.inputField}>
                    <InputField
                      placeholder="Yes"
                      inputFieldIcon={
                        get(values, 'acceptOffers')
                          ? CheckedIcon
                          : UncheckedIcon
                      }
                      iconClick={() => {
                        setFieldValue('acceptOffers', true)
                      }}
                    />
                  </div>
                  <div className={styles.inputField}>
                    <InputField
                      placeholder="No"
                      inputFieldIcon={
                        !get(values, 'acceptOffers')
                          ? CheckedIcon
                          : UncheckedIcon
                      }
                      iconClick={() => {
                        setFieldValue('acceptOffers', false)
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className={styles.containerCheck}>
            <div className={styles.questionsContainer}>
              <div className={styles.questionTag}>
                Is this a titled piece of equipment?
              </div>
              <div className={styles.inputCheckbox}>
                <div className={styles.inputField}>
                  <InputField
                    placeholder="Yes"
                    inputFieldIcon={
                      get(values, 'isThisATitledPieceOfEquipment')
                        ? CheckedIcon
                        : UncheckedIcon
                    }
                    iconClick={() => {
                      setFieldValue('isThisATitledPieceOfEquipment', true)
                      setFieldValue(
                        'isThisATitledPieceOfEquipment.equipmentTitle',
                        get(values, 'title') || ''
                      )
                    }}
                  />
                </div>
                <div className={styles.inputField}>
                  <InputField
                    placeholder="No"
                    inputFieldIcon={
                      !get(values, 'isThisATitledPieceOfEquipment')
                        ? CheckedIcon
                        : UncheckedIcon
                    }
                    iconClick={() => {
                      setFieldValue('isThisATitledPieceOfEquipment', false)
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.withCheckbox}>
          <div className={styles.containerCheck}>
            <div className={styles.questionsContainer}>
              <div className={styles.questionTag}>
                Will you load the equipment on to the buyers trucks?
              </div>
              <div className={styles.inputCheckbox}>
                <div className={styles.inputField}>
                  <InputField
                    placeholder="Yes"
                    inputFieldIcon={
                      get(values, 'willYouLoadTheEquipmentOnToTheBuyersTrucks')
                        ? CheckedIcon
                        : UncheckedIcon
                    }
                    iconClick={() => {
                      setFieldValue(
                        'willYouLoadTheEquipmentOnToTheBuyersTrucks',
                        true
                      )
                    }}
                  />
                </div>
                <div className={styles.inputField}>
                  <InputField
                    placeholder="No"
                    inputFieldIcon={
                      !get(values, 'willYouLoadTheEquipmentOnToTheBuyersTrucks')
                        ? CheckedIcon
                        : UncheckedIcon
                    }
                    iconClick={() => {
                      setFieldValue(
                        'willYouLoadTheEquipmentOnToTheBuyersTrucks',
                        false
                      )
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={styles.containerCheck}>
            <div className={styles.questionsContainer}>
              <div className={styles.questionTag}>
                Does this equipment require dismantling?
              </div>
              <div className={styles.inputCheckbox}>
                <div className={styles.inputField}>
                  <InputField
                    placeholder="Yes"
                    inputFieldIcon={
                      get(values, 'doesThisRequireDismantling')
                        ? CheckedIcon
                        : UncheckedIcon
                    }
                    iconClick={() => {
                      setFieldValue('doesThisRequireDismantling', {})
                    }}
                  />
                </div>
                <div className={styles.inputField}>
                  <InputField
                    placeholder="No"
                    inputFieldIcon={
                      !get(values, 'doesThisRequireDismantling')
                        ? CheckedIcon
                        : UncheckedIcon
                    }
                    iconClick={() => {
                      setFieldValue('doesThisRequireDismantling', null)
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {Boolean(values?.doesThisRequireDismantling) && (
          <div className={styles.withCheckbox}>
            <div className={styles.containerCheck}>
              <div className={styles.questionsContainer}>
                <div className={styles.questionTag}>
                  Will Dismantling Require Crane Service?
                </div>
                <div className={styles.inputCheckbox}>
                  <div className={styles.inputField}>
                    <InputField
                      placeholder="Yes"
                      inputFieldIcon={
                        get(
                          values,
                          'doesThisRequireDismantling.willDismantlingRequireCraneService'
                        )
                          ? CheckedIcon
                          : UncheckedIcon
                      }
                      iconClick={() => {
                        setFieldValue(
                          'doesThisRequireDismantling.willDismantlingRequireCraneService',
                          true
                        )
                      }}
                    />
                  </div>
                  <div className={styles.inputField}>
                    <InputField
                      placeholder="No"
                      inputFieldIcon={
                        !get(
                          values,
                          'doesThisRequireDismantling.willDismantlingRequireCraneService'
                        )
                          ? CheckedIcon
                          : UncheckedIcon
                      }
                      iconClick={() => {
                        setFieldValue(
                          'doesThisRequireDismantling.willDismantlingRequireCraneService',
                          false
                        )
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.containerCheck}>
              <div className={styles.questionsContainer}>
                <div className={styles.questionTag}>
                  Are you willing to help with dismantling?
                </div>
                <div className={styles.inputCheckbox}>
                  <div className={styles.inputField}>
                    <InputField
                      placeholder="Yes"
                      inputFieldIcon={
                        get(
                          values,
                          'doesThisRequireDismantling.areYouWillingToHelpWithDismantling'
                        )
                          ? CheckedIcon
                          : UncheckedIcon
                      }
                      iconClick={() => {
                        setFieldValue(
                          'doesThisRequireDismantling.areYouWillingToHelpWithDismantling.toWhatExtentAreYouWillingToHelp',
                          ''
                        )
                      }}
                    />
                  </div>
                  <div className={styles.inputField}>
                    <InputField
                      placeholder="No"
                      inputFieldIcon={
                        !get(
                          values,
                          'doesThisRequireDismantling.areYouWillingToHelpWithDismantling'
                        )
                          ? CheckedIcon
                          : UncheckedIcon
                      }
                      iconClick={() => {
                        setFieldValue(
                          'doesThisRequireDismantling.areYouWillingToHelpWithDismantling',
                          null
                        )
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {Boolean(
          values?.doesThisRequireDismantling?.areYouWillingToHelpWithDismantling
        ) && (
          <div className={styles.withCheckbox}>
            <div className={styles.containerCheck}></div>
            <div
              className={classNames(styles.containerCheck, styles.fullInput)}
            >
              <div className={styles.inputs}>
                <div className={styles.inputContainer}>
                  <div
                    className={classNames(
                      styles.inputField,
                      styles.fullInputField
                    )}
                  >
                    <InputField
                      name="doesThisRequireDismantling.areYouWillingToHelpWithDismantling.toWhatExtentAreYouWillingToHelp"
                      value={get(
                        values,
                        'doesThisRequireDismantling.areYouWillingToHelpWithDismantling.toWhatExtentAreYouWillingToHelp'
                      )}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      touched={touched}
                      errors={errors}
                      placeholder={'To what extent are you willing to help?'}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className={classNames(styles.withCheckbox, styles.marginClass)}>
        <div className={styles.containerCheck}>
          <div className={styles.questionsContainer}>
            <div className={styles.questionTag}>
              Is the equipment at a different address?
            </div>
            <div className={styles.inputCheckbox}>
              <div className={styles.inputField}>
                <InputField
                  placeholder="Yes"
                  inputFieldIcon={
                    get(
                      values,
                      'isTheEquipmentAtADifferentAddress.isEquipmentAtADifferentAddress'
                    )
                      ? CheckedIcon
                      : UncheckedIcon
                  }
                  iconClick={() => {
                    setFieldValue(
                      'isTheEquipmentAtADifferentAddress.isEquipmentAtADifferentAddress',
                      true
                    )
                  }}
                />
              </div>
              <div className={styles.inputField}>
                <InputField
                  placeholder="No"
                  inputFieldIcon={
                    !get(
                      values,
                      'isTheEquipmentAtADifferentAddress.isEquipmentAtADifferentAddress'
                    )
                      ? CheckedIcon
                      : UncheckedIcon
                  }
                  iconClick={() => {
                    setFieldValue(
                      'isTheEquipmentAtADifferentAddress.isEquipmentAtADifferentAddress',
                      false
                    )
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.containerCheck}>
          <div className={styles.questionsContainer}>
            <div className={styles.questionTag}>
              Will You Provide The Electrical Disconnect If Required?
            </div>
            <div className={styles.inputCheckbox}>
              <div className={styles.inputField}>
                <InputField
                  placeholder="Yes"
                  inputFieldIcon={
                    get(
                      values,
                      'willYouProvideTheElectricalDisconnectIfRequired'
                    )
                      ? CheckedIcon
                      : UncheckedIcon
                  }
                  iconClick={() => {
                    setFieldValue(
                      'willYouProvideTheElectricalDisconnectIfRequired',
                      true
                    )
                  }}
                />
              </div>
              <div className={styles.inputField}>
                <InputField
                  placeholder="No"
                  inputFieldIcon={
                    !get(
                      values,
                      'willYouProvideTheElectricalDisconnectIfRequired'
                    )
                      ? CheckedIcon
                      : UncheckedIcon
                  }
                  iconClick={() => {
                    setFieldValue(
                      'willYouProvideTheElectricalDisconnectIfRequired',
                      false
                    )
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {get(
        values,
        'isTheEquipmentAtADifferentAddress.isEquipmentAtADifferentAddress'
      ) && (
        <React.Fragment>
          <div className={styles.formHeading}>Address</div>
          <div className={styles.inputs}>
            <div className={styles.inputContainer}>
              <div className={styles.inputField}>
                <div className={styles.dropdown}>
                  <div>
                    <InputField
                      customClass={classNames(
                        styles.dropdowninput,
                        active ? styles.dropdownblock : ''
                      )}
                      placeholder="City"
                      value={get(
                        values,
                        'isTheEquipmentAtADifferentAddress.equipmentCity'
                      )}
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      disabled={disabled}
                      onBlur={handleBlur}
                      name="isTheEquipmentAtADifferentAddress.equipmentCity"
                    />
                    <div
                      onClick={() => setActive(!active)}
                      className={classNames(
                        styles.disblock,
                        `${active ? styles.disnone : ''}`
                      )}
                    >
                      ____
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.inputField}>
                <div className={styles.dropdown}>
                  <div>
                    <InputField
                      customClass={classNames(
                        styles.dropdowninput,
                        active ? styles.dropdownblock : ''
                      )}
                      placeholder="State"
                      value={get(
                        values,
                        'isTheEquipmentAtADifferentAddress.equipmentState'
                      )}
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      disabled={disabled}
                      onBlur={handleBlur}
                      name="isTheEquipmentAtADifferentAddress.equipmentState"
                    />
                    <div
                      onClick={() => setActive(!active)}
                      className={classNames(
                        styles.disblock,
                        `${active ? styles.disnone : ''}`
                      )}
                    >
                      ____
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.inputContainer}>
              <div className={styles.inputField}>
                <InputField
                  name="isTheEquipmentAtADifferentAddress.equipmentZip"
                  placeholder="ZipCode"
                  value={get(
                    values,
                    'isTheEquipmentAtADifferentAddress.equipmentZip'
                  )}
                  onChange={handleChange}
                  errors={errors}
                  touched={touched}
                  onBlur={handleBlur}
                  type="text"
                  disabled={disabled}
                />
              </div>
              <div className={styles.inputField}>
                <div className={styles.dropdown}>
                  <div>
                    <InputField
                      customClass={classNames(
                        styles.dropdowninput,
                        active ? styles.dropdownblock : ''
                      )}
                      placeholder="Country"
                      value={get(
                        values,
                        'isTheEquipmentAtADifferentAddress.equipmentCountry'
                      )}
                      onChange={handleChange}
                      errors={errors}
                      touched={touched}
                      disabled={disabled}
                      onBlur={handleBlur}
                      name="isTheEquipmentAtADifferentAddress.equipmentCountry"
                    />
                    <div
                      onClick={() => setActive(!active)}
                      className={classNames(
                        styles.disblock,
                        `${active ? styles.disnone : ''}`
                      )}
                    >
                      ____
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.inputContainer}>
              <div className={styles.inputField}>
                <InputField
                  name="isTheEquipmentAtADifferentAddress.equipmentStreet"
                  placeholder="Street"
                  value={get(
                    values,
                    'isTheEquipmentAtADifferentAddress.equipmentStreet'
                  )}
                  onChange={handleChange}
                  errors={errors}
                  touched={touched}
                  onBlur={handleBlur}
                  type="text"
                  disabled={disabled}
                />
              </div>
              <div className={styles.inputField}></div>
            </div>
          </div>
        </React.Fragment>
      )}
      <div className={classNames(styles.withCheckbox, styles.marginClass)}>
        <div className={styles.containerCheck}>
          <div className={styles.questionsContainer}>
            <div className={styles.questionTag}>
              Is there a lien on the equipment?
            </div>
            <div className={styles.inputCheckbox}>
              <div className={styles.inputField}>
                <InputField
                  placeholder="Yes"
                  inputFieldIcon={
                    get(values, 'lienInfo') ? CheckedIcon : UncheckedIcon
                  }
                  iconClick={() => {
                    setFieldValue('lienInfo.lienHolderName', '')
                    setFieldValue('lienInfo.lienHolderEmail', '')
                    setFieldValue('lienInfo.lienHolderPhone', '')
                    setFieldValue('lienInfo.lienHolderPayoffAmount', '')
                  }}
                />
              </div>
              <div className={styles.inputField}>
                <InputField
                  placeholder="No"
                  inputFieldIcon={
                    !get(values, 'lienInfo') ? CheckedIcon : UncheckedIcon
                  }
                  iconClick={() => {
                    setFieldValue('lienInfo', undefined)
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.containerCheck}></div>
      </div>
      {get(values, 'lienInfo') ? (
        <React.Fragment>
          <div className={styles.formHeading}>Lien Info</div>
          <div className={styles.inputs}>
            <div className={styles.inputContainer}>
              <div className={styles.inputField}>
                <InputField
                  name="lienInfo.lienHolderName"
                  placeholder="Lien Holder Name"
                  value={get(values, 'lienInfo.lienHolderName')}
                  onChange={handleChange}
                  errors={errors}
                  touched={touched}
                  onBlur={handleBlur}
                  type="text"
                  disabled={disabled}
                />
              </div>
              <div className={styles.inputField}>
                <InputField
                  name="lienInfo.lienHolderEmail"
                  placeholder="Lien Holder Email"
                  value={get(values, 'lienInfo.lienHolderEmail')}
                  onChange={handleChange}
                  errors={errors}
                  touched={touched}
                  onBlur={handleBlur}
                  type="text"
                  disabled={disabled}
                />
              </div>
            </div>
            <div className={styles.inputContainer}>
              <div className={styles.inputField}>
                <PhoneInputSelect
                  placeholder="Lien Holder Phone"
                  name="lienInfo.lienHolderPhone"
                  value={get(values, 'lienInfo.lienHolderPhone')}
                  setFieldValue={setFieldValue}
                  errors={errors}
                  touched={touched}
                  onBlur={handleBlur}
                  type="text"
                  disabled={disabled}
                  id="lienPhoneNumber"
                />
              </div>
              <div className={styles.inputField}>
                <InputField
                  name="lienInfo.lienHolderPayoffAmount"
                  placeholder="Lien Holder Pay Off Amount"
                  value={get(values, 'lienInfo.lienHolderPayoffAmount')}
                  onChange={handleChange}
                  errors={errors}
                  touched={touched}
                  onBlur={handleBlur}
                  type="number"
                  min={0}
                  disabled={disabled}
                />
              </div>
            </div>
          </div>
        </React.Fragment>
      ) : null}
    </React.Fragment>
  )
}

export default FaqSection
