import React, {useEffect} from 'react'
import {Building} from '../../../assets/svgs'
import styles from '../ProductDetailPage.module.scss'
import {get} from 'lodash'
import InputField from '../../InputField'
import DropDownSelect from '../../DropDown'
import classNames from 'classnames'
import InputRadio from '../../InputRadio'
import DatePickerComponent from '../../DatePicker'
import {addDays, convertToDate} from 'components/utils'
import {
  DURATION_OPTIONS,
  INITIAL_BUY_NOW_PRODUCT_STATE,
  PUBLISH_ACTIONS
} from 'common/constants'
import {publishAuction} from 'common/enums'
import CurrencyInputField from 'components/InputField/CurrencyInputField'
interface AuctionDetailProps {
  values: typeof INITIAL_BUY_NOW_PRODUCT_STATE | any
  handleChange?: (e: any) => void | undefined
  handleBlur?: (e: any) => void
  errors?: {} | undefined
  touched?: {} | undefined
  disabled?: any
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<any>
}

const AuctionDetail = ({
  values,
  handleChange,
  errors,
  touched,
  handleBlur,
  disabled,
  setFieldValue
}: AuctionDetailProps) => {
  const preventInput = (event: React.KeyboardEvent) =>
    ['e', 'E', '+', '-', '.'].includes(event.key) && event.preventDefault()

  const durationOptionValues = DURATION_OPTIONS.map(({value}) =>
    Number(value)
  ).filter(Boolean)

  const minDate = addDays(new Date(), 1)
  const auctionDuration = values?.auctionInfo?.auctionDuration
  const auctionDurationValue = values?.auctionInfo?.auctionDurationValue
  const auctionValue = auctionDuration?.toString()
  const scheduledTimeErrors = get(errors, 'auctionInfo.auctionScheduledTime')

  const auctionDropdownValue = DURATION_OPTIONS.findIndex(
    (option) => option.value === auctionValue
  )

  const otherValueIndex = DURATION_OPTIONS.findIndex(
    (option) => option.value === 'Other'
  )

  const isOther = !durationOptionValues.includes(Number(auctionValue)) || false
  const openingPrice = get(values, 'openingPriceMap.openingPrice')
  const openingPriceValue = openingPrice ? openingPrice : undefined
  const buyNowPrice = get(values, 'buyNowPriceMap.buyNowPrice')
  const buyNowPriceValue = buyNowPrice ? buyNowPrice : undefined
  const initialReservePrice = get(values, 'initialReservePriceMap.reservePrice')
  const initialReservePriceValue = initialReservePrice
    ? initialReservePrice
    : undefined

  useEffect(() => {
    setFieldValue(
      'auctionInfo.auctionDurationValue',
      get(values, 'auctionInfo.auctionDuration')
    )
  }, [isOther, auctionValue])

  return (
    <React.Fragment>
      <div className={styles.formHeading}>Auction Details</div>
      <div className={styles.inputs}>
        <div className={styles.inputContainer}>
          <div className={styles.inputField}>
            <div className={styles.dropdown}>
              <div>
                <DropDownSelect
                  firstIcon={<Building />}
                  options={DURATION_OPTIONS.map((opt) => opt.label)}
                  customClass={classNames(
                    styles.dropdowninput,
                    styles.dropdownblock
                  )}
                  placeholder="Duration"
                  customValue={
                    isOther
                      ? DURATION_OPTIONS[otherValueIndex].label
                      : undefined
                  }
                  value={
                    isOther
                      ? DURATION_OPTIONS[otherValueIndex]
                      : auctionDropdownValue
                  }
                  onChange={(index) => {
                    const value = DURATION_OPTIONS[index].value
                    setFieldValue('auctionInfo.auctionDuration', value)
                  }}
                  cancelClickFunction={() => {
                    setFieldValue('auctionInfo.auctionDuration', '3')
                    setFieldValue('auctionInfo.auctionDurationValue', 3)
                  }}
                  errors={errors}
                  touched={touched}
                  disabled={disabled}
                  onBlur={handleBlur}
                  name="auctionInfo.auctionDuration"
                />
                <div className={classNames(styles.disblock, styles.disnone)}>
                  ____
                </div>
              </div>
            </div>
          </div>
          <div className={styles.inputField}>
            <CurrencyInputField
              setFieldValue={setFieldValue}
              name="openingPriceMap.openingPrice"
              value={openingPriceValue}
              onBlur={handleBlur}
              touched={touched}
              errors={errors}
              placeholder="Starting Price"
            />
          </div>
        </div>
        {isOther && (
          <div className={styles.inputContainer}>
            <div className={styles.inputField}>
              <InputField
                name="auctionInfo.auctionDurationValue"
                placeholder="Enter number of days"
                defaultValue={auctionDuration}
                value={auctionDurationValue}
                onKeyDown={preventInput}
                onChange={handleChange}
                onBlur={handleBlur}
                touched={touched}
                errors={errors}
                type="number"
                min={3}
                max={180}
              />
            </div>
            <div className={styles.inputField}></div>
          </div>
        )}

        <div
          className={classNames(styles.inputContainer, styles.custom_margin)}
        >
          <div className={styles.inputField}>
            <InputRadio
              name="auctionInfo.publishAuction"
              label={'Publish Auction'}
              selected={get(values, 'auctionInfo.publishAuction')}
              radioOptions={PUBLISH_ACTIONS}
              onChange={(e) => {
                const value = e.target.value
                setFieldValue('auctionInfo.publishAuction', value)
                const auctionScheduledTimeValue =
                  value === publishAuction.NOW
                    ? new Date()
                    : addDays(new Date(), 1)
                setFieldValue(
                  'auctionInfo.auctionScheduledTime',
                  auctionScheduledTimeValue
                )
              }}
              onBlur={handleBlur}
              touched={touched}
              errors={errors}
            />
          </div>
          <div className={classNames(styles.inputField, styles.fullInput)}>
            <CurrencyInputField
              customClass={styles.fullInputField}
              name="buyNowPriceMap.buyNowPrice"
              value={buyNowPriceValue}
              onBlur={handleBlur}
              touched={touched}
              errors={errors}
              placeholder="Buy it Now Price"
              setFieldValue={setFieldValue}
            />
          </div>
        </div>

        <div className={styles.inputContainer}>
          <div className={styles.inputField}>
            <DatePickerComponent
              name="auctionInfo.auctionScheduledTime"
              selectedValue={convertToDate(
                get(values, 'auctionInfo.auctionScheduledTime')
              )}
              setFieldValue={setFieldValue}
              minDate={minDate}
              disabled={
                get(values, 'auctionInfo.publishAuction') === publishAuction.NOW
              }
            />
            {scheduledTimeErrors && (
              <div className={styles['error-message']}>
                {scheduledTimeErrors}
              </div>
            )}
          </div>
          <div className={classNames(styles.inputField, styles.fullInput)}>
            <CurrencyInputField
              customClass={styles.fullInputField}
              name="initialReservePriceMap.reservePrice"
              value={initialReservePriceValue}
              onBlur={handleBlur}
              setFieldValue={setFieldValue}
              touched={touched}
              errors={errors}
              placeholder="Reserve Price"
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default AuctionDetail
