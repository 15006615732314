import cn from 'classnames'
import {Collection} from 'common/enums'
import {DetailType} from 'components/enums'
import React, {useEffect, useState} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {toast} from 'react-toastify'
import {ArrowDown} from '../../assets/svgs'
import DetailPage from '../DetailPage'
import {useFirebase} from '../Firebase/hooks'
import Layout from '../Layout'
import {Loader} from '../Loader'
import NavBar from '../Navbar'
import withAuthorization from '../Session/withAuthorization'
import styles from './TaxExemptionDetailPage.module.scss'

interface TaxExemptionDetailPageProps {
  customClass: string
}

const TaxExemptionDetailPage = ({customClass}: TaxExemptionDetailPageProps) => {
  const navigate = useNavigate()
  const {state} = useLocation()
  const {getDocumentData} = useFirebase()
  const contactId = state?.id || ''
  const [loader, setLoader] = useState(true)
  const previousPage = state?.currentPage
  const [appState, setAppState] = useState<any>({
    documentData: {}
  })

  const cancelCallback = (error: Error) => {
    if (error) {
      toast.error(`Something went wrong while fetching the finance document.`)
      console.error(error)
    }
  }

  useEffect(() => {
    if (!contactId) return
    const {unsSubData} = getDocumentData(
      setAppState,
      cancelCallback,
      contactId,
      Collection.TaxExemptionCertificates
    )
    setTimeout(() => {
      setLoader(false)
    }, 2000)
    return () => {
      unsSubData?.()
    }
  }, [])

  return (
    <div>
      <div className={styles.withLayout}>
        <Layout />
        <div className={cn(styles.container, customClass)}>
          <NavBar title="Tax Exemption Application Details" />
          {loader ? (
            <Loader />
          ) : (
            <React.Fragment>
              <div className={styles.buttons}>
                <span
                  onClick={() =>
                    navigate('/tax-exemption-applications', {
                      state: {page: previousPage}
                    })
                  }
                >
                  <ArrowDown />
                  Back
                </span>
              </div>

              <div className={styles.edit_section}>
                <DetailPage
                  data={appState.documentData}
                  detailType={DetailType.TaxExemption}
                />
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  )
}

export default withAuthorization(TaxExemptionDetailPage)
