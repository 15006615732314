import {CheckedIcon, UncheckedIcon} from 'assets/images'
import classNames from 'classnames'
import {AccountDocument, AdminDocument, AgreementDocument, Sector} from 'common'
import {AccountTypes, Collection, listingStatus} from 'common/enums'
import AlgoliaClient, {
  ACCOUNTS_ADMIN_PANEL_ALGOLIA_INDEX
} from 'components/AlgoliaSearch'
import ButtonDropDown from 'components/ButtonDropDown'
import ConfirmationModal from 'components/ConfirmationModal'
import ModalComponent from 'components/ModalComponent'
import RecallModal from 'components/RecallModal'
import SelectConsigneeModal from 'components/SelectConsigneeModal'
import {validateAddress} from 'components/apis/taxjar'
import {DetailType, signMethod} from 'components/enums'
import {IFormattedAccount} from 'components/types'
import {Formik, FormikHelpers, FormikProps} from 'formik'
import _, {isEqual} from 'lodash'
import React, {useEffect, useRef, useState} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {toast} from 'react-toastify'
import {ArrowDown, Building} from '../../assets/svgs'
import Button from '../Button'
import DetailPage from '../DetailPage'
import DropDownSelect from '../DropDown'
import {useAppContext, useFirebase} from '../Firebase/hooks'
import InputField from '../InputField'
import Layout from '../Layout'
import {Loader} from '../Loader'
import NavBar from '../Navbar'
import PhoneInputSelect from '../PhoneInput'
import withAuthorization from '../Session/withAuthorization'
import {
  getFormattedAccount,
  getUserFullName,
  sortByName,
  trimStringFields
} from '../utils'
import styles from './CreateNewAccount.module.scss'
import {CreateAccountSchema, INIITAL_ACCOUNT_STATE} from './schema'
interface CreateNewAccountProps {
  customClass: string
}

const CreateNewAccount = ({customClass}: CreateNewAccountProps) => {
  const [optionsList, setOptionsList] = useState<
    Array<{id: string; name: string}>
  >([])
  const [showConsigneeModal, setShowConsigneeModal] = useState<{
    method: signMethod | undefined
    show: boolean
  }>({
    method: undefined,
    show: false
  })
  const navigate = useNavigate()
  const {state, search} = useLocation()
  const {
    getDocumentData,
    getCollectionData,
    getSectors,
    createAccount,
    deleteAccount,
    editAccount,
    getCollectionBasedOnQuery,
    getCollectionSnapshotBasedOnQuery
  } = useFirebase()
  const [adminsList, setAdminsList] = useState<
    Array<{
      id: string
      name: string
      email: string
    }>
  >([])
  const [formData, setFormData] = useState<IFormattedAccount | null>(null)
  const [show, setShow] = useState<boolean>(false)
  const toggleShow = () => setShow(!show)
  const [hasContacts, setHasContacts] = useState<boolean>(false)
  const [hasCheckedDocuments, setHasCheckedDocuments] = useState<boolean>(false)
  const formRef: React.Ref<FormikProps<IFormattedAccount>> | undefined =
    useRef(null)
  const accountTypeOptions = [...Object.values(AccountTypes)]
  const redirectToAfterNewAccount = state?.path
  const searchAccountId = new URLSearchParams(search).get('accountId')
  const accountID = state?.id || searchAccountId || ''
  const objectID = state?.objectID
  const isEdit = state?.isEdit ? state?.isEdit : false
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [loader, setLoader] = useState(isEdit ? true : false)
  const previousPage = state?.currentPage
  const [appState, setAppState] = useState<{
    documentData: AccountDocument | null
  }>({
    documentData: null
  })
  const [agreementDocuments, setAgreementDocuments] = useState<{
    documentData: Array<AgreementDocument>
  }>()
  const [showRecallModal, setShowRecallModal] = useState(false)
  const [disabled, setDisabled] = useState(isEdit ? true : false)
  const [sectors, setSectors] = useState<Array<Sector>>([])
  const disableMSAButton = appState?.documentData?.hasSignedMSA ? true : false
  const {appState: applicationState} = useAppContext()
  const isSuperUser =
    applicationState?.isSuperAdmin || applicationState.isSuperManager || false
  const isAccountDetails = isEdit && disabled
  const showMSAButton = (isEdit && isSuperUser) || false
  const initialState = appState.documentData
    ? getFormattedAccount(appState.documentData)
    : INIITAL_ACCOUNT_STATE

  const toggleShowManualSignModal = () =>
    setShowConsigneeModal({
      method: signMethod.Manual,
      show: true
    })

  const toggleShowZohoSignModal = () =>
    setShowConsigneeModal({
      method: signMethod.Zoho,
      show: true
    })

  const handleCloseConsigneeModal = () =>
    setShowConsigneeModal({method: undefined, show: false})

  const cancelCallback = (error: Error) => {
    if (error) {
      toast.error(`Something went wrong while fetching account docuemnt.`)
    }
  }

  useEffect(() => {
    if (!accountID) return

    const unsubscribe = getCollectionSnapshotBasedOnQuery<AgreementDocument>({
      updateState: setAgreementDocuments,
      cancelCallback: (_error) => {
        console.warn('Failed to get MSA agreement status')
      },
      collectionName: Collection.ZohoSignGeneralMsaAgreement,
      queryVariable: 'accountId',
      queryValue: accountID
    }).unsub

    return () => {
      if (unsubscribe) unsubscribe()
    }
  }, [accountID])

  useEffect(() => {
    if (disabled && isEdit) {
      const {unsSubData} = getDocumentData(
        setAppState,
        cancelCallback,
        accountID,
        Collection.Accounts
      )
      return () => {
        unsSubData?.()
      }
    }
  }, [disabled, accountID])

  const getAccounts = async () => {
    const {data, error} = await getCollectionData(Collection.Accounts)
    if (error) {
      toast.error('Something went wrong while fetching accounts.')
      return
    }
    if (data) {
      const dropdownOptions = data?.map((account: AccountDocument) => {
        const accountName = account?.accountName || ''
        return {
          id: account.accountId,
          name: accountName
        }
      })
      dropdownOptions.sort(sortByName)
      setOptionsList([...dropdownOptions])
    }
  }

  const getConsigneeData = async () => {
    !loader && setLoader(true)
    const response = await getCollectionBasedOnQuery<AdminDocument>(
      Collection.Admins,
      'role',
      'superAdmin'
    )
    const consigneeData = response?.data ? response.data : []
    const adminData = consigneeData.map((admin) => {
      return {
        id: admin.userId,
        name: getUserFullName(admin?.firstName || '', admin?.lastName || ''),
        email: admin.contactInfo.emailAddress
      }
    })
    setAdminsList(adminData)
    setLoader(false)
  }

  useEffect(() => {
    getAccounts()
    getSectors()
      .then((res) => setSectors(res))
      .catch(() => toast.warn('Something went wrong! Failed to fetch sectors.'))
    if (isEdit) {
      isSuperUser && getConsigneeData()
      setTimeout(() => {
        setLoader(false)
      }, 2000)
    }
  }, [])

  useEffect(() => {
    const {documentData} = appState
    if (documentData && !disabled) {
      const accountObject = getFormattedAccount(documentData)
      setFormData({...accountObject})
      formRef?.current?.setValues({...accountObject})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appState.documentData?.accountId, formRef, disabled])

  const handleDeletion = async () => {
    if (hasContacts) {
      toast.error('This Account has Contacts made against it')
      return
    } else {
      setLoader(true)
      const searchIndex = AlgoliaClient.initIndex(
        ACCOUNTS_ADMIN_PANEL_ALGOLIA_INDEX
      )
      await searchIndex.deleteObject(objectID)
      const {message, error} = await deleteAccount(accountID)
      setLoader(false)
      if (error) {
        toast.warning(error)
        return
      } else {
        toast.success(message)
        navigate('/accounts', {
          state: {page: previousPage}
        })
        return
      }
    }
  }

  const handleSubmit = async (
    values: IFormattedAccount,
    formik: FormikHelpers<IFormattedAccount>
  ) => {
    if (!isSubmitting) {
      setIsSubmitting(true)

      const isObjectSame = isEqual(values, formData)

      if (isEdit && isObjectSame) {
        toast.warning('Please make some changes')
        setIsSubmitting(false)
        return
      }

      const {accountName, accountId, ...restAccountValues} = values

      const accountObject = {
        ...restAccountValues,
        accountName: accountName,
        billingStreetAddress2: values.billingStreetAddress2 || null,
        shippingStreetAddress2: values.shippingStreetAddress2 || null,
        descriptionInfo: values.descriptionInfo || null,
        phoneNumber: values?.phoneNumber
      }

      if (values.sameAddress) {
        accountObject.shippingCountry = values.billingCountry
        accountObject.shippingState = values.billingState
        accountObject.shippingCity = values.billingCity
        accountObject.shippingZipCode = values.billingZipCode
        accountObject.shippingStreetAddress = values.billingStreetAddress
        accountObject.shippingStreetAddress2 =
          values.billingStreetAddress2 || null
      }

      const {sameAddress, ...rest} = accountObject
      const {shippingAddress, billingAddress} = appState.documentData || {}

      const isDifferentBillingAddress = !isEqual(
        {
          billingStreetAddress: accountObject.billingStreetAddress,
          billingStreetAddress2: accountObject.billingStreetAddress2,
          billingCountry: accountObject.billingCountry,
          billingState: accountObject.billingState,
          billingCity: accountObject.billingCity,
          billingZipCode: accountObject.billingZipCode
        },
        {
          billingStreetAddress: billingAddress?.billingStreetAddress,
          billingStreetAddress2: billingAddress?.billingStreetAddress2,
          billingCountry: billingAddress?.billingCountry,
          billingState: billingAddress?.billingState,
          billingCity: billingAddress?.billingCity,
          billingZipCode: billingAddress?.billingZipCode
        }
      )
      const isDifferentShippingAddress = !isEqual(
        {
          shippingStreetAddress: accountObject.shippingStreetAddress,
          shippingStreetAddress2: accountObject.shippingStreetAddress2,
          shippingCountry: accountObject.shippingCountry,
          shippingState: accountObject.shippingState,
          shippingCity: accountObject.shippingCity,
          shippingZipCode: accountObject.shippingZipCode
        },
        {
          shippingStreetAddress: shippingAddress?.shippingStreetAddress,
          shippingStreetAddress2: shippingAddress?.shippingStreetAddress2,
          shippingCountry: shippingAddress?.shippingCountry,
          shippingState: shippingAddress?.shippingState,
          shippingCity: shippingAddress?.shippingCity,
          shippingZipCode: shippingAddress?.shippingZipCode
        }
      )

      const {
        billingCountry,
        billingState,
        billingCity,
        billingZipCode,
        shippingCountry,
        shippingState,
        shippingCity,
        shippingZipCode
      } = accountObject || {}

      const {error: billingAddressError} = isDifferentBillingAddress
        ? await validateAddress({
            city: billingCity,
            state: billingState,
            zip: billingZipCode,
            country: billingCountry
          })
        : {error: ''}

      const isUnsupportedBillingCountry = billingAddressError?.includes(
        'Unsupported Country',
        /g/
      )

      if (isUnsupportedBillingCountry) {
        toast.info(
          'Our platform validates only US addresses, please make sure the entered physical address is correct'
        )
      }

      if (billingAddressError && !isUnsupportedBillingCountry) {
        formik.setFieldError('billingCity', 'Invalid Address')
        formik.setFieldError('billingState', 'Invalid Address')
        formik.setFieldError('billingZipCode', 'Invalid Address')
        formik.setFieldError('billingCountry', 'Invalid Address')
        toast.error('Please enter a valid physical company address.')
        setIsSubmitting(false)
        return
      }

      const {error: shippingAddressError} =
        isDifferentShippingAddress && !sameAddress
          ? await validateAddress({
              city: shippingCity,
              zip: shippingZipCode,
              state: shippingState,
              country: shippingCountry
            })
          : {error: ''}

      const isUnsupportedShippingCountry = shippingAddressError?.includes(
        'Unsupported Country',
        /g/
      )

      if (isUnsupportedShippingCountry && !sameAddress) {
        toast.info(
          'Our platform validates only US addresses, please make sure the entered mailing address is correct'
        )
      }

      if (shippingAddressError && !isUnsupportedShippingCountry) {
        formik.setFieldError('shippingCountry', 'Invalid Address')
        formik.setFieldError('shippingState', 'Invalid Address')
        formik.setFieldError('shippingCity', 'Invalid Address')
        formik.setFieldError('shippingZipCode', 'Invalid Address')
        toast.warn('Please enter a valid mailing company address.')
        setIsSubmitting(false)
        return
      }

      const {message, error, response} = isEdit
        ? await editAccount(trimStringFields(rest), accountID)
        : await createAccount(trimStringFields(rest))

      if (error) {
        toast.warning(error)
        setIsSubmitting(false)
      } else {
        toast.success(message)
        setIsSubmitting(false)

        if (redirectToAfterNewAccount) {
          navigate(redirectToAfterNewAccount, {
            state: {
              account: {
                id: response?.data?.data?.accountId,
                name: response?.data?.data?.accountName
              },
              isEdit: true
            }
          })
        } else {
          navigate('/accounts', {
            state: {page: state?.currentpage}
          })
        }
      }
    }
  }

  const navTitle = isAccountDetails
    ? 'Account Details'
    : isEdit
    ? 'Edit Account'
    : 'Create New Account'

  const agreementDocument = _.first(agreementDocuments?.documentData)
  const isSignedByOneParty =
    agreementDocument?.status === listingStatus.CONSIGNEE_SIGNED ||
    agreementDocument?.status === listingStatus.RECIPIENT_SIGNED
  const displayRecallOption = agreementDocument ? !isSignedByOneParty : false
  const listingAgreementOptions = displayRecallOption
    ? [
        {
          onClick: toggleShowZohoSignModal,
          option: 'Send Agreement Through Zoho Sign'
        },
        {
          onClick: toggleShowManualSignModal,
          option: 'Sign Agreement Manually'
        },
        {
          onClick: () => setShowRecallModal(true),
          option: 'Recall MSA Agreement'
        }
      ]
    : [
        {
          onClick: toggleShowZohoSignModal,
          option: 'Send Agreement Through Zoho Sign'
        },
        {
          onClick: toggleShowManualSignModal,
          option: 'Sign Agreement Manually'
        }
      ]

  return (
    <div className={styles.withLayout}>
      <Layout />
      <div className={classNames(styles.container, customClass)}>
        <NavBar title={navTitle} />
        {loader ? (
          <Loader />
        ) : (
          <React.Fragment>
            {showRecallModal && (
              <RecallModal
                agreement={agreementDocument}
                agreementType={Collection.ZohoSignGeneralMsaAgreement}
                handleClose={() => setShowRecallModal(false)}
                show={showRecallModal}
              />
            )}
            {show && (
              <ModalComponent handleClose={toggleShow} show={show}>
                <ConfirmationModal
                  removeIcon
                  handleClose={toggleShow}
                  text={`Delete Account`}
                  content={
                    'You cannot undo these changes. Are you sure you want to delete this account?'
                  }
                  handleDeleteButton={handleDeletion}
                  isDelete={true}
                  isAccount={true}
                  documentId={accountID}
                  setHasDocuments={setHasContacts}
                  hasCheckedDocuments={hasCheckedDocuments}
                  setHasCheckedDocuments={setHasCheckedDocuments}
                />
              </ModalComponent>
            )}
            {showConsigneeModal.show && (
              <ModalComponent
                show={showConsigneeModal.show}
                handleClose={handleCloseConsigneeModal}
              >
                <SelectConsigneeModal
                  handleClose={handleCloseConsigneeModal}
                  sign={showConsigneeModal.method}
                  adminsList={adminsList}
                  accountID={accountID}
                  isMSA={true}
                />
              </ModalComponent>
            )}
            <div className={styles.buttons}>
              <span
                onClick={() =>
                  navigate('/accounts', {
                    state: {page: previousPage}
                  })
                }
              >
                <ArrowDown />
                Back
              </span>
              <div className={styles.twobtn}>
                {showMSAButton && (
                  <ButtonDropDown
                    buttonText="Send MSA Signing Agreement"
                    customButtonStlyes={(styles.changes_btn, styles.MSAbtn)}
                    selectOptions={listingAgreementOptions}
                    disabled={disableMSAButton}
                  />
                )}
                {isEdit && (
                  <Button
                    text="Delete"
                    customClass={styles.changes_btn}
                    onClick={toggleShow}
                  />
                )}
                {disabled && (
                  <Button
                    text="Edit"
                    customClass={styles.changes_btn}
                    onClick={() => setDisabled(false)}
                  />
                )}
                {(!isEdit || !disabled) && (
                  <Button
                    text={'Save'}
                    type="submit"
                    disabled={isSubmitting}
                    customClass={styles.changes_btn}
                    onClick={() => formRef.current?.handleSubmit()}
                  />
                )}
              </div>
            </div>

            <div className={styles.edit_section}>
              {!isAccountDetails && (
                <h1 className={styles.secondheading}>Account Details</h1>
              )}
              {isAccountDetails ? (
                <DetailPage
                  data={initialState}
                  detailType={DetailType.Account}
                />
              ) : (
                <Formik
                  innerRef={formRef}
                  initialValues={initialState}
                  validationSchema={CreateAccountSchema}
                  onSubmit={handleSubmit}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue
                  }) => {
                    const dropdownClass = classNames(
                      styles.dropdowninput,
                      styles.dropdownblock
                    )
                    const {
                      shippingCity,
                      billingCity,
                      accountName,
                      accountType,
                      billingCountry,
                      billingState,
                      billingStreetAddress,
                      billingStreetAddress2,
                      billingZipCode,
                      descriptionInfo,
                      email,
                      parentAccountId,
                      phoneNumber,
                      sameAddress,
                      sector,
                      shippingStreetAddress,
                      shippingCountry,
                      shippingState,
                      shippingStreetAddress2,
                      shippingZipCode,
                      websiteURL
                    } = values || {}

                    const sectorValue = sectors.find(
                      (sec) => sec.nodeId === sector
                    )?.name

                    return (
                      <form
                        className={styles.from_submiting}
                        onSubmit={handleSubmit}
                      >
                        <div className={styles.formContainer}>
                          <div className={styles.inputs}>
                            <div className={styles.inputContainer}>
                              <div className={styles.inputField}>
                                <InputField
                                  name="accountName"
                                  placeholder="Account Name"
                                  value={accountName}
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  onBlur={handleBlur}
                                  type="text"
                                  disabled={disabled}
                                />
                              </div>
                              <div className={styles.inputField}>
                                <InputField
                                  placeholder="Description"
                                  name="descriptionInfo"
                                  value={descriptionInfo}
                                  onChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                  onBlur={handleBlur}
                                  type="text"
                                  disabled={disabled}
                                />
                              </div>
                            </div>
                            <div className={styles.inputContainer}>
                              <div className={styles.inputField}>
                                <div className={styles.dropdown}>
                                  <div>
                                    <DropDownSelect
                                      firstIcon={<Building />}
                                      options={optionsList}
                                      customClass={classNames(
                                        styles.dropdowninput,
                                        styles.dropdownblock
                                      )}
                                      placeholder="Parent Account"
                                      value={optionsList.findIndex(
                                        (option) =>
                                          option.id === parentAccountId
                                      )}
                                      cancelClickFunction={() => {
                                        setFieldValue('parentAccountId', '')
                                      }}
                                      onChange={(index) => {
                                        setFieldValue(
                                          'parentAccountId',
                                          optionsList[index].id
                                        )
                                      }}
                                      errors={errors}
                                      touched={touched}
                                      disabled={disabled}
                                      onBlur={handleBlur}
                                      name="parentAccountId"
                                    />
                                    <div
                                      className={classNames(
                                        styles.disblock,
                                        styles.disnone
                                      )}
                                    >
                                      ____
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className={styles.inputField}>
                                <div className={styles.dropdown}>
                                  <div>
                                    <DropDownSelect
                                      firstIcon={<Building />}
                                      options={sectors}
                                      displayValue={true}
                                      customClass={classNames(
                                        styles.dropdowninput,
                                        styles.dropdownblock
                                      )}
                                      placeholder="Sector"
                                      value={sectorValue}
                                      cancelClickFunction={() => {
                                        setFieldValue('sector', '')
                                      }}
                                      onChange={(index) => {
                                        const value = sectors[index].nodeId
                                        setFieldValue('sector', value)
                                      }}
                                      errors={errors}
                                      touched={touched}
                                      disabled={disabled}
                                      onBlur={handleBlur}
                                      name="sector"
                                    />
                                    <div
                                      className={classNames(
                                        styles.disblock,
                                        styles.disnone
                                      )}
                                    >
                                      ____
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className={styles.inputContainer}>
                              <div className={styles.inputField}>
                                <div className={styles.dropdown}>
                                  <div>
                                    <DropDownSelect
                                      firstIcon={<Building />}
                                      options={accountTypeOptions}
                                      customClass={classNames(
                                        styles.dropdowninput,
                                        styles.dropdownblock
                                      )}
                                      placeholder="Account Type"
                                      value={accountTypeOptions.findIndex(
                                        (option) => option === accountType
                                      )}
                                      cancelClickFunction={() => {
                                        setFieldValue('accountType', '')
                                      }}
                                      onChange={(index) => {
                                        setFieldValue(
                                          'accountType',
                                          accountTypeOptions[index]
                                        )
                                      }}
                                      errors={errors}
                                      touched={touched}
                                      disabled={disabled}
                                      onBlur={handleBlur}
                                      name="accountType"
                                    />
                                    <div
                                      className={classNames(
                                        styles.disblock,
                                        styles.disnone
                                      )}
                                    >
                                      ____
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className={styles.headingContainer}>
                              <div className={styles.formHeading}>
                                Account Information
                              </div>
                              <div className={styles.inputs}>
                                <div className={styles.inputContainer}>
                                  <div className={styles.inputField}>
                                    <InputField
                                      placeholder="Website"
                                      name="websiteURL"
                                      value={websiteURL}
                                      onChange={handleChange}
                                      errors={errors}
                                      touched={touched}
                                      onBlur={handleBlur}
                                      type="text"
                                      disabled={disabled}
                                    />
                                  </div>
                                  <div className={styles.inputField}>
                                    <PhoneInputSelect
                                      placeholder="Account Phone Number"
                                      name="phoneNumber"
                                      value={phoneNumber}
                                      setFieldValue={setFieldValue}
                                      errors={errors}
                                      touched={touched}
                                      onBlur={handleBlur}
                                      type="text"
                                      disabled={disabled}
                                      id="phoneNumber"
                                    />
                                  </div>
                                </div>
                                <div className={styles.inputContainer}>
                                  <div className={styles.inputField}>
                                    <InputField
                                      placeholder="Email"
                                      name="email"
                                      value={email}
                                      onChange={handleChange}
                                      errors={errors}
                                      touched={touched}
                                      onBlur={handleBlur}
                                      type="text"
                                      disabled={disabled}
                                    />
                                  </div>
                                  <div className={styles.inputField}></div>
                                </div>
                              </div>
                            </div>
                            <div className={styles.headingContainer}>
                              <div className={styles.formHeading}>
                                Physical Address
                              </div>
                              <div className={styles.inputs}>
                                <div className={styles.inputContainer}>
                                  <div className={styles.inputField}>
                                    <InputField
                                      placeholder="Physical Street 1"
                                      name="billingStreetAddress"
                                      value={billingStreetAddress}
                                      onChange={handleChange}
                                      errors={errors}
                                      touched={touched}
                                      onBlur={handleBlur}
                                      type="text"
                                      disabled={disabled}
                                    />
                                  </div>
                                  <div className={styles.inputField}>
                                    <InputField
                                      placeholder="Physical Street 2"
                                      name="billingStreetAddress2"
                                      value={billingStreetAddress2}
                                      onChange={handleChange}
                                      errors={errors}
                                      touched={touched}
                                      onBlur={handleBlur}
                                      type="text"
                                      disabled={disabled}
                                    />
                                  </div>
                                </div>
                                <div className={styles.inputContainer}>
                                  <div className={styles.inputField}>
                                    <div className={styles.dropdown}>
                                      <div>
                                        <InputField
                                          customClass={dropdownClass}
                                          placeholder="Physical City"
                                          value={billingCity}
                                          onChange={handleChange}
                                          errors={errors}
                                          touched={touched}
                                          disabled={disabled}
                                          onBlur={handleBlur}
                                          name="billingCity"
                                        />
                                        <div
                                          className={classNames(
                                            styles.disblock,
                                            styles.disnone
                                          )}
                                        >
                                          ____
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className={styles.inputField}>
                                    <div className={styles.dropdown}>
                                      <div>
                                        <InputField
                                          customClass={dropdownClass}
                                          placeholder="Physical State"
                                          value={billingState}
                                          onChange={handleChange}
                                          errors={errors}
                                          touched={touched}
                                          disabled={disabled}
                                          onBlur={handleBlur}
                                          name="billingState"
                                        />
                                        <div
                                          className={classNames(
                                            styles.disblock,
                                            styles.disnone
                                          )}
                                        >
                                          ____
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className={styles.inputContainer}>
                                  <div className={styles.inputField}>
                                    <InputField
                                      placeholder="Physical Zip Code"
                                      name="billingZipCode"
                                      value={billingZipCode}
                                      onChange={handleChange}
                                      errors={errors}
                                      touched={touched}
                                      onBlur={handleBlur}
                                      type="text"
                                      disabled={disabled}
                                    />
                                  </div>
                                  <div className={styles.inputField}>
                                    <div className={styles.dropdown}>
                                      <div>
                                        <InputField
                                          customClass={dropdownClass}
                                          placeholder="Physical Country"
                                          value={billingCountry}
                                          onChange={handleChange}
                                          errors={errors}
                                          touched={touched}
                                          disabled={disabled}
                                          onBlur={handleBlur}
                                          name="billingCountry"
                                        />
                                        <div
                                          className={classNames(
                                            styles.disblock,
                                            styles.disnone
                                          )}
                                        >
                                          ____
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className={styles.withCheckbox}>
                              <div className={styles.containerCheck}>
                                <div className={styles.formHeading}>
                                  Mailing Address same as Physical Address
                                </div>
                                <div className={styles.inputCheckbox}>
                                  <div className={styles.inputField}>
                                    <InputField
                                      placeholder="Yes"
                                      inputFieldIcon={
                                        sameAddress
                                          ? CheckedIcon
                                          : UncheckedIcon
                                      }
                                      iconClick={() => {
                                        setFieldValue('sameAddress', true)
                                      }}
                                    />
                                  </div>
                                  <div className={styles.inputField}>
                                    <InputField
                                      placeholder="No"
                                      inputFieldIcon={
                                        !sameAddress
                                          ? CheckedIcon
                                          : UncheckedIcon
                                      }
                                      iconClick={() => {
                                        setFieldValue('sameAddress', false)
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            {!sameAddress && (
                              <div className={styles.headingContainer}>
                                <div className={styles.formHeading}>
                                  Mailing Address
                                </div>
                                <div className={styles.inputs}>
                                  <div className={styles.inputContainer}>
                                    <div className={styles.inputField}>
                                      <InputField
                                        placeholder="Mailing Street 1"
                                        name="shippingStreetAddress"
                                        value={shippingStreetAddress}
                                        onChange={handleChange}
                                        errors={errors}
                                        touched={touched}
                                        onBlur={handleBlur}
                                        type="text"
                                        disabled={disabled}
                                      />
                                    </div>
                                    <div className={styles.inputField}>
                                      <InputField
                                        placeholder="Mailing Street 2"
                                        name="shippingStreetAddress2"
                                        value={shippingStreetAddress2}
                                        onChange={handleChange}
                                        errors={errors}
                                        touched={touched}
                                        onBlur={handleBlur}
                                        type="text"
                                        disabled={disabled}
                                      />
                                    </div>
                                  </div>
                                  <div className={styles.inputContainer}>
                                    <div className={styles.inputField}>
                                      <div className={styles.dropdown}>
                                        <div>
                                          <InputField
                                            customClass={classNames(
                                              styles.dropdowninput,
                                              styles.dropdownblock
                                            )}
                                            placeholder="Mailing City"
                                            value={shippingCity}
                                            onChange={handleChange}
                                            errors={errors}
                                            touched={touched}
                                            disabled={disabled}
                                            onBlur={handleBlur}
                                            name="shippingCity"
                                          />
                                          <div
                                            className={classNames(
                                              styles.disblock,
                                              styles.disnone
                                            )}
                                          >
                                            ____
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className={styles.inputField}>
                                      <div className={styles.dropdown}>
                                        <div>
                                          <InputField
                                            customClass={classNames(
                                              styles.dropdowninput,
                                              styles.dropdownblock
                                            )}
                                            placeholder="Mailing State"
                                            value={shippingState}
                                            onChange={handleChange}
                                            errors={errors}
                                            touched={touched}
                                            disabled={disabled}
                                            onBlur={handleBlur}
                                            name="shippingState"
                                          />
                                          <div
                                            className={classNames(
                                              styles.disblock,
                                              styles.disnone
                                            )}
                                          >
                                            ____
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className={styles.inputContainer}>
                                    <div className={styles.inputField}>
                                      <InputField
                                        placeholder="Mailing Zip Code"
                                        name="shippingZipCode"
                                        value={shippingZipCode}
                                        onChange={handleChange}
                                        errors={errors}
                                        touched={touched}
                                        onBlur={handleBlur}
                                        type="text"
                                        disabled={disabled}
                                      />
                                    </div>
                                    <div className={styles.inputField}>
                                      <div className={styles.dropdown}>
                                        <div>
                                          <InputField
                                            customClass={classNames(
                                              styles.dropdowninput,
                                              styles.dropdownblock
                                            )}
                                            placeholder="Mailing Country"
                                            value={shippingCountry}
                                            onChange={handleChange}
                                            errors={errors}
                                            touched={touched}
                                            disabled={disabled}
                                            onBlur={handleBlur}
                                            name="shippingCountry"
                                          />
                                          <div
                                            className={classNames(
                                              styles.disblock,
                                              styles.disnone
                                            )}
                                          >
                                            ____
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </form>
                    )
                  }}
                </Formik>
              )}
            </div>
          </React.Fragment>
        )}
      </div>
    </div>
  )
}

export default withAuthorization(CreateNewAccount)
