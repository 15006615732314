import {useEffect} from 'react'
import styles from './Table.module.scss'
import cn from 'classnames'
import {SortIcon} from 'assets/svgs'

interface TableProps {
  customClass?: string
  titles: Array<{title: string; sortBy?: () => void}>
  rowData: React.ReactNode
}

const Table = ({customClass, titles, rowData}: TableProps) => {
  return (
    <div className={cn(styles.container, customClass)}>
      <table>
        <thead className={styles.table_head}>
          <tr>
            {titles.map(({title, sortBy}, index) => {
              const isSortable = typeof sortBy !== 'undefined'
              return (
                <th
                  className={(isSortable && styles.sortable) || styles.header}
                  key={`${title}-table-item-${index}`}
                  onClick={sortBy}
                >
                  {title} {isSortable && <SortIcon />}
                </th>
              )
            })}
          </tr>
        </thead>

        <tbody>{rowData}</tbody>
      </table>
    </div>
  )
}

export default Table
