import {equipmentOptions, listingType, publishAuction} from 'common/enums'
import {
  convertToDate,
  getDaysFromToday,
  removeHtmlTags,
  withBuyersPremium
} from '../utils'
import * as Yup from 'yup'
import phone from 'phone'
import {isDate, isNumber} from 'lodash'

export const ProductInitialDocumentSchema = Yup.object({
  accountId: Yup.string().required('Required'),
  contactId: Yup.string().required('Required'),
  listingType: Yup.string().required('Required')
})

export const ProductFormSchema = Yup.object({
  productId: Yup.string(),
  buyNowPriceMap: Yup.object({
    buyNowPrice: Yup.number()
      .required('Price Not Given')
      .min(1, 'This price field should be in range of $1 - $9,999,999')
      .max(9999999, 'This price field should be in range of $1 - $9,999,999'),
    buyNowPriceTimestamp: Yup.object({
      seconds: Yup.number(),
      nanoseconds: Yup.number()
    })
  }),
  openingPriceMap: Yup.object({
    openingPrice: Yup.number(),
    openingPriceTimestamp: Yup.object({
      seconds: Yup.number(),
      nanoseconds: Yup.number()
    })
  }),
  title: Yup.string().required('Title is required'),
  zohoID: Yup.string(),
  isCommissionSetManually: Yup.bool(),
  isBuyersPremiumSetManually: Yup.bool(),
  commissionPercentage: Yup.number()
    .required('Commission percentage is required!')
    .min(1, 'Commission percentage cannot be less than 1%')
    .max(99, 'Commission percentage should be less than 100%'),
  buyersPremium: Yup.number()
    .required(`Buyer's Premium is required!`)
    .min(1, `Buyer's Premium cannot be less than 1%`)
    .max(99, `Buyer's Premium should be less than 100%`),
  description: Yup.string()
    .test(
      'description_length_max_reached',
      'Description should not be more than 2500 characters',
      (value) => {
        const count = removeHtmlTags(value || '').trim().length
        const maxCountReached = count >= 2501
        if (maxCountReached) {
          return false
        } else {
          return true
        }
      }
    )
    .test(
      'description_length_min_not_reached',
      'Please provide description',
      (value) => {
        const count = removeHtmlTags(value || '').trim().length
        const minCountNotReached = count < 3
        if (minCountNotReached) {
          return false
        } else {
          return true
        }
      }
    )
    .required('Please provide description'),
  equipmentId: Yup.string().required('Please provide equipment type'),
  subcategoryId: Yup.string(),
  equipmentHours: Yup.number()
    .nullable()
    .when('productDetails.firstChoice', {
      is: equipmentOptions.EquipmentHours,
      then: Yup.number()
        .integer('Please provide valid equipment hours')
        .min(0, 'Please provide valid equipment hours')
        .max(Number.MAX_SAFE_INTEGER, 'Please provide valid equipment hours')
        .required('Please provide valid equipment hours')
    }),
  equipmentMileage: Yup.number()
    .nullable()
    .when('productDetails.firstChoice', {
      is: equipmentOptions.EquipmentMileage,
      then: Yup.number()
        .integer('Please provide valid equipment mileage')
        .min(0, 'Please provide valid equipment mileage')
        .max(Number.MAX_SAFE_INTEGER, 'Please provide valid equipment mileage')
        .required('Please provide valid equipment mileage')
    }),
  model: Yup.string().required('Please state model'),
  equipmentVinNumber: Yup.string()
    .nullable()
    .when('productDetails.secondChoice', {
      is: equipmentOptions.EquipmentVINNumber,
      then: Yup.string().required('Please provide a valid equipment vin number')
    }),
  productManufacturer: Yup.string().required(
    'Please provide equipment manufacturer'
  ),
  serialNumber: Yup.string()
    .nullable()
    .when('productDetails.secondChoice', {
      is: equipmentOptions.SerialNumber,
      then: Yup.string().required('Please provide a valid serial number')
    }),
  newOrUsedEquipment: Yup.string().required('Required'),
  isTitledPieceOfEquipment: Yup.boolean(),
  willYouProvideTheElectricalDisconnectIfRequired: Yup.boolean(),
  willYouLoadTheEquipmentOnToTheBuyersTrucks: Yup.boolean(),
  year: Yup.number().nullable(),
  yearUnknown: Yup.boolean().nullable(),
  doesThisRequireDismantling: Yup.object({
    willDismantlingRequireCraneService: Yup.boolean().nullable(),
    areYouWillingToHelpWithDismantling: Yup.object({
      toWhatExtentAreYouWillingToHelp: Yup.string().test({
        name: 'doesThisRequireDismantling.areYouWillingToHelpWithDismantling.toWhatExtentAreYouWillingToHelp',
        message: 'Required',
        test: (value, context) => {
          const areYouWillingToHelpWithDismantling =
            typeof context.options.context?.doesThisRequireDismantling
              ?.areYouWillingToHelpWithDismantling !== 'object'
          return areYouWillingToHelpWithDismantling ? true : Boolean(value)
        }
      })
    }).nullable()
  }).nullable(),
  lienInfo: Yup.object({
    lienHolderName: Yup.string().test({
      name: 'lienInfo.lienHolderName',
      message: 'Lien holder name required',
      test: (value, context) => {
        const lienInfo = typeof context.options.context?.lienInfo !== 'object'
        return lienInfo ? true : Boolean(value)
      }
    }),
    lienHolderEmail: Yup.string()
      .email('Invalid email')
      .matches(/^[^$%`~|\/]+$/, 'Invalid email format')
      .test({
        name: 'lienInfo.lienHolderEmail',
        message: 'Lien holder email required',
        test: (value, context) => {
          const lienInfo = typeof context.options.context?.lienInfo !== 'object'
          return lienInfo ? true : Boolean(value)
        }
      }),
    lienHolderPhone: Yup.string().test(
      'invalid_number',
      'Invalid Number',
      (value, context) => {
        const lienInfo = typeof context.options.context?.lienInfo !== 'object'
        return lienInfo
          ? true
          : value
          ? phone(value, {validateMobilePrefix: true}).isValid
          : false
      }
    ),
    lienHolderPayoffAmount: Yup.number()
      .min(0, 'Negative values not allowed')
      .test({
        name: 'lienInfo.lienHolderPayoffAmount',
        message: 'Lien holder payoff amount required',
        test: (value, context) => {
          const lienInfo = typeof context.options.context?.lienInfo !== 'object'
          return lienInfo ? true : Boolean(value)
        }
      })
  }).nullable(),
  productDetails: Yup.object({
    files: Yup.array().min(1, 'Please upload Images/Videos'),
    pdfAttachments: Yup.array(),
    firstChoice: Yup.mixed().oneOf(
      [
        equipmentOptions.EquipmentHours,
        equipmentOptions.EquipmentMileage,
        equipmentOptions.Unknown
      ],
      'Please select an option.'
    ),
    secondChoice: Yup.mixed().oneOf(
      [
        equipmentOptions.SerialNumber,
        equipmentOptions.EquipmentVINNumber,
        equipmentOptions.Unknown
      ],
      'Please select an option.'
    )
  }),
  contractInfo: Yup.object({
    contractDuration: Yup.number()
      .integer('Contact duration should not have decimals')
      .required('Contract duration is required')
      .min(2, 'Contract duration should be greater than 1')
      .max(999, 'Contract duration should not be more than 999 days'),
    autoRenew: Yup.bool().required(),
    renewalWindow: Yup.number()
      .integer('Notification duration should not have decimals')
      .required('Notification duration is required')
      .test(
        'in-range',
        'Notification duration should be in range of contract duration',
        function (value) {
          if (!value) return false
          const contractDuration = this.parent.contractDuration
          return value >= 1 && value <= contractDuration
        }
      )
  }),
  status: Yup.string(),
  views: Yup.number(),
  isTheEquipmentAtADifferentAddress: Yup.object({
    equipmentCountry: Yup.string().when('isEquipmentAtADifferentAddress', {
      is: true,
      then: Yup.string().required('Country required')
    }),
    equipmentState: Yup.string().when('isEquipmentAtADifferentAddress', {
      is: true,
      then: Yup.string().required('State required')
    }),
    equipmentCity: Yup.string().when('isEquipmentAtADifferentAddress', {
      is: true,
      then: Yup.string().required('City required')
    }),
    equipmentZip: Yup.string().when('isEquipmentAtADifferentAddress', {
      is: true,
      then: Yup.string().required('Zip code required')
    }),
    equipmentStreet: Yup.string().when('isEquipmentAtADifferentAddress', {
      is: true,
      then: Yup.string().required('Street required')
    })
  })
})

export const AuctionProductFormSchema = Yup.object({
  ...ProductFormSchema.fields,
  contractInfo: Yup.object({
    contractDuration: Yup.number()
      .integer('Contact duration should not have decimals')
      .required('Contract duration is required')
      .max(999, 'Contract duration should not be more than 999 days')
      .test(
        'in-range',
        'Contract duration should not be less than the auction duration',
        function (value, context) {
          if (!value) return false
          const isAuction =
            context.options.context?.listingType === listingType.Auction
          const auctionInfo = context.options.context?.auctionInfo
          const auctionDuration = isNumber(auctionInfo?.auctionDuration)
            ? Number(auctionInfo?.auctionDuration)
            : Number(auctionInfo?.auctionDurationValue)
          const auctionScheduledTime = convertToDate(
            auctionInfo?.auctionScheduledTime
          )
          const durationInDays = getDaysFromToday(
            new Date(
              new Date().setDate(
                new Date(auctionScheduledTime).getDate() +
                  Number(auctionDuration)
              )
            )
          )
          return isAuction ? value >= durationInDays : value >= 1
        }
      ),
    autoRenew: Yup.bool().required(),
    renewalWindow: Yup.number()
      .integer('Notification duration should not have decimals')
      .required('Notification duration is required')
      .test(
        'in-range',
        'Notification duration should be in range of contract duration',
        function (value) {
          if (!value) return false
          const contractDuration = this.parent.contractDuration
          return value >= 1 && value <= contractDuration
        }
      )
  }),
  auctionInfo: Yup.object({
    auctionDuration: Yup.string(),
    auctionDurationValue: Yup.number().when('auctionDuration', {
      is: 'Other',
      then: Yup.number()
        .min(3, 'The auction duration should be between 3 days - 30 days')
        .max(30, 'The auction duration should be between 3 days - 30 days')
    }),
    publishAuction: Yup.string().required('Please select a publish action'),
    auctionScheduledTime: Yup.mixed().test({
      name: 'auctionInfo.auctionScheduledTime',
      exclusive: false,
      params: {},
      message: 'Auction scheduled time must be greater than current time',
      test: (value, context) => {
        const publishAuctionValue = context.parent.publishAuction
        if (publishAuctionValue === publishAuction.NOW) {
          return true
        } else {
          const dateValue = isDate(value) ? value : convertToDate(value)
          const today = new Date()
          dateValue.setHours(0, 0, 0, 0)
          today.setHours(0, 0, 0, 0)
          return dateValue >= today
        }
      }
    })
  }),
  openingPriceMap: Yup.object({
    openingPrice: Yup.number()
      .min(1, 'This price field should be in range of $1 - $9,999,999')
      .max(9999999, 'This Price field should be in range of $1 - $9,999,999')
      .required('Starting price Not Given')
      .test({
        name: 'openingPriceMap.openingPrice',
        exclusive: false,
        params: {},
        message: 'Starting price must be less than the reserve price',
        test: (value, context) => {
          const reservePrice =
            context.options.context?.initialReservePriceMap?.reservePrice
          return value ? value <= parseFloat(reservePrice) : false
        }
      }),
    openingPriceTimestamp: Yup.object({
      seconds: Yup.number(),
      nanoseconds: Yup.number()
    })
  }),
  buyNowPriceMap: Yup.object({
    buyNowPrice: Yup.number()
      .min(1, 'This price field should be in range of $1 - $9,999,999')
      .max(9999999, 'This price field should be in range of $1 - $9,999,999')
      .required('Buy it now price not given')
      .test({
        name: 'buyNowPriceMap.buyNowPrice',
        exclusive: false,
        params: {},
        message:
          'Buy it Now price must be greater than 20% of the starting price',
        test: (value, context) => {
          const openingPrice =
            context.options.context?.openingPriceMap.openingPrice
          return value
            ? value >= Number(withBuyersPremium(openingPrice, 20))
            : false
        }
      }),
    buyNowPriceTimestamp: Yup.object({
      seconds: Yup.number(),
      nanoseconds: Yup.number()
    })
  }),
  initialReservePriceMap: Yup.object({
    reservePrice: Yup.number()
      .required('Reserve Price not given')
      .min(1, 'This price field should be in range of $1 - $9,999,999')
      .max(9999999, 'This price field should be in range of $1 - $9,999,999'),
    reservePriceTimestamp: Yup.object({
      seconds: Yup.number(),
      nanoseconds: Yup.number()
    })
  }),
  finalSalePriceMap: Yup.object({
    finalSalePrice: Yup.number(),
    finalSalePriceTimestamp: Yup.object({
      seconds: Yup.number(),
      nanoseconds: Yup.number()
    })
  }).nullable()
})
