import {InvoiceDocument} from 'common'
import AlgoliaClient, {
  INVOICE_ADMIN_PANEL_ALGOLIA_INDEX
} from 'components/AlgoliaSearch'
import Button from 'components/Button'
import {useAppContext} from 'components/Firebase/hooks'
import InputField from 'components/InputField'
import Pagination from 'components/Pagination'
import {TABLE_PAGINATION_PAGE_SIZE} from 'components/constants'
import {
  getFormattedDate,
  searchWithAlgolia,
  useSortableData
} from 'components/utils'
import {Timestamp} from 'firebase/firestore'
import {debounce} from 'lodash'
import React, {useEffect, useRef, useState} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {CheckedIcon, UncheckedIconTwo} from '../../assets/images'
import Layout from '../Layout'
import {Loader} from '../Loader'
import NavBar from '../Navbar'
import withAuthorization from '../Session/withAuthorization'
import Table from '../TableComponent'
import styles from './Invoices.module.scss'

interface InvoicesProps {
  customClass: string
}
const Invoices = ({customClass}: InvoicesProps) => {
  const {state} = useLocation()
  const pageFromLocation = state?.page
  const [currentPage, setCurrentPage] = useState<number>(pageFromLocation || 1)
  const [loader, setLoader] = useState(true)
  const [invoicesData, setInvoicesData] = useState<Array<InvoiceDocument>>([])
  const [pageCount, setPageCount] = useState<number>(0)
  const navigate = useNavigate()
  const {
    appState: {isSuperAdmin}
  } = useAppContext()

  useEffect(() => {
    AlgoliaClient.clearCache()
    searchWithAlgolia<InvoiceDocument>({
      searchQuery: '*',
      searchIndex,
      setLoaderState: setLoader,
      setData: setInvoicesData,
      setPageCount,
      currentPage
    }).then(() => setLoader(false))
  }, [])

  const getRowData = (rowData: Array<InvoiceDocument>) => {
    return (rowData || []).map((singleRowData, index: number) => {
      const {
        invoiceId,
        createdAt,
        salePrice,
        buyerAccountName,
        sellerAccountName,
        productTitle,
        invoiceMarkedAsClosed
      } = singleRowData
      const date =
        typeof createdAt === 'number'
          ? Timestamp.fromMillis(createdAt)
          : createdAt
      return (
        <tr
          key={`role-management-row-data-${index}`}
          onClick={() => {
            navigate('/invoices/single-invoice-detail', {
              state: {
                id: invoiceId,
                isEdit: true,
                currentPage
              },
              preventScrollReset: true
            })
          }}
        >
          <td>{productTitle || '-'}</td>
          <td>{buyerAccountName || '-'}</td>
          <td>{sellerAccountName || '-'}</td>
          <td>{salePrice || '-'}</td>
          <td>
            <img
              src={invoiceMarkedAsClosed ? CheckedIcon : UncheckedIconTwo}
              alt="active"
            />
          </td>
          <td>{getFormattedDate(date) || '-'}</td>
        </tr>
      )
    })
  }

  const scrollRef: React.RefObject<HTMLDivElement> = React.createRef()

  const scrollToRef = () =>
    scrollRef?.current?.scrollIntoView({behavior: 'smooth'})

  const formattedItems =
    invoicesData.map((data) => ({
      ...data,
      date: data?.createdAt || 0
    })) || invoicesData
  const {items, sortBy} = useSortableData(formattedItems)

  const showPagination = pageCount > TABLE_PAGINATION_PAGE_SIZE

  const onPageChange = (page: number) => {
    setLoader(true)
    setCurrentPage(page)
    debouncedSearch?.current({
      searchQuery: searchValue,
      searchIndex,
      setLoaderState: setLoader,
      setData: setInvoicesData,
      setPageCount,
      currentPage: page
    })
    setLoader(false)
    scrollToRef()
  }

  const tableTitles = [
    {title: 'Equipment Title', sortBy: () => sortBy('productTitle')},
    {title: 'Buyer Account Name', sortBy: () => sortBy('buyerAccountName')},
    {title: 'Seller Account Name', sortBy: () => sortBy('sellerAccountName')},
    {title: 'Sale Price', sortBy: () => sortBy('salePrice')},
    {title: 'Marked as Closed'},
    {title: 'Date', sortBy: () => sortBy('date')}
  ]

  const [searchValue, setSearchValue] = useState('')
  const searchIndex = AlgoliaClient.initIndex(INVOICE_ADMIN_PANEL_ALGOLIA_INDEX)
  const debouncedSearch = useRef(
    debounce(searchWithAlgolia<InvoiceDocument>, 800)
  )

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event?.target?.value
    setSearchValue(value)
    if (value) {
      debouncedSearch?.current({
        searchQuery: value,
        searchIndex,
        setLoaderState: setLoader,
        setData: setInvoicesData,
        setPageCount
      })
    } else {
      debouncedSearch?.current({
        searchQuery: '*',
        searchIndex,
        setLoaderState: setLoader,
        setData: setInvoicesData,
        setPageCount,
        currentPage
      })
    }
  }

  return (
    <div className={styles.withLayout}>
      <Layout />
      <div className={[styles.container, customClass].join(' ')}>
        <NavBar title="Invoices" />
        <div className={styles.button_filters}>
          <InputField
            name="invoiceSearchField"
            type="text"
            value={searchValue}
            onChange={handleSearch}
            customClass={styles.inputField}
            placeholder="Search for Filters"
            isSearch={true}
          />
          {isSuperAdmin && (
            <Button
              customClass={styles.create_account_btn}
              text="Generate Invoice"
              onClick={() => navigate('/invoices/create-invoice')}
            />
          )}
        </div>
        {loader ? (
          <Loader />
        ) : (
          <React.Fragment>
            <Table
              customClass={styles.threedetails}
              titles={tableTitles}
              rowData={getRowData(items)}
            />
            {showPagination && (
              <div className="pagination__wrapper">
                <Pagination
                  currentPage={currentPage}
                  totalCount={pageCount}
                  pageSize={TABLE_PAGINATION_PAGE_SIZE}
                  onPageChange={onPageChange}
                />
              </div>
            )}
          </React.Fragment>
        )}
      </div>
    </div>
  )
}

export default withAuthorization(Invoices)
