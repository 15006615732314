import {IDashboardTab} from 'components/types'
import {BiSolidCategory, BiSolidCategoryAlt} from 'react-icons/bi'
import {
  FaBell,
  FaChartPie,
  FaCreditCard,
  FaFileInvoiceDollar,
  FaFileSignature,
  FaHandshake,
  FaHome,
  FaMoneyCheckAlt,
  FaPhone,
  FaRocketchat,
  FaShoppingBasket,
  FaStar,
  FaSyncAlt,
  FaTags,
  FaUser,
  FaUsers
} from 'react-icons/fa'
import {HiReceiptTax} from 'react-icons/hi'
import {PiCertificateFill} from 'react-icons/pi'
import {RiAdminFill, RiSecurePaymentFill} from 'react-icons/ri'

export const tabs: IDashboardTab[] = [
  {
    icon: <FaHome />,
    path: '/dashboard',
    label: 'Home',
    isSuperAdmin: true,
    isSuperManager: true,
    isMSAAdmin: true
  },
  {
    icon: <FaBell />,
    path: '/notifications',
    label: 'Notifications',
    isSuperAdmin: true,
    isSuperManager: true,
    isMSAAdmin: true
  },
  {
    icon: <FaPhone />,
    path: '/contacts',
    label: 'Contacts',
    isSuperAdmin: true,
    isSuperManager: true,
    isMSAAdmin: true
  },
  {
    icon: <FaUser />,
    path: '/accounts',
    label: 'Accounts',
    isSuperAdmin: true,
    isSuperManager: true,
    isMSAAdmin: true
  },
  {
    icon: <FaShoppingBasket />,
    path: '/equipment',
    label: 'Equipment',
    isSuperAdmin: true,
    isSuperManager: true,
    isMSAAdmin: true
  },
  {
    icon: <FaChartPie />,
    path: '/reports',
    label: 'Equipment Reports',
    isSuperAdmin: true,
    isSuperManager: true,
    isMSAAdmin: true
  },
  {
    icon: <FaTags />,
    path: '/deals',
    label: 'Deals',
    isSuperAdmin: true,
    isSuperManager: true,
    isMSAAdmin: false
  },
  {
    icon: <FaFileInvoiceDollar />,
    path: '/invoices',
    label: 'Invoices',
    isSuperAdmin: true,
    isSuperManager: true,
    isMSAAdmin: false
  },
  {
    icon: <HiReceiptTax />,
    path: '/sales-tax',
    label: 'Sales Tax',
    isSuperAdmin: true,
    isSuperManager: true,
    isMSAAdmin: false
  },
  {
    icon: <FaUsers />,
    path: '/taxjar-customers',
    label: 'TaxJar Customers',
    isSuperAdmin: true,
    isSuperManager: true,
    isMSAAdmin: false
  },
  {
    icon: <RiAdminFill />,
    path: '/role-management',
    label: 'Role Management',
    isSuperAdmin: true,
    isSuperManager: false,
    isMSAAdmin: false
  },
  {
    icon: <FaRocketchat />,
    path: '/chats',
    label: 'Chats',
    isSuperAdmin: true,
    isSuperManager: true,
    isMSAAdmin: true
  },
  {
    icon: <FaMoneyCheckAlt />,
    path: '/finance-applications',
    label: 'Finance Applications',
    isSuperAdmin: true,
    isSuperManager: false,
    isMSAAdmin: false
  },
  {
    icon: <PiCertificateFill />,
    path: '/tax-exemption-applications',
    label: 'Tax Exemption Applications',
    isSuperAdmin: true,
    isSuperManager: false,
    isMSAAdmin: false
  },
  {
    icon: <FaCreditCard />,
    path: '/bank-account-info',
    label: 'Bank Account Info',
    isSuperAdmin: true,
    isSuperManager: true,
    isMSAAdmin: false
  },
  {
    icon: <BiSolidCategory />,
    path: '/categories',
    label: 'Categories',
    isSuperAdmin: true,
    isSuperManager: true,
    isMSAAdmin: false
  },
  {
    icon: <BiSolidCategoryAlt />,
    path: '/sectors',
    label: 'Sectors',
    isSuperAdmin: true,
    isSuperManager: true,
    isMSAAdmin: false
  },
  {
    icon: <FaSyncAlt />,
    path: '/sync',
    label: 'Sync',
    isSuperAdmin: true,
    isSuperManager: false,
    isMSAAdmin: false
  },
  {
    icon: <FaHandshake />,
    path: '/msa-agreement',
    label: 'MSA Agreements',
    isSuperAdmin: true,
    isSuperManager: false,
    isMSAAdmin: false
  },
  {
    icon: <FaFileSignature />,
    path: '/listing-agreement',
    label: 'Listing Agreements',
    isSuperAdmin: true,
    isSuperManager: false,
    isMSAAdmin: false
  },
  {
    icon: <RiSecurePaymentFill />,
    path: '/ach-agreement',
    label: 'ACH Agreements',
    isSuperAdmin: true,
    isSuperManager: false,
    isMSAAdmin: false
  },
  {
    icon: <FaStar />,
    path: '/feedback',
    label: 'Feedback',
    isSuperAdmin: true,
    isSuperManager: false,
    isMSAAdmin: false
  }
]

const extendedRoutes = [
  {
    path: '/deals/deal-details',
    isSuperAdmin: true,
    isSuperManager: true,
    isMSAAdmin: false
  },
  {
    path: '/finance-applications/finance-application-details',
    isSuperAdmin: true,
    isSuperManager: false,
    isMSAAdmin: false
  },
  {
    path: '/accounts/single-account-details',
    isSuperAdmin: true,
    isSuperManager: true,
    isMSAAdmin: true
  },
  {
    path: '/msa-agreement/agreement-details',
    isSuperAdmin: true,
    isSuperManager: false,
    isMSAAdmin: false
  },
  {
    path: '/listing-agreement/agreement-details',
    isSuperAdmin: true,
    isSuperManager: false,
    isMSAAdmin: false
  },
  {
    path: '/role-management/role-management-details',
    isSuperAdmin: true,
    isSuperManager: false,
    isMSAAdmin: false
  },
  {
    path: '/contacts/single-contact-details',
    isSuperAdmin: true,
    isSuperManager: true,
    isMSAAdmin: true
  },
  {
    path: '/categories/single-category-details',
    isSuperAdmin: true,
    isSuperManager: false,
    isMSAAdmin: false
  },
  {
    path: '/equipment/single-equipment-detail',
    isSuperAdmin: true,
    isSuperManager: true,
    isMSAAdmin: true
  },
  {
    path: '/reports/single-report-detail',
    isSuperAdmin: true,
    isSuperManager: true,
    isMSAAdmin: true
  },
  {
    path: '/invoices/create-invoice',
    isSuperAdmin: true,
    isSuperManager: false,
    isMSAAdmin: false
  },
  {
    path: '/invoices/single-invoice-detail',
    isSuperAdmin: true,
    isSuperManager: true,
    isMSAAdmin: false
  }
]

export const routes = tabs
  .map(({path, isMSAAdmin, isSuperAdmin, isSuperManager}) => ({
    path,
    isMSAAdmin,
    isSuperAdmin,
    isSuperManager
  }))
  .concat(extendedRoutes)
