import {useState} from 'react'
import {Document, Page, pdfjs} from 'react-pdf'
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`

interface PDFViewerProps {
  file: any
  setShowLoader: (prop: boolean) => void
}

export default function PDFViewer({file, setShowLoader}: PDFViewerProps) {
  const [numPages, setNumPages] = useState<number>(0)
  const [error, setError] = useState<boolean>(false)

  function onDocumentLoadSuccess({numPages: nextNumPages}: any) {
    setNumPages(nextNumPages)
    setShowLoader(false)
  }

  function onDocumentLoadError() {
    setShowLoader(false)
    setError(true)
  }

  return (
    <Document
      file={file}
      onLoadSuccess={onDocumentLoadSuccess}
      onLoadError={onDocumentLoadError}
    >
      {Array.from({length: numPages}, (_, index) => (
        <Page
          key={`page_${index + 1}`}
          pageNumber={index + 1}
          renderAnnotationLayer={false}
          renderTextLayer={false}
        />
      ))}
      {error && <div>Failed to Load PDF</div>}
    </Document>
  )
}
