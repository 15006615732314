import {Cancel} from 'assets/svgs'
import {AgreementDocument} from 'common'
import {Collection} from 'common/enums'
import Button from 'components/Button'
import {useFirebase} from 'components/Firebase/hooks'
import ModalComponent from 'components/ModalComponent'
import {useState} from 'react'
import {toast} from 'react-toastify'
import styles from './RecallModal.module.scss'

interface RecallModalProps {
  agreementType:
    | Collection.ZohoSignGeneralMsaAgreement
    | Collection.ZohoSignListingAgreement
  agreement?: AgreementDocument
  handleClose: () => void
  show: boolean
}

const RecallModal = ({
  handleClose,
  show,
  agreement,
  agreementType
}: RecallModalProps) => {
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [reason, setReason] = useState('Cancelled by Admin')
  const {recallAgreement} = useFirebase()

  const handleSubmit = async () => {
    if (isSubmitted) return
    if (!reason.length) {
      toast.warn('Please enter a recall reason.')
      return
    }
    setIsSubmitted(true)
    const {error, message} = await recallAgreement(
      reason,
      agreement?.documentId || '',
      agreementType
    )
    error ? toast.warn(message) : toast.success(message)
    setIsSubmitted(false)
    handleClose()
  }

  return (
    <ModalComponent show={show} handleClose={handleClose}>
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.heading}>
            <Cancel onClick={handleClose} />
            <h1>Agreement Recall Reason</h1>
          </div>
          <div className={styles.textarea_wrapper}>
            <div>
              <textarea
                onChange={(e) => setReason(e.target.value.slice(0, 250))}
                placeholder={'Please enter agreement recall reason.'}
                value={reason}
                maxLength={250}
                cols={30}
                rows={10}
              ></textarea>
              <div className={styles.text_options}>
                <div>{reason.length}/250 characters</div>
              </div>
            </div>
          </div>
          <div className={styles.buttons}>
            <button className="btn-cancel" onClick={handleClose}>
              Cancel
            </button>
            <Button
              customBtnClass="btn-fill-orange"
              onClick={handleSubmit}
              disabled={isSubmitted}
              type="button"
              text="Submit"
            />
          </div>
        </div>
      </div>
    </ModalComponent>
  )
}

export default RecallModal
