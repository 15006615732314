import {API_BASE_URLS, PROJECT_IDS} from 'common/apis'
import {Environment} from 'common/enums'

export const URL_REGEX =
  /^(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()!@:%_\+.~#?&\/\/=]*)$/

export const USER_NOT_AUTHORIZED_MESSAGE = 'User does not have access'
export const NOTIFICATION_NOT_GRANTED_MESSAGE = `Don't miss out on important updates! Enable browser notifications to stay informed. Please note that some browser modes may prevent notifications from appearing.`

export const TABLE_PAGINATION_PAGE_SIZE = 50

const environment =
  (process.env.REACT_APP_PUBLIC_APPLICATION_ENVIRONMENT as Environment) ||
  Environment.DEVELOPMENT

console.log(`Running in ${environment}`)

// const environmentSuffix =
//   environment === Environment.PRODUCTION
//     ? ''
//     : environment === Environment.STAGING
//     ? ''
//     : 'demo'

// export const PRODUCTION_URL = `https://auction-site-${environmentSuffix}.vercel.app` // follow this suffix approach for later environment setups
export const PRODUCTION_URL = `https://divauctions.divdash.com/`

export const API_HOST_URL =
  process.env.REACT_APP_USE_EMULATOR === 'true'
    ? `http://127.0.0.1:5001/${PROJECT_IDS[environment]}/us-central1`
    : API_BASE_URLS[environment]
