import {DealDocument} from 'common'
import {dealStage} from 'common/enums'
import AlgoliaClient, {
  DEALS_ADMIN_PANEL_ALGOLIA_INDEX
} from 'components/AlgoliaSearch'
import InputField from 'components/InputField'
import Pagination from 'components/Pagination'
import ReqNotFoundScreen from 'components/ReqNotFoundScreen'
import {TABLE_PAGINATION_PAGE_SIZE} from 'components/constants'
import {Timestamp} from 'firebase/firestore'
import {debounce, isDate} from 'lodash'
import React, {useEffect, useRef, useState} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import Button from '../Button'
import Layout from '../Layout'
import {Loader} from '../Loader'
import NavBar from '../Navbar'
import withAuthorization from '../Session/withAuthorization'
import Table from '../TableComponent'
import {
  convertToDate,
  getFormattedDate,
  searchWithAlgolia,
  useSortableData
} from '../utils'
import styles from './DealPage.module.scss'

const DealPage = ({customClass}: {customClass: string}) => {
  const {state} = useLocation()
  const pageFromLocation = state?.page
  const [currentPage, setCurrentPage] = useState<number>(pageFromLocation || 1)
  const [dealData, setDealData] = useState<Array<DealDocument>>([])
  const [loader, setLoader] = useState(true)
  const [pageCount, setPageCount] = useState<number>(0)
  const navigate = useNavigate()

  useEffect(() => {
    AlgoliaClient.clearCache()
    searchWithAlgolia<DealDocument>({
      searchQuery: '*',
      searchIndex,
      setLoaderState: setLoader,
      setData: setDealData,
      setPageCount,
      currentPage
    }).then(() => setLoader(false))
  }, [])

  const tableTitles = [
    {title: 'Deal Name', sortBy: () => sortBy('dealName')},
    {title: 'Stage', sortBy: () => sortBy('stage')},
    {title: 'Buyer Account Name', sortBy: () => sortBy('buyerAccountName')},
    {title: 'Seller Account Name', sortBy: () => sortBy('sellerAccountName')},
    {title: 'Created At', sortBy: () => sortBy('sortDate')}
  ]

  const getFormattedData = (dealData: DealDocument) => {
    const {dealName, stage, buyerAccountName, sellerAccountName, dealId} =
      dealData
    const createdAtDate =
      dealData?.createdAt && convertToDate(dealData?.createdAt)

    const createdAt = isDate(createdAtDate)
      ? getFormattedDate(createdAtDate)
      : '-'
    const date = isDate(createdAtDate)
      ? Timestamp.fromDate(createdAtDate).seconds
      : '-'
    return {
      dealName,
      stage,
      buyerAccountName,
      sellerAccountName,
      dealId,
      createdAt,
      sortDate: dealData?.createdAt || 0,
      date
    }
  }

  const getRowData = (
    rowData: Array<{
      dealName: string
      stage: dealStage
      buyerAccountName: string
      sellerAccountName: string
      dealId: string
      createdAt: string
      date: string | number
    }>
  ) => {
    return (rowData || []).map((singleRowData, index) => {
      const {
        dealName,
        stage,
        buyerAccountName,
        sellerAccountName,
        createdAt,
        dealId
      } = singleRowData
      return (
        <tr
          key={`deal-row-data-${index}`}
          onClick={() => {
            navigate('/deals/deal-details', {
              state: {id: dealId, isEdit: true, currentPage},
              preventScrollReset: true
            })
          }}
        >
          <td>{dealName || '-'}</td>
          <td>{stage || '-'}</td>
          <td>{buyerAccountName || '-'}</td>
          <td>{sellerAccountName || '-'}</td>
          <td>{createdAt || '-'}</td>
        </tr>
      )
    })
  }

  const noData = !dealData.length && !loader

  const scrollRef: React.RefObject<HTMLDivElement> = React.createRef()

  const scrollToRef = () =>
    scrollRef?.current?.scrollIntoView({behavior: 'smooth'})

  const formattedContactData = dealData.map((data) => getFormattedData(data))
  const {items, sortBy} = useSortableData(formattedContactData)

  const showPagination = pageCount > TABLE_PAGINATION_PAGE_SIZE

  const onPageChange = (page: number) => {
    setLoader(true)
    setCurrentPage(page)
    debouncedSearch?.current({
      searchQuery: searchValue,
      searchIndex,
      setLoaderState: setLoader,
      setData: setDealData,
      setPageCount,
      currentPage: page
    })
    setLoader(false)
    scrollToRef()
  }

  const [searchValue, setSearchValue] = useState('')
  const searchIndex = AlgoliaClient.initIndex(DEALS_ADMIN_PANEL_ALGOLIA_INDEX)
  const debouncedSearch = useRef(debounce(searchWithAlgolia<DealDocument>, 800))

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event?.target?.value
    setSearchValue(value)
    if (value) {
      debouncedSearch?.current({
        searchQuery: value,
        searchIndex: searchIndex,
        setLoaderState: setLoader,
        setData: setDealData,
        setPageCount
      })
      return
    }
    debouncedSearch?.current({
      searchQuery: '*',
      searchIndex,
      setLoaderState: setLoader,
      setData: setDealData,
      setPageCount,
      currentPage
    })
  }

  return (
    <div className={styles.withLayout}>
      <Layout />
      <div className={[styles.container, customClass].join(' ')}>
        <NavBar title="Deals" />
        <div className={styles.button_filters}>
          <InputField
            name="dealSearchField"
            type="text"
            value={searchValue}
            onChange={handleSearch}
            customClass={styles.inputField}
            placeholder="Search for Filters"
            isSearch={true}
          />
          <div className={styles.button_creaters}>
            <Button
              onClick={() => navigate('/deals/deal-details')}
              text={'Create Deal'}
              customClass={styles.creater_button}
            />
          </div>
        </div>
        {loader ? (
          <Loader />
        ) : noData ? (
          <ReqNotFoundScreen />
        ) : (
          <React.Fragment>
            <Table titles={tableTitles} rowData={getRowData(items)} />
            {showPagination && (
              <div className="pagination__wrapper">
                <Pagination
                  currentPage={currentPage}
                  totalCount={pageCount}
                  pageSize={TABLE_PAGINATION_PAGE_SIZE}
                  onPageChange={onPageChange}
                />
              </div>
            )}
          </React.Fragment>
        )}
      </div>
    </div>
  )
}

export default withAuthorization(DealPage)
