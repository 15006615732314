import * as Yup from 'yup'

export const ChangePasswordSchema = Yup.object().shape({
  currentPassword: Yup.string()
    .required('required*')
    .matches(
      /^[A-Za-z0-9!@#*()+{}.&-_^%+=$]*$/,
      'Password cannot contain spaces'
    ),
  newPassword: Yup.string()
    .required('required*')
    .matches(
      /^[A-Za-z0-9!@#*()+{}.&-_^%+=$]*$/,
      'Password cannot contain spaces'
    )
    .min(8, 'Password should be at least 8 characters')
    .notOneOf(
      [Yup.ref('currentPassword'), null],
      'Current and new password cannot be the same'
    ),
  confirmNewPassword: Yup.string()
    .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
    .required('required*')
})
