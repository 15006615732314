import {InvoiceDocument} from 'common'
import ContentModal from 'components/ContentModal'
import {useFirebase} from 'components/Firebase/hooks'
import ModalComponent from 'components/ModalComponent'
import {useState} from 'react'
import {toast} from 'react-toastify'

const NoticeOfReleaseModal = ({
  show,
  handleClose,
  invoiceDocument
}: {
  show: boolean
  handleClose: () => void
  invoiceDocument: InvoiceDocument
}) => {
  const [data] = useState(invoiceDocument)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const {sendNoticeOfRelease} = useFirebase()
  const noticeOfRelease = data?.noticeOfRelease

  const handleSubmit = async () => {
    if (isSubmitting) return
    setIsSubmitting(true)
    const {success} = await sendNoticeOfRelease(invoiceDocument)
    if (!success) {
      setIsSubmitting(false)
      toast.warn('Something went wrong! Failed to send notice of release')
      return
    }
    toast.success('Notice of Relase sent successfully!')
    handleClose()
  }

  const heading = `${noticeOfRelease ? 'Re-' : ''}Send Notice of Release`
  const send = `
  Are you sure you want to send the Notice of Release? 
  By confirming, you will notify the seller that payment for ${invoiceDocument.productTitle} has been received. 
  The seller will be authorized to release the equipment to the buyer. 
  This action cannot be undone.`
  const resend = `
  Are you sure you want to re-send the Notice of Release? 
  This will remind the seller that the buyer's payment for ${invoiceDocument.productTitle} has been completed.`

  return (
    <ModalComponent show={show} handleClose={handleClose}>
      <ContentModal
        heading={heading}
        buttonText={'Submit'}
        handleClose={handleClose}
        buttonOnClick={handleSubmit}
        buttonDisabled={isSubmitting}
        content={noticeOfRelease ? resend : send}
      />
    </ModalComponent>
  )
}

export default NoticeOfReleaseModal
