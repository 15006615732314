import React from 'react'
import styles from './DatePicker.module.scss'
import {useWindowSize} from 'components/utils'
import moment, {Moment} from 'moment'
import {
  DatePicker,
  DateValidationError,
  MobileDatePicker,
  PickerChangeHandlerContext
} from '@mui/x-date-pickers'
import {isDate} from 'lodash'

interface DatePickerProps {
  selectedValue: Date
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<any>
  isYear?: Boolean
  name: string
  minDate?: Date
  maxDate?: Date
  label?: string
  disabled?: boolean
}
type NestedStyles = {
  [key: string]: React.CSSProperties
}

const DatePickerComponent = ({
  name,
  selectedValue,
  setFieldValue,
  isYear,
  minDate,
  maxDate = new Date('1 Jan 2077'),
  label,
  disabled
}: DatePickerProps) => {
  const {width = 0} = useWindowSize()
  const isDesktop = width > 1025
  const inputStyles: React.CSSProperties = !isDesktop
    ? {
        padding: '0 0 0 16.5px',
        width: '94%'
      }
    : {
        paddingTop: 'unset',
        paddingRight: 'unset',
        paddingBottom: 'unset',
        width: '96%'
      }

  const pickerStyles: NestedStyles = {
    '& .MuiInputBase-root': {
      border: 'none',
      display: 'block',
      position: 'relative'
    },
    '& .MuiInputBase-input': {
      ...inputStyles
    },
    '& .MuiOutlinedInput-notchedOutline': {
      visibility: 'hidden'
    },
    '& .MuiInputAdornment-root': {
      position: 'absolute',
      maxHeight: 'unset',
      width: '95%',
      height: '100%',
      top: '0',
      left: '0',
      background: 'unset'
    },
    '& .MuiInputAdornment-root button': {
      width: '100%',
      height: '100%',
      background: 'unset'
    },
    '& .MuiInputAdornment-root button span': {
      display: 'none'
    },
    '& .MuiInputAdornment-root button svg': {
      display: 'none'
    }
  }

  const getYear = isDate(selectedValue)
    ? new Date(`1 Jan ${selectedValue?.getFullYear()}`)
    : new Date(`1 Jan ${selectedValue || new Date().getFullYear()}`)

  const handleYearPickerOnChange = (
    value: Moment | null,
    _context?: PickerChangeHandlerContext<DateValidationError>
  ) => setFieldValue(name || 'year', moment(value).toDate().getFullYear())

  const handleCalenderPickerOnChange = (
    value: Moment | null,
    _context?: PickerChangeHandlerContext<DateValidationError>
  ) => setFieldValue(name || 'year', moment(value).toDate())

  return (
    <div className={styles.inputs}>
      {label && <label>{label}</label>}
      {isYear ? (
        isDesktop ? (
          <DatePicker
            key={name}
            views={['year']}
            sx={pickerStyles}
            defaultValue={moment(maxDate)}
            minDate={moment(minDate)}
            maxDate={moment(maxDate)}
            disabled={disabled}
            onChange={handleYearPickerOnChange}
            value={moment(getYear)}
          />
        ) : (
          <MobileDatePicker
            key={name}
            views={['year']}
            sx={pickerStyles}
            defaultValue={moment(maxDate)}
            minDate={moment(minDate)}
            maxDate={moment(maxDate)}
            disabled={disabled}
            onChange={handleYearPickerOnChange}
            value={moment(getYear)}
          />
        )
      ) : isDesktop ? (
        <DatePicker
          key={name}
          sx={pickerStyles}
          defaultValue={moment(maxDate)}
          minDate={moment(minDate)}
          maxDate={moment(maxDate)}
          disabled={disabled}
          onChange={handleCalenderPickerOnChange}
          value={moment(selectedValue)}
        />
      ) : (
        <MobileDatePicker
          key={name}
          sx={pickerStyles}
          defaultValue={moment(maxDate)}
          minDate={moment(minDate)}
          maxDate={moment(maxDate)}
          disabled={disabled}
          onChange={handleCalenderPickerOnChange}
          value={moment(selectedValue)}
        />
      )}
    </div>
  )
}

export default DatePickerComponent
