import React, {ReactNode} from 'react'
import {Link as RouterLink} from 'react-router-dom'

interface LinkWrapperProps
  extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  to: string
  external?: boolean
  children: ReactNode
}

const LinkWrapper: React.FC<LinkWrapperProps> = ({
  to,
  external,
  children,
  ...rest
}) => {
  if (external) {
    return (
      <a
        style={{textDecoration: 'none'}}
        href={to}
        target="_blank"
        rel="noreferrer"
        {...rest}
      >
        {children}
      </a>
    )
  }

  return (
    <RouterLink style={{textDecoration: 'none'}} to={to} {...rest}>
      {children}
    </RouterLink>
  )
}

export default LinkWrapper
