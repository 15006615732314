import styles from './Layout.module.scss'
import classNames from 'classnames'
import React from 'react'
import {MiniLogo, LoginSvg} from '../../assets/svgs'
import {useLocation, useNavigate} from 'react-router-dom'
import {useAppContext} from 'components/Firebase/hooks'
import {tabs} from './constants'
import {IDashboardTab} from 'components/types'

const Layout: React.FC = () => {
  const {pathname} = useLocation()
  const navigate = useNavigate()
  const {appState} = useAppContext()

  const isSuperAdmin = appState?.isSuperAdmin
  const isSuperManager = appState?.isSuperManager
  const isMSAAdmin = appState?.isMSAAdmin

  const activeTab = classNames(styles.button_links, styles['active_button'])

  const renderTab = (tab: IDashboardTab) => {
    const isActive = pathname.includes(tab.path)

    const isAllowed =
      (tab.isSuperAdmin && isSuperAdmin) ||
      (tab.isSuperManager && isSuperManager) ||
      (tab.isMSAAdmin && isMSAAdmin)

    if (isAllowed) {
      return (
        <button
          key={tab.path}
          onClick={() => navigate(tab.path)}
          className={isActive ? activeTab : styles.button_links}
        >
          {tab.icon}
          {tab.label}
        </button>
      )
    }
    return null
  }

  return (
    <div className={styles.links}>
      <div className={styles.links_inner}>
        <MiniLogo
          className={styles.logo}
          onClick={() => {
            navigate('/dashboard')
          }}
        />
        <div className={styles.buttons}>{tabs.map(renderTab)}</div>
      </div>
    </div>
  )
}

export default Layout
