import React from 'react'
import classNames from 'classnames'
import styles from './DetailPage.module.scss'
import ProductDetails from './ProductDetails'
import ListingAgreementDetailsPage from './ListingAgreementDetails'
import InvoiceDetails from './InvoiceDetails'
import DealsDetails from './DealsDetails'
import FinanceDetails from './FinanceDetails'
import AdminDetails from './AdminDetails'
import ContactDetails from './ContactDetails'
import CategoryDetails from './CategoryDetails'
import BankAccountDetails from './BankAccountDetails'
import AccountDetails from './AccountDetails'
import {DetailType} from 'components/enums'
import SalesTaxDetails from './SalesTaxDetails'
import TaxJarCustomerDetails from './TaxJarCustomerDetails'
import TaxExemptionDetails from './TaxExemptionDetails'
import EquipmentReportDetails from './EquipmentReportDetails'
import SectorDetails from './SectorDetails'

interface DetailPageProps {
  data: any
  detailType?: DetailType
}

const DetailPage: React.FC<DetailPageProps> = ({data, detailType}) => {
  const renderDetailsComponent = () => {
    switch (detailType) {
      case DetailType.Product:
        return <ProductDetails {...data} />
      case DetailType.Deals:
        return <DealsDetails {...data} />
      case DetailType.Agreement:
        return <ListingAgreementDetailsPage {...data} />
      case DetailType.Finance:
        return <FinanceDetails {...data} />
      case DetailType.Admin:
        return <AdminDetails {...data} />
      case DetailType.Invoice:
        return <InvoiceDetails {...data} />
      case DetailType.Contact:
        return <ContactDetails {...data} />
      case DetailType.Category:
        return <CategoryDetails {...data} />
      case DetailType.Sector:
        return <SectorDetails {...data} />
      case DetailType.BankAccount:
        return <BankAccountDetails {...data} />
      case DetailType.Account:
        return <AccountDetails {...data} />
      case DetailType.SalesTax:
        return <SalesTaxDetails {...data} />
      case DetailType.TaxJarCustomer:
        return <TaxJarCustomerDetails {...data} />
      case DetailType.TaxExemption:
        return <TaxExemptionDetails {...data} />
      case DetailType.EquipmentReports:
        return <EquipmentReportDetails {...data} />
      default:
        return null
    }
  }

  return (
    <div
      className={classNames(
        styles.from_submiting,
        detailType === DetailType.Contact && styles.contactContainer,
        detailType === DetailType.Agreement && styles.agreementDetails,
        detailType === DetailType.TaxExemption && styles.agreementDetails
      )}
    >
      {renderDetailsComponent()}
    </div>
  )
}

export default DetailPage
