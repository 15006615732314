import {get} from 'lodash'
import React from 'react'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import styles from './PhoneInput.module.scss'

const buttonStyle = {
  background: '#F4F4F4',
  borderRight: 'none',
  borderTopLeftRadius: '10px',
  borderBottomLeftRadius: '10px',
  border: '1px solid #E5E5E5'
}

const inputStyle = {
  background: '#F4F4F4',
  width: '321.42px',
  fontSize: '14px',
  borderRadius: '10px',
  fontWeight: '500',
  border: '1px solid #E5E5E5',
  paddingLeft: '50px'
}

interface PhoneInputProps {
  id?: string
  name: string
  placeholder?: string
  type?: string
  value?: string
  errors?: any
  touched?: any
  label?: string
  smaller?: boolean
  disabled?: boolean
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<any>
  onBlur?: (e: any) => void
  disableDropdown?: boolean
  customError?: string
}

const PhoneInputSelect = ({
  label,
  name,
  id,
  value,
  errors = {},
  touched = {},
  setFieldValue,
  onBlur,
  disabled,
  placeholder,
  disableDropdown,
  customError
}: PhoneInputProps) => {
  const hasError = (get(touched, name) && get(errors, name)) || customError
  return (
    <div className={styles.container}>
      <label htmlFor={id}>{label}</label>
      <PhoneInput
        placeholder={placeholder}
        country="us"
        inputProps={{
          name,
          id,
          onBlur
        }}
        value={value}
        disableDropdown={disableDropdown}
        onChange={(v, d, e) => {
          setFieldValue!(name || id || '', `+${v}`)
        }}
        buttonClass={styles.phone_input_button}
        buttonStyle={{
          ...buttonStyle,
          backgroundColor: hasError
            ? 'rgba(248, 165, 23, 0.0980387)'
            : '#F4F4F4',
          borderColor: hasError ? '#F8A517' : '#E5E5E5'
        }}
        inputStyle={{
          ...inputStyle,
          width: '100%',
          height: '49px',
          backgroundColor: hasError
            ? 'rgba(248, 165, 23, 0.0980387)'
            : '#F4F4F4',
          borderColor: hasError ? '#F8A517' : '#E5E5E5'
        }}
        disabled={disabled}
      />
      <div className={styles.error}>{hasError}</div>
    </div>
  )
}

export default PhoneInputSelect
