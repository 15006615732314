import React from 'react'
import styles from '../DetailPage.module.scss'
import {SalesTaxDocument} from 'common'
import {getFormattedDate, withThousandSeperatorString} from 'components/utils'
import {Timestamp} from 'firebase/firestore'
import {FieldSet} from '../DetailFields'

const SalesTaxDetails = (data: SalesTaxDocument) => {
  const {generalInfo, salesTaxInfo} = data
  const breakdown = salesTaxInfo?.breakdown
  const createdAt = generalInfo?.createdAt || Timestamp.now()

  const buyerAddress = {
    city: generalInfo?.buyerBillingAddress?.billingCity,
    state: generalInfo?.buyerBillingAddress?.billingState,
    country: generalInfo?.buyerBillingAddress?.billingCountry,
    streetAddress: generalInfo?.buyerBillingAddress?.billingStreetAddress
  }
  const sellerAddress = {
    city: generalInfo?.sellerBillingAddress?.billingCity,
    state: generalInfo?.sellerBillingAddress?.billingState,
    country: generalInfo?.sellerBillingAddress?.billingCountry,
    streetAddress: generalInfo?.sellerBillingAddress?.billingStreetAddress
  }

  const getFormattedAddress = (address: {
    city: string
    state: string
    country: string
    streetAddress: string
  }) => {
    const {city, country, state, streetAddress} = address
    return (
      `${streetAddress ? streetAddress + ', ' : ''}` +
      `${city ? city + ', ' : ''}` +
      `${state || ''}` +
      ', ' +
      `${country ? country : ''}`
    )
  }

  const asPrice = (value: number | undefined) => {
    return value !== undefined ? withThousandSeperatorString(`${value}`) : ''
  }

  const withPercentage = (value: number | undefined) => {
    if (value !== undefined) {
      const percentValue = Number(value) * 100
      return `${value} (${percentValue} Percent)`
    }
    return ''
  }

  const generalInformation = [
    {label: 'Invoice Id', value: generalInfo?.invoiceId},
    {label: 'Equipment Name', value: generalInfo?.productTitle},
    {label: 'Buyer ID', value: generalInfo?.buyerContactId},
    {label: 'Buyer Name', value: generalInfo?.buyerContactName},
    {label: 'Buyer Account ID', value: generalInfo?.buyerAccountId},
    {label: 'Buyer Address', value: getFormattedAddress(buyerAddress)}
  ]

  const salesTaxInformation = [
    {label: 'Final Sale Price', value: asPrice(salesTaxInfo?.orderTotalAmount)},
    {
      label: 'State Sales Tax Rate',
      value: withPercentage(breakdown?.lineItems![0]?.stateSalesTaxRate)
    },
    {
      label: 'County Tax Rate',
      value: withPercentage(breakdown?.countyTaxRate)
    },
    {
      label: 'City Tax Rate',
      value: withPercentage(breakdown?.cityTaxRate)
    },
    {
      label: 'Special Tax Rate',
      value: withPercentage(breakdown?.specialTaxRate)
    }
  ]

  const salesTax = [
    {
      label: 'Combined Tax Rate',
      value: withPercentage(breakdown?.lineItems![0]?.combinedTaxRate)
    },
    {
      label: 'State Tax Amount',
      value: asPrice(breakdown?.lineItems![0]?.stateAmount)
    },
    {
      label: 'City Tax Amount',
      value: asPrice(breakdown?.lineItems![0]?.cityAmount)
    }
  ]

  const salesTaxSecondColumn = [
    {label: 'Purchase Date', value: getFormattedDate(createdAt)},
    {label: 'Equipment ID', value: generalInfo?.productId},
    {label: 'Seller ID', value: generalInfo?.sellerContactId},
    {label: 'Seller Name', value: generalInfo?.sellerContactName},
    {label: 'Seller Account ID', value: generalInfo?.sellerAccountId},
    {label: 'Seller Address', value: getFormattedAddress(sellerAddress)}
  ]

  const salesTaxSecondColumnSecondRow = [
    {label: 'Taxable Amount', value: asPrice(breakdown?.taxableAmount)},
    {
      label: 'State Taxable Amount',
      value: asPrice(breakdown?.stateTaxableAmount)
    },
    {
      label: 'County Taxable Amount',
      value: asPrice(breakdown?.countyTaxRate)
    },
    {
      label: 'City Taxable Amount',
      value: asPrice(breakdown?.cityTaxableAmount)
    },
    {
      label: 'Special District Taxable Amount',
      value: asPrice(breakdown?.specialDistrictTaxableAmount)
    }
  ]

  const salesTaxSecondColumnThirdRow = [
    {
      label: 'Special District Amount',
      value: asPrice(breakdown?.lineItems![0]?.specialDistrictAmount)
    },
    {
      label: 'County Tax Amount',
      value: asPrice(breakdown?.lineItems![0]?.countyAmount)
    },
    {
      label: 'Tax Collectable',
      value: asPrice(breakdown?.taxCollectable),
      line: true
    }
  ]

  return (
    <React.Fragment>
      <div className={styles.inputsfields}>
        <FieldSet title="General Information" fields={generalInformation} />
        <FieldSet
          gap
          title="Sales Tax Information"
          fields={salesTaxInformation}
        />
        <FieldSet gap title="Sales Tax" fields={salesTax} />
      </div>
      <div style={{marginTop: '1.5rem'}} className={styles.inputsfields}>
        <FieldSet fields={salesTaxSecondColumn} />
        <div style={{marginTop: '5rem'}} />
        <FieldSet fields={salesTaxSecondColumnSecondRow} />
        <div style={{marginTop: '4.5rem'}} />
        <FieldSet fields={salesTaxSecondColumnThirdRow} />
      </div>
    </React.Fragment>
  )
}

export default SalesTaxDetails
