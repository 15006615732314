import classNames from 'classnames'
import {Category} from 'common'
import ModalComponent from 'components/ModalComponent'
import {IFieldSetSingleField} from 'components/types'
import React from 'react'
import {FieldSet} from '../DetailFields'
import styles from '../DetailPage.module.scss'
import FileModal from '../ProductDetails/FileModal'
import SubCategoriesPage from './SubCategoriesPage'

const CategoryDetails = (data: Category) => {
  const [selectedFile, setSelectedFile] = React.useState('')
  const [showModal, setShowModal] = React.useState(false)

  const toggleShowModal = () => setShowModal(!showModal)

  const CategoryImage = () => (
    <div
      className={classNames(styles.sec_input, styles.normalAlignItems)}
      style={{justifyContent: 'center'}}
    >
      <div className={styles.multiDetailsBox}>
        <div className={styles.singleDetailBox}>
          <div className={styles.imageGridContainer}>
            <p className={styles.customParagraphTag}>Image</p>
            <div
              className={styles.imageContainer}
              onClick={() => {
                setSelectedFile(() => {
                  toggleShowModal()
                  return data?.imageUrl || ''
                })
              }}
            >
              <img src={data?.imageUrl} alt="category-img" />
            </div>
          </div>
        </div>
      </div>
    </div>
  )

  const fields: Array<IFieldSetSingleField> = [
    {
      label: 'Category Name',
      value: data?.name || '-'
    },
    {
      label: 'Category URL Slug',
      value: data?.slug || '-'
    },
    {
      label: 'Popular Category',
      isBoolean: true,
      value: data?.isPopularCategoryMap?.isPopularCategory || false
    }
  ]

  return (
    <React.Fragment>
      <div className={styles.category_detail_container}>
        <div className={styles.category_detail_inner_container}>
          <div className={styles.inputsfields}>
            <FieldSet title="Category Details" fields={fields} />
          </div>
          <div className={styles.inputsfields}>
            <CategoryImage />
          </div>
        </div>
        <SubCategoriesPage categoryId={data.nodeId} />
      </div>
      {showModal && (
        <ModalComponent show={showModal} handleClose={toggleShowModal}>
          <FileModal
            handleClose={toggleShowModal}
            selectedFile={selectedFile}
            isVideo={false}
          />
        </ModalComponent>
      )}
    </React.Fragment>
  )
}

export default CategoryDetails
