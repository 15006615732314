import ContentModal from 'components/ContentModal'
import ModalComponent from 'components/ModalComponent'
import {DetailType} from 'components/enums'
import {Formik, FormikProps} from 'formik'
import {get} from 'lodash'
import React, {useEffect, useRef, useState} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {toast} from 'react-toastify'
import {ArrowDown} from '../../assets/svgs'
import Button from '../Button'
import styles from '../CreateNewCategory/CreateNewCategory.module.scss'
import DetailPage from '../DetailPage'
import {useFirebase} from '../Firebase/hooks'
import InputField from '../InputField'
import Layout from '../Layout'
import {Loader} from '../Loader'
import NavBar from '../Navbar'
import withAuthorization from '../Session/withAuthorization'
import {generateRandomId} from '../utils'
import {CreateSectorSchema} from './schema'

const CreateNewSector = () => {
  const navigate = useNavigate()
  const {createOrEditSector, deleteSector} = useFirebase()
  const {state} = useLocation()
  const [show, setShow] = useState<boolean>(false)
  const isEdit = Boolean(state?.isEdit)
  const [disabled, setDisabled] = useState(isEdit ? true : false)
  const [loader, setLoader] = useState(isEdit ? true : false)
  const [sectorId, setSectorId] = useState('')
  const formRef: React.Ref<FormikProps<{name: string}>> = useRef(null)
  const previousPage = state?.currentPage
  const appState = {
    documentData: isEdit
      ? {
          ...state?.data
        }
      : {
          name: '',
          nodeId: ''
        }
  }

  const closeDeleteModal = () => setShow(false)

  useEffect(() => {
    setSectorId(state?.id || generateRandomId())
    isEdit &&
      setTimeout(() => {
        setLoader(false)
      }, 2000)
  }, [])

  useEffect(() => {
    const {documentData} = appState
    if (documentData && !disabled) {
      const stateObj = {
        name: state?.data?.name
      }
      formRef?.current?.setValues(stateObj)
    }
  }, [appState?.documentData?.nodeId, disabled])

  useEffect(() => {
    isEdit &&
      setTimeout(() => {
        setLoader(false)
      }, 2000)
  }, [])

  const isSectorDetails = isEdit && disabled

  const handleDeletion = async () => {
    setLoader(true)
    const {error} = await deleteSector(sectorId)
    if (error) {
      toast.error(`${error}`)
      setLoader(false)
      return
    }
    toast.success('Sector deleted successfully')
    setLoader(false)
    navigate('/sectors', {
      state: {page: previousPage}
    })
    return
  }

  return (
    <div>
      <div className={styles.withLayout}>
        <Layout />
        <div className={styles.container}>
          <NavBar title="Sectors" />
          {loader ? (
            <Loader />
          ) : (
            <React.Fragment>
              <div className={styles.buttons}>
                <span
                  onClick={() =>
                    navigate('/sectors', {
                      state: {page: previousPage}
                    })
                  }
                >
                  <ArrowDown />
                  Back
                </span>
                <div className={styles.twobtn}>
                  {disabled && (
                    <Button
                      text="Edit"
                      customClass={styles.changes_btn}
                      onClick={() => setDisabled(false)}
                    />
                  )}
                  {(!isEdit || !disabled) && (
                    <Button
                      text={'Save'}
                      customClass={styles.changes_btn}
                      onClick={() => formRef.current?.handleSubmit()}
                      disabled={disabled}
                    />
                  )}
                  {isEdit && (
                    <Button
                      text={'Delete'}
                      customClass={styles.changes_btn}
                      onClick={() => setShow(true)}
                    />
                  )}
                </div>
              </div>

              <div className={styles.edit_section}>
                {!isSectorDetails && (
                  <h1 className={styles.secondheading}>Sector Details</h1>
                )}
                {isSectorDetails ? (
                  <DetailPage
                    data={appState.documentData}
                    detailType={DetailType.Sector}
                  />
                ) : (
                  <Formik
                    innerRef={formRef}
                    initialValues={{
                      name: appState.documentData?.name
                    }}
                    validationSchema={CreateSectorSchema}
                    onSubmit={async (values: {name: string}) => {
                      const isUnchanged = state?.data?.name === values.name
                      if (isUnchanged) {
                        toast.warn('Please make some changes to update.')
                        return
                      }
                      setLoader(true)
                      const {error, message} = await createOrEditSector(
                        {
                          nodeId: sectorId,
                          name: `${values.name}`.trim()
                        },
                        true
                      )
                      setLoader(false)
                      if (error) {
                        toast.warning(`${error}`)
                        return
                      } else {
                        toast.success(message)
                        navigate('/sectors', {
                          state: {page: previousPage}
                        })
                      }
                      return
                    }}
                  >
                    {(props) => {
                      const {
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit
                      } = props
                      return (
                        <form
                          className={styles.from_submiting}
                          onSubmit={handleSubmit}
                        >
                          <div className={styles.formContainer}>
                            <div className={styles.inputs}>
                              <div className={styles.inputContainer}>
                                <div className={styles.inputField}>
                                  <InputField
                                    name="name"
                                    placeholder="Name"
                                    value={get(values, 'name')}
                                    onChange={handleChange}
                                    errors={errors}
                                    touched={touched}
                                    onBlur={handleBlur}
                                    type="text"
                                    disabled={disabled}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      )
                    }}
                  </Formik>
                )}
              </div>
            </React.Fragment>
          )}
          {show && (
            <ModalComponent handleClose={closeDeleteModal} show={show}>
              <ContentModal
                removeIcon
                buttonText="Confirm"
                buttonDisabled={loader}
                heading={`Delete Sector`}
                buttonOnClick={handleDeletion}
                handleClose={closeDeleteModal}
                content={
                  'You cannot undo these changes. Are you sure you want to delete this sector?'
                }
              />
            </ModalComponent>
          )}
        </div>
      </div>
    </div>
  )
}

export default withAuthorization(CreateNewSector)
