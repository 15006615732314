import ContentModal from 'components/ContentModal'
import {useFirebase} from 'components/Firebase/hooks'
import ModalComponent from 'components/ModalComponent'
import {useState} from 'react'
import {toast} from 'react-toastify'

const MarkAsReadModal = ({
  show,
  handleClose
}: {
  show: boolean
  handleClose: () => void
}) => {
  const [disbaled, setDisbaled] = useState(false)
  const {markAllNotiicationsAsRead} = useFirebase()
  const markAsRead = async () => {
    if (disbaled) return
    setDisbaled(true)
    const {error} = await markAllNotiicationsAsRead()
    if (error) {
      setDisbaled(false)
      toast.warn(
        'Something went wrong! Failed to mark all notifications as read. Please try again later.'
      )
      return
    }
    handleClose()
  }
  return (
    <ModalComponent handleClose={handleClose} show={show}>
      <ContentModal
        buttonText="Confirm"
        buttonDisabled={disbaled}
        handleClose={handleClose}
        heading="Mark all as read"
        buttonOnClick={markAsRead}
        content="Are you sure you want to mark all your notifications as read? This action cannot be undone."
      />
    </ModalComponent>
  )
}

export default MarkAsReadModal
