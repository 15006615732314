import {SearchIndex} from 'algoliasearch'
import {
  AccountDocument,
  AdminDocument,
  ContactDocument,
  DealDocument,
  FILE_OBJECT,
  ProductDocument
} from 'common'
import {
  MONTH_NAMES,
  OG_PREVIEW_HELPER_ENDPOINT,
  PREVEIW_LINK_HELPER_ENDPOINT
} from 'common/constants'
import {listingType} from 'common/enums'
import {City, Country, ICity, IState, State} from 'country-state-city'
import {
  ContentBlock,
  ContentState,
  EditorState,
  convertFromHTML
} from 'draft-js'
import {Timestamp} from 'firebase/firestore'
import {
  camelCase,
  isDate,
  isEqual,
  isNumber,
  isObject,
  isString,
  orderBy
} from 'lodash'
import moment from 'moment'
import {RefObject, useCallback, useEffect, useMemo, useState} from 'react'
import {API_HOST_URL} from './constants'
import {
  ChatsDocument,
  IFormattedAccount,
  IFormattedAdmin,
  IFormattedContact
} from './types'
import {
  ACH_ADMIN_PANEL_ALGOLIA_INDEX,
  LA_ADMIN_PANEL_ALGOLIA_INDEX,
  MSA_ADMIN_PANEL_ALGOLIA_INDEX
} from './AlgoliaSearch'

export const removeHtmlTags = (input: string): string => {
  const htmlTagRegex = /<[^>]*>/g
  const cleanedString = input.replace(htmlTagRegex, '')
  return cleanedString
}

export const removeEmojis = (inputString: string) => {
  const emojiRegex =
    /[\u{1F300}-\u{1F64F}\u{1F680}-\u{1F6FF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}\u{1F900}-\u{1F9FF}\u{1F1E0}-\u{1F1FF}\u{1FAD0}\u{1F6F8}\u{1F6CE}\u{1F6AA}-\u{1F6B0}\u{1F6C0}\u{1F6D0}\u{1F6E0}\u{1F6F0}\u{1F680}\u{1F681}\u{1F682}\u{1F683}\u{1F684}\u{1F685}\u{1F686}\u{1F687}\u{1F688}\u{1F689}\u{1F68A}-\u{1F68B}\u{1F68C}\u{1F68D}\u{1F68E}-\u{1F68F}\u{1F690}\u{1F691}-\u{1F6FF}\u{1F774}\u{1F775}\u{1F776}\u{1F777}\u{1F778}\u{1F779}\u{1F77A}\u{1F77B}\u{1F77C}\u{1F77D}\u{1F77E}\u{1F77F}\u{1F780}-\u{1F7FF}\u{1F80C}\u{1F80D}\u{1F80E}\u{1F80F}\u{1F848}-\u{1F84F}\u{1F85A}-\u{1F85F}\u{1F888}-\u{1F88F}\u{1F8AE}-\u{1F8FF}\u{1F90C}-\u{1F93A}\u{1F93C}-\u{1F945}\u{1F947}-\u{1F94C}\u{1F950}-\u{1F95E}\u{1F960}-\u{1F97A}\u{1F97C}-\u{1F9A2}\u{1F9A5}-\u{1F9AA}\u{1F9AE}-\u{1F9BA}\u{1F9BC}-\u{1F9CB}\u{1F9CD}-\u{1F9FF}\u{1FA70}-\u{1FA73}\u{1FA78}-\u{1FA7A}\u{1FA80}-\u{1FA82}\u{1FA90}-\u{1FA95}\u{200D}]/gu
  return inputString.replace(emojiRegex, '')
}

export const trimStringFields: any = (obj: any) => {
  if (typeof obj !== 'object' || obj === null) {
    return obj
  }
  if (Array.isArray(obj)) {
    return obj.map((item) => trimStringFields(item))
  }
  return Object.keys(obj).reduce((acc: any, key) => {
    const value = obj[key]
    if (typeof value === 'string') {
      acc[key] = value.trim()
    } else {
      acc[key] = trimStringFields(value)
    }
    return acc
  }, {})
}

export function bytesToSize(bytes: number = 0): string {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
  if (bytes === 0) return 'n/a'
  const i = Math.min(
    Math.floor(Math.log(bytes) / Math.log(1024)),
    sizes.length - 1
  )
  if (i === 0) return `${bytes} ${sizes[i]}`
  return `${(bytes / 1024 ** i).toFixed(2)} ${sizes[i]}`
}

export const withThousandSeperatorString = (number: string | number = '') => {
  const parsedValue = parseFloat(`${number}`.replace(/[^\d.]/g, '')) || 0
  return parsedValue.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  })
}

const isRefArray = (
  r: RefObject<any> | RefObject<any>[]
): r is RefObject<any>[] => {
  return 'length' in r
}

const isTarget = (ref: RefObject<any>, event: MouseEvent) => {
  return ref && ref.current && ref.current.contains(event.target)
}

const trueForAny = (array: any[], condition: (props: any) => boolean) => {
  return array.reduce((conditionAlreadyMet, value) => {
    return conditionAlreadyMet || condition(value)
  }, false)
}

export const removeThousandSeperatorString = (number: string) =>
  Number(number.replace(/[^\d.-]/g, ''))

export const withoutBuyersPremium = (
  totalWithBuyersPremium: string | number,
  premiumPercentage: number = 10
) => {
  const deduction =
    Number(removeThousandSeperatorString(`${totalWithBuyersPremium}`)) *
    (premiumPercentage / 100)
  const result =
    Number(removeThousandSeperatorString(`${totalWithBuyersPremium}`)) -
    deduction
  return result.toFixed(2)
}

export const useClickOutside = (
  ref: RefObject<any> | RefObject<any>[],
  onclick: () => void
) => {
  const handleClick = useCallback(
    (click: MouseEvent) => {
      if (isRefArray(ref)) {
        if (trueForAny(ref, (ref: RefObject<any>) => isTarget(ref, click))) {
          return
        }
      } else {
        if (isTarget(ref, click)) {
          return
        }
      }
      onclick()
    },
    [onclick, ref]
  )

  useEffect(() => {
    document.addEventListener('click', handleClick)

    return () => {
      document.removeEventListener('click', handleClick)
    }
  }, [handleClick])

  return ref
}

export const sortByName = (a: {name: string}, b: {name: string}) =>
  a?.name?.localeCompare(b?.name || '')

export const getFormattedAccount = (
  accountObj: AccountDocument
): IFormattedAccount => {
  const {
    accountInfo,
    accountName = '',
    accountType = '',
    sector = '',
    shippingAddress,
    billingAddress,
    descriptionInfo = '',
    parentAccountId = '',
    accountId = ''
  } = accountObj || {}
  const {email = '', phoneNumber = '', websiteURL = ''} = accountInfo || {}
  const {
    shippingCity = '',
    shippingCountry = '',
    shippingState = '',
    shippingStreetAddress = '',
    shippingZipCode = '',
    shippingStreetAddress2 = ''
  } = shippingAddress || {}
  const {
    billingStreetAddress = '',
    billingStreetAddress2 = '',
    billingCountry = '',
    billingCity = '',
    billingState = '',
    billingZipCode = ''
  } = billingAddress || {}

  const shipping = Object.values(shippingAddress || {}).sort()
  const billing = Object.values(billingAddress || {}).sort()
  const sameAddress = isEqual(shipping, billing)

  return {
    accountId,
    accountName,
    sector,
    accountType,
    parentAccountId,
    websiteURL,
    email,
    phoneNumber,
    descriptionInfo,
    shippingStreetAddress,
    shippingStreetAddress2,
    shippingCountry,
    shippingCity,
    shippingState,
    shippingZipCode,
    billingStreetAddress,
    billingStreetAddress2,
    billingCountry,
    billingCity,
    billingState,
    billingZipCode,
    sameAddress
  }
}

export const getDaysFromToday = (timestamp: Timestamp | Date) => {
  const today = moment()
  const todayAsNumber = today.valueOf()
  const dateObject = convertToDate(timestamp)
  const timestampAsNumber = dateObject.getTime()
  const timeDifference = Math.abs(timestampAsNumber - todayAsNumber)
  const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24))
  return daysDifference
}

export const convertToDate = (timestamp: Timestamp | number | Date): Date =>
  isString(timestamp)
    ? new Date(timestamp)
    : isDate(timestamp)
    ? timestamp
    : isNumber(timestamp)
    ? new Date(timestamp)
    : new Date(timestamp?.seconds * 1000 || (timestamp as any)?._seconds * 1000)

export const isValidUrl = (url: string) => {
  const regex =
    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/
  const validUrl = regex.test(url)
  return validUrl
}

export const getLinkPreviewData = async (url: string) => {
  if (!url.length) return

  const response = await fetch(
    `${API_HOST_URL}${PREVEIW_LINK_HELPER_ENDPOINT}`,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      method: 'POST',
      body: JSON.stringify({url})
    }
  )
  try {
    const res = await response.json()
    return res.data
  } catch (error) {
    return url
  }
}

export const getOGPreviewData = async (url: string) => {
  if (!url.length) return

  const response = await fetch(`${API_HOST_URL}${OG_PREVIEW_HELPER_ENDPOINT}`, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    method: 'POST',
    body: JSON.stringify({url})
  })
  try {
    const res = await response.json()
    return res.data
  } catch (error) {
    return url
  }
}

export const parseHtmlToPlainText = (htmlContent: string) => {
  const blocksFromHTML = convertFromHTML(htmlContent)
  const contentBlocks: ContentBlock[] = blocksFromHTML.contentBlocks
  const contentState = ContentState.createFromBlockArray(contentBlocks)
  const newEditorState = EditorState.createWithContent(contentState)
  return newEditorState
}

export const plainTextLinkToHyperlink = (str: string | undefined) => {
  if (!str) return
  const stringArr = str.split(' ')
  const linkIndex = stringArr.findIndex((str: string) => isValidUrl(str))
  stringArr[
    linkIndex
  ] = `<a href="${stringArr[linkIndex]}">${stringArr[linkIndex]}<a/>`
  return stringArr.join(' ')
}

export const getFormattedUser = (
  userObject: ContactDocument,
  isDetail?: boolean | undefined
): IFormattedContact => {
  const {
    userPrefs,
    accountName = '',
    accountId = '',
    firstName = '',
    isPrimaryContact = false,
    isSynced = false,
    isVerified = false,
    lastName = '',
    createdAt,
    lastUpdated,
    contactId,
    awaitFeedback = [],
    isAchAuthorize = false,
    termsAndConditions,
    jobId = ''
  } = userObject || {}
  const {
    emailAddress = '',
    photoURL = '',
    mobileNumber = '',
    title = ''
  } = userObject?.contactInfo || {}
  const {initialAccepted, lastAccepted} = termsAndConditions || {}
  const intialTermsAccepted = initialAccepted
    ? getFormattedDate(initialAccepted, true)
    : '-'
  const latestTermsAccepted = lastAccepted
    ? getFormattedDate(lastAccepted, true)
    : '-'
  return {
    firstName,
    lastName,
    accountId,
    accountName,
    title,
    mobileNumber,
    emailAddress,
    photoURL,
    isPrimaryContact,
    intialTermsAccepted,
    latestTermsAccepted,
    newsLetter: userPrefs?.newsLetter || false,
    textMessage: userPrefs?.textMessage || false,
    ...(isDetail && {
      isSynced,
      contactId,
      isVerified,
      isAchAuthorize,
      jobId,
      awaitFeedback,
      createdAt: getFormattedDate(createdAt),
      lastUpdated: getFormattedDate(lastUpdated)
    })
  }
}

export const getFormattedAdmin = (
  adminObject: AdminDocument
): IFormattedAdmin => {
  const {lastUpdated, createdAt, userId, contactInfo, ...rest} =
    adminObject || {}
  return {
    ...rest,
    ...contactInfo
  }
}

export const getFormattedDate = (date?: Date, withTime?: boolean) => {
  if (!date) return ''
  date = convertToDate(date)
  const day = date.getDate()
  const month = MONTH_NAMES[date.getMonth()]
  const yearOfDate = date.getFullYear()
  const formattedTime = date.toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: '2-digit',
    hour12: true
  })
  return withTime
    ? `${day} ${month} ${yearOfDate} ${formattedTime}`
    : `${day} ${month} ${yearOfDate}`
}

export const searchWithAlgolia = async <T>({
  accountList,
  searchIndex,
  searchQuery,
  setData,
  setLoaderState,
  setPageCount,
  numberOfHits,
  facetFilters,
  currentPage
}: {
  searchQuery: string
  searchIndex: SearchIndex
  setLoaderState: React.Dispatch<React.SetStateAction<boolean>>
  setData: React.Dispatch<React.SetStateAction<T[]>>
  setPageCount?: React.Dispatch<React.SetStateAction<number>>
  accountList?: Array<string>
  numberOfHits?: number
  currentPage?: number
  facetFilters?: Array<string>
}) => {
  setLoaderState(true)

  const agreementIndices = [
    LA_ADMIN_PANEL_ALGOLIA_INDEX,
    MSA_ADMIN_PANEL_ALGOLIA_INDEX,
    ACH_ADMIN_PANEL_ALGOLIA_INDEX
  ]

  const isAgreementIndex = agreementIndices.includes(searchIndex.indexName)

  if (accountList?.length) {
    let results: any = []
    for (let i = 0; i <= accountList.length; i++) {
      if (accountList[i]) {
        const currentAccountID = accountList[i]
        const filters = (facetFilters?.length && {
          facetFilters: [...facetFilters, `accountId:${currentAccountID}`]
        }) || {filters: `accountId:${currentAccountID}`}
        const searchResponse = await searchIndex.search(searchQuery || '*', {
          ...filters,
          hitsPerPage: numberOfHits || 50,
          page: currentPage ? currentPage - 1 : 0
        })
        results.push(searchResponse.hits)
        results = results.flat()
      }
    }
    const resultsArray =
      orderBy(
        results,
        isAgreementIndex ? 'lastUpdated' : 'createdAt',
        'desc'
      ) || []
    setData(resultsArray)
    setLoaderState(false)
    return
  }

  const searchResponse = await searchIndex.search(searchQuery || '*', {
    hitsPerPage: numberOfHits || 50,
    facetFilters,
    page: currentPage ? currentPage - 1 : 0
  })
  const searchHits = searchResponse?.hits || []
  const resultsArray =
    orderBy(
      searchHits,
      isAgreementIndex ? 'lastUpdated' : 'createdAt',
      'desc'
    ) || []
  setPageCount?.(searchResponse?.nbHits || 0)
  setData(resultsArray as Array<T>)
  setLoaderState(false)
  return
}

export const downloadPDFfromURL = async (url: string, name?: string) => {
  try {
    const data = await fetch(url)
    const blob = await data.blob()
    const fileName = name ?? new URL(url).pathname.split('/').pop()
    const link = URL.createObjectURL(blob)
    var a = document.createElement('a')
    a.setAttribute('download', fileName || '')
    a.setAttribute('href', link)
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  } catch (error: any) {
    console.error('Ërror retreiving file: ', error.name, error.message)
  }
}

export const getFormattedProduct = (data: ProductDocument) => {
  const {
    productDetails,
    doesThisRequireDismantling,
    lienInfo,
    isTheEquipmentAtADifferentAddress,
    auctionInfo,
    createdAt,
    lastUpdated,
    endedAt,
    contractInfo,
    listingType: productListingType
  } = data || {}
  const imagesArray = productDetails?.images || []
  const videosArray = productDetails?.videos || []
  const attachmentsArray = productDetails?.attachments || []
  const willingToHelpObject =
    doesThisRequireDismantling?.areYouWillingToHelpWithDismantling
  const requireDismantling = Boolean(doesThisRequireDismantling)
  const willingToHelp = isObject(willingToHelpObject)
  const toWhatExtent = isObject(willingToHelpObject)
  const toWhatExtentValue =
    willingToHelpObject?.toWhatExtentAreYouWillingToHelp || ''
  const isLien = isObject(lienInfo) || false
  const addressObj = isTheEquipmentAtADifferentAddress
  const isAuction = productListingType === listingType.Auction || false
  const createdAtDate = createdAt ? convertToDate(createdAt) : ''
  const endedAtDate = endedAt ? convertToDate(endedAt) : ''
  const lastUpdatedDate = lastUpdated ? convertToDate(lastUpdated) : ''

  const auctionScheduledTime = auctionInfo?.auctionScheduledTime
    ? convertToDate(auctionInfo?.auctionScheduledTime)
    : ''

  const contractDuration = contractInfo?.contractDuration
  const autoRenew = contractInfo?.autoRenew || false
  const requestedChanges = data?.requestedChanges || {}
  const renewalWindow = contractInfo?.renewalWindow || '-'

  return {
    imagesArray,
    videosArray,
    attachmentsArray,
    requireDismantling,
    willingToHelp,
    toWhatExtent,
    isLien,
    addressObj,
    isAuction,
    createdAtDate,
    lastUpdatedDate,
    auctionScheduledTime,
    lienObject: lienInfo,
    auctionObj: auctionInfo,
    endedAtDate,
    contractDuration,
    renewalWindow,
    autoRenew,
    requestedChanges,
    toWhatExtentValue,
    isAtADifferentAddress: Boolean(
      isTheEquipmentAtADifferentAddress?.isEquipmentAtADifferentAddress
    )
  }
}

interface configProps {
  key?: string
  direction?: string
}

interface useSortableDataReturnType {
  items: Array<any>
  sortBy: (key: string, dir?: 'ascending' | 'descending') => void
}

export const useSortableData = (
  items: Array<any>,
  config: configProps = {key: '', direction: ''}
): useSortableDataReturnType => {
  const [sortConfig, setSortConfig] = useState<configProps>(config)

  const sortedItems = useMemo(() => {
    if (!items?.length) return []
    const sortableItems = [...items]
    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        const aValue = a[sortConfig.key as keyof typeof sortConfig.key]
        const bValue = b[sortConfig.key as keyof typeof sortConfig.key]
        if (typeof aValue === 'string' && typeof bValue === 'string') {
          return sortConfig.direction === 'ascending'
            ? aValue.localeCompare(bValue)
            : bValue.localeCompare(aValue)
        } else {
          return sortConfig.direction === 'ascending'
            ? aValue < bValue
              ? -1
              : 1
            : bValue < aValue
            ? -1
            : 1
        }
      })
    }
    return sortableItems
  }, [items, sortConfig])

  const sortBy = (key: string) => {
    let direction = 'ascending'
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === 'ascending'
    ) {
      direction = 'descending'
    }
    setSortConfig({key, direction})
  }

  return {items: sortedItems, sortBy}
}

export const DOTS = '...'

const range = (start: number, end: number) => {
  const length = end - start + 1
  return Array.from({length}, (_, idx) => idx + start)
}

interface usePaginationProps {
  totalCount: number
  pageSize: number
  siblingCount: number
  currentPage: number
}

type usePaginationReturnType = Array<string | number> | undefined

export const usePagination = ({
  totalCount,
  pageSize,
  siblingCount = 1,
  currentPage
}: usePaginationProps): usePaginationReturnType => {
  const paginationRange = useMemo(() => {
    const totalPageCount = Math.ceil(totalCount / pageSize)
    const totalPageNumbers = siblingCount + 5
    if (totalPageNumbers >= totalPageCount) {
      return range(1, totalPageCount)
    }

    const leftSiblingIndex = Math.max(currentPage - siblingCount, 1)
    const rightSiblingIndex = Math.min(
      currentPage + siblingCount,
      totalPageCount
    )

    const shouldShowLeftDots = leftSiblingIndex > 2
    const shouldShowRightDots = rightSiblingIndex < totalPageCount - 2

    const firstPageIndex = 1
    const lastPageIndex = totalPageCount

    if (!shouldShowLeftDots && shouldShowRightDots) {
      const leftItemCount = 4 * siblingCount
      const leftRange = range(1, leftItemCount)

      return [...leftRange, DOTS, totalPageCount]
    }

    if (shouldShowLeftDots && !shouldShowRightDots) {
      const rightItemCount = 4 * siblingCount
      const rightRange = range(
        totalPageCount - rightItemCount + 1,
        totalPageCount
      )
      return [firstPageIndex, DOTS, ...rightRange]
    }

    if (shouldShowLeftDots && shouldShowRightDots) {
      const middleRange = range(leftSiblingIndex, rightSiblingIndex)
      return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex]
    }
  }, [totalCount, pageSize, siblingCount, currentPage])

  return paginationRange
}
interface windowSizeProps {
  width: number | undefined
  height: number | undefined
}

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState<windowSizeProps>({
    width: undefined,
    height: undefined
  })

  useEffect(() => {
    // only execute all the code below in client side
    if (typeof window !== 'undefined') {
      // Handler to call on window resize
      const handleResize = () => {
        // Set window width/height to state
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight
        })
      }

      // Add event listener
      window.addEventListener('resize', handleResize)

      // Call handler right away so state gets updated with initial window size
      handleResize()

      // Remove event listener on cleanup
      return () => window.removeEventListener('resize', handleResize)
    }
  }, []) // Empty array ensures that effect is only run on mount
  return windowSize
}

export const getRelativeTime = (d1: Date | number | any, d2 = Date.now()) => {
  const units: any = {
    year: 24 * 60 * 60 * 1000 * 365,
    month: (24 * 60 * 60 * 1000 * 365) / 12,
    day: 24 * 60 * 60 * 1000,
    hour: 60 * 60 * 1000,
    minute: 60 * 1000,
    second: 1000
  }
  const rtf = new Intl.RelativeTimeFormat('en', {numeric: 'auto'})
  const elapsed = d1 - d2
  let u: any
  for (u in units)
    if (Math.abs(elapsed) > units[u] || u === 'second')
      return rtf.format(Math.round(elapsed / units[u]), u)
}

export const getChatsList = (
  dealsArray: Array<DealDocument>
): Array<ChatsDocument> => {
  return dealsArray.map((deal) => {
    const {
      dealName,
      lastMessage: {lastMessageTimestamp, text, senderId}
    } = deal
    const lastMessageSender =
      (senderId === deal.sellerContactId && deal.sellerContactName) ||
      (senderId === deal.buyerContactId && deal.buyerContactName) ||
      ''
    return {
      ...deal,
      lastMessageSender,
      lastMessage: text,
      productName: dealName,
      lastMessageTimestamp
    }
  })
}

export const getTimeStampFromObject = (timestamp: Timestamp | number) =>
  isNumber(timestamp)
    ? Timestamp.fromMillis(timestamp)
    : new Timestamp(
        timestamp?.seconds || (timestamp as any)?._seconds || 0,
        timestamp?.nanoseconds || (timestamp as any)?._nanoseconds || 0
      )

export const withBuyersPremium = (
  number: string | number,
  premiumPrecentage?: number
) => {
  const appliedPremium = premiumPrecentage ?? 10
  const buyersPremium = (Number(number) / 100) * appliedPremium + Number(number)
  return buyersPremium.toFixed(2)
}

export const getUserFullName = (firstName?: string, lastName?: string) => {
  if (firstName && lastName) {
    return firstName + ' ' + lastName
  } else if (firstName && !lastName) {
    return firstName
  } else if (!firstName && lastName) {
    return lastName
  } else return ''
}

export function getTimezoneOffset(date: Date) {
  const timezoneOffsetMinutes = date.getTimezoneOffset()
  const timezoneOffsetHours = -timezoneOffsetMinutes / 60
  const sign = timezoneOffsetHours >= 0 ? '+' : '-'
  const offset = Math.abs(timezoneOffsetHours)
  const offsetString = `${sign}${offset}`
  return `GMT${offsetString}`
}

export const toMomentDateString = (date?: Date | Timestamp) => {
  const newDate = convertToDate(date || new Date())
  const timezoneOffset = getTimezoneOffset(newDate)
  const dateFormat = 'YYYY-MM-DD HH:mm:ss'
  const formattedDate = moment(newDate, dateFormat).format(dateFormat)
  const dateStringWithOffset = `${formattedDate} ${timezoneOffset}`
  return dateStringWithOffset
}

export const isCorrectURI = (inputString: string) => {
  const uriValidationRegex = /(?<!%)%(?![0-9]{2})/
  return !uriValidationRegex.test(inputString)
}

export const conditionalDecodeURI = (inputString: string): string => {
  if (/%[0-9A-Fa-f]{2}/.test(inputString)) {
    try {
      return decodeURI(inputString)
    } catch (error) {
      console.error('Error decoding URI:', error)
      return inputString
    }
  }
  return inputString
}

export const getParsedFileNameForUploadingFile = (fileName: string) => {
  const fileNameArr = conditionalDecodeURI(fileName).split('.')
  const fileExtension = fileNameArr.pop()
  const fileNameWithoutExtension = fileNameArr.join('.')
  const parsedFileName = `${camelCase(
    fileNameWithoutExtension
  )}-${Date.now()}.${fileExtension}`
  return parsedFileName
}

export const generateRandomId = () => {
  const characters = 'abcdefghijklmnopqrstuvwxyz0123456789'
  let id = ''
  for (let i = 0; i < 16; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length)
    id += characters[randomIndex]
  }
  return id
}

export const addDays = (date: Date | string, days: number): Date => {
  return moment(new Date(date)).add(days, 'days').toDate()
}

export const filteredCountries = Country.getAllCountries()
  .filter((country) => {
    const states = State.getStatesOfCountry(country.isoCode)
    if (!states.length) {
      return false
    }
    if (!states.some(({isoCode}) => isoCode.length === 2)) {
      return false
    }
    const cities = states.reduce((acc: Array<ICity>, state: IState) => {
      const cities = City.getCitiesOfState(country.isoCode, state.isoCode)
      return [...acc, ...cities]
    }, [])
    return Boolean(cities.length)
  })
  .sort((a, b) => {
    const topCountries = ['United States', 'Canada', 'Mexico']
    const indexA = topCountries.indexOf(a.name)
    const indexB = topCountries.indexOf(b.name)

    if (indexA !== -1 && indexB !== -1) {
      return indexA - indexB
    } else if (indexA !== -1) {
      return -1
    } else if (indexB !== -1) {
      return 1
    } else {
      return a.name.localeCompare(b.name)
    }
  })

export const getStatesWithCities = (countryCode: string) => {
  const states = State.getStatesOfCountry(countryCode)
  const statesWithCities = states.filter((state) => {
    const cities = City.getCitiesOfState(countryCode, state.isoCode)
    return cities.length > 0
  })
  return statesWithCities
}

export const getCitiesOfState = (country: string, state: string) =>
  Object.values(City.getCitiesOfState(country, state))

export const getStatesOfCountry = (country: string) =>
  Object.values(getStatesWithCities(country))

export const getStatesOfCountryWithTwoLetterIso = (country: string) =>
  Object.values(
    getStatesWithCities(country).filter((state) => state.isoCode.length === 2)
  )

export const getCountryNameByCode = (countryCode: string) =>
  Country.getCountryByCode(countryCode)?.name || ''

export const getStateNameByCode = (stateCode: string, countryCode: string) =>
  State.getStateByCodeAndCountry(stateCode, countryCode)?.name || ''

export const getCountryIsoCode = (countryName: string) => {
  if (countryName?.length < 4) return countryName
  const countryCode = filteredCountries.find(
    (country) => countryName === country.name
  )?.isoCode
  return countryCode || ''
}

export const getStateIsoCode = (countryName: string, stateName: string) => {
  if (stateName.length < 4) return stateName
  const countryCode =
    (countryName.length < 4 && countryName) || getCountryIsoCode(countryName)
  const stateCode = State.getStatesOfCountry(countryCode).find(
    (state) => state.name === stateName
  )?.isoCode
  return stateCode || ''
}

export const memoizeFunc = (cb: any) => {
  let cache: any = {}
  // Accepting rest parameter arguments as the several functions that we need memoized have varaible number of arguments.
  return (...str: [string]) => {
    // Creating a key using all the provided arguments
    let combindStr = str.join('-')
    if (cache[combindStr]) {
      return cache[combindStr]
    } else {
      const resp = cb(...str)
      cache[combindStr] = resp
      return resp
    }
  }
}

export const memoizedStatesOfCountry = memoizeFunc(getStatesOfCountry)

export const getEllipsisString = (text: string, count: number) => {
  return text?.length > count ? `${text.slice(0, count).trim()}...` : text
}

export const displayNotificationBanner = () => {
  if (typeof window !== 'undefined' && typeof Notification !== 'undefined') {
    const showNotificationBanner =
      localStorage.getItem('hideNotificationBanner') === 'true'
    if (!showNotificationBanner) {
      const status = Notification.permission
      if (status !== 'granted') {
        return true
      }
      return false
    }
    return false
  }
}

export const flattenObject = (object: any): any => {
  let result = {}
  const recursiveFunction = (obj: any) => {
    for (const key in obj) {
      if (typeof obj[key] === 'object' && Array.isArray(obj[key]) === false) {
        recursiveFunction(obj[key])
      } else {
        result = {...result, [key]: obj[key]}
      }
    }
  }
  recursiveFunction(object)
  return result
}

export const extractPathFromUrl = (url: string): string => {
  const urlObject = new URL(url)
  return urlObject.pathname + urlObject.search
}

export const sameHostURL = (url1: string, hostName: string): boolean => {
  if (!isValidUrl(url1 || '')) return false
  const url1HostName = new URL(url1).hostname
  return url1HostName === hostName
}

export function addQueryParam({
  url,
  param,
  value
}: {
  url: string
  param: string
  value: string
}) {
  const urlObject = new URL(url)
  urlObject.searchParams.set(param, value)
  return urlObject.toString()
}

export function getUniqueObjects<T>(arr: T[], keyFn: (item: T) => string): T[] {
  const uniqueKeys = new Set<string>()
  const uniqueArr: T[] = []

  arr.forEach((item) => {
    const key = keyFn(item)
    if (!uniqueKeys.has(key)) {
      uniqueKeys.add(key)
      uniqueArr.push(item)
    }
  })

  return uniqueArr
}

const getFilesBasedOnType = (fileArray: Array<FILE_OBJECT>, fileType: string) =>
  fileArray?.filter((file: FILE_OBJECT) => file.type === fileType)

export const getFileInformation = (fileArr: Array<FILE_OBJECT>, file: File) => {
  const {type, size, name} = file
  const isPDF = type === 'application/pdf'
  const isImage = type.includes('image')
  const sizeInMB = size / 1024 ** 2
  const fileArray = [...fileArr]
  const countOfImages = getFilesBasedOnType(fileArray, 'image')?.length

  return {
    name,
    type,
    sizeInMB,
    countOfImages,
    isPDF,
    isImage
  }
}
