import {
  BillingAddress,
  FILE_OBJECT,
  IProductFormState,
  ProductDocument
} from 'common'
import {
  equipmentOptions,
  listingType,
  productStatus,
  publishAuction
} from 'common/enums'
import {
  addDays,
  getCountryNameByCode,
  getStateNameByCode,
  getTimeStampFromObject,
  toMomentDateString
} from 'components/utils'
import {Timestamp} from 'firebase/firestore'
import _, {isEmpty, isString, trim} from 'lodash'
import moment from 'moment'

export const getVideoURLs = (files: Array<FILE_OBJECT>) => {
  const images = files
    ?.filter((file) => file.type.includes('image'))
    .map((file) => file?.downloadURL)
    .filter(isString)

  const videoFiles = files.filter((file) => file.type.includes('video'))

  const driveUrls = videoFiles
    .filter((file) => file.uploadType === 'google-drive')
    .map((file) => file.URL)

  const videos = videoFiles
    .filter(
      (file) =>
        file.uploadType !== 'google-drive' && file.uploadType !== 'embed'
    )
    .map((file) => file.downloadURL)
    .filter(isString)

  const embedUrls = files
    .filter((file) => file.uploadType === 'embed')
    .map((file) => file.URL)

  return {
    images,
    driveUrls,
    videos,
    embedUrls
  }
}

export const getModifiedEqipmentFormFields = ({
  isAuction,
  productDocument,
  companyAddress,
  values
}: {
  values: IProductFormState
  productDocument: ProductDocument
  companyAddress?: BillingAddress
  isAuction: boolean
}) => {
  const isApprovedBuyNowListing =
    productDocument.listingType === listingType.BuyNow &&
    productDocument.status === productStatus.Approved
  const isCanceled = productDocument?.status === productStatus.Canceled || false
  const isInProgress =
    productDocument?.status === productStatus.inProgress || false

  const {
    productDetails,
    equipmentHours,
    equipmentMileage,
    createdAt,
    equipmentVinNumber,
    serialNumber,
    status,
    auctionInfo,
    lienInfo,
    isThisATitledPieceOfEquipment,
    listingType: equipmentListingType,
    contractInfo,
    commissionPercentage,
    buyersPremium
  } = values || {}

  const {
    files = [],
    firstChoice,
    secondChoice,
    pdfAttachments: attachments = []
  } = productDetails || {}

  const {
    auctionDuration: duration,
    auctionScheduledTime: scheduledTime,
    auctionDurationValue,
    publishAuction: publishAuctionOption
  } = auctionInfo || {}

  const {openingPrice: formOpeningPrice = 0} = values.openingPriceMap || {}

  const {finalSalePrice: formFinalSalePrice = 0} =
    values.finalSalePriceMap || {}

  const {
    reservePrice: formReservePrice = 0,
    reservePriceTimestamp: reservePriceTime
  } = values?.initialReservePriceMap || {}

  const {
    buyNowPrice: formBuyNowPrice = 0,
    buyNowPriceTimestamp: buyNowPriceTime
  } = values?.buyNowPriceMap || {}

  const {
    openingPrice: currentOpeningPrice = 0,
    openingPriceTimestamp: currentOpeningPriceTime
  } = productDocument?.openingPriceMap || {}

  const currentOpeningPriceTimestamp = isApprovedBuyNowListing
    ? toMomentDateString(currentOpeningPriceTime)
    : toMomentDateString(Timestamp.now())

  const {finalSalePriceTimestamp: currentFinalSalePriceTimestamp} =
    productDocument?.finalSalePriceMap || {}

  const {reservePrice: currentReservePrice} =
    productDocument?.initialReservePriceMap || {}

  const {buyNowPrice: currentBuyNowPrice} =
    productDocument?.buyNowPriceMap || {}

  const {driveUrls, embedUrls, images, videos} = getVideoURLs(files)

  const auctionDuration = Number(duration)
    ? Number(duration)
    : Number(auctionDurationValue)

  const currentDateString = toMomentDateString(Timestamp.now())
  const auctionDateUponApproval =
    productDocument.status !== productStatus.Approved &&
    values.status === productStatus.Approved
      ? currentDateString
      : toMomentDateString(productDocument.auctionInfo?.auctionScheduledTime)

  const auctionScheduledTime =
    publishAuctionOption === publishAuction.NOW
      ? auctionDateUponApproval
      : toMomentDateString(scheduledTime)

  const scheduledTimePlusDuration = toMomentDateString(
    addDays(new Date(auctionScheduledTime), auctionDuration)
  )
  const defaultBuyNowDuration = new Date(
    new Date().setDate(new Date().getDate() + 180)
  )

  const defaultAuctionDuration = new Date(
    new Date().setDate(
      new Date(auctionScheduledTime).getDate() + Number(auctionDuration)
    )
  )

  const contractDurationToDate = moment()
    .add(contractInfo?.contractDuration, 'days')
    .toDate()

  const contractDuration =
    equipmentListingType === listingType.Auction
      ? toMomentDateString(contractDurationToDate || defaultAuctionDuration)
      : toMomentDateString(contractDurationToDate || defaultBuyNowDuration)

  const finalSalePriceTimestamp = currentFinalSalePriceTimestamp
    ? toMomentDateString(currentFinalSalePriceTimestamp)
    : currentDateString

  const reservePriceTimestamp =
    formReservePrice === currentReservePrice
      ? toMomentDateString(reservePriceTime)
      : currentDateString

  const buyNowPriceTimestamp =
    formBuyNowPrice === currentBuyNowPrice
      ? toMomentDateString(buyNowPriceTime)
      : currentDateString

  const equipmentTitle =
    isThisATitledPieceOfEquipment?.equipmentTitle || values?.title || ''

  const {
    lienHolderEmail,
    lienHolderName,
    lienHolderPayoffAmount,
    lienHolderPhone
  } = lienInfo || {
    lienHolderEmail: '',
    lienHolderName: '',
    lienHolderPayoffAmount: 0,
    lienHolderPhone: ''
  }

  const lienInfoMap = isEmpty(lienInfo)
    ? undefined
    : {
        lienInfo: {
          lienHolderEmail,
          lienHolderName,
          lienHolderPayoffAmount,
          lienHolderPhone: `+${trim(lienHolderPhone, '+')}`
        }
      }

  const {
    equipmentCountry = '',
    equipmentState = '',
    equipmentCity = '',
    equipmentStreet = '',
    equipmentZip = '',
    isEquipmentAtADifferentAddress
  } = values.isTheEquipmentAtADifferentAddress || {}

  const {
    billingCity,
    billingCountry,
    billingState,
    billingStreetAddress,
    billingZipCode
  } = companyAddress || {
    billingCity: '',
    billingCountry: '',
    billingState: '',
    billingStreetAddress: '',
    billingZipCode: ''
  }

  const sameAddress = {
    equipmentCountry: billingCountry,
    equipmentState: billingState,
    equipmentCity: billingCity,
    equipmentZip: billingZipCode,
    equipmentStreet: billingStreetAddress,
    isEquipmentAtADifferentAddress: false
  }

  const differentEquipmentState =
    equipmentState.length > 3
      ? equipmentState
      : equipmentState && equipmentCountry
      ? getStateNameByCode(equipmentState, equipmentCountry)
      : equipmentState

  const differentEquipmentCountry =
    equipmentCountry.length > 3
      ? equipmentCountry
      : equipmentCountry
      ? getCountryNameByCode(equipmentCountry)
      : equipmentCountry

  const differentAddress = {
    equipmentStreet,
    equipmentCity,
    equipmentZip,
    equipmentState: differentEquipmentState,
    equipmentCountry: differentEquipmentCountry,
    isEquipmentAtADifferentAddress: true
  }

  const equipmentAddress = isEquipmentAtADifferentAddress
    ? differentAddress
    : sameAddress

  const equipmentStatus =
    isCanceled || isInProgress ? productStatus.Pending : status

  const listingAgreementSigned = productDocument.listingAgreementSigned || false
  const subcategoryId =
    values.subcategoryId === 'None' ? '' : values.subcategoryId

  interface IProductFormStateExtended extends IProductFormState {
    yearUnknown: boolean
  }

  const {yearUnknown, ...formValues} = values as IProductFormStateExtended

  const modifiedBuyNowEqipmentValues: ProductDocument = {
    ...formValues,
    subcategoryId,
    listingAgreementSigned,
    buyersPremium: _.round(buyersPremium || 10, 2),
    commissionPercentage: _.round(commissionPercentage || 10, 2),
    status: equipmentStatus,
    year: yearUnknown ? null : formValues.year,
    websiteLink: `/equipment-detail/${productDocument.productId}`,
    isTheEquipmentAtADifferentAddress: equipmentAddress,
    contractInfo: {
      contractDuration,
      autoRenew: contractInfo.autoRenew || false,
      durationInDays: contractInfo?.contractDuration,
      renewalWindow: contractInfo?.renewalWindow
    },
    createdAt: getTimeStampFromObject(createdAt),
    productDetails: {
      images,
      videos,
      driveUrls,
      embedUrls,
      attachments,
      firstChoice,
      secondChoice
    },
    openingPriceMap: {
      openingPrice: isApprovedBuyNowListing
        ? currentOpeningPrice
        : formBuyNowPrice,
      openingPriceTimestamp: currentOpeningPriceTimestamp
    },
    expectedRevenue: formBuyNowPrice,
    finalSalePriceMap: {
      finalSalePrice: formBuyNowPrice,
      finalSalePriceTimestamp: buyNowPriceTimestamp
    },
    buyNowPriceMap: {
      buyNowPrice: formBuyNowPrice,
      buyNowPriceTimestamp: buyNowPriceTimestamp
    },
    ...(firstChoice === equipmentOptions.EquipmentHours
      ? {
          equipmentHours: Number(equipmentHours),
          equipmentMileage: null
        }
      : firstChoice === equipmentOptions.EquipmentMileage
      ? {
          equipmentMileage: Number(equipmentMileage),
          equipmentHours: null
        }
      : firstChoice === equipmentOptions.Unknown
      ? {
          equipmentMileage: null,
          equipmentHours: null
        }
      : {}),
    ...(secondChoice === equipmentOptions.SerialNumber
      ? {
          serialNumber: serialNumber,
          equipmentVinNumber: null
        }
      : secondChoice === equipmentOptions.EquipmentVINNumber
      ? {
          equipmentVinNumber: equipmentVinNumber,
          serialNumber: null
        }
      : secondChoice === equipmentOptions.Unknown
      ? {
          equipmentVinNumber: null,
          serialNumber: null
        }
      : {}),
    ...lienInfoMap,
    ...(equipmentTitle
      ? {
          isThisATitledPieceOfEquipment: {
            equipmentTitle
          }
        }
      : {})
  }

  if (isAuction) {
    const finalSalePrice =
      productDocument.finalSalePriceMap?.finalSalePrice ||
      formOpeningPrice ||
      formFinalSalePrice
    const modifiedAuctionEqipmentValues: ProductDocument = {
      ...modifiedBuyNowEqipmentValues,
      endedAt: scheduledTimePlusDuration,
      auctionInfo: {
        auctionDuration: auctionDuration.toString(),
        auctionScheduledTime: auctionScheduledTime,
        publishAuction: publishAuctionOption!
      },
      initialReservePriceMap: {
        reservePrice: formReservePrice,
        reservePriceTimestamp: reservePriceTimestamp
      },
      openingPriceMap: {
        openingPrice: formOpeningPrice,
        openingPriceTimestamp: currentOpeningPriceTimestamp
      },
      finalSalePriceMap: {
        finalSalePrice,
        finalSalePriceTimestamp: finalSalePriceTimestamp
      },
      expectedRevenue: formOpeningPrice
    }

    return modifiedAuctionEqipmentValues
  }

  return modifiedBuyNowEqipmentValues
}
