import {fetcher, methods} from 'common/apis'
import {
  INVOICE_ADMIN_BUYER_WIRE_ADD_ENDPOINT,
  INVOICE_GENERATION_ENDPOINT,
  INVOICE_MARK_CLOSED_ENDPOINT,
  INVOICE_SELLER_WIRE_ADD_ENDPOINT,
  INVOICE_RE_OPEN_ENDPOINT
} from 'common/constants'
import {API_HOST_URL} from 'components/constants'

const GENERATE_INVOICE = `${API_HOST_URL}${INVOICE_GENERATION_ENDPOINT}`
const ADD_BUYER_WIRE = `${API_HOST_URL}${INVOICE_ADMIN_BUYER_WIRE_ADD_ENDPOINT}`
const ADD_SELLER_WIRE = `${API_HOST_URL}${INVOICE_SELLER_WIRE_ADD_ENDPOINT}`
const MARK_AS_CLOSED = `${API_HOST_URL}${INVOICE_MARK_CLOSED_ENDPOINT}`
const RE_OPEN_INVOICE = `${API_HOST_URL}${INVOICE_RE_OPEN_ENDPOINT}`

export const generateInvoice = async ({dealId}: {dealId: string}) => {
  const {data, error} = await fetcher(
    `${GENERATE_INVOICE}/${dealId}`,
    methods.POST
  )
  return {data, error}
}

export const addBuyerWire = async ({
  invoiceId,
  buyerWireAttachment,
  buyerWireDescription,
  buyerWireAmountPaid
}: {
  invoiceId: string
  buyerWireAttachment: string
  buyerWireDescription: string
  buyerWireAmountPaid: number
}) => {
  const {data, error} = await fetcher(
    `${ADD_BUYER_WIRE}/${invoiceId}`,
    methods.POST,
    {
      buyerWireAttachment: buyerWireAttachment,
      buyerWireDescription: buyerWireDescription,
      buyerWireAmountPaid: buyerWireAmountPaid,
      markedAsRecieved: true,
      isCredit: true
    }
  )
  return {data, error}
}

export const addSellerWire = async ({
  invoiceId,
  sellerWireAttachment,
  sellerWireDescription,
  sellerWireAmountPaid
}: {
  invoiceId: string
  sellerWireAttachment: string
  sellerWireDescription: string
  sellerWireAmountPaid: number
}) => {
  const {data, error} = await fetcher(
    `${ADD_SELLER_WIRE}/${invoiceId}`,
    methods.POST,
    {
      sellerWireAttachment: sellerWireAttachment,
      sellerWireDescription: sellerWireDescription,
      sellerWireAmountPaid: sellerWireAmountPaid,
      isCredit: true
    }
  )
  return {data, error}
}

export const markInvoiceAsClosed = async ({invoiceId}: {invoiceId: string}) => {
  const {data, error} = await fetcher(
    `${MARK_AS_CLOSED}/${invoiceId}`,
    methods.POST
  )
  return {data, error}
}

export const reOpenInvoice = async ({invoiceId}: {invoiceId: string}) => {
  const {data, error} = await fetcher(
    `${RE_OPEN_INVOICE}/${invoiceId}`,
    methods.POST
  )
  return {data, error}
}
