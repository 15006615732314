import Layout from '../Layout'
import NavBar from '../Navbar'
import withAuthorization from '../Session/withAuthorization'
import styles from './Dashboard.module.scss'

const Dashboard = () => {
  return (
    <div className={styles.withLayout}>
      <Layout />
      <div className={styles.container}>
        <NavBar title="Home" />
      </div>
    </div>
  )
}

export default withAuthorization(Dashboard)
