import {Triangle} from 'assets/svgs'
import styles from './InboxOfferReceiver.module.scss'
import {Offers, ProductDocument} from 'common'
import {PurchaseMethod, offerStatus} from 'common/enums'
import UserProfilePicture from 'components/UserProfileImage'
import {ChatsDocument} from 'components/types'
import {getFormattedDate, withThousandSeperatorString} from 'components/utils'

interface offerWithIndex extends Offers {
  offerIndex: number
}

const InboxOfferReceiver = ({
  currentProduct,
  buyerContactId,
  productImage,
  offer,
  data
}: {
  currentProduct?: ProductDocument
  buyerContactId: string
  offer: offerWithIndex
  productImage: string
  data: ChatsDocument
}) => {
  const offerDate = getFormattedDate(offer.date)
  const offers = data.offers
  const previousOfferAmount = offers![offer.offerIndex - 1]?.offerPrice
  const isCounteroffer =
    offer.offerStatus === offerStatus.COUNTER_PENDING ||
    offer.offerStatus === offerStatus.COUNTER_EDITIED_BUYER ||
    offer.offerStatus === offerStatus.COUNTER_EDITIED_SELLER ||
    offer.offerStatus === offerStatus.COUNTER_REJECTED ||
    offer.offerStatus === offerStatus.COUNTER_ACCEPTED

  const offerAccepted = offers.some(
    (offer) => offer.offerStatus === offerStatus.ACCEPTED
  )

  const purchasedOnBuyNow =
    currentProduct?.purchaseMethod === PurchaseMethod.BuyNow && offerAccepted

  const purchasedOnBid =
    currentProduct?.purchaseMethod === PurchaseMethod.Bid && offerAccepted

  const purchaseType = purchasedOnBid
    ? 'Bid Amount'
    : purchasedOnBuyNow
    ? 'Sold For'
    : isCounteroffer
    ? 'Counter-offer Amount'
    : 'Offer Amount'

  const renderOptions = () => {
    switch (offer?.offerStatus) {
      case offerStatus.EXPIRED:
        return (
          <div className={styles['option-selected']}>
            <span>
              Offer expired, submit a bidder deposit to make an offer again
            </span>
          </div>
        )
      case offerStatus.ACCEPTED:
        return (
          <div className={styles['option-selected']}>
            <span>Offer Accepted</span>
          </div>
        )
      case offerStatus.REJECTED:
        return (
          <div className={styles['option-selected']}>
            <span>Offer Rejected</span>
          </div>
        )
      case offerStatus.EDITIED:
        return (
          <div className={styles['option-selected']}>
            <span>Offer edited by buyer</span>
          </div>
        )
      case offerStatus.COUNTERED:
        return (
          <div className={styles['option-selected']}>
            <span>Offer countered by seller</span>
          </div>
        )
      case offerStatus.COUNTER_WITHDRAWN:
        return (
          <div className={styles['option-selected']}>
            <span>Counter-offer withdrawn by seller</span>
          </div>
        )
      case offerStatus.COUNTER_EDITIED_SELLER:
        return (
          <div className={styles['option-selected']}>
            <span>Counter-offer edited by seller</span>
          </div>
        )
      case offerStatus.COUNTER_EDITIED_BUYER:
        return (
          <div className={styles['option-selected']}>
            <span>Counter-offer edited by buyer</span>
          </div>
        )
      case offerStatus.WITHDRAWN:
        return (
          <div className={styles['option-selected']}>
            <span>Offer withdrawn by buyer</span>
          </div>
        )
      case offerStatus.COUNTER_ACCEPTED:
        return (
          <div className={styles['option-selected']}>
            <span>Counter-offer accepted by buyer</span>
          </div>
        )
      case offerStatus.COUNTER_REJECTED:
        return (
          <div className={styles['option-selected']}>
            <span>Counter-offer rejected by buyer</span>
          </div>
        )
      case offerStatus.COUNTER_PENDING:
        return (
          <div className={styles['option-selected']}>
            <span>Counter-offer made by seller</span>
          </div>
        )
      case offerStatus.PENDING:
        return (
          <div className={styles['option-selected']}>
            <span>Offer made by buyer</span>
          </div>
        )
      default:
        return null
    }
  }
  return (
    <div className={styles.container}>
      <div className={styles.user_img}>
        <UserProfilePicture alt="img-user-offer" userId={buyerContactId} />
      </div>
      <div className={styles.content}>
        <div className={styles.img_container}>
          <img alt="img-product-offer" src={productImage} />
          <div className={styles.info_headings}>
            <p>{offer.productTitle}</p>
            <h3>
              Date: <b> {offerDate}</b>
            </h3>
            <div className={styles.flex_container}>
              <h3>{purchaseType}:</h3>
              {isCounteroffer ? (
                <div className={styles['counter-offer-container']}>
                  <h1 className={styles['strike-thorugh']}>
                    {withThousandSeperatorString(`${previousOfferAmount}`)}
                  </h1>
                  <h1 className={styles['counter-amount']}>
                    {withThousandSeperatorString(`${offer.offerPrice}`)}
                  </h1>
                </div>
              ) : (
                <h1>{withThousandSeperatorString(`${offer.offerPrice}`)}</h1>
              )}
            </div>
          </div>
        </div>
        <h5>Message:</h5>
        <p>{offer?.message} </p>
        {renderOptions()}
        <Triangle className={styles.msg_icon} />
      </div>
    </div>
  )
}

export default InboxOfferReceiver
