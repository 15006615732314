import classNames from 'classnames'
import React from 'react'
import styles from './WebsiteBanner.module.scss'
import {Cancel} from 'assets/svgs'

interface WebsiteBannerProps {
  bannerButtonClick?: () => void
  bannerButtonDisabled?: boolean
  bannerButtonText?: string
  bannerText?: string
  showCancelButton?: boolean
  cancelButtonClick?: () => void
  isNotificationBanner?: boolean
}

const WebsiteBanner: React.FC<WebsiteBannerProps> = ({
  bannerButtonClick,
  bannerButtonDisabled,
  bannerButtonText,
  bannerText,
  showCancelButton,
  cancelButtonClick,
  isNotificationBanner
}) => {
  return (
    <div
      className={classNames(
        styles.full_layout,
        isNotificationBanner && styles.notification_layout
      )}
    >
      <p>{bannerText}</p>
      <div className={styles.buttonsContainer}>
        <React.Fragment>
          {bannerButtonText && (
            <button disabled={bannerButtonDisabled} onClick={bannerButtonClick}>
              {bannerButtonText}
            </button>
          )}
        </React.Fragment>
        {showCancelButton && (
          <div
            onClick={cancelButtonClick}
            className={styles.cancelBtnContainer}
          >
            <Cancel />
          </div>
        )}
      </div>
    </div>
  )
}

export default WebsiteBanner
