import Button from 'components/Button'
import ModalComponent from 'components/ModalComponent'
import ViewPDFModal from 'components/ViewPDFModal'
import React, {useState} from 'react'
import styles from '../DetailPage.module.scss'

const ListItem = ({title, url}: {title: string; url: string}) => {
  const [showPDFModal, setShowPDFModal] = useState<boolean>(false)
  const toggleViewPDFModal = () => setShowPDFModal(!showPDFModal)

  return (
    <React.Fragment>
      <div className={styles.reportField}>
        <div className={styles.title}>{title}</div>
        <Button
          onClick={toggleViewPDFModal}
          customBtnClass="btn-outline"
          customClass={styles.button}
          text="Open"
        />
      </div>
      {showPDFModal && (
        <ModalComponent show={showPDFModal} handleClose={toggleViewPDFModal}>
          <ViewPDFModal
            url={`${url}?time=${Date.now()}`}
            handleClose={toggleViewPDFModal}
            fileName={title}
          />
        </ModalComponent>
      )}
    </React.Fragment>
  )
}

export default ListItem
