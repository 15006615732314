import {useClickOutside} from 'components/utils'
import {useCallback, useRef, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import {
  ArrowDown,
  ChangePasswordIcon,
  Logout,
  NotificationIcon,
  RedDots
} from 'assets/svgs'
import {useAppContext, useSession} from '../Firebase/hooks'
import styles from './Navbar.module.scss'
import NotificationsDropMenu from './NotificationsDropMenu'

const Navbar = ({title}: {title: string}) => {
  const {removeFcmAndSignOut, appState} = useAppContext()
  const notificationMenuWindowRef = useRef<HTMLDivElement>(null)
  const [showProfileMenu, setShowProfileMenu] = useState(false)
  const [showNotificationMenu, setShowNotificationMenu] = useState(false)
  const profileMenuWindowRef = useRef<HTMLDivElement>(null)
  const navigate = useNavigate()
  const {user} = useSession()
  const displayImage = user?.photoURL

  const {notifications, adminDetails} = appState || {}
  const {notificationTimestamps} = adminDetails || {}
  const {lastViewed} = notificationTimestamps || {}
  const latestNotificationTime = notifications?.[0]?.createdAt?.valueOf() || 0
  const hasUnreadNotifications = lastViewed
    ? latestNotificationTime > lastViewed?.valueOf()
    : Boolean(notifications?.length)

  const dismissProfileMenuWindow = useCallback(() => {
    setShowProfileMenu(false)
  }, [setShowProfileMenu])
  const dismissNotificationMenuWindow = useCallback(() => {
    setShowNotificationMenu(false)
  }, [setShowNotificationMenu])
  useClickOutside([notificationMenuWindowRef], dismissNotificationMenuWindow)
  useClickOutside([profileMenuWindowRef], dismissProfileMenuWindow)

  return (
    <div className={styles.pages}>
      <div className={styles.top_side}>
        <h1 className={styles.heading}>{title}</h1>
        <div className={styles.profile_side}>
          <div
            ref={notificationMenuWindowRef}
            className={styles.notification_btn}
          >
            <div
              onClick={() => setShowNotificationMenu(!showNotificationMenu)}
              className={styles.noti_icon}
            >
              {hasUnreadNotifications && (
                <RedDots className={styles.unread_notification_icon} />
              )}
              <NotificationIcon />
            </div>
            {showNotificationMenu && <NotificationsDropMenu />}
          </div>
          <div ref={profileMenuWindowRef} className={styles.profile_drop}>
            <div
              className={styles.proifle}
              onClick={() => setShowProfileMenu(!showProfileMenu)}
            >
              {displayImage && <img src={displayImage} alt="profile-img" />}
              <ArrowDown />
            </div>
            {showProfileMenu && (
              <div className={styles.menu_profile}>
                <button onClick={() => removeFcmAndSignOut()}>
                  <Logout /> Sign out
                </button>
                <button onClick={() => navigate(`/change-password`)}>
                  <ChangePasswordIcon style={{marginLeft: '-2px'}} /> Update
                  Password
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Navbar
