import React from 'react'
import styles from './MessageInformation.module.scss'
import {PRODUCT_STATUS_FIELDS} from 'common/constants'
import {HelpOrange} from 'assets/svgs'
import {FormikErrors, FormikTouched} from 'formik'
import {flattenObject} from 'components/utils'

interface MessageProps {
  errors: FormikErrors<any>
  touched: FormikTouched<any>
}

const getKey = (key: string) => {
  switch (key) {
    case 'files':
      return 'Images/Videos'
    default:
      return PRODUCT_STATUS_FIELDS?.[key] || key
  }
}

const MessageInformation = ({errors, touched}: MessageProps) => {
  const checkErrors = ({errors, touched}: MessageProps): boolean => {
    const hasErrors = Object.keys(errors).some((key) => touched[key])
    return hasErrors
  }
  const hasFormErrors = checkErrors({errors, touched})
  if (!hasFormErrors) return null
  const flattenErrors = flattenObject(errors)
  return (
    <>
      <div className={styles.container}>
        <p className={styles.orange}>
          <HelpOrange />
          <b className={styles.marginTop}>Need more information</b>
        </p>
        <p className={styles.orange}>
          <b>Validation Issues:</b>
        </p>
        <ul>
          {Object.entries(flattenErrors).map(([key, value]) => (
            <p className={styles.orange} key={key}>
              {getKey(key)}: {`${value}`}
            </p>
          ))}
        </ul>
      </div>
    </>
  )
}

export default MessageInformation
