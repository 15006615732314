import styles from './MessageSection.module.scss'
import {
  LongArrowGreenRight,
  Archived,
  LargeSearch,
  ChevronLeft
} from '../../assets/svgs'
import {useEffect, useState} from 'react'
import cn from 'classnames'
import InboxCard from './InboxCard'
import {getChatsList, getRelativeTime, useWindowSize} from '../utils'
import {useAppContext, useFirebase, useSession} from '../Firebase/hooks'
import Chat from './Chat'
import {useLocation, useNavigate} from 'react-router-dom'
import {toast} from 'react-toastify'
import NavBar from 'components/Navbar'
import Layout from 'components/Layout'
import withAuthorization from 'components/Session/withAuthorization'
import {DealDocument} from 'common'
import {ChatsDocument} from 'components/types'

const MessageSection = () => {
  const [toggleState, setToggleState] = useState<string | undefined>()
  const [deals, setDeals] = useState<Array<DealDocument>>([])
  const {
    appState: {isSuperAdmin, isSuperManager, isMSAAdmin}
  } = useAppContext()
  const [showArchiveChats, setShowArchiveChats] = useState<boolean>(false)
  const [accountList, setAccountList] = useState<Array<string>>([])
  const [searchInput, setSearchInput] = useState<string>('')
  const [chatData, setChatData] = useState<ChatsDocument | null>(null)
  const {getUserDeals, getUserAccounts} = useFirebase()
  const {width} = useWindowSize()
  const {user} = useSession()
  const {state} = useLocation()
  const openChat = state?.openChat || false
  const chatId = state?.id || false
  const uid = user?.uid || ''
  const navigate = useNavigate()

  const isSuperAdminOrManager = isSuperAdmin || isSuperManager

  const getMSAAccountsList = async () => {
    const userAccounts = await getUserAccounts()
    const accountIDLength = userAccounts.length
    if (accountIDLength) {
      !accountList.length && setAccountList([...userAccounts])
    }
  }

  useEffect(() => {
    if (isMSAAdmin) {
      getMSAAccountsList()
    }
  }, [])

  const cancelCallback = (error: Error) => {
    if (error) {
      toast.error(`Something went wrong! ${error}`)
    }
  }

  useEffect(() => {
    if (!uid || (!isSuperAdminOrManager && !accountList?.length)) return
    if (!isSuperAdminOrManager && !accountList?.length) return
    const {unSubscribe} = getUserDeals(
      setDeals,
      cancelCallback,
      isSuperAdminOrManager,
      accountList
    )
    return () => {
      unSubscribe?.()
    }
  }, [uid, accountList?.length])

  const searchByNameAndMessage = (input: string, arr: Array<ChatsDocument>) => {
    return arr.filter(
      ({lastMessage, sellerContactName, dealName = ''}) =>
        lastMessage?.toLowerCase().indexOf(input.toLowerCase()) > -1 ||
        sellerContactName?.toLowerCase().indexOf(input.toLowerCase()) > -1 ||
        dealName?.toLowerCase().indexOf(input.toLowerCase()) > -1
    )
  }

  const isTabletAndMobile = width && width > 1024 ? false : true
  const archivedChats = deals?.filter(({isArchived}) => isArchived === true)
  const nonArchivedChats = deals?.filter(({isArchived}) => isArchived === false)
  const filteredChatList = getChatsList(
    showArchiveChats ? archivedChats : nonArchivedChats
  )

  const filteredChats = (
    searchInput
      ? searchByNameAndMessage(searchInput, filteredChatList)
      : filteredChatList
  ).sort(
    (a, b) =>
      b?.lastMessageTimestamp?.seconds - a?.lastMessageTimestamp?.seconds
  )

  const toggleTab = (index: string, data?: ChatsDocument) => {
    setToggleState(index)
    setChatData(data || null)
  }

  useEffect(() => {
    if (openChat) {
      const chatData = filteredChatList.find((chat) => chat.chatId === chatId)
      if (chatData) {
        const chatToggleState = `${chatData.productId}-${chatData.buyerContactId}`
        toggleTab(chatToggleState, chatData)
      }
    }
  }, [openChat, Boolean(filteredChatList.length)])

  const onBackArrowClick = () => {
    isTabletAndMobile
      ? toggleState === undefined
        ? navigate('/')
        : setToggleState(undefined)
      : navigate('/')
  }

  const toggleArchiveChats = () => setShowArchiveChats(!showArchiveChats)

  return (
    <div className={styles.withLayout}>
      <Layout />
      <div className={styles.layoutContainer}>
        <NavBar title="Chats" />
        <div className={styles.container}>
          <button onClick={onBackArrowClick}>
            <LongArrowGreenRight />
            Back
          </button>
          <h1>Messages</h1>
          <div className={styles.message_container}>
            <div
              style={
                !isTabletAndMobile
                  ? {display: 'block'}
                  : toggleState === undefined
                  ? {display: 'block'}
                  : {display: 'none'}
              }
              className={styles.messeges}
            >
              <div className={styles.input_container}>
                <div className={styles.serchbar}>
                  <div className={styles.search_icon}>
                    <LargeSearch />
                  </div>
                  <input
                    type="text"
                    value={searchInput}
                    onChange={(e) => setSearchInput(e.target.value)}
                    placeholder="Search messages"
                  />
                </div>
              </div>
              <div className={styles.content}>
                {showArchiveChats ? (
                  <button
                    className={styles['archived_btn-active']}
                    onClick={toggleArchiveChats}
                  >
                    <ChevronLeft /> <span>Archived</span>
                  </button>
                ) : (
                  <button
                    className={styles.archived_btn}
                    onClick={toggleArchiveChats}
                  >
                    <Archived />
                    Archived
                  </button>
                )}
              </div>

              <div className={styles.chats__container}>
                {filteredChats.map((chat: ChatsDocument, index: number) => {
                  const {
                    lastMessageTimestamp,
                    lastMessage,
                    productName,
                    thumbnail,
                    productId,
                    buyerContactId,
                    sellerContactName,
                    lastMessageSender
                  } = chat

                  const chatToggleState = `${productId}-${buyerContactId}`
                  const lastMessageToRelativeTime = getRelativeTime(
                    lastMessageTimestamp?.toDate()
                  )
                  const onToggleInboxCard = () => {
                    if (toggleState !== chatToggleState)
                      toggleTab(chatToggleState, chat)
                  }

                  return (
                    <InboxCard
                      key={`chat-list-${index}-${lastMessageTimestamp?.seconds}`}
                      onClick={onToggleInboxCard}
                      thumbnail={thumbnail}
                      title={productName}
                      content={sellerContactName}
                      buyerContactId={buyerContactId}
                      lastMessageSender={lastMessageSender}
                      message={lastMessage}
                      time={lastMessageToRelativeTime || ''}
                      customClass={
                        toggleState === chatToggleState
                          ? cn(styles.tabs, styles['active-tabs'])
                          : styles.tabs
                      }
                    />
                  )
                })}
              </div>
            </div>
            {chatData && (
              <div
                style={
                  !isTabletAndMobile
                    ? {display: 'block'}
                    : toggleState === undefined
                    ? {display: 'none'}
                    : {display: 'block'}
                }
                className={styles.container_messages_in}
              >
                <Chat
                  deals={deals}
                  data={chatData}
                  handleReset={() => setChatData(null)}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default withAuthorization(MessageSection)
