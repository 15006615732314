import React from 'react'
import styles from './Alert.module.scss'
import cn from 'classnames'
import {
  PurchaseMethod,
  alertTypes,
  inspectionStatus,
  offerStatus
} from 'common/enums'
import {GreenCheck, RedCross} from '../../../assets/svgs'

interface AlertProps {
  alertType: alertTypes
  purchaseMethod?: PurchaseMethod
  inactiveParticipants?: Array<string>
  type?: offerStatus | inspectionStatus
}

const Alert = ({
  type,
  alertType,
  purchaseMethod,
  inactiveParticipants
}: AlertProps) => {
  if (alertType === alertTypes.OfferReceiver)
    switch (type) {
      case offerStatus.ACCEPTED:
        return (
          <div className={cn(styles['alert'], styles['alert-wrapper'])}>
            <div className={cn(styles['alert'], styles['alert-success-1'])}>
              <div>
                <GreenCheck />
                {purchaseMethod === PurchaseMethod.BuyNow
                  ? 'Your equipment has been purchased'
                  : purchaseMethod === PurchaseMethod.Bid
                  ? 'Your equipment has been sold to the highest bidder'
                  : "You've accepted the offer"}
                . Buyer has been notified to proceed with the payment.
              </div>
            </div>
          </div>
        )
      case offerStatus.REJECTED:
        return (
          <div className={cn(styles['alert'], styles['alert-wrapper'])}>
            <div className={cn(styles['alert'], styles['alert-danger'])}>
              <div>
                <RedCross />
                Okay! Buyer has been notified about rejection of the offer.
              </div>
            </div>
          </div>
        )
      case offerStatus.COUNTER_ACCEPTED:
        return (
          <React.Fragment>
            <div className={cn(styles['alert'], styles['alert-wrapper'])}>
              <div className={cn(styles['alert'], styles['alert-success'])}>
                🎉 Counter-offer Accepted! Congratulations, the buyer has
                accepted your counter-offer. The deal is on its way!
              </div>
            </div>
          </React.Fragment>
        )
      case offerStatus.COUNTER_REJECTED:
        return (
          <div className={cn(styles['alert'], styles['alert-wrapper'])}>
            <div className={cn(styles['alert'], styles['alert-danger'])}>
              <div>
                <RedCross />
                Counter-offer Rejected! The buyer has decided not to accept your
                counter-offer. Please consider your options and continue
                negotiating if necessary.
              </div>
            </div>
          </div>
        )
      case offerStatus.COUNTER_WITHDRAWN:
        return (
          <div className={cn(styles['alert'], styles['alert-wrapper'])}>
            <div className={cn(styles['alert'], styles['alert-danger'])}>
              <div>
                <RedCross />
                Okay! Your counter-offer has been withdrawn.
              </div>
            </div>
          </div>
        )
      default:
        return null
    }
  if (alertType === alertTypes.InspectionReceiver)
    switch (type) {
      case inspectionStatus.REJECTED:
        return (
          <div className={cn(styles['alert'], styles['alert-wrapper'])}>
            <div className={cn(styles['alert'], styles['alert-danger'])}>
              <div>
                <RedCross />
                Okay! Buyer has been notified about rejection of the inspection
                request.
              </div>
            </div>
          </div>
        )
      case inspectionStatus.DONE:
        return (
          <div className={cn(styles['alert'], styles['alert-wrapper'])}>
            <div className={cn(styles['alert'], styles['alert-success-1'])}>
              <div>
                <GreenCheck />
                Great! The buyer can now view the inspection location. Please
                schedule a time at which the buyer can visit to inspect the
                equipment.
              </div>
            </div>
          </div>
        )
      default:
        return null
    }
  if (alertType === alertTypes.ProductSold) {
    return (
      <div className={cn(styles['alert'], styles['alert-wrapper'])}>
        <div className={cn(styles['alert'], styles['alert-info'])}>
          This equipment has been sold.
        </div>
      </div>
    )
  }
  if (alertType === alertTypes.ParticipantLeft) {
    return (
      <div
        style={{flexDirection: 'column'}}
        className={cn(styles['alert'], styles['alert-wrapper'])}
      >
        {inactiveParticipants?.map((userName: string, index) => {
          const firstName = userName?.split(' ')![0] || userName
          return (
            <div
              key={`inactive-participant-${userName}-${index}`}
              className={cn(
                styles['alert'],
                styles['alert-info'],
                styles['alert-chat-leave']
              )}
            >
              {firstName} left the chat
            </div>
          )
        })}
      </div>
    )
  } else return null
}

export default Alert
