import {fetcher, methods} from 'common/apis'
import {
  ZOHO_MANUAL_SIGN_GENERAL_MSA_AGREEMENT,
  ZOHO_MANUAL_SIGN_LA_AGREEMENT
} from 'common/constants'
import {API_HOST_URL} from 'components/constants'

const MANUAL_SIGN_MSA = `${API_HOST_URL}${ZOHO_MANUAL_SIGN_GENERAL_MSA_AGREEMENT}`
const MANUAL_SIGN_LA = `${API_HOST_URL}${ZOHO_MANUAL_SIGN_LA_AGREEMENT}`

export const manualMsaSigning = async ({
  accountId,
  adminId
}: {
  accountId: string
  adminId: string
}) => {
  const {data} = await fetcher(`${MANUAL_SIGN_MSA}`, methods.POST, {
    accountId,
    adminId
  })

  return {status: data.status, msg: data.msg, code: data.code}
}

export const manualLASigning = async ({
  productId,
  adminId
}: {
  productId: string
  adminId: string
}) => {
  const {data} = await fetcher(`${MANUAL_SIGN_LA}`, methods.POST, {
    productId,
    adminId
  })

  return {status: data.status, msg: data.msg, code: data.code}
}
