import {useState} from 'react'
import styles from './SelectConsigneeModal.module.scss'
import {Cancel} from '../../assets/svgs'
import {toast} from 'react-toastify'
import Button from '../Button'
import {useFirebase} from '../Firebase/hooks'
import DropDownSelect from 'components/DropDown'
import {Loader} from 'components/Loader'
import {signMethod} from 'components/enums'
import {manualLASigning, manualMsaSigning} from 'components/apis/agreement'
interface AdminDropdownDocument {
  id: string
  name: string
  email: string
}

interface SelectConsigneeModalProps {
  handleClose: () => void
  adminsList: Array<AdminDropdownDocument>
  accountID?: string
  productID?: string
  contactID?: string
  sign?: signMethod
  isMSA?: boolean
  isACH?: boolean
}

const SelectConsigneeModal = ({
  handleClose,
  adminsList,
  accountID,
  productID,
  contactID,
  sign,
  isMSA,
  isACH
}: SelectConsigneeModalProps) => {
  const {signAgreement} = useFirebase()
  const [loader, setLoader] = useState<boolean>(false)
  const [disabled, setDisabled] = useState(false)
  const [selectedConsignee, setSelectedConsignee] = useState<number>()
  const [consigneeData, setConsigneeData] = useState({
    consigneeId: '',
    consigneeName: '',
    consigneeEmail: ''
  })

  const handleSubmit = async () => {
    const adminNotSelected =
      !consigneeData.consigneeEmail && !consigneeData.consigneeName
    if (adminNotSelected) {
      toast.warning('Please select an admin for signing.')
      return
    }
    setDisabled(true)
    setLoader(true)
    if (sign === signMethod.Zoho) {
      const {
        message,
        error,
        statusCode: code
      } = isMSA
        ? await signAgreement({
            agreementData: {
              ...consigneeData,
              accountID
            },
            signMSA: true
          })
        : isACH
        ? await signAgreement({
            agreementData: {
              ...consigneeData,
              adminID: consigneeData.consigneeId,
              contactID: contactID
            },
            signACH: true
          })
        : await signAgreement({
            agreementData: {
              ...consigneeData,
              productID
            }
          })
      if (error) {
        toast.warning(error)
        setLoader(false)
        setDisabled(false)
        return
      }
      const errorCondition = code === 409 || code === 500 || code === 404
      if (errorCondition) {
        toast.warning(message)
        setLoader(false)
        setDisabled(false)
        return
      }
    }
    if (sign === signMethod.Manual) {
      const {status, msg, code} = isMSA
        ? await manualMsaSigning({
            accountId: accountID || '',
            adminId: consigneeData.consigneeId
          })
        : await manualLASigning({
            productId: productID || '',
            adminId: consigneeData.consigneeId
          })
      if (status === false || code === 500 || code === 404 || code === 409) {
        toast.warn(msg)
        setLoader(false)
        setDisabled(false)
        return
      }
    }
    toast.success('Agreement sent successfully.')
    setLoader(false)
    setDisabled(false)
    handleClose()
  }

  if (!sign) return null

  return (
    <div className={styles.container}>
      <div className={styles.cancelContainer}>
        <Cancel className={styles.cancel} onClick={handleClose} />
      </div>
      <div className={styles.content}>
        {loader ? (
          <Loader fullHeight={false} />
        ) : (
          <div className={styles.modalContent}>
            <div className={styles.headingContainer}>
              {sign === signMethod.Zoho
                ? 'Zoho Sign Agreement'
                : 'Manual Sign Agreement'}
            </div>
            <p className={styles.subText}>
              Please select an Admin to sign the agreement.
            </p>
            <div className={styles.dropdownContainer}>
              <DropDownSelect
                options={adminsList}
                customClass={styles.dropdowninput}
                placeholder="Select Admin"
                value={selectedConsignee}
                cancelClickFunction={() => {
                  setSelectedConsignee(adminsList.length + 1)
                  setConsigneeData({
                    consigneeId: '',
                    consigneeEmail: '',
                    consigneeName: ''
                  })
                }}
                onChange={(index) => {
                  setSelectedConsignee(index)
                  const consigneeName = adminsList[index].name
                  const consigneeEmail = adminsList[index].email
                  const consigneeId = adminsList[index].id
                  setConsigneeData({
                    consigneeId,
                    consigneeEmail,
                    consigneeName
                  })
                }}
                name="adminSelect"
              />
            </div>
            <div className={styles.buttonsComponent}>
              <Button
                customClass={styles.buttonClass}
                text="Sign Agreement"
                disabled={disabled}
                onClick={handleSubmit}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default SelectConsigneeModal
