import {CheckedIcon, UncheckedIcon} from 'assets/images'
import {Category, FILE_OBJECT} from 'common'
import ContentModal from 'components/ContentModal'
import ModalComponent from 'components/ModalComponent'
import UploadFileSection from 'components/ProductDetailPage/UploadFileSection'
import {DetailType} from 'components/enums'
import {Timestamp} from 'firebase/firestore'
import {Formik, FormikProps} from 'formik'
import _, {first, get} from 'lodash'
import React, {useEffect, useRef, useState} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {toast} from 'react-toastify'
import {ArrowDown} from '../../assets/svgs'
import Button from '../Button'
import DetailPage from '../DetailPage'
import {useFirebase} from '../Firebase/hooks'
import InputField from '../InputField'
import Layout from '../Layout'
import {Loader} from '../Loader'
import NavBar from '../Navbar'
import withAuthorization from '../Session/withAuthorization'
import {generateRandomId} from '../utils'
import styles from './CreateNewCategory.module.scss'
import {CreateCategorySchema} from './schema'

interface ICategoryFormState {
  name: string
  slug: string
  isPopularCategory: boolean
  imageUrl?: Array<FILE_OBJECT | undefined>
}

const CreateNewCategory = () => {
  const navigate = useNavigate()
  const {
    createOrEditCateogry,
    deleteCateogry,
    uploadFile,
    getCategories,
    getFileInfoFromStorage
  } = useFirebase()
  const {state} = useLocation()
  const [categories, setCategories] = useState<Array<Category>>([])
  const [show, setShow] = useState<boolean>(false)
  const [isUploading, setIsUploading] = useState<boolean>(false)
  const [formData, setFormData] = useState<ICategoryFormState>()
  const [imageFile, setImageFile] = useState<FILE_OBJECT>()
  const isPopularCategoryTimeStampFromState =
    state?.data?.isPopularCategoryMap?.isPopularCategoryTimestamp
  const isPopularCategoryFromState =
    state?.data?.isPopularCategoryMap?.isPopularCategory
  const isEdit = Boolean(state?.isEdit)
  const [disabled, setDisabled] = useState(isEdit ? true : false)
  const [loader, setLoader] = useState(isEdit ? true : false)
  const [categoryId, setCategoryId] = useState('')
  const formRef: React.Ref<FormikProps<ICategoryFormState>> = useRef(null)
  const previousPage = state?.currentPage
  const appState = {
    documentData: isEdit
      ? {
          ...state?.data,
          slug:
            state?.data?.slug ||
            _.kebabCase(state?.data?.name.replace('&', 'and'))
        }
      : ({
          name: '',
          nodeId: '',
          imageUrl: '',
          slug: '',
          isPopularCategoryMap: {
            isPopularCategory: false,
            isPopularCategoryTimestamp: Timestamp.now()
          }
        } as Category)
  }

  useEffect(() => {
    formRef.current?.setFieldValue(
      'slug',
      state?.data?.slug || _.kebabCase(state?.data?.name.replace('&', 'and'))
    )
  }, [formRef.current])

  const closeDeleteModal = () => setShow(false)

  useEffect(() => {
    if (!appState.documentData?.imageUrl?.length) return
    getFileInfoFromStorage(appState.documentData?.imageUrl).then((file) => {
      if (file) setImageFile(file)
    })
    getCategories().then(({data}) => setCategories(data || []))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appState.documentData?.imageUrl])

  useEffect(() => {
    setCategoryId(state?.id || generateRandomId())
    isEdit &&
      setTimeout(() => {
        setLoader(false)
      }, 2000)
  }, [])

  useEffect(() => {
    const {documentData} = appState
    if (documentData && !disabled) {
      const stateObj = {
        name: state?.data?.name,
        slug: state?.data?.slug,
        imageUrl: [imageFile],
        isPopularCategory: isPopularCategoryFromState
      }
      setFormData(stateObj)
      formRef?.current?.setValues(stateObj)
    }
  }, [appState?.documentData?.nodeId, disabled])

  useEffect(() => {
    isEdit &&
      setTimeout(() => {
        setLoader(false)
      }, 2000)
  }, [])

  const isCategoryDetails = isEdit && disabled

  const handleDeletion = async () => {
    setLoader(true)
    const {error} = await deleteCateogry(categoryId)
    if (error) {
      toast.error(`${error}`)
      setLoader(false)
      return
    }
    toast.success('Category deleted successfully')
    setLoader(false)
    navigate('/categories', {
      state: {page: previousPage}
    })
    return
  }

  const checkIfAlreadyTaken = (slug: string): boolean => {
    const otherCategoriesSlug =
      categories
        .filter(({nodeId}) => nodeId !== categoryId)
        .map((category) => category.slug) || []

    const alreadyExists = otherCategoriesSlug.includes(slug)
    return alreadyExists
  }

  const placeHolderImage =
    'https://firebasestorage.googleapis.com/v0/b/auctionsite.appspot.com/o/assets%2FImagePlaceholder.png?alt=media&token=d33e0dbc-6ae2-403b-b8af-d689a6304235'

  return (
    <div>
      <div className={styles.withLayout}>
        <Layout />
        <div className={styles.container}>
          <NavBar title="Categories" />
          {loader ? (
            <Loader />
          ) : (
            <React.Fragment>
              <div className={styles.buttons}>
                <span
                  onClick={() =>
                    navigate('/categories', {
                      state: {page: previousPage}
                    })
                  }
                >
                  <ArrowDown />
                  Back
                </span>
                <div className={styles.twobtn}>
                  {disabled && (
                    <Button
                      text="Edit"
                      customClass={styles.changes_btn}
                      onClick={() => setDisabled(false)}
                    />
                  )}
                  {(!isEdit || !disabled) && (
                    <Button
                      text={'Save'}
                      customClass={styles.changes_btn}
                      onClick={() => formRef.current?.handleSubmit()}
                      disabled={disabled}
                    />
                  )}
                  {isEdit && (
                    <Button
                      text={'Delete'}
                      customClass={styles.changes_btn}
                      onClick={() => setShow(true)}
                    />
                  )}
                </div>
              </div>

              <div className={styles.edit_section}>
                {!isCategoryDetails && (
                  <h1 className={styles.secondheading}>Category Details</h1>
                )}
                {isCategoryDetails ? (
                  <DetailPage
                    data={appState.documentData}
                    detailType={DetailType.Category}
                  />
                ) : (
                  <Formik
                    innerRef={formRef}
                    initialValues={{
                      slug: appState.documentData?.slug,
                      name: appState.documentData?.name,
                      imageUrl: (imageFile && [imageFile]) || [],
                      isPopularCategory:
                        appState.documentData?.isPopularCategoryMap
                          ?.isPopularCategory || false
                    }}
                    validationSchema={CreateCategorySchema}
                    onSubmit={async (values: ICategoryFormState) => {
                      const isAlreadyTaken = checkIfAlreadyTaken(values.slug)
                      if (isAlreadyTaken) {
                        toast.warn('The provided URL is already in use.')
                        return
                      }
                      const hasName = values.name.length
                      const image = first(values.imageUrl)
                      const hasImage = image?.type?.includes('image') || false
                      if (isUploading) {
                        toast.warn(
                          'Please wait till the files finish uploading'
                        )
                        return
                      }
                      if (!hasName) {
                        toast.warn('Please provide a category name')
                        return
                      }
                      if (!hasImage) {
                        toast.warn('Please Upload an Image')
                        return
                      }
                      const popularCategoryMap = {
                        isPopularCategory: values.isPopularCategory || false,
                        isPopularCategoryTimestamp:
                          isPopularCategoryTimeStampFromState || Timestamp.now()
                      }

                      if (
                        values.isPopularCategory === true &&
                        formData?.isPopularCategory !== values.isPopularCategory
                      ) {
                        popularCategoryMap.isPopularCategoryTimestamp =
                          Timestamp.now()
                      }
                      const imageUnchanged = image?.downloadURL || false
                      setLoader(true)
                      if (imageUnchanged) {
                        const {error, message} = await createOrEditCateogry(
                          {
                            slug: values.slug,
                            nodeId: categoryId,
                            name: `${values.name}`.trim(),
                            isPopularCategoryMap: popularCategoryMap
                          },
                          true
                        )
                        setLoader(false)
                        if (error) {
                          toast.warning(`${error}`)
                          return
                        } else {
                          toast.success(message)
                          navigate('/categories', {
                            state: {page: previousPage}
                          })
                        }
                        return
                      } else {
                        await uploadFile({
                          file: image as any as File,
                          onFileUpload: async (downloadURL?: string) => {
                            const popularCategoryMap = {
                              isPopularCategory:
                                values.isPopularCategory || false,
                              isPopularCategoryTimestamp:
                                isPopularCategoryTimeStampFromState ||
                                Timestamp.now()
                            }

                            if (
                              values.isPopularCategory === true &&
                              formData?.isPopularCategory !==
                                values.isPopularCategory
                            ) {
                              popularCategoryMap.isPopularCategoryTimestamp =
                                Timestamp.now()
                            }
                            const {error, message} = isEdit
                              ? await createOrEditCateogry(
                                  {
                                    slug: values.slug,
                                    nodeId: categoryId,
                                    name: `${values.name}`.trim(),
                                    imageUrl: downloadURL || placeHolderImage,
                                    isPopularCategoryMap: popularCategoryMap
                                  },
                                  true
                                )
                              : await createOrEditCateogry(
                                  {
                                    slug: values.slug,
                                    nodeId: categoryId,
                                    name: values.name,
                                    imageUrl: downloadURL || placeHolderImage,
                                    isPopularCategoryMap: popularCategoryMap
                                  },
                                  false
                                )
                            if (error) {
                              setLoader(false)
                              toast.warning(`${error}`)
                              return
                            } else {
                              setLoader(false)
                              toast.success(message)
                              navigate('/categories', {
                                state: {page: previousPage}
                              })
                            }
                            return
                          },
                          filePath: undefined,
                          productId: categoryId,
                          fileType: 'image',
                          fileName: 'fileName',
                          isCategory: true
                        })
                      }
                    }}
                  >
                    {(props) => {
                      const {
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        setFieldValue
                      } = props
                      return (
                        <form
                          className={styles.from_submiting}
                          onSubmit={handleSubmit}
                        >
                          <div className={styles.formContainer}>
                            <div className={styles.inputs}>
                              <div className={styles.inputContainer}>
                                <div className={styles.inputField}>
                                  <InputField
                                    name="name"
                                    placeholder="Name"
                                    value={get(values, 'name')}
                                    onChange={(e) => {
                                      handleChange(e)
                                      const slug = _.kebabCase(
                                        e.target.value.replace('&', 'and')
                                      )
                                      setFieldValue('slug', slug)
                                    }}
                                    errors={errors}
                                    touched={touched}
                                    onBlur={handleBlur}
                                    type="text"
                                    disabled={disabled}
                                  />
                                </div>
                              </div>
                              <div className={styles.inputContainer}>
                                <div className={styles.inputField}>
                                  <InputField
                                    name="slug"
                                    placeholder="URL"
                                    value={get(values, 'slug')}
                                    onChange={handleChange}
                                    errors={errors}
                                    touched={touched}
                                    onBlur={handleBlur}
                                    type="text"
                                    disabled={disabled}
                                  />
                                </div>
                              </div>
                              <div
                                style={{marginBottom: '2rem'}}
                                className={styles.withCheckbox}
                              >
                                <div className={styles.containerCheck}>
                                  <div className={styles.formHeading}>
                                    Popular Category
                                  </div>
                                  <div className={styles.inputCheckbox}>
                                    <div className={styles.inputField}>
                                      <InputField
                                        placeholder="Yes"
                                        inputFieldIcon={
                                          get(values, 'isPopularCategory')
                                            ? CheckedIcon
                                            : UncheckedIcon
                                        }
                                        iconClick={() => {
                                          setFieldValue(
                                            'isPopularCategory',
                                            true
                                          )
                                        }}
                                      />
                                    </div>
                                    <div className={styles.inputField}>
                                      <InputField
                                        placeholder="No"
                                        inputFieldIcon={
                                          !get(values, 'isPopularCategory')
                                            ? CheckedIcon
                                            : UncheckedIcon
                                        }
                                        iconClick={() => {
                                          setFieldValue(
                                            'isPopularCategory',
                                            false
                                          )
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <UploadFileSection
                                isUploading={isUploading}
                                setIsUploading={setIsUploading}
                                isCategoryUpload={true}
                                productID={categoryId}
                                touched={touched}
                                errors={errors}
                                handleBlur={handleBlur}
                                setFieldValue={setFieldValue}
                                values={values}
                                deleteCallback={() =>
                                  (appState.documentData.imgUrl = '')
                                }
                              />
                            </div>
                          </div>
                        </form>
                      )
                    }}
                  </Formik>
                )}
              </div>
            </React.Fragment>
          )}
          {show && (
            <ModalComponent handleClose={closeDeleteModal} show={show}>
              <ContentModal
                removeIcon
                buttonText="Confirm"
                buttonDisabled={loader}
                heading={`Delete Category`}
                buttonOnClick={handleDeletion}
                handleClose={closeDeleteModal}
                content={
                  'You cannot undo these changes. Are you sure you want to delete this category?'
                }
              />
            </ModalComponent>
          )}
        </div>
      </div>
    </div>
  )
}

export default withAuthorization(CreateNewCategory)
