import {UserPlaceholder} from 'assets/images'
import cn from 'classnames'
import {FrontEndNotification} from 'common'
import {useAppContext, useFirebase, useSession} from 'components/Firebase/hooks'
import Layout from 'components/Layout'
import LinkWrapper from 'components/LinkWrapper'
import {LoaderWithoutText} from 'components/Loader'
import Navbar from 'components/Navbar'
import withAuthorization from 'components/Session/withAuthorization'
import {
  addQueryParam,
  convertToDate,
  extractPathFromUrl,
  getRelativeTime,
  getUniqueObjects,
  sameHostURL
} from 'components/utils'
import {DocumentData, QueryDocumentSnapshot} from 'firebase/firestore'
import {useEffect, useState} from 'react'
import styles from './Notifications.module.scss'
import MarkAsReadModal from './MarkAsReadModal'
import {toast} from 'react-toastify'

const Notifications = ({customClass}: {customClass?: string}) => {
  const {
    appState: {
      notifications: initialNotifications,
      lastVisibleNotification: initialLastVisibleNotification,
      hasMoreNotifications: moreNotifications,
      adminDetails
    },
    newAppState
  } = useAppContext()
  const {user} = useSession()
  const userId = user?.uid
  const photoURL = user?.photoURL
  const {notificationTimestamps} = adminDetails || {}
  const {lastViewed, markAsRead} = notificationTimestamps || {}
  const latestNotificationTime =
    initialNotifications?.[0]?.createdAt?.valueOf() || 0
  const hasUnreadNotifications = lastViewed
    ? latestNotificationTime > lastViewed?.valueOf()
    : Boolean(initialNotifications?.length)
  const allMarkedAsRead = markAsRead
    ? markAsRead?.valueOf() > latestNotificationTime
    : false

  const {getNextNotifications, updateLastViewedNotification} = useFirebase()
  const [loading, setLoading] = useState(false)
  const [showMarkAsReadModal, setShowMarkAsReadModal] = useState(false)
  const [notifications, setNotifications] = useState<
    Array<DocumentData> | undefined
  >([...(initialNotifications || [])])
  const [lastVisibleNotification, setLastVisibleNotification] = useState<
    QueryDocumentSnapshot<DocumentData> | undefined
  >(initialLastVisibleNotification)

  useEffect(() => {
    if (!userId) return
    if (hasUnreadNotifications) updateLastViewedNotification()
    setNotifications([...(initialNotifications || [])])
    setLastVisibleNotification(initialLastVisibleNotification)
  }, [initialNotifications?.length, userId])

  const fetchNextNotifications = async () => {
    setLoading(true)
    const res = await getNextNotifications({
      lastVisibleNotification:
        lastVisibleNotification || initialLastVisibleNotification
    })
    const hasMoreNotifications = res?.notifications?.length === 10
    if (res?.lastVisibleNotification) {
      setLastVisibleNotification(res.lastVisibleNotification)
    }
    newAppState({hasMoreNotifications: hasMoreNotifications})
    if (res?.notifications) {
      setNotifications((prev) => {
        const newNotificationsArr = [
          ...(prev || []),
          ...res?.notifications
        ] as Array<FrontEndNotification>

        const removeDuplicates = getUniqueObjects(
          newNotificationsArr,
          (e) => e?.createdAt.seconds && e?.createdAt.nanoseconds
        )

        return removeDuplicates
      })
    }
    setLoading(false)
  }

  const toggleMarkAsReadModal = () => {
    if (allMarkedAsRead && showMarkAsReadModal === false) {
      toast.warn('All notifications are already marked as read.')
      return
    }
    setShowMarkAsReadModal(!showMarkAsReadModal)
  }

  return (
    <div className={styles.withLayout}>
      <Layout />
      <div className={[styles.container, customClass].join(' ')}>
        <Navbar title="Notifications" />
        <div className={cn(styles.top_container, customClass)}>
          <div className={styles.btnWrapper}>
            <button
              onClick={toggleMarkAsReadModal}
              className={styles.markAllRead}
            >
              MARK ALL AS READ
            </button>
          </div>
          <div className={styles.Notification_container}>
            {notifications?.map((notification, index) => {
              const {
                CTA,
                photoUrl,
                createdAt,
                name,
                description,
                title,
                read,
                notificationId
              } = notification

              const isRead =
                read ||
                (markAsRead?.valueOf() || 0) >= createdAt.valueOf() ||
                false

              const getCta = () => {
                const hostname =
                  (typeof window !== 'undefined' && window.location.hostname) ||
                  ''
                const ctaOfSameOrigin = sameHostURL(CTA, hostname)
                if (ctaOfSameOrigin) {
                  const ctaPath = extractPathFromUrl(
                    isRead
                      ? CTA
                      : addQueryParam({
                          url: CTA,
                          param: 'notificationId',
                          value: notificationId
                        })
                  )
                  return {isExternal: false, cta: ctaPath}
                }
                return {
                  isExternal: true,
                  cta: CTA
                }
              }

              const link = getCta()
              const time = getRelativeTime(convertToDate(createdAt))

              return (
                <LinkWrapper
                  to={link.cta}
                  external={link.isExternal}
                  key={`notification-${createdAt?.seconds}-${index}`}
                >
                  <div
                    style={isRead ? {} : {background: 'aliceblue'}}
                    className={styles.notify}
                  >
                    <img
                      alt="user-img"
                      src={photoUrl || photoURL || UserPlaceholder}
                    />
                    <div className={styles.post_container}>
                      <div>
                        <h3>
                          <b>{name}</b>
                          {title}
                        </h3>
                        <p>{description}</p>
                      </div>
                      <span>
                        {isRead ? null : <span className={styles.dot}></span>}
                        <p className={styles.time}>{time}</p>
                      </span>
                    </div>
                  </div>
                </LinkWrapper>
              )
            })}
          </div>
          <div className={styles.notifications_loader}>
            {loading && <LoaderWithoutText />}
          </div>
          {!loading && moreNotifications && (
            <button
              className={styles.loadMore}
              onClick={fetchNextNotifications}
            >
              Load More
            </button>
          )}
        </div>
      </div>
      {showMarkAsReadModal && (
        <MarkAsReadModal
          handleClose={toggleMarkAsReadModal}
          show={showMarkAsReadModal}
        />
      )}
    </div>
  )
}

export default withAuthorization(Notifications)
