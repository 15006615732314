import classNames from 'classnames'
import {AttachmentFileType, IProductFormState} from 'common'
import {useFirebase} from 'components/Firebase/hooks'
import {
  downloadPDFfromURL,
  getParsedFileNameForUploadingFile,
  isCorrectURI
} from 'components/utils'
import {cloneDeep, get} from 'lodash'
import {useEffect, useRef, useState} from 'react'
import {toast} from 'react-toastify'
import {Cancel_red, PdfIcon, PinPdf} from '../../../assets/svgs'
import styles from './DragPdf.module.scss'

interface DragPdfProps {
  touched?: {} | undefined
  errors?: {} | undefined
  handleBlur: (e: any) => void | undefined
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => Promise<any>
  values: IProductFormState
  requestedChanges?: {
    [key: string]: string
  }
  productId?: string
  isUploading: boolean
  onlyPdfs?: boolean
  setIsUploading: (e: boolean) => void
  filesCountState?: {
    filesCount: {attachments: number; media: number}
    initialLoaded: boolean
  }
}

const DragPdf = ({
  touched,
  errors,
  handleBlur,
  setFieldValue,
  onlyPdfs = false,
  values,
  requestedChanges,
  productId,
  setIsUploading,
  filesCountState
}: DragPdfProps) => {
  const {filesCount, initialLoaded} = filesCountState || {}
  const pdfAttachments = values?.productDetails
    ?.pdfAttachments as Array<AttachmentFileType>
  const isTouched = get(touched, 'productDetails.pdfAttachments')
  const requestedChange = get(requestedChanges, 'attachments')
  const error = get(errors, 'productDetails.pdfAttachments')

  const {uploadFile, deleteFile} = useFirebase()
  const [uploadingFilesCount, setUploadingFilesCount] = useState(0)
  const [removeDisabled, setRemoveDisabled] = useState(false)
  const [dragOver, setDragOver] = useState(false)
  const fileInputRef = useRef<HTMLInputElement>(null)
  const [files, setFiles] = useState<Array<AttachmentFileType>>(
    pdfAttachments?.length ? cloneDeep(pdfAttachments) : []
  )

  const handleDragEnter = () => {
    setDragOver(true)
  }

  const handleDragLeave = () => {
    setDragOver(false)
  }

  const incrementCount = () => setUploadingFilesCount((prev) => prev + 1)
  const decrementCount = () =>
    setUploadingFilesCount((prev) => (prev <= 1 ? 0 : prev - 1))

  const renderPlaceholder = () => {
    const isProductForm = !initialLoaded && onlyPdfs
    const modifiedFiles = isProductForm
      ? (filesCount && filesCount.attachments - files.length) || 0
      : 0
    const uploadingOrModifiedFilesCount = isProductForm
      ? modifiedFiles > 0
        ? modifiedFiles
        : 0
      : uploadingFilesCount
    const showPlaceholder = Boolean(uploadingOrModifiedFilesCount)
    return showPlaceholder
      ? Array.from({length: uploadingOrModifiedFilesCount}).map((_, index) => (
          <div
            key={`skeleton-${index}-${uploadingFilesCount}`}
            className="skeleton-box"
            style={{height: '62px', width: 'inherit', borderRadius: '10px'}}
          />
        ))
      : null
  }

  useEffect(() => {
    if (!pdfAttachments?.length) return
    setFiles(cloneDeep(pdfAttachments))
  }, [pdfAttachments?.length])

  const handleDelete = (title: string) => {
    const filesArr = files.filter((file) => file?.title !== title)
    setFiles(() => {
      const files = cloneDeep(filesArr)
      setFieldValue('productDetails.pdfAttachments', files)
      return files
    })
    toast.success('File Deleted Successfully')
  }

  const handleRemove = async (file: AttachmentFileType, index: number) => {
    if (removeDisabled) return
    setRemoveDisabled(true)
    const fileName = file?.title
    await deleteFile({
      fileName,
      productID: productId,
      fileType: 'attachments'
    })
    handleDelete(fileName)
    setRemoveDisabled(false)
  }

  const handleUpload = async (currentFile: File, fileName: string) => {
    incrementCount()
    setIsUploading(true)
    await uploadFile({
      file: currentFile,
      onFileUpload: (downloadURL) => {
        const file: AttachmentFileType = {
          title: fileName,
          url: downloadURL || '',
          originalTitle: currentFile.name
        }
        setFiles((state) => {
          const updatedState = cloneDeep(state)
          setFieldValue('productDetails.pdfAttachments', [
            ...updatedState,
            file
          ])
          return [...updatedState, file]
        })
        decrementCount()
        toast.success('File Uploaded Successfully')
        setIsUploading(false)
      },
      productId: productId,
      fileType: 'attachments',
      fileName
    })
  }

  const getFileInformation = (file: File) => {
    const {type, size, name} = file
    const isPDF = type === 'application/pdf'
    const sizeInMB = size / 1024 ** 2
    const fileArray = [...files]
    const countOfPDFFiles = fileArray?.length
    return {
      name,
      isPDF,
      sizeInMB,
      countOfPDFFiles
    }
  }

  const uploadFileValidations = async (file: File) => {
    const {name, isPDF, sizeInMB, countOfPDFFiles} = getFileInformation(file)
    if (!isCorrectURI(name)) {
      toast.warn(
        'Please rename your file appropriately. The file name contains invalid characters.'
      )
      return
    }
    const fileName = getParsedFileNameForUploadingFile(name)
    if (isPDF) {
      if (sizeInMB > 5) {
        toast.warning('Each Document should be less than 5 MB')
        return
      }
      if (countOfPDFFiles === 10) {
        toast.warning('10 Documents Maximum Allowed')
        return
      }
      setIsUploading(true)
      await handleUpload(file, fileName)
      return
    }
    toast.warning('Only PDFs are allowed')
    return
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h1>DOCUMENTS</h1>
        <div
          className={classNames(styles['darg_items'], {
            [styles['blur']]: dragOver
          })}
          onDragEnter={handleDragEnter}
          onDragOver={(e) => e.preventDefault()}
          onDragLeave={handleDragLeave}
        >
          <PdfIcon className={styles.pdf_icon} />
          <h3>
            PDF Files Relating to your Equipment - Brochures, Maintenance
            Records, etc. Drag and Drop Files Here or Click to{' '}
            <button type="button" onClick={() => fileInputRef.current?.click()}>
              {' '}
              Browse
            </button>
          </h3>
          <input
            onBlur={handleBlur}
            type="file"
            ref={fileInputRef}
            accept={onlyPdfs ? 'application/pdf' : undefined}
            onDropCapture={(e) => {
              e.stopPropagation()
              setDragOver(false)
            }}
            onChange={async (e) => {
              if (e?.target?.files) {
                for (var i = 0; i < e.target.files.length; i++) {
                  var imageFile = e.target.files[i]
                  await uploadFileValidations(imageFile)
                }
              }
              if (fileInputRef?.current) fileInputRef.current.value = ''
            }}
            onClick={(event) => {
              const target = event.target as HTMLInputElement
              target.value = ''
            }}
            multiple
          />
        </div>
      </div>
      <div className={styles.files_container}>
        {files.map((file, index) => (
          <div
            className={styles.file_container_red}
            key={`file-${file.title}-${index}`}
          >
            <h4
              onClick={() => downloadPDFfromURL(file?.url, file?.title)}
              className={styles.downloadable}
            >
              <PinPdf />
              {file?.originalTitle}
            </h4>
            <button
              type="button"
              disabled={removeDisabled}
              onClick={() => handleRemove(file, index)}
            >
              Remove <Cancel_red />
            </button>
          </div>
        ))}
      </div>
      {renderPlaceholder()}
      <span className={styles.error}>
        {requestedChange
          ? `${requestedChange}${isTouched && error ? ', ' + error : ''} `
          : isTouched && error}
      </span>
    </div>
  )
}

export default DragPdf
