import React, {useState, useCallback, useRef} from 'react'
import Picker from '@emoji-mart/react'
import styles from './EmojiInput.module.scss'
import {useClickOutside} from 'components/utils'
import {Emoji} from 'assets/svgs'

interface EmojiInputProps {
  value: string
  isDisabled?: boolean
  onSelection(contentWithEmoji: string): any
}

const EmojiInput = ({value, onSelection, isDisabled}: EmojiInputProps) => {
  const [showPicker, setPickerState] = useState(false)
  const picker = useRef<HTMLDivElement>(null)

  const dismissPicker = useCallback(() => {
    setPickerState(false)
  }, [setPickerState])

  useClickOutside([picker], dismissPicker)

  const togglePicker = () => {
    setPickerState(!showPicker)
  }

  const addEmoji = (emoji: any) => {
    if ('native' in emoji) {
      onSelection(`${value}${emoji.native}`)
      dismissPicker()
    }
  }

  return (
    <div className={styles.emoji_container} ref={picker}>
      <div className={styles.emoji_dialog}>
        {showPicker && (
          <Picker
            theme="light"
            set="native"
            emoji=""
            title=""
            native={true}
            onEmojiSelect={addEmoji}
          />
        )}
      </div>
      <button
        disabled={isDisabled}
        className={styles.emoji_btn}
        onClick={togglePicker}
      >
        <Emoji title="Open emoji selector" />
      </button>
    </div>
  )
}

export {EmojiInput}
