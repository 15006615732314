import {Cancel} from 'assets/svgs'
import Button from 'components/Button'
import ModalComponent from 'components/ModalComponent'
import {useState} from 'react'
import styles from './AddCommentsModal.module.scss'
import {InvoiceDocument} from 'common'
import {useFirebase} from 'components/Firebase/hooks'
import {toast} from 'react-toastify'

interface AddCommentsModalProps {
  handleClose: () => void
  invoice: InvoiceDocument
  show: boolean
}

const AddCommentsModal = ({
  handleClose,
  invoice,
  show
}: AddCommentsModalProps) => {
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [disclaimer, setDisclaimer] = useState(
    invoice.comment?.disclaimer || ''
  )
  const [description, setDescription] = useState(
    invoice.comment?.description || ''
  )
  const {addInvoiceComment} = useFirebase()
  const invoiceId = invoice.invoiceId

  const handleSubmit = async () => {
    if (isSubmitted || !invoiceId) return
    if (!disclaimer.length && !description.length) {
      toast.warn('Both description and disclaimer is required.')
      return
    }
    if (!disclaimer.length) {
      toast.warn('Please enter a disclaimer.')
      return
    }
    if (!description.length) {
      toast.warn('Please enter a description.')
      return
    }
    setIsSubmitted(true)
    const {success, message} = await addInvoiceComment({
      invoiceId: invoiceId,
      invoiceComment: {
        description,
        disclaimer
      }
    })
    success ? toast.success(message) : toast.warn(message)
    setIsSubmitted(false)
    handleClose()
  }

  const resetDescription = () => {
    setDescription(
      `All payments are due within 5 business days from the date of this invoice. Late payments may be subject to additional fees. UNLESS OTHERWISE STATED SPECIFICALLY IN THE LISTING, TO THE EXTENT PERMITTED BY APPLICABLE LAW, THE ITEM SOLD IS BEING SOLD ON AN “AS IS – WHERE IS” BASIS, WITHOUT ANY WARRANTY, EXPRESSED OR IMPLIED, INCLUDING ANY IMPLIED WARRANTY OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE. SELLER SHALL NOT BE LIABLE FOR LOSS OF REVENUES OR PROFITS, INCONVENIENCE, EXPENSE FOR SUBSTITUTE EQUIPMENT OR SERVICE, STORAGE CHARGES, LOSS OR ANY OTHER SPECIAL, INCIDENTAL OR CONSEQUENTIAL DAMAGES CAUSED BY THE USE OR MISUSE OF, OR INABILITY TO USE THE ITEM SOLD. IN NO EVENT SHALL SELLER’S LIABILITY EXCEED THE AMOUNT BUYER PAID TO SELLER TO PURCHASE THE ITEM SOLD. BUYER ASSUMES ALL RISK AND LIABILITY FOR LOSS, DAMAGE OR INJURY TO BUYER AND BUYER’S PROPERTY AND TO OTHERS AND THEIR PROPERTY ARISING OUT OF USE OR MISUSE OF, OR INABILITY TO USE THE ITEM SOLD.`
    )
  }

  const resetDisclaimer = () => {
    setDisclaimer(
      `Please wire the amount shown using the wire information listed below within 5 business days. Include the invoice number with your wire.`
    )
  }

  return (
    <ModalComponent show={show} handleClose={handleClose}>
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.heading}>
            <Cancel onClick={handleClose} />
            <h1>Add Invoice Comment</h1>
          </div>
          <div className={styles.textarea_wrapper}>
            <div>
              <textarea
                onChange={(e) => setDescription(e.target.value.slice(0, 1000))}
                placeholder={'Please enter comment description.'}
                value={description}
                maxLength={1000}
                cols={30}
                rows={10}
              ></textarea>
              <div className={styles.text_options}>
                <div>{description.length}/1000 characters</div>
                <Button
                  customBtnClass="btn-fill-orange"
                  onClick={resetDescription}
                  disabled={isSubmitted}
                  withLoader={false}
                  type="button"
                  text="Reset"
                />
              </div>
            </div>
            <div>
              <textarea
                onChange={(e) => setDisclaimer(e.target.value.slice(0, 250))}
                placeholder={'Please enter comment disclaimer.'}
                value={disclaimer}
                maxLength={250}
                cols={30}
                rows={10}
              ></textarea>
              <div className={styles.text_options}>
                <div>{disclaimer.length}/250 characters</div>
                <Button
                  customBtnClass="btn-fill-orange"
                  onClick={resetDisclaimer}
                  disabled={isSubmitted}
                  withLoader={false}
                  type="button"
                  text="Reset"
                />
              </div>
            </div>
          </div>
          <div className={styles.buttons}>
            <button className="btn-cancel" onClick={handleClose}>
              Cancel
            </button>
            <Button
              customBtnClass="btn-fill-orange"
              onClick={handleSubmit}
              disabled={isSubmitted}
              type="button"
              text="Submit"
            />
          </div>
        </div>
      </div>
    </ModalComponent>
  )
}

export default AddCommentsModal
