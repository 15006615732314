import classNames from 'classnames'
import Button from 'components/Button'
import ContentModal from 'components/ContentModal'
import {useAppContext, useFirebase} from 'components/Firebase/hooks'
import ModalComponent from 'components/ModalComponent'
import {IFieldSetSingleField, IFormattedContact} from 'components/types'
import React, {useState} from 'react'
import {toast} from 'react-toastify'
import {FieldSet} from '../DetailFields'
import styles from '../DetailPage.module.scss'

const ContactDetails = (data: IFormattedContact) => {
  const isDepositWaived = data.personalDetails?.stripe?.depositWaived || false
  const isPaymentHoldSubmitted =
    data.personalDetails?.stripe?.isPaymentHold || false
  const isEmailVerified = data.personalDetails?.emailVerified || false
  const [deferredDepositStatus, setDeferredDepositStatus] =
    useState(isDepositWaived)
  const [showWaiveDepositModal, setShowWaiveDepositModal] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const {waiveBidderDeposit} = useFirebase()
  const {
    appState: {isSuperAdmin}
  } = useAppContext()

  const toggleWaiveDepositModal = () => {
    setDeferredDepositStatus(isDepositWaived)
    setShowWaiveDepositModal(!showWaiveDepositModal)
  }

  const handleWaiveDeposit = async () => {
    if (!data?.contactId) return
    if (!isDepositWaived && isPaymentHoldSubmitted) {
      toast.warn('A bidder deposit has already been submitted by this contact.')
      return
    }
    setDisabled(true)
    const depositStatus = deferredDepositStatus ? false : true
    const {status} = await waiveBidderDeposit(data?.contactId, depositStatus)
    status
      ? depositStatus
        ? toast.success('Bidder deposit waived successfully')
        : toast.success('Bidder deposit waive removed successfully')
      : toast.error('Something went wrong! Failed to perform this action.')
    setDisabled(false)
    toggleWaiveDepositModal()
  }

  const contactFields: Array<IFieldSetSingleField> = [
    {label: 'User ID', value: data?.contactId || '-'},
    {label: 'First Name', value: data?.firstName},
    {label: 'Account Name', value: data?.accountName || ''},
    {label: 'Title', value: data?.title},
    {label: 'Is Primary Contact', value: data?.isPrimaryContact ? 'Yes' : 'No'},
    {label: 'Mobile', value: data?.mobileNumber},
    {label: 'Email', value: data?.emailAddress || '-'},
    {label: 'Is Synced', value: data?.isSynced ? 'Yes' : 'No'},
    {label: 'Is Verified', value: isEmailVerified ? 'Yes' : 'No'}
  ]

  const userPreferencesFields: Array<IFieldSetSingleField> = [
    {
      label: 'Acknowledgement for text message',
      value: data?.textMessage ? 'Yes' : 'No'
    },
    {
      label: 'Acknowledgement for newsletter',
      value: data?.newsLetter ? 'Yes' : 'No'
    }
  ]

  const personalDetailFields: Array<IFieldSetSingleField> = [
    {
      label: 'Payment Hold Submitted',
      value: isPaymentHoldSubmitted,
      isBoolean: true
    }
  ]

  const additionalFields: Array<IFieldSetSingleField> = [
    {label: 'Last Name', value: data?.lastName},
    {label: 'Account ID', value: data?.accountId},
    {label: 'Created At', value: data?.createdAt},
    {label: 'Last Updated', value: data?.lastUpdated},
    {label: 'Initial Terms Accepted', value: data?.intialTermsAccepted},
    {label: 'Latest Terms Accepted', value: data?.latestTermsAccepted},
    {label: 'Last Updated', value: data?.lastUpdated},
    {label: 'Is ACH Authorized', value: data?.isAchAuthorize, isBoolean: true},
    {
      label: 'Awaiting Feedback For Equipment(s)',
      dropDown: {
        isDropdDown: true,
        options: data.awaitFeedback || [],
        route: '/equipment/single-equipment-detail'
      }
    }
  ]

  return (
    <React.Fragment>
      <div className={styles.inputsfields}>
        <FieldSet gap fields={contactFields} />
        <FieldSet gap title="User Preferences" fields={userPreferencesFields} />
      </div>
      <div className={styles.inputsfields}>
        <FieldSet fields={additionalFields} />
        {isSuperAdmin && (
          <React.Fragment>
            <FieldSet
              title="Personal Details"
              fields={personalDetailFields}
              gap
            />
            <div
              className={classNames(styles.sec_input, styles.normalAlignItems)}
            >
              <p className={styles.customParagraphTag}>Waive Bidder Deposit</p>
              <Button
                onClick={toggleWaiveDepositModal}
                customClass={styles.waive_deposit_button}
                text={isDepositWaived ? 'Remove Waive' : 'Waive Deposit'}
                customBtnClass={
                  isDepositWaived ? 'btn-fill-red' : 'btn-fill-orange'
                }
              />
            </div>
          </React.Fragment>
        )}
      </div>
      {showWaiveDepositModal && isSuperAdmin && (
        <ModalComponent
          handleClose={toggleWaiveDepositModal}
          show={showWaiveDepositModal}
        >
          <ContentModal
            buttonText="Confirm"
            removeIcon={deferredDepositStatus}
            buttonDisabled={disabled}
            heading={
              deferredDepositStatus
                ? 'Remove Waive on Bidder Deposit'
                : 'Waive Bidder Deposit'
            }
            buttonOnClick={handleWaiveDeposit}
            handleClose={toggleWaiveDepositModal}
            content={
              deferredDepositStatus
                ? 'Performing this action will remove the waive on bidder deposit for this contact.'
                : 'Performing this action will allow this contact to bid / offer / purchase an equipment without submitting a bidder deposit.'
            }
          />
        </ModalComponent>
      )}
    </React.Fragment>
  )
}

export default ContactDetails
