import classnames from 'classnames'
import React from 'react'

interface Props {
  children: React.ReactNode
  className?: string
  style?: React.CSSProperties
  preventInteraction?: boolean
  scrollBehavior?: 'smooth' | 'auto'
  showOption?: boolean
}

const AutoScroll = ({
  children,
  className,
  style,
  scrollBehavior = 'smooth',
  showOption = true
}: Props) => {
  const [autoScroll, setAutoScroll] = React.useState(true)
  const containerElement = React.useRef<HTMLDivElement>(null)
  const cls = classnames('scroll', className, {
    [`scroll--empty`]: React.Children.count(children) === 0,
    [`scroll--showOption`]: showOption
  })
  const componentStyle = {
    ...style
  } as const

  const onWheel = () => {
    const {current} = containerElement

    if (current && showOption) {
      setAutoScroll(
        current.scrollTop + current.offsetHeight === current.scrollHeight
      )
    }
  }

  React.useEffect(() => {
    setTimeout(() => {
      const {current} = containerElement

      if (current) {
        current.style.scrollBehavior = scrollBehavior
      }
    }, 0)
  }, [containerElement, scrollBehavior])

  React.useEffect(() => {
    if (!autoScroll) {
      return
    }

    const {current} = containerElement

    if (current) {
      current.scrollTop = current.scrollHeight
    }
  }, [children, containerElement, autoScroll])

  return (
    <div className={cls}>
      <div
        className={`scroll__scroll-container`}
        onWheel={onWheel}
        ref={containerElement}
        style={componentStyle}
      >
        {children}
      </div>
    </div>
  )
}

export default AutoScroll
