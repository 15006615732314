import {FILE_OBJECT} from 'common'
import {useEffect, useRef, useState} from 'react'
import useDrivePicker from 'react-google-drive-picker'
import {toast} from 'react-toastify'
import styles from './DrivePicker.module.scss'
import {Environment} from 'common/enums'

const DrivePicker = ({
  addFileToArr
}: {
  addFileToArr: React.Dispatch<FILE_OBJECT>
}) => {
  const tokenRef: any = useRef()
  const SCOPES = ['https://www.googleapis.com/auth/drive']
  const [openPicker, data] = useDrivePicker()
  const [token, setToken] = useState('')

  useEffect(() => {
    // do anything with the selected/uploaded files
    if (data) {
      setToken(data.access_token)
      tokenRef.current = data.access_token
    }
  }, [data])

  const changePermissions = async (authToken: string, fileID: string) => {
    try {
      await fetch(
        `https://www.googleapis.com/drive/v3/files/${fileID}/permissions`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`
          },
          method: 'POST',
          body: JSON.stringify({
            role: 'reader',
            type: 'anyone'
          })
        }
      )
    } catch (e) {
      toast.error(`Something went wrong!`)
    }
  }

  const getThumbnail = async (authToken: string, fileID: string) => {
    try {
      const response = await fetch(
        `https://www.googleapis.com/drive/v3/files/${fileID}?fields=thumbnailLink`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`
          },
          method: 'GET'
        }
      )
      const thumbnail = await response.json()
      return thumbnail?.thumbnailLink as string
    } catch (e) {
      toast.error(`Something went wrong!`)
    }
  }

  const environment =
    (process.env.REACT_APP_PUBLIC_APPLICATION_ENVIRONMENT as Environment) ||
    Environment.DEVELOPMENT

  const key = {
    [Environment.DEVELOPMENT]: {
      apiKey: process.env.REACT_APP_PUBLIC_DEVELOPMENT_API_KEY || '',
      clientId: process.env.REACT_APP_PUBLIC_DEVELOPMENT_DRIVE_CLIENT_ID || ''
    },
    [Environment.STAGING]: {
      apiKey: process.env.REACT_APP_PUBLIC_STAGING_API_KEY || '',
      clientId: process.env.REACT_APP_PUBLIC_STAGING_DRIVE_CLIENT_ID || ''
    },
    [Environment.PRODUCTION]: {
      apiKey: process.env.REACT_APP_PUBLIC_PRODUCTION_API_KEY || '',
      clientId: process.env.REACT_APP_PUBLIC_PRODUCTION_DRIVE_CLIENT_ID || ''
    }
  }[environment]

  const handleOpenPicker = () => {
    openPicker({
      clientId: key.clientId,
      developerKey: key.apiKey,
      viewId: 'DOCS',
      token: token,
      showUploadView: true,
      showUploadFolders: true,
      supportDrives: true,
      callbackFunction: async (fileData) => {
        if (fileData.action === 'picked') {
          const URL = fileData.docs[0].url
          const type = fileData.docs[0].type
          const name = fileData.docs[0].name
          const mbSize = fileData.docs[0].sizeBytes

          if (fileData.docs[0].type !== 'video') {
            toast.error('You can only upload videos through Google Drive.')
            return
          }

          const thumbnail = await getThumbnail(
            tokenRef.current,
            fileData.docs[0].id
          )

          addFileToArr({
            URL: URL,
            downloadURL: URL,
            hasThumbnail: Boolean(thumbnail),
            thumbnail,
            type,
            name,
            mbSize,
            uploadType: 'google-drive'
          })

          changePermissions(tokenRef.current, fileData.docs[0].id)
        }
      },
      multiselect: false,
      customScopes: SCOPES
    })
  }

  return (
    <button type="button" className={styles.button} onClick={handleOpenPicker}>
      Upload Video from Google Drive
    </button>
  )
}

export default DrivePicker
