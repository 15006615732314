import {useState} from 'react'
import Button from '../Button'
import styles from './SyncedPage.module.scss'
import {useFirebase} from '../Firebase/hooks'
import withAuthorization from '../Session/withAuthorization'
import Layout from '../Layout'
import NavBar from '../Navbar'
import {Loader} from 'components/Loader'
import {toast} from 'react-toastify'

interface SyncedProps {
  customClass: string
}

const SyncedPage = ({customClass}: SyncedProps) => {
  const [loader, setLoader] = useState<boolean>(false)
  const {
    handleSyncFirebaseToZohoAccountAPI,
    handleSyncZohoToFirebaseAccountAPI,
    handleSyncFirebaseToZohoProductAPI,
    handleSyncZohoToFirebaseWebsiteAccountAPI,
    handleSyncZohoToFirebaseProductAPI
  } = useFirebase()

  const handleSyncFirebaseToZohoAccount = async () => {
    setLoader(true)
    const {error} = await handleSyncFirebaseToZohoAccountAPI()
    setLoader(false)
    if (error) {
      toast.error('Something went wrong!')
    } else {
      toast.success('Successfully Synced Firebase to Zoho Account!')
    }
  }

  const handleSyncZohoToFirebaseAccount = async () => {
    setLoader(true)
    const {error} = await handleSyncZohoToFirebaseAccountAPI()
    setLoader(false)
    if (error) {
      toast.error('Something went wrong!')
    } else {
      toast.success('Successfully Synced Zoho to Firebase Account!')
    }
  }

  const handleSyncFirebaseToZohoProduct = async () => {
    setLoader(true)
    const {error} = await handleSyncFirebaseToZohoProductAPI()
    setLoader(false)
    if (error) {
      toast.error('Something went wrong!')
    } else {
      toast.success('Successfully Synced Firebase to Zoho Product!')
    }
  }
  const handleSyncZohoToFirebaseProduct = async () => {
    setLoader(true)
    const {error} = await handleSyncZohoToFirebaseProductAPI()
    setLoader(false)
    if (error) {
      toast.error('Something went wrong!')
    } else {
      toast.success('Successfully Synced Zoho to Firebase Product!')
    }
  }

  const handleSyncZohoToFirebaseWebsiteAccount = async () => {
    setLoader(true)
    const {error} = await handleSyncZohoToFirebaseWebsiteAccountAPI()
    setLoader(false)
    if (error) {
      toast.error('Something went wrong!')
    } else {
      toast.success('Successfully Synced Zoho to Firebase Website Account!')
    }
  }

  return (
    <div className={styles.withLayout}>
      <Layout />
      <div className={[styles.container, customClass].join(' ')}>
        <NavBar title="Sync" />
        {loader ? (
          <Loader />
        ) : (
          <div className={styles.syncContainer}>
            <div className={styles.headingBox}>
              <div className={styles.headingContainer}>
                Sync Accounts & Contacts
              </div>
              <div className={styles.buttonContainer}>
                <Button
                  onClick={handleSyncFirebaseToZohoAccount}
                  text="From Firebase to Zoho"
                  customClass={styles.syncBtn}
                />
                <Button
                  onClick={handleSyncZohoToFirebaseAccount}
                  text="From Zoho to Firebase"
                  customClass={styles.syncBtn}
                />
              </div>
            </div>
            <div className={styles.headingBox}>
              <div className={styles.headingContainer}>
                Sync For Website Accounts & Contacts
              </div>
              <div className={styles.buttonContainer}>
                <Button
                  onClick={handleSyncZohoToFirebaseWebsiteAccount}
                  text="From Zoho to Firebase"
                  customClass={styles.syncBtn}
                />
              </div>
            </div>
            <div className={styles.headingBox}>
              <div className={styles.headingContainer}>Sync Products</div>
              <div className={styles.buttonContainer}>
                <Button
                  onClick={handleSyncFirebaseToZohoProduct}
                  text="From Firebase to Zoho"
                  customClass={styles.syncBtn}
                />
                <Button
                  onClick={handleSyncZohoToFirebaseProduct}
                  text="From Zoho to Firebase (One Time)"
                  customClass={styles.syncBtn}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default withAuthorization(SyncedPage)
