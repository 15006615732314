import * as Yup from 'yup'

export const CreateCategorySchema = Yup.object({
  name: Yup.string().trim().required('Required'),
  slug: Yup.string().matches(
    /^[a-z0-9]+(?:-[a-z0-9]+)*$/,
    'This should be a URL-friendly (lowercase letters, numbers, and hyphens only)'
  ),
  imageUrl: Yup.array().min(1, 'Required'),
  isPopularCategory: Yup.boolean()
})
