import React, {useState, useEffect} from 'react'
import {ArrowDown} from '../../assets/svgs'
import styles from './AgreementDetailPage.module.scss'
import {useLocation, useNavigate} from 'react-router-dom'
import withAuthorization from '../Session/withAuthorization'
import NavBar from '../Navbar'
import Layout from '../Layout'
import {useAppContext, useFirebase} from '../Firebase/hooks'
import {toast} from 'react-toastify'
import {Loader} from '../Loader'
import DetailPage from '../DetailPage'
import {Collection} from 'common/enums'
import {DetailType} from 'components/enums'
interface AgreementDetailPageProps {
  customClass: string
}

const AgreementDetailPage = ({customClass}: AgreementDetailPageProps) => {
  const navigate = useNavigate()
  const {state, pathname} = useLocation()
  const agreementId = state?.id ? state?.id : ''
  const isEdit = state?.isEdit ? state?.isEdit : false
  const previousPage = state?.currentPage
  const {getDocumentData} = useFirebase()
  const isLAPage = pathname === '/listing-agreement/agreement-details' || false
  const isMSAPage = pathname === '/msa-agreement/agreement-details' || false
  const isACHPage = pathname === '/ach-agreement/agreement-details' || false

  const [loader, setLoader] = useState(isEdit ? true : false)
  const agreementCollectionName = isLAPage
    ? Collection.ZohoSignListingAgreement
    : isMSAPage
    ? Collection.ZohoSignGeneralMsaAgreement
    : isACHPage
    ? Collection.AchAuthorization
    : ''
  const [applicationState, setApplicationState] = useState<any>({
    documentData: {}
  })

  const {appState} = useAppContext()
  const isSuperAdmin = appState?.isSuperAdmin || false
  const disabled = isEdit ? true : false

  const cancelCallback = (error: Error) => {
    if (error) {
      toast.error(`Something went wrong while fetching agreements.`)
    }
  }

  useEffect(() => {
    if (disabled && isEdit) {
      const {unsSubData} = getDocumentData(
        setApplicationState,
        cancelCallback,
        agreementId,
        agreementCollectionName
      )
      return () => {
        unsSubData?.()
      }
    }
  }, [disabled])

  useEffect(() => {
    if (isSuperAdmin) {
      if (isEdit) {
        setTimeout(() => {
          setLoader(false)
        }, 2000)
      }
    } else {
      navigate('/')
    }
  }, [isSuperAdmin])

  return (
    <div className={styles.withLayout}>
      <Layout />
      <div className={[styles.container, customClass].join(' ')}>
        <NavBar
          title={
            isLAPage
              ? 'Listing Agreement Details'
              : isMSAPage
              ? 'MSA Agreement Details'
              : isACHPage
              ? ' ACH Agreement Details'
              : '-'
          }
        />
        {loader ? (
          <Loader />
        ) : (
          <React.Fragment>
            <div className={styles.buttons}>
              <span
                onClick={() =>
                  navigate(
                    isLAPage
                      ? '/listing-agreement'
                      : isMSAPage
                      ? '/msa-agreement'
                      : isACHPage
                      ? '/ach-agreement'
                      : '/',
                    {
                      state: {page: previousPage}
                    }
                  )
                }
              >
                <ArrowDown />
                Back
              </span>
            </div>

            <div className={styles.edit_section}>
              <DetailPage
                data={applicationState.documentData}
                detailType={DetailType.Agreement}
              />
            </div>
          </React.Fragment>
        )}
      </div>
    </div>
  )
}

export default withAuthorization(AgreementDetailPage)
