import {Map} from 'common'
import {SellerNotifications, sendNotification} from 'common/apis/notifications'
import {PRODUCT_STATUS_FIELDS} from 'common/constants'
import {useFirebase} from 'components/Firebase/hooks'
import {API_HOST_URL} from 'components/constants'
import _ from 'lodash'
import React, {useEffect, useState} from 'react'
import {toast} from 'react-toastify'
import {Cancel} from '../../../assets/svgs'
import Button from '../../Button'
import styles from '../RequestChangesModal/RequestChangesModal.module.scss'

interface RequestedChangesSubmitModalProps {
  handleClose: () => void
  updatedRequestedChanges: Map
  currentRequestedChanges: Map
  productId: string
  contactId: string
}

interface IModifiedRFC {
  [key: string]: {
    value: string
    isRemoved: boolean
  }
}

const RequestedChangesSubmitModal = ({
  handleClose,
  updatedRequestedChanges,
  currentRequestedChanges,
  productId,
  contactId
}: RequestedChangesSubmitModalProps) => {
  const [requestedChanges, setRequestedChanges] = useState<
    IModifiedRFC | undefined
  >()
  const [disabled, setDisabled] = useState(false)
  const {updateRequestedChanges} = useFirebase()

  const getRequestedChanges = () => {
    const current = _.cloneDeep(currentRequestedChanges)
    const updated = _.cloneDeep(updatedRequestedChanges)

    let result: IModifiedRFC = {}
    _.forEach(_.keys(current), function (key) {
      if (_.includes(_.keys(updated), key)) {
        _.assign(result, {
          [key]: {
            value: updated[key],
            isRemoved: false
          }
        })
      } else {
        _.assign(result, {
          [key]: {
            value: current[key],
            isRemoved: true
          }
        })
      }
    })

    _.forEach(_.keys(updated), function (key) {
      if (!_.includes(_.keys(result), key)) {
        _.assign(result, {
          [key]: {
            value: updated[key],
            isRemoved: false
          }
        })
      }
    })

    return result
  }

  useEffect(() => {
    setRequestedChanges(getRequestedChanges())
  }, [])

  const handleSubmit = async () => {
    setDisabled(true)
    const {success, error} = await updateRequestedChanges(
      updatedRequestedChanges,
      productId
    )
    if (success) {
      toast.success('Request For Changes Submitted Successfully!')
      sendNotification({
        baseURL: API_HOST_URL,
        productId,
        contactId: contactId,
        code: SellerNotifications.ProductRFC
      })
      handleClose()
      setDisabled(false)
    } else if (error) {
      toast.error('Something Went Wrong')
      setDisabled(false)
      return
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.cancelContainer}>
        <Cancel className={styles.cancel} onClick={handleClose} />
      </div>
      <div className={styles.content}>
        <div className={styles.modalContent}>
          <div className={styles.headingContainer}>Review RFC(s)</div>
          <div className={styles.textAreaContainer}>
            {requestedChanges
              ? Object.entries(requestedChanges).map((rfc, index) => {
                  const [label, {value, isRemoved}] = rfc
                  return (
                    <React.Fragment key={`rfc-item-${index}`}>
                      <p className={styles.orange}>
                        <span className={styles.rfcItemHeading}>
                          {PRODUCT_STATUS_FIELDS[label]}:
                        </span>{' '}
                        <b
                          style={{
                            textDecoration: isRemoved
                              ? 'line-through'
                              : 'unset',
                            color: isRemoved ? 'red' : 'black'
                          }}
                        >
                          {value}
                        </b>
                      </p>
                    </React.Fragment>
                  )
                })
              : null}
          </div>
          <div className={styles.buttonsComponent}>
            <Button
              customClass={styles.buttonClass}
              text="Submit"
              disabled={disabled}
              onClick={handleSubmit}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default RequestedChangesSubmitModal
