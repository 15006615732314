import classNames from 'classnames'
import {
  AccountDocument,
  AdminDocument,
  ContactDocument,
  PersonalDetail
} from 'common'
import {Collection} from 'common/enums'
import AlgoliaClient, {
  CONTACTS_ADMIN_PANEL_ALGOLIA_INDEX
} from 'components/AlgoliaSearch'
import ButtonDropDown from 'components/ButtonDropDown'
import ConfirmationModal from 'components/ConfirmationModal'
import ModalComponent from 'components/ModalComponent'
import SelectConsigneeModal from 'components/SelectConsigneeModal'
import {DetailType, signMethod} from 'components/enums'
import {IFormattedContact} from 'components/types'
import {Formik, FormikProps} from 'formik'
import {get, isEqual, isNull, omit} from 'lodash'
import React, {useEffect, useRef, useState} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {toast} from 'react-toastify'
import {CheckedIcon, UncheckedIcon} from '../../assets/images'
import {ArrowDown, Building} from '../../assets/svgs'
import Button from '../Button'
import DetailPage from '../DetailPage'
import DropDownSelect from '../DropDown'
import {useAppContext, useFirebase} from '../Firebase/hooks'
import InputField from '../InputField'
import Layout from '../Layout'
import {Loader} from '../Loader'
import NavBar from '../Navbar'
import PhoneInputSelect from '../PhoneInput'
import withAuthorization from '../Session/withAuthorization'
import {
  getFormattedUser,
  getUserFullName,
  sortByName,
  trimStringFields
} from '../utils'
import styles from './CreateNewContact.module.scss'
import {CreateContactSchema, INIITAL_CONTACT_STATE} from './schema'

interface CreateNewContactProps {
  customClass: string
}

const CreateNewContact = ({customClass}: CreateNewContactProps) => {
  const navigate = useNavigate()
  const {
    appState: {isSuperAdmin, isSuperManager}
  } = useAppContext()
  const [adminsList, setAdminsList] = useState<
    Array<{id: string; name: string; email: string}>
  >([])
  const [hasProducts, setHasProducts] = useState<boolean>(false)
  const [hasCheckedDocuments, setHasCheckedDocuments] = useState<boolean>(false)
  const [show, setShow] = useState<boolean>(false)
  const toggleShow = () => setShow(!show)
  const {
    getDocumentData,
    getCollectionData,
    deleteContact,
    createContact,
    editContact,
    getCollectionBasedOnQuery,
    getUserPersonalDetail
  } = useFirebase()
  const formRef: React.Ref<FormikProps<IFormattedContact>> | undefined =
    useRef(null)
  const [optionsList, setOptionsList] = useState<
    Array<{id: string; name: string}>
  >([])
  const [formData, setFormData] = useState<IFormattedContact | null>(null)
  const {state} = useLocation()
  const accountFromState = state?.company
  const objectID = state?.objectID
  const contactID = state?.id ? state?.id : ''
  const isEdit = state?.isEdit ? state?.isEdit : false
  const previousPage = state?.currentPage
  const showSendACH = (isEdit && (isSuperAdmin || isSuperManager)) || false
  const [loader, setLoader] = useState(isEdit ? true : false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [disabled, setDisabled] = useState(isEdit ? true : false)
  const [personalDetails, setPersonalDetails] = useState<PersonalDetail | null>(
    null
  )
  const [appState, setAppState] = useState<{
    documentData: ContactDocument | null
  }>({
    documentData: null
  })
  const [showConsigneeModal, setShowConsigneeModal] = useState<{
    method: signMethod | undefined
    show: boolean
  }>({
    method: undefined,
    show: false
  })
  const isContactDetails = isEdit && disabled
  const disableACH = appState.documentData?.isAchAuthorize
  const initialState = isNull(appState.documentData)
    ? INIITAL_CONTACT_STATE
    : getFormattedUser(appState.documentData)

  const detailPageData =
    appState.documentData && getFormattedUser(appState.documentData, true)

  const getConsigneeData = async () => {
    !loader && setLoader(true)
    const response = await getCollectionBasedOnQuery<AdminDocument>(
      Collection.Admins,
      'role',
      'superAdmin'
    )
    const consigneeData = response?.data ? response.data : []
    const adminData = consigneeData.map((admin) => {
      return {
        id: admin.userId,
        name: getUserFullName(admin?.firstName || '', admin?.lastName || ''),
        email: admin.contactInfo.emailAddress
      }
    })
    setAdminsList(adminData)
    setLoader(false)
  }

  useEffect(() => {
    if (!isSuperAdmin) return
    if (!isContactDetails || !appState.documentData?.contactId) return
    const {unSubscribe} = getUserPersonalDetail({
      userId: appState.documentData?.contactId,
      updateState: setPersonalDetails,
      cancelCallback: (_error) =>
        toast.warn('Something went wrong! Failed to get personal details')
    })
    return () => unSubscribe?.()
  }, [isContactDetails, appState.documentData?.contactId])

  const toggleShowZohoSignModal = () =>
    setShowConsigneeModal({
      method: signMethod.Zoho,
      show: true
    })

  const handleCloseConsigneeModal = () =>
    setShowConsigneeModal({method: undefined, show: false})

  const cancelCallback = (error: Error) => {
    if (error) {
      toast.error(`Something went wrong while fetching contact document.`)
    }
  }

  const getAccounts = async () => {
    const {data = [], error} = await getCollectionData(Collection.Accounts)
    if (error) {
      toast.error('Something went wrong while fetching accounts.')
      return
    }
    const dropdownOptions = data
      .map((account: AccountDocument) => {
        const accountName = account?.accountName || ''
        return {
          id: account?.accountId,
          name: accountName
        }
      })
      .sort(sortByName)
    dropdownOptions.sort(sortByName)
    setOptionsList([...dropdownOptions])
  }

  useEffect(() => {
    getAccounts()
    if (isEdit) {
      if (isSuperAdmin || isSuperManager) getConsigneeData()
      setTimeout(() => {
        setLoader(false)
      }, 2000)
    }
  }, [])

  useEffect(() => {
    if (disabled && isEdit) {
      const {unsSubData} = getDocumentData(
        setAppState,
        cancelCallback,
        contactID,
        Collection.Contacts
      )

      return () => {
        unsSubData?.()
      }
    }
  }, [disabled])

  useEffect(() => {
    const {documentData} = appState
    if (documentData && !disabled) {
      const userObject = getFormattedUser(documentData)
      setFormData({...userObject})
      formRef?.current?.setValues({...userObject})
    }
    if (accountFromState) {
      formRef.current?.setFieldValue('accountName', accountFromState?.name)
      formRef.current?.setFieldValue('accountId', accountFromState?.id)
    }
  }, [appState, formRef, disabled, accountFromState, optionsList.length])

  const handleDeletion = async () => {
    if (hasProducts) {
      toast.error('This contact has equipment made against it')
      return
    } else {
      toggleShow()
      setLoader(true)
      const searchIndex = AlgoliaClient.initIndex(
        CONTACTS_ADMIN_PANEL_ALGOLIA_INDEX
      )
      await searchIndex.deleteObject(objectID)
      const {message, error} = await deleteContact(contactID)
      setLoader(false)
      if (error) {
        toast.warning(error)
        return
      } else {
        toast.success(message)
        navigate('/contacts', {
          state: {page: previousPage}
        })
        return
      }
    }
  }

  const handleSubmit = async (values: IFormattedContact) => {
    const isObjectSame = isEqual(values, formData)
    if (isEdit && isObjectSame) {
      toast.warning('Please make some changes')
      return
    }
    setIsSubmitting(true)
    if (isSubmitting) return
    const {accountId, accountName, newsLetter, ...restContactValues} = values
    const contactObject = {
      ...omit(restContactValues, 'latestTermsAccepted', 'intialTermsAccepted'),
      accountId,
      accountName,
      newsLetter,
      photoURL: ''
    }
    const {message, error} = isEdit
      ? await editContact(trimStringFields(contactObject), contactID)
      : await createContact(trimStringFields(contactObject))
    if (error) {
      toast.warning(error)
      setIsSubmitting(false)
    } else {
      setIsSubmitting(false)
      toast.success(message)
      navigate('/contacts', {
        state: {page: previousPage}
      })
    }
  }

  const navTitle = isContactDetails
    ? 'View Contact'
    : isEdit
    ? 'Edit Contact'
    : 'Create New Contact'

  return (
    <React.Fragment>
      <div className={styles.withLayout}>
        <Layout />
        <div className={classNames(styles.container, customClass)}>
          <NavBar title={navTitle} />
          {loader ? (
            <Loader />
          ) : (
            <React.Fragment>
              <div className={styles.buttons}>
                <span
                  onClick={() =>
                    navigate('/contacts', {
                      state: {page: previousPage}
                    })
                  }
                >
                  <ArrowDown />
                  Back
                </span>
                <div className={styles.twobtn}>
                  {showSendACH && (
                    <ButtonDropDown
                      buttonText="Send ACH Signing Agreement"
                      customButtonStlyes={(styles.changes_btn, styles.LAbtn)}
                      customOptionsContainerStyles={styles.dropdownOptions}
                      selectOptions={[
                        {
                          onClick: toggleShowZohoSignModal,
                          option: 'Send Agreement Through Zoho Sign'
                        }
                      ]}
                      disabled={disableACH}
                    />
                  )}
                  {isEdit && (
                    <Button
                      text="Delete"
                      customClass={styles.changes_btn}
                      onClick={() => {
                        toggleShow()
                      }}
                    />
                  )}
                  {disabled && (
                    <Button
                      text="Edit"
                      customClass={styles.changes_btn}
                      onClick={() => setDisabled(false)}
                    />
                  )}
                  {(!isEdit || !disabled) && (
                    <Button
                      text={'Save'}
                      customClass={styles.changes_btn}
                      onClick={() => formRef.current?.handleSubmit()}
                      disabled={isSubmitting}
                    />
                  )}
                </div>
              </div>
              <div className={styles.edit_section}>
                {isContactDetails ? (
                  <React.Fragment>
                    <h1 className={styles.secondheading}>Contact Details</h1>
                    <DetailPage
                      data={{...detailPageData, personalDetails}}
                      detailType={DetailType.Contact}
                    />
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <h1 className={styles.secondheading}>
                      Contact Information
                    </h1>
                    <Formik
                      innerRef={formRef}
                      initialValues={initialState}
                      onSubmit={handleSubmit}
                      validationSchema={CreateContactSchema}
                    >
                      {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        setFieldValue,
                        setFieldError,
                        setFieldTouched
                      }) => (
                        <form
                          className={styles.from_submiting}
                          onSubmit={handleSubmit}
                        >
                          <div className={styles.formContainer}>
                            <div className={styles.inputs}>
                              <div className={styles.inputContainer}>
                                <div className={styles.inputField}>
                                  <InputField
                                    name="firstName"
                                    placeholder="First Name"
                                    value={get(values, 'firstName')}
                                    onChange={handleChange}
                                    errors={errors}
                                    touched={touched}
                                    onBlur={handleBlur}
                                    type="text"
                                    disabled={disabled}
                                  />
                                </div>
                                <div className={styles.inputField}>
                                  <InputField
                                    name="lastName"
                                    placeholder="Last Name"
                                    value={get(values, 'lastName')}
                                    onChange={handleChange}
                                    errors={errors}
                                    touched={touched}
                                    onBlur={handleBlur}
                                    type="text"
                                    disabled={disabled}
                                  />
                                </div>
                              </div>
                              <div className={styles.inputContainer}>
                                <div className={styles.inputField}>
                                  <div className={styles.dropdown}>
                                    <div>
                                      <DropDownSelect
                                        firstIcon={<Building />}
                                        options={optionsList}
                                        customClass={classNames(
                                          styles.dropdowninput,
                                          styles.dropdownblock
                                        )}
                                        placeholder="Account"
                                        createNew={{
                                          currentPath:
                                            '/contacts/single-contact-details',
                                          pathTo:
                                            '/accounts/single-account-details',
                                          title: 'Account',
                                          id: contactID
                                        }}
                                        value={optionsList.findIndex(
                                          (option) =>
                                            option.id ===
                                            get(values, 'accountId')
                                        )}
                                        cancelClickFunction={() => {
                                          setFieldValue('accountId', '')
                                          setFieldValue('accountName', '')
                                          setFieldTouched('accountId', true)
                                        }}
                                        onChange={(index) => {
                                          const currentOption =
                                            optionsList[index]
                                          setFieldValue(
                                            'accountId',
                                            currentOption.id
                                          )
                                          setFieldValue(
                                            'accountName',
                                            currentOption.name
                                          )
                                          setFieldError('accountId', '')
                                          setFieldTouched('accountId', false)
                                        }}
                                        errors={errors}
                                        touched={touched}
                                        disabled={disabled}
                                        onBlur={handleBlur}
                                        name="accountId"
                                      />
                                      <div
                                        className={classNames(
                                          styles.disblock,
                                          styles.disnone
                                        )}
                                      >
                                        ____
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className={styles.inputField}></div>
                              </div>
                            </div>

                            <div className={styles.headingContainer}>
                              <div className={styles.formHeading}>
                                User Info
                              </div>
                              <div className={styles.inputs}>
                                <div className={styles.inputContainer}>
                                  <div className={styles.inputField}>
                                    <InputField
                                      name="title"
                                      placeholder="Title"
                                      value={get(values, 'title')}
                                      onChange={handleChange}
                                      errors={errors}
                                      touched={touched}
                                      onBlur={handleBlur}
                                      type="text"
                                      disabled={disabled}
                                    />
                                  </div>
                                  <div className={styles.inputField}>
                                    <InputField
                                      name="emailAddress"
                                      placeholder="Email"
                                      value={get(values, 'emailAddress')}
                                      onChange={handleChange}
                                      errors={errors}
                                      touched={touched}
                                      onBlur={handleBlur}
                                      type="text"
                                      disabled={disabled}
                                    />
                                  </div>
                                </div>
                                <div className={styles.inputContainer}>
                                  <div className={styles.inputField}>
                                    <PhoneInputSelect
                                      placeholder="Mobile Number"
                                      name="mobileNumber"
                                      value={get(values, 'mobileNumber')}
                                      setFieldValue={setFieldValue}
                                      errors={errors}
                                      touched={touched}
                                      onBlur={handleBlur}
                                      type="text"
                                      disabled={disabled}
                                      id="mobileNumber"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className={styles.withCheckbox}>
                              <div className={styles.containerCheck}>
                                <div className={styles.formHeading}>
                                  Is Primary Contact
                                </div>
                                <div className={styles.inputCheckbox}>
                                  <div className={styles.inputField}>
                                    <InputField
                                      name="isPrimaryContactYes"
                                      placeholder="Yes"
                                      inputFieldIcon={
                                        get(values, 'isPrimaryContact')
                                          ? CheckedIcon
                                          : UncheckedIcon
                                      }
                                      iconClick={() => {
                                        setFieldValue('isPrimaryContact', true)
                                      }}
                                    />
                                  </div>
                                  <div className={styles.inputField}>
                                    <InputField
                                      name="isPrimaryContactNo"
                                      placeholder="No"
                                      inputFieldIcon={
                                        !get(values, 'isPrimaryContact')
                                          ? CheckedIcon
                                          : UncheckedIcon
                                      }
                                      iconClick={() => {
                                        setFieldValue('isPrimaryContact', false)
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className={styles.containerCheck}>
                                <div className={styles.formHeading}></div>
                                <div className={styles.inputCheckbox}>
                                  <div className={styles.inputField}></div>
                                  <div className={styles.inputField}></div>
                                </div>
                              </div>
                            </div>

                            <div className={styles.withCheckbox}>
                              <div className={styles.containerCheck}>
                                <div className={styles.formHeading}>
                                  Receive Newsletter
                                </div>
                                <div className={styles.inputCheckbox}>
                                  <div className={styles.inputField}>
                                    <InputField
                                      name="newsletterYes"
                                      placeholder="Yes"
                                      inputFieldIcon={
                                        get(values, 'newsLetter')
                                          ? CheckedIcon
                                          : UncheckedIcon
                                      }
                                      iconClick={() => {
                                        setFieldValue('newsLetter', true)
                                      }}
                                    />
                                  </div>
                                  <div className={styles.inputField}>
                                    <InputField
                                      name="newsletterNo"
                                      placeholder="No"
                                      inputFieldIcon={
                                        !get(values, 'newsLetter')
                                          ? CheckedIcon
                                          : UncheckedIcon
                                      }
                                      iconClick={() => {
                                        setFieldValue('newsLetter', false)
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className={styles.containerCheck}>
                                <div className={styles.formHeading}>
                                  Text Messages
                                </div>
                                <div className={styles.inputCheckbox}>
                                  <div className={styles.inputField}>
                                    <InputField
                                      name="textMessageYes"
                                      placeholder="Yes"
                                      inputFieldIcon={
                                        get(values, 'textMessage')
                                          ? CheckedIcon
                                          : UncheckedIcon
                                      }
                                      iconClick={() => {
                                        setFieldValue('textMessage', true)
                                      }}
                                    />
                                  </div>
                                  <div className={styles.inputField}>
                                    <InputField
                                      name="textMessageNo"
                                      placeholder="No"
                                      inputFieldIcon={
                                        !get(values, 'textMessage')
                                          ? CheckedIcon
                                          : UncheckedIcon
                                      }
                                      iconClick={() => {
                                        setFieldValue('textMessage', false)
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      )}
                    </Formik>
                  </React.Fragment>
                )}
              </div>
            </React.Fragment>
          )}
        </div>
      </div>
      {show && (
        <ModalComponent handleClose={toggleShow} show={show}>
          <ConfirmationModal
            removeIcon
            isDelete={true}
            isContact={true}
            documentId={contactID}
            text={`Delete Contact`}
            handleClose={toggleShow}
            setHasDocuments={setHasProducts}
            handleDeleteButton={handleDeletion}
            hasCheckedDocuments={hasCheckedDocuments}
            setHasCheckedDocuments={setHasCheckedDocuments}
            content={
              'You cannot undo these changes. Are you sure you want to delete this contact?'
            }
          />
        </ModalComponent>
      )}
      {showConsigneeModal.show && (
        <ModalComponent
          show={showConsigneeModal.show}
          handleClose={handleCloseConsigneeModal}
        >
          <SelectConsigneeModal
            handleClose={handleCloseConsigneeModal}
            sign={showConsigneeModal.method}
            adminsList={adminsList}
            contactID={contactID}
            isACH={true}
          />
        </ModalComponent>
      )}
      {show && (
        <ModalComponent handleClose={toggleShow} show={show}>
          <ConfirmationModal
            handleClose={toggleShow}
            text={`Delete Contact`}
            content={
              'You cannot undo these changes. Are you sure you want to delete this contact?'
            }
            handleDeleteButton={handleDeletion}
            isDelete={true}
            isContact={true}
            documentId={contactID}
            setHasDocuments={setHasProducts}
            hasCheckedDocuments={hasCheckedDocuments}
            setHasCheckedDocuments={setHasCheckedDocuments}
          />
        </ModalComponent>
      )}
    </React.Fragment>
  )
}

export default withAuthorization(CreateNewContact)
