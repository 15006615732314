import React, {useEffect} from 'react'
import {DocumentData, QueryDocumentSnapshot} from 'firebase/firestore'
import {useFirebase, useSession} from 'components/Firebase/hooks'
import {createContext, useState} from 'react'
import localforage from 'localforage'
import {adminRole} from 'common/enums'
import {AdminDocument} from 'common'

export interface IAppContext {
  appState: {
    role?: adminRole
    isMSAAdmin: boolean
    isAuthorized: boolean
    isSuperAdmin: boolean
    isSuperManager: boolean
    adminDetails?: AdminDocument
    accountsList?: Array<string>
    hasMoreNotifications: boolean
    notifications?: Array<DocumentData>
    lastVisibleNotification?: QueryDocumentSnapshot<DocumentData>
  }
  newAppState: (_e: {
    isMSAAdmin?: boolean
    isAuthorized?: boolean
    isSuperAdmin?: boolean
    isSuperManager?: boolean
    hasMoreNotifications?: boolean
  }) => void
  removeFcmAndSignOut: () => void
  setFcmToken: () => void
}

const AppContextInitialState: IAppContext = {
  appState: {
    isMSAAdmin: false,
    notifications: [],
    isAuthorized: false,
    isSuperAdmin: false,
    isSuperManager: false,
    hasMoreNotifications: false,
    lastVisibleNotification: undefined
  },
  newAppState: (_e: {
    isMSAAdmin?: boolean
    isAuthorized?: boolean
    isSuperAdmin?: boolean
    isSuperManager?: boolean
    hasMoreNotifications?: boolean
  }) => {},
  removeFcmAndSignOut: () => {},
  setFcmToken: () => {}
}

const AppContext = createContext(AppContextInitialState)

const AppStateProvider = ({children}: {children: React.ReactNode}) => {
  const [appState, setAppState] = useState({...AppContextInitialState.appState})
  const {user} = useSession()
  const userId = user?.uid
  const {
    requestNotifactionsPermission,
    signOut,
    removeFcmToken,
    getNotifications,
    getAdminDocument,
    getFCM
  } = useFirebase()
  const userCustomAttributes = (user as any)?.reloadUserInfo?.customAttributes
  const isSuperAdmin =
    (userCustomAttributes && JSON.parse(userCustomAttributes)?.superAdmin) ||
    false
  const isSuperManager =
    (userCustomAttributes && JSON.parse(userCustomAttributes)?.superManager) ||
    false
  const isMSA =
    (userCustomAttributes && JSON.parse(userCustomAttributes)?.accounts) ||
    false
  const role =
    (isSuperAdmin && adminRole.superAdmin) ||
    (isSuperManager && adminRole.superManager) ||
    (isMSA && adminRole.MSA) ||
    ''

  useEffect(() => {
    if (!user) return
    const unSubscribe = getNotifications(setAppState)
    return () => unSubscribe?.()
  }, [userId])

  useEffect(() => {
    if (!userId) return
    setAppState((prev) => ({...prev, role, accountsList: isMSA || []}))
    setFcmToken()
  }, [userId])

  useEffect(() => {
    if (!userId) return
    const {unsubscribe} = getAdminDocument(setAppState)
    return () => unsubscribe?.()
  }, [userId])

  const setFcmToken = async () => {
    const {fcmToken: dbToken} = await getFCM({role})
    const token: string = (await localforage.getItem('fcm_token')) || ''
    requestNotifactionsPermission({role})
    if (!dbToken || token !== dbToken) localforage.removeItem('fcm_token')
  }

  const newAppState = (appState: Partial<IAppContext['appState']>) =>
    setAppState((prev) => ({...prev, ...appState}))

  const removeFcmAndSignOut = () => {
    removeFcmToken({role})
    signOut()
    setAppState({...AppContextInitialState.appState})
  }

  return (
    <AppContext.Provider
      value={{appState, newAppState, removeFcmAndSignOut, setFcmToken}}
    >
      {children}
    </AppContext.Provider>
  )
}

export {AppStateProvider, AppContext}
