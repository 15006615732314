export enum signMethod {
  Zoho = 'Zoho Sign',
  Manual = 'Manual Sign'
}
export enum InvoiceTable {
  BuyerWire = 'buyer-wire',
  SellerWire = 'seller-wire',
  LineItem = 'line-item'
}
export enum DetailType {
  Product = 'product',
  Agreement = 'agreement',
  Finance = 'finance',
  TaxExemption = 'taxExemption',
  Invoice = 'invoice',
  Deals = 'deals',
  Contact = 'contact',
  Category = 'category',
  Sector = 'sector',
  Admin = 'admin',
  BankAccount = 'bankAccount',
  Account = 'account',
  SalesTax = 'salesTax',
  TaxJarCustomer = 'taxJarCustomer',
  EquipmentReports = 'equipmentReports'
}
