import styles from '../MessageSection.module.scss'

interface DateContainerProps {
  date: string
}

const DateContainer = ({date}: DateContainerProps) => {
  return (
    <div className={styles.para_container}>
      <p className={styles.today}>{date}</p>
    </div>
  )
}

export default DateContainer
