import {Subcategory} from 'common'
import styles from './SubCategoriesPage.module.scss'
import React, {Dispatch, SetStateAction, useState} from 'react'
import SubcategoryModal from '../SubcategoryModal'
import ModalComponent from 'components/ModalComponent'
import FileModal from 'components/DetailPage/ProductDetails/FileModal'

const RowData = ({
  setSubcategories,
  isSuperManager,
  subcategories,
  categoryId,
  rowData
}: {
  isSuperManager: boolean
  rowData: Subcategory
  categoryId: string
  subcategories?: Subcategory[]
  setSubcategories?: Dispatch<SetStateAction<Subcategory[]>>
}) => {
  const [showAddModal, setShowAddModal] = useState(false)
  const [selectedImage, setSelectedImage] = React.useState('')

  const handleCloseImageModal = () => setSelectedImage('')
  const handleShowImageModal = (imageUrl?: string) =>
    setSelectedImage(imageUrl || '')

  const handleCloseAddModal = () => setShowAddModal(false)
  const handleShowAddModal = () => {
    if (isSuperManager) return
    setShowAddModal(true)
  }

  const {name, nodeId, imageUrl} = rowData || {}
  return (
    <React.Fragment>
      <tr style={isSuperManager ? {cursor: 'unset'} : undefined}>
        <td onClick={handleShowAddModal}>{nodeId}</td>
        <td onClick={handleShowAddModal}>{name}</td>
        <td onClick={() => handleShowImageModal(imageUrl)}>
          <img
            className={styles.tableImage}
            src={`${imageUrl}?time=${Date.now()}`}
            alt="category-img"
          />
        </td>
      </tr>
      {showAddModal && (
        <SubcategoryModal
          subcategories={subcategories}
          setSubcategories={setSubcategories}
          categoryId={categoryId}
          handleClose={handleCloseAddModal}
          subcategoryId={nodeId}
          show={showAddModal}
          isEdit={true}
          data={rowData}
        />
      )}
      {selectedImage && (
        <ModalComponent
          show={Boolean(selectedImage)}
          handleClose={handleCloseImageModal}
        >
          <FileModal
            handleClose={handleCloseImageModal}
            selectedFile={selectedImage}
            isVideo={false}
          />
        </ModalComponent>
      )}
    </React.Fragment>
  )
}

export default RowData
