import {Eye, InfoIcon} from 'assets/svgs'
import classNames from 'classnames'
import {Bid, ProductDocument} from 'common'
import {PRODUCT_STATUS_FIELDS} from 'common/constants'
import {equipmentOptions, productStatus} from 'common/enums'
import {useFirebase} from 'components/Firebase/hooks'
import RequestedChangesSubmitModal from 'components/ProductDetailPage/RequestedChangesSubmitModal'
import {PRODUCTION_URL} from 'components/constants'
import {isDate, isEmpty, isEqual, isNumber, omit} from 'lodash'
import React, {useEffect, useState} from 'react'
import {toast} from 'react-toastify'
import {CommentIcon} from '../../../assets/images'
import ModalComponent from '../../ModalComponent'
import RequestedChangesModal from '../../ProductDetailPage/RequestChangesModal'
import ViewPDFModal from '../../ViewPDFModal'
import {
  getDaysFromToday,
  getFormattedDate,
  getFormattedProduct,
  parseHtmlToPlainText,
  withThousandSeperatorString
} from '../../utils'
import FieldComponent from './FieldComponent'
import FileModal from './FileModal'
import ProductBidsModal from './ProductBidsModal'
import styles from './ProductDetails.module.scss'

const ProductDetails = (data: ProductDocument & {agreementStatus: string}) => {
  const [isVideoImage, setIsVideoImage] = useState(false)
  const [isVideo, setIsVideo] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [showRFCInfoModal, setShowRFCInfoModal] = useState(false)
  const [showReqModal, setShowReqModal] = useState(false)
  const [showSubmitRFCModal, setShowSubmitRFCModal] = useState(false)
  const [showTotalBids, setShowTotalBids] = useState(false)
  const [selectedFile, setSelectedFile] = useState('')
  const [selectedReqKey, setSelectedReqKey] = useState('')
  const [invoiceURL, setInvoiceURL] = useState('')
  const [bids, setBids] = useState<Array<Bid>>()
  const {
    imagesArray,
    videosArray: videos,
    attachmentsArray,
    requireDismantling,
    willingToHelp,
    toWhatExtent,
    isLien,
    addressObj,
    isAuction,
    createdAtDate,
    lastUpdatedDate,
    auctionScheduledTime,
    lienObject,
    auctionObj,
    endedAtDate,
    autoRenew,
    contractDuration,
    requestedChanges,
    toWhatExtentValue,
    isAtADifferentAddress,
    renewalWindow
  } = getFormattedProduct(data)
  const driveUrls = data.productDetails?.driveUrls || []
  const embedUrls = data.productDetails?.embedUrls || []
  const videosArray = [...videos, ...driveUrls, ...embedUrls]
  const totalBids = bids?.length || 0

  const toggleTotalBidsModal = () =>
    Boolean(totalBids) && setShowTotalBids(!showTotalBids)

  useEffect(() => {
    if (!isAuction) return
    const cancelCallback = (error: Error) => {
      if (error) {
        toast.error(`Something went wrong while fetching bids!`)
      }
    }
    const unSubscribe = getProductBids(data?.productId, cancelCallback, setBids)

    return () => {
      unSubscribe?.()
    }
  }, [])

  useEffect(() => {
    setRequestedChangesObject(requestedChanges)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEmpty(requestedChanges)])
  const [requestedChangesObject, setRequestedChangesObject] = useState({
    ...requestedChanges
  })
  const {getSingleCategory, getProductBids} = useFirebase()
  const [equipmentId, setEquipmentId] = useState(data?.equipmentId)

  useEffect(() => {
    if (!data?.equipmentId) return
    const getCategoryName = async () => {
      const {data: category} = await getSingleCategory(data.equipmentId || '')
      setEquipmentId(category?.name || '-')
    }
    getCategoryName()
  }, [data?.equipmentId])

  const requestedChangesKeys = Object.keys(requestedChangesObject)
  const lengthRequestedChanges = requestedChangesKeys?.length || 0
  const half = Math.ceil(lengthRequestedChanges / 2)
  const firstHalfReq = requestedChangesKeys.slice(0, half)
  const secondHalfReq = requestedChangesKeys.slice(half)
  const isSerialNumber = data?.serialNumber
  const isEquipmentHours = isNumber(data?.equipmentHours)
  const differentAddress = addressObj?.isEquipmentAtADifferentAddress || false
  const disableRFC = isEqual(requestedChanges, requestedChangesObject)
  const toggleShowModal = () => setShowModal(!showModal)
  const toggleShowReqModal = () => setShowReqModal(!showReqModal)
  const displayRFCInfoModal = () => setShowRFCInfoModal(true)
  const closeRFCInfoModal = () => setShowRFCInfoModal(false)

  const setReqChangePopupVariables = (key: React.SetStateAction<string>) => {
    setSelectedReqKey(key)
    toggleShowReqModal()
  }

  const checkForRequestedChanges = (label: string, reqKey: string) => {
    const isReqChange = requestedChangesObject?.[reqKey] ? true : false
    return isReqChange ? (
      <div className={styles.reqChangeContainer}>{label}*</div>
    ) : (
      label
    )
  }

  const updateRequestedChanges = ({
    comment,
    remove
  }: {
    comment?: string
    remove?: boolean
  }) =>
    setRequestedChangesObject((prev) =>
      remove ? omit(prev, selectedReqKey) : {...prev, [selectedReqKey]: comment}
    )

  const showCommentIcon =
    data?.status === productStatus.inProgress ||
    data?.status === productStatus.Pending
  const isSold = data?.status === productStatus.Sold
  const descriptionEditorValue = parseHtmlToPlainText(data?.description || '')
  const firstChoiceUnknown =
    data?.productDetails?.firstChoice === equipmentOptions.Unknown
  const secondChoiceUnknown =
    data?.productDetails?.secondChoice === equipmentOptions.Unknown

  return (
    <React.Fragment>
      <div className={styles.buttonsContainer}>
        {data.status === productStatus.Approved && (
          <a
            href={`${PRODUCTION_URL}/equipment-detail/${data?.productId}`}
            className={classNames(styles.button, styles.viewProductBtn)}
            target="_blank"
            rel="noreferrer"
          >
            <Eye /> View Equipment
          </a>
        )}
        {showCommentIcon && (
          <div className={styles.infoBoxContainer}>
            <InfoIcon onClick={displayRFCInfoModal} />
            <button
              disabled={disableRFC}
              onClick={() => setShowSubmitRFCModal(true)}
              className={classNames(styles.button, styles.btn_rfc)}
            >
              Submit RFC(s)
            </button>
          </div>
        )}
      </div>
      <div className={styles.mainContainer}>
        <div className={styles.headingBox}>Equipment Status</div>
        <div className={styles.productdetailbox}>
          <div className={styles.multiDetailsBox}>
            <FieldComponent
              label="Commision Percentage"
              value={data?.commissionPercentage || '-'}
            />
            <FieldComponent
              label="Is Synced"
              checkedCondition={data?.isSynced}
              checkedField={true}
            />
            <FieldComponent
              label="Listing Agreement Signed"
              checkedField={true}
              checkedCondition={data?.listingAgreementSigned}
            />
            <FieldComponent
              label="Is Commision Set Manually"
              checkedCondition={data?.isCommissionSetManually}
              checkedField={true}
            />
          </div>
          <div className={styles.multiDetailsBox}>
            <FieldComponent
              label="Buyer's Premium"
              value={data?.buyersPremium || '-'}
            />
            <FieldComponent label="Status" value={data?.status || '-'} />
            <FieldComponent
              label="Listing Agreement Status"
              value={data?.agreementStatus || '-'}
            />
            <FieldComponent
              label="Is Featured Listing"
              checkedField={true}
              checkedCondition={data?.isFeaturedListing}
            />
            <FieldComponent
              label="Is Buyer's Premium Set Manually"
              checkedCondition={data?.isBuyersPremiumSetManually}
              checkedField={true}
            />
          </div>
        </div>

        <div className={styles.headingBox}>Equipment Ownership</div>
        <div className={styles.productdetailbox}>
          <div className={styles.multiDetailsBox}>
            <FieldComponent
              label="Equipment ID"
              value={data?.productId || '-'}
              isInputField={true}
            />

            <FieldComponent
              label="Account ID"
              value={data?.accountId || '-'}
              isInputField={true}
            />
            <FieldComponent
              label="Contact ID"
              value={data?.contactId || '-'}
              isInputField={true}
            />
          </div>
          <div className={styles.multiDetailsBox}>
            <FieldComponent
              label="Equipment Sub Type"
              value={data?.subcategoryId || '-'}
            />
            <FieldComponent
              label="Account Name"
              value={data?.accountName || '-'}
            />
            <FieldComponent
              label="Contact Name"
              value={data?.contactName || '-'}
            />
          </div>
        </div>

        <div className={styles.headingBox}>Equipment Details</div>
        <div className={styles.productdetailbox}>
          <div className={styles.multiDetailsBox}>
            <FieldComponent
              label={checkForRequestedChanges('Title', 'title')}
              value={data?.title || '-'}
              setReqChangePopupVariables={setReqChangePopupVariables}
              withCommentIcon={showCommentIcon}
              requestedChangeKey="title"
            />
            {!isAuction && (
              <FieldComponent
                label="Accept Offers"
                checkedField={true}
                checkedCondition={data?.acceptOffers}
              />
            )}
            <FieldComponent
              label="Listing Type"
              value={data?.listingType || '-'}
            />
            <FieldComponent
              label={checkForRequestedChanges('Equipment Type', 'equipmentId')}
              value={equipmentId || '-'}
              setReqChangePopupVariables={setReqChangePopupVariables}
              withCommentIcon={showCommentIcon}
              requestedChangeKey="equipmentId"
            />
            <FieldComponent
              label={checkForRequestedChanges(
                isEquipmentHours
                  ? equipmentOptions.EquipmentHours
                  : equipmentOptions.EquipmentMileage,
                isEquipmentHours ? 'equipmentHours' : 'equipmentMileage'
              )}
              value={
                firstChoiceUnknown
                  ? 'Unknown'
                  : isEquipmentHours
                  ? data?.equipmentHours
                  : data?.equipmentMileage
              }
              setReqChangePopupVariables={setReqChangePopupVariables}
              withCommentIcon={showCommentIcon}
              requestedChangeKey={
                isEquipmentHours ? 'equipmentHours' : 'equipmentMileage'
              }
            />

            <FieldComponent
              label={checkForRequestedChanges(
                'Equipment Manufacturer',
                'productManufacturer'
              )}
              value={data?.productManufacturer}
              setReqChangePopupVariables={setReqChangePopupVariables}
              requestedChangeKey="productManufacturer"
              withCommentIcon={showCommentIcon}
            />
          </div>
          <div className={styles.multiDetailsBox}>
            <FieldComponent
              isLink
              label="Website Link"
              value={
                data?.websiteLink
                  ? `${PRODUCTION_URL}${data?.websiteLink}`
                  : '-'
              }
            />
            <FieldComponent
              label={checkForRequestedChanges('Model', 'model')}
              value={data?.model || '-'}
              setReqChangePopupVariables={setReqChangePopupVariables}
              requestedChangeKey="model"
              withCommentIcon={showCommentIcon}
            />
            <FieldComponent
              label={checkForRequestedChanges('Year', 'year')}
              value={data?.year === null ? 'Unknown' : data?.year}
              setReqChangePopupVariables={setReqChangePopupVariables}
              requestedChangeKey="year"
              withCommentIcon={showCommentIcon}
            />
            <FieldComponent
              label={checkForRequestedChanges(
                isSerialNumber
                  ? equipmentOptions.SerialNumber
                  : equipmentOptions.EquipmentVINNumber,
                isSerialNumber ? 'serialNumber' : 'equipmentVinNumber'
              )}
              value={
                secondChoiceUnknown
                  ? 'Unknown'
                  : isSerialNumber
                  ? data?.serialNumber
                  : data?.equipmentVinNumber
              }
              setReqChangePopupVariables={setReqChangePopupVariables}
              withCommentIcon={showCommentIcon}
              requestedChangeKey={
                isSerialNumber ? 'serialNumber' : 'equipmentVinNumber'
              }
            />
            <FieldComponent
              label="New or Used Equipment"
              value={data?.newOrUsedEquipment || '-'}
            />
          </div>
        </div>
        {isAuction && (
          <React.Fragment>
            <div className={styles.headingBox}>Auction Information</div>
            <div className={styles.productdetailbox}>
              <div className={styles.multiDetailsBox}>
                <FieldComponent
                  label={checkForRequestedChanges(
                    'Auction Duration',
                    'auctionDuration'
                  )}
                  value={`${auctionObj?.auctionDuration || 0} days` || '-'}
                  setReqChangePopupVariables={setReqChangePopupVariables}
                  withCommentIcon={showCommentIcon}
                  requestedChangeKey="auctionDuration"
                />
                <FieldComponent
                  label={checkForRequestedChanges(
                    'Auction Scheduled Time',
                    'auctionScheduledTime'
                  )}
                  value={
                    isDate(auctionScheduledTime)
                      ? getFormattedDate(new Date(auctionScheduledTime))
                      : '-'
                  }
                  setReqChangePopupVariables={setReqChangePopupVariables}
                  withCommentIcon={showCommentIcon}
                  requestedChangeKey="auctionScheduledTime"
                />
              </div>
              <div className={styles.multiDetailsBox}>
                <FieldComponent
                  label="Publish Auction"
                  value={auctionObj?.publishAuction || '-'}
                />
              </div>
            </div>
          </React.Fragment>
        )}
        <div className={styles.headingBox}>
          {checkForRequestedChanges('Description', 'description')}
        </div>
        <div
          className={classNames(
            styles.productdetailbox,
            styles.productDescription
          )}
        >
          <div
            style={{
              width: '100%'
            }}
            className={styles.multiDetailsBox}
          >
            <FieldComponent
              isRichText={true}
              value={descriptionEditorValue}
              setReqChangePopupVariables={setReqChangePopupVariables}
              withCommentIcon={showCommentIcon}
              requestedChangeKey="description"
            />
          </div>
        </div>
        <div className={styles.headingBox}>Images / Videos / Attachments</div>
        <div className={styles.productdetailbox}>
          <div className={styles.multiDetailsBox}>
            <div className={styles.singleDetailBox}>
              <div className={styles.labelDetail}>
                {checkForRequestedChanges('Images', 'images')}
              </div>
              <div className={styles.imageGridContainer}>
                {imagesArray.length
                  ? imagesArray.map((imageURL: string, index: number) => {
                      return (
                        <React.Fragment key={`image${index}${imageURL}`}>
                          <div
                            className={styles.imageContainer}
                            onClick={() => {
                              setSelectedFile(() => {
                                setIsVideoImage(true)
                                toggleShowModal()
                                setIsVideo(false)
                                return imageURL
                              })
                            }}
                          >
                            <img src={imageURL} alt="imgurl" />
                          </div>
                          {showCommentIcon ? (
                            <div
                              className={styles.commentIconContainer}
                              onClick={() =>
                                setReqChangePopupVariables('images')
                              }
                            >
                              <img src={CommentIcon} alt="comment" />
                            </div>
                          ) : null}
                        </React.Fragment>
                      )
                    })
                  : '-'}
              </div>
            </div>
            <div className={styles.singleDetailBox}>
              <div className={styles.labelDetail}>
                {checkForRequestedChanges('Attachments', 'attachments')}
              </div>
              <div className={styles.labelValue}>
                {attachmentsArray.length ? (
                  <ul>
                    {attachmentsArray.map((attachment, index) => {
                      const attachmentTitle =
                        attachment?.originalTitle || attachment.title
                      const attachmentURL = attachment?.url || ''
                      const showCommentIcon =
                        attachmentsArray.length === 1 || index === 0
                      return (
                        <React.Fragment key={`attachment-item-${index}`}>
                          <li
                            className={styles.cursorPointer}
                            key={`attachment${index}`}
                            onClick={() => {
                              setInvoiceURL(() => {
                                setIsVideoImage(false)
                                setShowModal(true)
                                return attachmentURL
                              })
                            }}
                          >
                            {`${index + 1}. ${
                              attachmentTitle.length > 30
                                ? `${attachmentTitle.slice(0, 5).trim()}...pdf`
                                : attachmentTitle
                            }`}
                            {showCommentIcon ? (
                              <div
                                className={styles.commentIconContainer}
                                onClick={(e) => {
                                  e.preventDefault()
                                  e.stopPropagation()
                                  setReqChangePopupVariables('attachments')
                                }}
                              >
                                <img src={CommentIcon} alt="comment" />
                              </div>
                            ) : null}
                          </li>
                        </React.Fragment>
                      )
                    })}
                  </ul>
                ) : (
                  '-'
                )}
              </div>
            </div>
          </div>
          <div className={styles.multiDetailsBox}>
            <div className={styles.singleDetailBox}>
              <div className={styles.labelDetail}>
                {checkForRequestedChanges('Videos', 'videos')}
              </div>
              <div className={styles.videoGridContainer}>
                {videosArray.length
                  ? videosArray.map((videoURL: string, index: number) => {
                      const fileId = videoURL?.split('/')[5]
                      const driveUrlRegex =
                        /^https:\/\/drive\.google\.com\/file/
                      const isDriveUrl = driveUrlRegex.test(videoURL)
                      const isEmbedUrl = videoURL.includes('youtube')
                      const showCommentIcon =
                        videosArray.length === 1 || index === 1
                      return (
                        <React.Fragment key={`video-item-${index}`}>
                          <div
                            className={classNames(styles.videoContainer)}
                            key={`video-${index}-${videoURL}`}
                            onClick={() => {
                              setSelectedFile(() => {
                                setIsVideo(true)
                                setIsVideoImage(true)
                                toggleShowModal()
                                return videoURL
                              })
                            }}
                          >
                            {isDriveUrl ? (
                              <iframe
                                allowFullScreen
                                title="drive-video-player"
                                src={`https://drive.google.com/file/d/${fileId}/preview`}
                                width="131"
                                height="106"
                              ></iframe>
                            ) : isEmbedUrl ? (
                              <iframe
                                allowFullScreen
                                title="embed-video-player"
                                src={videoURL}
                                width="131"
                                height="106"
                              ></iframe>
                            ) : (
                              <video src={videoURL} />
                            )}
                          </div>
                          {showCommentIcon ? (
                            <div
                              className={styles.commentIconContainer}
                              onClick={() =>
                                setReqChangePopupVariables('videos')
                              }
                            >
                              <img src={CommentIcon} alt="comment" />
                            </div>
                          ) : null}
                        </React.Fragment>
                      )
                    })
                  : '-'}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.headingBox}>Questions</div>
        <div className={styles.productdetailbox}>
          <div className={styles.multiDetailsBox}>
            {!isAuction && (
              <FieldComponent
                label="Allow Buyers to make you offers?"
                checkedCondition={data?.acceptOffers}
                checkedField={true}
              />
            )}
            <FieldComponent
              label="Is this a titled piece of equipment?"
              checkedCondition={Boolean(data?.isThisATitledPieceOfEquipment)}
              checkedField={true}
            />
            {requireDismantling ? (
              <FieldComponent
                label="Are you willing to help with dismantling?"
                checkedCondition={willingToHelp}
                checkedField={true}
              />
            ) : null}
            <FieldComponent
              label="Will dismantling require crane service?"
              checkedCondition={
                data?.doesThisRequireDismantling
                  ?.willDismantlingRequireCraneService
              }
              checkedField={true}
            />
            <FieldComponent
              label="Is there a lien on the equipment?"
              checkedCondition={isLien}
              checkedField={true}
            />
            {toWhatExtent ? (
              <FieldComponent
                label="Is the equipment at a different address?"
                checkedCondition={differentAddress}
                checkedField={true}
              />
            ) : null}
          </div>

          <div className={styles.multiDetailsBox}>
            <FieldComponent
              label="Does this equipment require dismantling?"
              checkedCondition={requireDismantling}
              checkedField={true}
            />
            {willingToHelp ? (
              <FieldComponent
                label={checkForRequestedChanges(
                  'To what extent are you willing to help?',
                  'toWhatExtentAreYouWillingToHelp'
                )}
                isTextArea={true}
                value={toWhatExtentValue || ''}
                setReqChangePopupVariables={setReqChangePopupVariables}
                withCommentIcon={showCommentIcon}
                requestedChangeKey="toWhatExtentAreYouWillingToHelp"
              />
            ) : null}
            <FieldComponent
              label="Will you provide the electrical disconnect if required?"
              checkedCondition={
                data?.willYouProvideTheElectricalDisconnectIfRequired
              }
              checkedField={true}
            />
            <FieldComponent
              label="Will you load the equipment on to the buyers trucks?"
              checkedCondition={
                data?.willYouLoadTheEquipmentOnToTheBuyersTrucks
              }
              checkedField={true}
            />
            {!toWhatExtent && (
              <FieldComponent
                label="Is the equipment at a different address?"
                checkedCondition={differentAddress}
                checkedField={true}
              />
            )}
          </div>
        </div>
        {isLien && (
          <React.Fragment>
            <div className={styles.headingBox}>Lien Info</div>

            <div className={styles.productdetailbox}>
              <div className={styles.multiDetailsBox}>
                <FieldComponent
                  label={checkForRequestedChanges(
                    'Lien Holder Name',
                    'lienHolderName'
                  )}
                  value={lienObject?.lienHolderName || '-'}
                  setReqChangePopupVariables={setReqChangePopupVariables}
                  withCommentIcon={showCommentIcon}
                  requestedChangeKey="lienHolderName"
                />
                <FieldComponent
                  label={checkForRequestedChanges(
                    'Lien Holder Phone',
                    'lienHolderPhone'
                  )}
                  value={lienObject?.lienHolderPhone || '-'}
                  setReqChangePopupVariables={setReqChangePopupVariables}
                  withCommentIcon={showCommentIcon}
                  requestedChangeKey="lienHolderPhone"
                />
              </div>
              <div className={styles.multiDetailsBox}>
                <FieldComponent
                  label={checkForRequestedChanges(
                    'Lien Holder Email',
                    'lienHolderEmail'
                  )}
                  value={lienObject?.lienHolderEmail || '-'}
                  setReqChangePopupVariables={setReqChangePopupVariables}
                  withCommentIcon={showCommentIcon}
                  requestedChangeKey="lienHolderEmail"
                />
                <FieldComponent
                  label={checkForRequestedChanges(
                    'Lien Holder Pay off Amount',
                    'lienHolderPayoffAmount'
                  )}
                  value={lienObject?.lienHolderPayoffAmount || '-'}
                  setReqChangePopupVariables={setReqChangePopupVariables}
                  withCommentIcon={showCommentIcon}
                  requestedChangeKey="lienHolderPayoffAmount"
                />
              </div>
            </div>
          </React.Fragment>
        )}
        <div className={styles.headingBox}>Address</div>
        <div className={styles.productdetailbox}>
          <div className={styles.multiDetailsBox}>
            <FieldComponent
              label={checkForRequestedChanges('Country', 'equipmentCountry')}
              value={addressObj?.equipmentCountry || '-'}
              setReqChangePopupVariables={setReqChangePopupVariables}
              withCommentIcon={isAtADifferentAddress}
              requestedChangeKey="equipmentCountry"
            />
            <FieldComponent
              label={checkForRequestedChanges('City', 'equipmentCity')}
              value={addressObj?.equipmentCity || '-'}
              setReqChangePopupVariables={setReqChangePopupVariables}
              withCommentIcon={isAtADifferentAddress}
              requestedChangeKey="equipmentCity"
            />
            <FieldComponent
              label={checkForRequestedChanges('Zip Code', 'equipmentZip')}
              value={addressObj?.equipmentZip || '-'}
              setReqChangePopupVariables={setReqChangePopupVariables}
              withCommentIcon={isAtADifferentAddress}
              requestedChangeKey="equipmentZip"
            />
          </div>
          <div className={styles.multiDetailsBox}>
            <FieldComponent
              label={checkForRequestedChanges('State', 'equipmentState')}
              value={addressObj?.equipmentState || '-'}
              setReqChangePopupVariables={setReqChangePopupVariables}
              withCommentIcon={isAtADifferentAddress}
              requestedChangeKey="equipmentState"
            />
            <FieldComponent
              label={checkForRequestedChanges('Street', 'equipmentStreet')}
              value={addressObj?.equipmentStreet || '-'}
              setReqChangePopupVariables={setReqChangePopupVariables}
              withCommentIcon={isAtADifferentAddress}
              requestedChangeKey="equipmentStreet"
            />
          </div>
        </div>
        <div className={styles.headingBox}>Price</div>
        <div className={styles.productdetailbox}>
          <div className={styles.multiDetailsBox}>
            <FieldComponent
              label={checkForRequestedChanges('Buy Now Price', 'buyNowPrice')}
              value={
                withThousandSeperatorString(
                  `${data?.buyNowPriceMap?.buyNowPrice || 0}`
                ) || '-'
              }
              setReqChangePopupVariables={setReqChangePopupVariables}
              withCommentIcon={showCommentIcon}
              requestedChangeKey="buyNowPrice"
            />
            {isAuction && (
              <FieldComponent
                label={checkForRequestedChanges(
                  'Opening Price',
                  'openingPrice'
                )}
                value={
                  withThousandSeperatorString(
                    `${data?.openingPriceMap?.openingPrice || 0}`
                  ) || '-'
                }
                setReqChangePopupVariables={setReqChangePopupVariables}
                withCommentIcon={showCommentIcon}
                requestedChangeKey={'openingPrice'}
              />
            )}
          </div>
          <div className={styles.multiDetailsBox}>
            <FieldComponent
              label={!isSold ? 'Current Price' : 'Final Sale Price'}
              value={withThousandSeperatorString(
                `${data?.finalSalePriceMap?.finalSalePrice || 0}`
              )}
              setReqChangePopupVariables={setReqChangePopupVariables}
              withCommentIcon={false}
            />
            {isAuction && (
              <FieldComponent
                label={'Total Bids'}
                value={`${totalBids} Bids`}
                clickableText={totalBids === 0 ? '' : `(See More)`}
                clickableTextOnClick={toggleTotalBidsModal}
                withCommentIcon={false}
              />
            )}
            {isAuction && (
              <FieldComponent
                label={checkForRequestedChanges(
                  'Initial Reserve Price',
                  'reservePrice'
                )}
                value={
                  withThousandSeperatorString(
                    `${data?.initialReservePriceMap?.reservePrice || 0}`
                  ) || '-'
                }
                setReqChangePopupVariables={setReqChangePopupVariables}
                withCommentIcon={showCommentIcon}
                requestedChangeKey="reservePrice"
              />
            )}
          </div>
        </div>

        <div className={styles.headingBox}>Contract Information</div>
        <div className={styles.productdetailbox}>
          <div className={styles.multiDetailsBox}>
            <FieldComponent
              label="Duration (Days)"
              value={`${getDaysFromToday(contractDuration)} Days left`}
            />
            <FieldComponent
              label="Notification Duration (Days)"
              value={renewalWindow}
            />
          </div>
          <div className={styles.multiDetailsBox}>
            <FieldComponent
              label="Auto Renew"
              checkedCondition={autoRenew}
              checkedField={true}
            />
          </div>
        </div>
        <div className={styles.headingBox}>Miscellaneous</div>
        <div className={styles.productdetailbox}>
          <div className={styles.multiDetailsBox}>
            <FieldComponent
              label="Created At"
              value={
                isDate(createdAtDate)
                  ? getFormattedDate(new Date(createdAtDate))
                  : '-'
              }
            />
            <FieldComponent label="Views" value={data?.views || 0} />
          </div>
          <div className={styles.multiDetailsBox}>
            <FieldComponent
              label="Last Updated"
              value={
                isDate(lastUpdatedDate)
                  ? getFormattedDate(new Date(lastUpdatedDate))
                  : '-'
              }
            />
            <FieldComponent
              label="Ended At"
              value={
                isSold && isDate(endedAtDate)
                  ? getFormattedDate(new Date(endedAtDate))
                  : '-'
              }
            />
          </div>
        </div>

        {lengthRequestedChanges ? (
          <React.Fragment>
            <div className={styles.headingBox}>Requested Changes</div>
            <div className={styles.productdetailbox}>
              <div className={styles.multiDetailsBox}>
                {firstHalfReq.map((key, index) => {
                  return (
                    <FieldComponent
                      label={`${PRODUCT_STATUS_FIELDS[key]}`}
                      value={requestedChangesObject[key] || '-'}
                      key={`requestedChangeFirst${index}`}
                    />
                  )
                })}
              </div>
              <div className={styles.multiDetailsBox}>
                {secondHalfReq.map((key, index) => {
                  return (
                    <FieldComponent
                      label={`${PRODUCT_STATUS_FIELDS[key]}`}
                      value={requestedChangesObject[key] || '-'}
                      key={`requestedChangeSecond${index}`}
                    />
                  )
                })}
              </div>
            </div>
          </React.Fragment>
        ) : null}
      </div>

      {showTotalBids && (
        <ModalComponent handleClose={toggleTotalBidsModal} show={showTotalBids}>
          <ProductBidsModal
            bids={bids || []}
            handleClose={toggleTotalBidsModal}
          />
        </ModalComponent>
      )}
      {showReqModal && (
        <ModalComponent show={showReqModal} handleClose={toggleShowReqModal}>
          <RequestedChangesModal
            handleClose={toggleShowReqModal}
            fieldComment={requestedChangesObject[selectedReqKey] || ''}
            updateRequestedChanges={updateRequestedChanges}
          />
        </ModalComponent>
      )}
      {showRFCInfoModal && (
        <ModalComponent show={showRFCInfoModal} handleClose={closeRFCInfoModal}>
          <ViewPDFModal
            handleClose={closeRFCInfoModal}
            url={'/RFC_Submission_Guidelines.pdf'}
            forRFCFile
          />
        </ModalComponent>
      )}
      {showSubmitRFCModal && (
        <ModalComponent
          show={showSubmitRFCModal}
          handleClose={() => setShowSubmitRFCModal(false)}
        >
          <RequestedChangesSubmitModal
            handleClose={() => setShowSubmitRFCModal(false)}
            productId={data?.productId || ''}
            contactId={data?.contactId || ''}
            updatedRequestedChanges={requestedChangesObject}
            currentRequestedChanges={requestedChanges}
          />
        </ModalComponent>
      )}
      {showModal && (
        <ModalComponent show={showModal} handleClose={toggleShowModal}>
          {isVideoImage ? (
            <FileModal
              handleClose={toggleShowModal}
              selectedFile={selectedFile}
              isVideo={isVideo}
            />
          ) : (
            <ViewPDFModal handleClose={toggleShowModal} url={invoiceURL} />
          )}
        </ModalComponent>
      )}
    </React.Fragment>
  )
}

export default ProductDetails
