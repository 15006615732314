import styles from './InboxCard.module.scss'
import cn from 'classnames'
import {Truck, UserPlaceholder} from '../../../assets/images'
import {isArray} from 'lodash'
import {isValidUrl} from 'components/utils'
import {DealThumbnail} from 'common'
import UserProfilePicture from 'components/UserProfileImage'

interface InboxCardProps {
  customClass: string
  thumbnail: DealThumbnail
  title: string
  content: string
  message: string
  buyerContactId: string
  lastMessageSender: string
  time: string
  onClick: () => void
}

const InboxCard = ({
  customClass,
  thumbnail,
  title,
  content,
  message,
  lastMessageSender,
  buyerContactId,
  time,
  onClick
}: InboxCardProps) => {
  const productImage =
    (isArray(thumbnail?.productImage)
      ? thumbnail?.productImage![0]
      : thumbnail?.productImage) || Truck

  return (
    <div onClick={onClick} className={cn(styles.users_container, customClass)}>
      <div className={styles.img_container}>
        <img
          alt="img-product"
          src={productImage}
          className={styles.img__avatar}
        />
        <UserProfilePicture
          userId={buyerContactId}
          alt="img-user-secondary"
          className={styles.img__user}
        />
      </div>
      <div className={styles.content}>
        <h2 className="with-ellipsis">{title}</h2>
        <p className="with-ellipsis">{content}</p>
        <div>
          <p className="with-ellipsis">
            <b style={{fontWeight: 'bold'}}>{lastMessageSender || 'Admin'}</b>
            {`: `}
            {message}
          </p>
          <b>{time}</b>
        </div>
      </div>
    </div>
  )
}

export default InboxCard
