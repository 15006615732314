import React from 'react'
import styles from './ReceiverMessage.module.scss'
import {
  DownloadIconCircle,
  PdfIcon,
  Play_icon,
  Triangle
} from '../../../assets/svgs'
import {downloadPDFfromURL} from '../../utils'
import DOMPurify from 'dompurify'
import ReactPlayer from 'react-player'
import {Attachments, PreviewLinkProps} from 'common'
import ChatImage from 'components/ChatImage'
import UserProfilePicture from 'components/UserProfileImage'
import classnames from 'classnames'

interface ReceiverMessageProps {
  message: string
  buyerContactId: string
  attachment: Array<Attachments>
  preview?: PreviewLinkProps
}

const ReceiverMessage = ({
  message,
  attachment,
  buyerContactId,
  preview
}: ReceiverMessageProps) => {
  if (attachment?.length > 0) {
    return (
      <React.Fragment>
        {attachment?.map((att, index) => {
          const {type} = att
          const renderComponentBasedOnType = () =>
            type === 'image' ? (
              <ChatImage alt="receiver-attachment-img" url={att?.downloadURL} />
            ) : type === 'video' ? (
              <div className={styles.video_wrapper}>
                <ReactPlayer
                  className={styles.videos}
                  controls
                  playing
                  url={att?.downloadURL}
                  playIcon={<Play_icon />}
                />
              </div>
            ) : type === 'application/pdf' ? (
              <div className={styles.PDF_container}>
                <PdfIcon />{' '}
                {att?.name.length > 9
                  ? `${att?.name.slice(0, 5).trim()}...pdf`
                  : att?.name}
                <button
                  onClick={() =>
                    downloadPDFfromURL(att?.downloadURL, att?.name)
                  }
                >
                  <DownloadIconCircle />
                </button>
              </div>
            ) : null
          return (
            <div
              key={`receiver-message-with-attachement-${index}`}
              className={styles.conatiner}
            >
              <div className={styles.left_container}>
                <div className={styles.messagerimg}>
                  <UserProfilePicture
                    userId={buyerContactId}
                    alt="img-messager"
                  />
                </div>
                <div
                  style={
                    type === 'video'
                      ? {maxWidth: '23.5rem'}
                      : {maxWidth: '22.5rem'}
                  }
                  className={styles.content_left}
                >
                  <div className={styles.attacment_wrapper}>
                    {renderComponentBasedOnType()}
                  </div>
                  {message && (
                    <p
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(message)
                      }}
                      className={styles.attachment_message}
                    ></p>
                  )}
                  <Triangle className={styles.message_ions} />
                </div>
              </div>
            </div>
          )
        })}
      </React.Fragment>
    )
  }

  return (
    <div className={classnames(styles.conatiner)}>
      <div className={styles.left_container}>
        <div className={styles.messagerimg}>
          <UserProfilePicture userId={buyerContactId} alt="img-messager" />
        </div>
        <div className={styles.content_left}>
          {preview?.title?.length ? (
            <div className={styles.preview_container}>
              <div className={styles.preview_container_img_container}>
                <img src={preview?.image} alt="map-img" />
              </div>
              <div className={styles.preview_container_content_container}>
                <div>{preview?.title}</div>
                <div className={styles.preview_content}>
                  {preview?.description}
                </div>
              </div>
            </div>
          ) : null}
          <p
            dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(message)}}
          ></p>
          <Triangle className={styles.message_ions} />
        </div>
      </div>
    </div>
  )
}

export default ReceiverMessage
